/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utilities for working with reducers
 */

const ACTION_TYPE_CONTEXT_SEPARATOR = ':';

/**
 * Returns the given action type combined with the given store context
 * @param actionType The action type
 * @param storeContext The store context
 * @returns The combined action type with context
 */
export const getActionTypeWithContext = (actionType: string, storeContext: string) => {
  return `${storeContext}${ACTION_TYPE_CONTEXT_SEPARATOR}${actionType}`;
};

/**
 * Returns whether the given action type matches the provided store context
 * @param actionType The action type
 * @param storeContext The store context
 * @param matchNoContext Whether an action type without any context should be counted as a match
 * @returns True if the action type meets the criteria, False otherwise
 */
export const actionTypeHasGivenContext = (actionType: string, storeContext: string, matchNoContext: boolean) => {
  const actionHasContext = actionType.indexOf(ACTION_TYPE_CONTEXT_SEPARATOR) > -1;

  return (
    (actionHasContext && actionType.split(ACTION_TYPE_CONTEXT_SEPARATOR)[0] === storeContext) ||
    (!actionHasContext && matchNoContext)
  );
};

/**
 * Removes the given context from the given action type
 * @param actionType The action type
 * @param storeContext The store context
 * @returns The action type without context
 */
export const removeContextFromActionType = (actionType: string, storeContext: string) => {
  return actionType.replace(`${storeContext}${ACTION_TYPE_CONTEXT_SEPARATOR}`, '');
};

/**
 * Converts a string to an integer (number) but throws an exception if
 * the string is not a integer.
 * @param input String to convert to a number
 * @returns Converted number
 */
export const convertToInt = (input: string): number => {
  const converted = parseInt(input, 10); // always base 10
  if (isNaN(converted)) throw new Error('Not a number');
  return converted;
};
