/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: New Terminal Installation
 */
import React, {useEffect, useMemo, useState} from 'react';
import {isNil} from 'lodash';
import {useLocation, useNavigate} from 'react-router-dom';
import {useStore} from '../../store/Store';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styled from '@emotion/styled';
import PageContainer from '../mainMenu/PageContainer';
import {
  BUTTON_HOVER_COLOR,
  FORM_COLOR,
  LABEL_COLOR,
  NEXT_BUTTON_DISABLED,
  NEXT_BUTTON_TEXT_DISABLED_COLOR
} from '../../theme/Colors';
import {COMPLETED_OPTION_TEXT_COLOR} from '../common/theme/Colors';
import {
  HeaderHorizontalRule,
  BottomHorizontalRule,
  PageDescription,
  HeaderLabel,
  NextButton,
  EquipmentDetailContainer
} from '../CommonStyles';
import Breadcrumb from '../common/Breadcrumb';
import {AircraftInfoAction} from '../../store/reducers/AircraftInfoReducer';
import {submitRequestQuery} from '../../store/queries/submitRequestQuery';
import {IStore} from '../../store/types';
import NewTerminalCompletionModal from './NewTerminalCompletionModal';
import {
  DUAL_BAND_KA_INSTALLATION_OPTIONS,
  DUAL_BAND_NETWORK,
  KA_INSTALLATION_OPTIONS,
  KA_NETWORK,
  KU_INSTALLATION_OPTIONS,
  KU_NETWORK
} from '../common/constants';
import {APPROVAL_MODULES, determineCompletionState} from './addNewTerminalUtils';
import {EquipmentInfoAction} from '../../store/reducers/EquipmentInfoReducer';
import useFetchV2 from '../../utils/useFetchV2';

const ID_PREFIX = 'newTerminalInstallation';

interface NewTerminalProps {
  title: string;
  aircraftSerialNumber?: string;
}

const SubTitleLabel = styled.div`
  display: flex;
  width: 646px;
  height: 24px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
  color: ${FORM_COLOR};
  flex: none;
  order: 0;
  flex-grow: 0;
  .no-serial-number {
    color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
  }
`;

const NewTerminalButtons = styled.button<{textColor: string}>`
   box-sizing: border-box;
   margin-bottom: 8px;
   background: white;
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 8px 28px;
   gap: 8px;
   min-width: 300px;
   width: -webkit-fill-available;
   height: 48px;
   font-family: 'Source Sans Pro';
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 24px;
   color: ${props => props.textColor};
   border: 1px solid ${NEXT_BUTTON_DISABLED};
   border-radius: 1000px;
   flex: none;
   order: 0;
   align-self: stretch;
   flex-grow: 0;
   cursor: pointer;
   justify-content: space-between;
   &:disabled {
     cursor: not-allowed;
     pointer-events: auto;
     color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
   }
   &:hover {
     background: ${BUTTON_HOVER_COLOR};
   }
   .option {
    display: flex;
    .arrow {
      width: 20px;
      height: 20px
      position: absolute;
      left: 16.67%;
      right: 16.67%;
      top: 16.67%;
      bottom: 16.67%;
      margin-left: 8px;
      color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
    }
   }
   .completed {
    font-size: 16px;
    display: flex;
    margin-left: 8px;
   }
 `;

const LeftChild: React.FC<{store: IStore; dispatch: any; location: any; navigate: any}> = ({
  store,
  dispatch,
  location,
  navigate
}) => {
  const network = store.aircraftInfo.network;
  const handleInstallationOptionClick = (navigationPath: string, aircraftNetwork: string) => {
    dispatch({type: AircraftInfoAction.SET_AIRCRAFT_NETWORK, payload: aircraftNetwork});
    navigate('/' + navigationPath);
  };

  return (
    <>
      <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>New Terminal Installation</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--description`}>
        Select one of the following options to begin your new installation. You may save your input at any time and
        return to complete your data entry later. <i>All the info needs to be competed before submission.</i>
      </PageDescription>
      <BottomHorizontalRule />
      <EquipmentDetailContainer>
        {network === DUAL_BAND_NETWORK || network === KU_NETWORK ? (
          <>
            <SubTitleLabel id={`${ID_PREFIX}--kuSubTitleLabel`} style={{marginTop: 40}}>
              Add Required Ku Equipment Information
            </SubTitleLabel>

            {KU_INSTALLATION_OPTIONS.map(options => {
              const dataSubmissionStatus =
                !isNil(store.equipmentInfo[options.equipment]) &&
                (!isNil(store.equipmentInfo[options.equipment]['serialNumber']) ||
                  !isNil(store.equipmentInfo[options.equipment]['routerType']));
              return (
                <NewTerminalButtons
                  id={`${ID_PREFIX}--ku-${options.id}-button`}
                  onClick={() => handleInstallationOptionClick(options.id, KU_NETWORK)}
                  textColor={dataSubmissionStatus ? COMPLETED_OPTION_TEXT_COLOR : LABEL_COLOR}
                >
                  <span className="option">
                    {options.name}
                    <ArrowForwardIcon id={`${options.id}-arrow`} className="arrow" />
                  </span>
                  {dataSubmissionStatus ? (
                    <span className="completed" id={`${ID_PREFIX}--${options.id}-${options.equipment}-completed`}>
                      <CheckCircleOutlineIcon />
                    </span>
                  ) : (
                    <></>
                  )}
                </NewTerminalButtons>
              );
            })}
          </>
        ) : (
          <></>
        )}
        {network === DUAL_BAND_NETWORK || network === KA_NETWORK ? (
          <>
            <SubTitleLabel id={`${ID_PREFIX}--kaSubTitleLabel`} style={{marginTop: 40}}>
              Add Required Ka Equipment Information
            </SubTitleLabel>

            {(network === KA_NETWORK ? KA_INSTALLATION_OPTIONS : DUAL_BAND_KA_INSTALLATION_OPTIONS).map(options => {
              const dataSubmissionStatus =
                !isNil(store.equipmentInfo[options.equipment]) &&
                (!isNil(store.equipmentInfo[options.equipment]['serialNumber']) ||
                  !isNil(store.equipmentInfo[options.equipment]['routerType']));
              return (
                <NewTerminalButtons
                  id={`${ID_PREFIX}--ka-${options.id}-button`}
                  onClick={() => handleInstallationOptionClick(options.id, KA_NETWORK)}
                  textColor={dataSubmissionStatus ? COMPLETED_OPTION_TEXT_COLOR : LABEL_COLOR}
                >
                  <span className="option">
                    {options.name}
                    <ArrowForwardIcon id={`${options.id}-arrow`} className="arrow" />
                  </span>
                  {dataSubmissionStatus ? (
                    <span className="completed" id={`${ID_PREFIX}--${options.id}-${options.equipment}-completed`}>
                      <CheckCircleOutlineIcon />
                    </span>
                  ) : (
                    <></>
                  )}
                </NewTerminalButtons>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </EquipmentDetailContainer>
    </>
  );
};
const ActionButtons: React.FC<{
  completionStatus: boolean;
  store: IStore;
  setTaskId: any;
}> = ({completionStatus, store, setTaskId}) => {
  return (
    <NextButton
      id={`${ID_PREFIX}--submitButton`}
      onClick={() => setTaskId(store.equipmentInfo.taskId)}
      disabled={!completionStatus}
    >
      <span className="next-button-text">Submit</span>
      <ArrowForwardIcon className="next-arrow" />
    </NextButton>
  );
};

const NewTerminalInstallation: React.FC<NewTerminalProps> = ({title, aircraftSerialNumber}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {store, dispatch} = useStore();
  const network = store.aircraftInfo.network;
  const [showCompletionModal, setShowCompletionModal] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>('');

  const queryParams = useMemo(() => {
    if (!taskId) return;
    return {
      taskId: taskId,
      moduleForApproval: APPROVAL_MODULES.EQUIPMENT
    };
  }, [taskId]);

  const {isLoading: isSubmitRequestLoading, data: submitResponse} = useFetchV2(
    {route: submitRequestQuery.route, params: queryParams},
    submitRequestQuery.transform
  );
  const closeModal = () => {
    setShowCompletionModal(false);
    dispatch({type: EquipmentInfoAction.RESET_EQUIPMENT_INFO});
    navigate('/main-menu');
  };
  useEffect(() => {
    if (isSubmitRequestLoading) return;
    if (submitResponse) {
      setShowCompletionModal(true);
    }
  }, [submitResponse, isSubmitRequestLoading]);
  const networkBasedOptions =
    network === DUAL_BAND_NETWORK
      ? DUAL_BAND_KA_INSTALLATION_OPTIONS
      : network === KA_NETWORK
      ? KA_INSTALLATION_OPTIONS
      : KU_INSTALLATION_OPTIONS;

  const completionStatus = determineCompletionState(store.equipmentInfo, networkBasedOptions);

  return (
    <>
      <NewTerminalCompletionModal openModal={showCompletionModal} setOpenModal={closeModal} />
      <PageContainer
        title={title}
        leftChild={<LeftChild store={store} dispatch={dispatch} navigate={navigate} location={location} />}
        actionButtons={<ActionButtons completionStatus={completionStatus} store={store} setTaskId={setTaskId} />}
      />
    </>
  );
};

export default NewTerminalInstallation;
