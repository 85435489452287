/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Service Plan Terms List View
 */
import React, {useMemo, useEffect, useState} from 'react';
import {HorizontalTabs, MenuBar, ShareLink, ListGridV2, ListGridColumn} from '@viasat/insights-components';
import {lookupTableTabDefinitions} from '../tabDefinitions';
import PageStyled from '../../../theme/PageStyled';
import {PageProps} from '../../common/layout/PageProps';
import ListGridContainer from '../../common/ListGridContainer';
import {getElementId} from '../../../utils/elementIdUtils';
import {getServicePlanTermsListQuery} from '../../../store/queries/lookupQueries';
import SnackBar from '../../common/SnackBar';
import {useStore} from '../../../store/Store';
import {getCurrentUrlPath} from '../../../utils/linkUtils';
import {LookupsListAction} from '../../../store/reducers/LookupsReducer';
import {
  LookupListColumnId,
  buildServicePlanTermsListColumns,
  dynamicSort,
  getQueryFieldMapping
} from './lookupTableUtils';
import AddServicePlanTerms from './forms/AddServicePlanTerms';
import {useRefreshLookupList, useResetSelectedLookup} from '../../../utils/customHooks';
import {IServicePlanTerms} from '../../../store/queries/lookupTypes';
import AccessControl from '../../common/accessControl/AccessControl';
import LookupHeaderAction from './LookupListHeaderActions';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {SERVICE_PLAN_TERMS_LIST_STORE_CONTEXT} from '../../../store/reducers/ServicePlanTermsListReducer';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import ListViewSortContainer from '../ListViewSortContainer';
import useFetchV2 from '../../../utils/useFetchV2';

const ID_PREFIX = 'servicePlanTermsList';

const ServicePlanTermsList: React.FC<PageProps> = () => {
  const {store, dispatch} = useStore();
  const {isAdmin} = store.init;
  const {sort} = store.servicePlanTermsList;
  const {newRow, deactivatedRow, refreshList, selectedLookups} = store.lookups;
  const [openAddServicePlanTerms, setOpenAddServicePlanTerms] = useState<boolean>(false);
  const {snackbar} = store.app;
  const [servicePlanTermsListData, setServicePlanTermsListData] = useState<IServicePlanTerms[]>(null);
  const urlPath = getCurrentUrlPath();

  useResetSelectedLookup();
  useRefreshLookupList(refreshList);

  const handleRowSelection = (selectedLookup: any) => {
    dispatch({
      type: LookupsListAction.SELECTED_LOOKUP_ROWS,
      payload: selectedLookup
    });
  };

  const handleRowDeselection = (removedLookup: any) => {
    dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: removedLookup});
  };

  const servicePlanTermsListColumns = buildServicePlanTermsListColumns(isAdmin).map(col => {
    // Add row selection/deselection handlers for checkbox
    if (col.key === 'id') {
      col.handleRowSelection = handleRowSelection;
      col.handleRowDeselection = handleRowDeselection;
    }

    return col;
  });
  const queryParams = useMemo(
    () => ({newRow, deactivatedRow, refreshList, doCache: false}),
    [newRow, deactivatedRow, refreshList]
  );

  const {isLoading: isServicePlanTermsListLoading, data: servicePlanTermsListInfo} = useFetchV2(
    {route: getServicePlanTermsListQuery.route, params: queryParams},
    getServicePlanTermsListQuery.transform
  );

  useEffect(() => {
    if (refreshList) {
      setTimeout(() => {
        dispatch({
          type: LookupsListAction.REFRESH_LOOKUP_LIST,
          payload: false
        });
        dispatch({
          type: LookupsListAction.RESET_NEW_ROW_COUNT,
          payload: 0
        });
      }, 3000); // The API call takes 1s or 1.5s or 2s sometimes and inconsistent with 500ms.
    }
  }, [refreshList, dispatch]);

  // Determine what columns to show and hide for the list grid
  const currentColumns = servicePlanTermsListColumns;

  useEffect(() => {
    if (isServicePlanTermsListLoading) return;
    const servicePlanTermsDataWithSelections: IServicePlanTerms[] = servicePlanTermsListInfo
      ? [...servicePlanTermsListInfo].map(row => {
          return {
            ...row,
            isSelected: selectedLookups.some((selectedRow: IServicePlanTerms) => selectedRow.id === row.id),
            isRowHighlighted: row.status === 'Retired'
          };
        })
      : [];
    setServicePlanTermsListData(servicePlanTermsDataWithSelections.sort(dynamicSort(sort.column, sort.order)));
  }, [servicePlanTermsListInfo, isServicePlanTermsListLoading, selectedLookups, sort.column, sort.order]);

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(LookupListColumnId[columnId]);

  const handleSortChange = ({column, order}: {column: string; order: string}) => {
    setServicePlanTermsListData(servicePlanTermsListData.sort(dynamicSort(column, order)));
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, SERVICE_PLAN_TERMS_LIST_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(servicePlanTermsListColumns[column]),
        validColumns: servicePlanTermsListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: servicePlanTermsListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(servicePlanTermsListColumns[col.key])
        )
      }
    });
  };
  return (
    <PageStyled>
      <MenuBar
        getFullElementId={(name: string, type: string) => getElementId(ID_PREFIX, 'menuBar', name, type)}
        title={''}
        subtitle={''}
        isSubtitleLoading={false}
        leftStack={[
          <AccessControl permitted={isAdmin}>
            <LookupHeaderAction
              isLoading={isServicePlanTermsListLoading}
              selectedLookups={selectedLookups}
              setOpenAddAircraftModel={setOpenAddServicePlanTerms}
              idPrefix={ID_PREFIX}
              isRetire={false}
            />
          </AccessControl>,
          <ListViewSortContainer
            key="sorts"
            idPrefix={ID_PREFIX}
            disabled={isServicePlanTermsListLoading}
            storeContext={SERVICE_PLAN_TERMS_LIST_STORE_CONTEXT}
            columns={servicePlanTermsListColumns}
            showColumnGroups={true}
            getQueryFieldForColumn={getQueryFieldForColumn}
          />
        ]}
        rightStack={[
          <ShareLink
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'shareLink', name, type)}
            disabled={isServicePlanTermsListLoading}
            urlPath={urlPath}
            urlQuery={''}
          />
        ]}
        tabs={
          <HorizontalTabs
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'listTabs', name, type)}
            tabs={lookupTableTabDefinitions}
            titleCount={servicePlanTermsListInfo ? servicePlanTermsListInfo.length : undefined}
          ></HorizontalTabs>
        }
      />

      {openAddServicePlanTerms ? (
        <AddServicePlanTerms
          openPanel={openAddServicePlanTerms}
          setOpenPanel={setOpenAddServicePlanTerms}
          panelData={selectedLookups[0]}
          isUpdate={selectedLookups.length === 1}
        ></AddServicePlanTerms>
      ) : (
        <></>
      )}
      {snackbar ? (
        <SnackBar id={`${ID_PREFIX}--snackbar`} openSnack={snackbar.openSnack} snackMessage={snackbar.snackMessage} />
      ) : (
        <></>
      )}
      <ListGridContainer>
        <ListGridV2
          listGridData={!isServicePlanTermsListLoading && servicePlanTermsListData ? servicePlanTermsListData : []}
          listGridDataTotal={
            !isServicePlanTermsListLoading && servicePlanTermsListData ? servicePlanTermsListData.length : 0
          }
          isHeaderLoading={isServicePlanTermsListLoading}
          isLoading={isServicePlanTermsListLoading}
          columns={currentColumns}
          fullWidthLoadingBars={true}
          handleSortChange={handleSortChange}
          selectedSort={sort}
        />
      </ListGridContainer>
    </PageStyled>
  );
};

export default ServicePlanTermsList;
