/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: PageContent Component SMP
 */

import React from 'react';
import styled from '@emotion/styled';

import {MenuBar, MenuBarProps} from '@viasat/insights-components';

interface PageContentProps extends MenuBarProps {
  children: JSX.Element;
}

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const PageContent: React.FC<PageContentProps> = ({
  children,
  getFullElementId,
  leftStack,
  rightStack,
  title,
  color,
  isSubtitleLoading,
  subtitle
}) => {
  return (
    <ContentContainer>
      <MenuBar
        getFullElementId={getFullElementId}
        title={title}
        subtitle={subtitle}
        leftStack={leftStack}
        rightStack={rightStack}
        children={children}
        color={color}
        isSubtitleLoading={isSubtitleLoading}
      />
      <span>{children}</span>
    </ContentContainer>
  );
};

export default PageContent;
