/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utilities for working with dates and times
 */

import {DateTime} from 'luxon';

export const DATE_MONTH_YEAR_FORMAT = 'yyyy-MMM-dd';
export const TIME_WITH_MINUTES_FORMAT = 'HH:mm';
export const TIME_WITH_MINUTES_SECONDS_FORMAT = 'HH:mm:ss';

export const STATUS_TOOLTIP_DATE_TIME_FORMAT = `${DATE_MONTH_YEAR_FORMAT} ${TIME_WITH_MINUTES_FORMAT}`;
export const STATUS_TOOLTIP_DURATION_FORMAT = "d'd' h'h' m'm'";

export const AUTO_REFRESH_DATE_TIME_FORMAT = `${DATE_MONTH_YEAR_FORMAT} ${TIME_WITH_MINUTES_SECONDS_FORMAT}`;

export const EXPORT_FILENAME_DATE_TIME_FORMAT = 'yyyyLLddHHmmss';

export const DATE_FULL_FORMAT = 'YYYY-MM-DD';

export const DATE_TIME_LOCAL_FORMAT_MINUTES = `${DATE_FULL_FORMAT} ${TIME_WITH_MINUTES_FORMAT}`;

export const DATE_TIME_FORMAT_FOR_DB = 'yyyy-MM-dd HH:mm:ss';

export const MONTH_DATE_YEAR_FORMAT = 'MM/dd/yyyy';

export const DATE_MONTH_YEAR_NUMBER_FORMAT = 'yyyy-MM-dd';
/**
 * Formats a DateTime object into a UTC string
 * @param dateTime DateTime object
 * @param fmt Format for string
 * @returns Formatted date/time string in UTC
 */
export const formatDateTimeInput = (dateTime: DateTime, fmt: string) => {
  return dateTime.toFormat(fmt);
};

/**
 * Formats a DateTime object into a UTC date/time string for auto refresh switch
 * @param date DateTime object
 * @returns Formatted date/time string in UTC for auto refresh switch
 */
export const formatDateTimeInputAsAutoRefresh = (date: DateTime) => {
  return formatDateTimeInput(date, AUTO_REFRESH_DATE_TIME_FORMAT);
};

/**
 * Formats a DateTime object into a UTC date string with abbreviated month name
 * @param date DateTime object
 * @returns Formatted date string in UTC  with abbreviated month name
 */
export const formatDateTimeInputAsDateMonthYear = (date: DateTime) => {
  return formatDateTimeInput(date, DATE_MONTH_YEAR_FORMAT);
};

/**
 * Formats a DateTime object into a date string for export file name
 * @param date DateTime object
 * @returns Formatted date string for export file name
 */
export const formatDateTimeInputAsExportFilename = (date: DateTime) => {
  return formatDateTimeInput(date, EXPORT_FILENAME_DATE_TIME_FORMAT);
};

/**
 * Formats date string to ISO date with hours and seconds
 * @param date - Date string
 * @returns Formatted date string with hours and seconds
 */
export const formatDateTimeInputToISOString = (date: string) => {
  return formatDateTimeInput(DateTime.fromISO(date), DATE_TIME_FORMAT_FOR_DB);
};

/**
 * Formats date string to Month Date format from Date Month format
 * @param date - Date string
 * @returns Formatted date in Month Date format
 */
export const formatDateTimeInputAsMonthDateYear = (date: string) => {
  const dateFormatted = formatDateTimeInput(DateTime.fromISO(date), DATE_MONTH_YEAR_NUMBER_FORMAT);
  return formatDateTimeInput(DateTime.fromISO(dateFormatted), MONTH_DATE_YEAR_FORMAT);
};

/**
 * Formats date string date month format
 * @param date - Date string
 * @returns Formatted date in Date month format
 */
export const formatDateTimeInputAsDateMonthYearNumber = (date: string) => {
  return formatDateTimeInput(DateTime.fromISO(date), DATE_MONTH_YEAR_NUMBER_FORMAT);
};
