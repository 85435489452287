/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Aircraft Details
 */
import React from 'react';
import {capitalize} from 'lodash';
import BaseTable, {AutoResizer} from 'react-base-table';
import {IApprovalDetail, ITaskData} from '../../store/queries/getRequestInformationQuery';
import {ReviewSection} from './ReviewInformationStyles';
import {formatTimeStamp, headerRowStyle, RowDataStyle} from './RequestHistory';
import styled from '@emotion/styled';
import {NEXT_BUTTON_DISABLED} from '../../theme/Colors';
import {WHITE} from '../common/theme/Colors';
import StatusComponent from '../common/elements/StatusContainer';
import {formatCamelCaseString} from '../../utils/app-utils';
interface IAircraftdetail {
  idPrefix: string;
  taskDetail: ITaskData | null;
  approvalDetail: IApprovalDetail;
  varAccountName: string;
}
export const AircraftDetailsTable = styled.div`
  width: 95vw;
  min-height: 15vh;
  max-height: 50vh;
  padding: 0 5px 10px 0px;
  margin-left: 20px;
  .BaseTable__header-row,
  .BaseTable__row {
    background-color: ${WHITE};
  }
  .BaseTable__table-main {
    outline: none;
  }
  .BaseTable__header-row {
    border-bottom: 1px solid;
    border-bottom-color: ${NEXT_BUTTON_DISABLED};
  }
`;

/**
 * method to format the row data
 * @returns formatted cell for each row information
 */
const Cell = ({columnIndex, cellData, rowIndex, column}) => {
  return (
    <RowDataStyle id={`reviewApprovalInfo__aircraftDetail--row${rowIndex}${columnIndex}`}>
      {cellData ? cellData : '--'}
    </RowDataStyle>
  );
};

const AircraftDetail: React.FC<IAircraftdetail> = ({idPrefix, taskDetail, approvalDetail, varAccountName}) => {
  const aircraftDetailColumns = [
    {
      key: 'status',
      dataKey: 'status',
      title: 'Status',
      resizable: false,
      width: 150,
      style: headerRowStyle,
      cellRenderer: ({cellData}) => <StatusComponent status={cellData} idPrefix="review-request-status" isGrid={true} />
    },
    {
      key: 'organization',
      dataKey: 'organization',
      title: 'Organization',
      resizable: false,
      width: 200,
      style: headerRowStyle
    },
    {
      key: 'serialNumer',
      dataKey: 'serialNumber',
      title: 'Aircraft SN',
      resizable: false,
      width: 150,
      style: headerRowStyle
    },
    {
      key: 'tailId',
      dataKey: 'tailId',
      title: 'Tail ID',
      resizable: false,
      width: 120,
      style: headerRowStyle
    },
    {
      key: 'varAccountName',
      dataKey: 'varAccountName',
      title: 'Value Added Reseller',
      resizable: false,
      width: 200,
      style: headerRowStyle
    },
    {
      key: 'requestType',
      dataKey: 'requestType',
      title: 'Request Type',
      resizable: false,
      width: 200,
      style: headerRowStyle
    },
    {
      key: 'requested',
      dataKey: 'requested',
      title: 'Requested',
      resizable: false,
      width: 130,
      style: headerRowStyle,
      cellRenderer: ({cellData, rowIndex, columnIndex}) => (
        <RowDataStyle id={`reviewApprovalInfo__aircraftDetail--row${rowIndex}${columnIndex}`}>
          {cellData ? formatTimeStamp(cellData) : '--'}
        </RowDataStyle>
      )
    },
    {
      key: 'requestor',
      dataKey: 'requestor',
      title: 'Requestor',
      resizable: false,
      width: 200,
      style: headerRowStyle
    }
  ];

  const requestorFirstName = approvalDetail.submittedBy.split('.')[0];

  var requestorLastName = approvalDetail.submittedBy.split('@')[0].split('.').pop();
  const aircraftDetailsData = [
    {
      status: approvalDetail.status,
      organization: taskDetail.make,
      serialNumber: taskDetail.serialNumber,
      tailId: taskDetail.tailId,
      varAccountName: varAccountName ? varAccountName : 'BizAv Retail', // Default VAR name when OEM has not chosen one.
      requestType: formatCamelCaseString(taskDetail.taskType),
      requested: approvalDetail.submittedTstamp,
      requestor: `${capitalize(requestorFirstName)} ${capitalize(requestorLastName)}`
    }
  ];
  return (
    <ReviewSection hasNoTop={true} hasNoBorder={true}>
      <AircraftDetailsTable>
        <AutoResizer>
          {({width, height}) => (
            <BaseTable
              id={`${idPrefix}__aircraftDetail`}
              fixed
              columns={aircraftDetailColumns}
              data={aircraftDetailsData}
              width={width}
              height={height}
              rowHeight={70}
              scrollToTop={100}
              style={{boxShadow: 'none'}}
              forceUpdateTable
              components={{
                TableCell: Cell
              }}
            />
          )}
        </AutoResizer>
      </AircraftDetailsTable>
    </ReviewSection>
  );
};

export default AircraftDetail;
