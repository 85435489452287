/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Equipment Information Form Hooks
 */

import {useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import {FieldValues, UseFormUnregister, UseFormWatch} from 'react-hook-form';

import {usePartNumbersFetch} from './customHooks';
import {getPartNumbersQuery} from '../store/queries/lookupQueries';

import {IConfigItems} from '../store/queries/getAllConfigItemsQuery';
import {EquipmentInfoState, IRouterInformation} from '../store/reducers/EquipmentInfoReducer';
import {FormField, FormFieldType} from '../components/common/elements/Form';

import {
  isExistingKaBandIp,
  validateConfigItemsExists,
  validateDropdownField,
  validateWarrantyEndDate
} from './validationUtils';

import {
  EquipmentType,
  KU_NETWORK,
  OTHER_ROUTER,
  VSR_ROUTER,
  ipv4Regex,
  macAddressRegex,
  routerIdPatternRegex,
  routerTypeList,
  serialNumberPatternRegex,
  serialNumberValidation,
  simNumberPatternRegex
} from '../components/common/constants';
import {kaBandIPAddressRequired} from './config';

/**
 * Builds equipment information form with PN & SN
 * @param watch react useForm - watch hook
 * @param aircraftNetwork network of the aircraft
 * @param equipmentInfo partNumber and serialNumber
 * @param confgItems Confguration Items of the aircraft
 * @returns Array of Equipment Form Fields
 */

export function useEquipmentInformationForm(
  watch: UseFormWatch<FieldValues>,
  aircraftNetwork: string,
  equipmentInfo: {partNumber: string; serialNumber: string},
  equipment: EquipmentType,
  configItems: IConfigItems[],
  objectIdPrefix?: string,
  existingEquipmentInfo?: {partNumber: string; serialNumber: string}
): {productDescription: string; equipmentFormFields: FormField[]} {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
        color: '#465967'
      }
    }
  };

  const partNumbersList = usePartNumbersFetch(equipmentInfo && equipment, aircraftNetwork, getPartNumbersQuery);

  if (!equipmentInfo) {
    return;
  }

  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const selectedPartNumber = watch(`${idPrefix + 'partNumber'}`);

  const partNumberParams = partNumbersList?.filter(partNumber => partNumber.viasatPartNumber === selectedPartNumber)[0];

  const serialNumberRegex = serialNumberValidation[selectedPartNumber];

  const productDescription = partNumberParams?.viasatPartNumberDescription;
  const serialNumberFormat = partNumberParams?.serialNumberFormat;
  const serialNumberFormatDescription = partNumberParams?.serialNumberFormatDescription;
  const serialNumberInput = watch(`${idPrefix + 'serialNumber'}`);

  const equipmentFormFields = [
    {
      id: `${idPrefix + 'partNumber'}`,
      label: 'Part Number',
      type: FormFieldType.SELECT,
      value: equipmentInfo.partNumber,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: partNumbersList?.map(partNumber => partNumber.viasatPartNumber),
      description: {title: 'Product Description', value: productDescription ? productDescription : '--'},
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Part Number')
      }
    },
    {
      id: `${idPrefix + 'serialNumber'}`,
      label: 'Serial Number',
      type: FormFieldType.INPUT,
      value: equipmentInfo.serialNumber,
      disabled: false,
      placeholder: serialNumberFormat ? `  eg. ${serialNumberFormat}` : '',
      description: serialNumberFormat ? {title: 'Serial Number Format', value: serialNumberFormat} : null,
      descriptionHelper: serialNumberFormatDescription ? serialNumberFormatDescription : '',
      rules: {
        required: true,
        pattern: {value: new RegExp(serialNumberRegex), message: 'Invalid Serial Number'},
        warning: validateConfigItemsExists(configItems, 'serialNumber', serialNumberInput, aircraftNetwork),
        validate: (value, formValues) => {
          if (existingEquipmentInfo)
            return (
              value !== existingEquipmentInfo?.serialNumber ||
              'Invalid Serial Number. Serial Number cannot be same as installed hardware.'
            );
          else return true;
        }
      }
    }
  ];
  return {productDescription, equipmentFormFields};
}

/**
 * Builds Modem information form with PN, SN, MAC Address/IP Address
 * @param watch react useForm - watch hook
 * @param aircraftNetwork network of the aircraft
 * @param modemInfo Modem Information
 * @param confgItems Confguration Items of the aircraft
 * @returns Array of Modem Form Fields
 */

export function useModemInformationForm(
  watch: UseFormWatch<FieldValues>,
  aircraftNetwork: string,
  modemInfo: {
    partNumber: string;
    serialNumber: string;
    ipAddress?: string;
    macAddress?: string;
    kaBandIpAddress?: string;
  },
  equipment: EquipmentType,
  configItems: IConfigItems[],
  varKaIpList: any,
  aircraftInfo: any,
  objectIdPrefix?: string,
  existingModemInfo?: {partNumber: string; serialNumber: string; ipAddress?: string; macAddress?: string}
): {productDescription: string; modemInfoFormFields: FormField[]} {
  const isKuAircraft = aircraftNetwork === KU_NETWORK;

  const baseModemInfo = useEquipmentInformationForm(
    watch,
    aircraftNetwork,
    modemInfo,
    equipment,
    configItems,
    objectIdPrefix,
    existingModemInfo
  );

  if (!modemInfo || !baseModemInfo) {
    return;
  }

  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const ipAddressInput = watch(`${idPrefix + 'ipAddress'}`);
  const macAddressInput = watch(`${idPrefix + 'macAddress'}`);
  const modemInfoFormFields: FormField[] = [
    ...baseModemInfo.equipmentFormFields,
    isKuAircraft
      ? {
          id: `${idPrefix + 'ipAddress'}`,
          label: 'IP Address',
          type: FormFieldType.INPUT,
          value: modemInfo.ipAddress,
          disabled: false,
          rules: {
            required: true,
            pattern: {value: new RegExp(ipv4Regex), message: 'Invalid IP Address'},
            warning: validateConfigItemsExists(configItems, 'ipAddress', ipAddressInput, aircraftNetwork)
          }
        }
      : {
          id: `${idPrefix + 'macAddress'}`,
          label: 'MAC Address',
          type: FormFieldType.INPUT,
          value: modemInfo.macAddress,
          disabled: false,
          rules: {
            required: true,
            pattern: {value: new RegExp(macAddressRegex), message: 'Invalid MAC Address'},
            warning: validateConfigItemsExists(configItems, 'macAddress', macAddressInput, aircraftNetwork),
            validate: (value, formValues) => {
              if (existingModemInfo)
                return (
                  (isKuAircraft ? value !== existingModemInfo?.ipAddress : value !== existingModemInfo?.macAddress) ||
                  'Invalid Mac Address. Mac Address cannot be same as installed hardware.'
                );
              else return true;
            },
            upperCaseInput: true
          }
        }
  ];
  if (!isKuAircraft) {
    modemInfoFormFields.push({
      id: `${idPrefix + 'kaBandIpAddress'}`,
      label: 'Ka IP Address',
      type: FormFieldType.INPUT,
      value: modemInfo.kaBandIpAddress,
      disabled: false,
      dropDownValues: [],
      description: null,
      rules: {
        required: kaBandIPAddressRequired,
        pattern: {value: new RegExp(ipv4Regex), message: 'Invalid IP Address'},
        validate: value => isExistingKaBandIp(value, aircraftInfo.tailId, varKaIpList)
      }
    });
  }
  return {productDescription: baseModemInfo.productDescription, modemInfoFormFields};
}

/**
 * Builds Router information form fields
 * @param watch react useForm - watch hook
 * @param unregister react useForm - unregister hook
 * @param routerInfo Router Information
 * @returns Array of Router Form Fields
 */

export function useRouterInfomationForm(
  watch: UseFormWatch<FieldValues>,
  unregister: UseFormUnregister<FieldValues>,
  routerInfo: IRouterInformation,
  objectIdPrefix?: string,
  existingRouterInfo?: IRouterInformation
): FormField[] {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
        color: '#465967'
      }
    }
  };

  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const selectedRouterType = watch(`${idPrefix + 'routerType'}`);
  const isVSRRouter = selectedRouterType === VSR_ROUTER;
  const isOtherRouter = selectedRouterType === OTHER_ROUTER;

  const routerTypeFormField: FormField[] = routerInfo
    ? [
        {
          id: `${idPrefix + 'routerType'}`,
          label: 'Router Type',
          type: FormFieldType.SELECT,
          value: routerInfo.routerType,
          disabled: false,
          menuProps: MenuProps,
          dropDownValues: routerTypeList,
          rules: {
            required: true,
            pattern: null,
            validate: (value, formValues) => validateDropdownField(value, 'Router Type')
          }
        }
      ]
    : null;

  const otherRouterTypeFormField: FormField[] = routerInfo
    ? [
        {
          id: `${idPrefix + 'otherRouterType'}`,
          label: ' ',
          type: FormFieldType.INPUT,
          value: routerInfo.otherRouterType,
          disabled: false,
          rules: {
            required: 'other router type is required',
            pattern: null,
            validate: (value, formValues) => {
              if (existingRouterInfo)
                return (
                  value !== existingRouterInfo.otherRouterType ||
                  'Invalid Router type. Router type cannot be same as installed hardware.'
                );
              else return true;
            }
          }
        }
      ]
    : [];

  const vsrRouterInforFormFields: FormField[] = routerInfo
    ? [
        {
          id: `${idPrefix + 'serialNumber'}`,
          label: 'Serial Number',
          type: FormFieldType.INPUT,
          value: routerInfo.serialNumber,
          disabled: false,
          rules: {
            required: isVSRRouter,
            pattern: {value: new RegExp(serialNumberPatternRegex), message: 'Invalid Serial Number'},
            validate: (value, formValues) => {
              if (existingRouterInfo)
                return (
                  value !== existingRouterInfo.serialNumber ||
                  'Invalid Serial Number. Serial Number cannot be same as installed hardware.'
                );
              else return true;
            }
          }
        },
        {
          id: `${idPrefix + 'routerId'}`,
          label: 'Router ID (Aerosphere)',
          type: FormFieldType.INPUT,
          value: routerInfo.routerId,
          disabled: false,
          rules: {
            required: isVSRRouter,
            pattern: {value: new RegExp(routerIdPatternRegex), message: 'Invalid Router ID'},
            validate: (value, formValues) => {
              if (existingRouterInfo)
                return (
                  value !== existingRouterInfo.routerId ||
                  'Invalid Router Id. Router Id cannot be same as installed hardware.'
                );
              else return true;
            }
          }
        },
        {
          id: `${idPrefix + 'simNumber'}`,
          label: 'SIM Number',
          type: FormFieldType.INPUT,
          value: routerInfo.simNumber,
          disabled: false,
          rules: {
            required: isVSRRouter,
            pattern: {value: new RegExp(simNumberPatternRegex), message: 'Invalid SIM Number'},
            validate: (value, formValues) => {
              if (existingRouterInfo)
                return (
                  value !== existingRouterInfo.simNumber ||
                  'Invalid SIM Number. SIM Number cannot be same as installed hardware.'
                );
              else return true;
            }
          }
        }
      ]
    : null;

  const routerInfoFormFields = routerInfo && routerTypeFormField;
  isVSRRouter && routerInfoFormFields?.push(...vsrRouterInforFormFields);
  isOtherRouter &&
    routerInfoFormFields?.push(...otherRouterTypeFormField) &&
    routerInfoFormFields?.push(...vsrRouterInforFormFields);
  //unregister fields on router type change
  const routerInfoFormFieldsStr = JSON.stringify(routerInfoFormFields);
  useEffect(() => {
    unregister('serialNumber');
    unregister('simNumber');
    unregister('routerId');
    unregister('otherRouterType');
  }, [routerInfoFormFieldsStr, unregister]);

  return routerInfoFormFields;
}
interface IKuWarrantyStartDateInput {
  modemWarrantyStartDate: string;
  antennaWarrantyStartDate: string;
  acuWarrantyStartDate: string;
  routerWarrantyStartDate: string;
}
interface IKuWarrantyEndDateInput {
  modemWarrantyEndDate: string;
  antennaWarrantyEndDate: string;
  acuWarrantyEndDate: string;
  routerWarrantyEndDate: string;
}

interface IKaWarrantyStartDateInput {
  modemWarrantyStartDate: string;
  antennaWarrantyStartDate: string;
  apsuWarrantyStartDate: string;
  fantrayWarrantyStartDate: string;
  routerWarrantyStartDate: string;
}

interface IKaWarrantyEndDateInput {
  modemWarrantyEndDate: string;
  antennaWarrantyEndDate: string;
  apsuWarrantyEndDate: string;
  fantrayWarrantyEndDate: string;
  routerWarrantyEndDate: string;
}

/**
 * Builds Warranty Extension Form fields
 * @param watch react useForm - watch hook
 * @param equipmentInfo Equipment Information
 * @returns Equipment Warranty Fields
 */
export function useWarrantyExtensionForm(
  watch: UseFormWatch<FieldValues>,
  equipmentInfo: EquipmentInfoState
): {
  kaWarrantyFormFields: FormField[];
  kuWarrantyFormFields: FormField[];
} {
  const kaAntennaInfo = equipmentInfo.kaAntenna;
  const kaModemInfo = equipmentInfo.kaModem;
  const kuAntennaInfo = equipmentInfo.kuAntenna;
  const kuModemInfo = equipmentInfo.kuModem;
  const apsuInfo = equipmentInfo.apsu;
  const acuInfo = equipmentInfo.acu;
  const fanTrayInfo = equipmentInfo.fanTray;
  const routerInfo = equipmentInfo.router;
  const warrantyEndDate = watch('commonWarrantyDate');
  const [kuModemWarrantyEndDate, setKuModemWarrantyEndDate] = useState(null);
  const [kuAntennaWarrantyEndDate, setKuAntennaWarrantyEndDate] = useState(null);
  const [kuAcuWarrantyEndDate, setKuAcuWarrantyEndDate] = useState(null);
  const [kuRouterWarrantyEndDate, setKuRouterWarrantyEndDate] = useState(null);

  const [kaModemWarrantyEndDate, setKaModemWarrantyEndDate] = useState(null);
  const [kaAntennaWarrantyEndDate, setKaAntennaWarrantyEndDate] = useState(null);
  const [kaApsuWarrantyEndDate, setKaApsuWarrantyEndDate] = useState(null);
  const [kaFantrayWarrantyEndDate, setKaFantrayWarrantyEndDate] = useState(null);
  const [kaRouterWarrantyEndDate, setKaRouterWarrantyEndDate] = useState(null);

  const intialKuWarrantyStartDateValues: IKuWarrantyStartDateInput = {
    modemWarrantyStartDate: kuModemInfo ? kuModemInfo.warrantyStartDate : '',
    antennaWarrantyStartDate: kuAntennaInfo ? kuAntennaInfo.warrantyStartDate : '',
    acuWarrantyStartDate: acuInfo ? acuInfo.warrantyStartDate : '',
    routerWarrantyStartDate: routerInfo ? routerInfo.warrantyStartDate : ''
  };
  const intialKuWarrantyEndDateValues: IKuWarrantyEndDateInput = {
    modemWarrantyEndDate: kuModemInfo ? kuModemInfo.warrantyEndDate : '',
    antennaWarrantyEndDate: kuAntennaInfo ? kuAntennaInfo.warrantyEndDate : '',
    acuWarrantyEndDate: acuInfo ? acuInfo.warrantyEndDate : '',
    routerWarrantyEndDate: routerInfo ? routerInfo.warrantyEndDate : ''
  };
  const intialKaWarrantyStartDateValues: IKaWarrantyStartDateInput = {
    modemWarrantyStartDate: kaModemInfo ? kaModemInfo.warrantyStartDate : '',
    antennaWarrantyStartDate: kaAntennaInfo ? kaAntennaInfo.warrantyStartDate : '',
    apsuWarrantyStartDate: apsuInfo ? apsuInfo.warrantyStartDate : '',
    fantrayWarrantyStartDate: fanTrayInfo ? fanTrayInfo.warrantyStartDate : '',
    routerWarrantyStartDate: routerInfo ? routerInfo.warrantyStartDate : ''
  };
  const intialKaWarrantyEndDateValues: IKaWarrantyEndDateInput = {
    modemWarrantyEndDate: kaModemInfo ? kaModemInfo.warrantyEndDate : '',
    antennaWarrantyEndDate: kaAntennaInfo ? kaAntennaInfo.warrantyEndDate : '',
    apsuWarrantyEndDate: apsuInfo ? apsuInfo.warrantyEndDate : '',
    fantrayWarrantyEndDate: fanTrayInfo ? fanTrayInfo.warrantyEndDate : '',
    routerWarrantyEndDate: routerInfo ? routerInfo.warrantyEndDate : ''
  };

  const kuWarrantyFormFields: FormField[] = [];

  if (kuModemInfo) {
    kuWarrantyFormFields.push({
      id: 'kuModemWarrantyStartDate',
      label: 'Modem Warranty Start Date',
      type: FormFieldType.DATE,
      value: intialKuWarrantyStartDateValues.modemWarrantyStartDate,
      disabled: true,
      rules: {
        required: false,
        pattern: null
      }
    });
    kuWarrantyFormFields.push({
      id: 'kuModemWarrantyEndDate',
      label: 'Modem Warranty End Date',
      type: FormFieldType.DATE,
      value: kuModemWarrantyEndDate ? kuModemWarrantyEndDate : intialKuWarrantyEndDateValues.modemWarrantyEndDate,
      setDate: setKuModemWarrantyEndDate,
      disabled: !isEmpty(warrantyEndDate),
      rules: {
        required: isEmpty(warrantyEndDate),
        pattern: null,
        validate: isEmpty(warrantyEndDate)
          ? (value, formValues) => validateWarrantyEndDate(value, intialKuWarrantyEndDateValues.modemWarrantyEndDate)
          : null
      }
    });
  }
  if (kuAntennaInfo) {
    kuWarrantyFormFields.push({
      id: 'kuAntennaWarrantyStartDate',
      label: 'Antenna Warranty Start Date',
      type: FormFieldType.DATE,
      value: intialKuWarrantyStartDateValues.antennaWarrantyStartDate,
      disabled: true,
      rules: {
        required: false,
        pattern: null
      }
    });
    kuWarrantyFormFields.push({
      id: 'kuAntennaWarrantyEndDate',
      label: 'Antenna Warranty End Date',
      type: FormFieldType.DATE,
      value: kuAntennaWarrantyEndDate ? kuAntennaWarrantyEndDate : intialKuWarrantyEndDateValues.antennaWarrantyEndDate,
      setDate: setKuAntennaWarrantyEndDate,
      disabled: !isEmpty(warrantyEndDate),
      rules: {
        required: isEmpty(warrantyEndDate),
        pattern: null,
        validate: isEmpty(warrantyEndDate)
          ? (value, formValues) => validateWarrantyEndDate(value, intialKuWarrantyEndDateValues.antennaWarrantyEndDate)
          : null
      }
    });
  }

  if (acuInfo) {
    kuWarrantyFormFields.push({
      id: 'kuACUWarrantyStartDate',
      label: 'ACU Warranty Start Date',
      type: FormFieldType.DATE,
      value: intialKuWarrantyStartDateValues.acuWarrantyStartDate,
      disabled: true,
      rules: {
        required: false,
        pattern: null
      }
    });
    kuWarrantyFormFields.push({
      id: 'kuAcuWarrantyEndDate',
      label: 'ACU Warranty End Date',
      type: FormFieldType.DATE,
      value: kuAcuWarrantyEndDate ? kuAcuWarrantyEndDate : intialKuWarrantyEndDateValues.acuWarrantyEndDate,
      setDate: setKuAcuWarrantyEndDate,
      disabled: !isEmpty(warrantyEndDate),
      rules: {
        required: isEmpty(warrantyEndDate),
        pattern: null,
        validate: isEmpty(warrantyEndDate)
          ? (value, formValues) => validateWarrantyEndDate(value, intialKuWarrantyEndDateValues.acuWarrantyEndDate)
          : null
      }
    });
  }

  if (routerInfo) {
    kuWarrantyFormFields.push({
      id: 'kuRouterWarrantyStartDate',
      label: 'Router Warranty Start Date',
      type: FormFieldType.DATE,
      value: intialKuWarrantyStartDateValues.routerWarrantyStartDate,
      disabled: true,
      rules: {
        required: false,
        pattern: null
      }
    });
    kuWarrantyFormFields.push({
      id: 'kuRouterWarrantyEndDate',
      label: 'Router Warranty End Date',
      type: FormFieldType.DATE,
      value: kuRouterWarrantyEndDate ? kuRouterWarrantyEndDate : intialKuWarrantyEndDateValues.routerWarrantyEndDate,
      setDate: setKuRouterWarrantyEndDate,
      disabled: !isEmpty(warrantyEndDate),
      rules: {
        required: isEmpty(warrantyEndDate),
        pattern: null,
        validate: isEmpty(warrantyEndDate)
          ? (value, formValues) => validateWarrantyEndDate(value, intialKuWarrantyEndDateValues.routerWarrantyEndDate)
          : null
      }
    });
  }

  const kaWarrantyFormFields: FormField[] = [];
  if (kaModemInfo && kaModemInfo.serialNumber) {
    kaWarrantyFormFields.push({
      id: 'kaModemWarrantyStartDate',
      label: 'Modem Warranty Start Date',
      type: FormFieldType.DATE,
      value: intialKaWarrantyStartDateValues.modemWarrantyStartDate,
      disabled: true,
      rules: {
        required: false,
        pattern: null
      }
    });

    kaWarrantyFormFields.push({
      id: 'kaModemWarrantyEndDate',
      label: 'Modem Warranty End Date',
      type: FormFieldType.DATE,
      value: kaModemWarrantyEndDate ? kaModemWarrantyEndDate : intialKaWarrantyEndDateValues.modemWarrantyEndDate,
      setDate: setKaModemWarrantyEndDate,
      disabled: !isEmpty(warrantyEndDate),
      rules: {
        required: isEmpty(warrantyEndDate),
        pattern: null,
        validate: isEmpty(warrantyEndDate)
          ? (value, formValues) => validateWarrantyEndDate(value, intialKaWarrantyEndDateValues.modemWarrantyEndDate)
          : null
      }
    });
  }
  if (kaAntennaInfo && kaAntennaInfo.serialNumber) {
    kaWarrantyFormFields.push({
      id: 'kaAntennaWarrantyStartDate',
      label: 'Antenna Warranty Start Date',
      type: FormFieldType.DATE,
      value: intialKaWarrantyStartDateValues.antennaWarrantyStartDate,
      disabled: true,
      rules: {
        required: false,
        pattern: null
      }
    });

    kaWarrantyFormFields.push({
      id: 'kaAntennaWarrantyEndDate',
      label: 'Antenna Warranty End Date',
      type: FormFieldType.DATE,
      value: kaAntennaWarrantyEndDate ? kaAntennaWarrantyEndDate : intialKaWarrantyEndDateValues.antennaWarrantyEndDate,
      setDate: setKaAntennaWarrantyEndDate,
      disabled: !isEmpty(warrantyEndDate),
      rules: {
        required: isEmpty(warrantyEndDate),
        pattern: null,
        validate: isEmpty(warrantyEndDate)
          ? (value, formValues) => validateWarrantyEndDate(value, intialKaWarrantyEndDateValues.antennaWarrantyEndDate)
          : null
      }
    });
  }
  if (apsuInfo && apsuInfo.serialNumber) {
    kaWarrantyFormFields.push({
      id: 'kaApsuWarrantyStartDate',
      label: 'APSU Warranty Start Date',
      type: FormFieldType.DATE,
      value: intialKaWarrantyStartDateValues.apsuWarrantyStartDate,
      disabled: true,
      rules: {
        required: false,
        pattern: null
      }
    });
    kaWarrantyFormFields.push({
      id: 'kaApsuWarrantyEndDate',
      label: 'APSU Warranty End Date',
      type: FormFieldType.DATE,
      value: kaApsuWarrantyEndDate ? kaApsuWarrantyEndDate : intialKaWarrantyEndDateValues.apsuWarrantyEndDate,
      setDate: setKaApsuWarrantyEndDate,
      disabled: !isEmpty(warrantyEndDate),
      rules: {
        required: isEmpty(warrantyEndDate),
        pattern: null,
        validate: isEmpty(warrantyEndDate)
          ? (value, formValues) => validateWarrantyEndDate(value, intialKaWarrantyEndDateValues.apsuWarrantyEndDate)
          : null
      }
    });
  }
  if (fanTrayInfo) {
    kaWarrantyFormFields.push({
      id: 'kaFantrayWarrantyStartDate',
      label: 'Fan Tray Warranty Start Date',
      type: FormFieldType.DATE,
      value: intialKaWarrantyStartDateValues.fantrayWarrantyStartDate,
      disabled: true,
      rules: {
        required: false,
        pattern: null
      }
    });
    kaWarrantyFormFields.push({
      id: 'kaFantrayWarrantyEndDate',
      label: 'Fan Tray Warranty End Date',
      type: FormFieldType.DATE,
      value: kaFantrayWarrantyEndDate ? kaFantrayWarrantyEndDate : intialKaWarrantyEndDateValues.fantrayWarrantyEndDate,
      setDate: setKaFantrayWarrantyEndDate,
      disabled: !isEmpty(warrantyEndDate),
      rules: {
        required: isEmpty(warrantyEndDate),
        pattern: null,
        validate: isEmpty(warrantyEndDate)
          ? (value, formValues) => validateWarrantyEndDate(value, intialKaWarrantyEndDateValues.fantrayWarrantyEndDate)
          : null
      }
    });
  }

  if (routerInfo) {
    kaWarrantyFormFields.push({
      id: 'kaRouterWarrantyStartDate',
      label: 'Router Warranty Start Date',
      type: FormFieldType.DATE,
      value: intialKaWarrantyStartDateValues.routerWarrantyStartDate,
      disabled: true,
      rules: {
        required: false,
        pattern: null
      }
    });
    kaWarrantyFormFields.push({
      id: 'kaRouterWarrantyEndDate',
      label: 'Router Warranty End Date',
      type: FormFieldType.DATE,
      value: kaRouterWarrantyEndDate ? kaRouterWarrantyEndDate : intialKaWarrantyEndDateValues.routerWarrantyEndDate,
      setDate: setKaRouterWarrantyEndDate,
      disabled: !isEmpty(warrantyEndDate),
      rules: {
        required: isEmpty(warrantyEndDate),
        pattern: null,
        validate: isEmpty(warrantyEndDate)
          ? (value, formValues) => validateWarrantyEndDate(value, intialKaWarrantyEndDateValues.routerWarrantyEndDate)
          : null
      }
    });
  }
  return {kaWarrantyFormFields, kuWarrantyFormFields};
}
