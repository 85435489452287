/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Router Information
 */
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {isNil} from 'lodash';
import {useForm} from 'react-hook-form';

import {useStore} from '../../store/Store';
import {EquipmentInfoAction, IRouterInformation} from '../../store/reducers/EquipmentInfoReducer';
import {AppAction} from '../../store/reducers/AppReducer';

import useFetchV2 from '../../utils/useFetchV2';
import {updateRouterInformation} from '../../store/queries/newInstall/equipmentInfoQuery';
import {useRouterInfomationForm} from '../../utils/equipmentInformationFormHook';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import FormComponent from '../common/elements/Form';

import {
  BottomHorizontalRule,
  HeaderHorizontalRule,
  HeaderLabel,
  LatestRevision,
  NextButton,
  PageDescription,
  StyledLink
} from '../CommonStyles';

import {SELECT_AN_OPTION} from '../common/constants';
import {showUserManuals} from '../../utils/config';

const ID_PREFIX = 'routerInformation';

interface RouterInformationPageProps {
  title: string;
}

const RouterInformationPage: React.FC<RouterInformationPageProps> = ({title}) => {
  const location = useLocation();

  const RouterInfoForm = () => {
    const {store, dispatch} = useStore();
    const navigate = useNavigate();
    const {aircraftInfo} = store;
    const routerInfo = store.equipmentInfo?.router;
    const taskId = store.equipmentInfo?.taskId;
    const breadcrumbInfo = store.app.breadcrumb;
    const initialValues = routerInfo
      ? routerInfo
      : {
          routerType: SELECT_AN_OPTION,
          serialNumber: null,
          routerId: null,
          simNumber: null,
          otherRouterType: null
        };

    const {handleSubmit, formState, watch, control, unregister} = useForm();

    const routerInfoFormFields = useRouterInfomationForm(watch, unregister, initialValues);

    const [routerFormData, setRouterFormData] = useState<IRouterInformation>(null);

    const queryParams = useMemo(() => {
      if (isNil(routerFormData) || isNil(taskId)) return;
      return {
        ...routerFormData,
        aircraftSerialNumber: aircraftInfo.serialNumber,
        tailId: aircraftInfo.tailId,
        make: aircraftInfo.make,
        model: aircraftInfo.model,
        network: aircraftInfo.network,
        taskId
      };
      // eslint-disable-next-line
    }, [routerFormData, taskId]);

    const {isLoading: updationInProgress, data: updateInfomration} = useFetchV2(
      {route: updateRouterInformation.route, params: queryParams},
      updateRouterInformation.transform
    );
    useEffect(() => {
      if (!updationInProgress && !isNil(updateInfomration)) {
        dispatch({type: EquipmentInfoAction.SET_ROUTER_INFO, payload: routerFormData});
        dispatch({
          type: AppAction.SET_BREADCRUMB,
          payload: breadcrumbInfo.filter(breadCrumb => breadCrumb.linkPath === '/new-terminal-installation')
        });
        navigate('/new-terminal-installation');
      }
    }, [updationInProgress, updateInfomration, routerFormData, breadcrumbInfo, dispatch, navigate]);

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Enter Router Information</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--description`}>
          Use your mobile device to scan the QR code on the Router, or manually enter the router information for the new
          terminal.
        </PageDescription>
        <BottomHorizontalRule />
        <FormComponent
          idPrefix={ID_PREFIX}
          id="new-router-information-form"
          formFields={routerInfoFormFields}
          setFormData={setRouterFormData}
          formControlMethods={{
            handleSubmit,
            formState,
            watch,
            control
          }}
        ></FormComponent>
        {showUserManuals ? (
          <>
            <BottomHorizontalRule />
            <LatestRevision id={`${ID_PREFIX}--latestRevisionTitle`}>
              View latest revision of the Viasat Router Operations and Test Manual here:
            </LatestRevision>
            <StyledLink id={`${ID_PREFIX}--installProcedureLink`} href="#">
              Router Installation Procedures
            </StyledLink>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton id={`${ID_PREFIX}--nextButton`} form="new-router-information-form" disabled={false}>
        <span className="next-button-text">Next</span>
        <ArrowForwardIcon className="next-arrow" />
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<RouterInfoForm />} actionButtons={<ActionButtons />} />;
};

export default RouterInformationPage;
