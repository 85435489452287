/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Modem Information
 */
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {useStore} from '../../store/Store';
import {EquipmentInfoAction} from '../../store/reducers/EquipmentInfoReducer';
import {AppAction} from '../../store/reducers/AppReducer';

import {updateKuModemInformation} from '../../store/queries/newInstall/equipmentInfoQuery';
import {updateKaModemInformation} from '../../store/queries/newInstall/equipmentInfoQuery';

import {useEquipmentUpdate} from '../../utils/customHooks';
import {useModemInformationForm} from '../../utils/equipmentInformationFormHook';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormComponent from '../common/elements/Form';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';

import {
  BottomHorizontalRule,
  HeaderHorizontalRule,
  HeaderLabel,
  LatestRevision,
  NextButton,
  PageDescription,
  StyledLink
} from '../CommonStyles';

import {KU_NETWORK, EquipmentType, SELECT_AN_OPTION} from '../common/constants';
import {showUserManuals} from '../../utils/config';

const ID_PREFIX = 'modemInformation';

interface ModemProps {
  title: string;
  aircraftSerialNumber?: string;
}

interface IFormInput {
  partNumber: string;
  serialNumber: string;
  macAddress?: string;
  ipAddress?: string;
  kaBandIpAddress?: string;
}

const ModemInformation: React.FC<ModemProps> = ({title, aircraftSerialNumber}) => {
  const location = useLocation();

  const ModemInfoForm = () => {
    const {store, dispatch} = useStore();

    const [modemFormData, setModemFormData] = useState(null);
    const {aircraftInfo} = store;
    const {aircraftNetwork} = aircraftInfo;
    const {varKaIpList} = store.app;
    const taskId = store.openTasksInfo.newTask?.taskId;

    const isKuAircraft = aircraftNetwork === KU_NETWORK;

    const modemInfo = isKuAircraft ? store.equipmentInfo.kuModem : store.equipmentInfo.kaModem;
    const modemConfigItems = store.app.configItems?.['Modem'] ? store.app.configItems['Modem'] : [];
    const breadcrumbInfo = store.app.breadcrumb;

    const {handleSubmit, formState, watch, control} = useForm();

    const initialValues: IFormInput = modemInfo
      ? modemInfo
      : {
          partNumber: SELECT_AN_OPTION,
          serialNumber: '',
          macAddress: '',
          ipAddress: '',
          kaBandIpAddress: ''
        };

    const {productDescription, modemInfoFormFields} = useModemInformationForm(
      watch,
      aircraftNetwork,
      initialValues,
      EquipmentType.MODEM,
      modemConfigItems,
      varKaIpList,
      aircraftInfo
    );
    // update EquipmentInformation table and right panel with submitted form data
    useEquipmentUpdate<IFormInput>(
      taskId,
      modemFormData,
      isKuAircraft ? updateKuModemInformation : updateKaModemInformation,
      '/new-terminal-installation',
      () => {
        dispatch({
          type: isKuAircraft ? EquipmentInfoAction.SET_KU_MODEM_INFO : EquipmentInfoAction.SET_KA_MODEM_INFO,
          payload: {productDescription, ...modemFormData}
        });
        dispatch({
          type: isKuAircraft ? EquipmentInfoAction.SET_KA_MODEM_INFO : EquipmentInfoAction.SET_KU_MODEM_INFO,
          payload: null
        });
        if (isKuAircraft) {
          dispatch({
            type: EquipmentInfoAction.SET_APSU_INFO,
            payload: null
          });
          dispatch({
            type: EquipmentInfoAction.SET_FAN_TRAY_INFO,
            payload: null
          });
          dispatch({
            type: EquipmentInfoAction.SET_KA_MODEM_INFO,
            payload: null
          });
          dispatch({
            type: EquipmentInfoAction.SET_KA_ANTENNA_INFO,
            payload: null
          });
        }
        dispatch({
          type: AppAction.SET_BREADCRUMB,
          payload: breadcrumbInfo.filter(breadCrumb => breadCrumb.linkPath === '/new-terminal-installation')
        });
      },
      aircraftInfo
    );
    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Enter Modem Details</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--description`}>
          Use your mobile device to scan the QR code on the Modem, or manually enter the modem information for the new
          terminal.
        </PageDescription>
        <BottomHorizontalRule />
        <FormComponent
          idPrefix={ID_PREFIX}
          id="new-modem-information-form"
          formFields={modemInfoFormFields}
          setFormData={setModemFormData}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
        {showUserManuals ? (
          <>
            <BottomHorizontalRule />
            <LatestRevision id={`${ID_PREFIX}--latestRevisionTitle`}>
              View latest revision of the Viasat Modem Operations and Test Manual here:
            </LatestRevision>
            <StyledLink href="#" id={`${ID_PREFIX}--installProcedureLink`}>
              Modem Installation Procedures
            </StyledLink>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton id={`${ID_PREFIX}--nextButton`} form="new-modem-information-form" disabled={false}>
        <span className="next-button-text">Next</span>
        <ArrowForwardIcon className="next-arrow" />
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<ModemInfoForm />} actionButtons={<ActionButtons />} />;
};

export default ModemInformation;
