/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Request Details
 */
import React from 'react';
import BaseTable, {AutoResizer} from 'react-base-table';
import {ReviewItemSubTitle, ReviewItemTitle, ReviewSection} from './ReviewInformationStyles';
import {IApprovalDetail} from '../../store/queries/getRequestInformationQuery';
import styled from '@emotion/styled';
import {POPUP_TEXT_COLOR, WHITE} from '../common/theme/Colors';
import moment from 'moment';
import {DATE_FULL_FORMAT} from '../../utils/dateTimeUtils';
import {NEXT_BUTTON_DISABLED, TABLE_ROW_COLOR} from '../../theme/Colors';
import StatusComponent from '../common/elements/StatusContainer';

export const RequestHistoryTable = styled.div<{minHeight: number}>`
  width: 90vw;
  min-height: ${props => props.minHeight}vh;
  max-height: 50vh;
  margin-bottom: 50px;
  margin-left: 32px;
  padding: 0 5px 20px 0px;
  .BaseTable__header-row,
  .BaseTable__row {
    background-color: ${WHITE};
  }
  .BaseTable__table-main {
    outline: none;
  }
  .BaseTable__header-row {
    border-bottom: 1px solid;
    border-bottom-color: ${NEXT_BUTTON_DISABLED};
  }
`;

export const RowDataStyle = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${POPUP_TEXT_COLOR};
`;

interface IRequestHistory {
  idPrefix: string;
  requestHistory: any;
  approvalDetail: IApprovalDetail;
}
/**
 * method to format the row data
 * @returns formatted cell for each row information
 */
export const Cell = ({columnIndex, cellData, rowIndex, column}) => {
  return (
    <RowDataStyle id={`reviewApprovalInfo__reviewHistory--row${rowIndex}${columnIndex}`}>
      {cellData ? cellData : '--'}
    </RowDataStyle>
  );
};

/**
 * method to format the date and time stamp
 */
export const formatTimeStamp = data => {
  return moment.utc(data).format(DATE_FULL_FORMAT);
};

export const headerRowStyle = {color: TABLE_ROW_COLOR, fontSize: '16px'};

const RequestHistory: React.FC<IRequestHistory> = ({idPrefix, requestHistory}) => {
  const requestHistoryColumns = [
    {
      key: 'status',
      dataKey: 'status',
      title: 'Status',
      resizable: false,
      width: 220,
      style: headerRowStyle,
      cellRenderer: ({cellData}) => (
        <StatusComponent status={cellData} idPrefix="review-request-history-status" isGrid={true} />
      )
    },
    {
      key: 'createdBy',
      dataKey: 'createdBy',
      title: 'Updated By',
      resizable: false,
      width: 250,
      style: headerRowStyle
    },
    {
      key: 'createdTstamp',
      dataKey: 'createdTstamp',
      title: 'Update',
      resizable: false,
      width: 150,
      style: headerRowStyle,
      cellRenderer: ({cellData, rowIndex, columnIndex}) => (
        <RowDataStyle id={`reviewApprovalInfo__reviewHistory--row${rowIndex}${columnIndex}`}>
          {cellData ? formatTimeStamp(cellData) : '--'}
        </RowDataStyle>
      )
    },
    {
      key: 'description',
      dataKey: 'description',
      title: 'Message',
      resizable: false,
      width: 450,
      style: headerRowStyle
    }
  ];

  return (
    <ReviewSection>
      <ReviewItemTitle>Request History</ReviewItemTitle>
      <ReviewItemSubTitle>A chronological record of the request's history.</ReviewItemSubTitle>

      <RequestHistoryTable minHeight={requestHistory.length > 2 ? 35 : 25}>
        <AutoResizer>
          {({width, height}) => (
            <BaseTable
              fixed
              columns={requestHistoryColumns}
              data={requestHistory}
              width={width}
              height={height}
              rowHeight={70}
              scrollToTop={100}
              style={{boxShadow: 'none'}}
              forceUpdateTable
              components={{
                TableCell: Cell
              }}
            />
          )}
        </AutoResizer>
      </RequestHistoryTable>
    </ReviewSection>
  );
};

export default RequestHistory;
