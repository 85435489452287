/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Section Alert Message Component
 */
import styled from '@emotion/styled';
import {Warning} from '@mui/icons-material';
import {BAR_SERVICE_MESSAGE_CONTAINER, CANCEL_ICON_COLOR, LABEL_COLOR} from '../../theme/Colors';

const SectionAlertMessageDiv = styled.div`
  height: 24px;
  width: auto;
  padding: 16px;
  background: ${BAR_SERVICE_MESSAGE_CONTAINER};
  color: ${LABEL_COLOR};
  border-radius: 8px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  span {
    margin-left: 8px;
    margin-top: 2px;
  }
`;

interface ISectionAlertMessageProps {
  idPrefix: string;
  message: string;
}

const SectionAlertMessage = ({idPrefix, message}: ISectionAlertMessageProps) => {
  return (
    <SectionAlertMessageDiv id={`${idPrefix}--warningMsg`}>
      <Warning sx={{color: CANCEL_ICON_COLOR}} />
      <span>{message}</span>
    </SectionAlertMessageDiv>
  );
};

export default SectionAlertMessage;
