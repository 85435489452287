/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add New Router Type in Lookup Table
 *
 */
import React, {useMemo, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {AddForm, AddFormHeader, DrawerButton, ButtonContainer, FormContainer} from '../LookupTableStyles';
import {addRouterTypeQuery, updateRouterTypeQuery} from '../../../../store/queries/lookupQueries';
import {SideDrawerProps, togglePanel, handleBackdropClick} from '../lookupTableUtils';
import {useStore} from '../../../../store/Store';
import {AppAction} from '../../../../store/reducers/AppReducer';
import {LookupsListAction} from '../../../../store/reducers/LookupsReducer';
import FormComponent, {FormField, FormFieldType} from '../../../common/elements/Form';
import useFetchV2 from '../../../../utils/useFetchV2';

const ID_PREFIX = 'routerType';

interface IFormInput {
  routerType: string;
  serialNumber: string;
  aerosphereId: string;
  simCardNumber: string;
  notes: string;
}

const AddRouterType: React.FC<SideDrawerProps> = ({openPanel, setOpenPanel, panelData, isUpdate}) => {
  const {dispatch} = useStore();
  const [newRouterTypeInfo, setNewRouterTypeInfo] = useState<any>(null);
  const [updateRouterTypeInfo, setUpdateRouterTypeInfo] = useState<any>(null);
  let initialValues: IFormInput = {
    routerType: '',
    serialNumber: '',
    aerosphereId: '',
    simCardNumber: '',
    notes: ''
  };
  if (isUpdate) initialValues = panelData;

  const InputProps = {
    style: {
      maxHeight: 200,
      width: 290,
      height: 70,
      color: '#465967',
      fontFamily: 'Source Sans Pro'
    }
  };

  const routerTypeFormFields: FormField[] = [
    {
      id: 'routerType',
      label: 'Router Type',
      type: FormFieldType.INPUT,
      value: initialValues.routerType,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'serialNumber',
      label: 'Serial Number',
      type: FormFieldType.INPUT,
      value: initialValues.serialNumber,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'viasatPartNumberDescription',
      label: 'Aerosphere ID',
      type: FormFieldType.INPUT,
      value: initialValues.aerosphereId,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'simCardNumber',
      label: 'SIM Card Number',
      type: FormFieldType.INPUT,
      value: initialValues.simCardNumber,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'notes',
      label: 'Notes',
      type: FormFieldType.TEXTFIELD,
      value: initialValues.notes,
      disabled: false,
      inputProps: InputProps,
      isMultiline: true,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  const {handleSubmit, formState, unregister, reset, watch, control} = useForm();

  const addQueryParams = useMemo(() => {
    if (newRouterTypeInfo && !newRouterTypeInfo.routerType) return;
    return newRouterTypeInfo;
  }, [newRouterTypeInfo]);

  const updateQueryParams = useMemo(() => {
    if (isUpdate && updateRouterTypeInfo && !updateRouterTypeInfo.routerType) return;
    return updateRouterTypeInfo;
  }, [updateRouterTypeInfo, isUpdate]);

  const {isLoading: addInProgress, data: addNewRouterTypeInfo} = useFetchV2(
    {route: addRouterTypeQuery.route, params: addQueryParams},
    addRouterTypeQuery.transform
  );
  const {isLoading: updateInProgress, data: updatedRouterTypeInfo} = useFetchV2(
    {route: updateRouterTypeQuery(panelData?.id).route, params: updateQueryParams},
    updateRouterTypeQuery(panelData?.id).transform
  );
  const onCancelClick = () => {
    unregister();
    reset(
      {routerType: '', serialNumber: '', aerosphereId: '', simCardNumber: '', notes: ''},
      {
        keepValues: false
      }
    );
    setOpenPanel(false);
  };

  const handleOpen = () => {
    togglePanel(true);
    setOpenPanel(true);
  };

  const handleClose = () => {
    if (localStorage.getItem('backdropClick') === 'true') {
      localStorage.setItem('backdropClick', 'false');
      return;
    }
    togglePanel(false);
    setOpenPanel(false);
  };

  useEffect(() => {
    if (addInProgress) return;
    if (addNewRouterTypeInfo) {
      setOpenPanel(false);
      dispatch({
        type: LookupsListAction.SET_NEW_ROW_COUNT,
        payload: 1
      });
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'New router type entry added.'}
      });
    }
    //eslint-disable-next-line
  }, [addInProgress, addNewRouterTypeInfo]);

  useEffect(() => {
    if (updateInProgress) return;
    if (updatedRouterTypeInfo) {
      setOpenPanel(false);
      dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: panelData});
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'Router Type data updated.'}
      });
    }
    //eslint-disable-next-line
  }, [updateInProgress, updatedRouterTypeInfo]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={openPanel}
        onOpen={handleOpen}
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
      >
        <AddForm>
          <AddFormHeader id={`${ID_PREFIX}--routerTypeListTitle`}>ADD NEW ROUTER TYPE INFO</AddFormHeader>
          <FormContainer>
            <FormComponent
              id="add-router-type-form"
              formFields={routerTypeFormFields}
              setFormData={!isUpdate ? setNewRouterTypeInfo : setUpdateRouterTypeInfo}
              formControlMethods={{handleSubmit, formState, watch, control}}
              idPrefix={ID_PREFIX}
            ></FormComponent>
          </FormContainer>
          <ButtonContainer>
            <DrawerButton id={`${ID_PREFIX}-cancel-button`} className="cancel-button" onClick={onCancelClick}>
              <span className="button-text">Cancel</span>
            </DrawerButton>
            <DrawerButton
              form="add-router-type-form"
              id={`${ID_PREFIX}-add-button`}
              className="add-button"
              onClick={() => null}
            >
              <span className="button-text">{!isUpdate ? 'Add' : 'Update'}</span>
            </DrawerButton>
          </ButtonContainer>
        </AddForm>
      </SwipeableDrawer>
    </>
  );
};

export default AddRouterType;
