/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description - In Progress Status icon
 */

import React from 'react';

interface InProgressStatusIconProps {
  id: string;
}

const InProgressStatusIcon: React.FC<InProgressStatusIconProps> = ({id}) => {
  return (
    <svg  width="21" height="23" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.83301 0.69165V0.708317V2.37498C13.4913 2.82498 16.083 6.14998 15.633 9.80832C15.2497 12.8417 12.8663 15.25 9.83301 15.6083V17.275C14.4163 16.8167 17.7497 12.75 17.2913 8.16665C16.9163 4.20832 13.7747 1.08332 9.83301 0.69165ZM8.16634 0.71665C6.54134 0.874984 4.99134 1.49998 3.72467 2.54998L4.91634 3.78332C5.84967 3.03332 6.97467 2.54998 8.16634 2.38332V0.71665ZM2.54967 3.72498C1.49967 4.99165 0.874674 6.53332 0.708008 8.16665H2.37467C2.53301 6.98332 2.99967 5.85832 3.74134 4.91665L2.54967 3.72498ZM11.9163 6.08332L7.84967 10.15L6.08301 8.38332L5.19967 9.26665L7.84967 11.9167L12.7997 6.96665L11.9163 6.08332ZM0.716341 9.83332C0.883008 11.4667 1.52467 13.0083 2.55801 14.275L3.74134 13.0833C3.00801 12.1417 2.53301 11.0167 2.38301 9.83332H0.716341ZM4.91634 14.3083L3.72467 15.45C4.98301 16.5 6.53301 17.1583 8.16634 17.3333V15.6667C6.98301 15.5167 5.85801 15.0417 4.91634 14.3083Z"
        fill="#465967"
      />
    </svg>
  );
};
export default InProgressStatusIcon;
