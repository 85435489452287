/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: IP Address List View
 */
import React, {useMemo, useEffect, useState} from 'react';
import {HorizontalTabs, MenuBar, ShareLink, ListGridV2} from '@viasat/insights-components';
import {lookupTableTabDefinitions} from '../tabDefinitions';
import PageStyled from '../../../theme/PageStyled';
import {PageProps} from '../../common/layout/PageProps';
import ListGridContainer from '../../common/ListGridContainer';
import {getElementId} from '../../../utils/elementIdUtils';
import {getIPAddressesQuery} from '../../../store/queries/lookupQueries';
import AddIPAddress from './forms/AddIPAddress';
import SnackBar from '../../common/SnackBar';
import {useStore} from '../../../store/Store';
import {getCurrentUrlPath} from '../../../utils/linkUtils';
import {LookupsListAction} from '../../../store/reducers/LookupsReducer';
import {useRefreshLookupList, useResetSelectedLookup} from '../../../utils/customHooks';
import {IIPAddress} from '../../../store/queries/lookupTypes';
import {buildIPAddressListColumns} from './lookupTableUtils';
import LookupHeaderAction from './LookupListHeaderActions';
import RetireLookupRecord, {RetireLookupType} from './forms/RetireLookupRecord';
import AccessControl from '../../common/accessControl/AccessControl';
import useFetchV2 from '../../../utils/useFetchV2';

const ID_PREFIX = 'ipAddressesList';

const IPAddressList: React.FC<PageProps> = () => {
  const {store, dispatch} = useStore();
  const {isAdmin} = store.init;
  const {newRow, deactivatedRow, refreshList, selectedLookups} = store.lookups;

  const {snackbar} = store.app;
  const urlPath = getCurrentUrlPath();

  useResetSelectedLookup();
  useRefreshLookupList(refreshList);

  const handleRowSelection = (selectedLookup: any) => {
    dispatch({
      type: LookupsListAction.SELECTED_LOOKUP_ROWS,
      payload: selectedLookup
    });
  };

  const handleRowDeselection = (removedLookup: any) => {
    dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: removedLookup});
  };

  const ipAddressesListColumns = buildIPAddressListColumns(isAdmin).map(col => {
    // Add row selection/deselection handlers for checkbox
    if (col.key === 'id') {
      col.handleRowSelection = handleRowSelection;
      col.handleRowDeselection = handleRowDeselection;
    }

    return col;
  });
  const queryParams = useMemo(
    () => ({newRow, deactivatedRow, refreshList, doCache: false}),
    [newRow, deactivatedRow, refreshList]
  );

  const {isLoading: ipAddressLoading, data: ipAddressesInfo} = useFetchV2(
    {route: getIPAddressesQuery.route, params: queryParams},
    getIPAddressesQuery.transform
  );
  const [openAddIPAddress, setOpenAddIPAddress] = useState<boolean>(false);
  const [retireIpAddress, setRetireIpAddress] = useState<boolean>(false);

  useEffect(() => {
    if (refreshList) {
      setTimeout(() => {
        dispatch({
          type: LookupsListAction.REFRESH_LOOKUP_LIST,
          payload: false
        });
        dispatch({
          type: LookupsListAction.RESET_NEW_ROW_COUNT,
          payload: 0
        });
      }, 3000); // The API call takes 1s or 1.5s or 2s sometimes and inconsistent with 500ms.
    }
  }, [refreshList, dispatch]);

  // Determine what columns to show and hide for the list grid
  const currentColumns = ipAddressesListColumns;

  const ipAddressDataWithSelections: IIPAddress[] = ipAddressesInfo
    ? [...ipAddressesInfo].map(row => {
        return {
          ...row,
          isSelected: selectedLookups.some((selectedRow: IIPAddress) => selectedRow.id === row.id),
          isRowHighlighted: row.status === 'Retired'
        };
      })
    : [];

  return (
    <PageStyled>
      <MenuBar
        getFullElementId={(name: string, type: string) => getElementId(ID_PREFIX, 'menuBar', name, type)}
        title={''}
        subtitle={''}
        isSubtitleLoading={false}
        leftStack={[
          <AccessControl permitted={isAdmin}>
            <LookupHeaderAction
              isLoading={ipAddressLoading}
              selectedLookups={selectedLookups}
              setOpenAddAircraftModel={setOpenAddIPAddress}
              setRetireAircraftModel={setRetireIpAddress}
              idPrefix={ID_PREFIX}
              isRetire={true}
              allowRetireDeactive={true}
            />
          </AccessControl>
        ]}
        rightStack={[
          <ShareLink
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'shareLink', name, type)}
            disabled={ipAddressLoading}
            urlPath={urlPath}
            urlQuery={''}
          />
        ]}
        tabs={
          <HorizontalTabs
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'listTabs', name, type)}
            tabs={lookupTableTabDefinitions}
            titleCount={ipAddressesInfo ? ipAddressesInfo.length : undefined}
          ></HorizontalTabs>
        }
      />
      {openAddIPAddress ? (
        <AddIPAddress
          openPanel={openAddIPAddress}
          setOpenPanel={setOpenAddIPAddress}
          panelData={selectedLookups[0]}
          isUpdate={selectedLookups.length === 1}
        ></AddIPAddress>
      ) : (
        <></>
      )}
      {retireIpAddress && (
        <RetireLookupRecord
          retireLookupType={RetireLookupType.RETIRE}
          openRetireLookupModal={retireIpAddress}
          retireLookupQueryRoute={'lookups/deactivate/ipAddress/' + selectedLookups[0]?.id}
          recordDisplayName={'IP Address'}
          setOpenRetireLookupModal={setRetireIpAddress}
        />
      )}
      {snackbar ? (
        <SnackBar id={`${ID_PREFIX}--snackbar`} openSnack={snackbar.openSnack} snackMessage={snackbar.snackMessage} />
      ) : (
        <></>
      )}
      <ListGridContainer>
        <ListGridV2
          listGridData={ipAddressDataWithSelections}
          listGridDataTotal={ipAddressDataWithSelections.length}
          isHeaderLoading={ipAddressLoading}
          isLoading={ipAddressLoading}
          columns={currentColumns}
          fullWidthLoadingBars={true}
          selectedSort={null}
        />
      </ListGridContainer>
    </PageStyled>
  );
};

export default IPAddressList;
