/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add Aircraft Home Base
 */
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {CustomerInfoAction} from '../../../store/reducers/CustomerInfoReducer';
import {useStore} from '../../../store/Store';
import Breadcrumb from '../../common/Breadcrumb';
import FormComponent, {FormField, FormFieldType} from '../../common/elements/Form';
import {BottomHorizontalRule, HeaderHorizontalRule, HeaderLabel, NextButton, PageDescription} from '../../CommonStyles';
import PageContainer from '../../mainMenu/PageContainer';

const ID_PREFIX = 'aircraftHomeBase';

interface AddAircraftTailIdProps {
  title: string;
}

const AircraftTailInfoForm = () => {
  const {dispatch, store} = useStore();
  const customerInfo = store.customerInfo;
  const navigate = useNavigate();
  const location = useLocation();
  const {handleSubmit, formState, watch, control} = useForm();

  const [aircraftTailInfo, setAircraftTailInfo] = useState(null);

  const initialValues = {
    aircraftHomeBaseAirport: customerInfo.aircraftHomeBaseAirport ? customerInfo.aircraftHomeBaseAirport : ''
  };

  const aircraftTailInfoFormFields: FormField[] = [
    {
      id: 'aircraftHomeBaseAirport',
      label: 'Aircraft Home Base Airport (ICAO Airport Code)',
      type: FormFieldType.INPUT,
      value: initialValues.aircraftHomeBaseAirport,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    }
  ];
  useEffect(() => {
    if (aircraftTailInfo === null) return;
    dispatch({
      type: CustomerInfoAction.SET_AIRCRAFT_TAIL_ID_AND_HOME_BASE_AIRPORT,
      payload: {
        aircraftHomeBaseAirport: aircraftTailInfo.aircraftHomeBaseAirport
      }
    });
    navigate('../add-data-sharing-permission');
    // eslint-disable-next-line
  }, [aircraftTailInfo]);
  return (
    <>
      <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Add Aircraft Homebase</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--description`}>
        Please add the home base for the customer’s aircraft.
      </PageDescription>
      <BottomHorizontalRule />
      <FormComponent
        idPrefix={ID_PREFIX}
        id="add-aircraft-tailId-form"
        formFields={aircraftTailInfoFormFields}
        setFormData={setAircraftTailInfo}
        formControlMethods={{handleSubmit, formState, watch, control}}
      ></FormComponent>
    </>
  );
};

const ActionButtons: React.FC = () => {
  return (
    <NextButton id="add-aircraft-tailId-save--button" form="add-aircraft-tailId-form" disabled={false}>
      <span className="next-button-text">Next</span>
      <ArrowForwardIcon className="next-arrow" />
    </NextButton>
  );
};

const AddAircraftHomeBase: React.FC<AddAircraftTailIdProps> = ({title}) => {
  return <PageContainer title={title} leftChild={<AircraftTailInfoForm />} actionButtons={<ActionButtons />} />;
};

export default AddAircraftHomeBase;
