/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Defines config items for List View Tabs
 *
 */

import {SvgIcon} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

export interface TabDefinition {
  page: string;
  route: string;
  Icon: typeof SvgIcon;
  text: string;
  id: string;
  tabName: string;
  title: string;
  shown: boolean;
  disabled: boolean;
  internalOnly: boolean;
  hideTitleCount?: boolean;
}
export type TabDefinitions = TabDefinition[];
export type TabDefinitionsSetter = (TabDefinitions) => void;

export const lookupTableTabDefinitions: TabDefinitions = [
  {
    page: 'aircraft-model-list',
    route: '/lookups/aircraft-model-list',
    Icon: SvgIcon,
    text: 'Aircraft Model',
    id: 'aircraftModelList',
    tabName: 'aircraftModelList',
    title: 'Aircraft Model',
    shown: true,
    disabled: false,
    internalOnly: false
  },
  {
    page: 'ip-address-list',
    route: '/lookups/ip-address-list',
    Icon: SvgIcon,
    text: 'IP Addresses',
    id: 'ipAddressList',
    tabName: 'ipAddressList',
    title: 'IP Addresses',
    shown: true,
    disabled: false,
    internalOnly: false
  },
  {
    page: 'ka-band-sw-versions',
    route: '/lookups/ka-band-sw-versions',
    Icon: SvgIcon,
    text: 'KA-BAND SW',
    id: 'kaBandSwList',
    tabName: 'kaBandSwList',
    title: 'KA-BAND SW',
    shown: true,
    disabled: false,
    internalOnly: false
  },
  {
    page: 'ku-band-sw-versions',
    route: '/lookups/ku-band-sw-versions',
    Icon: SvgIcon,
    text: 'KU-BAND SW',
    id: 'kuBandSwList',
    tabName: 'kuBandSwList',
    title: 'KU-BAND SW',
    shown: true,
    disabled: false,
    internalOnly: false
  },
  {
    page: 'ku-band-map-files',
    route: '/lookups/ku-band-map-files',
    Icon: SvgIcon,
    text: 'KU-BAND MAP FILES',
    id: 'kuBandMapFilesList',
    tabName: 'kuBandMapFilesList',
    title: 'KU-BAND MAP FILES',
    shown: true,
    disabled: false,
    internalOnly: false
  },
  {
    page: 'part-number-list',
    route: '/lookups/part-number-list',
    Icon: SvgIcon,
    text: 'Part Number List',
    id: 'partNumberList',
    tabName: 'partNumberList',
    title: 'Part Number List',
    shown: true,
    disabled: false,
    internalOnly: false,
    hideTitleCount: false
  },
  {
    page: 'service-plan-list',
    route: '/lookups/service-plan-list',
    Icon: SvgIcon,
    text: 'Service Plan List',
    id: 'servicePlanList',
    tabName: 'servicePlanList',
    title: 'Service Plan List',
    shown: true,
    disabled: false,
    internalOnly: false,
    hideTitleCount: false
  },
  {
    page: 'service-plan-terms',
    route: '/lookups/service-plan-terms',
    Icon: SvgIcon,
    text: 'Service Plan Terms',
    id: 'servicePlanTerms',
    tabName: 'servicePlanTerms',
    title: 'Service Plan Terms',
    shown: true,
    disabled: false,
    internalOnly: false,
    hideTitleCount: false
  },
  {
    page: 'router-type-list',
    route: '/lookups/router-type-list',
    Icon: SvgIcon,
    text: 'Router Type List',
    id: 'routerTypeList',
    tabName: 'routerTypeList',
    title: 'Router Type List',
    shown: true,
    disabled: false,
    internalOnly: false
  },
  {
    page: 'warranty-terms-list',
    route: '/lookups/warranty-terms-list',
    Icon: SvgIcon,
    text: 'Warranty',
    id: 'warrantyTermsList',
    tabName: 'warrantyTermsList',
    title: 'Warranty',
    shown: true,
    disabled: false,
    internalOnly: false,
    hideTitleCount: false
  }
];

export const openTaskTabDefinitions: TabDefinitions = [
  {
    page: 'openTasks',
    route: '/lists/open-tasks',
    Icon: AssignmentTurnedInIcon,
    text: 'tab.open_tasks.link',
    id: 'openTasks',
    tabName: 'openTasks',
    title: 'Open Tasks',
    shown: true,
    disabled: false,
    internalOnly: false,
    hideTitleCount: false
  }
];

export const approvalListTabs: TabDefinitions = [
  {
    page: 'approvals-list',
    route: '/lists/approvals-list',
    Icon: PendingActionsIcon,
    text: 'tab.open_tasks.link',
    id: 'approvalsList',
    tabName: 'approvalsList',
    title: 'Requests',
    shown: true,
    disabled: false,
    internalOnly: false,
    hideTitleCount: false
  }
];
