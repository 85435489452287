/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Service Plan Information
 */

import {isObject} from 'lodash';
import {delegateToReducers} from '../Reducer';

export const SERVICE_PLAN_STORE_CONTEXT = 'servicePlan';

interface ISuspendServicePlan {
  taskId: string;
  suspensionType: string;
  suspendDate: string;
  resumeDate: string;
}

interface IBarringServicePlan {
  taskId: string;
}

interface IDecommissionServicePlan {
  taskId: string;
  decommissionDate: string;
  decommissionReason: string;
  decommissionOtherReason: string;
}

interface IResumeServicePlan {
  taskId: string;
  resumeDate: string;
}

export interface ServicePlanState {
  servicePlanType: string;
  contractTerm: string;
  region: string[];
  dataAllowance: string;
  planStartDate: string;
  ipScope: string;
  suspendServicePlan: ISuspendServicePlan;
  barringServicePlan: IBarringServicePlan;
  decommissionServicePlan: IDecommissionServicePlan;
  resumeServicePlan: IResumeServicePlan;
}

export const InitialServicePlanState: ServicePlanState = {
  servicePlanType: '',
  contractTerm: '',
  region: [],
  dataAllowance: '',
  planStartDate: '',
  ipScope: '',
  suspendServicePlan: null,
  barringServicePlan: null,
  decommissionServicePlan: null,
  resumeServicePlan: null
};

export const ServicePlanSessionStoreKeys: string[] = [
  'servicePlanType',
  'contractTerm',
  'region',
  'dataAllowance',
  'planStartDate',
  'ipScope',
  'suspendServicePlan',
  'barringServicePlan',
  'decommissionServicePlan',
  'resumeServicePlan'
];

export enum ServicePlanAction {
  SET_SERVICE_PLAN = 'SET_SERVICE_PLAN',
  SET_SUSPEND_SERVICE_PLAN = 'SET_SUSPEND_SERVICE_PLAN',
  SET_BARRING_SERVICE_PLAN = 'SET_BARRING_SERVICE_PLAN',
  SET_DECOMMISSION_SERVICE_PLAN = 'SET_DECOMMISSION_SERVICE_PLAN',
  SET_RESUME_SERVICE_PLAN = 'SET_RESUME_SERVICE_PLAN',
  RESET_SERVICE_PLAN = 'RESET_SERVICE_PLAN'
}

/**
 * Service Plan action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const ServicePlanReducer = (state: ServicePlanState, action: any): ServicePlanState => {
  switch (action.type) {
    case ServicePlanAction.SET_SERVICE_PLAN:
      const servicePlan = action.payload;
      state = {
        ...state,
        ...servicePlan,
        planStartDate:
          servicePlan.planStartDate && isObject(servicePlan.planStartDate)
            ? servicePlan.planStartDate.toISODate()
            : servicePlan.planStartDate
      };
      break;
    case ServicePlanAction.SET_SUSPEND_SERVICE_PLAN:
      state = {
        ...state,
        suspendServicePlan: {
          ...action.payload
        }
      };
      break;
    case ServicePlanAction.SET_BARRING_SERVICE_PLAN:
      state = {
        ...state,
        barringServicePlan: {
          ...action.payload
        }
      };
      break;
    case ServicePlanAction.SET_DECOMMISSION_SERVICE_PLAN:
      state = {
        ...state,
        decommissionServicePlan: {
          ...action.payload
        }
      };
      break;
    case ServicePlanAction.SET_RESUME_SERVICE_PLAN:
      state = {
        ...state,
        resumeServicePlan: {
          ...action.payload
        }
      };
      break;
    case ServicePlanAction.RESET_SERVICE_PLAN:
      state = {
        ...InitialServicePlanState
      };
      break;
    default:
      break;
  }

  state = delegateToReducers(SERVICE_PLAN_STORE_CONTEXT, state, action, {}, InitialServicePlanState);

  return state;
};
