/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Wrapper Component for styles and locale
 */

import React from 'react';
import {IntlProvider} from 'react-intl';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import local_en from './locale/en.json';
import appTheme from './components/common/appTheme';
import {ThemeProvider} from '@mui/material/styles';

interface AppProvidersProps {
  children: any;
}

const AppProviders: React.FC<AppProvidersProps> = props => {
  return (
    <IntlProvider locale="en" messages={local_en}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={appTheme}>{props.children}</ThemeProvider>
      </StyledEngineProvider>
    </IntlProvider>
  );
};

export default AppProviders;
