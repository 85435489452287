/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add Customer Billing Information
 */
import {isNil} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {addCustomerQuery} from '../../../store/queries/addCustomerQuery';
import {updateCustomerQuery} from '../../../store/queries/updateCustomerQuery';
import {AppAction} from '../../../store/reducers/AppReducer';
import {CustomerInfoAction} from '../../../store/reducers/CustomerInfoReducer';
import {ServicePlanAction} from '../../../store/reducers/ServicePlanReducer';
import {useStore} from '../../../store/Store';
import {useCreateApprovalRequestOnCustomerInformationSubmit} from '../../../utils/customHooks';
import Breadcrumb from '../../common/Breadcrumb';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormComponent, {FormField, FormFieldType} from '../../common/elements/Form';
import {
  BottomHorizontalRule,
  FormContainerLeftPanel,
  HeaderHorizontalRule,
  HeaderLabel,
  NextButton,
  PageDescription
} from '../../CommonStyles';
import PageContainer from '../../mainMenu/PageContainer';
import useFetchV2 from '../../../utils/useFetchV2';

const ID_PREFIX = 'customerBillingInfo';

interface AddCustomerBillingInfoProps {
  title: string;
}

const LeftChild: React.FC<{setCustomerInfoReadyToSave: any}> = ({setCustomerInfoReadyToSave}) => {
  const location = useLocation();
  const {handleSubmit, formState, watch, control, clearErrors, setValue} = useForm();
  const {store, dispatch} = useStore();
  const [customerBillingInfoFormData, setcustomerBillingInfoFormData] = useState(null);
  const {contactInfo, businessAddress} = store.customerInfo;
  const customerInfo = store.customerInfo;
  const initialValues = {
    contactName: customerInfo.billingContactInfo ? customerInfo.billingContactInfo.contactName : '',
    contactEmail: customerInfo.billingContactInfo ? customerInfo.billingContactInfo.contactEmail : '',
    contactPhone: customerInfo.billingContactInfo ? customerInfo.billingContactInfo.phoneNumber : '',
    country: customerInfo.billingAddressInfo ? customerInfo.billingAddressInfo.country : '',
    region: customerInfo.billingAddressInfo ? customerInfo.billingAddressInfo.region : '',
    city: customerInfo.billingAddressInfo ? customerInfo.billingAddressInfo.city : '',
    addressLine1: customerInfo.billingAddressInfo ? customerInfo.billingAddressInfo.addressLine1 : '',
    addressLine2: customerInfo.billingAddressInfo ? customerInfo.billingAddressInfo.addressLine2 : '',
    zipCode: customerInfo.billingAddressInfo ? customerInfo.billingAddressInfo.zipcode : ''
  };
  const checkBoxTypes = ['undefined', 'string'];
  const billingContactSameAsCustomerContact = watch('sameBillingInfo');
  const billingAddressSameAsBusinessAddress = watch('sameAddress');
  const checkBillingContactSameAsCustomerContact =
    checkBoxTypes.indexOf(billingContactSameAsCustomerContact) > -1 || billingContactSameAsCustomerContact === true;
  const checkBillingAddressSameAsBusinessAddress =
    checkBoxTypes.indexOf(billingAddressSameAsBusinessAddress) > -1 || billingAddressSameAsBusinessAddress === true;

  // This will clear the validation error if user check the Same details option and
  // also sets the same values into required fields
  useEffect(() => {
    clearErrors(['contactName', 'contactEmail', 'phoneNumber']);
    if (checkBillingContactSameAsCustomerContact) {
      setValue('contactName', contactInfo.contactName);
      setValue('contactEmail', contactInfo.contactEmail);
      setValue('phoneNumber', contactInfo.phoneNumber);
    }
  }, [checkBillingContactSameAsCustomerContact, clearErrors, setValue, contactInfo]);

  // This will clear the validation error if user check the Same details option and
  // also sets the same values into required fields
  useEffect(() => {
    clearErrors(['country', 'region', 'city', 'addressLine1', 'zipcode']);
    if (checkBillingAddressSameAsBusinessAddress) {
      setValue('country', businessAddress.country);
      setValue('region', businessAddress.region);
      setValue('city', businessAddress.city);
      setValue('addressLine1', businessAddress.addressLine1);
      setValue('addressLine2', businessAddress.addressLine2);
      setValue('zipcode', businessAddress.zipcode);
    }
  }, [checkBillingAddressSameAsBusinessAddress, clearErrors, setValue, businessAddress]);

  const customerBillingInfoFormFields: FormField[] = [
    {
      id: `${ID_PREFIX}--billingContactInfoTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Billing Contact Information',
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: 'sameBillingInfo',
      label: '',
      type: FormFieldType.CHECK_BOX,
      value: 'Is the billing contact information the same as the customer contact information?',
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'contactName',
      label: 'Contact Name',
      type: FormFieldType.INPUT,
      value: checkBillingContactSameAsCustomerContact ? contactInfo.contactName : initialValues.contactName,
      disabled: checkBillingContactSameAsCustomerContact ? true : false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'contactEmail',
      label: 'Contact Email',
      type: FormFieldType.INPUT,
      value: checkBillingContactSameAsCustomerContact ? contactInfo.contactEmail : initialValues.contactEmail,
      disabled: checkBillingContactSameAsCustomerContact ? true : false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'phoneNumber',
      label: 'Contact Phone Number',
      type: FormFieldType.INPUT,
      value: checkBillingContactSameAsCustomerContact ? contactInfo.phoneNumber : initialValues.contactPhone,
      disabled: checkBillingContactSameAsCustomerContact ? true : false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: '',
      label: '',
      type: FormFieldType.BOTTOM_HORIZONTAL_RULE,
      value: '',
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: `${ID_PREFIX}--billingAddressTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Billing Address',
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: 'sameAddress',
      label: '',
      type: FormFieldType.CHECK_BOX,
      value: 'Is the billing address the same as the company address?',
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'country',
      label: 'Country',
      type: FormFieldType.INPUT,
      value: checkBillingAddressSameAsBusinessAddress ? businessAddress.country : initialValues.country,
      disabled: checkBillingAddressSameAsBusinessAddress ? true : false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'region',
      label: 'State / Province / Region',
      type: FormFieldType.INPUT,
      value: checkBillingAddressSameAsBusinessAddress ? businessAddress.region : initialValues.region,
      disabled: checkBillingAddressSameAsBusinessAddress ? true : false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'city',
      label: 'City',
      type: FormFieldType.INPUT,
      value: checkBillingAddressSameAsBusinessAddress ? businessAddress.city : initialValues.city,
      disabled: checkBillingAddressSameAsBusinessAddress ? true : false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'addressLine1',
      label: 'Address Line 1',
      type: FormFieldType.INPUT,
      value: checkBillingAddressSameAsBusinessAddress ? businessAddress.addressLine1 : initialValues.addressLine1,
      disabled: checkBillingAddressSameAsBusinessAddress ? true : false,
      description: {title: null, value: 'Street address, P.O. box'},
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'addressLine2',
      label: 'Address Line 2',
      type: FormFieldType.INPUT,
      value: checkBillingAddressSameAsBusinessAddress ? businessAddress.addressLine2 : initialValues.addressLine2,
      disabled: checkBillingAddressSameAsBusinessAddress ? true : false,
      description: {title: null, value: 'Apartment, suite, unit, building, floor, etc.'},
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'zipcode',
      label: 'Zip Code / Postal Code',
      type: FormFieldType.INPUT,
      value: checkBillingAddressSameAsBusinessAddress ? businessAddress.zipcode : initialValues.zipCode,
      disabled: checkBillingAddressSameAsBusinessAddress ? true : false,
      rules: {
        required: true,
        pattern: null
      }
    }
  ];

  const [servicePlan, setServicePlan] = useState<any>(null);

  useEffect(() => {
    if (servicePlan && servicePlan.serialNumber && servicePlan.make) {
      dispatch({type: ServicePlanAction.SET_SERVICE_PLAN, payload: servicePlan});
    }
    //eslint-disable-next-line
  }, [servicePlan, dispatch]);

  useEffect(() => {
    if (customerBillingInfoFormData === null) return;
    dispatch({
      type: CustomerInfoAction.SET_BILLING_CONTACT_INFO,
      payload: {
        sameBillingInfo: customerBillingInfoFormData.sameBillingInfo,
        contactName: customerBillingInfoFormData.contactName,
        contactEmail: customerBillingInfoFormData.contactEmail,
        phoneNumber: customerBillingInfoFormData.phoneNumber
      }
    });
    dispatch({
      type: CustomerInfoAction.SET_BILLING_ADDRESS_INFO,
      payload: {
        sameAddress: customerBillingInfoFormData.sameAddress,
        country: customerBillingInfoFormData.country,
        region: customerBillingInfoFormData.region,
        city: customerBillingInfoFormData.city,
        addressLine1: customerBillingInfoFormData.addressLine1,
        addressLine2: customerBillingInfoFormData.addressLine2,
        zipcode: customerBillingInfoFormData.zipcode
      }
    });
    setServicePlan(customerBillingInfoFormData);
    setCustomerInfoReadyToSave(true);
    // eslint-disable-next-line
  }, [customerBillingInfoFormData]);

  return (
    <>
      <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Enter Billing Contact Information</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--description`}>
        Please enter the customer’s billing contact information.
      </PageDescription>
      <BottomHorizontalRule />
      <FormContainerLeftPanel>
        <FormComponent
          idPrefix={ID_PREFIX}
          id="add-billing-contact-form"
          formFields={customerBillingInfoFormFields}
          setFormData={setcustomerBillingInfoFormData}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
      </FormContainerLeftPanel>
    </>
  );
};

const ActionButtons: React.FC = () => {
  return (
    <NextButton id="add-billing-contact-save--button" form="add-billing-contact-form" disabled={false}>
      <span className="next-button-text">Next</span>
      <ArrowForwardIcon className="next-arrow" />
    </NextButton>
  );
};

const AddCustomerBillingInfo: React.FC<AddCustomerBillingInfoProps> = ({title}) => {
  const [isCustomerInfoReadyToSave, setCustomerInfoReadyToSave] = useState<boolean>(false);
  const {store, dispatch} = useStore();
  const navigate = useNavigate();

  const {customerInfo, aircraftInfo} = store;
  const {breadcrumb} = store.app;
  const taskId = customerInfo.taskId;
  // Whether the customer information is update or new
  const isUpdate = !isNil(taskId);
  const requestParams = useMemo(() => {
    if (!isCustomerInfoReadyToSave) return;
    return {
      isInternalUser: 'true',
      ...aircraftInfo,
      ...customerInfo
    };
    // eslint-disable-next-line
  }, [isCustomerInfoReadyToSave]);
  const {isLoading: isCustomerInfoSaving, data: customerInfoResponse} = useFetchV2(
    {route: taskId ? updateCustomerQuery(taskId).route : addCustomerQuery.route, params: requestParams},
    taskId ? updateCustomerQuery(taskId).transform : addCustomerQuery.transform
  );
  const {isSubmitRequestLoading, submitResponse} = useCreateApprovalRequestOnCustomerInformationSubmit(
    customerInfoResponse?.taskId,
    isUpdate
  );
  useEffect(() => {
    if ((isCustomerInfoSaving && !customerInfoResponse) || isSubmitRequestLoading || !submitResponse) {
      return;
    }
    dispatch({
      type: CustomerInfoAction.SET_CUSTOMER_TASK_ID,
      payload: customerInfoResponse.taskId
    });
    dispatch({
      type: AppAction.SET_BREADCRUMB,
      payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
    });
    let operation,
      message,
      details = '';

    operation = 'added';
    message = 'New Customer information has been';
    details = 'New Customer information has been sent to Viasat MCC. ';

    dispatch({
      type: AppAction.SET_ALERT_INFO,
      payload: {
        showAlert: true,
        operation: operation,
        message: message,
        details: details + 'Support staff will reply via email as quickly as possible.'
      }
    });
    navigate('/main-menu');
    // eslint-disable-next-line
  }, [dispatch, isCustomerInfoSaving, customerInfoResponse, isSubmitRequestLoading, submitResponse]);
  return (
    <PageContainer
      title={title}
      leftChild={<LeftChild setCustomerInfoReadyToSave={setCustomerInfoReadyToSave} />}
      actionButtons={<ActionButtons />}
    />
  );
};

export default AddCustomerBillingInfo;
