/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Auth utility functions
 */

import {spaComs} from '@viasat/insights-spa-package';

/**
 * Handles a user logging out
 */
export const logout = () => {
  spaComs.logout();
};

/**
 * Logs out of the app locally
 */
export const localLogout = () => {
  sessionStorage.clear();
  localStorage.clear();
};
