/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: APSU Information
 */

import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {useStore} from '../../store/Store';
import {EquipmentInfoAction, IApsuInformation} from '../../store/reducers/EquipmentInfoReducer';
import {AppAction} from '../../store/reducers/AppReducer';

import {updateAPSUInformation} from '../../store/queries/newInstall/equipmentInfoQuery';

import {useEquipmentUpdate} from '../../utils/customHooks';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import FormComponent from '../common/elements/Form';

import {
  BottomHorizontalRule,
  HeaderHorizontalRule,
  HeaderLabel,
  LatestRevision,
  NextButton,
  PageDescription,
  StyledLink
} from '../CommonStyles';
import {EquipmentType, SELECT_AN_OPTION} from '../common/constants';
import {showUserManuals} from '../../utils/config';
import {useEquipmentInformationForm} from '../../utils/equipmentInformationFormHook';

const ID_PREFIX = 'apsuInstallation';

interface APSUInstallationPageProps {
  title: string;
}

interface IFormInput {
  partNumber: string;
  serialNumber: string;
}

const APSUInstallationPage: React.FC<APSUInstallationPageProps> = ({title}) => {
  const location = useLocation();

  const APSUInfoForm = () => {
    const {store, dispatch} = useStore();
    const {aircraftInfo} = store;
    const {aircraftNetwork} = aircraftInfo;
    const apsuInfo = store.equipmentInfo.apsu;
    const taskId = store.openTasksInfo.newTask?.taskId;
    const breadcrumbInfo = store.app.breadcrumb;
    const initialValues = apsuInfo
      ? apsuInfo
      : {
          partNumber: SELECT_AN_OPTION,
          serialNumber: ''
        };

    const {handleSubmit, formState, watch, control} = useForm();
    const apsuConfigItems = store.app.configItems?.['Power Supply'] ? store.app.configItems['Power Supply'] : [];
    const {productDescription, equipmentFormFields} = useEquipmentInformationForm(
      watch,
      aircraftNetwork,
      initialValues,
      EquipmentType.APSU,
      apsuConfigItems
    );

    const [apsuformData, setApsuFormData] = useState<IFormInput>(null);

    useEquipmentUpdate<IApsuInformation>(
      taskId,
      apsuformData,
      updateAPSUInformation,
      '/new-terminal-installation',
      () => {
        dispatch({
          type: EquipmentInfoAction.SET_APSU_INFO,
          payload: {productDescription, ...apsuformData}
        });
        dispatch({
          type: AppAction.SET_BREADCRUMB,
          payload: breadcrumbInfo.filter(breadCrumb => breadCrumb.linkPath === '/new-terminal-installation')
        });
      },
      aircraftInfo
    );

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Enter APSU Information</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--description`}>
          Use your mobile device to scan the QR code on the Antenna Power Supply(APSU), or manually enter the APSU
          information for the new terminal.
        </PageDescription>
        <BottomHorizontalRule />
        <FormComponent
          idPrefix={ID_PREFIX}
          id="new-apsu-information-form"
          formFields={equipmentFormFields}
          setFormData={setApsuFormData}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
        {showUserManuals ? (
          <>
            <BottomHorizontalRule />
            <LatestRevision id={`${ID_PREFIX}--latestRevisionTitle`}>
              View latest revision of the Viasat APSU Operations and Test Manual here:
            </LatestRevision>
            <StyledLink href="#" id={`${ID_PREFIX}--installProcedureLink`}>
              APSU Installation Procedures
            </StyledLink>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton id={`${ID_PREFIX}--nextButton`} form="new-apsu-information-form" disabled={false}>
        <span className="next-button-text">Next</span>
        <ArrowForwardIcon className="next-arrow" />
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<APSUInfoForm />} actionButtons={<ActionButtons />} />;
};

export default APSUInstallationPage;
