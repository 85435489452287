/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Warranty Terms List View
 */
import React, {useMemo, useEffect, useState} from 'react';
import {HorizontalTabs, MenuBar, ShareLink, ListGridV2, ListGridColumn} from '@viasat/insights-components';
import {lookupTableTabDefinitions} from '../tabDefinitions';
import PageStyled from '../../../theme/PageStyled';
import {PageProps} from '../../common/layout/PageProps';
import ListGridContainer from '../../common/ListGridContainer';
import {getElementId} from '../../../utils/elementIdUtils';
import {getWarrantyTermsListQuery} from '../../../store/queries/lookupQueries';
import SnackBar from '../../common/SnackBar';
import {useStore} from '../../../store/Store';
import {getCurrentUrlPath} from '../../../utils/linkUtils';
import {LookupsListAction} from '../../../store/reducers/LookupsReducer';
import {LookupListColumnId, buildWarrantyTermsListColumns, dynamicSort, getQueryFieldMapping} from './lookupTableUtils';
import AddWarrantyTerms from './forms/AddWarrantyTerm';
import {useRefreshLookupList, useResetSelectedLookup} from '../../../utils/customHooks';
import {IWarrantyTerm} from '../../../store/queries/lookupTypes';
import AccessControl from '../../common/accessControl/AccessControl';
import LookupHeaderAction from './LookupListHeaderActions';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {WARRANTY_TERMS_LIST_STORE_CONTEXT} from '../../../store/reducers/WarrantyTermsListReducer';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import ListViewSortContainer from '../ListViewSortContainer';
import useFetchV2 from '../../../utils/useFetchV2';
import RetireLookupRecord, {RetireLookupType} from './forms/RetireLookupRecord';

const ID_PREFIX = 'warrantyTermsList';

const WarrantyTermsList: React.FC<PageProps> = () => {
  const {store, dispatch} = useStore();
  const {isAdmin} = store.init;
  const {sort} = store.warrantyTermsList;
  const {newRow, deactivatedRow, refreshList, selectedLookups} = store.lookups;
  const [openAddWarrantyTerms, setOpenAddWarrantyTerms] = useState<boolean>(false);
  const {snackbar} = store.app;
  const [warrantyTermsListData, setWarrantyTermsListData] = useState<IWarrantyTerm[]>(null);
  const [retireWarrantyTermModel, setRetireWarrantyTermModel] = useState<boolean>(false);
  const urlPath = getCurrentUrlPath();
  useResetSelectedLookup();
  useRefreshLookupList(refreshList);

  const handleRowSelection = (selectedLookup: any) => {
    dispatch({
      type: LookupsListAction.SELECTED_LOOKUP_ROWS,
      payload: selectedLookup
    });
  };

  const handleRowDeselection = (removedLookup: any) => {
    dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: removedLookup});
  };

  const warrantyTermsListColumns = buildWarrantyTermsListColumns(isAdmin).map(col => {
    // Add row selection/deselection handlers for checkbox
    if (col.key === 'id') {
      col.handleRowSelection = handleRowSelection;
      col.handleRowDeselection = handleRowDeselection;
    }

    return col;
  });
  const queryParams = useMemo(
    () => ({newRow, deactivatedRow, refreshList, doCache: false}),
    [newRow, deactivatedRow, refreshList]
  );

  const {isLoading: isWarrantyTermsListLoading, data: warrantyTermsListInfo} = useFetchV2(
    {route: getWarrantyTermsListQuery.route, params: queryParams},
    getWarrantyTermsListQuery.transform
  );

  useEffect(() => {
    if (refreshList) {
      setTimeout(() => {
        dispatch({
          type: LookupsListAction.REFRESH_LOOKUP_LIST,
          payload: false
        });
        dispatch({
          type: LookupsListAction.RESET_NEW_ROW_COUNT,
          payload: 0
        });
      }, 3000); // The API call takes 1s or 1.5s or 2s sometimes and inconsistent with 500ms.
    }
  }, [refreshList, dispatch]);

  // Determine what columns to show and hide for the list grid
  const currentColumns = warrantyTermsListColumns;

  useEffect(() => {
    if (isWarrantyTermsListLoading) return;
    const warrantyTermsDataWithSelections: IWarrantyTerm[] = warrantyTermsListInfo
      ? [...warrantyTermsListInfo].map(row => {
          return {
            ...row,
            isSelected: selectedLookups.some((selectedRow: IWarrantyTerm) => selectedRow.id === row.id)
          };
        })
      : [];
    setWarrantyTermsListData(warrantyTermsDataWithSelections.sort(dynamicSort(sort.column, sort.order)));
  }, [warrantyTermsListInfo, isWarrantyTermsListLoading, selectedLookups, sort.column, sort.order]);

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(LookupListColumnId[columnId]);

  const handleSortChange = ({column, order}: {column: string; order: string}) => {
    setWarrantyTermsListData(warrantyTermsListData.sort(dynamicSort(column, order)));
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, WARRANTY_TERMS_LIST_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(warrantyTermsListColumns[column]),
        validColumns: warrantyTermsListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: warrantyTermsListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(warrantyTermsListColumns[col.key])
        )
      }
    });
  };
  return (
    <PageStyled>
      <MenuBar
        getFullElementId={(name: string, type: string) => getElementId(ID_PREFIX, 'menuBar', name, type)}
        title={''}
        subtitle={''}
        isSubtitleLoading={false}
        leftStack={[
          <AccessControl permitted={isAdmin}>
            <LookupHeaderAction
              isLoading={isWarrantyTermsListLoading}
              selectedLookups={selectedLookups}
              setOpenAddAircraftModel={setOpenAddWarrantyTerms}
              setRetireAircraftModel={setRetireWarrantyTermModel}
              idPrefix={ID_PREFIX}
              isRetire={true}
              allowRetireDeactive={true}
            />
          </AccessControl>,
          <ListViewSortContainer
            key="sorts"
            idPrefix={ID_PREFIX}
            disabled={isWarrantyTermsListLoading}
            storeContext={WARRANTY_TERMS_LIST_STORE_CONTEXT}
            columns={warrantyTermsListColumns}
            showColumnGroups={true}
            getQueryFieldForColumn={getQueryFieldForColumn}
          />
        ]}
        rightStack={[
          <ShareLink
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'shareLink', name, type)}
            disabled={isWarrantyTermsListLoading}
            urlPath={urlPath}
            urlQuery={''}
          />
        ]}
        tabs={
          <HorizontalTabs
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'listTabs', name, type)}
            tabs={lookupTableTabDefinitions}
            titleCount={warrantyTermsListInfo ? warrantyTermsListInfo.length : undefined}
          ></HorizontalTabs>
        }
      />

      {openAddWarrantyTerms ? (
        <AddWarrantyTerms
          openPanel={openAddWarrantyTerms}
          setOpenPanel={setOpenAddWarrantyTerms}
          panelData={selectedLookups[0]}
          isUpdate={selectedLookups.length === 1}
        ></AddWarrantyTerms>
      ) : (
        <></>
      )}
      {retireWarrantyTermModel && (
        <RetireLookupRecord
          retireLookupType={RetireLookupType.RETIRE}
          openRetireLookupModal={retireWarrantyTermModel}
          retireLookupQueryRoute={'lookups/deactivate/warrantyTerm/' + selectedLookups[0]?.id}
          recordDisplayName={'Warranty Terms'}
          setOpenRetireLookupModal={setRetireWarrantyTermModel}
        />
      )}
      {snackbar ? (
        <SnackBar id={`${ID_PREFIX}--snackbar`} openSnack={snackbar.openSnack} snackMessage={snackbar.snackMessage} />
      ) : (
        <></>
      )}
      <ListGridContainer>
        <ListGridV2
          listGridData={!isWarrantyTermsListLoading && warrantyTermsListData ? warrantyTermsListData : []}
          listGridDataTotal={!isWarrantyTermsListLoading && warrantyTermsListData ? warrantyTermsListData.length : 0}
          isHeaderLoading={isWarrantyTermsListLoading}
          isLoading={isWarrantyTermsListLoading}
          columns={currentColumns}
          fullWidthLoadingBars={true}
          handleSortChange={handleSortChange}
          selectedSort={sort}
        />
      </ListGridContainer>
    </PageStyled>
  );
};

export default WarrantyTermsList;
