/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: This component handles the tab components and any children
 *
 */

import {Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {LoadingSpinner} from '@viasat/insights-components';
import {PageProps} from '../common/layout/PageProps';
import {lookupTableTabDefinitions} from './tabDefinitions';
import PageStyled from '../../theme/PageStyled';
import OpenTasks from './openTasks/OpenTasks';
import PartNumberList from './lookups/PartNumberList';
import ServicePlanList from './lookups/ServicePlanList';
import AircraftModelList from './lookups/AircraftModelList';
import RouterTypeList from './lookups/RouterTypeList';
import IPAddressList from './lookups/IPAddressList';
import KaBandSwList from './lookups/KaBandSwList';
import KuBandSwList from './lookups/KuBandSwList';
import KuBandMapFilesList from './lookups/KuBandMapFilesList';
import ServicePlanTermsList from './lookups/ServicePlanTermsList';
import ApprovalsList from './approvals/ApprovalsList';
import WarrantyTermsList from './lookups/WarrantyTermsList';

const getPageComponent: any = (tabName: string) =>
  ({
    openTasks: OpenTasks,
    partNumberList: PartNumberList,
    servicePlanList: ServicePlanList,
    aircraftModelList: AircraftModelList,
    routerTypeList: RouterTypeList,
    ipAddressList: IPAddressList,
    kaBandSwList: KaBandSwList,
    kuBandSwList: KuBandSwList,
    kuBandMapFilesList: KuBandMapFilesList,
    servicePlanTerms: ServicePlanTermsList,
    warrantyTermsList: WarrantyTermsList,
    approvalsList: ApprovalsList
  }[tabName] || null);

const ListViewPage: React.FC<PageProps> = (props: any) => {
  return (
    <PageStyled>
      <Suspense fallback={<LoadingSpinner id="list-view-loading-screen" />}>
        <Routes>
          {lookupTableTabDefinitions.map(listViewPage => {
            const {page, tabName} = listViewPage;
            const PageComponent = getPageComponent(tabName);
            return <Route key={page} path={`${page}`} element={<PageComponent {...props} view={page} />} />;
          })}
          <Route element={<Navigate to="aircraft-model-list" />} />
        </Routes>
      </Suspense>
    </PageStyled>
  );
};

export default ListViewPage;
