/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Global constants
 *
 */
import moment from 'moment';
import {replaceHardwareEnabled, serviceProviderChangeEnabled} from '../../utils/config';

export const NO_DATA_INDICATOR = '--';

export const BREADCRUMB_ROUTE_MAP = {
  'identify-aircraft': 'Add Aircraft Serial Number',
  'contact-viasat': 'Contact Viasat',
  'identify-aircraft-new-installation': 'Identify Aircraft',
  'edit-aircraft-information': 'Aircraft Details',
  'new-terminal-installation': 'New Terminal Installation',
  'new-modem-information': 'Add Modem Details',
  'new-antenna-information': 'Add Antenna Information',
  'new-apsu-installation': 'Add APSU Information',
  'new-acu-information': 'Add ACU Information',
  'new-fantray-installation': 'Add Fan Tray Information',
  'new-router-information': 'Add Router Information',
  'add-external-customer': 'Add Customer Information',
  'add-internal-customer': 'Add Customer Information',
  'add-aircraft-tail-id': 'Add Aircraft Homebase',
  'add-customer-billing-info': 'Add Customer Billing Information',
  'add-data-sharing-permission': 'Data Sharing Permission',
  'choose-service-plan': 'Choose Service Plan',
  'change-service-plan': 'Change Service Plan',
  'service-plan-options': 'Service Plan Options',
  'suspend-service-plan': 'Suspend Service',
  'resume-service-plan': 'Resume Service',
  'bar-service': 'Bar Service',
  'decommission-service-plan': 'Decommission Service',
  'edit-service-plan': 'Edit Service Plan',
  'add-ip-ka-address': 'Specify Ka-Band IP Address',
  'replace-terminal-equipment': 'Replace Terminal Equipment',
  'replace-apsu-information': 'Replace APSU Information',
  'replace-fantray-information': 'Replace FanTray Information',
  'replace-router-information': 'Replace Router Information',
  'replace-antenna-information': 'Replace Antenna Information',
  'replace-modem-information': 'Replace Modem Information',
  'replace-acu-information': 'Replace ACU Information',
  'warranty-extension': 'Warranty Details',
  'warranty-reason': 'Reason for Warranty Change',
  'request-aircraft-access': 'Request Access'
};

export enum EquipmentType {
  MODEM = 'Modem',
  ANTENNA = 'Antenna',
  APSU = 'APSU',
  ACU = 'ACU',
  FAN_TRAY = 'Fan Tray'
}

//Network
export const KU_NETWORK = 'Ku';
export const KA_NETWORK = 'Ka';
export const DUAL_BAND_NETWORK = 'Dual Band (Ka & Ku)';

//Routers
export const VSR_ROUTER = 'Viasat BizAv Router (VSR)';
export const OTHER_ROUTER = 'Other';

// Routers types list
export const routerTypeList = [
  VSR_ROUTER,
  'ARIINC Direct (CCX router)',
  'Honeywell GoDirect Router (GDR)',
  'Gogo SCS Router',
  'Satcom Direct Router (SDR)',
  'Satcom Direct Router - Gateway (SDR-G)',
  OTHER_ROUTER
];

// Router Regex:
export const serialNumberPatternRegex = '\\b\\d{9}\\b';
export const routerIdPatternRegex = '\\b\\d{1}LL\\d{7}\\b';
export const simNumberPatternRegex = '\\b\\d{19}\\b';

//Serail Number Regex
const currentYear = moment.utc().year().toString().slice(-1);
export const serialNumberValidation = {
  '1248795-AC': `\\bH2\\d{2}(1[8,9]|2[0-${currentYear}])([0-4][0-9]|5[0-2])\\d{4}\\b`,
  '1248795-DC': `\\bH2\\d{2}(1[8,9]|2[0-${currentYear}])([0-4][0-9]|5[0-2])\\d{4}\\b`,
  //eslint-disable-next-line
  '1054023': '\\d{2}-\\d{4}',
  '1243533': `\\bD777(1[8,9]|2[0-${currentYear}])([0-4][0-9]|5[0-2])\\d{0,4}\\b`,
  '1243532': `\\bD777(1[8,9]|2[0-${currentYear}])([0-4][0-9]|5[0-2])\\d{4}\\b`,
  '1209814-001': '\\d{5}-[A-Z]\\d{5}',
  '1209814-011': '\\d{5}-[A-Z]{3}\\d{4}',
  '1259484-002': '\\d{6}-\\d{1}-\\d{1}',
  '1259484-003': '\\d{6}-\\d{1}-\\d{1}',
  '1259484-004': '\\d{6}-\\d{1}-\\d{1}',
  '1151745': '\\d{5}-[A-Z]\\d{4}',
  '1151745-002': '\\d{5}-[A-Z]{2}\\d{4}'
};

//Mac Address regex
export const macAddressRegex = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/i;
export const ipv4Regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

//New Install Menu Options
export const KU_INSTALLATION_OPTIONS = [
  {
    id: 'new-modem-information',
    name: 'Add Modem Information',
    equipment: 'kuModem',
    isMandatory: true
  },
  {
    id: 'new-antenna-information',
    name: 'Add Antenna Information',
    equipment: 'kuAntenna',
    isMandatory: true
  },
  {
    id: 'new-acu-information',
    name: 'Add ACU Information',
    equipment: 'acu',
    isMandatory: true
  },
  {
    id: 'new-router-information',
    name: 'Add Router Information',
    equipment: 'router',
    isMandatory: false
  }
];
export const DUAL_BAND_KA_INSTALLATION_OPTIONS = [
  {
    id: 'new-modem-information',
    name: 'Add Modem Information',
    equipment: 'kaModem',
    isMandatory: true
  },
  {
    id: 'new-antenna-information',
    name: 'Add Antenna Information',
    equipment: 'kaAntenna',
    isMandatory: true
  },
  {
    id: 'new-apsu-installation',
    name: 'Add APSU Information',
    equipment: 'apsu',
    isMandatory: true
  },
  {
    id: 'new-fantray-installation',
    name: 'Add Fan Tray Information',
    equipment: 'fanTray',
    isMandatory: false
  }
];
export const KA_INSTALLATION_OPTIONS = [
  ...DUAL_BAND_KA_INSTALLATION_OPTIONS,
  {
    id: 'new-router-information',
    name: 'Add Router Information',
    equipment: 'router',
    isMandatory: false
  }
];

export const ADD_NEW_TERMINAL: IMainMenu = {
  id: 'add-new-terminal',
  name: 'Equipment Details',
  navigationPath: '/new-terminal-installation'
};
export const REPLACE_HARDWARE: IMainMenu = {
  id: 'replace-hardware',
  name: 'Equipment Replacement',
  navigationPath: '/replace-terminal-equipment',
  isDisabled: !replaceHardwareEnabled
};

export interface IMainMenu {
  id: string;
  name: string;
  navigationPath: string;
  isDisabled?: boolean;
  status?: string;
}

export const COMMON_MAIN_MENU_OPTIONS: IMainMenu[] = [
  {
    id: 'add-new-terminal',
    name: 'Equipment Details',
    navigationPath: '/new-terminal-installation'
  },
  {
    id: 'replace-hardware',
    name: 'Equipment Replacement',
    navigationPath: '/replace-terminal-equipment',
    isDisabled: !replaceHardwareEnabled
  },
  {
    id: 'add-edit-customer-info',
    name: 'Customer Details',
    navigationPath: ''
  },
  {id: 'add-edit-service-plan', name: 'Service Plan Options', navigationPath: '/choose-service-plan'},
  {
    id: 'request-service-provider-change',
    name: 'Request Service Provider Change',
    navigationPath: '',
    isDisabled: !serviceProviderChangeEnabled
  }
];

export const SELECT_AN_OPTION = 'Select an option';

export const PROPERTIES_TO_BE_EXCLUDED_ON_RIGHT_PANEL = [
  'productDescription',
  'isNewInstall',
  'varAccountId',
  'isRequestSubmittedForApproval'
];

export const MANUFACTURERS = ['Airbus', 'Boeing', 'Bombardier', 'Dassault', 'Embraer', 'Gulfstream', 'Test Bench'];

export const YES_NO = ['Yes', 'No'];
export const NETWORK_OPTIONS = ['Ka', 'Ku', 'Dual-band', 'VVIP Ka', 'VVIP Dual-band'];

export enum NetworkCapability {
  KA = 'Ka',
  KU = 'Ku',
  DUAL_BAND = 'Dual Band (Ka & Ku)'
}

export enum FACTORY_TEST_RATE_PANS {
  KA = 'Ka Test',
  KU = 'Ku Test',
  BAND_DUAL = 'Dual Band Test'
}

export enum SF_SERVICE_SITE_STATUS {
  PRE_COMMISSION = 'Pre-Commission',
  FACTORY_TEST = 'Factory Test',
  FIXED_LAB_TERMINAL_SERVICE = 'Fixed Lab Terminal Service',
  DEMO = 'Demo',
  REVENUE_SERVICE = 'Revenue Service',
  SUSPENDED = 'Suspended',
  BARRED = 'Barred',
  DECOMMISSIONED = 'Decommissioned'
}

export enum TASK_STATUS {
  NEW = 'New',
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  REVIEW = 'Review',
  DECLINED = 'Declined',
  INFO_REQUIRED = 'Info Required',
  APPROVED = 'Approved'
}

export enum TASK_TYPES {
  NEW_INSTALL = 'NewInstall',
  REPLACE_HARDWARE = 'ReplaceHardware',
  NEW_CUSTOMER = 'NewCustomer',
  CHANGE_CUSTOMER = 'ChangeCustomer',
  NEW_SERVICE_PLAN = 'NewServicePlan',
  CHANGE_SERVICE_PLAN = 'ChangeServicePlan',
  SUSPEND_MONTHLY_SERVICE_PLAN = 'Suspend Monthly Service',
  SUSPEND_MAINTENANCE_SERVICE_PLAN = 'Suspend Service for Maintenance',
  RESUME_SERVICE = 'ResumeService',
  BARRING_SERVICE = 'BarringService',
  CHANGE_SERVICE_PROVIDER = 'ChangeServiceProvider',
  DECOMMISSION_SERVICE = 'DecommissionService',
  CHANGE_AIRCRAFT_DETAILS = 'ChangeAircraftDetails',
  REQUEST_AIRCRAFT_ACCESS = 'RequestAircraftAccess'
}

export enum APPROVAL_PROCESS_DESCRIPTION {
  WAITING = 'Waiting',
  COMPLETED = 'Completed',
  FAILED = 'Failed'
}

export enum APPROVAL_PROGRESS_STEP_LABELS {
  SERVICE_SITE_CREATE = 'Service Site Creation',
  CONFIG_ITEM_CREATE = 'Configuration Items Creation',
  SERVICE_SITE_UPDATE = 'Service Site Update',
  CONFIG_ITEM_UPDATE = 'Configuration Items Update',
  JIRA_CREATE = 'Jira Ticket Creation',
  FINAL_APPROVAL = 'Final Approval and Email',
  ACCOUNT_CREATE = 'Account Creation'
}

export enum APPROVAL_PROCESS_STATUS {
  WAITING = 'waiting',
  SUCCESS = 'success',
  ERROR = 'error'
}

/**
 * Gets Approval steps config based on task type
 * @param taskType - Review information task type
 * @return Returns config based on task type
 */
export const getApprovalProcessConfig = (taskType: string) => {
  let approvalProcess = [];

  switch (taskType) {
    case TASK_TYPES.NEW_INSTALL:
      approvalProcess = [
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.SERVICE_SITE_CREATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.CONFIG_ITEM_CREATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.JIRA_CREATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.FINAL_APPROVAL,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        }
      ];
      break;
    case TASK_TYPES.REPLACE_HARDWARE:
      approvalProcess = [
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.CONFIG_ITEM_UPDATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.SERVICE_SITE_UPDATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.JIRA_CREATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.FINAL_APPROVAL,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        }
      ];
      break;
    case TASK_TYPES.REQUEST_AIRCRAFT_ACCESS:
      approvalProcess = [
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.SERVICE_SITE_UPDATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.FINAL_APPROVAL,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        }
      ];
      break;
    case TASK_TYPES.CHANGE_AIRCRAFT_DETAILS:
    case TASK_TYPES.BARRING_SERVICE:
    case TASK_TYPES.DECOMMISSION_SERVICE:
    case TASK_TYPES.CHANGE_SERVICE_PLAN:
    case TASK_TYPES.RESUME_SERVICE:
    case TASK_TYPES.NEW_SERVICE_PLAN:
      approvalProcess = [
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.SERVICE_SITE_UPDATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.JIRA_CREATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.FINAL_APPROVAL,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        }
      ];
      break;
    case TASK_TYPES.NEW_CUSTOMER:
      approvalProcess = [
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.ACCOUNT_CREATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.SERVICE_SITE_UPDATE,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        },
        {
          label: APPROVAL_PROGRESS_STEP_LABELS.FINAL_APPROVAL,
          status: APPROVAL_PROCESS_STATUS.WAITING,
          description: APPROVAL_PROCESS_DESCRIPTION.WAITING
        }
      ];
      break;
    default:
      break;
  }

  return approvalProcess;
};

export const JIRA_CREATION_TASKS = [
  'NewInstall',
  'ReplaceHardware',
  'DecommissionService',
  'BarringService',
  'ChangeServicePlan',
  'RequestAircraftAccess'
];

export const INSTALLATION_TYPES = ['Linefit', 'Aftermarket Install'];

export const DEFAULT_GROUP_CODE = 'ALL';

export enum EQUIPMENT_DETAIL_MAPPING {
  KA_MODEM = 'ModemKa',
  KA_ANTENNA = 'AntennaKa',
  KA_APSU = 'PowerSupplyKa',
  KU_MODEM = 'ModemKu',
  KU_ANTENNA = 'AntennaKu',
  KU_ACU = 'ACUKu'
}

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
      color: '#465967'
    }
  }
};

export enum SERVICE_PLAN_REQUEST_TYPES {
  NEW_PLAN = 'New Service Plan',
  CHANGE_PLAN = 'Change Service Plan',
  SUSPEND_FOR_MAINTENANCE = 'Suspend Service for Maintenance',
  SUSPEND_FOR_MONTHLY_SUBSCRIPTION = 'Suspend Monthly Service',
  RESUME_SERVICE = 'Resume Service',
  BARRING_SERVICE = 'Barring Service',
  DECOMMISSION_SERVICE = 'Decommission Service'
}

export const REVIEW_REQUEST_KA_IP_BAND_WARNING = 'The IP address is already in use by another active task.';

export const AIRCRAFT_NOT_ONBOARDED_TOOLTIP_MSG = 'Aircraft is not onboarded yet.';
