/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: List view component
 */

import React from 'react';
import styled from '@emotion/styled';
import {ListGridColumn, ListGridV2, HorizontalTabs, ShareLink, MenuBar} from '@viasat/insights-components';
import PageStyled from '../../theme/PageStyled';
import {lookupTableTabDefinitions} from './tabDefinitions';
import {getElementId} from '../../utils/elementIdUtils';
import {getCurrentUrlPath} from '../../utils/linkUtils';
import {MODAL_FONT_COLOR} from '../common/theme/Colors';
import {ColumnSortState} from '../../store/reducers/ColumnSortReducer';

export interface ListViewComponentProps {
  idPrefix: string;
  isLoading: boolean;
  tabs?: JSX.Element;
  listColumns: ListGridColumn[];
  listData: any;
  listTotal: number;
  arePrereqsLoading: boolean;
  headerActionButton?: JSX.Element[];
  listContainerHeight?: string;
  handleRowSelection?: (selectedRow: any) => void;
  handleRowDeselection?: (removedRow: any) => void;
  rowSelectionKey?: string;
  rowDisableKey?: string;
  selectedRows?: any[];
  handleSortChange?: ({column, order}: ColumnSortState) => void;
  selectedSort?: ColumnSortState;
}

const ListGridContainer = styled.div<{height?: string}>`
  position: relative;
  height: calc(100vh - ${props => (props.height ? props.height : '105px')});
  overflow: hidden;
  margin-left: 12px;
  @media only screen and (max-width: 1178px) {
    height: calc(100vh - ${props => (props.height ? props.height : '105px')});
  }
  @media only screen and (max-width: 548px) {
    height: calc(100vh - ${props => (props.height ? props.height : '105px')});
  }
  .BaseTable__table-frozen-right .BaseTable__header-cell:first-of-type,
  .BaseTable__table-frozen-right .BaseTable__row-cell:first-of-type,
  .BaseTable__table-main .BaseTable__row-cell:last-of-type {
    padding-right: 0px;
  }
  .BaseTable__table-frozen-right {
    box-shadow: none;
    .BaseTable__header-row:first-of-type .BaseTable__header-cell:last-of-type {
      border-left: none;
    }
    .BaseTable__header-row:last-of-type .BaseTable__header-cell:last-of-type {
      border-left: none;
    }
    .BaseTable__row-cell:last-of-type {
      border-left: 1px solid ${MODAL_FONT_COLOR};
    }
  }
`;

const ListViewComponent = (props: ListViewComponentProps) => {
  const {
    idPrefix,
    headerActionButton,
    listColumns,
    listData,
    listTotal,
    arePrereqsLoading,
    isLoading,
    listContainerHeight,
    selectedSort,
    handleSortChange
  } = props;

  const urlPath = getCurrentUrlPath();

  return (
    <PageStyled>
      <MenuBar
        getFullElementId={(name, type) => `${idPrefix}--pageContainer__${name}-${type}`}
        leftStack={headerActionButton}
        rightStack={[
          <ShareLink
            getFullElementId={(name, type) => getElementId(idPrefix, 'shareLink', name, type)}
            disabled={isLoading}
            urlPath={urlPath}
            urlQuery={''}
          />
        ]}
        tabs={
          <HorizontalTabs
            getFullElementId={(name, type) => getElementId(idPrefix, 'listTabs', name, type)}
            tabs={lookupTableTabDefinitions}
            titleCount={!false ? listData?.length : undefined}
          ></HorizontalTabs>
        }
      />
      <ListGridContainer height={listContainerHeight}>
        <ListGridV2
          columns={listColumns}
          listGridData={listData ? listData : []}
          listGridDataTotal={listTotal}
          isHeaderLoading={arePrereqsLoading}
          isLoading={isLoading}
          fullWidthLoadingBars={true}
          handleSortChange={handleSortChange}
          selectedSort={selectedSort}
        />
      </ListGridContainer>
    </PageStyled>
  );
};

export default ListViewComponent;
