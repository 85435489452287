/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ListGrid icon text or link cell
 *
 */
import React from 'react';
import styled from '@emotion/styled';
import {GRID_CELL_LINK_COLOR, GRID_CELL_TEXT_ACTIVE_COLOR, GRID_CELL_TEXT_DURATION_COLOR} from '../../theme/Colors';

interface ListGridIconTextOrLinkProps {
  cellIdBase: string;
  text?: string | JSX.Element;
  icon?: any;
  tooltipText?: string;
  descriptionText?: string;
  textUnderlineColor?: string;
  handleClick?: (rowData: any, $event?: any) => void;
  rowData: any;
}

const Container = styled.div<{text: string | JSX.Element}>`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: ${props => (props.text ? 'flex' : 'inline-flex')};
  flex-direction: row;
`;

const Icon = styled.div`
  width: 12px;
  height: 16px;
`;

const TextOrLink = styled.div<{hasDescription: boolean}>`
  width: ${props => (props.hasDescription ? '98px' : 'auto')};
  color: ${GRID_CELL_TEXT_ACTIVE_COLOR};
  margin-left: 10px;
  margin-right: 8px;
`;

const StyledLink = styled.a<{decorationColor: boolean}>`
  color: ${GRID_CELL_LINK_COLOR};
  text-decoration-color: ${props => (props.decorationColor ? GRID_CELL_TEXT_DURATION_COLOR : '')};
`;

const Description = styled.div`
  min-width: 70px;
  width: 100%;
  color: ${GRID_CELL_TEXT_DURATION_COLOR};
  text-align: right;
  margin-right: 16px;
`;

export const ListGridIconTextOrLink: React.FC<ListGridIconTextOrLinkProps> = ({
  cellIdBase,
  icon,
  text,
  tooltipText,
  descriptionText,
  textUnderlineColor,
  handleClick,
  rowData
}) => {
  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    handleClick(rowData, event);
  };
  return (
    <>
      <Container id={`${cellIdBase}_iconTextOrLink`} text={text}>
        {icon ? <Icon>{icon}</Icon> : <></>}
        {text !== undefined ? (
          <TextOrLink hasDescription={descriptionText !== null}>
            {handleClick ? (
              <StyledLink
                href="#"
                decorationColor={textUnderlineColor != null}
                onClick={onLinkClick}
                id={`${cellIdBase}_iconWithLink`}
              >
                {text}
              </StyledLink>
            ) : (
              <span id={`${cellIdBase}_iconWithText`}>{text}</span>
            )}
          </TextOrLink>
        ) : (
          <></>
        )}

        {descriptionText && (
          <Description id={`${cellIdBase}_iconWithTextandDescription`}>{descriptionText}</Description>
        )}
      </Container>
    </>
  );
};

export default ListGridIconTextOrLink;
