/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Decommission Service Plan
 */
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useForm, useWatch} from 'react-hook-form';
import {isNil} from 'lodash';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {
  BottomHorizontalRule,
  FormContainerLeftPanel,
  HeaderHorizontalRule,
  HeaderLabel,
  NextButton,
  PageDescription
} from '../CommonStyles';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import {useStore} from '../../store/Store';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import SectionAlertMessage from '../common/SectionAlertMessage';
import {validateDropdownField} from '../../utils/validationUtils';
import {AppAction} from '../../store/reducers/AppReducer';
import {decommissionServicePlanQuery} from '../../store/queries/servicePlanInfoQueries';
import {useCreateApprovalRequestOnServicePlanSubmit} from '../../utils/customHooks';
import {HELP_TEXT_COLOR} from '../common/theme/Colors';
import useFetchV2 from '../../utils/useFetchV2';

const ID_PREFIX = 'servicePlan-decommission';

const OTHER_OPTION = 'Other';
const DECOMMISSION_REASONS = [
  'Contract ended and not renewing',
  'Aircraft sold',
  'Aircraft Airworthiness',
  'Inoperable terminal hardware',
  'Transfer of Service Provider',
  'Management Company transfer',
  'Customer is subject to Early Termination fees',
  OTHER_OPTION
];

interface DecommissionServicePlanProps {
  title: string;
}

interface IFormInput {
  decommissionDate: string;
  decommissionReason: string;
  decommissionOtherReason?: string;
}

export const TextFieldInputProps = {
  style: {
    maxHeight: 200,
    height: 70,
    color: HELP_TEXT_COLOR,
    fontFamily: 'Source Sans Pro'
  }
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
      color: HELP_TEXT_COLOR
    }
  }
};

const DecommissionServicePlan: React.FC<DecommissionServicePlanProps> = ({title}) => {
  const LeftChild = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {store, dispatch} = useStore();
    const taskId = store.servicePlan?.decommissionServicePlan?.taskId;
    const {serialNumber, tailId, make, model, network} = store.aircraftInfo;
    const {breadcrumb} = store.app;
    const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';

    let initialValues: IFormInput = {
      decommissionDate: null,
      decommissionReason: 'Select an option',
      decommissionOtherReason: ''
    };

    const {handleSubmit, formState, watch, control} = useForm();
    const [formData, setFormData] = useState(null);
    const [decommissionDate, setDecommissionDate] = useState(null);

    const selectedReasonType: string = useWatch({
      control,
      name: 'decommissionReason'
    });

    const decommissionOtherReason: string = useWatch({
      control,
      name: 'decommissionOtherReason'
    });

    const decommissionDateFormatted =
      decommissionDate && decommissionDate.c
        ? decommissionDate.c.month + '/' + decommissionDate.c.day + '/' + decommissionDate.c.year
        : initialValues.decommissionDate;

    const isOtherReason = selectedReasonType === OTHER_OPTION;

    const servicePlanInfoFormFields: FormField[] = [
      {
        id: 'decommissionDate',
        label: 'Decommission Date',
        type: FormFieldType.DATE,
        value: decommissionDateFormatted,
        setDate: setDecommissionDate,
        disabled: false,
        menuProps: MenuProps,
        dropDownValues: [],
        placeholder: '__/__/____',
        description: null,
        rules: {
          required: true,
          pattern: null
        }
      },
      {
        id: 'decommissionReason',
        label: 'Reason',
        type: FormFieldType.SELECT,
        value: initialValues.decommissionReason,
        placeholder: 'tada',
        disabled: false,
        menuProps: MenuProps,
        dropDownValues: DECOMMISSION_REASONS,
        description: null,
        rules: {
          required: true,
          pattern: null,
          validate: (value, _) => validateDropdownField(value, 'Reason')
        }
      }
    ];

    if (isOtherReason) {
      servicePlanInfoFormFields.push({
        id: 'decommissionOtherReason',
        label: '',
        type: FormFieldType.TEXTFIELD,
        value: initialValues.decommissionOtherReason,
        placeholder: 'Reason',
        inputProps: TextFieldInputProps,
        isMultiline: true,
        fullWidth: true,
        disabled: false,
        rules: {
          required: 'Reason for Other is required',
          maxLength: {
            value: 250,
            message: 'Reason for Other cannot exceed more than 250 characters'
          },
          pattern: null
        },
        helperText: `${decommissionOtherReason?.length | 0} / 250`
      });
    }

    const queryParams = useMemo(() => {
      if (
        isNil(tailId) ||
        isNil(serialNumber) ||
        isNil(formData?.decommissionDate) ||
        isNil(formData?.decommissionReason)
      )
        return;
      return {
        groupCode,
        taskId,
        serialNumber,
        tailId,
        make,
        model,
        network,
        decommissionDate: formData.decommissionDate,
        decommissionReason:
          formData?.decommissionReason === OTHER_OPTION ? formData.decommissionOtherReason : formData.decommissionReason
      };
      //eslint-disable-next-line
    }, [formData, groupCode]);

    const {isLoading: inProgress, data: servicePlanInfo} = useFetchV2(
      {route: decommissionServicePlanQuery.route, params: queryParams},
      decommissionServicePlanQuery.transform
    );

    const {isSubmitRequestLoading, submitResponse} = useCreateApprovalRequestOnServicePlanSubmit(
      servicePlanInfo?.taskId,
      false
    );

    useEffect(() => {
      if (inProgress || isNil(servicePlanInfo) || isSubmitRequestLoading || isNil(submitResponse)) return;
      const operation = null;
      const message = 'We have received your request.';
      const details = 'You will receive an email upon Viasat review.';
      dispatch({
        type: AppAction.SET_BREADCRUMB,
        payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
      });
      dispatch({
        type: AppAction.SET_ALERT_INFO,
        payload: {
          showAlert: true,
          operation: operation,
          message: message,
          details: details
        }
      });
      navigate('/main-menu');
      //eslint-disable-next-line
    }, [dispatch, inProgress, servicePlanInfo, isSubmitRequestLoading, submitResponse]);

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Decommission Service</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--pageDescription`}>
          Please define the desired date you wish to decommission service.
        </PageDescription>
        <BottomHorizontalRule />
        <FormContainerLeftPanel>
          <SectionAlertMessage
            idPrefix={ID_PREFIX}
            message="Decommissioning service will shut down all Viasat connectivity services."
          />
          <FormComponent
            idPrefix={ID_PREFIX}
            id="change-service-plan-information-form"
            formFields={servicePlanInfoFormFields}
            setFormData={setFormData}
            formControlMethods={{handleSubmit, formState, watch, control}}
          ></FormComponent>
        </FormContainerLeftPanel>
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton form="change-service-plan-information-form" id={`${ID_PREFIX}--nextButton`} disabled={false}>
        <span className="next-button-text">Next</span>
        <ArrowForwardIcon className="next-arrow" />
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<LeftChild />} actionButtons={<ActionButtons />} />;
};

export default DecommissionServicePlan;
