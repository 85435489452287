/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: validate aircraft hooks
 */

import {find, isArray, isNil, map} from 'lodash';
import {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {IAircraft, identifyAircraftQuery} from '../queries/identifyAircraft/identifyAircraftQuery';
import {startNewInstallQuery} from '../store/queries/newInstall/startNewInstallQuery';
import {AircraftInfoAction, AircraftInfoState} from '../store/reducers/AircraftInfoReducer';
import {EquipmentInfoAction} from '../store/reducers/EquipmentInfoReducer';
import {CustomerInfoAction} from '../store/reducers/CustomerInfoReducer';
import {OpenTasksInfoAction} from '../store/reducers/OpenTasksReducer';
import {ServicePlanAction} from '../store/reducers/ServicePlanReducer';
import {StatusAction} from '../store/reducers/StatusReducer';
import {useStore} from '../store/Store';
import {useGetCustomerInfo, useGetEquipmentInfo, useGetServicePlanInfo} from './customHooks';
import {contactViasatQuery} from '../store/queries/contactViasatQuery';
import {AppAction} from '../store/reducers/AppReducer';
import useFetchV2 from './useFetchV2';
import {EQUIPMENT_DETAIL_MAPPING, KA_NETWORK, KU_NETWORK, TASK_TYPES} from '../components/common/constants';
import {openTaskQuery} from '../store/queries/openTaskListQuery';

interface IAircraftInformation {
  tailId?: string;
  serialNumber?: string;
  make?: string;
  model?: string;
  network?: string;
}

enum VALIDATE_AIRCRAFT_USECASES {
  NEW_AIRCRAFT = 'NEW_AIRCRAFT',
  NEW_AIRCRAFT_WITH_ONGOING_TASK = 'NEW_AIRCRAFT_WITH_ONGOING_TASK',
  EXISTING_AIRCRAFT = 'EXISTING_AIRCRAFT',
  EXISTING_AIRCRAFT_WITH_ONGOING_TASK = 'EXISTING_AIRCRAFT_WITH_ONGOING_TASK',
  AIRCRAFT_WITH_UPDATED_DETAILS = 'AIRCRAFT_WITH_UPDATED_DETAILS',
  REQUEST_ACCESS_FLOW = 'REQUEST_ACCESS_FLOW'
}

/**
 * identifies the use case based on validate aircraft API response
 * @param isExists is it a already exisitng aircraft
 * @param isNewInstall is it a new installation
 * @param isRequestSubmittedForApproval is the request already submitted for approval
 * @param isValidationWithUpdatedDetails is the validation from aircraft details page
 * @param hasOngoingTask does aircraft have an ongoing task
 * @returns use case
 */
export const identifyUsecase = (
  isExists: boolean,
  isNewInstall: boolean,
  isRequestSubmittedForApproval: boolean,
  isValidationWithUpdatedDetails: boolean,
  hasOngoingTask: boolean,
  userHaveAccess: boolean
) => {
  if (userHaveAccess === false){
    return VALIDATE_AIRCRAFT_USECASES.REQUEST_ACCESS_FLOW;
  } else if (isNewInstall && !isRequestSubmittedForApproval && isValidationWithUpdatedDetails) {
    return VALIDATE_AIRCRAFT_USECASES.AIRCRAFT_WITH_UPDATED_DETAILS;
  } else if (!isExists && isNewInstall && !hasOngoingTask) {
    return VALIDATE_AIRCRAFT_USECASES.NEW_AIRCRAFT;
  } else if (isExists && isNewInstall && hasOngoingTask) {
    return VALIDATE_AIRCRAFT_USECASES.NEW_AIRCRAFT_WITH_ONGOING_TASK;
  } else if (isExists && !isNewInstall && !hasOngoingTask) {
    return VALIDATE_AIRCRAFT_USECASES.EXISTING_AIRCRAFT;
  } else if (isExists && !isNewInstall && hasOngoingTask) {
    return VALIDATE_AIRCRAFT_USECASES.EXISTING_AIRCRAFT_WITH_ONGOING_TASK;
  }
};
/**
 * Hook to validate a given serial number
 * @param aircraft aircraft information that includes tailId, serialNumber, make, model, network
 * @param setShowValidationStatusModal method to open a modal
 * @param navigationUrl url to be navigated once all effects are executed
 * @param allowDataRest optional config to disable or enable data reset
 * @param onDataResetComplete callback function to be executed once all effects are executed
 * @returns validation results
 */

export function useValidateSerialNumber(
  aircraft: IAircraftInformation,
  setShowValidationStatusModal: (openModal: boolean) => void,
  isNewInstall: boolean,
  navigationUrl: string = '/main-menu',
  allowDataRest: boolean = true,
  onDataResetComplete?: () => void
) {
  const {dispatch, store} = useStore();
  const navigate = useNavigate();
  const groupCode = store.customer.current.code;
  const queryParams = useMemo(() => {
    if (isNil(aircraft) || !isNewInstall) return;
    return {
      serialNumber: aircraft.serialNumber,
      make: aircraft.make,
      model: aircraft.model,
      network: aircraft.network,
      tailId: aircraft.tailId,
      groupCode
    };
  }, [aircraft, isNewInstall, groupCode]);
  const {
    refetch: validatedAircraftReFetch,
    isLoading: validateInProgress,
    fetchStatus: validateFetchStatus,
    data: validatedAircraft,
    isFetching: validatedAircraftIsFetching
  } = useFetchV2({route: identifyAircraftQuery.route, params: queryParams}, identifyAircraftQuery.transform);
  useEffect(() => {
    if (validateInProgress || !allowDataRest) return;
    if (validatedAircraft) {
      dispatch({type: AircraftInfoAction.RESET_AIRCRAFT});
      dispatch({type: OpenTasksInfoAction.RESET_TASK});
      dispatch({type: EquipmentInfoAction.RESET_EQUIPMENT_INFO});
      dispatch({type: CustomerInfoAction.RESET_CUSTOMER_INFO});
      dispatch({type: ServicePlanAction.RESET_SERVICE_PLAN});
      dispatch({type: StatusAction.RESET_STATUS});

      dispatch({type: AircraftInfoAction.SET_USE_CASE, payload: validatedAircraft.isNewInstall});
      dispatch({
        type: AircraftInfoAction.SET_IS_REQUEST_SUBMITTED_FLAG,
        payload: validatedAircraft.isRequestSubmittedForApproval
      });

      const useCase = identifyUsecase(
        validatedAircraft.isExists,
        validatedAircraft.isNewInstall,
        validatedAircraft.isRequestSubmittedForApproval,
        validatedAircraft.isValidationWithUpdatedDetails,
        !!validatedAircraft.aircraftDetails?.taskId,
        validatedAircraft.userHaveAccess
      );
      switch (useCase) {
        case VALIDATE_AIRCRAFT_USECASES.REQUEST_ACCESS_FLOW: {
          setShowValidationStatusModal(true);
          break
        }

        case VALIDATE_AIRCRAFT_USECASES.AIRCRAFT_WITH_UPDATED_DETAILS: {
          setShowValidationStatusModal(false);
          break;
        }

        case VALIDATE_AIRCRAFT_USECASES.NEW_AIRCRAFT: {
          setShowValidationStatusModal(true);
          break;
        }
        case VALIDATE_AIRCRAFT_USECASES.NEW_AIRCRAFT_WITH_ONGOING_TASK: {
          setShowValidationStatusModal(true);
          break;
        }

        case VALIDATE_AIRCRAFT_USECASES.EXISTING_AIRCRAFT_WITH_ONGOING_TASK: {
          setShowValidationStatusModal(true);
          break;
        }
        case VALIDATE_AIRCRAFT_USECASES.EXISTING_AIRCRAFT: {
          dispatch({
            type: AircraftInfoAction.SET_AIRCRAFT,
            payload: {
              ...validatedAircraft.aircraftDetails,
              taskId:
                validatedAircraft.taskDetails && validatedAircraft.taskDetails.length
                  ? validatedAircraft.taskDetails[0].taskId
                  : null
            }
          });
          if (validatedAircraft.equipmentDetail && validatedAircraft.equipmentDetail?.length) {
            const equipmentDetail = map(validatedAircraft.equipmentDetail, e => ({
              [e.configType.replace(' ', '') + e.configSubType]: e
            }));
            if (validatedAircraft.aircraftDetails.network === KA_NETWORK) {
              const modemKa = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KA_MODEM);
              if (modemKa) {
                dispatch({
                  type: EquipmentInfoAction.SET_KA_MODEM_INFO,
                  payload: modemKa[EQUIPMENT_DETAIL_MAPPING.KA_MODEM]
                });
              }

              const antennaKa = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KA_ANTENNA);
              if (antennaKa) {
                dispatch({
                  type: EquipmentInfoAction.SET_KA_ANTENNA_INFO,
                  payload: antennaKa[EQUIPMENT_DETAIL_MAPPING.KA_ANTENNA]
                });
              }

              const apsuKa = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KA_APSU);
              if (apsuKa) {
                dispatch({type: EquipmentInfoAction.SET_APSU_INFO, payload: apsuKa[EQUIPMENT_DETAIL_MAPPING.KA_APSU]});
              }

              // Reset Ku equipments
              dispatch({type: EquipmentInfoAction.SET_KU_MODEM_INFO, payload: null});
              dispatch({type: EquipmentInfoAction.SET_KU_ANTENNA_INFO, payload: null});
              dispatch({type: EquipmentInfoAction.SET_ACU_INFO, payload: null});
            } else if (validatedAircraft.aircraftDetails.network === KU_NETWORK) {
              const modemKu = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KU_MODEM);
              if (modemKu) {
                dispatch({
                  type: EquipmentInfoAction.SET_KU_MODEM_INFO,
                  payload: modemKu[EQUIPMENT_DETAIL_MAPPING.KU_MODEM]
                });
              }
              const antennaKu = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KU_ANTENNA);
              if (antennaKu) {
                dispatch({
                  type: EquipmentInfoAction.SET_KU_ANTENNA_INFO,
                  payload: antennaKu[EQUIPMENT_DETAIL_MAPPING.KU_ANTENNA]
                });
              }
              const acuKu = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KU_ACU);
              if (acuKu) {
                dispatch({type: EquipmentInfoAction.SET_ACU_INFO, payload: acuKu[EQUIPMENT_DETAIL_MAPPING.KU_ACU]});
              }
              // Reset Ka equipments
              dispatch({type: EquipmentInfoAction.SET_KA_MODEM_INFO, payload: null});
              dispatch({type: EquipmentInfoAction.SET_KA_ANTENNA_INFO, payload: null});
              dispatch({type: EquipmentInfoAction.SET_APSU_INFO, payload: null});
            } else {
              const modemKa = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KA_MODEM);
              if (modemKa) {
                dispatch({
                  type: EquipmentInfoAction.SET_KA_MODEM_INFO,
                  payload: modemKa[EQUIPMENT_DETAIL_MAPPING.KA_MODEM]
                });
              }

              const antennaKa = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KA_ANTENNA);
              if (antennaKa) {
                dispatch({
                  type: EquipmentInfoAction.SET_KA_ANTENNA_INFO,
                  payload: antennaKa[EQUIPMENT_DETAIL_MAPPING.KA_ANTENNA]
                });
              }

              const apsuKa = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KA_APSU);
              if (apsuKa) {
                dispatch({type: EquipmentInfoAction.SET_APSU_INFO, payload: apsuKa[EQUIPMENT_DETAIL_MAPPING.KA_APSU]});
              }
              const modemKu = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KU_MODEM);
              if (modemKu) {
                dispatch({
                  type: EquipmentInfoAction.SET_KU_MODEM_INFO,
                  payload: modemKu[EQUIPMENT_DETAIL_MAPPING.KU_MODEM]
                });
              }
              const antennaKu = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KU_ANTENNA);
              if (antennaKu) {
                dispatch({
                  type: EquipmentInfoAction.SET_KU_ANTENNA_INFO,
                  payload: antennaKu[EQUIPMENT_DETAIL_MAPPING.KU_ANTENNA]
                });
              }
              const acuKu = find(equipmentDetail, EQUIPMENT_DETAIL_MAPPING.KU_ACU);
              if (acuKu) {
                dispatch({type: EquipmentInfoAction.SET_ACU_INFO, payload: acuKu[EQUIPMENT_DETAIL_MAPPING.KU_ACU]});
              }
            }
          } else {
            if (validatedAircraft.aircraftDetails.network === KA_NETWORK) {
              // Reset Ku equipments
              dispatch({type: EquipmentInfoAction.SET_KU_MODEM_INFO, payload: null});
              dispatch({type: EquipmentInfoAction.SET_KU_ANTENNA_INFO, payload: null});
              dispatch({type: EquipmentInfoAction.SET_ACU_INFO, payload: null});
            } else if (validatedAircraft.aircraftDetails.network === KU_NETWORK) {
              // Reset Ka equipments
              dispatch({type: EquipmentInfoAction.SET_KA_MODEM_INFO, payload: null});
              dispatch({type: EquipmentInfoAction.SET_KA_ANTENNA_INFO, payload: null});
              dispatch({type: EquipmentInfoAction.SET_APSU_INFO, payload: null});
            }
          }
          if (!isNil(validatedAircraft.servicePlanDetail)) {
            dispatch({
              type: ServicePlanAction.SET_SERVICE_PLAN,
              payload: {
                servicePlanType: validatedAircraft.servicePlanDetail.servicePlanName,
                contractTerm: validatedAircraft.servicePlanDetail.contractTerm,
                planStartDate: validatedAircraft.servicePlanDetail.servicePlanStartDate
              }
            });
          }
          navigationUrl && navigate(navigationUrl);
          break;
        }
      }
      onDataResetComplete && onDataResetComplete();
    }
    // eslint-disable-next-line
  }, [validateInProgress, validatedAircraft, allowDataRest]);

  return {
    validateInProgress,
    validateFetchStatus,
    validatedAircraft,
    validatedAircraftReFetch,
    validatedAircraftIsFetching
  };
}
/**
 * Hook to start a new install
 * @param aircraft details of the new aircraft
 */
export function useStartNewInstall(aircraft: AircraftInfoState) {
  const {dispatch, store} = useStore();
  const navigate = useNavigate();
  const groupCode = store.customer.current.code;
  const queryParamsForNewInstall = useMemo(() => {
    if (!aircraft?.make) return;
    return {
      ...aircraft,
      groupCode: groupCode
    };
  }, [aircraft, groupCode]);
  const {isLoading: newInstallInProgress, data: newInstallData} = useFetchV2(
    {route: startNewInstallQuery.route, params: queryParamsForNewInstall},
    startNewInstallQuery.transform
  );

  if (!newInstallInProgress && !isNil(newInstallData.taskId)) {
    dispatch({
      type: OpenTasksInfoAction.SET_NEW_TASK,
      payload: {taskId: newInstallData.taskId, serialNumber: newInstallData.serialNumber, tailId: newInstallData.tailId}
    });
    dispatch({
      type: EquipmentInfoAction.SET_TASK_ID,
      payload: newInstallData.taskId
    });
    dispatch({type: AircraftInfoAction.SET_AIRCRAFT, payload: aircraft});
    navigate('/main-menu');
  }
  return newInstallInProgress;
}

/**
 * Hook to send contact viasat request
 * @param contactDetails details of the contact form
 */
export function useContactViasat(contactDetails: any) {
  const {dispatch} = useStore();
  const navigate = useNavigate();
  const queryParams = useMemo(() => {
    if (!contactDetails) return;
    return contactDetails;
  }, [contactDetails]);
  const {isLoading: contactViasatIsLoading, data: contactViasatData} = useFetchV2(
    {route: contactViasatQuery.route, params: queryParams},
    contactViasatQuery.transform
  );

  if (!contactViasatIsLoading && !isNil(contactViasatData)) {
    const operation = 'added';
    const message = 'Your message has been sent!';
    const details = 'Your message has been sent to Viasat. Support staff will reply via email as quickly as possible.';
    dispatch({
      type: AppAction.SET_ALERT_INFO,
      payload: {
        showAlert: true,
        operation: operation,
        message: message,
        details: details
      }
    });
    navigate('/main-menu');
  }
  return {contactViasatIsLoading, contactViasatData};
}

/**
 * Hook to set Aircraft details to store
 * @param aircraft details of the new aircraft
 */
export function useSetAircraftInfo(continueExistingTask: boolean, aircraftDetails: IAircraft) {
  const {dispatch} = useStore();
  useEffect(() => {
    if (!continueExistingTask) return;
    dispatch({
      type: AircraftInfoAction.SET_AIRCRAFT,
      payload: {
        serialNumber: aircraftDetails?.serialNumber,
        tailId: aircraftDetails?.tailId,
        make: aircraftDetails?.make,
        model: aircraftDetails?.model,
        network: aircraftDetails?.network,
        installationType: aircraftDetails?.installationType
      }
    });
    dispatch({
      type: OpenTasksInfoAction.SET_NEW_TASK,
      payload: {
        taskId: aircraftDetails.taskId,
        serialNumber: aircraftDetails.serialNumber,
        tailId: aircraftDetails.tailId
      }
    });
    dispatch({
      type: EquipmentInfoAction.SET_TASK_ID,
      payload: aircraftDetails.taskId
    });
    // eslint-disable-next-line
  }, [continueExistingTask, aircraftDetails?.serialNumber]);
}
/**
 * Hook to retrieve task details and update the store
 * @param retrieveTaskDetails whether retrieve task details from db
 * @param navigateFromOpenTask Checks open task navigation
 * @param taskId taskId
 * @param taskType type of task
 * @param groupCode loggedInUser group code
 * @param navigateRoute path to navigate after fetching task details
 */
export const useGetTaskDetails = (
  retrieveTaskDetails: boolean,
  navigateFromOpenTask: boolean,
  taskDetails: [] | any,
  groupCode: string,
  navigateRoute: string,
  aircraftDetails?: AircraftInfoState
) => {
  const {dispatch} = useStore();
  let aircraftInfoTaskId = null;
  let equipmentTaskId = null;
  let customerInfoTaskId = null;
  let servicePlanTaskId = null;
  let taskInfo = null;
  let equipmentInfoStatus = null;
  let customerInfoStatus = null;
  let servicePlanStatus = null;
  let tasksList = [];
  if (navigateFromOpenTask) {
    tasksList.push(taskDetails);
  }
  let taskItems = navigateFromOpenTask ? tasksList : taskDetails;
  const taskStatusKey = navigateFromOpenTask ? 'status' : 'taskStatus';
  if (isArray(taskItems) && taskItems.length > 0) {
    taskItems.forEach(task => {
      taskInfo = {...task, ...aircraftDetails};
      switch (task?.['taskType']) {
        case TASK_TYPES.NEW_INSTALL:
          equipmentTaskId = task['taskId'];
          equipmentInfoStatus = task[taskStatusKey];
          break;
        case TASK_TYPES.REPLACE_HARDWARE:
          equipmentTaskId = task['taskId'];
          equipmentInfoStatus = task[taskStatusKey];
          break;
        case TASK_TYPES.NEW_CUSTOMER:
          customerInfoTaskId = task['taskId'];
          customerInfoStatus = task[taskStatusKey];
          break;
        case TASK_TYPES.NEW_SERVICE_PLAN:
          servicePlanTaskId = task['taskId'];
          servicePlanStatus = task[taskStatusKey];
          break;
        case TASK_TYPES.CHANGE_AIRCRAFT_DETAILS:
          aircraftInfoTaskId = retrieveTaskDetails ? task['taskId'] : null;
          break;
        case TASK_TYPES.SUSPEND_MONTHLY_SERVICE_PLAN:
          servicePlanTaskId = task['taskId'];
          servicePlanStatus = task[taskStatusKey];
          break;
        case TASK_TYPES.SUSPEND_MAINTENANCE_SERVICE_PLAN:
          servicePlanTaskId = task['taskId'];
          servicePlanStatus = task[taskStatusKey];
          break;
        case TASK_TYPES.RESUME_SERVICE:
          servicePlanTaskId = task['taskId'];
          servicePlanStatus = task[taskStatusKey];
          break;
        case TASK_TYPES.BARRING_SERVICE:
          servicePlanTaskId = task['taskId'];
          servicePlanStatus = task[taskStatusKey];
          break;
        case TASK_TYPES.DECOMMISSION_SERVICE:
          servicePlanTaskId = task['taskId'];
          servicePlanStatus = task[taskStatusKey];
          break;
      }
    });
  }
  const doesMultipleTaskExistsForSN = isArray(taskDetails);
  // Take the single taskId from open tasks and query for the data
  if (!isNil(taskDetails) && !isArray(taskDetails)) {
    taskInfo = taskDetails;
    equipmentTaskId = taskDetails['taskId'];
    customerInfoTaskId = taskDetails['taskId'];
  }
  const navigate = useNavigate();

  const equipmentInfoLoading = useGetEquipmentInfo(retrieveTaskDetails, equipmentTaskId, groupCode);

  const customerInfoLoading = useGetCustomerInfo(retrieveTaskDetails, customerInfoTaskId, groupCode);

  const servicePlanInfoLoading = useGetServicePlanInfo(retrieveTaskDetails, servicePlanTaskId, groupCode);

  // To navigate in case of Equipment Details Task
  useEffect(() => {
    if (equipmentInfoLoading) return;
    dispatch({
      type: AircraftInfoAction.SET_AIRCRAFT,
      payload: {
        isNewInstall: taskInfo.taskType === 'NewInstall',
        serialNumber: taskInfo.serialNumber,
        tailId: taskInfo.tailId,
        make: taskInfo.make,
        model: taskInfo.model,
        network: taskInfo.network,
        aircraftNetwork: taskInfo.network,
        endUser: null,
        valueAddedReseller: null,
        installationType: taskInfo.installationType
      }
    });
    dispatch({
      type: AircraftInfoAction.SET_TASK_ID,
      payload: equipmentTaskId
    });
    dispatch({
      type: StatusAction.SET_STATUS,
      payload: {
        equipmentInformation: equipmentInfoStatus,
        customerInformation: customerInfoStatus,
        servicePlanInformation: servicePlanStatus
      }
    });
    if (!doesMultipleTaskExistsForSN) {
      navigate(navigateRoute);
    }

    // eslint-disable-next-line
  }, [equipmentInfoLoading]);
  // To navigate in case of Customer Task
  useEffect(() => {
    if (customerInfoLoading) return;
    dispatch({
      type: AircraftInfoAction.SET_AIRCRAFT,
      payload: {
        isNewInstall: taskInfo.taskType === 'NewInstall',
        serialNumber: taskInfo.serialNumber,
        tailId: taskInfo.tailId,
        make: taskInfo.make,
        model: taskInfo.model,
        network: taskInfo.network,
        aircraftNetwork: taskInfo.network,
        endUser: null,
        valueAddedReseller: null,
        installationType: taskInfo.installationType
      }
    });
    dispatch({
      type: AircraftInfoAction.SET_TASK_ID,
      payload: customerInfoTaskId
    });
    dispatch({
      type: StatusAction.SET_STATUS,
      payload: {
        equipmentInformation: equipmentInfoStatus,
        customerInformation: customerInfoStatus,
        servicePlanInformation: servicePlanStatus
      }
    });
    if (!doesMultipleTaskExistsForSN) {
      navigate(navigateRoute);
    }
    // eslint-disable-next-line
  }, [customerInfoLoading]);
  // To navigate in case of Service Plan Task
  useEffect(() => {
    if (servicePlanInfoLoading) return;
    dispatch({
      type: AircraftInfoAction.SET_AIRCRAFT,
      payload: {
        isNewInstall: taskInfo.taskType === 'NewInstall',
        serialNumber: taskInfo.serialNumber,
        tailId: taskInfo.tailId,
        make: taskInfo.make,
        model: taskInfo.model,
        network: taskInfo.network,
        aircraftNetwork: taskInfo.network,
        endUser: null,
        valueAddedReseller: null,
        installationType: taskInfo.installationType
      }
    });
    dispatch({
      type: AircraftInfoAction.SET_TASK_ID,
      payload: servicePlanTaskId
    });
    dispatch({
      type: StatusAction.SET_STATUS,
      payload: {
        equipmentInformation: equipmentInfoStatus,
        customerInformation: customerInfoStatus,
        servicePlanInformation: servicePlanStatus
      }
    });
    if (!doesMultipleTaskExistsForSN) {
      navigate(navigateRoute);
    }
    // eslint-disable-next-line
  }, [servicePlanInfoLoading]);
  //To navigate in case of Change Aircraft Details Task
  useEffect(() => {
    if (isNil(aircraftInfoTaskId)) return;
    dispatch({
      type: AircraftInfoAction.SET_TASK_ID,
      payload: aircraftInfoTaskId
    });
    dispatch({
      type: StatusAction.SET_STATUS,
      payload: {
        equipmentInformation: equipmentInfoStatus,
        customerInformation: customerInfoStatus,
        servicePlanInformation: servicePlanStatus
      }
    });
    if (!doesMultipleTaskExistsForSN) {
      navigate(navigateRoute);
    }
    // eslint-disable-next-line
  }, [aircraftInfoTaskId]);
  // wait until all tasks information load for SN with multiple tasks
  useEffect(() => {
    if (!doesMultipleTaskExistsForSN) {
      return;
    }
    if (
      (taskItems.includes(task => task['taskType'] === TASK_TYPES.CHANGE_AIRCRAFT_DETAILS) &&
        isNil(aircraftInfoTaskId)) ||
      (equipmentTaskId && equipmentInfoLoading) ||
      (customerInfoTaskId && customerInfoLoading) ||
      (servicePlanTaskId && servicePlanInfoLoading)
    ) {
      return;
    }
    navigate(navigateRoute);
    // eslint-disable-next-line
  }, [aircraftInfoTaskId, equipmentInfoLoading, customerInfoLoading, servicePlanInfoLoading]);

  return {isLoading: equipmentInfoLoading || customerInfoLoading || servicePlanInfoLoading};
};

/**
 * Hook to retrieve open task details and update the store
 * @param serialNumber Aircraft Serialnumber
 * @param groupCode loggedInUser group code
 */
export const useGetOpenTaskDetails = (serialNumber: string, groupCode: string) => {
  const {dispatch} = useStore();

  // load open task data
  const queryParams = useMemo(() => {
    if (isNil(serialNumber) || isNil(groupCode)) return;
    return {
      groupCode,
      doCache: false,
      filters: {
        serialNumber: [serialNumber]
      }
    };
  }, [serialNumber, groupCode]);

  const {isLoading: openTasksListLoading, data: openTasksListData} = useFetchV2(
    {route: openTaskQuery.route, params: queryParams},
    openTaskQuery.transform
  );

  const taskStatusKey = 'status';
  const taskIdKey = 'taskId';

  // Update service plan menu status to store
  // And set task ID's for for each service plan options
  useEffect(() => {
    if (openTasksListLoading) return;

    let servicePlanStatus = null;
    let servicePlanSuspendStatus = null;
    let servicePlanBarringStatus = null;
    let servicePlanDecommissionStatus = null;
    let servicePlanResumeStatus = null;

    if (isArray(openTasksListData) && openTasksListData.length > 0) {
      openTasksListData.forEach(task => {
        let taskId = task[taskIdKey];
        let actionType = null;
        switch (task?.['taskType']) {
          case TASK_TYPES.NEW_SERVICE_PLAN:
            servicePlanStatus = task[taskStatusKey];
            break;
          case TASK_TYPES.CHANGE_SERVICE_PLAN:
            servicePlanStatus = task[taskStatusKey];
            break;
          case TASK_TYPES.SUSPEND_MONTHLY_SERVICE_PLAN:
            servicePlanSuspendStatus = task[taskStatusKey];
            actionType = ServicePlanAction.SET_SUSPEND_SERVICE_PLAN;
            break;
          case TASK_TYPES.SUSPEND_MAINTENANCE_SERVICE_PLAN:
            servicePlanSuspendStatus = task[taskStatusKey];
            actionType = ServicePlanAction.SET_SUSPEND_SERVICE_PLAN;
            break;
          case TASK_TYPES.BARRING_SERVICE:
            servicePlanBarringStatus = task[taskStatusKey];
            actionType = ServicePlanAction.SET_BARRING_SERVICE_PLAN;
            break;
          case TASK_TYPES.DECOMMISSION_SERVICE:
            servicePlanDecommissionStatus = task[taskStatusKey];
            actionType = ServicePlanAction.SET_DECOMMISSION_SERVICE_PLAN;
            break;
          case TASK_TYPES.RESUME_SERVICE:
            servicePlanResumeStatus = task[taskStatusKey];
            actionType = ServicePlanAction.SET_RESUME_SERVICE_PLAN;
            break;
        }
        if (taskId && actionType) {
          dispatch({
            type: actionType,
            payload: {
              taskId: taskId
            }
          });
        }
      });
    }
    dispatch({
      type: StatusAction.SET_STATUS,
      payload: {
        servicePlanInformation: servicePlanStatus,
        servicePlanSuspendInformation: servicePlanSuspendStatus,
        servicePlanBarringInformation: servicePlanBarringStatus,
        servicePlanDecommissionInformation: servicePlanDecommissionStatus,
        servicePlanResumeInformation: servicePlanResumeStatus
      }
    });
    // eslint-disable-next-line
  }, [openTasksListLoading, openTasksListData]);

  return {isLoading: openTasksListLoading, data: openTasksListData};
};
