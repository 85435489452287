/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Approvals List View
 */
import React, {useMemo, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  HorizontalTabs,
  MenuBar,
  ShareLink,
  ListGridV2,
  LoadingButton,
  ListGridColumn
} from '@viasat/insights-components';
import {approvalListTabs} from '../tabDefinitions';
import PageStyled from '../../../theme/PageStyled';
import {PageProps} from '../../common/layout/PageProps';
import ListGridContainer from '../../common/ListGridContainer';
import {getElementId} from '../../../utils/elementIdUtils';
import {approvalsListQuery} from '../../../store/queries/approvalsListQuery';
import SnackBar from '../../common/SnackBar';
import {useStore} from '../../../store/Store';
import {getCurrentUrlPath} from '../../../utils/linkUtils';
import {
  useSortingParams,
  useFilteringParams,
  downloadProgressPercentCalculation,
  getListViewFilters
} from '../listsUtil';
import {
  APPROVAL_LIST_FILTERS,
  ApprovalListColumnId,
  buildApprovalsListColumns,
  fetchDBColumnKey,
  getQueryFieldMapping
} from './ApprovalUtils';
import {handleExportClick} from './exportApprovalRequests';
import FilterHookContainer from '../../common/elements/filterSelector/FilterHookContainer';
import ListViewSortContainer from '../ListViewSortContainer';
import {APPROVAL_INFO_STORE_CONTEXT} from '../../../store/reducers/ApprovalReducer';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import {FilterAction} from '../../../store/reducers/FiltersReducer';
import {getFilterOptions} from '../../../utils/filterUtils';
import useFetchV2 from '../../../utils/useFetchV2';

const ID_PREFIX = 'approvalsList';

const ApprovalsList: React.FC<PageProps> = () => {
  const {store, dispatch} = useStore();

  const {snackbar} = store.app;
  const urlPath = getCurrentUrlPath();

  const navigate = useNavigate();

  const handleApprovalItemClick = (rowData: any) => {
    navigate(`/review-request/${rowData.taskId}/view`);
    return;
  };

  const {
    sort,
    filters: {filters = []}
  } = store.approvalInfo;

  const approvalsListColumns = buildApprovalsListColumns(handleApprovalItemClick);
  const [downloadProgressPercentage, setDownloadProgressPercentage] = useState(0);
  const [approvalListData, setapprovalListData] = useState<any>([]);
  const [timerIndex, setTimerIndex] = useState(-1);
  const clamp = (n: number, min: number, max: number) => Math.max(Math.min(n, max), min);
  const approvalListFilters = useMemo(() => getListViewFilters(APPROVAL_LIST_FILTERS), []);
  const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';
  const queryParams = useMemo(() => ({groupCode, doCache: false}), [groupCode]);
  const queryParamsWithFiltering = useFilteringParams(queryParams, approvalListFilters, filters);

  const queryParamsWithSorting = useSortingParams(queryParamsWithFiltering, sort.queryField, sort.order);

  const clearIndex = () => {
    if (timerIndex > -1) {
      clearTimeout(timerIndex);
      setTimerIndex(-1);
    }
  };

  useEffect(() => {
    clearIndex();
    if (downloadProgressPercentage > 0 && downloadProgressPercentage < 100) {
      // using line equation : ms = 2.278 * row + 2975
      const update = (7.778 * approvalsListColumns.length + 7975) / 50;
      const idx = window.setTimeout(() => {
        let amount = 0;
        const {progressAmount, progressValue} = downloadProgressPercentCalculation(downloadProgressPercentage, amount);
        amount = progressAmount;
        setDownloadProgressPercentage(clamp(progressValue + progressAmount, 0, 99.4));
      }, update);
      setTimerIndex(idx);
    }
    // eslint-disable-next-line
  }, [downloadProgressPercentage]);

  const {isLoading: approvalsListDataLoading, data: approvalListInfo} = useFetchV2(
    {route: approvalsListQuery.route, params: queryParamsWithSorting},
    approvalsListQuery.transform
  );
  useEffect(() => {
    if (approvalsListDataLoading) return;
    setapprovalListData(approvalListInfo);
  }, [approvalsListDataLoading, approvalListInfo]);

  // useEffect Purpose: Update the options available for filtering each of the filterable columns
  useEffect(() => {
    if (approvalListFilters) {
      const {domainOptions, rangeOptions} = getFilterOptions({listData: approvalListInfo}, approvalListFilters);

      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, APPROVAL_INFO_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [approvalListInfo, dispatch, approvalListFilters]);

  const exportColumns = fetchDBColumnKey();

  const handleExport = async (downloadSuccess, downloadFailure) => {
    handleExportClick(
      queryParamsWithSorting,
      exportColumns,
      downloadSuccess,
      setDownloadProgressPercentage,
      downloadFailure
    );
  };

  const setShowListNotification = (show: boolean) => {
    return;
  };
  const handleFilterChange = () => {
    setShowListNotification(true); // allows notification to be shown again on filter change
  };
  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(ApprovalListColumnId[columnId]);

  // Determine what columns to show and hide for the list grid
  const currentColumns = approvalsListColumns;

  const handleSortChange = ({column, order}: {column: string; order: string}) => {
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, APPROVAL_INFO_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(approvalsListColumns[column]),
        validColumns: approvalsListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: approvalsListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(approvalsListColumns[col.key])
        )
      }
    });
  };

  return (
    <PageStyled>
      <MenuBar
        getFullElementId={(name: string, type: string) => getElementId(ID_PREFIX, 'menuBar', name, type)}
        title={''}
        subtitle={''}
        isSubtitleLoading={false}
        leftStack={[
          <FilterHookContainer
            key="filters"
            idPrefix={ID_PREFIX}
            storeContext={APPROVAL_INFO_STORE_CONTEXT}
            handleFilterChange={handleFilterChange}
          />,
          <ListViewSortContainer
            key="sorts"
            idPrefix={ID_PREFIX}
            disabled={approvalsListDataLoading}
            storeContext={APPROVAL_INFO_STORE_CONTEXT}
            columns={approvalsListColumns}
            showColumnGroups={true}
            getQueryFieldForColumn={getQueryFieldForColumn}
          />
        ]}
        rightStack={[
          <LoadingButton
            getFullElementId={(name, type) => `${ID_PREFIX}--exportButton__${name}-${type}`}
            key={`export${ID_PREFIX}`}
            title="CSV"
            successMessage="Done"
            errorMessage="Error"
            messageDurationMs={5000}
            isDisabled={false}
            onClick={handleExport}
            percentageComplete={downloadProgressPercentage}
          />,
          <ShareLink
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'shareLink', name, type)}
            disabled={approvalsListDataLoading}
            urlPath={urlPath}
            urlQuery={''}
          />
        ]}
        tabs={
          <HorizontalTabs
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'listTabs', name, type)}
            tabs={approvalListTabs}
            titleCount={!false ? approvalListData.length : undefined}
          ></HorizontalTabs>
        }
      />

      {snackbar ? (
        <SnackBar id={`${ID_PREFIX}--snackbar`} openSnack={snackbar.openSnack} snackMessage={snackbar.snackMessage} />
      ) : (
        <></>
      )}
      <ListGridContainer>
        <ListGridV2
          listGridData={approvalListData}
          listGridDataTotal={approvalListData.length}
          isHeaderLoading={approvalsListDataLoading}
          isLoading={approvalsListDataLoading}
          columns={currentColumns}
          fullWidthLoadingBars={true}
          handleSortChange={handleSortChange}
          selectedSort={sort}
        />
      </ListGridContainer>
    </PageStyled>
  );
};

export default ApprovalsList;
