/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Service Plan Information Queries
 */
import {useMemo} from 'react';
import {find, isNil} from 'lodash';
import {Query} from './types';
import {DateTime} from 'luxon';
import {IServicePlan} from './lookupTypes';
import {DUAL_BAND_NETWORK, KA_NETWORK, KU_NETWORK, TASK_TYPES} from '../../components/common/constants';

/**
 * Prepares the query params for Service Plan
 * @param newTaskId Task ID for the activity
 * @param isInternal Internal User check
 * @param servicePlanIpScopeInfoFormData Form Data
 * @param servicePlans List of Service Plans
 * @param taskDetails Task Details
 * @param network Aircraft Network optional
 * @param partialData Partial form data optional
 * @returns
 */
export const useServicePlanQueryParams = (
  newTaskId: string,
  isInternal: boolean,
  servicePlanIpScopeInfoFormData: any,
  servicePlans: IServicePlan[],
  taskDetails: any,
  isNewInstall: boolean,
  groupCode: string,
  network?: string,
  partialData?: any
) =>
  useMemo(() => {
    let task_Id = newTaskId;
    let servicePlanData = {};
    if (
      network &&
      (network === KA_NETWORK || network === DUAL_BAND_NETWORK) &&
      (isNil(partialData) || isNil(servicePlanIpScopeInfoFormData))
    )
      return;
    if (network && network === KU_NETWORK && isNil(servicePlanIpScopeInfoFormData)) return;
    if (isNil(network) && (isNil(partialData) || isNil(servicePlanIpScopeInfoFormData))) return;
    let planFilter = {};
    if (!isInternal) {
      planFilter = {
        generalPlanName: partialData ? partialData.servicePlanType : servicePlanIpScopeInfoFormData?.servicePlanType
      };
    } else {
      planFilter = {
        planName: partialData ? partialData.servicePlanType : servicePlanIpScopeInfoFormData?.servicePlanType
      };
    }
    const planDetails = find(servicePlans, {...planFilter});
    if (!planDetails) return;
    servicePlanData = {
      ...taskDetails,
      ...partialData,
      ...servicePlanIpScopeInfoFormData,
      servicePlanType: planDetails?.generalPlanName,
      planStartDate: servicePlanIpScopeInfoFormData?.planStartDate
        ? servicePlanIpScopeInfoFormData?.planStartDate
        : DateTime.utc().toISODate(),
      requestType: isNewInstall ? TASK_TYPES.NEW_SERVICE_PLAN : TASK_TYPES.CHANGE_SERVICE_PLAN
    };
    if (!isNil(partialData) && isNil(partialData.taskId)) {
      servicePlanData = {
        ...servicePlanData,
        region: JSON.stringify(partialData.region),
        planStartDate: partialData?.planStartDate ? partialData?.planStartDate : DateTime.utc().toISODate()
      };
    } else if (!isNil(task_Id)) {
      servicePlanData = {
        ...servicePlanData
      };
    }
    return {
      ...servicePlanData,
      taskId: task_Id,
      groupCode: groupCode
    };
    //eslint-disable-next-line
  }, [servicePlanIpScopeInfoFormData, groupCode]);

export const getServicePlanQuery: Query<any> = {
  route: 'servicePlan/getServicePlan',
  transform: null
};

export const addServicePlanQuery: Query<any> = {
  route: 'servicePlan/addServicePlan',
  transform: null
};

export const updateServicePlanQuery: Query<any> = {
  route: 'servicePlan/updateServicePlan',
  transform: null
};

export const barringServicePlanQuery: Query<any> = {
  route: 'servicePlan/barringServicePlan',
  transform: null
};

export const resumeServicePlanQuery: Query<any> = {
  route: 'servicePlan/resumeServicePlan',
  transform: null
};

export const suspendServicePlanQuery: Query<any> = {
  route: 'servicePlan/suspendServicePlan',
  transform: null
};

export const decommissionServicePlanQuery: Query<any> = {
  route: 'servicePlan/decommissionServicePlan',
  transform: null
};
