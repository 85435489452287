/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Build Id utils
 */

 /**
  * build id with idPrefix, form field type(input or select), dropdown value by removing spaces and special characters
  * @param idPrefix id prefix
  * @param formFieldid form field type
  * @param dropDownValue dropdown value
  * @returns returns validation message if no valid value selected  else true
  */

 export const buildId = (idPrefix: string, formFieldid: string, dropDownValue: string): string => {
    const id=`${idPrefix}--${formFieldid}Option-${dropDownValue
        .split(' ')
        .join('')
        .replace(/[^a-zA-Z0-9 ]/g, '')}`
    return id;
 };
 