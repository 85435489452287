/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Resume Service Plan
 */
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {isNil} from 'lodash';
import InfoIcon from '@mui/icons-material/Info';

import {
  BottomDescription,
  BottomHorizontalRule,
  FormContainerLeftPanel,
  HeaderHorizontalRule,
  HeaderLabel,
  NextButton,
  PageDescription
} from '../CommonStyles';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import {useStore} from '../../store/Store';
import {AppAction} from '../../store/reducers/AppReducer';
import {resumeServicePlanQuery} from '../../store/queries/servicePlanInfoQueries';
import {useCreateApprovalRequestOnServicePlanSubmit} from '../../utils/customHooks';
import useFetchV2 from '../../utils/useFetchV2';

const ID_PREFIX = 'servicePlan-resume';

interface ResumeServicePlanProps {
  title: string;
}

interface IFormInput {
  resumeStartDate: string;
}

const ResumeServicePlan: React.FC<ResumeServicePlanProps> = ({title}) => {
  const LeftChild = () => {
    const navigate = useNavigate();
    const {store, dispatch} = useStore();
    const taskId = store.servicePlan?.resumeServicePlan?.taskId;
    const {serialNumber, tailId, make, model, network} = store.aircraftInfo;
    const {breadcrumb} = store.app;
    const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';
    const location = useLocation();
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: 224,
          width: 250,
          color: '#465967'
        }
      }
    };
    let initialValues: IFormInput = {
      resumeStartDate: ''
    };
    const [planResumeDate, setPlanResumeDate] = useState(null);
    const [resumeServiceFormData, setResumeServiceFormData] = useState(null);

    const planResumeDateFormatted =
      planResumeDate && planResumeDate.c
        ? planResumeDate.c.month + '/' + planResumeDate.c.day + '/' + planResumeDate.c.year
        : initialValues.resumeStartDate;
    const {handleSubmit, formState, watch, control} = useForm();
    const queryParams = useMemo(() => {
      if (isNil(tailId) || isNil(serialNumber) || isNil(resumeServiceFormData)) return;
      return {
        groupCode,
        requestType: 'Resume Service',
        taskId,
        serialNumber,
        tailId,
        make,
        model,
        network,
        resumeDate: resumeServiceFormData?.resumeDate
      };
      //eslint-disable-next-line
    }, [resumeServiceFormData, groupCode]);

    const {isLoading: inProgress, data: servicePlanInfo} = useFetchV2(
      {route: resumeServicePlanQuery.route, params: queryParams},
      resumeServicePlanQuery.transform
    );

    const {isSubmitRequestLoading, submitResponse} = useCreateApprovalRequestOnServicePlanSubmit(
      servicePlanInfo?.taskId,
      false
    );

    useEffect(() => {
      if (inProgress || isNil(servicePlanInfo) || isSubmitRequestLoading) return;
      const operation = null;
      const message = 'We have received your request.';
      const details = 'You will receive an email upon Viasat review.';
      dispatch({
        type: AppAction.SET_BREADCRUMB,
        payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
      });
      dispatch({
        type: AppAction.SET_ALERT_INFO,
        payload: {
          showAlert: true,
          operation: operation,
          message: message,
          details: details
        }
      });
      navigate('/main-menu');
      //eslint-disable-next-line
    }, [dispatch, inProgress, servicePlanInfo, isSubmitRequestLoading, submitResponse]);

    const resumeServicePlanInfoFormFields: FormField[] = [
      {
        id: 'resumeDate',
        label: 'Start Date',
        type: FormFieldType.DATE,
        value: planResumeDateFormatted,
        placeholder: '__/__/____',
        setDate: setPlanResumeDate,
        disabled: false,
        menuProps: MenuProps,
        description: null,
        rules: {
          required: true,
          pattern: null
        }
      }
    ];

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Resume Service</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--pageDescription`}>
          To resume service enter the desired start date. Please allow 72 hours to process your request.
        </PageDescription>
        <BottomHorizontalRule />
        <FormContainerLeftPanel>
          <FormComponent
            idPrefix={ID_PREFIX}
            id="resume-service-form"
            formFields={resumeServicePlanInfoFormFields}
            setFormData={setResumeServiceFormData}
            formControlMethods={{handleSubmit, formState, watch, control}}
          ></FormComponent>
        </FormContainerLeftPanel>
        <BottomDescription>
          <InfoIcon className="info-icon" />
          Resuming service may take up to 3 working days.
        </BottomDescription>
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton form="resume-service-form" id={`${ID_PREFIX}--nextButton`} disabled={false}>
        <span className="next-button-text">Submit</span>
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<LeftChild />} actionButtons={<ActionButtons />} />;
};

export default ResumeServicePlan;
