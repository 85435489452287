/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Replace ACU Information
 */
import React, {useMemo, useEffect} from 'react';
import {useWatch, useForm} from 'react-hook-form';
import {useStore} from '../../store/Store';
import {isNil} from 'lodash';

import {EquipmentInfoAction, IAcuInformation} from '../../store/reducers/EquipmentInfoReducer';
import ReplaceEquipmentBase from './ReplaceEquipmentBase';
import {FormField, FormFieldType} from '../common/elements/Form';
import {FormConfig, FormTypes} from './EquipmentUpdateForm';

import {updateAcuInformation} from '../../store/queries/newInstall/equipmentInfoQuery';
import {EquipmentType, SELECT_AN_OPTION, serialNumberValidation} from '../common/constants';
import {usePartNumbersFetch} from '../../utils/customHooks';
import {getPartNumbersQuery} from '../../store/queries/lookupQueries';
import {validateConfigItemsExists, validateDropdownField} from '../../utils/validationUtils';
import {getExistingEquipmentInfoQuery} from '../../store/queries/getExistingEquipmentInfoQuery';
import useFetchV2 from '../../utils/useFetchV2';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
      color: '#465967'
    }
  }
};

interface IFormInput {
  partNumber: string;
  serialNumber: string;
}

interface AcuReplaceProps {
  title: string;
}

const AcuReplace: React.FC<AcuReplaceProps> = ({title}) => {
  const {handleSubmit, formState, control, watch, reset, setValue} = useForm();
  const {store, dispatch} = useStore();
  const {aircraftNetwork, siteId} = store.aircraftInfo;
  const acuConfigItems = store.app.configItems?.['ACU'] ? store.app.configItems['ACU'] : [];

  const queryParams = useMemo(() => {
    if (isNil(siteId) || isNil(aircraftNetwork)) return;
    return {
      siteId: siteId,
      configType: 'ACU',
      configSubType: aircraftNetwork
    };
  }, [siteId, aircraftNetwork]);

  const {isLoading: existingEquipmentInfoLoading, data: existingEquipmentData} = useFetchV2(
    {route: getExistingEquipmentInfoQuery.route, params: queryParams},
    getExistingEquipmentInfoQuery.transform
  );
  useEffect(() => {
    if (existingEquipmentInfoLoading && !existingEquipmentData) return;
    if (existingEquipmentData && existingEquipmentData.length) {
      dispatch({
        type: EquipmentInfoAction.SET_EXISTING_ACU_INFO,
        payload: existingEquipmentData[0]
      });
      setValue('partNumber', existingEquipmentData[0]?.partNumber);
      setValue('serialNumber', existingEquipmentData[0]?.serialNumber);
    }
    // eslint-disable-next-line
  }, [existingEquipmentInfoLoading]);

  const formConfigList: FormConfig[] = [
    {
      id: FormTypes.EXISTING,
      title: 'Verify Existing ACU Information',
      description:
        'To start please verify the information for the faulty Antenna Control Unit (ACU) that is being removed from the terminal. If this information does not how below, please Add it manually.',
      footerText: '',
      footerHyperLinkText: '',
      footerHyperLinkUrl: '',
      actionType: EquipmentInfoAction.SET_EXISTING_ACU_INFO
    },
    {
      id: FormTypes.NEW,
      title: 'Add Replacement ACU Information',
      description: 'Add replacement Antenna Control Unit (ACU) information.',
      footerText: 'View latest revision of the Viasat ACU Operations and Test Manual here:',
      footerHyperLinkText: 'ACU Installation Procedures',
      footerHyperLinkUrl: '#',
      actionType: EquipmentInfoAction.SET_ACU_INFO
    }
  ];

  const partNumbersList = usePartNumbersFetch(EquipmentType.ACU, aircraftNetwork, getPartNumbersQuery);

  const selectedPartNumber: string = useWatch({
    control,
    name: 'partNumber'
  });
  const serialNumberInput = watch('serialNumber');
  const partNumberParams = partNumbersList?.filter(partNumber => partNumber.viasatPartNumber === selectedPartNumber)[0];

  const productDescription = partNumberParams?.viasatPartNumberDescription;
  const serialNumberFormat = partNumberParams?.serialNumberFormat;
  const serialNumberFormatDescription = partNumberParams?.serialNumberFormatDescription;

  const serialNumberRegex = serialNumberValidation[selectedPartNumber];

  const getFormFields = (initialValues: IFormInput, isExistingForm: boolean): FormField[] => {
    const existingAcu: IAcuInformation = store.equipmentInfo.existingAcu;
    const equipmentFormFields: FormField[] = [
      {
        id: 'partNumber',
        label: 'Part Number',
        type: FormFieldType.SELECT,
        value: initialValues.partNumber,
        disabled: false,
        menuProps: MenuProps,
        dropDownValues: partNumbersList?.map(partNumber => partNumber.viasatPartNumber),
        description: {
          title: 'Product Description',
          value: productDescription ? productDescription : '--'
        },
        rules: {
          required: true,
          pattern: null,
          validate: (value, formValues) => validateDropdownField(value, 'Part Number')
        }
      },
      {
        id: 'serialNumber',
        label: 'Serial Number',
        type: FormFieldType.INPUT,
        value: initialValues.serialNumber,
        disabled: false,
        placeholder: serialNumberFormat ? `  eg. ${serialNumberFormat}` : '',
        description: serialNumberFormat ? {title: 'Serial Number Format', value: serialNumberFormat} : null,
        descriptionHelper: serialNumberFormatDescription ? serialNumberFormatDescription : '',
        rules: {
          required: true,
          pattern: {value: new RegExp(serialNumberRegex), message: 'Invalid Serial Number.'},
          warning: validateConfigItemsExists(
            acuConfigItems,
            'serialNumber',
            serialNumberInput,
            aircraftNetwork,
            siteId
          ),
          validate: (value, formValues) => {
            if (!isExistingForm)
              return (
                value !== existingAcu?.serialNumber ||
                'Invalid Serial Number. Serial Number cannot be same as installed hardware.'
              );
            else return true;
          }
        }
      }
    ];
    return equipmentFormFields;
  };

  return (
    <ReplaceEquipmentBase<IFormInput>
      title={title}
      aircraftSerialNumber={''}
      formIdPrefix={'replaceAcuForm'}
      equipmentName="acu"
      existingEquipmentStoreKey="existingAcu"
      newEquipmentStoreKey="acu"
      formConfigList={formConfigList}
      defaultFormValues={{
        partNumber: existingEquipmentData?.length ? existingEquipmentData[0]?.partNumber : SELECT_AN_OPTION,
        serialNumber: ''
      }}
      getFormFields={(initialValues, isExistingForm) => getFormFields(initialValues, isExistingForm)}
      formControlMethods={{handleSubmit, formState, control, watch, reset}}
      fetchQuery={updateAcuInformation}
      isLoading={siteId ? existingEquipmentInfoLoading : false}
    />
  );
};

export default AcuReplace;
