/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Ka Band Software Versions List View
 */
import React, {useMemo, useEffect, useState} from 'react';
import {HorizontalTabs, MenuBar, ShareLink, ListGridV2, ListGridColumn} from '@viasat/insights-components';
import {lookupTableTabDefinitions} from '../tabDefinitions';
import PageStyled from '../../../theme/PageStyled';
import {PageProps} from '../../common/layout/PageProps';
import ListGridContainer from '../../common/ListGridContainer';
import {getElementId} from '../../../utils/elementIdUtils';
import {getKuBandMapBundleFilesListQuery} from '../../../store/queries/lookupQueries';
import SnackBar from '../../common/SnackBar';
import {useStore} from '../../../store/Store';
import {getCurrentUrlPath} from '../../../utils/linkUtils';
import RetireLookupRecord, {RetireLookupType} from './forms/RetireLookupRecord';
import {LookupsListAction} from '../../../store/reducers/LookupsReducer';
import {
  LookupListColumnId,
  buildMapBundleVersionsListColumns,
  dynamicSort,
  getQueryFieldMapping
} from './lookupTableUtils';
import AddMapBundleVersion from './forms/AddMapBundleVersion';
import {useRefreshLookupList, useResetSelectedLookup} from '../../../utils/customHooks';
import {IKuMapBundleFilesList} from '../../../store/queries/lookupTypes';
import AccessControl from '../../common/accessControl/AccessControl';
import LookupHeaderAction from './LookupListHeaderActions';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import {MAP_BUNDLE_VERSIONS_LIST_STORE_CONTEXT} from '../../../store/reducers/MapBundleVersionsReducer';
import ListViewSortContainer from '../ListViewSortContainer';
import useFetchV2 from '../../../utils/useFetchV2';

const ID_PREFIX = 'kuBandMapFilesList';

const KuBandMapFilesList: React.FC<PageProps> = () => {
  const {store, dispatch} = useStore();
  const {isAdmin} = store.init;
  const {newRow, deactivatedRow, refreshList, selectedLookups} = store.lookups;
  const [openAddKuMapBundleVersion, setOpenAddKuMapBundleVersion] = useState<boolean>(false);
  const [kuMapBundleListData, setKuMapBundleListData] = useState<IKuMapBundleFilesList[]>(null);
  const [retireMapBundle, setRetireMapBundle] = useState<boolean>(false);

  const {snackbar} = store.app;
  const {sort} = store.mapBundleVersionsList;
  const urlPath = getCurrentUrlPath();

  useResetSelectedLookup();
  useRefreshLookupList(refreshList);

  const handleRowSelection = (selectedLookup: any) => {
    dispatch({
      type: LookupsListAction.SELECTED_LOOKUP_ROWS,
      payload: selectedLookup
    });
  };

  const handleRowDeselection = (removedLookup: any) => {
    dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: removedLookup});
  };

  const kuBandMapFilesColumns = buildMapBundleVersionsListColumns(isAdmin).map(col => {
    // Add row selection/deselection handlers for checkbox
    if (col.key === 'id') {
      col.handleRowSelection = handleRowSelection;
      col.handleRowDeselection = handleRowDeselection;
    }

    return col;
  });

  const queryParams = useMemo(
    () => ({newRow, deactivatedRow, refreshList, doCache: false}),
    [newRow, deactivatedRow, refreshList]
  );

  const {isLoading: isKuBandMapFilesLoading, data: kuBandMapFilesListInfo} = useFetchV2(
    {route: getKuBandMapBundleFilesListQuery.route, params: queryParams},
    getKuBandMapBundleFilesListQuery.transform
  );

  useEffect(() => {
    if (refreshList) {
      setTimeout(() => {
        dispatch({
          type: LookupsListAction.REFRESH_LOOKUP_LIST,
          payload: false
        });
        dispatch({
          type: LookupsListAction.RESET_NEW_ROW_COUNT,
          payload: 0
        });
      }, 3000); // The API call takes 1s or 1.5s or 2s sometimes and inconsistent with 500ms.
    }
  }, [refreshList, dispatch]);

  // Determine what columns to show and hide for the list grid
  const currentColumns = kuBandMapFilesColumns;

  useEffect(() => {
    if (isKuBandMapFilesLoading) return;
    const kuMapBundleVersionsDataWithSelections: IKuMapBundleFilesList[] = kuBandMapFilesListInfo
      ? [...kuBandMapFilesListInfo].map(row => {
          return {
            ...row,
            isSelected: selectedLookups.some((selectedRow: IKuMapBundleFilesList) => selectedRow.id === row.id),
            isRowHighlighted: row.status === 'Retired'
          };
        })
      : [];
    setKuMapBundleListData(kuMapBundleVersionsDataWithSelections.sort(dynamicSort(sort.column, sort.order)));
  }, [kuBandMapFilesListInfo, isKuBandMapFilesLoading, selectedLookups, sort.column, sort.order]);

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(LookupListColumnId[columnId]);

  const handleSortChange = ({column, order}: {column: string; order: string}) => {
    setKuMapBundleListData(kuMapBundleListData.sort(dynamicSort(column, order)));
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, MAP_BUNDLE_VERSIONS_LIST_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(kuBandMapFilesColumns[column]),
        validColumns: kuBandMapFilesColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: kuBandMapFilesColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(kuBandMapFilesColumns[col.key])
        )
      }
    });
  };
  return (
    <PageStyled>
      <MenuBar
        getFullElementId={(name: string, type: string) => getElementId(ID_PREFIX, 'menuBar', name, type)}
        title={''}
        subtitle={''}
        isSubtitleLoading={false}
        leftStack={[
          <AccessControl permitted={isAdmin}>
            <LookupHeaderAction
              isLoading={isKuBandMapFilesLoading}
              selectedLookups={selectedLookups}
              setOpenAddAircraftModel={setOpenAddKuMapBundleVersion}
              setRetireAircraftModel={setRetireMapBundle}
              idPrefix={ID_PREFIX}
              isRetire={true}
              allowRetireDeactive={true}
            />
          </AccessControl>,
          <ListViewSortContainer
            key="sorts"
            idPrefix={ID_PREFIX}
            disabled={isKuBandMapFilesLoading}
            storeContext={MAP_BUNDLE_VERSIONS_LIST_STORE_CONTEXT}
            columns={kuBandMapFilesColumns}
            showColumnGroups={true}
            getQueryFieldForColumn={getQueryFieldForColumn}
          />
        ]}
        rightStack={[
          <ShareLink
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'shareLink', name, type)}
            disabled={isKuBandMapFilesLoading}
            urlPath={urlPath}
            urlQuery={''}
          />
        ]}
        tabs={
          <HorizontalTabs
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'listTabs', name, type)}
            tabs={lookupTableTabDefinitions}
            titleCount={kuBandMapFilesListInfo ? kuBandMapFilesListInfo.length : undefined}
          ></HorizontalTabs>
        }
      />
      {openAddKuMapBundleVersion ? (
        <AddMapBundleVersion
          openPanel={openAddKuMapBundleVersion}
          setOpenPanel={setOpenAddKuMapBundleVersion}
          panelData={selectedLookups[0]}
          isUpdate={selectedLookups.length === 1}
        ></AddMapBundleVersion>
      ) : (
        <></>
      )}
      {retireMapBundle && (
        <RetireLookupRecord
          retireLookupType={RetireLookupType.RETIRE}
          openRetireLookupModal={retireMapBundle}
          retireLookupQueryRoute={'lookups/deactivate/mapBundleVersion/' + selectedLookups[0]?.id}
          recordDisplayName={'Ku Map Bundle'}
          setOpenRetireLookupModal={setRetireMapBundle}
        />
      )}
      {snackbar ? (
        <SnackBar id={`${ID_PREFIX}--snackbar`} openSnack={snackbar.openSnack} snackMessage={snackbar.snackMessage} />
      ) : (
        <></>
      )}
      <ListGridContainer>
        <ListGridV2
          listGridData={!isKuBandMapFilesLoading && kuMapBundleListData ? kuMapBundleListData : []}
          listGridDataTotal={!isKuBandMapFilesLoading && kuMapBundleListData ? kuMapBundleListData.length : 0}
          isHeaderLoading={isKuBandMapFilesLoading}
          isLoading={isKuBandMapFilesLoading}
          columns={currentColumns}
          showGroupHeader={true}
          fullWidthLoadingBars={true}
          handleSortChange={handleSortChange}
          selectedSort={sort}
        />
      </ListGridContainer>
    </PageStyled>
  );
};

export default KuBandMapFilesList;
