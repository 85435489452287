/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft, Equipment, Customer and Service Plan Information Right Panel with accordion containers
 */
import React from 'react';
import styled from '@emotion/styled';
import {isNil, omit} from 'lodash';
import {PictureAsPdf} from '@mui/icons-material';
import {LABEL_COLOR, DISABLED_SAVE_PDF_BTN_COLOR} from '../../theme/Colors';
import {useStore} from '../../store/Store';

import AccordionContainer, {IAccordionItem, IAccordionItems} from '../common/elements/accordion/Accordion';
import {
  DEFAULT_GROUP_CODE,
  DUAL_BAND_NETWORK,
  KA_NETWORK,
  PROPERTIES_TO_BE_EXCLUDED_ON_RIGHT_PANEL
} from '../common/constants';
import {AppAction} from '../../store/reducers/AppReducer';

const AircraftDetailsLabel = styled.div`
  height: 32px;
  margin-bottom: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: ${LABEL_COLOR};
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
`;

const TitleCard = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SaveAsPdfButton = styled.div<{disabled: boolean}>`
  display: flex;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props => (props.disabled ? `${DISABLED_SAVE_PDF_BTN_COLOR}` : ``)};
  pointer-events: ${props => (props.disabled ? `none` : ``)};
  @media print {
    display: none;
  }
`;

interface RightPanelInformationContainerProps {
  title: string;
  showExistingInfo?: boolean;
  showDifference?: boolean;
  ignoreFieldsIfEmpty?: boolean;
  apsuReplaceData?: any;
  fanTrayReplaceData?: any;
  routerReplaceData?: any;
  kaAntennaReplaceData?: any;
  kuAntennaReplaceData?: any;
  kuModemReplaceData?: any;
  kaModemReplaceData?: any;
  acuReplaceData?: any;
}
const convertCamelCaseToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
const prepareAccordionData = (accordionData: any): IAccordionItem[] => {
  const accordionItems: IAccordionItem[] = [];
  // Form the Aircraft Data
  for (const data in accordionData) {
    // this can be refactored once we implement on key press right panel updates
    if (!PROPERTIES_TO_BE_EXCLUDED_ON_RIGHT_PANEL.includes(data)) {
      accordionItems.push({label: convertCamelCaseToTitleCase(data), value: accordionData[data]});
    }
  }
  return accordionItems;
};
const RightPanelInformationContainer: React.FC<RightPanelInformationContainerProps> = ({
  title,
  showExistingInfo,
  showDifference,
  ignoreFieldsIfEmpty,
  apsuReplaceData,
  fanTrayReplaceData,
  routerReplaceData,
  kuAntennaReplaceData,
  kaAntennaReplaceData,
  kuModemReplaceData,
  kaModemReplaceData,
  acuReplaceData
}) => {
  const {store, dispatch} = useStore();
  const {isInternal} = store.init;
  const groupCode = store.customer.current.code;
  const {aircraftInfo, equipmentInfo, customerInfo, servicePlan} = store;
  const {rightPanelAccordionState} = store.app;
  const aircraftAccordionData = prepareAccordionData(aircraftInfo);
  aircraftAccordionData.splice(
    aircraftAccordionData.findIndex(item => item.label === 'Aircraft Network'),
    1
  );
  aircraftAccordionData.splice(
    aircraftAccordionData.findIndex(item => item.label === 'Task Id'),
    1
  );
  aircraftAccordionData.splice(
    aircraftAccordionData.findIndex(item => item.label === 'Site Id'),
    1
  );
  const EQUIPMENT_DATA_TO_OMIT = [
    'warrantyStartDate',
    'warrantyEndDate',
    'installDate',
    'status',
    'configType',
    'configSubType',
    'configItemId',
    'newWarrantyEndDate'
  ];
  const kuModemAccordionData = prepareAccordionData(omit(equipmentInfo.kuModem, EQUIPMENT_DATA_TO_OMIT));
  const kuModemExistingAccordionData = prepareAccordionData(equipmentInfo.existingKuModem);
  const kuModemReplaceAccordionData = prepareAccordionData(kuModemReplaceData);
  const kaModemAccordionData = prepareAccordionData(omit(equipmentInfo.kaModem, EQUIPMENT_DATA_TO_OMIT));
  const kaModemExistingAccordionData = prepareAccordionData(equipmentInfo.existingKaModem);
  const kaModemReplaceAccordionData = prepareAccordionData(kaModemReplaceData);
  const kuAntennaAccordionData = prepareAccordionData(omit(equipmentInfo.kuAntenna, EQUIPMENT_DATA_TO_OMIT));
  const kuAntennaExistingAccordionData = prepareAccordionData(equipmentInfo.existingKuAntenna);
  const kuAntennaReplaceAccordionData = prepareAccordionData(kuAntennaReplaceData);
  const kaAntennaAccordionData = prepareAccordionData(omit(equipmentInfo.kaAntenna, EQUIPMENT_DATA_TO_OMIT));
  const kaAntennaExistingAccordionData = prepareAccordionData(equipmentInfo.existingKaAntenna);
  const kaAntennaReplaceAccordionData = prepareAccordionData(kaAntennaReplaceData);
  const fanTrayAccordionData = prepareAccordionData(omit(equipmentInfo.fanTray, EQUIPMENT_DATA_TO_OMIT));
  const fanTrayReplaceAccordionData = prepareAccordionData(fanTrayReplaceData);
  const fanTrayExistingAccordionData = prepareAccordionData(equipmentInfo.existingFanTray);
  const routerAccordionData = prepareAccordionData(omit(equipmentInfo.router, EQUIPMENT_DATA_TO_OMIT));
  const routerReplaceAccordionData = prepareAccordionData(routerReplaceData);
  const routerExistingAccordionData = prepareAccordionData(equipmentInfo.existingRouter);
  const apsuAccordionData = prepareAccordionData(omit(equipmentInfo.apsu, EQUIPMENT_DATA_TO_OMIT));
  const apsuReplaceAccordionData = prepareAccordionData(apsuReplaceData);
  const apsuExistingAccordionData = prepareAccordionData(equipmentInfo.existingApsu);
  const acuAccordionData = prepareAccordionData(omit(equipmentInfo.acu, EQUIPMENT_DATA_TO_OMIT));
  const acuExistingAccordionData = prepareAccordionData(equipmentInfo.existingAcu);
  const acuReplaceAccordionData = prepareAccordionData(acuReplaceData);
  const showKuEquipmentInfoSectionTitle =
    aircraftInfo.network === DUAL_BAND_NETWORK &&
    (!isNil(equipmentInfo.kuModem) ||
      !isNil(equipmentInfo.kuAntenna) ||
      !isNil(equipmentInfo.acu) ||
      !isNil(equipmentInfo.router));
  const showKaEquipmentInfoSectionTitle =
    aircraftInfo.network === DUAL_BAND_NETWORK &&
    (!isNil(equipmentInfo.kaModem) ||
      !isNil(equipmentInfo.kaAntenna) ||
      !isNil(equipmentInfo.apsu) ||
      !isNil(equipmentInfo.fanTray));

  // customer information
  const organizationInfo = prepareAccordionData(customerInfo?.organizationInfo);
  const contactInfo = prepareAccordionData(customerInfo?.contactInfo);
  const businessAddress = prepareAccordionData(customerInfo?.businessAddress);
  const aircraftHomeBase = prepareAccordionData({
    aircraftHomeBaseAirport: customerInfo?.aircraftHomeBaseAirport,
    visibleToManufacturer: customerInfo?.visibleToManufacturer
  });
  const billingContactInfo = prepareAccordionData(customerInfo?.billingContactInfo);
  const billingAddressInfo = prepareAccordionData(customerInfo?.billingAddressInfo);

  // service plan
  const servicePlanOptionInfo = prepareAccordionData(
    omit(servicePlan, [
      'ipScope',
      'taskId',
      'tailId',
      'serialNumber',
      'suspendServicePlan',
      'barringServicePlan',
      'decommissionServicePlan',
      'resumeServicePlan'
    ])
  );
  const servicePlanIpScopeInfo = prepareAccordionData({
    ipScope: servicePlan?.ipScope
  });

  /**
   * Function to select according data from different data source
   * based on component props and data availability in equipment replace data
   *
   * @param {IAccordionItem[]} items - preprocessed equipment accordion data
   * @param {IAccordionItem[]} existingItems - preprocessed existing equipment accordion data
   * @param {IAccordionItem[]} replaceItems - preprocessed equipment form accordion data
   * @return {IAccordionItem[]} List of IAccordionItem.
   */
  const selectAccordionData = (
    accordionData: IAccordionItem[],
    existingAccordionData: IAccordionItem[],
    replaceAccordionData: IAccordionItem[]
  ): IAccordionItem[] => {
    let results: IAccordionItem[] = [];
    const haveReplaceAccordionData: boolean = replaceAccordionData?.length > 0;
    // choose replaceAccordionData or accordionData as accordionData based on data availability on replaceAccordionData
    const accordionDataItems = haveReplaceAccordionData ? replaceAccordionData : accordionData;
    if (showDifference && haveReplaceAccordionData) {
      const labelReg = {};
      accordionDataItems.forEach(x => {
        labelReg[x.label] = x.value;
      });
      let itemDifferenceData: IAccordionItem[] = [];
      existingAccordionData.forEach(x => {
        // show existing accordion data with label if it have value
        if (x.value) itemDifferenceData.push({label: x.label, value: `${x.value} (Existing)`, highlight: true});
        // if existing accordion data don't have any value add label to replace accordion data
        const replaceLabel = !x.value ? x.label : '';
        if (labelReg[x.label])
          itemDifferenceData.push({
            label: replaceLabel,
            value: labelReg[x.label] ? `${labelReg[x.label]} (Replacement)` : null
          });
      });
      results = itemDifferenceData;
    } else if (showExistingInfo) {
      // returns replaceAccordionData or existingAccordionData based on data availability on replaceAccordionData
      results = haveReplaceAccordionData ? replaceAccordionData : existingAccordionData;
    } else {
      results = accordionDataItems;
    }
    if (ignoreFieldsIfEmpty) results = results.filter(x => !!x.value);
    return results;
  };
  // this needs refactoring , once product description is implemented for replace hardware
  const aircraftInformation: IAccordionItems[] = [
    {title: 'Aircraft', sectionCategory: 'aircraft', items: aircraftAccordionData}
  ];

  const equipmentInformation: IAccordionItems[] = [
    showKuEquipmentInfoSectionTitle && {
      sectionTitle: 'Ku Equipment Information',
      sectionCategory: 'kuEquipmentInfo',
      title: '',
      items: []
    },
    {
      title: equipmentInfo.kuModem?.productDescription ? equipmentInfo.kuModem?.productDescription : 'Modem',
      sectionCategory: 'modem',
      items: selectAccordionData(kuModemAccordionData, kuModemExistingAccordionData, kuModemReplaceAccordionData)
    },
    {
      title: equipmentInfo.kuAntenna?.productDescription ? equipmentInfo.kuAntenna?.productDescription : 'Antenna',
      sectionCategory: 'antenna',
      items: selectAccordionData(kuAntennaAccordionData, kuAntennaExistingAccordionData, kuAntennaReplaceAccordionData)
    },
    {
      title: equipmentInfo.acu?.productDescription ? equipmentInfo.acu?.productDescription : 'ACU',
      sectionCategory: 'acu',
      items: selectAccordionData(acuAccordionData, acuExistingAccordionData, acuReplaceAccordionData)
    },
    {
      title: 'Router',
      sectionCategory: 'router',
      items: selectAccordionData(routerAccordionData, routerExistingAccordionData, routerReplaceAccordionData)
    },
    showKaEquipmentInfoSectionTitle && {
      sectionTitle: 'Ka Equipment Information',
      sectionCategory: 'kaEquipmentInfo',
      title: '',
      items: []
    },
    {
      title: equipmentInfo.kaModem?.productDescription ? equipmentInfo.kaModem?.productDescription : 'Modem',
      sectionCategory: 'modem',
      items: selectAccordionData(kaModemAccordionData, kaModemExistingAccordionData, kaModemReplaceAccordionData)
    },
    {
      title: equipmentInfo.kaAntenna?.productDescription ? equipmentInfo.kaAntenna?.productDescription : 'Antenna',
      sectionCategory: 'antenna',
      items: selectAccordionData(kaAntennaAccordionData, kaAntennaExistingAccordionData, kaAntennaReplaceAccordionData)
    },
    {
      title: equipmentInfo.apsu?.productDescription ? equipmentInfo.apsu?.productDescription : 'APSU',
      sectionCategory: 'apsu',
      items: selectAccordionData(apsuAccordionData, apsuExistingAccordionData, apsuReplaceAccordionData)
    },
    {
      title: equipmentInfo.fanTray?.productDescription ? equipmentInfo.fanTray?.productDescription : 'Fan Tray',
      sectionCategory: 'fanTray',
      items: selectAccordionData(fanTrayAccordionData, fanTrayExistingAccordionData, fanTrayReplaceAccordionData)
    }
  ];

  const customerInfoAccordionData: IAccordionItems[] = [
    {title: 'Organization Name', sectionCategory: 'organizationName', items: organizationInfo},

    {
      title: 'Aircraft Tail ID and Home base',
      sectionCategory: 'aircraftTailHomeBase',
      items: aircraftHomeBase
    }
  ];

  if (isInternal && groupCode === DEFAULT_GROUP_CODE) {
    customerInfoAccordionData.splice(1, 0, {
      title: 'Contact Information',
      sectionCategory: 'contactInformation',
      items: contactInfo
    });
    customerInfoAccordionData.splice(2, 0, {
      title: 'Business Address',
      sectionCategory: 'businessAddress',
      items: businessAddress
    });
    customerInfoAccordionData.push({
      title: 'Billing Contact Information',
      sectionCategory: 'billingContactInfo',
      items: billingContactInfo
    });
    customerInfoAccordionData.push({
      title: 'Billing Address',
      sectionCategory: 'billingAddress',
      items: billingAddressInfo
    });
  }

  const servicePlanInfoAccordionData: IAccordionItems[] = [
    {title: 'Service Option', sectionCategory: 'servicePlan', items: servicePlanOptionInfo}
  ];
  // Add Ka IP Address Information only for KA_NETWORK
  if (aircraftInfo.network === KA_NETWORK) {
    servicePlanInfoAccordionData.push({
      title: 'Ka IP Address',
      sectionCategory: 'servicePlanIPScope',
      items: servicePlanIpScopeInfo
    });
  }

  const EQUIPMENT_DATA_TO_OMIT_FOR_WARRANTY = [
    'partNumber',
    'serialNumber',
    'status',
    'configType',
    'configSubType',
    'configItemId'
  ];
  const kaModemWarrantyInfo = prepareAccordionData(omit(equipmentInfo.kaModem, EQUIPMENT_DATA_TO_OMIT_FOR_WARRANTY));
  const kaAntennaWarrantyInfo = prepareAccordionData(
    omit(equipmentInfo.kaAntenna, EQUIPMENT_DATA_TO_OMIT_FOR_WARRANTY)
  );
  const kaPowerSupplyWarrantyInfo = prepareAccordionData(omit(equipmentInfo.apsu, EQUIPMENT_DATA_TO_OMIT_FOR_WARRANTY));

  const kuModemWarrantyInfo = prepareAccordionData(omit(equipmentInfo.kuModem, EQUIPMENT_DATA_TO_OMIT_FOR_WARRANTY));
  const kuAntennaWarrantyInfo = prepareAccordionData(
    omit(equipmentInfo.kuAntenna, EQUIPMENT_DATA_TO_OMIT_FOR_WARRANTY)
  );
  const kuPowerSupplyWarrantyInfo = prepareAccordionData(omit(equipmentInfo.acu, EQUIPMENT_DATA_TO_OMIT_FOR_WARRANTY));

  const warrantyInformationAccordionData: IAccordionItems[] = [
    {
      title: 'Modem Warranty',
      sectionCategory: 'ka-modem-warrantyInfo',
      items: kaModemWarrantyInfo,
      subTitle: ''
    },
    {
      title: 'Antenna Warranty',
      sectionCategory: 'ka-antenna-warrantyInfo',
      items: kaAntennaWarrantyInfo,
      subTitle: ''
    },
    {
      title: 'APSU Warranty',
      sectionCategory: 'ka-apsu-warrantyInfo',
      items: kaPowerSupplyWarrantyInfo,
      subTitle: ''
    },
    {
      title: 'Modem Warranty',
      sectionCategory: 'ku-modem-warrantyInfo',
      items: kuModemWarrantyInfo,
      subTitle: ''
    },
    {
      title: 'Antenna Warranty',
      sectionCategory: 'ku-antenna-warrantyInfo',
      items: kuAntennaWarrantyInfo,
      subTitle: ''
    },
    {
      title: 'ACU Warranty',
      sectionCategory: 'ku-acu-warrantyInfo',
      items: kuPowerSupplyWarrantyInfo,
      subTitle: ''
    }
  ];

  /**
   * Changes the accordion container state expanded/collapsed
   * @param e The event source of the callback. **Warning**: This is a generic event not a change event.
   * @param expanded Accordion container state
   * @returns Updates the store with expanded true or false
   */
  const changeAccordionState = (e: React.SyntheticEvent, expanded: boolean, section: string) => {
    dispatch({
      type: AppAction.SET_RIGHT_PANEL_ACCORDION_STATE,
      payload: {[section]: expanded}
    });
    return;
  };

  return (
    <>
      <TitleCard>
        <AircraftDetailsLabel>{title}</AircraftDetailsLabel>
        <SaveAsPdfButton
          onClick={() => {
            dispatch({
              type: AppAction.SET_RIGHT_PANEL_ACCORDIONS_EXPANDED,
              payload: true
            });
            setTimeout(() => {
              window.print();
            }, 1000);
          }}
          disabled={aircraftInfo['serialNumber'] === null ? true : false}
        >
          <PictureAsPdf style={{marginRight: '5px'}} />
          Save as PDF
        </SaveAsPdfButton>
      </TitleCard>
      <AccordionContainer
        id="aircraftInfo"
        title={'Aircraft Information'}
        subTitle={'Aircraft'}
        items={aircraftInformation}
        expanded={true || rightPanelAccordionState.aircraftInfo}
        accordionChangeEvent={(e, rightPanelAccordionsExpanded) =>
          changeAccordionState(e, rightPanelAccordionsExpanded, 'aircraftInfo')
        }
      />
      <AccordionContainer
        id="equipmentInfo"
        title={'Equipment Information'}
        subTitle={''}
        items={equipmentInformation}
        expanded={false || rightPanelAccordionState.equipmentInfo}
        accordionChangeEvent={(e, rightPanelAccordionsExpanded) =>
          changeAccordionState(e, rightPanelAccordionsExpanded, 'equipmentInfo')
        }
      />
      <AccordionContainer
        id="aircraftCustomerInfo"
        title={'Customer Information'}
        subTitle={null}
        items={customerInfoAccordionData}
        expanded={false || rightPanelAccordionState.customerInfo}
        accordionChangeEvent={(e, rightPanelAccordionsExpanded) =>
          changeAccordionState(e, rightPanelAccordionsExpanded, 'customerInfo')
        }
      />
      <AccordionContainer
        id="aircraftServicePlanInfo"
        title={'Service Plan Information'}
        subTitle={null}
        items={servicePlanInfoAccordionData}
        expanded={false || rightPanelAccordionState.servicePlanInfo}
        accordionChangeEvent={(e, rightPanelAccordionsExpanded) =>
          changeAccordionState(e, rightPanelAccordionsExpanded, 'servicePlanInfo')
        }
      />
      {isInternal ? (
        <AccordionContainer
          id="aircraftWarrantyInfo"
          title={'Warranty Information'}
          subTitle={null}
          internalTitle={{title: 'Equipment Warranty Information', subTitle: '(Proprietary)'}}
          isInternal={isInternal}
          items={warrantyInformationAccordionData}
          expanded={false || rightPanelAccordionState.warrantyInfo}
          accordionChangeEvent={(e, rightPanelAccordionsExpanded) =>
            changeAccordionState(e, rightPanelAccordionsExpanded, 'warrantyInfo')
          }
        />
      ) : null}
    </>
  );
};

export default RightPanelInformationContainer;
