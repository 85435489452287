/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add New Part Number in Lookup Table
 *
 */
import React, {useMemo, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {AddForm, AddFormHeader, DrawerButton, ButtonContainer, FormContainer} from '../LookupTableStyles';
import {addServicePlanQuery} from '../../../../store/queries/lookupQueries';
import {SideDrawerProps, togglePanel, handleBackdropClick, MenuProps, InputProps} from '../lookupTableUtils';
import {useStore} from '../../../../store/Store';
import {AppAction} from '../../../../store/reducers/AppReducer';
import {LookupsListAction} from '../../../../store/reducers/LookupsReducer';
import FormComponent, {FormField, FormFieldType} from '../../../common/elements/Form';
import {NETWORK_OPTIONS, YES_NO} from '../../../common/constants';
import {validateDropdownField} from '../../../../utils/validationUtils';
import useFetchV2 from '../../../../utils/useFetchV2';

const ID_PREFIX = 'servicePlan';

interface IFormInput {
  planName: string;
  generalPlanName: string;
  dataAllowanceGb: string;
  hoursAllowance: string;
  coverage: string;
  vus: string;
  planType: string;
  planStatus: string;
  region: string;
  network: string;
  availableForViasat: string;
  availableForSatcomDirect: string;
  availableForHoneywell: string;
  availableForCollins: string;
  availableForOemMro: string;
  notes: string;
}

const LookupAddServicePlan: React.FC<SideDrawerProps> = ({openPanel, setOpenPanel}) => {
  const {dispatch} = useStore();
  const [newServicePlanInfo, setNewServicePlanInfo] = useState<any>(null);
  const initialValues: IFormInput = {
    planName: '',
    generalPlanName: '',
    dataAllowanceGb: '',
    hoursAllowance: '',
    coverage: '',
    vus: '',
    planType: '',
    planStatus: '',
    region: '',
    network: '',
    availableForViasat: '',
    availableForSatcomDirect: '',
    availableForHoneywell: '',
    availableForCollins: '',
    availableForOemMro: '',
    notes: ''
  };

  const servicePlanTermsFormFields: FormField[] = [
    {
      id: 'network',
      label: 'Network',
      type: FormFieldType.SELECT,
      value: initialValues.network,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: NETWORK_OPTIONS,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Network')
      }
    },
    {
      id: 'planType',
      label: 'Plant Type',
      type: FormFieldType.SELECT,
      value: initialValues.planType,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: ['Regional', 'Global'],
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Plan Type')
      }
    },
    {
      id: 'generalPlanName',
      label: 'General Plan Name',
      type: FormFieldType.INPUT,
      value: initialValues.generalPlanName,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'planName',
      label: 'Viasat Plan Name',
      type: FormFieldType.INPUT,
      value: initialValues.planName,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'dataAllowanceGb',
      label: 'Data Allowance GB',
      type: FormFieldType.INPUT,
      value: initialValues.dataAllowanceGb,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'hoursAllowance',
      label: 'Hours Allowance',
      type: FormFieldType.INPUT,
      value: initialValues.hoursAllowance,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'availableForViasat',
      label: 'Available for Viasat',
      type: FormFieldType.SELECT,
      value: initialValues.availableForViasat,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for Viasat')
      }
    },
    {
      id: 'availableForSatcomDirect',
      label: 'Available for Satcom Direct',
      type: FormFieldType.SELECT,
      value: initialValues.availableForSatcomDirect,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for Satcom Direct')
      }
    },
    {
      id: 'availableForHoneywell',
      label: 'Available for Honeywell',
      type: FormFieldType.SELECT,
      value: initialValues.availableForHoneywell,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for Honeywell')
      }
    },
    {
      id: 'availableForCollins',
      label: 'Available for Collins',
      type: FormFieldType.SELECT,
      value: initialValues.availableForCollins,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for Collins')
      }
    },
    {
      id: 'availableForOemMro',
      label: 'Available for OEM/MRO',
      type: FormFieldType.SELECT,
      value: initialValues.availableForOemMro,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for OEM/MRO')
      }
    },
    {
      id: 'notes',
      label: 'Notes',
      type: FormFieldType.TEXTFIELD,
      value: initialValues.notes,
      disabled: false,
      inputProps: InputProps,
      isMultiline: true,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  const {handleSubmit, formState, unregister, reset, watch, control} = useForm();

  const queryParams = useMemo(() => {
    if (
      newServicePlanInfo &&
      (!newServicePlanInfo.planName || !newServicePlanInfo.dataAllowanceGb || !newServicePlanInfo.hoursAllowance)
    )
      return;
    return newServicePlanInfo;
  }, [newServicePlanInfo]);

  const {isLoading: addInProgress, data: addNewServicePlanInfo} = useFetchV2(
    {route: addServicePlanQuery.route, params: queryParams},
    addServicePlanQuery.transform
  );

  const onCancelClick = () => {
    unregister();
    reset(
      {planName: '', dataAllowanceGb: '', hoursAllowance: '', coverage: '', vus: ''},
      {
        keepValues: false
      }
    );
    setOpenPanel(false);
  };

  const handleOpen = () => {
    togglePanel(true);
    setOpenPanel(true);
  };

  const handleClose = () => {
    if (localStorage.getItem('backdropClick') === 'true') {
      localStorage.setItem('backdropClick', 'false');
      return;
    }
    togglePanel(false);
    setOpenPanel(false);
  };

  useEffect(() => {
    if (addInProgress) return;
    if (addNewServicePlanInfo) {
      setOpenPanel(false);
      dispatch({
        type: LookupsListAction.SET_NEW_ROW_COUNT,
        payload: 1
      });
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'New service plan entry added.'}
      });
    }
    //eslint-disable-next-line
  }, [addInProgress, addNewServicePlanInfo]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={openPanel}
        onOpen={handleOpen}
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
      >
        <AddForm>
          <AddFormHeader id={`${ID_PREFIX}--servicePlanListTitle`}>ADD NEW SERVICE PLAN INFO</AddFormHeader>
          <FormContainer>
            <FormComponent
              id="add-service-plan-form"
              formFields={servicePlanTermsFormFields}
              setFormData={setNewServicePlanInfo}
              formControlMethods={{handleSubmit, formState, watch, control}}
              idPrefix={ID_PREFIX}
            ></FormComponent>
          </FormContainer>
          <ButtonContainer>
            <DrawerButton id="service-plan-cancel-button" className="cancel-button" onClick={onCancelClick}>
              <span className="button-text">Cancel</span>
            </DrawerButton>
            <DrawerButton
              form="add-service-plan-form"
              id="service-plan-add-button"
              className="add-button"
              onClick={() => null}
            >
              <span className="button-text">Add</span>
            </DrawerButton>
          </ButtonContainer>
        </AddForm>
      </SwipeableDrawer>
    </>
  );
};

export default LookupAddServicePlan;
