/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Equipment Details
 */
import {isNil} from 'lodash';
import React from 'react';
import AcuDetail from './equipment/AcuInformation';
import AntennaDetail from './equipment/AntennaInformation';
import ApsuDetail from './equipment/ApsuInformation';
import FanTrayDetail from './equipment/FanTrayInformation';
import ModemDetail from './equipment/ModemInformation';
import RouterDetail from './equipment/RouterInformation';
import {
  ReviewSubSectionDivider,
  ReviewItem,
  ReviewItemLabel,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewItems,
  ReviewSection,
  ReviewSubSection
} from '../ReviewInformationStyles';
import {TASK_TYPES} from '../../common/constants';
import {IEquipmentDetail} from '../reviewInformationTypes';

const EquipmentDetail: React.FC<IEquipmentDetail> = ({idPrefix, taskDetail, equipmentDetail}) => {
  return (
    <ReviewSection>
      <ReviewItemTitle id={`${idPrefix}__equipmentInfomration_title`}>Equipment Information</ReviewItemTitle>
      <ReviewItemSubTitle id={`${idPrefix}__equipmentInfomration_subtitle`}>
        Please review the information below for approval.
      </ReviewItemSubTitle>
      {/* Show the header of Previous and New only in the case of Replace Hardware */}
      {taskDetail && taskDetail?.taskType === TASK_TYPES.REPLACE_HARDWARE ? (
        <ReviewSubSection>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel></ReviewItemLabel>
              <ReviewItemValue>Previous</ReviewItemValue>
              <ReviewItemValue>New</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
          <ReviewSubSectionDivider />
        </ReviewSubSection>
      ) : null}
      <AntennaDetail
        idPrefix={idPrefix}
        kaAntenna={equipmentDetail.kaAntennaInfo}
        kuAntenna={equipmentDetail.kuAntennaInfo}
      />
      <ModemDetail idPrefix={idPrefix} kaModem={equipmentDetail.kaModemInfo} kuModem={equipmentDetail.kuModemInfo} />
      {!isNil(equipmentDetail.apsuInfo) ? (
        <ApsuDetail idPrefix={idPrefix} apsuDetail={equipmentDetail.apsuInfo} />
      ) : (
        <></>
      )}
      {!isNil(equipmentDetail.acuInfo) ? <AcuDetail idPrefix={idPrefix} acuDetail={equipmentDetail.acuInfo} /> : <></>}
      <FanTrayDetail idPrefix={idPrefix} fanTrayDetail={equipmentDetail.fanTrayInfo} />
      <RouterDetail idPrefix={idPrefix} routerDetail={equipmentDetail.routerInfo} />
    </ReviewSection>
  );
};

export default EquipmentDetail;
