/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Equipment Menu component
 */
import React, {useEffect, useMemo, useState} from 'react';
import {isNil} from 'lodash';
import {useLocation, useNavigate} from 'react-router-dom';
import {useStore} from '../../store/Store';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styled from '@emotion/styled';
import PageContainer from '../mainMenu/PageContainer';
import {
  BUTTON_HOVER_COLOR,
  FORM_COLOR,
  LABEL_COLOR,
  NEXT_BUTTON_DISABLED,
  NEXT_BUTTON_TEXT_DISABLED_COLOR
} from '../../theme/Colors';
import {COMPLETED_OPTION_TEXT_COLOR} from '../common/theme/Colors';
import {
  HeaderHorizontalRule,
  BottomHorizontalRule,
  PageDescription,
  HeaderLabel,
  NextButton,
  EquipmentDetailContainer
} from '../CommonStyles';
import Breadcrumb from '../common/Breadcrumb';
import {AircraftInfoAction} from '../../store/reducers/AircraftInfoReducer';
import {KU_NETWORK, KA_NETWORK, DUAL_BAND_NETWORK} from '../common/constants';
import {IStore} from '../../store/types';
import {APPROVAL_MODULES, IMenuOptions} from '../addNewTerminal/addNewTerminalUtils';
import {submitRequestQuery} from '../../store/queries/submitRequestQuery';
import {EquipmentInfoAction, EquipmentInfoState} from '../../store/reducers/EquipmentInfoReducer';
import NewTerminalCompletionModal from '../addNewTerminal/NewTerminalCompletionModal';
import {AppAction} from '../../store/reducers/AppReducer';
import useFetchV2 from '../../utils/useFetchV2';

type EquipmentMenuOptions = {
  id: string;
  name: string;
  equipment: string;
  isMandatory: boolean;
};

export interface EquipmentMenuProps {
  id?: string;
  title: string;
  description: string;
  aircraftSerialNumber?: string;
  kuMenuOptions: EquipmentMenuOptions[];
  kuMenuTitle: string;
  kaMenuOptions: EquipmentMenuOptions[];
  kaMenuTitle: string;
  dualBandMenuOptions: EquipmentMenuOptions[];
}

const EquipmentMenuSubTitle = styled.div`
  display: flex;
  width: 646px;
  height: 24px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
  color: ${FORM_COLOR};
  flex: none;
  order: 0;
  flex-grow: 0;
  .no-serial-number {
    color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
  }
`;

const EquipmentMenuItem = styled.button<{textColor: string}>`
   box-sizing: border-box;
   margin-bottom: 8px;
   background: white;
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 8px 28px;
   gap: 8px;
   min-width: 300px;
   width: -webkit-fill-available;
   height: 48px;
   font-family: 'Source Sans Pro';
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 24px;
   color: ${props => props.textColor};
   border: 1px solid ${NEXT_BUTTON_DISABLED};
   border-radius: 1000px;
   flex: none;
   order: 0;
   align-self: stretch;
   flex-grow: 0;
   cursor: pointer;
   justify-content: space-between;
   &:disabled {
     cursor: not-allowed;
     pointer-events: auto;
     color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
   }
   &:hover {
     background: ${BUTTON_HOVER_COLOR};
   }
   .option {
    display: flex;
    .arrow {
      width: 20px;
      height: 20px
      position: absolute;
      left: 16.67%;
      right: 16.67%;
      top: 16.67%;
      bottom: 16.67%;
      margin-left: 8px;
      color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
    }
   }
   .completed {
    font-size: 16px;
    display: flex;
    margin-left: 8px;
   }
 `;

/**
 * Determines the completion state of atleast one equipment to allow the user to submit it for approval
 * @param equipmentInfo Equipment Information from Store
 * @param menuOptions EquipmentMenu Options
 * @returns Allowed to submit or not
 */
const determineReplaceEquipmentCompletionState = (
  equipmentInfo: EquipmentInfoState,
  menuOptions: IMenuOptions[]
): boolean => {
  let readyForSubmit = false;
  menuOptions.forEach(option => {
    if (!isNil(equipmentInfo[option.equipment])) {
      readyForSubmit = true;
      return;
    }
  });
  return readyForSubmit;
};

const ActionButtons: React.FC<{
  completionStatus: boolean;
  store: IStore;
  setTaskId: any;
  isNewInstall: boolean;
  idPrefix: string;
}> = ({completionStatus, store, setTaskId, isNewInstall, idPrefix}) => {
  if (isNewInstall) return;
  return (
    <NextButton
      id={`${idPrefix}--equipmentApproval`}
      onClick={() => setTaskId(store.equipmentInfo.taskId)}
      disabled={!completionStatus}
    >
      <span className="next-button-text">Submit</span>
      <ArrowForwardIcon className="next-arrow" />
    </NextButton>
  );
};

const LeftChild: React.FC<{equipmentMenuOptions: any}> = ({equipmentMenuOptions}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {store, dispatch} = useStore();
  const network = store.aircraftInfo.network;

  const handleInstallationOptionClick = (navigationPath: string, aircraftNetwork: string) => {
    dispatch({type: AircraftInfoAction.SET_AIRCRAFT_NETWORK, payload: aircraftNetwork});
    navigate('/' + navigationPath);
  };
  return (
    <>
      <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
      <HeaderLabel id={`${equipmentMenuOptions.id}--header`}>{equipmentMenuOptions.title}</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${equipmentMenuOptions.id}--description`}>
        {equipmentMenuOptions.description}
      </PageDescription>
      <BottomHorizontalRule />
      <EquipmentDetailContainer>
        {network === DUAL_BAND_NETWORK || network === KU_NETWORK ? (
          <>
            <EquipmentMenuSubTitle id={`ku-${equipmentMenuOptions.id}--subtitle`} style={{marginTop: 40}}>
              {equipmentMenuOptions.kuMenuTitle}
            </EquipmentMenuSubTitle>

            {equipmentMenuOptions.kuMenuOptions.map(options => {
              const dataSubmissionStatus =
                !isNil(store.equipmentInfo[options.equipment]) &&
                (!isNil(store.equipmentInfo[options.equipment]['serialNumber']) ||
                  !isNil(store.equipmentInfo[options.equipment]['routerType']));
              return (
                <EquipmentMenuItem
                  key={`ku-${options.id}--button`}
                  id={`ku-${options.id}--button`}
                  onClick={() => handleInstallationOptionClick(options.navPath, KU_NETWORK)}
                  textColor={dataSubmissionStatus ? COMPLETED_OPTION_TEXT_COLOR : LABEL_COLOR}
                >
                  <span className="option">
                    {options.name} <ArrowForwardIcon id={`ku-${options.id}-arrow`} className="arrow" />
                  </span>
                  {dataSubmissionStatus ? (
                    <span className="completed" id={`ku-${options.id}-${options.equipment}-completed`}>
                      <CheckCircleOutlineIcon />
                    </span>
                  ) : (
                    <></>
                  )}
                </EquipmentMenuItem>
              );
            })}
          </>
        ) : (
          <></>
        )}
        {network === DUAL_BAND_NETWORK || network === KA_NETWORK ? (
          <>
            <EquipmentMenuSubTitle id={`ka-${equipmentMenuOptions.id}--subtitle`} style={{marginTop: 40}}>
              {equipmentMenuOptions.kaMenuTitle}
            </EquipmentMenuSubTitle>

            {(network === KA_NETWORK
              ? equipmentMenuOptions.kaMenuOptions
              : equipmentMenuOptions.dualBandMenuOptions
            ).map(options => {
              const dataSubmissionStatus =
                !isNil(store.equipmentInfo[options.equipment]) &&
                (!isNil(store.equipmentInfo[options.equipment]['serialNumber']) ||
                  !isNil(store.equipmentInfo[options.equipment]['routerType']));
              return (
                <EquipmentMenuItem
                  key={`ka-${options.id}--button`}
                  id={`ka-${options.id}--button`}
                  onClick={() => handleInstallationOptionClick(options.navPath, KA_NETWORK)}
                  textColor={dataSubmissionStatus ? COMPLETED_OPTION_TEXT_COLOR : LABEL_COLOR}
                >
                  <span className="option">
                    {options.name} <ArrowForwardIcon id={`ka-${options.id}-arrow`} className="arrow" />
                  </span>
                  {dataSubmissionStatus ? (
                    <span className="completed" id={`ka-${options.id}-${options.equipment}-completed`}>
                      <CheckCircleOutlineIcon />
                    </span>
                  ) : (
                    <></>
                  )}
                </EquipmentMenuItem>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </EquipmentDetailContainer>
    </>
  );
};

const EquipmentMenu: React.FC<EquipmentMenuProps> = ({
  id,
  title,
  description,
  aircraftSerialNumber,
  kuMenuOptions,
  kuMenuTitle,
  kaMenuOptions,
  kaMenuTitle,
  dualBandMenuOptions
}) => {
  const idPrefix = 'equipmentMenuOptions';
  const {store, dispatch} = useStore();
  const navigate = useNavigate();
  const isNewInstall = store.aircraftInfo.isNewInstall;
  const network = store.aircraftInfo.aircraftNetwork;

  const [showCompletionModal, setShowCompletionModal] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>('');

  const queryParams = useMemo(() => {
    if (!taskId) return;
    return {
      taskId: taskId,
      moduleForApproval: APPROVAL_MODULES.EQUIPMENT
    };
  }, [taskId]);

  const {isLoading: isSubmitRequestLoading, data: submitResponse} = useFetchV2(
    {route: submitRequestQuery.route, params: queryParams},
    submitRequestQuery.transform
  );
  const closeModal = () => {
    setShowCompletionModal(false);
    dispatch({type: EquipmentInfoAction.RESET_EQUIPMENT_INFO});
    navigate('/main-menu');
  };
  useEffect(() => {
    if (isSubmitRequestLoading) return;
    if (submitResponse) {
      if (submitResponse.taskType === 'ReplaceHardware') {
        dispatch({
          type: AppAction.SET_ALERT_INFO,
          payload: {
            showAlert: true,
            operation: 'added',
            message: 'Replace Hardware request has been',
            details:
              'Replace Hardware request has been sent for approval. Support staff will reply via email as quickly as possible.'
          }
        });
        navigate('/main-menu');
      } else {
        setShowCompletionModal(true);
      }
    }
    // eslint-disable-next-line
  }, [submitResponse, isSubmitRequestLoading]);

  const leftChildProps = {
    id,
    title,
    description,
    aircraftSerialNumber,
    kuMenuOptions,
    kuMenuTitle,
    kaMenuOptions,
    kaMenuTitle,
    dualBandMenuOptions
  };

  const networkBasedOptions =
    network === DUAL_BAND_NETWORK ? dualBandMenuOptions : network === KA_NETWORK ? kaMenuOptions : kuMenuOptions;

  const completionStatus = determineReplaceEquipmentCompletionState(store.equipmentInfo, networkBasedOptions);

  return (
    <>
      <NewTerminalCompletionModal openModal={showCompletionModal} setOpenModal={closeModal} />
      <PageContainer
        title={title}
        leftChild={<LeftChild equipmentMenuOptions={leftChildProps} />}
        actionButtons={
          <ActionButtons
            isNewInstall={isNewInstall}
            completionStatus={completionStatus}
            store={store}
            setTaskId={setTaskId}
            idPrefix={idPrefix}
          />
        }
      />
    </>
  );
};

export default EquipmentMenu;
