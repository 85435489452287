/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add SnackBars Messages
 *
 */
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import {AppAction} from '../../store/reducers/AppReducer';
import {useStore} from '../../store/Store';

const SnackBar: React.FC<any> = ({id, openSnack, snackMessage}) => {
  const {dispatch} = useStore();
  const handleSnackBarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    dispatch({
      type: AppAction.SET_SNACK_BAR,
      payload: {openSnack: false}
    });
  };

  return (
    <>
      <Snackbar id={id} open={openSnack} autoHideDuration={6000} onClose={handleSnackBarClose} message={snackMessage} />
    </>
  );
};

export default SnackBar;
