/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Lookup List Filter Utils
 */
import {isNil} from 'lodash';
import {FiltersState} from '../../../store/reducers/FiltersReducer';
import {FilterOptionsType, FilterType, convertObjectToFilterRangeOptions} from '../../../utils/filterUtils';
import {convertFilterValues} from '../listsUtil';
import {MANUFACTURERS} from '../../common/constants';

export enum AircraftModelFilterId {
  aircraftMake = 'aircraftMake',
  aircraftModel = 'aircraftModel',
  status = 'status'
}

export enum ServicePlanListFilterId {
  network = 'network',
  generalPlanName = 'generalPlanName',
  planName = 'planName',
  planStatus = 'planStatus',
  planType = 'planType'
}

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getMakeFilterRangeOptions = (): Array<FilterOptionsType> => convertFilterValues(MANUFACTURERS);

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getModelFilterRangeOptions = (props): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'aircraftModel') : [];

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getStatusFilterRangeOptions = (): Array<FilterOptionsType> => convertFilterValues(['Current', 'Retired']);

export const AIRCRAFT_MODEL_LIST_FILTERS: Array<FilterType> = [
  {
    title: 'Make',
    id: AircraftModelFilterId.aircraftMake,
    getValues: getMakeFilterRangeOptions
  },
  {
    title: 'Model',
    id: AircraftModelFilterId.aircraftModel,
    getValues: getModelFilterRangeOptions
  },
  {
    title: 'Status',
    id: AircraftModelFilterId.status,
    getValues: getStatusFilterRangeOptions
  }
];

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getPlanStatusFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertFilterValues(['Legacy', 'Current', 'Retired']);

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getPlanTypeFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertFilterValues(['Global', 'Regional']);

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getPlanNameFilterRangeOptions = (props): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'planName') : [];

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getGeneralPlanNameFilterRangeOptions = (props): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'generalPlanName') : [];

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getNetworkFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertFilterValues(['Ka', 'Ku', 'Dual Band']);

export const SERVICE_PLAN_LIST_FILTERS: Array<FilterType> = [
  {
    title: 'Network',
    id: ServicePlanListFilterId.network,
    getValues: getNetworkFilterRangeOptions
  },
  {
    title: 'General Plan Name',
    id: ServicePlanListFilterId.generalPlanName,
    getValues: getGeneralPlanNameFilterRangeOptions
  },
  {
    title: 'Plan Name',
    id: ServicePlanListFilterId.planName,
    getValues: getPlanNameFilterRangeOptions
  },
  {
    title: 'Plan Status',
    id: ServicePlanListFilterId.planStatus,
    getValues: getPlanStatusFilterRangeOptions
  },
  {
    title: 'Plan Type',
    id: ServicePlanListFilterId.planType,
    getValues: getPlanTypeFilterRangeOptions
  }
];

/**
 * Applies the filters dynamically based on the list of fitlers selecter per view
 * @param listData List view data that needs to be filter applied
 * @param filters List of filters selected in the filter hook container
 * @returns Filtered list data based on the list of filters
 */
export const dynamicFilter = (listData: any, filters: FiltersState) => {
  if (!isNil(filters) && filters.filters && filters.filters.length && !isNil(listData)) {
    let filteredData = listData;
    filters.filters &&
      filters.filters.map(
        filter =>
          (filteredData = listData.filter(aircraftModelList =>
            filter.rangeOptionsKeys.includes(aircraftModelList[filter.domainOptionsKey])
          ))
      );
    return filteredData;
  } else {
    return listData;
  }
};
