/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for initial data
 */

import {InitData} from '../queries/initQuery';

export const INIT_STORE_CONTEXT = 'init';

export type InitState = InitData;

export enum InitAction {
  SET_INIT = 'SET_INIT',
  SET_VIEW_AS_CUSTOMER = 'SET_VIEW_AS_CUSTOMER'
}

export enum CommonAction {
  PROCESS_URL_PARAMS = 'PROCESS_URL_PARAMS'
}

export const InitialInitState: InitState = {
  isInternal: false,
  isAdmin: false,
  isValueAddedReseller: false,
  email: '',
  uid: '',
  fullName: ''
};

export const InitSessionStoreKeys: string[] = ['isInternal', 'isAdmin', 'isValueAddedReseller'];

/**
 * Init state action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const InitReducer = (state: InitState, action: any): InitState => {
  switch (action.type) {
    case InitAction.SET_INIT:
      state = {...state, ...action.payload};
      break;
    default:
      break;
  }
  return state;
};
