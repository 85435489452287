/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Replace Equipment Menu
 */
import React from 'react';
import EquipmentMenu from '../equipmentMenu/EquipmentMenu';

interface ReplaceEquipmentMenuProps {
  title: string;
  aircraftSerialNumber?: string;
}

const ReplaceEquipmentMenu: React.FC<ReplaceEquipmentMenuProps> = ({title, aircraftSerialNumber}) => {
  const description = `Select one of the following options to replace faulty terminal equipment.`;

  const kuMenuOptions = [
    {
      id: 'replaceModemInformation',
      navPath: 'replace-modem-information',
      name: 'Replace Modem',
      equipment: 'kuModem',
      isMandatory: true
    },
    {
      id: 'replaceAntennaInformation',
      navPath: 'replace-antenna-information',
      name: 'Replace Antenna',
      equipment: 'kuAntenna',
      isMandatory: true
    },
    {
      id: 'replaceAcuInformation',
      navPath: 'replace-acu-information',
      name: 'Replace ACU',
      equipment: 'acu',
      isMandatory: true
    },
    {
      id: 'replaceRouterInformation',
      navPath: 'replace-router-information',
      name: 'Replace Router',
      equipment: 'router',
      isMandatory: false
    }
  ];
  const dualBandMenuOptions = [
    {
      id: 'replaceModemInformation',
      navPath: 'replace-modem-information',
      name: 'Replace Modem',
      equipment: 'kaModem',
      isMandatory: true
    },
    {
      id: 'replaceAntennaInformation',
      navPath: 'replace-antenna-information',
      name: 'Replace Antenna',
      equipment: 'kaAntenna',
      isMandatory: true
    },
    {
      id: 'replaceApsuInformation',
      navPath: 'replace-apsu-information',
      name: 'Replace APSU',
      equipment: 'apsu',
      isMandatory: true
    },
    {
      id: 'replaceFantrayInformation',
      navPath: 'replace-fantray-information',
      name: 'Replace Fan Tray',
      equipment: 'fanTray',
      isMandatory: false
    }
  ];
  const kaMenuOptions = [
    ...dualBandMenuOptions,
    {
      id: 'replaceRouterInformation',
      navPath: 'replace-router-information',
      name: 'Replace Router',
      equipment: 'router',
      isMandatory: false
    }
  ];

  return (
    <EquipmentMenu
      id="replaceEquipmentMenu"
      title={title}
      description={description}
      aircraftSerialNumber={aircraftSerialNumber}
      kuMenuOptions={kuMenuOptions}
      kuMenuTitle="Ku Hardware Replacement Options"
      kaMenuOptions={kaMenuOptions}
      kaMenuTitle="Ka Hardware Replacement Options"
      dualBandMenuOptions={dualBandMenuOptions}
    />
  );
};

export default ReplaceEquipmentMenu;
