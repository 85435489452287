/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - New Service Plan Details
 */
import React from 'react';
import {DateTime} from 'luxon';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewSection,
  ReviewSubSection,
  ReviewSubSectionItemTitle
} from '../ReviewInformationStyles';
import {renderReviewInformation} from '../util';
import {IServicePlanDetail} from '../reviewInformationTypes';
import {useStore} from '../../../store/Store';
import {find} from 'lodash';
import {DUAL_BAND_NETWORK, KA_NETWORK, REVIEW_REQUEST_KA_IP_BAND_WARNING, TASK_STATUS} from '../../common/constants';
import StatusComponent from '../../common/elements/StatusContainer';

const NewServicePlanDetail: React.FC<IServicePlanDetail> = ({idPrefix, servicePlanDetail, taskDetails}) => {
  const {store} = useStore();
  const {servicePlans} = store.app;
  const dataAllowance = find(servicePlans, {generalPlanName: servicePlanDetail.servicePlanType});

  const planStartDate = servicePlanDetail?.planStartDate
    ? DateTime.fromISO(servicePlanDetail?.planStartDate).toLocaleString()
    : null;
  return (
    <ReviewSection>
      <ReviewItemTitle>NEW SERVICE PLAN</ReviewItemTitle>
      <ReviewItemSubTitle>Please review the information below for approval.</ReviewItemSubTitle>
      <ReviewSubSection>
        <ReviewSubSectionItemTitle>Service Plan Information</ReviewSubSectionItemTitle>
        <ReviewItems>
          <ReviewItem>
            <ReviewItemLabel>Service Plan Type</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__planDetails--planType`}>
              {renderReviewInformation(servicePlanDetail.servicePlanType)}
            </ReviewItemValue>
          </ReviewItem>

          <ReviewItem>
            <ReviewItemLabel>Plan Start Date</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__planDetails--planStartDate`}>
              {renderReviewInformation(planStartDate)}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Data Allowance</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__planDetails--dataAllowance`}>
              {dataAllowance && dataAllowance.dataAllowanceGb
                ? `${renderReviewInformation(dataAllowance?.dataAllowanceGb?.toString())} GB`
                : '--'}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Contract Term</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__planDetails--planType`}>
              {renderReviewInformation(servicePlanDetail.servicePlanTerm)}
            </ReviewItemValue>
          </ReviewItem>
          {taskDetails.network === KA_NETWORK || taskDetails.network === DUAL_BAND_NETWORK ? (
            <>
              <ReviewItem>
                <ReviewItemLabel>Ka Band IP Address</ReviewItemLabel>
                <ReviewItemValue id={`${idPrefix}__planDetails--planTerm`}>
                  {renderReviewInformation(servicePlanDetail.ipScope)}
                </ReviewItemValue>
              </ReviewItem>
              {servicePlanDetail.kaBandIpExists ? (
                <ReviewItem>
                  <ReviewItemLabel />
                  <ReviewItemValue id={`${idPrefix}__planDetails--planTerm`}>
                    <StatusComponent
                      status={TASK_STATUS.INFO_REQUIRED}
                      idPrefix={`${idPrefix}__ka-ip-band-address-existing-warning`}
                      message={REVIEW_REQUEST_KA_IP_BAND_WARNING}
                    />
                  </ReviewItemValue>
                </ReviewItem>
              ) : null}
            </>
          ) : null}
        </ReviewItems>
      </ReviewSubSection>
    </ReviewSection>
  );
};

export default NewServicePlanDetail;
