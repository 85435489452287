/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Form to capture Warranty Extension Reason
 */
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {useStore} from '../../store/Store';
import {EquipmentInfoAction} from '../../store/reducers/EquipmentInfoReducer';

import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';

import {BottomHorizontalRule, HeaderHorizontalRule, HeaderLabel, PageDescription} from '../CommonStyles';
import {AppAction} from '../../store/reducers/AppReducer';
import {isNil} from 'lodash';
import useFetchV2 from '../../utils/useFetchV2';
import {extendWarrantyQuery} from '../../store/queries/warrantyExtensionQuery';
import FormSubmitButton from '../common/elements/FormSubmitButton';
import {TextFieldInputProps} from '../servicePlan/DecommissionServicePlan';


const ID_PREFIX = 'warrantyExtensionReason';

interface WarrantyExtensionReasonProps {
  title: string;
}

interface IFormInput {
  reason: string;
}




const WarrantyExtensionReason: React.FC<WarrantyExtensionReasonProps> = ({title}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const WarrantyExtReasonForm = () => {
    const {store, dispatch} = useStore();

    const [warrantyInfo, setWarrantyInfo] = useState(null);
    const {handleSubmit, formState, watch, control} = useForm();
    const {breadcrumb} = store.app;

    const initialValuesForm: IFormInput = {
      reason: ''
    };
    const warrantyReasonValue = watch('warrantyReason');

    const warrantyReasonFormFields: FormField[] = [
      {
        id: 'warrantyReason',
        label: 'Reason for Warranty Change',
        type: FormFieldType.TEXTFIELD,
        placeholder: 'Enter reason for warranty changes...',
        isMultiline: true,
        inputProps: TextFieldInputProps,
        value: initialValuesForm.reason,
        disabled: false,
        rules: {
          required: 'Reason for Warranty Change is required',
          maxLength: {
            value: 250,
            message: 'Reason for Warranty Change cannot exceed more than 250 characters'
          },
          pattern: null
        },
        helperText: `${warrantyReasonValue?.length | 0} / 250`
      }
    ];
    const warrantyDetails = store.equipmentInfo.warrantyDetails;

    const queryParams = useMemo(() => {
      if (!warrantyInfo) return;
      warrantyDetails.reason = warrantyInfo.warrantyReason;
      return warrantyDetails;
      //eslint-disable-next-line
    }, [warrantyInfo]);

    const {isLoading: isSubmitRequestLoading, data: submitResponse} = useFetchV2(
      {route: extendWarrantyQuery.route, params: queryParams},
      extendWarrantyQuery.transform
    );

    useEffect(() => {
      if (!warrantyInfo || isSubmitRequestLoading || isNil(submitResponse)) return;
      dispatch({
        type: EquipmentInfoAction.SET_WARRANTY_REASON,
        payload: warrantyInfo.warrantyReason
      });
      dispatch({
        type: EquipmentInfoAction.SET_EQUIPMENT_WARRANTY_EXTENSION,
        payload: {
          kaAntennaExtendedWarrantyEndDate: warrantyDetails.ka.antennaWarrantyEndDate,
          kaModemExtendedWarrantyEndDate: warrantyDetails.ka.modemWarrantyEndDate,
          kaApsuExtendedWarrantyEndDate: warrantyDetails.ka.apsuWarrantyEndDate,
          kaFantrayExtendedWarrantyEndDate: warrantyDetails.ka.fantrayWarrantyEndDate,
          kaRouterExtendedWarrantyEndDate: warrantyDetails.ka.routerWarrantyEndDate,
          kuAntennaExtendedWarrantyEndDate: warrantyDetails.ku.antennaWarrantyEndDate,
          kuModemExtendedWarrantyEndDate: warrantyDetails.ku.modemWarrantyEndDate,
          kuAcuExtendedWarrantyEndDate: warrantyDetails.ku.acuWarrantyEndDate,
          kuRouterExtendedWarrantyEndDate: warrantyDetails.ku.routerWarrantyEndDate
        }
      });

      const message = 'Warranties have been extended.';
      dispatch({
        type: AppAction.SET_BREADCRUMB,
        payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
      });
      dispatch({
        type: AppAction.SET_ALERT_INFO,
        payload: {
          showAlert: true,
          operation: null,
          message: message,
          details: ''
        }
      });
      navigate('/main-menu');

      // eslint-disable-next-line
    }, [warrantyInfo, isSubmitRequestLoading, submitResponse]);

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Reason for Warranty Change</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--description`}>
          Please add the reason for changing the warranty end dates.
        </PageDescription>
        <BottomHorizontalRule />
        <FormComponent
          idPrefix={ID_PREFIX}
          id="warranty-extension-reason-form"
          formFields={warrantyReasonFormFields}
          setFormData={setWarrantyInfo}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
      </>
    );
  };

  return (
    <PageContainer
      title={title}
      leftChild={<WarrantyExtReasonForm />}
      actionButtons={
        <FormSubmitButton
          buttonId={`${ID_PREFIX}--nextButton`}
          formId="warranty-extension-reason-form"
          disabled={false}
          hasArrowIcon={true}
          isLoading={false}
          isSubmit={true}
        />
      }
    />
  );
};

export default WarrantyExtensionReason;
