/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Access Control Wrapper to return the child element based on the access
 */

import React from 'react';

interface IAccessControl {
  permitted: boolean;
}

const AccessControl: React.FC<IAccessControl> = ({permitted, children}) => {
  return permitted ? <>{children}</> : <></>;
};

export default AccessControl;
