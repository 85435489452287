/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Reducer for column sort state
 */
import {Severity} from '@viasat/insights-components';

import {SnackBarState, createSnackBar} from './SnackBarReducer';

export interface OptionRow {
  optionKey: string;
  optionValue: string;
}
export type SortOrder = 'asc' | 'desc';

export interface ColumnSortState {
  options: OptionRow[];
  column: string;
  order: SortOrder;
  queryField: string;
}

declare type ParentState = {sort: ColumnSortState; snackBar?: SnackBarState};

export enum ColumnSortAction {
  SET_COLUMN_SORT = 'SET_COLUMN_SORT',
  CLEAR_COLUMN_SORT = 'CLEAR_COLUMN_SORT'
}

/**
 * Checks if sort added from URL are valid, generates a snackbar for all invalid sort
 *
 * @param state Current sort state
 * @returns snackbar message for invalid filter
 */
export const validateSort = (
  state: ParentState,
  validColumns: string[],
  validQueryFields: string[],
  defaultState: ParentState
): ParentState => {
  let {column, order, queryField} = state.sort;
  const validOrders = ['asc', 'desc'];
  const {order: defaultOrder, column: defaultColumn, queryField: defaultQueryField} = defaultState.sort;

  const msg = ['Invalid Sort'];
  const messages = state.snackBar && state.snackBar.length ? [...state.snackBar] : [];

  const isSortColumnInvalid =
    validColumns && validColumns.length > 0 && !validColumns.some((validColumn: any) => validColumn === column);

  const isSortOrderInvalid = !validOrders.some((validOrder: any) => validOrder === order);

  const isSortQueryFieldInvalid =
    validQueryFields &&
    validQueryFields.length > 0 &&
    !validQueryFields.some((validQueryField: any) => validQueryField === queryField);

  if (isSortColumnInvalid) {
    msg.push(`Column (${column}) `);
    column = defaultColumn;
  }
  if (isSortOrderInvalid) {
    msg.push(`Order (${order}) `);
    order = defaultOrder;
  }
  if (isSortQueryFieldInvalid) {
    msg.push(`Query Field (${queryField}) `);
    queryField = defaultQueryField;
  }

  if (isSortColumnInvalid || isSortOrderInvalid || isSortQueryFieldInvalid) {
    messages.push(
      createSnackBar({
        message: msg.join(' '),
        severity: Severity.WARNING,
        getFullElementId: (name, type) => `${name}-${type}`
      })
    );
  }

  return {
    ...state,
    sort: {
      ...state.sort,
      column,
      order,
      queryField
    },
    snackBar: messages
  };
};

/**
 * Embedded store for Sort
 * @param state Current State
 * @param action Action to perform
 * @param defaultState Initial Default State for embedded store
 * @returns Updated state
 */
export const ColumnSortReducer = (state: ParentState, action: any, defaultState: ParentState): ParentState => {
  const {...payload} = action.payload || {};
  switch (action.type) {
    case ColumnSortAction.SET_COLUMN_SORT:
      state = validateSort(
        {
          ...state,
          sort: {
            options: state.sort.options,
            column: payload.column,
            order: payload.order,
            queryField: payload.queryField
          }
        },
        payload.validColumns,
        payload.validQueryFields,
        defaultState
      );
      break;
    case ColumnSortAction.CLEAR_COLUMN_SORT:
      state = {
        ...state,
        sort: {
          options: [],
          column: '',
          order: 'desc',
          queryField: ''
        }
      };
      break;
    default:
      break;
  }

  return state;
};
