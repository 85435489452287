/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Antenna Information
 */

import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {useStore} from '../../store/Store';
import {AppAction} from '../../store/reducers/AppReducer';
import {EquipmentInfoAction, IAntennaInformation} from '../../store/reducers/EquipmentInfoReducer';

import {updateKuAntennaInformation} from '../../store/queries/newInstall/equipmentInfoQuery';
import {updateKaAntennaInformation} from '../../store/queries/newInstall/equipmentInfoQuery';

import {useEquipmentUpdate} from '../../utils/customHooks';
import {useEquipmentInformationForm} from '../../utils/equipmentInformationFormHook';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import FormComponent from '../common/elements/Form';

import {
  BottomHorizontalRule,
  HeaderHorizontalRule,
  HeaderLabel,
  LatestRevision,
  NextButton,
  PageDescription,
  StyledLink
} from '../CommonStyles';

import {EquipmentType, KU_NETWORK, SELECT_AN_OPTION} from '../common/constants';
import {showUserManuals} from '../../utils/config';

const ID_PREFIX = 'antennaInformation';

interface AntennaInformationPageProps {
  title: string;
}

interface IFormInput {
  partNumber: string;
  serialNumber: string;
}

const AntennaInformationPage: React.FC<AntennaInformationPageProps> = ({title}) => {
  const location = useLocation();

  const AntennaInfoForm = () => {
    const {store, dispatch} = useStore();
    const {aircraftInfo} = store;
    const {aircraftNetwork} = aircraftInfo;
    const taskId = store.openTasksInfo.newTask?.taskId;
    const antennaInfo = aircraftNetwork === KU_NETWORK ? store.equipmentInfo.kuAntenna : store.equipmentInfo.kaAntenna;
    const antennaConfigItems = store.app.configItems?.['Antenna'] ? store.app.configItems['Antenna'] : [];
    const breadcrumbInfo = store.app.breadcrumb;
    const initialValues = antennaInfo
      ? antennaInfo
      : {
          partNumber: SELECT_AN_OPTION,
          serialNumber: ''
        };

    const {handleSubmit, formState, watch, control} = useForm();
    const {productDescription, equipmentFormFields} = useEquipmentInformationForm(
      watch,
      aircraftNetwork,
      initialValues,
      EquipmentType.ANTENNA,
      antennaConfigItems
    );

    const [antennaFormData, setAntennaFormData] = useState<IFormInput>(null);

    useEquipmentUpdate<IAntennaInformation>(
      taskId,
      antennaFormData,
      aircraftNetwork === KU_NETWORK ? updateKuAntennaInformation : updateKaAntennaInformation,
      '/new-terminal-installation',
      () => {
        dispatch({
          type:
            aircraftNetwork === KU_NETWORK
              ? EquipmentInfoAction.SET_KU_ANTENNA_INFO
              : EquipmentInfoAction.SET_KA_ANTENNA_INFO,
          payload: {productDescription, ...antennaFormData}
        });
        if (aircraftNetwork === KU_NETWORK) {
          dispatch({
            type: EquipmentInfoAction.SET_APSU_INFO,
            payload: null
          });
          dispatch({
            type: EquipmentInfoAction.SET_FAN_TRAY_INFO,
            payload: null
          });
          dispatch({
            type: EquipmentInfoAction.SET_KA_MODEM_INFO,
            payload: null
          });
          dispatch({
            type: EquipmentInfoAction.SET_KA_ANTENNA_INFO,
            payload: null
          });
        }
        dispatch({
          type: AppAction.SET_BREADCRUMB,
          payload: breadcrumbInfo.filter(breadCrumb => breadCrumb.linkPath === '/new-terminal-installation')
        });
      },
      aircraftInfo
    );

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Enter Antenna Information</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--description`}>
          Use your mobile device to scan the QR code on the Antenna, or manually enter the antenna information for the
          new terminal.
        </PageDescription>
        <BottomHorizontalRule />
        <FormComponent
          idPrefix={ID_PREFIX}
          id="new-antenna-information-form"
          formFields={equipmentFormFields}
          setFormData={setAntennaFormData}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
        {showUserManuals ? (
          <>
            <BottomHorizontalRule />
            <LatestRevision id={`${ID_PREFIX}--latestRevisionTitle`}>
              View latest revision of the Viasat Modem Antenna and Test Manual here:
            </LatestRevision>
            <StyledLink href="#" id={`${ID_PREFIX}--installProcedureLink`}>
              Antenna Installation Procedures
            </StyledLink>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton id={`${ID_PREFIX}--nextButton`} form="new-antenna-information-form" disabled={false}>
        <span className="next-button-text">Next</span>
        <ArrowForwardIcon className="next-arrow" />
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<AntennaInfoForm />} actionButtons={<ActionButtons />} />;
};

export default AntennaInformationPage;
