/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: AutoCompleteInput Component
 */
import {useState} from 'react';
import {FormField} from './Form';
import {Autocomplete, TextField, ListItem, ListItemText, InputAdornment} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import {CLEAR_ICON_COLOR, EDITED_TXT_COLOR, NEXT_BUTTON_BORDER} from '../../../theme/Colors';
import styled from '@emotion/styled';
// import styled as EStyled from '@emotion/styled';
const StyledListItem = styled(ListItem)({
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  padding: '10px'
});

const StyledListItemText = styled(ListItemText)({
  fontFamily: 'Source Sans Pro',
  fontSize: '16px',
  color: CLEAR_ICON_COLOR
});

const AutoCompleteElement = styled.div`
  .clientJss-MuiAutocomplete-input {
    padding: 2.5px 4px 2.5px 6px !important;
  }
  .clientJss-MuiAutocomplete-input {
    font-family: 'Source Sans Pro' !important;
  }
`;

const AutoCompleteInput: React.FC<{
  id: string;
  formField: FormField;
  onChange: (...event: any[]) => void;
  errors;
  placeholder: string;
}> = ({id, formField, onChange, placeholder}) => {
  const [inputValue, setInputValue] = useState<string>(`${formField.value ? formField.value : ''}`);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  /**
   * Determines the input value and highlights the list items text
   * @param text Options text
   * @param highlight Input value
   * @returns Element with bold/normal font weight based on search
   */
  const highlightText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{fontWeight: 'bold'}}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  /**
   * This will filter out the items from list based on the search input
   * @param options Options list
   * @param state Input value status
   * @returns Gives filters items from the list
   */
  const filterOptions = (options: string[], state: {inputValue: string}) => {
    return options?.filter(option => option.toLowerCase().includes(state.inputValue.toLowerCase()));
  };
  return (
    <AutoCompleteElement>
      <Autocomplete
        options={formField?.dropDownValues}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue);
          setSelectedValue(newValue);
        }}
        sx={{padding: 1}}
        id={id}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        autoSelect={true}
        filterOptions={filterOptions}
        freeSolo={true}
        clearIcon={
          <CancelIcon sx={{position: 'absolute', top: -5, right: 10, color: CLEAR_ICON_COLOR, fontSize: 20}} />
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            sx={{
              fontFamily: 'Source Sans Pro',
              fontSize: 16,
              fontWeight: 400,
              color: EDITED_TXT_COLOR,
              backgroundColor: NEXT_BUTTON_BORDER
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        )}
        renderOption={(props, option) => (
          <StyledListItem {...props} key={option}>
            <StyledListItemText primary={highlightText(option, inputValue)} />
          </StyledListItem>
        )}
      />
    </AutoCompleteElement>
  );
};

export default AutoCompleteInput;
