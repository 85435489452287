/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Page Navigation Component
 */
import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from '@emotion/styled';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {BORDER_COLOR} from '../../theme/Colors';
import {BackButton} from '../CommonStyles';
import {useStore} from '../../store/Store';
import {AppAction} from '../../store/reducers/AppReducer';

const BottomPanel = styled.div`
  border-top: 1px solid ${BORDER_COLOR};
  height: 5vh;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BottomPanelContent = styled.div`
  padding: 16px 158px;
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100%;
`;

interface PageNavigationComponentProps {
  actionButtons?: any;
  onBackClick?: Function;
  title?: string;
}

const PageNavigationComponent: React.FC<PageNavigationComponentProps> = ({onBackClick, actionButtons, title}) => {
  const navigate = useNavigate();
  const {store, dispatch} = useStore();
  const breadcrumbInfo = [...store.app.breadcrumb];
  const OnBackClick = () => {
    if (typeof onBackClick !== 'function') {
      breadcrumbInfo.pop();
      if (breadcrumbInfo && breadcrumbInfo.length) {
        dispatch({type: AppAction.SET_BREADCRUMB, payload: breadcrumbInfo});
        navigate(breadcrumbInfo[breadcrumbInfo.length - 1]?.linkPath);
      } else {
        dispatch({type: AppAction.SET_BREADCRUMB, payload: breadcrumbInfo});
        navigate('/main-menu');
      }
    } else {
      onBackClick();
    }
  };

  return (
    <BottomPanel>
      <BottomPanelContent>
        <div style={{width: '50%', display: 'flex', justifyContent: 'left', marginTop: '14px'}}>
          <BackButton onClick={OnBackClick}>
            <KeyboardBackspaceIcon style={{marginRight: 5}} /> Back
          </BackButton>
        </div>
        <div style={{width: '50%', display: 'flex', justifyContent: 'right'}}>{actionButtons}</div>
      </BottomPanelContent>
    </BottomPanel>
  );
};

export default PageNavigationComponent;
