/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initializes a custom hook for a store provider
 */

import React, {createContext, useContext, useMemo, useReducer, useRef, useEffect} from 'react';
import {isEqual, get, set, has, isNil} from 'lodash';
import {IContext, IStore, IStoreProvider} from './types';
import {_getStoreDataPersistKey} from '../utils/StoreUtils';

import {INIT_STORE_CONTEXT, InitialInitState, InitSessionStoreKeys} from './reducers/InitReducer';
import {APP_STORE_CONTEXT, InitialAppState, AppSessionStoreKeys} from './reducers/AppReducer';
import {CUSTOMER_STORE_CONTEXT, InitialCustomerState, CustomerSessionStoreKeys} from './reducers/CustomerReducer';
import {
  InitialEquipmentInfoState,
  EQUIPMENT_INFO_STORE_CONTEXT,
  EquipmentInfoSessionStoreKeys
} from './reducers/EquipmentInfoReducer';
import {
  AircraftInfoSessionStoreKeys,
  AIRCRAFT_INFO_STORE_CONTEXT,
  InitialAircraftInfoState
} from './reducers/AircraftInfoReducer';
import {
  ServicePlanSessionStoreKeys,
  InitialServicePlanState,
  SERVICE_PLAN_STORE_CONTEXT
} from './reducers/ServicePlanReducer';
import {
  InitialOpenTasksInfoState,
  OpenTasksInfoSessionStoreKeys,
  OPEN_TASKS_INFO_STORE_CONTEXT
} from './reducers/OpenTasksReducer';
import {
  InitialApprovalInfoState,
  ApprovalInfoSessionStoreKeys,
  APPROVAL_INFO_STORE_CONTEXT
} from './reducers/ApprovalReducer';
import {InitialLookupsState, LookupsSessionStoreKeys, LOOKUPS_STORE_CONTEXT} from './reducers/LookupsReducer';
import {
  CustomerInfoSessionStoreKeys,
  InitialCustomerInfoState,
  CUSTOMER_INFO_STORE_CONTEXT
} from './reducers/CustomerInfoReducer';
import {
  AircraftModelListSessionStoreKeys,
  InitialAircraftModelListState,
  AIRCRAFT_MODEL_LIST_STORE_CONTEXT
} from './reducers/AircraftModelListReducer';
import {
  ServicePlanTermsSessionStoreKeys,
  InitialServicePlanTermsListState,
  SERVICE_PLAN_TERMS_LIST_STORE_CONTEXT
} from './reducers/ServicePlanTermsListReducer';
import {
  ServicePlanListsSessionStoreKeys,
  InitialServicePlansListState,
  SERVICE_PLANS_LIST_STORE_CONTEXT
} from './reducers/ServicePlansListReducer';
import {
  KaBandSwVersionsSessionStoreKeys,
  InitialKaBandSwVersionsListState,
  KA_BAND_SW_VERSIONS_LIST_STORE_CONTEXT
} from './reducers/KaBandSwVersionsReducer';
import {
  KuBandSwVersionsSessionStoreKeys,
  InitialKuBandSwVersionsListState,
  KU_BAND_SW_VERSIONS_LIST_STORE_CONTEXT
} from './reducers/KuBandSwVersionsReducer';
import {
  MapBundleVersionsSessionStoreKeys,
  InitialMapBundleVersionsListState,
  MAP_BUNDLE_VERSIONS_LIST_STORE_CONTEXT
} from './reducers/MapBundleVersionsReducer';
import {
  RouterTypesSessionStoreKeys,
  InitialRouterTypesListState,
  ROUTER_TYPES_LIST_STORE_CONTEXT
} from './reducers/RouterTypesReducer';
import {StatusSessionStoreKeys, InitialStatusState, STATUS_STORE_CONTEXT} from './reducers/StatusReducer';
import {
  InitialWarrantyTermsListState,
  WARRANTY_TERMS_LIST_STORE_CONTEXT,
  WarrantyTermsSessionStoreKeys
} from './reducers/WarrantyTermsListReducer';
interface IStoreItems {
  name: string;
  sessionKeys: string[];
  initialState: any;
  localDataMutate: (data: any) => string;
}

/**
 * Store Items Usage
 * localDataMutate: affects copy to Local Storage only
 */
const storeItems: IStoreItems[] = [
  {
    name: INIT_STORE_CONTEXT,
    sessionKeys: InitSessionStoreKeys,
    initialState: InitialInitState,
    localDataMutate: null
  },
  {
    name: APP_STORE_CONTEXT,
    sessionKeys: AppSessionStoreKeys,
    initialState: InitialAppState,
    localDataMutate: null
  },
  {
    name: CUSTOMER_STORE_CONTEXT,
    sessionKeys: CustomerSessionStoreKeys,
    initialState: InitialCustomerState,
    localDataMutate: null
  },
  {
    name: EQUIPMENT_INFO_STORE_CONTEXT,
    sessionKeys: EquipmentInfoSessionStoreKeys,
    initialState: InitialEquipmentInfoState,
    localDataMutate: null
  },
  {
    name: AIRCRAFT_INFO_STORE_CONTEXT,
    sessionKeys: AircraftInfoSessionStoreKeys,
    initialState: InitialAircraftInfoState,
    localDataMutate: null
  },
  {
    name: SERVICE_PLAN_STORE_CONTEXT,
    sessionKeys: ServicePlanSessionStoreKeys,
    initialState: InitialServicePlanState,
    localDataMutate: null
  },
  {
    name: OPEN_TASKS_INFO_STORE_CONTEXT,
    sessionKeys: OpenTasksInfoSessionStoreKeys,
    initialState: InitialOpenTasksInfoState,
    localDataMutate: null
  },
  {
    name: APPROVAL_INFO_STORE_CONTEXT,
    sessionKeys: ApprovalInfoSessionStoreKeys,
    initialState: InitialApprovalInfoState,
    localDataMutate: null
  },
  {
    name: LOOKUPS_STORE_CONTEXT,
    sessionKeys: LookupsSessionStoreKeys,
    initialState: InitialLookupsState,
    localDataMutate: null
  },
  {
    name: CUSTOMER_INFO_STORE_CONTEXT,
    sessionKeys: CustomerInfoSessionStoreKeys,
    initialState: InitialCustomerInfoState,
    localDataMutate: null
  },
  {
    name: AIRCRAFT_MODEL_LIST_STORE_CONTEXT,
    sessionKeys: AircraftModelListSessionStoreKeys,
    initialState: InitialAircraftModelListState,
    localDataMutate: null
  },
  {
    name: KA_BAND_SW_VERSIONS_LIST_STORE_CONTEXT,
    sessionKeys: KaBandSwVersionsSessionStoreKeys,
    initialState: InitialKaBandSwVersionsListState,
    localDataMutate: null
  },
  {
    name: KU_BAND_SW_VERSIONS_LIST_STORE_CONTEXT,
    sessionKeys: KuBandSwVersionsSessionStoreKeys,
    initialState: InitialKuBandSwVersionsListState,
    localDataMutate: null
  },
  {
    name: MAP_BUNDLE_VERSIONS_LIST_STORE_CONTEXT,
    sessionKeys: MapBundleVersionsSessionStoreKeys,
    initialState: InitialMapBundleVersionsListState,
    localDataMutate: null
  },
  {
    name: ROUTER_TYPES_LIST_STORE_CONTEXT,
    sessionKeys: RouterTypesSessionStoreKeys,
    initialState: InitialRouterTypesListState,
    localDataMutate: null
  },
  {
    name: SERVICE_PLANS_LIST_STORE_CONTEXT,
    sessionKeys: ServicePlanListsSessionStoreKeys,
    initialState: InitialServicePlansListState,
    localDataMutate: null
  },
  {
    name: SERVICE_PLAN_TERMS_LIST_STORE_CONTEXT,
    sessionKeys: ServicePlanTermsSessionStoreKeys,
    initialState: InitialServicePlanTermsListState,
    localDataMutate: null
  },
  {
    name: WARRANTY_TERMS_LIST_STORE_CONTEXT,
    sessionKeys: WarrantyTermsSessionStoreKeys,
    initialState: InitialWarrantyTermsListState,
    localDataMutate: null
  },
  {
    name: STATUS_STORE_CONTEXT,
    sessionKeys: StatusSessionStoreKeys,
    initialState: InitialStatusState,
    localDataMutate: null
  }
];

/**
 * Loads up the session store keys
 * @param prevState Previous State
 * @param currentState Current State
 */
const setSessionStore = (prevState: any, currentState: any) =>
  storeItems.forEach(storeItem => setSessionStoreKeys(storeItem.name, storeItem.sessionKeys, prevState, currentState));

/**
 * Gets a JSON value from session storage, else local storage or null
 * @param storeDataPersistKey Store Persist Key
 * @returns JSON value from session storage/local storage/null
 */
const getStorageData = (storeDataPersistKey: string) => {
  const sessionData = sessionStorage.getItem(storeDataPersistKey);
  if (!isNil(sessionData)) return JSON.parse(sessionData);

  const localData = localStorage.getItem(storeDataPersistKey);

  // Let's only mess with things if session storage is empty.
  if (sessionStorage.length === 0) {
    copyLocalToSessionStorage();
  }

  return isNil(localData) ? null : JSON.parse(localData);
};

/**
 * Copy the store from local storage to session storage. We only want
 * this to happen when you open a new window/tab.
 */
const copyLocalToSessionStorage = () => {
  storeItems.forEach(storeItem => {
    const persistKey = _getStoreDataPersistKey(storeItem.name);
    sessionStorage.setItem(persistKey, localStorage.getItem(persistKey));
  });
};

/**
 * Sets the value in session storage and updates all of local storage
 * Excluding those on the local storage exception list
 * @param storeDataPersistKey Store Data Persist Key
 * @param value New value
 */
const setStorageData = (storeDataPersistKey: string, value: any) => {
  sessionStorage.setItem(storeDataPersistKey, JSON.stringify(value));

  // By only writing one thing into local storage, we were getting
  // different things from different windows when opening a new
  // window. So, I make it so we get everything from the current window
  // in the new window.
  storeItems.forEach(storeItem => {
    const persistKey = _getStoreDataPersistKey(storeItem.name);
    const sessionData = sessionStorage.getItem(persistKey);
    const updateData = storeItem.localDataMutate ? storeItem.localDataMutate(sessionData) : sessionData;

    localStorage.setItem(persistKey, updateData);
  });
};

function createInitialReducerState<T extends object>(storeKey: string, saveKeys: string[], initialData: T): T {
  try {
    const initialClone = JSON.parse(JSON.stringify(initialData));
    const storeDataPersistKey = _getStoreDataPersistKey(storeKey);
    const sessionContextData = getStorageData(storeDataPersistKey);

    if (sessionContextData === null) {
      return initialClone;
    }
    return saveKeys.reduce(
      (memo: T, key: string) => (has(sessionContextData, key) ? set(memo, key, get(sessionContextData, key)) : memo),
      initialClone
    );
  } catch (error) {
    console.error(`Failed to create initial reducer state (${storeKey}): ${error.toString()}`);
  }
  return initialData;
}

const setSessionStoreKeys = (storeKey: string, sessionKeys: string[], prevState: any, currentState: any) => {
  let update = false;
  const newSessionState = sessionKeys.reduce((memo: any, sessionKey: string) => {
    const prevStateData = get(prevState[storeKey], sessionKey);
    const currStateData = get(currentState[storeKey], sessionKey);
    if (!isEqual(prevStateData, currStateData)) {
      update = true;
      return set(memo, sessionKey, currStateData);
    }
    return memo;
  }, {});

  if (update) {
    try {
      const storeDataPersistKey = _getStoreDataPersistKey(storeKey);
      const sessionContextData = getStorageData(storeDataPersistKey);
      const updateData = sessionKeys.reduce((memo: any, sessionKey: string) => {
        if (has(newSessionState, sessionKey)) {
          return set(memo, sessionKey, get(newSessionState, sessionKey));
        }
        return set(memo, sessionKey, get(sessionContextData, sessionKey));
      }, {});

      setStorageData(storeDataPersistKey, updateData);
    } catch (error) {
      console.error(`Failed to set session store keys (${storeKey}): ${error.toString()}`);
    }
  }
};

export const useSessionStoreReducer = (reducer, initialState) => {
  let prevState = useRef(initialState);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setSessionStore(prevState.current, state);
    prevState.current = state;
  }, [state]);

  return [state, dispatch];
};

const setInitialStateFromLocalStore = (): IStore => {
  return storeItems.reduce((sum, storeItem) => {
    return {
      ...sum,
      [storeItem.name]: createInitialReducerState(storeItem.name, storeItem.sessionKeys, storeItem.initialState)
    };
  }, {}) as IStore;
};

const StoreContext = createContext<IContext>({
  store: setInitialStateFromLocalStore(),
  dispatch: () => {}
});

export const StoreProvider = ({reducer, children}: IStoreProvider) => {
  const initialState: IStore = setInitialStateFromLocalStore();

  const reInit = (state: IStore, action: any) =>
    action.type === 'init' ? setInitialStateFromLocalStore() : reducer(state, action);
  const [store, dispatch] = useSessionStoreReducer(reInit, initialState);

  const value = useMemo(
    () => ({
      store,
      dispatch
    }),
    // eslint-disable-next-line
    [store]
  ) as IContext;

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export const useStore = (): IContext => useContext(StoreContext);
