/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Reducer to store and maintain different statuses
 */

import {delegateToReducers} from '../Reducer';

export const STATUS_STORE_CONTEXT = 'statusInfo';

export interface StatusState {
  equipmentInformation: string;
  customerInformation: string;
  servicePlanInformation: string;
  servicePlanSuspendInformation: string;
  servicePlanBarringInformation: string;
  servicePlanDecommissionInformation: string;
  servicePlanResumeInformation: string;
}

export const InitialStatusState: StatusState = {
  equipmentInformation: null,
  customerInformation: null,
  servicePlanInformation: null,
  servicePlanSuspendInformation: null,
  servicePlanBarringInformation: null,
  servicePlanDecommissionInformation: null,
  servicePlanResumeInformation: null
};

export const StatusSessionStoreKeys: string[] = [
  'equipmentInformation',
  'customerInformation',
  'servicePlanInformation',
  'servicePlanSuspendInformation',
  'servicePlanBarringInformation',
  'servicePlanDecommissionInformation',
  'servicePlanResumeInformation'
];

export enum StatusAction {
  SET_STATUS = 'SET_STATUS',
  RESET_STATUS = 'RESET_STATUS'
}

/**
 * Aircraft Information action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const StatusReducer = (state: StatusState, action: any): StatusState => {
  switch (action.type) {
    case StatusAction.SET_STATUS:
      const statusInfo = action.payload;
      state = {
        ...state,
        ...statusInfo
      };
      break;
    case StatusAction.RESET_STATUS:
      state = {
        ...state,
        equipmentInformation: null,
        customerInformation: null,
        servicePlanInformation: null,
        servicePlanSuspendInformation: null,
        servicePlanBarringInformation: null,
        servicePlanDecommissionInformation: null,
        servicePlanResumeInformation: null
      };
      break;
    default:
      break;
  }

  state = delegateToReducers(STATUS_STORE_CONTEXT, state, action, {}, InitialStatusState);

  return state;
};
