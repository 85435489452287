/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Hook to pull URL parameters into the store
 */

import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import * as qs from 'qs';
import {useStore} from '../store/Store';
import {CommonAction, UrlParamsPayload} from '../store/types';

export default function useUrlParams() {
  const {dispatch} = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search.length > 0) {
      // Parse the URL parameters and push them down to the store
      const params = qs.parse(location.search.replace('?', '')) as {[key: string]: string};
      const payload: UrlParamsPayload = {path: location.pathname, params};
      dispatch({type: CommonAction.PROCESS_URL_PARAMS, payload});

      // Clear out the params now that we've processed them
      navigate(location.pathname, {replace: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}
