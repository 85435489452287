/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Decommission Service Plan Details
 */
import React from 'react';
import {DateTime} from 'luxon';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewSection,
  ReviewSubSection
} from '../ReviewInformationStyles';
import {renderReviewInformation} from '../util';
import {IServicePlanDetail} from '../reviewInformationTypes';

const DecommissionServicePlanDetail: React.FC<IServicePlanDetail> = ({idPrefix, servicePlanDetail}) => {
  const decommissionDate = servicePlanDetail?.suspensionTstamp
    ? DateTime.fromISO(servicePlanDetail?.suspensionTstamp).toLocaleString()
    : null;
  const requestReason = servicePlanDetail?.requestReason;

  return (
    <ReviewSection>
      <ReviewItemTitle>{servicePlanDetail?.requestType?.toUpperCase()}</ReviewItemTitle>
      <ReviewItemSubTitle>Please review the information below for approval.</ReviewItemSubTitle>
      <ReviewSubSection>
        <ReviewItems>
          <ReviewItem>
            <ReviewItemLabel>Effective Date</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__decommissionDetails--requestedDate`}>
              {renderReviewInformation(decommissionDate)}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Reason for Decommission</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__decommissionDetails--requestReason`}>
              {renderReviewInformation(requestReason)}
            </ReviewItemValue>
          </ReviewItem>
        </ReviewItems>
      </ReviewSubSection>
    </ReviewSection>
  );
};

export default DecommissionServicePlanDetail;
