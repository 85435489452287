/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add New Warranty Term in Lookup Table
 *
 */
import React, {useMemo, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {AddForm, AddFormHeader, DrawerButton, ButtonContainer, FormContainer} from '../LookupTableStyles';
import {addWarrantyTermsQuery, updateWarrantyTermsQuery} from '../../../../store/queries/lookupQueries';
import {SideDrawerProps, togglePanel, handleBackdropClick, MenuProps, InputProps} from '../lookupTableUtils';
import {useStore} from '../../../../store/Store';
import {AppAction} from '../../../../store/reducers/AppReducer';
import {LookupsListAction} from '../../../../store/reducers/LookupsReducer';
import FormComponent, {FormField, FormFieldType} from '../../../common/elements/Form';
import {YES_NO} from '../../../common/constants';
import {validateDropdownField} from '../../../../utils/validationUtils';
import useFetchV2 from '../../../../utils/useFetchV2';

const ID_PREFIX = 'warrantyTerm';

interface IFormInput {
  make: string;
  installationType: string;
  networkType: string;
  warrantyStartInstallation: string;
  warrantyStartCustomerService: string;
  warrantyTerms: string;
  graceDuration: string;
  notes: string;
}

const AddWarrantyTerm: React.FC<SideDrawerProps> = ({openPanel, setOpenPanel, panelData, isUpdate}) => {
  const {store, dispatch} = useStore();
  const {oemAccountList} = store.app;
  const [newWarrantyTermsInfo, setNewWarrantyTermsInfo] = useState<any>(null);
  const [newWarrantyTermsInfoWithOemId, setNewWarrantyTermsInfWithOemId] = useState<any>(null);
  const [updateWarrantyTermsInfo, setUpdateWarrantyTermsInfo] = useState<any>(null);
  const [updateWarrantyTermsInfoWithOemId, setUpdateWarrantyTermsInfo2WithOemId] = useState<any>(null);
  let makeDropdownValues = oemAccountList?.map(oemAccount => oemAccount.oemName);
  makeDropdownValues?.push('General MRO');

  let initialValues: IFormInput = {
    make: '',
    installationType: '',
    networkType: '',
    warrantyStartInstallation: '',
    warrantyStartCustomerService: '',
    warrantyTerms: '',
    graceDuration: '',
    notes: ''
  };
  if (isUpdate) initialValues = panelData;

  const warrantyTermsFormFields: FormField[] = [
    {
      id: 'make',
      label: 'Make(Aircraft Manufacturer)',
      type: FormFieldType.SELECT,
      value: initialValues.make,
      disabled: false,
      dropDownValues: makeDropdownValues,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Make')
      }
    },
    {
      id: 'installationType',
      label: 'Installation Type',
      type: FormFieldType.SELECT,
      value: initialValues.installationType,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: [
        'Linefit Install',
        'Aftermarket Install',
        'In-warranty Repair  / Spares',
        'Out-of-warranty Repair'
      ],
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Installation Type')
      }
    },
    {
      id: 'networkType',
      label: 'Network Type',
      type: FormFieldType.SELECT,
      value: initialValues.networkType,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: ['Ka', 'Ku', 'Dual band'],
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Network Type')
      }
    },
    {
      id: 'warrantyStartInstallation',
      label: 'Warranty Start: Installation',
      type: FormFieldType.SELECT,
      value: initialValues.warrantyStartInstallation,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Warranty Start: Installation')
      }
    },
    {
      id: 'warrantyStartCustomerService',
      label: 'Warranty Start: Customer Service',
      type: FormFieldType.SELECT,
      value: initialValues.warrantyStartCustomerService,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Warranty Start: Customer Service')
      }
    },
    {
      id: 'warrantyTerms',
      label: 'Warranty Terms',
      type: FormFieldType.INPUT,
      value: initialValues.warrantyTerms,
      disabled: false,
      menuProps: MenuProps,
      description: null,
      rules: {
        required: true,
        pattern: {value: new RegExp('^[0-9]*$'), message: 'Invalid term'}
      }
    },
    {
      id: 'graceDuration',
      label: 'Grace Duration',
      type: FormFieldType.INPUT,
      value: initialValues.graceDuration,
      disabled: false,
      menuProps: MenuProps,
      description: null,
      rules: {
        required: true,
        pattern: {value: new RegExp('^[0-9]*$'), message: 'Invalid Duration'}
      }
    },
    {
      id: 'notes',
      label: 'Notes',
      type: FormFieldType.TEXTFIELD,
      value: initialValues.notes,
      disabled: false,
      inputProps: InputProps,
      isMultiline: true,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  const {handleSubmit, formState, unregister, reset, watch, control} = useForm();
  const addQueryParams = useMemo(() => {
    if (
      newWarrantyTermsInfoWithOemId &&
      (!newWarrantyTermsInfoWithOemId.make ||
        !newWarrantyTermsInfoWithOemId.installationType ||
        !newWarrantyTermsInfoWithOemId.networkType ||
        !newWarrantyTermsInfoWithOemId.warrantyStartInstallation ||
        !newWarrantyTermsInfoWithOemId.warrantyStartCustomerService ||
        !newWarrantyTermsInfoWithOemId.warrantyTerms ||
        !newWarrantyTermsInfoWithOemId.graceDuration)
    )
      return;
    return newWarrantyTermsInfoWithOemId;
  }, [newWarrantyTermsInfoWithOemId]);

  const updateQueryParams = useMemo(() => {
    if (
      isUpdate &&
      updateWarrantyTermsInfoWithOemId &&
      (!updateWarrantyTermsInfoWithOemId.make ||
        !updateWarrantyTermsInfoWithOemId.installationType ||
        !updateWarrantyTermsInfoWithOemId.networkType ||
        !updateWarrantyTermsInfoWithOemId.warrantyStartInstallation ||
        !updateWarrantyTermsInfoWithOemId.warrantyStartCustomerService ||
        !updateWarrantyTermsInfoWithOemId.warrantyTerms ||
        !updateWarrantyTermsInfoWithOemId.graceDuration)
    )
      return;
    return updateWarrantyTermsInfoWithOemId;
  }, [updateWarrantyTermsInfoWithOemId, isUpdate]);
  useEffect(() => {
    if (newWarrantyTermsInfo) {
      const oemID = oemAccountList?.find(oemAccount => oemAccount.oemName === newWarrantyTermsInfo.make)?.oemAccountId;
      setNewWarrantyTermsInfWithOemId({
        make: oemID ? oemID : newWarrantyTermsInfo.make,
        installationType: newWarrantyTermsInfo.installationType,
        networkType: newWarrantyTermsInfo.networkType,
        warrantyStartInstallation: newWarrantyTermsInfo.warrantyStartInstallation,
        warrantyStartCustomerService: newWarrantyTermsInfo.warrantyStartCustomerService,
        warrantyTerms: newWarrantyTermsInfo.warrantyTerms,
        graceDuration: newWarrantyTermsInfo.graceDuration,
        notes: newWarrantyTermsInfo?.notes
      });
    }
  }, [newWarrantyTermsInfo, oemAccountList]);
  const {isLoading: addInProgress, data: addNewWarrantyTermsInfo} = useFetchV2(
    {route: addWarrantyTermsQuery.route, params: addQueryParams},
    addWarrantyTermsQuery.transform
  );

  useEffect(() => {
    if (updateWarrantyTermsInfo) {
      const oemID = oemAccountList?.find(
        oemAccount => oemAccount.oemName === updateWarrantyTermsInfo.make
      )?.oemAccountId;
      setUpdateWarrantyTermsInfo2WithOemId({
        make: oemID ? oemID : updateWarrantyTermsInfo.make,
        installationType: updateWarrantyTermsInfo.installationType,
        networkType: updateWarrantyTermsInfo.networkType,
        warrantyStartInstallation: updateWarrantyTermsInfo.warrantyStartInstallation,
        warrantyStartCustomerService: updateWarrantyTermsInfo.warrantyStartCustomerService,
        warrantyTerms: updateWarrantyTermsInfo.warrantyTerms,
        graceDuration: updateWarrantyTermsInfo.graceDuration,
        notes: updateWarrantyTermsInfo?.notes
      });
    }
  }, [updateWarrantyTermsInfo, oemAccountList]);
  const {isLoading: updateInProgress, data: updatedWarrantyTermsInfo} = useFetchV2(
    {route: updateWarrantyTermsQuery(panelData?.id).route, params: updateQueryParams},
    updateWarrantyTermsQuery(panelData?.id).transform
  );
  const onCancelClick = () => {
    unregister();
    reset(
      {
        make: '',
        installationType: '',
        networkType: '',
        warrantyStartInstallation: '',
        warrantyStartCustomerService: '',
        warrantyTerms: '',
        graceDuration: '',
        notes: ''
      },
      {
        keepValues: false
      }
    );
    setOpenPanel(false);
  };

  const handleOpen = () => {
    togglePanel(true);
    setOpenPanel(true);
  };

  const handleClose = () => {
    if (localStorage.getItem('backdropClick') === 'true') {
      localStorage.setItem('backdropClick', 'false');
      return;
    }
    togglePanel(false);
    setOpenPanel(false);
  };

  useEffect(() => {
    if (addInProgress) return;
    if (addNewWarrantyTermsInfo) {
      setOpenPanel(false);
      dispatch({
        type: LookupsListAction.SET_NEW_ROW_COUNT,
        payload: 1
      });
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'Warranty Term entry added.'}
      });
    }
    //eslint-disable-next-line
  }, [addInProgress, addNewWarrantyTermsInfo]);

  useEffect(() => {
    if (updateInProgress) return;
    if (updatedWarrantyTermsInfo) {
      setOpenPanel(false);
      dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: panelData});
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'Warranty Term data updated.'}
      });
    }
    //eslint-disable-next-line
  }, [updateInProgress, updatedWarrantyTermsInfo]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={openPanel}
        onOpen={handleOpen}
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
      >
        <AddForm>
          <AddFormHeader id={`${ID_PREFIX}--warrantyTermsTitle`}>ADD WARRANTY TERM INFO</AddFormHeader>
          <FormContainer>
            <FormComponent
              id="add-warranty-terms-form"
              formFields={warrantyTermsFormFields}
              setFormData={!isUpdate ? setNewWarrantyTermsInfo : setUpdateWarrantyTermsInfo}
              formControlMethods={{handleSubmit, formState, watch, control}}
              idPrefix={ID_PREFIX}
            ></FormComponent>
          </FormContainer>
          <ButtonContainer>
            <DrawerButton id={`${ID_PREFIX}-cancel-button`} className="cancel-button" onClick={onCancelClick}>
              <span className="button-text">Cancel</span>
            </DrawerButton>
            <DrawerButton
              form="add-warranty-terms-form"
              id={`${ID_PREFIX}-add-button`}
              className="add-button"
              onClick={() => null}
            >
              <span className="button-text">{!isUpdate ? 'Add' : 'Update'}</span>
            </DrawerButton>
          </ButtonContainer>
        </AddForm>
      </SwipeableDrawer>
    </>
  );
};

export default AddWarrantyTerm;
