/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Replace Antenna Information
 */

import React, {useEffect, useMemo} from 'react';
import {useForm, useWatch} from 'react-hook-form';
import {isNil} from 'lodash';
import {EquipmentInfoAction, IAntennaInformation} from '../../store/reducers/EquipmentInfoReducer';
import {useStore} from '../../store/Store';
import {EquipmentType, KU_NETWORK, SELECT_AN_OPTION, serialNumberValidation} from '../common/constants';
import {FormField, FormFieldType} from '../common/elements/Form';
import {FormConfig, FormTypes} from './EquipmentUpdateForm';
import {usePartNumbersFetch} from '../../utils/customHooks';
import {getPartNumbersQuery} from '../../store/queries/lookupQueries';

import {
  updateKuAntennaInformation,
  updateKaAntennaInformation
} from '../../store/queries/newInstall/equipmentInfoQuery';

import ReplaceEquipmentBase from './ReplaceEquipmentBase';
import {validateConfigItemsExists, validateDropdownField} from '../../utils/validationUtils';
import {getExistingEquipmentInfoQuery} from '../../store/queries/getExistingEquipmentInfoQuery';
import useFetchV2 from '../../utils/useFetchV2';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
      color: '#465967'
    }
  }
};

export interface AntennaReplaceProps {
  title: string;
}

const AntennaReplace: React.FC<AntennaReplaceProps> = ({title}) => {
  const {handleSubmit, formState, control, watch, reset, setValue} = useForm();
  const {store, dispatch} = useStore();
  const {aircraftNetwork, siteId} = store.aircraftInfo;
  const antennaConfigItems = store.app.configItems?.['Antenna'] ? store.app.configItems['Antenna'] : [];
  const isKuAircraft = aircraftNetwork === KU_NETWORK;
  const queryParams = useMemo(() => {
    if (isNil(siteId) || isNil(aircraftNetwork)) return;
    return {
      siteId: siteId,
      configType: 'Antenna',
      configSubType: aircraftNetwork
    };
  }, [siteId, aircraftNetwork]);

  const {isLoading: existingEquipmentInfoLoading, data: existingEquipmentData} = useFetchV2(
    {route: getExistingEquipmentInfoQuery.route, params: queryParams},
    getExistingEquipmentInfoQuery.transform
  );
  useEffect(() => {
    if (existingEquipmentInfoLoading && !existingEquipmentData) return;
    if (existingEquipmentData && existingEquipmentData.length) {
      dispatch({
        type: isKuAircraft
          ? EquipmentInfoAction.SET_EXISTING_KU_ANTENNA_INFO
          : EquipmentInfoAction.SET_EXISTING_KA_ANTENNA_INFO,
        payload: existingEquipmentData[0]
      });
      setValue('partNumber', existingEquipmentData[0]?.partNumber);
      setValue('serialNumber', existingEquipmentData[0]?.serialNumber);
    }
    // eslint-disable-next-line
  }, [existingEquipmentInfoLoading]);

  const formConfigList: FormConfig[] = [
    {
      id: FormTypes.EXISTING,
      title: 'Verify Existing Antenna Information',
      description:
        'To start please verify the information for the faulty antenna that is being removed from the terminal. If this information does not appear below, please Add it manually.',
      footerText: '',
      footerHyperLinkText: '',
      footerHyperLinkUrl: '',
      actionType: isKuAircraft
        ? EquipmentInfoAction.SET_EXISTING_KU_ANTENNA_INFO
        : EquipmentInfoAction.SET_EXISTING_KA_ANTENNA_INFO
    },
    {
      id: FormTypes.NEW,
      title: 'Add Replacement Antenna Information',
      description: 'Please Add replacement Antenna information manually or via QR Code.',
      footerText: '',
      footerHyperLinkText: '',
      footerHyperLinkUrl: '',
      actionType: isKuAircraft ? EquipmentInfoAction.SET_KU_ANTENNA_INFO : EquipmentInfoAction.SET_KA_ANTENNA_INFO
    }
  ];

  const partNumbersList = usePartNumbersFetch(EquipmentType.ANTENNA, aircraftNetwork, getPartNumbersQuery);
  const selectedPartNumber: string = useWatch({
    control,
    name: 'partNumber'
  });
  const serialNumberInput = watch('serialNumber');
  const serialNumberRegex = serialNumberValidation[selectedPartNumber];

  const partNumberParams = partNumbersList?.filter(partNumber => partNumber.viasatPartNumber === selectedPartNumber)[0];

  const productDescription = partNumberParams?.viasatPartNumberDescription;
  const serialNumberFormat = partNumberParams?.serialNumberFormat;
  const serialNumberFormatDescription = partNumberParams?.serialNumberFormatDescription;

  const getFormFields = (initialValues: IAntennaInformation, isExistingForm: boolean): FormField[] => {
    const existingKaAntenna: IAntennaInformation = store.equipmentInfo.existingKaAntenna;
    const existingKuAntenna: IAntennaInformation = store.equipmentInfo.existingKuAntenna;

    const equipmentFormFields: FormField[] = [
      {
        id: 'partNumber',
        label: 'Part Number',
        type: FormFieldType.SELECT,
        value: initialValues.partNumber,
        disabled: false,
        menuProps: MenuProps,
        dropDownValues: partNumbersList?.map(partNumber => partNumber.viasatPartNumber),
        description: {title: 'Product Description', value: productDescription ? productDescription : '--'},
        rules: {
          required: true,
          pattern: null,
          validate: (value, formValues) => validateDropdownField(value, 'Part Number')
        }
      },
      {
        id: 'serialNumber',
        label: 'Serial Number',
        type: FormFieldType.INPUT,
        value: initialValues.serialNumber,
        disabled: false,
        placeholder: serialNumberFormat ? `  eg. ${serialNumberFormat}` : '',
        description: serialNumberFormat ? {title: 'Serial Number Format', value: serialNumberFormat} : null,
        descriptionHelper: serialNumberFormatDescription ? serialNumberFormatDescription : '',
        rules: {
          required: true,
          pattern: {value: new RegExp(serialNumberRegex), message: 'Invalid Serial Number.'},
          warning: validateConfigItemsExists(
            antennaConfigItems,
            'serialNumber',
            serialNumberInput,
            aircraftNetwork,
            siteId
          ),
          validate: (value, formValues) => {
            if (!isExistingForm)
              return (
                value !== (isKuAircraft ? existingKuAntenna?.serialNumber : existingKaAntenna?.serialNumber) ||
                'Invalid Serial Number. Serial Number cannot be same as installed hardware.'
              );
            else return true;
          }
        }
      }
    ];
    return equipmentFormFields;
  };

  return (
    <ReplaceEquipmentBase<IAntennaInformation>
      title={title}
      aircraftSerialNumber={''}
      formIdPrefix={isKuAircraft ? 'replaceKuAntennaForm' : 'replaceKaAntennaForm'}
      equipmentName={isKuAircraft ? 'kuAntenna' : 'kaAntenna'}
      existingEquipmentStoreKey={isKuAircraft ? 'existingKuAntenna' : 'existingKaAntenna'}
      newEquipmentStoreKey={isKuAircraft ? 'kuAntenna' : 'kaAntenna'}
      formConfigList={formConfigList}
      defaultFormValues={{
        partNumber: existingEquipmentData?.length ? existingEquipmentData[0]?.partNumber : SELECT_AN_OPTION,
        serialNumber: ''
      }}
      getFormFields={(initialValues, isExistingForm) => getFormFields(initialValues, isExistingForm)}
      formControlMethods={{handleSubmit, formState, control, watch, reset}}
      fetchQuery={isKuAircraft ? updateKuAntennaInformation : updateKaAntennaInformation}
      isLoading={siteId ? existingEquipmentInfoLoading : false}
    />
  );
};

export default AntennaReplace;
