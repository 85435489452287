/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Change Service Plan
 */
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {filter, find, isNil} from 'lodash';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {
  BottomHorizontalRule,
  FormContainerLeftPanel,
  HeaderHorizontalRule,
  HeaderLabel,
  NextButton,
  PageDescription
} from '../CommonStyles';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import {useStore} from '../../store/Store';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import {validateDropdownField} from '../../utils/validationUtils';
import {ServicePlanAction} from '../../store/reducers/ServicePlanReducer';
import {DateTime} from 'luxon';
import {KU_NETWORK} from '../common/constants';
import {useCreateApprovalRequestOnServicePlanSubmit} from '../../utils/customHooks';
import {
  addServicePlanQuery,
  updateServicePlanQuery,
  useServicePlanQueryParams
} from '../../store/queries/servicePlanInfoQueries';
import {makeContractTermOptional} from './util';
import useFetchV2 from '../../utils/useFetchV2';
import {AppAction} from '../../store/reducers/AppReducer';

const ID_PREFIX = 'servicePlan-change';

interface ChangeServicePlanProps {
  title: string;
}

interface IFormInput {
  contractTerm: string;
  servicePlanType: string;
  planStartDate: string;
  existingContractEndDate: string;
}

const ChangeServicePlan: React.FC<ChangeServicePlanProps> = ({title}) => {
  const LeftChild = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {store, dispatch} = useStore();
    const {servicePlans, servicePlanTerms, breadcrumb} = store.app;
    const {network, serialNumber, tailId, make, model, isNewInstall} = store.aircraftInfo;
    const {isInternal} = store.init;
    const newTaskId = store.aircraftInfo.taskId ? store.aircraftInfo.taskId : store.openTasksInfo.newTask?.taskId;
    const networkCapability = network === 'Dual Band (Ka & Ku)' ? 'Dual Band' : network;
    const networkSpecificServicePlans = filter(servicePlans, servicePlan =>
      servicePlan.network.includes(networkCapability)
    );
    const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';
    const taskDetails = {
      groupCode: groupCode,
      serialNumber: serialNumber,
      tailId: tailId,
      make: make,
      model: model,
      network: network
    };
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: 224,
          width: 250,
          color: '#465967'
        }
      }
    };
    let initialValues: IFormInput = {
      contractTerm: '',
      servicePlanType: 'Select an option',
      planStartDate: DateTime.utc().toISODate(),
      existingContractEndDate: ''
    };

    const {handleSubmit, formState, watch, control} = useForm();
    const [servicePlanInfoFormData, setServicePlanInfoFormData] = useState(null);
    const [planStartDate, setPlanStartDate] = useState(null);

    const planStartDateFormatted =
      planStartDate && planStartDate.c
        ? planStartDate.c.month + '/' + planStartDate.c.day + '/' + planStartDate.c.year
        : initialValues.planStartDate;

    const existingContractEndDateCheck = watch('existingContractEndDate');
    const servicePlanCheck = watch('servicePlanType');
    const isContractTermOptional = makeContractTermOptional(servicePlanCheck);
    const servicePlanInfoFormFields: FormField[] = [
      {
        id: 'servicePlanType',
        label: 'Service Plan Type',
        type: FormFieldType.SELECT,
        value: initialValues.servicePlanType,
        disabled: false,
        menuProps: MenuProps,
        dropDownValues:
          networkSpecificServicePlans && networkSpecificServicePlans.length
            ? networkSpecificServicePlans.map(servicePlan =>
                isInternal ? servicePlan.planName : servicePlan.generalPlanName
              )
            : [],
        description: null,
        rules: {
          required: true,
          pattern: null,
          validate: (value, formValues) => validateDropdownField(value, 'Service Plan Type')
        }
      },
      {
        id: 'planStartDate',
        label: 'Subscription Start Date',
        type: FormFieldType.DATE,
        value: planStartDateFormatted,
        setDate: setPlanStartDate,
        disabled: false,
        menuProps: MenuProps,
        dropDownValues: [],
        placeholder: '__/__/____',
        description: null,
        rules: {
          required: true,
          pattern: null
        }
      },
      {
        id: 'existingContractEndDate',
        label: 'Use the existing contract end date',
        type: FormFieldType.RADIO,
        value: initialValues.existingContractEndDate,
        disabled: isContractTermOptional,
        menuProps: MenuProps,
        dropDownValues: [],
        description: null,
        rules: {
          required: isContractTermOptional ? false : true,
          pattern: null
        },
        radioOptions: [{value: 'Yes'}, {value: 'No'}]
      },
      {
        id: 'servicePlanTerm',
        label: 'Contract Term',
        type: FormFieldType.SELECT,
        value: initialValues.contractTerm,
        disabled: isContractTermOptional || existingContractEndDateCheck === 'yes' ? true : false,
        menuProps: MenuProps,
        dropDownValues:
          servicePlanTerms && servicePlanTerms.length
            ? servicePlanTerms.map(servicePlanTerm => servicePlanTerm.term)
            : [],
        description: null,
        rules: {
          required: isContractTermOptional || existingContractEndDateCheck === 'yes' ? false : true,
          pattern: null,
          validate: (value, formValues) => validateDropdownField(value, 'Service Plan Term')
        }
      }
    ];

    useEffect(() => {
      if (!servicePlanInfoFormData) return;
      let planFilter = {};
      if (!isInternal) {
        planFilter = {generalPlanName: servicePlanInfoFormData.servicePlanType};
      } else {
        planFilter = {planName: servicePlanInfoFormData.servicePlanType};
      }
      const planDetails = find(servicePlans, {...planFilter});

      localStorage.setItem(
        'ChooseServicePlan',
        JSON.stringify({...servicePlanInfoFormData, region: ' ', dataAllowance: planDetails.dataAllowanceGb})
      );
      if (network !== KU_NETWORK) {
        navigate('/add-ip-ka-address');
      }
      // eslint-disable-next-line
    }, [servicePlanInfoFormData, dispatch]);

    // In the case of Ku band, let's go ahead and submit the service plan without any IP scope/address requirement
    const queryParams = useServicePlanQueryParams(
      newTaskId,
      isInternal,
      servicePlanInfoFormData,
      servicePlans,
      taskDetails,
      isNewInstall,
      groupCode,
      network
    );

    const isUpdate = !isNil(servicePlanInfoFormData) ? (isNil(newTaskId) ? false : true) : false;
    const {isLoading: inProgress, data: servicePlanInfo} = useFetchV2(
      {
        route: servicePlanInfoFormData && !isUpdate ? addServicePlanQuery.route : updateServicePlanQuery.route,
        params: queryParams
      },
      servicePlanInfoFormData && !isUpdate ? addServicePlanQuery.transform : updateServicePlanQuery.transform
    );

    const {isSubmitRequestLoading, submitResponse} = useCreateApprovalRequestOnServicePlanSubmit(
      servicePlanInfo?.taskId,
      isUpdate
    );
    useEffect(() => {
      if (inProgress || isNil(servicePlanInfo) || isSubmitRequestLoading || isNil(submitResponse)) return;

      let operation,
        message,
        details = '';
      if (servicePlanInfo.updated && servicePlanInfo.updated === 1) {
        operation = 'updated';
        message = 'Service plan information has been';
        details = 'Service plan information has been sent to Viasat MCC. ';
      } else {
        operation = 'added';
        message = 'New service plan information has been';
        details = 'New service plan information has been sent to Viasat MCC. ';
      }
      dispatch({
        type: AppAction.SET_BREADCRUMB,
        payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
      });
      dispatch({
        type: ServicePlanAction.SET_SERVICE_PLAN,
        payload: servicePlanInfoFormData
      });
      dispatch({
        type: AppAction.SET_ALERT_INFO,
        payload: {
          showAlert: true,
          operation: operation,
          message: message,
          details: details
        }
      });
      navigate('/main-menu');
      //eslint-disable-next-line
    }, [dispatch, inProgress, servicePlanInfo, isSubmitRequestLoading, submitResponse]);

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Change Service Plan</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--pageDescription`}>
          Updated the options below to personalize the service plan. Changing service may take up to 72 hours
        </PageDescription>
        <BottomHorizontalRule />
        <FormContainerLeftPanel>
          <FormComponent
            idPrefix={ID_PREFIX}
            id="change-service-plan-information-form"
            formFields={servicePlanInfoFormFields}
            setFormData={setServicePlanInfoFormData}
            formControlMethods={{handleSubmit, formState, watch, control}}
          ></FormComponent>
        </FormContainerLeftPanel>
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton form="change-service-plan-information-form" id={`${ID_PREFIX}--nextButton`} disabled={false}>
        <span className="next-button-text">Next</span>
        <ArrowForwardIcon className="next-arrow" />
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<LeftChild />} actionButtons={<ActionButtons />} />;
};

export default ChangeServicePlan;
