/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Basic Fetch Hook
 */
import {useEffect, useReducer, createContext, useContext} from 'react';
import {isEqual, isUndefined} from 'lodash';
import {apiBaseUrl} from './config';
import {logout} from './auth';
import {FetchQuery} from '../queries/types';

interface Action {
  type: string;
  payload: any;
}

interface StateInterface<Type> {
  isLoading: boolean;
  isError: boolean;
  data: Type | null;
}

interface CacheDataInterface {
  params: Record<string, any> | undefined;
  data: Record<string, any>;
}

export type QueryParams = Record<string, any> | undefined;

const cacheContext = createContext<{[key: string]: any}>({});

/**
 * Action reducer for the fetch hook
 * @param state Current state
 * @param action Action that was dispatched
 * @returns New state
 */
const createReducer =
  <Type>() =>
  (state: StateInterface<Type>, action: Action): StateInterface<Type> => {
    switch (action.type) {
      case 'success':
        state = {...state, isLoading: false, isError: false, data: action.payload};
        break;
      case 'error':
        state = {...state, isLoading: false, isError: true, data: null};
        break;
      case 'loading':
        if (!state.isLoading) {
          state = {...state, isLoading: true, data: null, isError: false};
        }
        break;
    }

    return state;
  };

/**
 * Fetch hook
 * @param query Insights Path
 * @param params Query parameters
 * @returns Basic Hook Data
 */
const useFetch = <Type>(query: FetchQuery<Type>, params: Record<string, any> | undefined): StateInterface<Type> => {
  const cache = useContext<{[key: string]: CacheDataInterface}>(cacheContext);
  const [state, dispatch] = useReducer(createReducer<Type>(), {
    isLoading: true,
    isError: false,
    data: null
  });

  useEffect(() => {
    let abortController: AbortController | null = null;
    let doCache = true;

    if (!params) return;

    if (!isUndefined(params.doCache)) {
      doCache = params.doCache;
    }
  
    const cacheData = cache[query.route];
    if (cacheData && isEqual(cacheData.params, params) && doCache) {
      dispatch({type: 'success', payload: cacheData.data});
      return;
    }

    const abort = () => {
      if (abortController && abortController.abort) {
        const abortTemp = abortController;
        // Set this to null so that we know it was an abort
        abortController = null;
        abortTemp.abort();
      }
    };

    (async () => {
      abortController = new AbortController();

      try {
        dispatch({
          type: 'loading',
          payload: null
        });

        const response = await fetch(`${apiBaseUrl}/${query.route}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.token}`
          },
          body: params ? JSON.stringify(params) : undefined,
          signal: abortController.signal
        });

        if (response.status === 401) {
          console.error(`Logging out: ${response.status}`);
          logout();
          return;
        } else if (response.status >= 300) {
          console.error(`Received response status: ${response.status}`);
          dispatch({type: 'error', payload: null});
          return;
        }

        const responseData = await response.json();
        const transformedData = query.transform ? query.transform(responseData) : responseData;
        cache[query.route] = {
          params,
          data: transformedData
        };
        dispatch({type: 'success', payload: transformedData});
      } catch (exception: any) {
        if (abortController != null) {
          console.error(
            `Exception ('${exception.toString()}') while transforming raw data for query '${query.route}')`
          );
          dispatch({type: 'error', payload: null});
        }
      } finally {
        abortController = null;
      }
    })();

    return () => {
      abort();
    };
  }, [cache, query, params]);

  return state;
};

export default useFetch;
