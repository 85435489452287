/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add New Software Version in Lookup Table
 *
 */
import React, {useMemo, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {AddForm, AddFormHeader, DrawerButton, ButtonContainer, FormContainer} from '../LookupTableStyles';
import {addServicePlanTermsQuery, updateServicePlanTermsQuery} from '../../../../store/queries/lookupQueries';
import {SideDrawerProps, togglePanel, handleBackdropClick, MenuProps, InputProps} from '../lookupTableUtils';
import {useStore} from '../../../../store/Store';
import {AppAction} from '../../../../store/reducers/AppReducer';
import {LookupsListAction} from '../../../../store/reducers/LookupsReducer';
import FormComponent, {FormField, FormFieldType} from '../../../common/elements/Form';
import {YES_NO} from '../../../common/constants';
import {validateDropdownField} from '../../../../utils/validationUtils';
import useFetchV2 from '../../../../utils/useFetchV2';

const ID_PREFIX = 'servicePlanTerms';

interface IFormInput {
  term: string;
  availableForViasat: string;
  availableForSatcomDirect: string;
  availableForHoneywell: string;
  availableForCollins: string;
  availableForOemMro: string;
  notes: string;
}

const AddServicePlanTerms: React.FC<SideDrawerProps> = ({openPanel, setOpenPanel, panelData, isUpdate}) => {
  const {dispatch} = useStore();
  const [newServicePlanTermsInfo, setNewServicePlanTermsInfo] = useState<any>(null);
  const [updateServicePlanTermsInfo, setUpdateServicePlanTermsInfo] = useState<any>(null);
  let initialValues: IFormInput = {
    term: '',
    availableForViasat: '',
    availableForSatcomDirect: '',
    availableForHoneywell: '',
    availableForCollins: '',
    availableForOemMro: '',
    notes: ''
  };
  if (isUpdate) initialValues = panelData;

  const servicePlanTermsFormFields: FormField[] = [
    {
      id: 'term',
      label: 'Term',
      type: FormFieldType.INPUT,
      value: initialValues.term,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'availableForViasat',
      label: 'Available for Viasat',
      type: FormFieldType.SELECT,
      value: initialValues.availableForViasat,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for Viasat')
      }
    },
    {
      id: 'availableForSatcomDirect',
      label: 'Available for Satcom Direct',
      type: FormFieldType.SELECT,
      value: initialValues.availableForSatcomDirect,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for Satcom Direct')
      }
    },
    {
      id: 'availableForHoneywell',
      label: 'Available for Honeywell',
      type: FormFieldType.SELECT,
      value: initialValues.availableForHoneywell,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for Honeywell')
      }
    },
    {
      id: 'availableForCollins',
      label: 'Available for Collins',
      type: FormFieldType.SELECT,
      value: initialValues.availableForCollins,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for Collins')
      }
    },
    {
      id: 'availableForOemMro',
      label: 'Available for OEM/MRO',
      type: FormFieldType.SELECT,
      value: initialValues.availableForOemMro,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Available for OEM/MRO')
      }
    },
    {
      id: 'notes',
      label: 'Notes',
      type: FormFieldType.TEXTFIELD,
      value: initialValues.notes,
      disabled: false,
      inputProps: InputProps,
      isMultiline: true,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  const {handleSubmit, formState, unregister, reset, watch, control} = useForm();

  const addQueryParams = useMemo(() => {
    if (
      newServicePlanTermsInfo &&
      (!newServicePlanTermsInfo.term ||
        !newServicePlanTermsInfo.availableForViasat ||
        !newServicePlanTermsInfo.availableForSatcomDirect ||
        !newServicePlanTermsInfo.availableForCollins ||
        !newServicePlanTermsInfo.availableForHoneywell ||
        !newServicePlanTermsInfo.availableForOemMro)
    )
      return;
    return newServicePlanTermsInfo;
  }, [newServicePlanTermsInfo]);

  const updateQueryParams = useMemo(() => {
    if (
      isUpdate &&
      updateServicePlanTermsInfo &&
      (!updateServicePlanTermsInfo.term ||
        !updateServicePlanTermsInfo.availableForViasat ||
        !updateServicePlanTermsInfo.availableForSatcomDirect ||
        !updateServicePlanTermsInfo.availableForCollins ||
        !updateServicePlanTermsInfo.availableForHoneywell ||
        !updateServicePlanTermsInfo.availableForOemMro)
    )
      return;
    return updateServicePlanTermsInfo;
  }, [updateServicePlanTermsInfo, isUpdate]);

  const {isLoading: addInProgress, data: addNewServicePlanTermsInfo} = useFetchV2(
    {route: addServicePlanTermsQuery.route, params: addQueryParams},
    addServicePlanTermsQuery.transform
  );
  const {isLoading: updateInProgress, data: updatedServicePlanTermsInfo} = useFetchV2(
    {route: updateServicePlanTermsQuery(panelData?.id).route, params: updateQueryParams},
    updateServicePlanTermsQuery(panelData?.id).transform
  );
  const onCancelClick = () => {
    unregister();
    reset(
      {softwareType: '', network: '', priorRelease: '', productionRelease: '', pilotRelease: '', notes: ''},
      {
        keepValues: false
      }
    );
    setOpenPanel(false);
  };

  const handleOpen = () => {
    togglePanel(true);
    setOpenPanel(true);
  };

  const handleClose = () => {
    if (localStorage.getItem('backdropClick') === 'true') {
      localStorage.setItem('backdropClick', 'false');
      return;
    }
    togglePanel(false);
    setOpenPanel(false);
  };

  useEffect(() => {
    if (addInProgress) return;
    if (addNewServicePlanTermsInfo) {
      setOpenPanel(false);
      dispatch({
        type: LookupsListAction.SET_NEW_ROW_COUNT,
        payload: 1
      });
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'Service Plan Term entry added.'}
      });
    }
    //eslint-disable-next-line
  }, [addInProgress, addNewServicePlanTermsInfo]);

  useEffect(() => {
    if (updateInProgress) return;
    if (updatedServicePlanTermsInfo) {
      setOpenPanel(false);
      dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: panelData});
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'Service Plan Term data updated.'}
      });
    }
    //eslint-disable-next-line
  }, [updateInProgress, updatedServicePlanTermsInfo]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={openPanel}
        onOpen={handleOpen}
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
      >
        <AddForm>
          <AddFormHeader id={`${ID_PREFIX}--servicePlanTermsTitle`}>ADD SERVICE PLAN TERM INFO</AddFormHeader>
          <FormContainer>
            <FormComponent
              id="add-service-plan-terms-form"
              formFields={servicePlanTermsFormFields}
              setFormData={!isUpdate ? setNewServicePlanTermsInfo : setUpdateServicePlanTermsInfo}
              formControlMethods={{handleSubmit, formState, watch, control}}
              idPrefix={ID_PREFIX}
            ></FormComponent>
          </FormContainer>
          <ButtonContainer>
            <DrawerButton id={`${ID_PREFIX}-cancel-button`} className="cancel-button" onClick={onCancelClick}>
              <span className="button-text">Cancel</span>
            </DrawerButton>
            <DrawerButton
              form="add-service-plan-terms-form"
              id={`${ID_PREFIX}-add-button`}
              className="add-button"
              onClick={() => null}
            >
              <span className="button-text">{!isUpdate ? 'Add' : 'Update'}</span>
            </DrawerButton>
          </ButtonContainer>
        </AddForm>
      </SwipeableDrawer>
    </>
  );
};

export default AddServicePlanTerms;
