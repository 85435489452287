/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Replace Equipment Base Component
 */
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useWatch} from 'react-hook-form';
import {isEmpty, isNil, isObject} from 'lodash';

import {useStore} from '../../store/Store';

import {FormField} from '../common/elements/Form';
import {FormControlMethods} from '../common/elements/Form';
import {FormActionButtons} from '../common/buttons/FormActionButton';
import PageContainer from '../mainMenu/PageContainer';
import RightPanelInformationContainer from '../mainMenu/RightPanelInformationContainer';

import EquipmentUpdateForm from './EquipmentUpdateForm';
import useSequence from '../common/hooks/useSequence';
import {useEquipmentUpdate} from '../../utils/customHooks';

import {FormConfig} from './EquipmentUpdateForm';
import {Query} from '../../store/queries/types';
import {EquipmentInfoAction} from '../../store/reducers/EquipmentInfoReducer';
import {LoadingSpinner} from '@viasat/insights-components';

interface ReplaceEquipmentBaseProps<FormInput> {
  title: string;
  aircraftSerialNumber?: string;
  equipmentName: string;
  formConfigList: FormConfig[];
  existingEquipmentStoreKey: string;
  newEquipmentStoreKey: string;
  defaultFormValues: FormInput;
  getFormFields: (initialValues: FormInput, isExistingForm: boolean) => FormField[];
  formControlMethods: FormControlMethods;
  fetchQuery: Query<any>;
  formIdPrefix?: string;
  isLoading?: boolean;
}

const ReplaceEquipmentBase = <IFormInput,>({
  title,
  aircraftSerialNumber,
  equipmentName,
  formConfigList,
  existingEquipmentStoreKey,
  newEquipmentStoreKey,
  defaultFormValues,
  getFormFields,
  formControlMethods,
  fetchQuery,
  formIdPrefix,
  isLoading = false
}: ReplaceEquipmentBaseProps<IFormInput>) => {
  const {store, dispatch} = useStore();
  const {handleSubmit, formState, control, watch, reset} = formControlMethods;
  const [newFormData, setNewFormData] = useState<IFormInput>(null);
  const [existingFormData, setExistingFormData] = useState<IFormInput>(null);
  const navigate = useNavigate();
  const breadcrumbInfo = store.app.breadcrumb;
  const taskId = store.openTasksInfo?.newTask?.taskId || store.equipmentInfo.taskId;
  const formId = `replace-${equipmentName}-information-form`;
  const {aircraftInfo} = store;

  const getFormValuesByIdx = (formIdx: number): IFormInput => {
    const formValues: IFormInput =
      formIdx === 0 ? store.equipmentInfo[existingEquipmentStoreKey] : store.equipmentInfo[newEquipmentStoreKey];
    const initialValues = isObject(formValues) && Object.keys(formValues).length ? formValues : defaultFormValues;
    return initialValues;
  };

  const {
    activeSequenceIdx: activeFormConfigIdx,
    nextSequence,
    previousSequence,
    getCurrentSequence
  } = useSequence<FormConfig>(formConfigList, 0);
  const existingFormConfigIdx: number = 0;
  const isExistingForm: boolean = activeFormConfigIdx === existingFormConfigIdx;
  const initialValues: IFormInput = getFormValuesByIdx(activeFormConfigIdx);

  // reset form values on form type change
  useEffect(() => {
    reset(getFormValuesByIdx(activeFormConfigIdx));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFormConfigIdx]);

  const formValues = useWatch({
    control
  });

  const storeEquipmentData = (formData: IFormInput, actionType: string) => {
    dispatch({
      type: actionType,
      payload: formData
    });
  };

  /**
   * Function to unset formDate object keys if the respective form field are not active
   *
   * @param {string} formData - Form Date
   * @return {IFormInput} replace Form Date with nullified values
   */
  const unsetNotActiveFormFieldValues = (formData: IFormInput): IFormInput => {
    const formFieldNames = getFormFields(initialValues, isExistingForm).map(x => x.id);
    const formDataToUpdate = {...formData};
    for (var key in formDataToUpdate) {
      if (!formFieldNames.includes(key)) {
        formDataToUpdate[key] = null;
      }
    }
    return formDataToUpdate;
  };

  const onFormSubmit = (formData: IFormInput, actionType: string) => {
    const formDataToUpdate = unsetNotActiveFormFieldValues(formData);
    if (isExistingForm) {
      // store existing form data to store and move to next sequence
      setExistingFormData(formDataToUpdate);
      storeEquipmentData(formDataToUpdate, actionType);
      nextSequence();
    } else setNewFormData(formDataToUpdate);
  };

  const onBackClick = (isExistingForm: boolean): void => {
    if (isExistingForm) {
      breadcrumbInfo.pop();
      navigate(-1);
    } else previousSequence();
  };

  const {updateInProgress, updateInformation} = useEquipmentUpdate<IFormInput>(
    taskId,
    newFormData,
    fetchQuery,
    '/replace-terminal-equipment',
    () => {
      // store new form data to store if update API call is success
      if (!isExistingForm) {
        const {actionType} = getCurrentSequence();
        storeEquipmentData(newFormData, actionType);
        breadcrumbInfo.pop();
      }
    },
    aircraftInfo,
    existingFormData
  );
  useEffect(() => {
    if (updateInProgress) return;
    if (!isNil(updateInformation) && (isNil(taskId) || isEmpty(taskId))) {
      dispatch({
        type: EquipmentInfoAction.SET_TASK_ID,
        payload: updateInformation.taskId
      });
    }
    // eslint-disable-next-line
  }, [updateInProgress, updateInformation]);

  const getLeftChild = (): JSX.Element => {
    const data = getCurrentSequence();
    return !isLoading ? (
      <EquipmentUpdateForm
        key={data.id}
        formId={formId}
        idPrefix={formIdPrefix}
        formConfig={data}
        formFields={getFormFields(initialValues, isExistingForm)}
        onFormSubmit={formData => onFormSubmit(formData, data.actionType)}
        formControlMethods={{handleSubmit, formState, control, watch}}
      />
    ) : (
      <LoadingSpinner caption={'Loading ...'} id={`${formId}-loading`} size={24} />
    );
  };

  /**
   * Function returns replace equipment prop based on equipmentName provided
   *
   * @param {string} equipmentName - preprocessed equipment accordion data
   * @param {{[key: string]: string}} formValues - preprocessed equipment accordion data
   * @return {[key: string]: string} replace equipment props object for provided equipmentName
   * @example
   * // returns {apsuReplaceData: {formField1: value, formField2: value}}
   */
  const getEquipmentReplaceProp = (equipmentName: string, formValues: any, isExistingForm: boolean): {[key: string]: IFormInput} => {
    if(isExistingForm) return;
    const equipmentReplaceField = `${equipmentName}ReplaceData`;
    const equipmentReplaceData = {};
    equipmentReplaceData[equipmentReplaceField] = unsetNotActiveFormFieldValues(formValues);
    return equipmentReplaceData;
  };

  return (
    <PageContainer
      title={title}
      leftChild={getLeftChild()}
      rightChild={
        <RightPanelInformationContainer
          title={'Aircraft & Service Details'}
          showExistingInfo={isExistingForm}
          showDifference={isExistingForm ? false : true}
          ignoreFieldsIfEmpty={true}
          {...getEquipmentReplaceProp(equipmentName, formValues, isExistingForm)}
        />
      }
      onBackClick={() => onBackClick(isExistingForm)}
      showExistingInfo={!isExistingForm}
      actionButtons={
        <FormActionButtons
          id={formIdPrefix}
          formId={formId}
          disabled={false}
          showArrow={isExistingForm}
          buttonText={isExistingForm ? 'Next' : 'Submit'}
        />
      }
    />
  );
};

export default ReplaceEquipmentBase;
