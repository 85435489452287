/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add IP Scope Information
 */

import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {isNil} from 'lodash';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {
  BottomHorizontalRule,
  FormContainerLeftPanel,
  HeaderHorizontalRule,
  HeaderLabel,
  NextButton,
  PageDescription
} from '../CommonStyles';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import {useStore} from '../../store/Store';
import {
  addServicePlanQuery,
  updateServicePlanQuery,
  useServicePlanQueryParams
} from '../../store/queries/servicePlanInfoQueries';
import {AppAction} from '../../store/reducers/AppReducer';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import {useCreateApprovalRequestOnServicePlanSubmit} from '../../utils/customHooks';
import {ipv4Regex} from '../common/constants';
import useFetchV2 from '../../utils/useFetchV2';
import {kaBandIPAddressRequired} from '../../utils/config';
import {isExistingKaBandIp} from '../../utils/validationUtils';

const ID_PREFIX = 'servicePlan-ipAddress';

interface AddKaIpAddressProps {
  title: string;
}

interface IFormInput {
  ipAddress: string;
}

const AddKaIpAddress: React.FC<AddKaIpAddressProps> = ({title}) => {
  const LeftChild = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {store, dispatch} = useStore();
    const newTaskId = store.aircraftInfo.taskId ? store.aircraftInfo.taskId : store.openTasksInfo.newTask?.taskId;
    const {breadcrumb, servicePlans} = store.app;
    const {isInternal} = store.init;
    const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';
    const {aircraftInfo} = store;
    const {varKaIpList} = store.app;
    const partialData = JSON.parse(localStorage.getItem('ChooseServicePlan'));
    const [existingKaIp, setExistingKaIp] = useState<string>('');

    const taskDetails = {
      groupCode: groupCode,
      serialNumber: aircraftInfo.serialNumber,
      tailId: aircraftInfo.tailId,
      make: aircraftInfo.make,
      model: aircraftInfo.model,
      network: aircraftInfo.network
    };

    let initialValues: IFormInput = {
      ipAddress: existingKaIp ? existingKaIp : ''
    };
    useEffect(() => {
      const existingKaBandIp = varKaIpList.filter(
        data => data.tailId === aircraftInfo.tailId && (data.serviceSiteKaBandIp || data.ntdChangesKaBandIp)
      );
      if (existingKaBandIp.length) {
        setExistingKaIp(
          existingKaBandIp[0]?.serviceSiteKaBandIp
            ? existingKaBandIp[0]?.serviceSiteKaBandIp
            : existingKaBandIp[0]?.ntdChangesKaBandIp
        );
      }
    }, [varKaIpList, aircraftInfo.tailId]);
    const servicePlanInfoFormFields: FormField[] = [
      {
        id: 'ipAddress',
        label: 'Ka IP Address',
        type: FormFieldType.INPUT,
        value: initialValues.ipAddress,
        disabled: false,
        dropDownValues: [],
        description: null,
        rules: {
          required: kaBandIPAddressRequired,
          pattern: {value: new RegExp(ipv4Regex), message: 'Invalid IP Address'},
          validate: value => isExistingKaBandIp(value, aircraftInfo.tailId, varKaIpList)
        }
      }
    ];

    const {handleSubmit, formState, watch, control} = useForm();
    const [servicePlanIpScopeInfoFormData, setServicePlanIpScopeInfoFormData] = useState(null);

    const queryParams = useServicePlanQueryParams(
      newTaskId,
      isInternal,
      servicePlanIpScopeInfoFormData,
      servicePlans,
      taskDetails,
      aircraftInfo.isNewInstall,
      groupCode,
      undefined,
      partialData
    );

    const isUpdate = !isNil(partialData) ? (isNil(partialData.taskId) ? true : false) : false;
    const {isLoading: inProgress, data: servicePlanInfo} = useFetchV2(
      {route: partialData && isUpdate ? addServicePlanQuery.route : updateServicePlanQuery.route, params: queryParams},
      partialData && isUpdate ? addServicePlanQuery.transform : updateServicePlanQuery.transform
    );

    const {isSubmitRequestLoading, submitResponse} = useCreateApprovalRequestOnServicePlanSubmit(
      servicePlanInfo?.taskId,
      isUpdate
    );

    useEffect(() => {
      if (inProgress || isNil(servicePlanInfo) || isSubmitRequestLoading || isNil(submitResponse)) return;

      let operation,
        message,
        details = '';
      if (servicePlanInfo.updated && servicePlanInfo.updated === 1) {
        operation = 'updated';
        message = 'Service plan information has been';
        details = 'Service plan information has been sent to Viasat MCC. ';
      } else {
        operation = 'added';
        message = 'New service plan information has been';
        details = 'New service plan information has been sent to Viasat MCC. ';
      }
      dispatch({
        type: AppAction.SET_BREADCRUMB,
        payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
      });
      dispatch({
        type: AppAction.SET_ALERT_INFO,
        payload: {
          showAlert: true,
          operation: operation,
          message: message,
          details: details
        }
      });
      navigate('/main-menu');
      //eslint-disable-next-line
    }, [dispatch, inProgress, servicePlanInfo, isSubmitRequestLoading, submitResponse]);

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Specify Ka-Band IP Address</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--pageDescription`}>
          Please specify the Ka-Band IP address for this aircraft service.
        </PageDescription>
        <BottomHorizontalRule />
        <FormContainerLeftPanel>
          <FormComponent
            idPrefix={ID_PREFIX}
            id="add-rate-plan-ka-ip-address-form"
            formFields={servicePlanInfoFormFields}
            setFormData={setServicePlanIpScopeInfoFormData}
            formControlMethods={{handleSubmit, formState, watch, control}}
          ></FormComponent>
        </FormContainerLeftPanel>
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton form="add-rate-plan-ka-ip-address-form" disabled={false} id={`${ID_PREFIX}--nextButton`}>
        <span className="next-button-text">Submit</span>
        <ArrowForwardIcon className="next-arrow" />
      </NextButton>
    );
  };

  return <PageContainer title={title} leftChild={<LeftChild />} actionButtons={<ActionButtons />} />;
};

export default AddKaIpAddress;
