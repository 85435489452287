/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Alert Message Container
 *
 */
import React, {useState} from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import {isNil} from 'lodash';
import {AlertWrapper, LeftGreenBorder, AlertContent} from '../CommonStyles';
import {AppAction} from '../../store/reducers/AppReducer';
import {useStore} from '../../store/Store';

const ID_PREFIX = 'alertContainer';

const AlertContainer: React.FC<any> = ({showAlert, alertOperation, primaryMessage, detailInformation}) => {
  const {dispatch} = useStore();
  const [isShow, setIsShow] = useState<boolean>(showAlert);
  const handleAlertClose = () => {
    setIsShow(false);
    dispatch({
      type: AppAction.SET_ALERT_INFO,
      payload: {showAlert: false}
    });
  };

  return (
    <>
      {isShow ? (
        <AlertWrapper>
          <LeftGreenBorder></LeftGreenBorder>
          <CheckCircleOutlineIcon className="greenTick"></CheckCircleOutlineIcon>
          <AlertContent>
            <div className="primaryMessage" id={`${ID_PREFIX}--primaryMessage`}>
              {primaryMessage} {isNil(alertOperation) ? '' : alertOperation === 'added' ? `submitted!` : `updated.`}
            </div>
            <div className="messageInfo" id={`${ID_PREFIX}--detailInfo`}>
              {detailInformation}
            </div>
          </AlertContent>
          <CloseIcon className="closeIcon" id="alert-close" onClick={handleAlertClose} />
        </AlertWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default AlertContainer;
