/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation Loading State Bars
 */
import React from 'react';
import {AircraftDetailsLabel} from '../mainMenu/PageContainer';
import {LoadingBar, LoadingBarType} from '@viasat/insights-components';
import {
  ReviewContainer,
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewSection,
  ReviewSubSection
} from './ReviewInformationStyles';

interface IReviewInfoLoadingBar {
  idPrefix: string;
  loadingBarRows: number;
}

const ReviewInfoLoadingBar: React.FC<IReviewInfoLoadingBar> = ({idPrefix, loadingBarRows}) => {
  const loadingBars = [];
  const labelLoadingBarWidth = 100;
  const valueLoadingBarWidth = 350;
  const childRowBars = 4;
  const childLoadingBars = [];
  for (let childLoadingBarIdx = 0; childLoadingBarIdx < childRowBars; childLoadingBarIdx++) {
    childLoadingBars.push(
      <ReviewItem key={`child-bar-${childLoadingBarIdx}`}>
        <ReviewItemLabel>
          <LoadingBar
            id={`${idPrefix}--loading-bar-section-label-${childLoadingBarIdx}`}
            height={10}
            type={LoadingBarType.default}
            width={labelLoadingBarWidth}
          />
        </ReviewItemLabel>
        <ReviewItemValue>
          <LoadingBar
            id={`${idPrefix}--loading-bar-section-value-${childLoadingBarIdx}`}
            height={10}
            type={LoadingBarType.default}
            width={valueLoadingBarWidth}
          />
        </ReviewItemValue>
      </ReviewItem>
    );
  }
  for (let loadingBarIdx = 0; loadingBarIdx < loadingBarRows; loadingBarIdx++) {
    loadingBars.push(
      <ReviewSection key={`loading-bar-row-${loadingBarIdx}`}>
        <ReviewItemTitle>
          <LoadingBar
            id={`${idPrefix}--loading-bar-section-title-${loadingBarIdx}`}
            height={10}
            type={LoadingBarType.default}
            width={valueLoadingBarWidth + 100}
          />
        </ReviewItemTitle>
        <ReviewSubSection>
          <ReviewItems>{childLoadingBars}</ReviewItems>
        </ReviewSubSection>
      </ReviewSection>
    );
  }
  return (
    <ReviewContainer isApproved={false}>
      <ReviewSubSection key={`loading-bar-row-primary`}>
        <AircraftDetailsLabel>
          <LoadingBar id={`${idPrefix}--loading-1`} height={10} type={LoadingBarType.default} width={500} />
        </AircraftDetailsLabel>
      </ReviewSubSection>
      {loadingBars}
    </ReviewContainer>
  );
};

export default ReviewInfoLoadingBar;
