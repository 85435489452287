/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Edit Review Information page
 */
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {get, isNil, keys, omit} from 'lodash';

import useFetchV2 from '../../../utils/useFetchV2';
import {useForm} from 'react-hook-form';

import {useStore} from '../../../store/Store';
import {IReviewInformation} from '../../../store/queries/getRequestInformationQuery';
import {updateEquipmentQuery} from '../../../store/queries/updateEquipmentInformationQuery';
import {ApprovalInfoAction} from '../../../store/reducers/ApprovalReducer';
import {updateCustomerQuery} from '../../../store/queries/updateCustomerQuery';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CloseIcon from '@mui/icons-material/Close';

import AircraftDetail from '../AircraftDetail';
import RequestDetail from '../RequestDetail';
import AccessControl from '../../common/accessControl/AccessControl';
import RequestHistory from '../RequestHistory';
import EditReviewInfoPopupModal from './EditReviewInformationModal';
import EquipmentInformationForm from './EquipmentInformation';
import CustomerInformationForm from './CustomerInformation';

import {SERVICE_PLAN_REQUEST_TYPES, TASK_STATUS, TASK_TYPES} from '../../common/constants';

import {NextButton, NormalButton} from '../../CommonStyles';
import {
  EditInformationBannerContainer,
  EditInformationBannerContent,
  EditInformationTitle,
  ReviewContainer,
  ReviewPageActions
} from '../ReviewInformationStyles';

import ServicePlanInformationForm from './ServicePlanInformation';
import {updateServicePlanQuery} from '../../../store/queries/servicePlanInfoQueries';
import AircraftInformationForm from './AircraftInformation';
import {editAircraftDetailsQuery} from '../../../store/queries/editAircraftDetailsQuery';

const EditInformationBanner: React.FC<{cancelEdit: () => void}> = ({cancelEdit}) => {
  return (
    <EditInformationBannerContainer>
      <EditInformationBannerContent>
        <ModeEditIcon className="editIcon" />
        <EditInformationTitle>Editing Mode Enabled</EditInformationTitle>
        <CloseIcon className="closeIcon" onClick={() => cancelEdit()} />
      </EditInformationBannerContent>
    </EditInformationBannerContainer>
  );
};

const EditReviewInformation: React.FC = () => {
  const idPrefix = 'reviewApprovalInfo';

  const {store, dispatch} = useStore();
  const navigate = useNavigate();

  const {isAdmin, isInternal, isValueAddedReseller} = store.init;
  const groupCode = store.customer.current.code;

  const [showEditReviewInfoModal, setShowEditReviewInfoModal] = useState<boolean>(false);
  const [editReviewFormData, setEditReviewFormData] = useState<any>();

  const [editReviewDescription, setEditReviewDescription] = useState<{description: string}>();
  const {varAccountList} = store.app;

  const form = 'review-edit-form';

  const {handleSubmit, formState, watch, control, unregister} = useForm();

  const approvalRequestInfo = useOutletContext<IReviewInformation>();

  const cancelEdit = () => {
    navigate(`/review-request/${approvalRequestInfo.taskDetail.taskId}/view`);
  };

  useEffect(() => {
    if (!editReviewFormData) return;
    setShowEditReviewInfoModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editReviewFormData]);

  // Update Equipment information
  const updateEquipmentQueryParams = useMemo(() => {
    if (!approvalRequestInfo.equipmentInformation || !editReviewDescription || !editReviewFormData) return;
    const {equipmentInfo} = editReviewFormData;
    if (approvalRequestInfo.taskDetail.taskType === TASK_TYPES.REPLACE_HARDWARE) {
      keys(equipmentInfo).forEach(equipmentInfoKey => {
        get(equipmentInfo, equipmentInfoKey).existing = get(
          approvalRequestInfo.equipmentInformation,
          equipmentInfoKey
        )?.existing;
      });
    }

    return {
      taskId: approvalRequestInfo.taskDetail.taskId,
      ...equipmentInfo,
      description: editReviewDescription.description
    };
  }, [approvalRequestInfo, editReviewFormData, editReviewDescription]);

  const {data: updateEquipmentInformation, isLoading: updateEquipmentInfoLoading} = useFetchV2(
    {route: updateEquipmentQuery.route, params: updateEquipmentQueryParams},
    updateEquipmentQuery.transform
  );
  // Update Customer information
  const updateCustomerQueryParams = useMemo(() => {
    if (!approvalRequestInfo.customerInformation || !editReviewDescription || !editReviewFormData) return;
    const {customerForm} = editReviewFormData;
    const {serialNumber, tailId, make, model} = approvalRequestInfo.taskDetail;
    // get varAccountId of selected var account name
    const {varAccountId} = customerForm;
    const varAccountByName = varAccountList?.find(varAccount => varAccount.varName === varAccountId);
    const varAccountByGroupCode = varAccountList?.find(varAccount => varAccount.groupCode === groupCode);
    customerForm.varAccountId = isValueAddedReseller
      ? varAccountByGroupCode?.varAccountId
      : varAccountByName?.varAccountId;
    //omit group title fields
    const customerInformation = omit(
      customerForm,
      Object.keys(customerForm).filter(key => key.includes('Title'))
    );
    return {
      isInternalUser: isInternal?.toString(),
      groupCode: !isInternal ? groupCode : '',
      ...customerInformation,
      serialNumber,
      tailId,
      make,
      model,
      requestFromApproval: true,
      description: editReviewDescription.description
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editReviewFormData, editReviewDescription]);

  const {data: updateCustomerInformation, isLoading: updateCusotmerInfoLoading} = useFetchV2(
    {route: updateCustomerQuery(approvalRequestInfo.taskDetail.taskId).route, params: updateCustomerQueryParams},
    updateEquipmentQuery.transform
  );

  const updateServicePlanQueryParams = useMemo(() => {
    if (!approvalRequestInfo.servicePlanInformation || !editReviewDescription || !editReviewFormData) return;
    const {servicePlanInfo} = editReviewFormData;
    if (
      approvalRequestInfo.servicePlanInformation.requestType === SERVICE_PLAN_REQUEST_TYPES.DECOMMISSION_SERVICE &&
      servicePlanInfo.decommissionReason === 'Other' &&
      servicePlanInfo.decommissionOtherReason
    ) {
      servicePlanInfo.decommissionReason = servicePlanInfo.decommissionOtherReason;
      delete servicePlanInfo.decommissionOtherReason;
    }
    return {
      taskId: approvalRequestInfo.taskDetail.taskId,
      requestType: approvalRequestInfo.servicePlanInformation.requestType,
      ...servicePlanInfo,
      requestFromApproval: true,
      description: editReviewDescription.description
    };
  }, [approvalRequestInfo, editReviewFormData, editReviewDescription]);

  const {data: updateServicePlanInformation, isLoading: updateServicePlanInfoLoading} = useFetchV2(
    {route: updateServicePlanQuery.route, params: updateServicePlanQueryParams},
    updateServicePlanQuery.transform
  );
  const updateAircraftInformationParams = useMemo(() => {
    if (
      !approvalRequestInfo.existingAircraft ||
      !editReviewDescription ||
      !editReviewFormData ||
      approvalRequestInfo.taskDetail.taskType !== TASK_TYPES.CHANGE_AIRCRAFT_DETAILS
    )
      return;
    const {aircraftInfo} = editReviewFormData;
    return {
      ...aircraftInfo,
      taskId: approvalRequestInfo.taskDetail.taskId,
      groupCode: 'ALL',
      isNewInstall: false,
      requestFromApproval: true,
      description: editReviewDescription.description
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editReviewFormData, editReviewDescription]);

  const {data: updateAircraftInformation, isLoading: updateAircraftInfoLoading} = useFetchV2(
    {route: editAircraftDetailsQuery.route, params: updateAircraftInformationParams},
    editAircraftDetailsQuery.transform
  );
  useEffect(() => {
    if (
      (approvalRequestInfo.equipmentInformation && updateEquipmentInfoLoading) ||
      (approvalRequestInfo.customerInformation && updateCusotmerInfoLoading) ||
      (approvalRequestInfo.servicePlanInformation && updateServicePlanInfoLoading) ||
      (approvalRequestInfo.taskDetail.taskType === TASK_TYPES.CHANGE_AIRCRAFT_DETAILS &&
        approvalRequestInfo.existingAircraft &&
        updateAircraftInfoLoading)
    )
      return;
    dispatch({
      type: ApprovalInfoAction.REFRESH_REVIEW_INFO,
      payload: 1
    });
    navigate(`/review-request/${approvalRequestInfo.taskDetail.taskId}/view`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateEquipmentInformation,
    updateEquipmentInfoLoading,
    updateCusotmerInfoLoading,
    updateCustomerInformation,
    updateServicePlanInfoLoading,
    updateServicePlanInformation,
    updateAircraftInfoLoading,
    updateAircraftInformation
  ]);

  return (
    <>
      {showEditReviewInfoModal && (
        <EditReviewInfoPopupModal
          openModal={showEditReviewInfoModal}
          setOpenModal={setShowEditReviewInfoModal}
          setEditReviewDescription={setEditReviewDescription}
          updateReviewInfoInProgress={
            !isNil(
              updateCustomerQueryParams ||
                updateEquipmentQueryParams ||
                updateServicePlanQueryParams ||
                updateAircraftInformationParams
            )
          }
        />
      )}
      <EditInformationBanner cancelEdit={cancelEdit} />
      <ReviewContainer hasBanner={true} isApproved={false}>
        <AircraftDetail
          idPrefix={idPrefix}
          taskDetail={approvalRequestInfo.taskDetail}
          approvalDetail={approvalRequestInfo.approvalDetail}
          varAccountName={approvalRequestInfo?.equipmentInformation?.varAccountName}
        />
        {approvalRequestInfo.existingAircraft &&
          approvalRequestInfo.taskDetail.taskType === TASK_TYPES.CHANGE_AIRCRAFT_DETAILS && (
            <AircraftInformationForm
              idPrefix={idPrefix}
              taskDetail={approvalRequestInfo.taskDetail}
              existingAircraft={approvalRequestInfo.existingAircraft}
              formId={form}
              formControlMethods={{handleSubmit, formState, watch, control, unregister}}
              setAircraftFormData={setEditReviewFormData}
            />
          )}
        {approvalRequestInfo.equipmentInformation && (
          <EquipmentInformationForm
            idPrefix={idPrefix}
            taskDetail={approvalRequestInfo.taskDetail}
            equipmentDetail={approvalRequestInfo.equipmentInformation}
            formId={form}
            formControlMethods={{handleSubmit, formState, watch, control, unregister}}
            setEquipmentFormData={setEditReviewFormData}
          />
        )}
        {approvalRequestInfo.servicePlanInformation && (
          <ServicePlanInformationForm
            idPrefix={idPrefix}
            taskDetail={approvalRequestInfo.taskDetail}
            servicePlanDetail={approvalRequestInfo.servicePlanInformation}
            formId={form}
            formControlMethods={{handleSubmit, formState, watch, control, unregister}}
            setEquipmentFormData={setEditReviewFormData}
          />
        )}
        {approvalRequestInfo.customerInformation && (
          <CustomerInformationForm
            idPrefix={idPrefix}
            customerDetail={approvalRequestInfo.customerInformation}
            taskDetail={approvalRequestInfo.taskDetail}
            formId={form}
            formControlMethods={{handleSubmit, formState, watch, control, unregister}}
            setCustomerFormData={setEditReviewFormData}
          />
        )}
        <RequestDetail
          idPrefix={idPrefix}
          taskDetail={approvalRequestInfo.taskDetail}
          approvalDetail={approvalRequestInfo.approvalDetail}
        />
        <RequestHistory
          idPrefix={idPrefix}
          requestHistory={approvalRequestInfo.approvalActivities}
          approvalDetail={approvalRequestInfo.approvalDetail}
        />
      </ReviewContainer>
      <ReviewPageActions>
        <AccessControl permitted={isAdmin}>
          {approvalRequestInfo.approvalDetail.status !== TASK_STATUS.APPROVED ? (
            <>
              <NextButton id={`${idPrefix}--save-button`} width={124} form={form}>
                <span id={`${idPrefix}--saveButton`} className="next-button-text">
                  Save
                </span>
              </NextButton>
              <NormalButton id={`${idPrefix}--cancel-button`} onClick={() => cancelEdit()}>
                <span id={`${idPrefix}--canelButton`} className="button-text">
                  Cancel
                </span>
              </NormalButton>
            </>
          ) : (
            <></>
          )}
        </AccessControl>
      </ReviewPageActions>
    </>
  );
};

export default EditReviewInformation;
