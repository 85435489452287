/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Equipment Details - Fan Tray - Ka/Ku
 */
import React from 'react';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemValue,
  ReviewSubSection,
  ReviewSubSectionItemTitle
} from '../../ReviewInformationStyles';
import {renderReviewInformation} from '../../util';

interface IFanTrayInfo {
  serialNumber: string;
  partNumber: string;
}
interface IFanTrayDetail {
  idPrefix: string;
  fanTrayDetail: {new: IFanTrayInfo; existing: IFanTrayInfo};
}

const FanTrayDetail: React.FC<IFanTrayDetail> = ({idPrefix, fanTrayDetail}) => {
  return (
    <>
      {fanTrayDetail && fanTrayDetail.new ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle>Fan Tray</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Part Number</ReviewItemLabel>
              {fanTrayDetail.existing && fanTrayDetail.existing.partNumber ? (
                <ReviewItemValue isExisting={true}>{renderReviewInformation(fanTrayDetail.existing.partNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue>{renderReviewInformation(fanTrayDetail.new.partNumber)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Serial Number</ReviewItemLabel>
              {fanTrayDetail.existing && fanTrayDetail.existing.serialNumber ? (
                <ReviewItemValue isExisting={true}>{renderReviewInformation(fanTrayDetail.existing.serialNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue>{renderReviewInformation(fanTrayDetail.new.serialNumber)}</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
    </>
  );
};

export default FanTrayDetail;
