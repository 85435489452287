/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Renders text in a ListGrid cell, and handles LongText with truncation... and full text in tooltip
 *
 */
import React from 'react';
import styled from '@emotion/styled';
import {CellRenderer, LongText} from '@viasat/insights-components';

import {NO_DATA_INDICATOR} from '../../constants';

interface ListGridLongTextProps {
  cellIdBase?: string;
  cellText: string | null;
  maxLength: number;
  isDisabled?: boolean;
}

const LongTextContainer = styled.div`
  width: 100%;
`;

export const ListGridLongText: React.FC<ListGridLongTextProps> = ({cellIdBase, cellText, maxLength, isDisabled}) => {
  return !cellText ? (
    <span id={`${cellIdBase}-label`}>{NO_DATA_INDICATOR}</span>
  ) : (
    <LongTextContainer id={`${cellIdBase}-label`}>
      <LongText maxLength={maxLength} fullTitle={cellText} disable={isDisabled} />
    </LongTextContainer>
  );
};

const LongTextCellRenderer: CellRenderer = ({cellData, cellIdBase, rowData}) => {
  // This value is arbitrary, to closely match width: 180px & text-overflow: ellipsis to show ToolTips
  // does not account for variable width chars
  const TRUNCATE_AMOUNT = 22;
  return (
    <ListGridLongText
      cellIdBase={cellIdBase}
      cellText={cellData}
      maxLength={TRUNCATE_AMOUNT}
      isDisabled={rowData.isRowDisabled}
    />
  );
};

export default LongTextCellRenderer;
