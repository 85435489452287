/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Page Container
 */
import React from 'react';
import styled from '@emotion/styled';

import PageContent from '../../PageContent';
import {
  ACCORDION_TITLE_LABEL,
  BORDER_COLOR,
  FORM_COLOR,
  LABEL_COLOR,
  SCROLL_BAR_THUMB,
  SCROLL_BAR_TRACK
} from '../../theme/Colors';
import PageNavigationComponent from './PageNavigationComponent';
import RightPanelInformationContainer from './RightPanelInformationContainer';
import {ContactViasatButton} from '../CommonStyles';
import {MailOutline} from '@mui/icons-material';
import {useNavigate} from 'react-router';

interface PageContainerProps {
  title: string;
  leftChild: any;
  rightChild?: any;
  showExistingInfo?: boolean;
  actionButtons?: any;
  onBackClick?: Function;
}
const LeftPanelContent = styled.div`
  padding: 24px 158px;
  max-height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 83vh;
  margin-bottom: 20px;
  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #${SCROLL_BAR_TRACK};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${SCROLL_BAR_THUMB};
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${SCROLL_BAR_THUMB};
    border-radius: 4px;
  }
  @media only screen and (max-width: 768px) {
    padding: 24px;
  }
`;

const LeftPanel = styled.div`
  width: 66%;
  height: calc(100vh - 98px);
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 590px) {
    height: calc(100vh - 122px);
  }
`;
const RightPanel = styled.div`
  width: 34%;
  max-height: 90vh;
  height: 89vh;
  float: right;
  overflow-y: scroll;
  padding: 24px;
  border-left: 1px solid ${BORDER_COLOR};
  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #${SCROLL_BAR_TRACK};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${SCROLL_BAR_THUMB};
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${SCROLL_BAR_THUMB};
    border-radius: 4px;
  }
  @media only screen and (max-width: 430px) {
    display: none;
  }
`;

const PageItems = styled.div`
  display: flex;
  overflow: hidden;
  .accordion-section {
    position: unset;
    box-shadow: none;
    @media only screen and (max-width: 769px) {
      width: 286px;
    }
  }
`;

export const AircraftDetailsLabel = styled.div`
  width: 506px;
  height: 32px;
  margin-bottom: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  align-items: left;
  letter-spacing: 0.2px;
  color: ${LABEL_COLOR};
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
`;

export const AccordionTitleLabel = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${ACCORDION_TITLE_LABEL};
`;

export const AccordionItemTitle = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 20px;

  letter-spacing: 0.2px;
  text-transform: uppercase;

  color: ${LABEL_COLOR};
`;

export const AccordionItemLabel = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  color: ${FORM_COLOR};
  margin-bottom: 10px;
  span {
    display: flex;
  }
`;

const PageContainer: React.FC<PageContainerProps> = ({
  title,
  leftChild,
  rightChild,
  showExistingInfo,
  actionButtons,
  onBackClick
}) => {
  const navigate = useNavigate();

  const contactViasat = () => {
    navigate('/contact-viasat');
  };
  return (
    <div style={{overflow: 'hidden', height: '99%'}}>
      <PageContent
        title={title}
        isSubtitleLoading={false}
        subtitle={''}
        getFullElementId={(name: string, type: string) => `${name}-${type}`}
        leftStack={[]}
        rightStack={[
          <ContactViasatButton onClick={() => contactViasat()} id={`contact-viasat-button`}>
            <MailOutline /> Contact Viasat
          </ContactViasatButton>
        ]}
      >
        <PageItems>
          <LeftPanel>
            <LeftPanelContent> {leftChild}</LeftPanelContent>
            {actionButtons ? (
              <PageNavigationComponent
                title={title}
                onBackClick={onBackClick}
                actionButtons={actionButtons}
              ></PageNavigationComponent>
            ) : (
              <></>
            )}
          </LeftPanel>
          <RightPanel id={`aircraft-details-right-panel`}>
            {rightChild || <RightPanelInformationContainer title={'Aircraft & Service Details'} />}
          </RightPanel>
        </PageItems>
      </PageContent>
    </div>
  );
};

export default PageContainer;
