/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Custom hooks
 */
import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {isEmpty, isNil} from 'lodash';

import {Query} from '../store/queries/types';

import {useStore} from '../store/Store';
import {IPartNumber} from '../store/queries/lookupTypes';
import {LookupsListAction} from '../store/reducers/LookupsReducer';
import {AppAction} from '../store/reducers/AppReducer';
import {AircraftInfoState} from '../store/reducers/AircraftInfoReducer';
import {EquipmentInfoAction} from '../store/reducers/EquipmentInfoReducer';
import {getEquipmentInfoQuery} from '../store/queries/getEquipmentInfoQuery';
import {getCustomerInfoQuery} from '../store/queries/getCustomerInfoQuery';
import {getServicePlanQuery} from '../store/queries/servicePlanInfoQueries';
import {CustomerInfoAction} from '../store/reducers/CustomerInfoReducer';
import {ServicePlanAction} from '../store/reducers/ServicePlanReducer';
import useFetchV2 from './useFetchV2';
import {approveQuery} from '../store/queries/approveQuery';
import {createJiraQuery} from '../store/queries/createJiraQuery';
import {createServiceSiteQuery, IServiceSite} from '../store/queries/salesforce/createServiceSite';
import {createConfigurationItemQuery} from '../store/queries/salesforce/createConfigurationItem';
import {APPROVAL_PROCESS_DESCRIPTION, APPROVAL_PROCESS_STATUS, TASK_TYPES} from '../components/common/constants';
import {createAccountQuery} from '../store/queries/salesforce/createAccount';
import {updateServiceSiteQuery} from '../store/queries/salesforce/updateServiceSite';
import {updateConfigurationItemQuery} from '../store/queries/salesforce/updateConfigurationItem';
import {APPROVAL_MODULES} from '../components/addNewTerminal/addNewTerminalUtils';
import {submitRequestQuery} from '../store/queries/submitRequestQuery';
import {IVarAccount} from '../store/queries/getVarAccountListQuery';
import {formatDateTimeInputAsDateMonthYearNumber} from './dateTimeUtils';

/**
 * Hook to auto update existing and new form data on state change
 * to provided API endpoint
 *
 * @param {string} taskId - taskId
 * @param {T} formData - form data
 * @param {existingFormData} existingFormData - existing form data
 * @param {Query<any>} fetchQuery - API end point
 * @param {string} redirectUrl - redirect url
 */
export function useEquipmentUpdate<T>(
  taskId: string,
  formData: T,
  fetchQuery: Query<any>,
  redirectUrl: string,
  callBack: () => void,
  aircraftInfo: AircraftInfoState,
  existingFormData?: T
): {updateInProgress: boolean; updateInformation: any} {
  const navigate = useNavigate();
  const {store} = useStore();
  const installationType = store.aircraftInfo.installationType;
  const queryParams = useMemo(() => {
    if (isNil(formData)) return;
    if (isNil(existingFormData)) {
      return {
        ...formData,
        aircraftSerialNumber: aircraftInfo.serialNumber,
        tailId: aircraftInfo.tailId,
        make: aircraftInfo.make,
        model: aircraftInfo.model,
        network: aircraftInfo.network,
        installationType: installationType,
        taskId
      };
    }
    return {
      ...formData,
      aircraftSerialNumber: aircraftInfo.serialNumber,
      tailId: aircraftInfo.tailId,
      make: aircraftInfo.make,
      model: aircraftInfo.model,
      network: aircraftInfo.network,
      existing: {...existingFormData},
      taskId
    };
    // eslint-disable-next-line
  }, [formData, existingFormData, taskId]);

  const {isLoading: updateInProgress, data: updateInformation} = useFetchV2(
    {route: fetchQuery.route, params: queryParams},
    fetchQuery.transform
  );
  useEffect(() => {
    if (!updateInProgress && !isNil(updateInformation) && updateInformation.updated === 1) {
      callBack();
      navigate(redirectUrl);
    } else if (!updateInProgress && !isNil(updateInformation) && !isNil(updateInformation.taskId)) {
      callBack();
      navigate(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInProgress, updateInformation]);

  return {updateInProgress, updateInformation};
}

/**
 * Hook to fetch part numbers
 *
 * @param {string} equipmentType - equipment type
 * @param {string} network - network type
 * @param {Query<any>} fetchQuery - query to fetch partnumbers
 */

export function usePartNumbersFetch(equipmentType: string, network: string, fetchQuery: Query<any>): IPartNumber[] {
  const [partNumbers, setPartNumbers] = useState<IPartNumber[]>([]);

  const queryParams = useMemo(() => {
    if (isNil(equipmentType)) return;
    return {
      equipmentType,
      network,
      lookupType: 'dropdown'
    };
  }, [equipmentType, network]);

  const {isLoading: partNumbersFetchInProgress, data: partNumbersList} = useFetchV2(
    {route: fetchQuery.route, params: queryParams},
    fetchQuery.transform
  );
  useEffect(() => {
    if (!partNumbersFetchInProgress && !isNil(partNumbers)) {
      setPartNumbers(partNumbersList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partNumbersFetchInProgress, partNumbers]);

  return partNumbers;
}

/**
 * Hook to retire lookup record
 *
 * @param {string} retireLookupRoute - retire lookup query route
 * @param {retireLookup} boolean - retire lookup
 * @param {string} retireLookupMessage - snack bar message on retiring a lookup record
 * @param {method} openRetireLookupModal - method to close retire lookup modal
 */

export function useRetireLookupRecord(
  retireLookupRoute: string,
  retireLookup: boolean,
  retireLookupMessage: string,
  openRetireLookupModal: (openModal: boolean) => void
) {
  const {dispatch} = useStore();
  const queryParams = useMemo(() => {
    if (!retireLookup) return;
    return {};
  }, [retireLookup]);
  const retireLookupQuery: Query<{deactivated: number}> = {route: retireLookupRoute, transform: null};
  const {isLoading: retireInProgress, data: retiredLookupdata} = useFetchV2(
    {route: retireLookupQuery.route, params: queryParams},
    retireLookupQuery.transform
  );
  useEffect(() => {
    if (retireInProgress) return;
    if (retiredLookupdata && retiredLookupdata.deactivated === 1) {
      openRetireLookupModal(false);
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: LookupsListAction.SET_DEACTIVATED_ROW_COUNT,
        payload: 1
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: retireLookupMessage}
      });
      dispatch({
        type: LookupsListAction.REMOVE_ALL_SELECTED_LOOKUPS,
        payload: []
      });
    }
    //eslint-disable-next-line
  }, [retireInProgress, retiredLookupdata]);
}
/**
 * Hook to refresh lookup list
 *
 * @param {boolean} refreshList - refresh list
 */

export function useRefreshLookupList(refreshList: boolean) {
  const {dispatch} = useStore();
  useEffect(() => {
    if (refreshList) {
      setTimeout(() => {
        dispatch({
          type: LookupsListAction.REFRESH_LOOKUP_LIST,
          payload: false
        });
        dispatch({
          type: LookupsListAction.RESET_NEW_ROW_COUNT,
          payload: 0
        });
        dispatch({
          type: LookupsListAction.RESET_DEACTIVATED_ROW_COUNT,
          payload: 0
        });
      }, 3000); // The API call takes 1s or 1.5s or 2s sometimes and inconsistent with 500ms.
    }
    //eslint-disable-next-line
  }, [refreshList]);
}
/**
 * Hook to remove selected lookup records
 *
 */
export function useResetSelectedLookup() {
  const {dispatch} = useStore();
  useEffect(() => {
    dispatch({
      type: LookupsListAction.REMOVE_ALL_SELECTED_LOOKUPS,
      payload: []
    });
    dispatch({
      type: AppAction.SET_SNACK_BAR,
      payload: {openSnack: false, snackMessage: null}
    });
    //eslint-disable-next-line
  }, []);
}
/**
 * Hook to clear breadcrumb
 *
 */

export function useClearBreadCrumb() {
  const {dispatch} = useStore();
  useEffect(() => {
    dispatch({type: AppAction.SET_BREADCRUMB, payload: []});
    //eslint-disable-next-line
  }, []);
}

export function useGetEquipmentInfo(fetchEquipmentInfo: boolean, taskId: string, groupCode: string) {
  const {dispatch} = useStore();
  const queryParams = useMemo(() => {
    if (!fetchEquipmentInfo) return;
    if (isNil(taskId)) return;
    return {
      taskId: taskId,
      groupCode: groupCode
    };
  }, [taskId, groupCode, fetchEquipmentInfo]);

  const {isLoading: equipmentInfoLoading, data: equipmentData} = useFetchV2(
    {route: getEquipmentInfoQuery.route, params: queryParams},
    getEquipmentInfoQuery.transform
  );

  useEffect(() => {
    if (equipmentInfoLoading) return;
    dispatch({type: EquipmentInfoAction.SET_EQUIPMENT_INFO, payload: equipmentData});
    // eslint-disable-next-line
  }, [equipmentInfoLoading]);

  return equipmentInfoLoading;
}

export function useGetEquipmentInfoV2(fetchEquipmentInfo: boolean, taskId: string, groupCode: string): any {
  const {dispatch} = useStore();
  const queryParams = useMemo(() => {
    if (!fetchEquipmentInfo) return;
    if (isNil(taskId)) return;
    return {
      taskId: taskId,
      groupCode: groupCode,
      time: new Date().toUTCString()
    };
  }, [taskId, groupCode, fetchEquipmentInfo]);

  const {
    refetch,
    fetchStatus,
    isLoading: equipmentInfoLoading,
    isFetching,
    data: equipmentData
  } = useFetchV2(
    {route: getEquipmentInfoQuery.route, params: queryParams},
    getEquipmentInfoQuery.transform,
    undefined,
    {enabled: !!queryParams}
  );

  useEffect(() => {
    if (equipmentData) {
      dispatch({type: EquipmentInfoAction.SET_EQUIPMENT_INFO, payload: equipmentData});
    }
  }, [dispatch, equipmentData]);

  return {fetchStatus, refetch, isLoading: equipmentInfoLoading, data: equipmentData, isFetching};
}

export function useGetCustomerInfo(fetchCustomerInfo: boolean, taskId: string, groupCode: string) {
  const {dispatch} = useStore();
  const queryParams = useMemo(() => {
    if (!fetchCustomerInfo) return;
    if (isNil(taskId)) return;
    return {
      taskId: taskId,
      groupCode: groupCode
    };
  }, [taskId, groupCode, fetchCustomerInfo]);

  const {isLoading: customerInfoLoading, data: customerData} = useFetchV2(
    {route: getCustomerInfoQuery.route, params: queryParams},
    getCustomerInfoQuery.transform
  );

  useEffect(() => {
    if (customerInfoLoading || !customerData) return;
    dispatch({type: CustomerInfoAction.SET_CUSTOMER_INFO, payload: customerData});
    // eslint-disable-next-line
  }, [customerInfoLoading, customerData]);

  return customerInfoLoading;
}

/**
 * Retrieves the existing service plan information and updates the store
 * @param fetchServicePlanInfo Existing Service Plan Information
 * @param taskId Task ID
 * @param groupCode User Group Code
 * @returns Service Plan Fetch state
 */
export const useGetServicePlanInfo = (fetchServicePlanInfo: boolean, taskId: string, groupCode: string) => {
  const {dispatch} = useStore();
  const queryParams = useMemo(() => {
    if (!fetchServicePlanInfo) return;
    if (isNil(taskId)) return;
    return {
      taskId: taskId,
      groupCode: groupCode
    };
  }, [taskId, groupCode, fetchServicePlanInfo]);

  const {isLoading: servicePlanInfoLoading, data: servicePlanData} = useFetchV2(
    {route: getServicePlanQuery.route, params: queryParams},
    getServicePlanQuery.transform
  );

  useEffect(() => {
    if (servicePlanInfoLoading) return;
    dispatch({
      type: ServicePlanAction.SET_SERVICE_PLAN,
      payload: servicePlanData && Array.isArray(servicePlanData) ? servicePlanData[0] : servicePlanData
    });
    // eslint-disable-next-line
  }, [servicePlanInfoLoading]);

  return servicePlanInfoLoading;
};

/**
 * Approves the request based on the taskId
 * @param taskIdForApproval Task ID
 * @param serviceSiteCreateData Service Site create/update success data
 * @param configItemCreateData Config Items create/update success data
 * @returns Approval Data and Approval state
 */
export const useApproveRequest = (
  taskIdForApproval: string,
  taskType: string,
  isNewTerminalInstallInProgress: boolean,
  isNewAccountCreationInProgress: boolean,
  isServicePlanUpdateInProgress: boolean,
  isConfigItemsUpdateInProgress: boolean
) => {
  const approveQueryParams = useMemo(() => {
    if (
      !taskIdForApproval ||
      (!isNil(taskType) && taskType === TASK_TYPES.NEW_INSTALL && isNewTerminalInstallInProgress) ||
      (!isNil(taskType) && taskType === TASK_TYPES.NEW_CUSTOMER && isNewAccountCreationInProgress) ||
      (!isNil(taskType) && taskType === TASK_TYPES.NEW_SERVICE_PLAN && isServicePlanUpdateInProgress) ||
      (!isNil(taskType) && taskType === TASK_TYPES.CHANGE_SERVICE_PLAN && isServicePlanUpdateInProgress) ||
      (!isNil(taskType) && taskType === TASK_TYPES.CHANGE_AIRCRAFT_DETAILS && isServicePlanUpdateInProgress) ||
      (!isNil(taskType) && taskType === TASK_TYPES.REPLACE_HARDWARE && isServicePlanUpdateInProgress) ||
      (!isNil(taskType) && taskType === TASK_TYPES.REPLACE_HARDWARE && isConfigItemsUpdateInProgress)
    )
      return;
    return {taskId: taskIdForApproval};
  }, [
    taskIdForApproval,
    taskType,
    isNewTerminalInstallInProgress,
    isNewAccountCreationInProgress,
    isServicePlanUpdateInProgress,
    isConfigItemsUpdateInProgress
  ]);

  const {data: approveData, isLoading: approveRequestLoading} = useFetchV2(
    {route: approveQuery.route, params: approveQueryParams},
    approveQuery.transform
  );

  return {approveData, approveRequestLoading};
};

/**
 * Create Service Site data
 * @param taskIdForApproval Task ID
 * @returns Service Creation data with state of creation
 */
export const useCreateServiceSite = (taskIdForApproval: string, taskType: any) => {
  const createServiceSiteQueryParams = useMemo(() => {
    if (isNil(taskIdForApproval) || (!isNil(taskType) && taskType !== TASK_TYPES.NEW_INSTALL)) return;
    return {taskId: taskIdForApproval};
    // eslint-disable-next-line
  }, [taskIdForApproval]);

  const {data: serviceSiteCreateData, isLoading: isServiceSiteCreationLoading} = useFetchV2(
    {route: createServiceSiteQuery.route, params: createServiceSiteQueryParams},
    createServiceSiteQuery.transform
  );
  let hasFailure = false;
  if (serviceSiteCreateData && serviceSiteCreateData.status && serviceSiteCreateData.status > 300) {
    hasFailure = true;
  }
  return {serviceSiteCreateData, isServiceSiteCreationLoading: isServiceSiteCreationLoading || hasFailure};
};

/**
 * Create Jira ticket on create/update Service Site
 * @param taskIdForApproval Task ID
 * @param requestType New or Update Service Site
 * @param serviceSiteCreateData Latest Service Site data
 * @returns Status of Jira ticket creation
 */
export const useCreateJiraTicket = (
  taskIdForApproval: string,
  serviceSiteData: IServiceSite | null,
  createJira: boolean
) => {
  const createJiraParams = useMemo(() => {
    if (!createJira) return;
    if (isNil(taskIdForApproval) || isNil(serviceSiteData) || serviceSiteData.status > 300) return;

    return {taskId: taskIdForApproval, caseId: serviceSiteData.caseId};
    // eslint-disable-next-line
  }, [taskIdForApproval, serviceSiteData]);

  const {data: jiraData, isLoading: isJiraCreationLoading} = useFetchV2(
    {route: createJiraQuery.route, params: createJiraParams},
    createJiraQuery.transform
  );
  let hasFailure = false;
  if (jiraData && !jiraData.isSuccess) {
    hasFailure = true;
  }
  return {jiraData, isJiraCreationLoading: isJiraCreationLoading || hasFailure};
};

/**
 * Creates Configuration Items
 * @param taskIdForApproval Task ID
 * @param serviceSiteCreateData Service Site success data
 * @returns Configuration Items create date and state
 */
export const useCreateConfigurationItems = (taskIdForApproval: string, serviceSiteCreateData: any, taskType: any) => {
  const configItemParams = useMemo(() => {
    if (
      isNil(taskIdForApproval) ||
      isNil(serviceSiteCreateData) ||
      (!isNil(taskType) && taskType !== TASK_TYPES.NEW_INSTALL) ||
      serviceSiteCreateData.status > 300
    )
      return;
    return {taskId: taskIdForApproval, siteId: serviceSiteCreateData.id};
    // eslint-disable-next-line
  }, [taskIdForApproval, serviceSiteCreateData]);

  const {data: configItemCreateData, isLoading: isConfigItemCreationInProgress} = useFetchV2(
    {route: createConfigurationItemQuery.route, params: configItemParams},
    createConfigurationItemQuery.transform
  );

  let hasFailure = false;
  if (configItemCreateData && configItemCreateData.status && configItemCreateData.status > 300) {
    hasFailure = true;
  }

  return {configItemCreateData, isConfigItemCreationInProgress: isConfigItemCreationInProgress || hasFailure};
};

/**
 * Create Account data
 * @param taskIdForApproval Task ID
 * @returns Account Creation data with state of creation
 */
export const useCreateAccount = (taskIdForApproval: string, taskType: any, siteId: string) => {
  const createAccountQueryParams = useMemo(() => {
    if (isNil(taskIdForApproval) || isNil(siteId) || (!isNil(taskType) && taskType !== TASK_TYPES.NEW_CUSTOMER)) return;
    return {taskId: taskIdForApproval, siteId};
    // eslint-disable-next-line
  }, [taskIdForApproval, siteId]);

  const {data: accountCreateData, isLoading: isAccountCreationInProgress} = useFetchV2(
    {route: createAccountQuery.route, params: createAccountQueryParams},
    createAccountQuery.transform
  );

  return {accountCreateData, isAccountCreationInProgress};
};

/**
 * Updates Service Site data
 * @param taskIdForApproval Task ID
 * @returns Service Update data with state of update
 */
export const useUpdateServiceSite = (
  taskIdForApproval: string,
  taskType: any,
  siteId: string,
  accountCreateData: any
) => {
  const updateServiceSiteQueryParams = useMemo(() => {
    if (isNil(taskIdForApproval) || isNil(siteId) || isNil(taskType) || taskType === TASK_TYPES.NEW_INSTALL) return;
    if (taskType === TASK_TYPES.NEW_CUSTOMER && (isNil(accountCreateData) || accountCreateData.status > 300)) return;
    return {taskId: taskIdForApproval, siteId};
  }, [taskIdForApproval, siteId, taskType, accountCreateData]);

  const {data: serviceSiteData, isLoading: isServiceUpdateInProgress} = useFetchV2(
    {route: updateServiceSiteQuery.route, params: updateServiceSiteQueryParams},
    updateServiceSiteQuery.transform
  );

  return {serviceSiteData, isServiceUpdateInProgress};
};

/**
 * Creates/Updates Configuration Items
 * @param taskIdForApproval Task ID
 * @param siteId Service Site ID
 * @param taskType Type of the Task to validate and proceed
 * @returns Configuration Items create date and state
 */
export const useCreateUpdateConfigurationItems = (taskIdForApproval: string, siteId: any, taskType: any) => {
  const configItemParams = useMemo(() => {
    if (isNil(taskIdForApproval) || isNil(siteId) || isNil(taskType) || taskType !== TASK_TYPES.REPLACE_HARDWARE)
      return;
    return {taskId: taskIdForApproval, siteId};
  }, [taskIdForApproval, taskType, siteId]);

  const {data: configItemUpdateData, isLoading: isConfigItemUpdateInProgress} = useFetchV2(
    {route: updateConfigurationItemQuery.route, params: configItemParams},
    updateConfigurationItemQuery.transform
  );

  return {configItemUpdateData, isConfigItemUpdateInProgress};
};

/**
 * Submits the Approval Request on Customer Information submit
 * @param taskId Task ID for the flow
 * @param isUpdate Whether action is update or new
 * @returns Approval Request status
 */
export const useCreateApprovalRequestOnCustomerInformationSubmit = (taskId: string, isUpdate?: boolean) => {
  const queryParams = useMemo(() => {
    if (!taskId) return;
    return {
      taskId: taskId,
      moduleForApproval: APPROVAL_MODULES.CUSTOMER
    };
  }, [taskId]);

  const {isLoading: isSubmitRequestLoading, data: submitResponse} = useFetchV2(
    {route: submitRequestQuery.route, params: queryParams},
    submitRequestQuery.transform
  );
  return {isSubmitRequestLoading, submitResponse};
};

/**
 * Submits the Approval Request on Service Pan Information submit
 * @param taskId Task ID for the flow
 * @param isUpdate Whether action is update or new
 * @returns Approval Request status
 */
export const useCreateApprovalRequestOnServicePlanSubmit = (taskId: string, isUpdate?: boolean) => {
  const queryParams = useMemo(() => {
    if (!taskId) return;
    return {
      taskId: taskId,
      moduleForApproval: APPROVAL_MODULES.SERVICE_PLAN
    };
  }, [taskId]);

  const {isLoading: isSubmitRequestLoading, data: submitResponse} = useFetchV2(
    {route: submitRequestQuery.route, params: queryParams},
    submitRequestQuery.transform
  );
  return {isSubmitRequestLoading, submitResponse};
};

/**
 * Hook to fetch list of var account id and name
 *
 * @param {Query<any>} fetchQuery - query to fetch partnumbers
 */

export function useVarAccountFetch(fetchQuery: Query<any>) {
  const [varAccountList, setVarAccountList] = useState<IVarAccount[]>([]);
  const queryParams = useMemo(() => {
    return {};
  }, []);

  const {isLoading: isVarAccountListLoading, data: varAccountInfo} = useFetchV2(
    {route: fetchQuery.route, params: queryParams},
    fetchQuery.transform
  );
  useEffect(() => {
    if (!isVarAccountListLoading && !isNil(varAccountList)) {
      setVarAccountList(varAccountInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVarAccountListLoading, varAccountList]);

  return {isVarAccountListLoading, varAccountList};
}

/**
 * Custom hook to handle the success messages of Choose or Change Service Plan
 * @param breadcrumb Breadcrumb
 * @param setIsLoading Set Loading Indicator state
 * @param inProgress Initial request is in progress
 * @param isSubmitRequestLoading Approval Request creation in progress
 * @param queryParams Request Query Params
 * @param submitResponse Approval Request endpoint response
 * @param servicePlanInfo Service Plan add/update endpoint response
 */
export const useServicePlanRequestResponseHandler = (
  setIsLoading: (isLoading: boolean) => void,
  inProgress: boolean,
  isSubmitRequestLoading: boolean,
  queryParams: any,
  submitResponse: any,
  servicePlanInfo: any
) => {
  const navigate = useNavigate();
  const {dispatch, store} = useStore();
  const {breadcrumb} = store.app;
  useEffect(() => {
    setIsLoading((inProgress || isSubmitRequestLoading) && !isNil(queryParams));
    if (inProgress || isNil(servicePlanInfo) || isSubmitRequestLoading || isNil(submitResponse)) return;

    let operation,
      message,
      details = '';
    if (servicePlanInfo.updated && servicePlanInfo.updated === 1) {
      operation = 'updated';
      message = 'Service plan information has been';
      details = 'Service plan information has been sent to Viasat MCC. ';
    } else {
      operation = 'added';
      message = 'New service plan information has been';
      details = 'New service plan information has been sent to Viasat MCC. ';
    }
    dispatch({
      type: AppAction.SET_BREADCRUMB,
      payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
    });
    dispatch({
      type: AppAction.SET_ALERT_INFO,
      payload: {
        showAlert: true,
        operation: operation,
        message: message,
        details: details
      }
    });
    navigate('/main-menu');
    //eslint-disable-next-line
  }, [dispatch, inProgress, servicePlanInfo, isSubmitRequestLoading, submitResponse]);
};

interface IWarrantyExtensionFields {
  commonWarrantyDate: string;
  kaAntennaWarrantyEndDate?: string;
  kaAntennaWarrantyStartDate?: string;
  kaApsuWarrantyEndDate?: string;
  kaApsuWarrantyStartDate?: string;
  kaModemWarrantyEndDate?: string;
  kaModemWarrantyStartDate?: string;
  kaFantrayWarrantyEndDate?: string;
  kaRouterWarrantyEndDate?: string;
  kuAntennaWarrantyEndDate?: string;
  kuModemWarrantyEndDate?: string;
  kuAcuWarrantyEndDate?: string;
  kuRouterWarrantyEndDate?: string;
}
/**
 * Custom hook to handle Equipment Warranty Extension
 * @param warrantyInfo - Warranty Start/End Dates of equipment
 * @param setWarrantyInfo - Set Warranty Start/End Dates of equipment
 */
export const useEquipmentWarrantyExtension = (
  warrantyInfo: IWarrantyExtensionFields,
  setWarrantyInfo: (warrantyInfo: IWarrantyExtensionFields) => void
) => {
  const {dispatch, store} = useStore();
  const navigate = useNavigate();
  const aircraftInfo = store.aircraftInfo;
  const equipmentInfo = store.equipmentInfo;
  const kaAntennaInfo = equipmentInfo.kaAntenna;
  const kaModemInfo = equipmentInfo.kaModem;
  const kuAntennaInfo = equipmentInfo.kuAntenna;
  const kuModemInfo = equipmentInfo.kuModem;
  const apsuInfo = equipmentInfo.apsu;
  const acuInfo = equipmentInfo.acu;
  const fanTrayInfo = equipmentInfo.fanTray;
  const routerInfo = equipmentInfo.router;
  useEffect(() => {
    if (!warrantyInfo) return;
    setWarrantyInfo(warrantyInfo);
    const configItems = [];
    const warrantyEndDate = warrantyInfo.commonWarrantyDate;
    const kaWarrantyInfo = {};
    const kuWarrantyInfo = {};

    if (warrantyInfo.kaAntennaWarrantyEndDate) {
      const kaAntennaExtendedWarrantyEndDate = !isEmpty(warrantyEndDate)
        ? warrantyEndDate
        : warrantyInfo.kaAntennaWarrantyEndDate;
      configItems.push({
        configItemId: kaAntennaInfo.configItemId,
        configItemType: 'Antenna',
        warrantyStart: kaAntennaInfo.warrantyStartDate,
        actualWarrantyEnd: kaAntennaInfo.warrantyEndDate,
        extendedWarrantyEnd: kaAntennaExtendedWarrantyEndDate
      });
      kaWarrantyInfo['antennaWarrantyEndDate'] = kaAntennaExtendedWarrantyEndDate
        ? formatDateTimeInputAsDateMonthYearNumber(kaAntennaExtendedWarrantyEndDate)
        : null;
    }
    if (warrantyInfo.kaModemWarrantyEndDate) {
      const kaModemExtendedWarrantyEndDate = !isEmpty(warrantyEndDate)
        ? warrantyEndDate
        : warrantyInfo.kaModemWarrantyEndDate;
      configItems.push({
        configItemId: kaModemInfo.configItemId,
        configItemType: 'Modem',
        warrantyStart: kaModemInfo.warrantyStartDate,
        actualWarrantyEnd: kaModemInfo.warrantyEndDate,
        extendedWarrantyEnd: kaModemExtendedWarrantyEndDate
      });
      kaWarrantyInfo['modemWarrantyEndDate'] = kaModemExtendedWarrantyEndDate
        ? formatDateTimeInputAsDateMonthYearNumber(kaModemExtendedWarrantyEndDate)
        : null;
    }
    if (warrantyInfo.kaApsuWarrantyEndDate) {
      const kaApsuExtendedWarrantyEndDate = !isEmpty(warrantyEndDate)
        ? warrantyEndDate
        : warrantyInfo.kaApsuWarrantyEndDate;
      configItems.push({
        configItemId: apsuInfo.configItemId,
        configItemType: 'Power Supply',
        warrantyStart: apsuInfo.warrantyStartDate,
        actualWarrantyEnd: apsuInfo.warrantyEndDate,
        extendedWarrantyEnd: kaApsuExtendedWarrantyEndDate
      });
      kaWarrantyInfo['apsuWarrantyEndDate'] = kaApsuExtendedWarrantyEndDate
        ? formatDateTimeInputAsDateMonthYearNumber(kaApsuExtendedWarrantyEndDate)
        : null;
    }
    if (warrantyInfo.kaFantrayWarrantyEndDate) {
      const kaFantrayExtendedWarrantyEndDate = !isEmpty(warrantyEndDate)
        ? warrantyEndDate
        : warrantyInfo.kaFantrayWarrantyEndDate;
      configItems.push({
        configItemId: fanTrayInfo.configItemId,
        configItemType: 'Fan Tray',
        warrantyStart: fanTrayInfo.warrantyStartDate,
        actualWarrantyEnd: fanTrayInfo.warrantyEndDate,
        extendedWarrantyEnd: kaFantrayExtendedWarrantyEndDate
      });
      kaWarrantyInfo['fantrayWarrantyEndDate'] = kaFantrayExtendedWarrantyEndDate
        ? formatDateTimeInputAsDateMonthYearNumber(kaFantrayExtendedWarrantyEndDate)
        : null;
    }
    if (warrantyInfo.kaRouterWarrantyEndDate) {
      const kaRouterExtendedWarrantyEndDate = !isEmpty(warrantyEndDate)
        ? warrantyEndDate
        : warrantyInfo.kaRouterWarrantyEndDate;
      configItems.push({
        configItemId: routerInfo.configItemId,
        configItemType: 'Router',
        warrantyStart: routerInfo.warrantyStartDate,
        actualWarrantyEnd: routerInfo.warrantyEndDate,
        extendedWarrantyEnd: kaRouterExtendedWarrantyEndDate
      });
      kaWarrantyInfo['routerWarrantyEndDate'] = kaRouterExtendedWarrantyEndDate
        ? formatDateTimeInputAsDateMonthYearNumber(kaRouterExtendedWarrantyEndDate)
        : null;
    }
    if (warrantyInfo.kuAntennaWarrantyEndDate) {
      const kuAntennaExtendedWarrantyEndDate = !isEmpty(warrantyEndDate)
        ? warrantyEndDate
        : warrantyInfo.kuAntennaWarrantyEndDate;
      configItems.push({
        configItemId: kuAntennaInfo.configItemId,
        configItemType: 'Antenna',
        warrantyStart: kuAntennaInfo.warrantyStartDate,
        actualWarrantyEnd: kuAntennaInfo.warrantyEndDate,
        extendedWarrantyEnd: kuAntennaExtendedWarrantyEndDate
      });
      kuWarrantyInfo['antennaWarrantyEndDate'] = kuAntennaExtendedWarrantyEndDate
        ? formatDateTimeInputAsDateMonthYearNumber(kuAntennaExtendedWarrantyEndDate)
        : null;
    }
    if (warrantyInfo.kuModemWarrantyEndDate) {
      const kuModemExtendedWarrantyEndDate = !isEmpty(warrantyEndDate)
        ? warrantyEndDate
        : warrantyInfo.kuModemWarrantyEndDate;
      configItems.push({
        configItemId: kuModemInfo.configItemId,
        configItemType: 'Modem',
        warrantyStart: kuModemInfo.warrantyStartDate,
        actualWarrantyEnd: kuModemInfo.warrantyEndDate,
        extendedWarrantyEnd: kuModemExtendedWarrantyEndDate
      });
      kuWarrantyInfo['modemWarrantyEndDate'] = kuModemExtendedWarrantyEndDate
        ? formatDateTimeInputAsDateMonthYearNumber(kuModemExtendedWarrantyEndDate)
        : null;
    }
    if (warrantyInfo.kuAcuWarrantyEndDate) {
      const kuAcuExtendedWarrantyEndDate = !isEmpty(warrantyEndDate)
        ? warrantyEndDate
        : warrantyInfo.kuAcuWarrantyEndDate;
      configItems.push({
        configItemId: acuInfo.configItemId,
        configItemType: 'ACU',
        warrantyStart: acuInfo.warrantyStartDate,
        actualWarrantyEnd: acuInfo.warrantyEndDate,
        extendedWarrantyEnd: kuAcuExtendedWarrantyEndDate
      });
      kuWarrantyInfo['acuWarrantyEndDate'] = kuAcuExtendedWarrantyEndDate
        ? formatDateTimeInputAsDateMonthYearNumber(kuAcuExtendedWarrantyEndDate)
        : null;
    }
    if (warrantyInfo.kuRouterWarrantyEndDate) {
      const kuRouterExtendedWarrantyEndDate = !isEmpty(warrantyEndDate)
        ? warrantyEndDate
        : warrantyInfo.kuRouterWarrantyEndDate;
      configItems.push({
        configItemId: routerInfo.configItemId,
        configItemType: 'Router',
        warrantyStart: routerInfo.warrantyStartDate,
        actualWarrantyEnd: routerInfo.warrantyEndDate,
        extendedWarrantyEnd: kuRouterExtendedWarrantyEndDate
      });
      kuWarrantyInfo['routerWarrantyEndDate'] = kuRouterExtendedWarrantyEndDate
        ? formatDateTimeInputAsDateMonthYearNumber(kuRouterExtendedWarrantyEndDate)
        : null;
    }
    dispatch({
      type: EquipmentInfoAction.SET_WARRANTY_INFO,
      payload: {
        serialNumber: aircraftInfo.serialNumber,
        tailId: aircraftInfo.tailId,
        siteId: aircraftInfo.siteId,
        configItems: configItems,
        ka: kaWarrantyInfo,
        ku: kuWarrantyInfo
      }
    });
    navigate('../warranty-extension-reason');

    // eslint-disable-next-line
  }, [warrantyInfo]);
};

export interface IApprovalProcessSteps {
  label: string;
  status: string;
  description: string;
}

/**
 * Find and update the aproval step item with latest status change
 * @param approvalProcessSteps - Approval Process steps
 * @param label - Approval step label
 * @param status - Approval step status
 * @param description - Approval step description
 * @return Returns the updated approval steps list based on status update
 */
const findAndUpdateApprovalProcessItem = (
  approvalProcessSteps: IApprovalProcessSteps[],
  label: string,
  status: string,
  description: string
) => {
  let result = [];
  if (approvalProcessSteps) {
    const objectToUpdateIndex = approvalProcessSteps.findIndex(obj => obj.label.includes(label));

    result = approvalProcessSteps.map((obj, index) => {
      if (index === objectToUpdateIndex) {
        return {...obj, status: status, description: description};
      }
      return obj;
    });
  }
  return result;
};

/**
 * Custom hook to Update Approval Process step status
 * @param errorConditionCheck - Condition to check error
 * @param successConditionCheck - Condition to check success
 * @param label - Approval step label
 * @param approvalProcessSteps - Approval Process steps
 * @param setApprovalProcessSteps - State to set Approval Process steps
 */
export const useApprovalProcessStepsUpdate = (
  errorConditionCheck: boolean,
  successConditionCheck: boolean,
  label: string,
  approvalProcessSteps: IApprovalProcessSteps[],
  setApprovalProcessSteps: any
) => {
  useEffect(() => {
    if (errorConditionCheck) {
      const updatedApprovalSteps = findAndUpdateApprovalProcessItem(
        approvalProcessSteps,
        label,
        APPROVAL_PROCESS_STATUS.ERROR,
        APPROVAL_PROCESS_DESCRIPTION.FAILED
      );
      setApprovalProcessSteps(updatedApprovalSteps);
    } else if (successConditionCheck) {
      const updatedApprovalSteps = findAndUpdateApprovalProcessItem(
        approvalProcessSteps,
        label,
        APPROVAL_PROCESS_STATUS.SUCCESS,
        APPROVAL_PROCESS_DESCRIPTION.COMPLETED
      );
      setApprovalProcessSteps(updatedApprovalSteps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorConditionCheck, successConditionCheck]);

  return approvalProcessSteps;
};
