/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Update Equipment Information
 */

import {Query} from '../types';

export const updateKuModemInformation: Query<any> = {
  route: '/newInstall/equipment/kuModem',
  transform: null
};

export const updateKaModemInformation: Query<any> = {
  route: '/newInstall/equipment/kaModem',
  transform: null
};

export const updateKuAntennaInformation: Query<any> = {
  route: '/newInstall/equipment/kuAntenna',
  transform: null
};

export const updateKaAntennaInformation: Query<any> = {
  route: '/newInstall/equipment/kaAntenna',
  transform: null
};

export const updateAPSUInformation: Query<any> = {
  route: '/newInstall/equipment/apsu',
  transform: null
};

export const updateAcuInformation: Query<any> = {
  route: '/newInstall/equipment/acu',
  transform: null
};

export const updateFanTrayInformation: Query<any> = {
  route: '/newInstall/equipment/fanTray',
  transform: null
};

export const updateRouterInformation: Query<any> = {
  route: '/newInstall/equipment/router',
  transform: null
};
