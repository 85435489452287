/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Types
 */

import {Dispatch} from 'react';

import {InitState} from './reducers/InitReducer';
import {AppState} from './reducers/AppReducer';
import {CustomerState} from './reducers/CustomerReducer';
import {EquipmentInfoState} from './reducers/EquipmentInfoReducer';
import {AircraftInfoState} from './reducers/AircraftInfoReducer';
import {OpenTasksState} from './reducers/OpenTasksReducer';
import {ApprovalState} from './reducers/ApprovalReducer';
import {ILookupsState} from './reducers/LookupsReducer';
import {ServicePlanState} from './reducers/ServicePlanReducer';
import {ICustomerInfoState} from './reducers/CustomerInfoReducer';
import {AircraftModelListState} from './reducers/AircraftModelListReducer';
import {KaBandSwVersionsListState} from './reducers/KaBandSwVersionsReducer';
import {KuBandSwVersionsListState} from './reducers/KuBandSwVersionsReducer';
import {MapBundleVersionsListState} from './reducers/MapBundleVersionsReducer';
import {RouterTypesListState} from './reducers/RouterTypesReducer';
import {ServicePlansListState} from './reducers/ServicePlansListReducer';
import {ServicePlanTermsListState} from './reducers/ServicePlanTermsListReducer';
import {StatusState} from './reducers/StatusReducer';
import {WarrantyTermsListState} from './reducers/WarrantyTermsListReducer';

export enum CommonAction {
  PROCESS_URL_PARAMS = 'PROCESS_URL_PARAMS'
}

export interface IStore {
  openTasksInfo: OpenTasksState;
  approvalInfo: ApprovalState;
  init: InitState;
  app: AppState;
  customer: CustomerState;
  equipmentInfo: EquipmentInfoState;
  aircraftInfo: AircraftInfoState;
  lookups: ILookupsState;
  servicePlan: ServicePlanState;
  customerInfo: ICustomerInfoState;
  aircraftModelList: AircraftModelListState;
  kaBandSwVersionsList: KaBandSwVersionsListState;
  kuBandSwVersionsList: KuBandSwVersionsListState;
  mapBundleVersionsList: MapBundleVersionsListState;
  routerTypesList: RouterTypesListState;
  servicePlansList: ServicePlansListState;
  servicePlanTermsList: ServicePlanTermsListState;
  statusInfo: StatusState;
  warrantyTermsList: WarrantyTermsListState;
}

export interface IAction {
  type: string;
  payload?: unknown;
}

export interface UrlParamsPayload {
  path: string;
  params: any;
}

export interface IContext {
  store: IStore;
  dispatch: Dispatch<IAction>;
}

export interface IStoreProvider {
  reducer: any;
  children: any;
}
