/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Part Number List View
 */
import React, {useMemo, useState} from 'react';
import {PageProps} from '../../common/layout/PageProps';
import useFetch from '../../../utils/useFetch';
import {getPartNumbersQuery} from '../../../store/queries/lookupQueries';
import {IPartNumber} from '../../../store/queries/lookupTypes';
import AddPartNumber from './forms/AddPartNumber';
import SnackBar from '../../common/SnackBar';
import {useStore} from '../../../store/Store';
import {LookupsListAction} from '../../../store/reducers/LookupsReducer';
import {buildPartNumberListColumns} from './lookupTableUtils';
import ListViewComponent from '../ListViewComponent';
import RetireLookupRecord, {RetireLookupType} from './forms/RetireLookupRecord';
import {useRefreshLookupList, useResetSelectedLookup} from '../../../utils/customHooks';
import LookupHeaderAction from './LookupListHeaderActions';
import AccessControl from '../../common/accessControl/AccessControl';

const ID_PREFIX = 'partNumberList';

interface PartNumberListRow extends IPartNumber {
  isSelected?: boolean;
  isDisabled?: boolean;
  isRowHighlighted?: boolean;
}

const PartNumberList: React.FC<PageProps> = () => {
  const {store, dispatch} = useStore();
  const {isAdmin} = store.init;
  const {newRow, deactivatedRow, refreshList, selectedLookups} = store.lookups;
  const {snackbar} = store.app;
  const [openAddPartNumber, setOpenAddPartNumber] = useState<boolean>(false);
  const [retirePartNumber, setRetirePartNumber] = useState<boolean>(false);

  useResetSelectedLookup();
  useRefreshLookupList(refreshList);

  function handleRowSelection(selectedLookup: any) {
    dispatch({
      type: LookupsListAction.SELECTED_LOOKUP_ROWS,
      payload: selectedLookup
    });
  }

  const handleRowDeselection = (removedLookup: any) => {
    dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: removedLookup});
  };

  const partNumbersListColumns = buildPartNumberListColumns(isAdmin).map(col => {
    if (col.key === 'id') {
      col.handleRowSelection = handleRowSelection;
      col.handleRowDeselection = handleRowDeselection;
    }
    return col;
  });

  const queryParams = useMemo(() => ({newRow, deactivatedRow, refreshList, doCache: false}), [newRow, deactivatedRow, refreshList]);
  const {isLoading: isPartNumbersLoading, data: partNumbersInfo} = useFetch<IPartNumber[]>(
    getPartNumbersQuery,
    queryParams
  );

  const partNumberTotal = useMemo(
    () => (isPartNumbersLoading ? 0 : partNumbersInfo?.length),
    [partNumbersInfo, isPartNumbersLoading]
  );

  const partNumberDataWithSelections: PartNumberListRow[] = partNumbersInfo
    ? [...partNumbersInfo].map(row => {
        return {
          ...row,
          isSelected: selectedLookups.some((selectedRow: IPartNumber) => selectedRow.id === row.id),
          isRowHighlighted: row.status === 'Retired'
        };
      })
    : null;

  return (
    <>
      <ListViewComponent
        idPrefix={ID_PREFIX}
        isLoading={isPartNumbersLoading}
        headerActionButton={[
          <AccessControl permitted={isAdmin}>
            <LookupHeaderAction
              isLoading={isPartNumbersLoading}
              selectedLookups={selectedLookups}
              setOpenAddAircraftModel={setOpenAddPartNumber}
              setRetireAircraftModel={setRetirePartNumber}
              idPrefix={ID_PREFIX}
              isRetire={true}
              allowRetireDeactive={true}
            />
          </AccessControl>
        ]}
        listColumns={partNumbersListColumns}
        listData={partNumberDataWithSelections}
        listTotal={partNumberTotal}
        listContainerHeight={'55px'}
        arePrereqsLoading={isPartNumbersLoading}
        handleRowSelection={handleRowSelection}
        handleRowDeselection={handleRowDeselection}
        rowSelectionKey={'id'}
        rowDisableKey={'id'}
        selectedRows={selectedLookups}
      />
      {openAddPartNumber ? (
        <AddPartNumber
          openPanel={openAddPartNumber}
          setOpenPanel={setOpenAddPartNumber}
          panelData={selectedLookups[0]}
          isUpdate={selectedLookups.length === 1}
        ></AddPartNumber>
      ) : (
        <></>
      )}
      {retirePartNumber && (
        <RetireLookupRecord
          retireLookupType={RetireLookupType.RETIRE}
          openRetireLookupModal={retirePartNumber}
          retireLookupQueryRoute={'lookups/deactivate/partNumber/' + selectedLookups[0]?.id}
          recordDisplayName={'Part Number'}
          setOpenRetireLookupModal={setRetirePartNumber}
        />
      )}
      {snackbar ? <SnackBar id={`${ID_PREFIX}--snackbar`} openSnack={snackbar.openSnack} snackMessage={snackbar.snackMessage} /> : <></>}
    </>
  );
};

export default PartNumberList;
