/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add New Part Number in Lookup Table
 *
 */
import React, {useMemo, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {AddForm, AddFormHeader, DrawerButton, ButtonContainer, FormContainer} from '../LookupTableStyles';
import {addPartNumberQuery, updatePartNumberQuery} from '../../../../store/queries/lookupQueries';
import {SideDrawerProps, togglePanel, handleBackdropClick, InputProps} from '../lookupTableUtils';
import {useStore} from '../../../../store/Store';
import {AppAction} from '../../../../store/reducers/AppReducer';
import {LookupsListAction} from '../../../../store/reducers/LookupsReducer';
import FormComponent, {FormField, FormFieldType} from '../../../common/elements/Form';
import {NETWORK_OPTIONS} from '../../../common/constants';
import {validateDropdownField} from '../../../../utils/validationUtils';
import useFetchV2 from '../../../../utils/useFetchV2';

const ID_PREFIX = 'partNumber';

interface IFormInput {
  viasatPartNumber: string;
  commonProductDescription: string;
  viasatPartNumberDescription: string;
  equipmentType: string;
  network: string;
  serialNumberFormat: string;
  serialNumberFormatDescription: string;
  notes: string;
}

const AddPartNumber: React.FC<SideDrawerProps> = ({openPanel, setOpenPanel, panelData, isUpdate}) => {
  const {dispatch} = useStore();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
        color: '#465967'
      }
    }
  };
  const [newPartNumberInfo, setNewPartNumberInfo] = useState<any>(null);
  const [updatePartNumberInfo, setUpdatePartNumberInfo] = useState<any>(null);
  let initialValues: IFormInput = {
    viasatPartNumber: '',
    commonProductDescription: '',
    viasatPartNumberDescription: '',
    equipmentType: '',
    network: '',
    serialNumberFormat: '',
    serialNumberFormatDescription: ' ',
    notes: ''
  };
  if (isUpdate) initialValues = panelData;

  const partNumberFormFields: FormField[] = [
    {
      id: 'viasatPartNumber',
      label: 'Viasat Part Number',
      type: FormFieldType.INPUT,
      value: initialValues.viasatPartNumber,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'commonProductDescription',
      label: 'Common Product Description',
      type: FormFieldType.INPUT,
      value: initialValues.commonProductDescription,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'viasatPartNumberDescription',
      label: 'Viasat Part Number Description',
      type: FormFieldType.INPUT,
      value: initialValues.viasatPartNumberDescription,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'equipmentType',
      label: 'Equipment Type',
      type: FormFieldType.INPUT,
      value: initialValues.equipmentType,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'network',
      label: 'Network',
      type: FormFieldType.SELECT,
      value: initialValues.network,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: NETWORK_OPTIONS,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Network')
      }
    },
    {
      id: 'serialNumberFormat',
      label: 'Serial Number Format',
      type: FormFieldType.INPUT,
      value: initialValues.serialNumberFormat,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'serialNumberFormatDescription',
      label: 'Serial Number Format Description',
      type: FormFieldType.INPUT,
      value: initialValues.serialNumberFormatDescription,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'notes',
      label: 'Notes',
      type: FormFieldType.TEXTFIELD,
      value: initialValues.notes,
      disabled: false,
      inputProps: InputProps,
      isMultiline: true,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  const {handleSubmit, formState, unregister, reset, watch, control} = useForm();

  const addQueryParams = useMemo(() => {
    if (
      newPartNumberInfo &&
      (!newPartNumberInfo.viasatPartNumber ||
        !newPartNumberInfo.commonProductDescription ||
        !newPartNumberInfo.viasatPartNumberDescription ||
        !newPartNumberInfo.equipmentType ||
        !newPartNumberInfo.network ||
        !newPartNumberInfo.serialNumberFormat)
    )
      return;
    return newPartNumberInfo;
  }, [newPartNumberInfo]);

  const updateQueryParams = useMemo(() => {
    if (
      isUpdate &&
      updatePartNumberInfo &&
      (!updatePartNumberInfo.viasatPartNumber ||
        !updatePartNumberInfo.commonProductDescription ||
        !updatePartNumberInfo.viasatPartNumberDescription ||
        !updatePartNumberInfo.equipmentType ||
        !updatePartNumberInfo.network ||
        !updatePartNumberInfo.serialNumberFormat)
    )
      return;
    return updatePartNumberInfo;
  }, [updatePartNumberInfo, isUpdate]);

  const {isLoading: addInProgress, data: addNewPartNumberInfo} = useFetchV2(
    {route: addPartNumberQuery.route, params: addQueryParams},
    addPartNumberQuery.transform
  );
  const {isLoading: updateInProgress, data: updatedPartNumberInfo} = useFetchV2(
    {route: updatePartNumberQuery(panelData?.id).route, params: updateQueryParams},
    updatePartNumberQuery(panelData?.id).transform
  );

  const onCancelClick = () => {
    unregister();
    reset(
      {
        viasatPartNumber: '',
        commonProductDescription: '',
        viasatPartNumberDescription: '',
        equipmentType: '',
        network: '',
        serialNumberFormat: '',
        notes: ''
      },
      {
        keepValues: false
      }
    );
    setOpenPanel(false);
  };

  const handleOpen = () => {
    togglePanel(true);
    setOpenPanel(true);
  };

  const handleClose = () => {
    if (localStorage.getItem('backdropClick') === 'true') {
      localStorage.setItem('backdropClick', 'false');
      return;
    }
    togglePanel(false);
    setOpenPanel(false);
  };

  useEffect(() => {
    if (addInProgress) return;
    if (addNewPartNumberInfo) {
      setOpenPanel(false);
      dispatch({
        type: LookupsListAction.SET_NEW_ROW_COUNT,
        payload: 1
      });
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'New part number entry added.'}
      });
    }
    //eslint-disable-next-line
  }, [addInProgress, addNewPartNumberInfo]);

  useEffect(() => {
    if (updateInProgress) return;
    if (updatedPartNumberInfo) {
      setOpenPanel(false);
      dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: panelData});
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'Part number data updated.'}
      });
    }
    //eslint-disable-next-line
  }, [updateInProgress, updatedPartNumberInfo]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={openPanel}
        onOpen={handleOpen}
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
      >
        <AddForm>
          <AddFormHeader id={`${ID_PREFIX}--partNumberListTitle`}>ADD NEW PART NUMBER INFO</AddFormHeader>
          <FormContainer>
            <FormComponent
              id="add-part-number-form"
              formFields={partNumberFormFields}
              setFormData={!isUpdate ? setNewPartNumberInfo : setUpdatePartNumberInfo}
              formControlMethods={{handleSubmit, formState, watch, control}}
              idPrefix={ID_PREFIX}
            ></FormComponent>
          </FormContainer>
          <ButtonContainer>
            <DrawerButton id={`${ID_PREFIX}-cancel-button`} className="cancel-button" onClick={onCancelClick}>
              <span className="button-text">Cancel</span>
            </DrawerButton>
            <DrawerButton
              form="add-part-number-form"
              id={`${ID_PREFIX}-add-button`}
              className="add-button"
              onClick={() => null}
            >
              <span className="button-text">{!isUpdate ? 'Add' : 'Update'}</span>
            </DrawerButton>
          </ButtonContainer>
        </AddForm>
      </SwipeableDrawer>
    </>
  );
};

export default AddPartNumber;
