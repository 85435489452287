/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: get Equipment Info query
 */

 import {Query} from './types';

 export interface IEquipmentInformation {
    tailId: string;
    serialNumber: string;
    routerInfo: string;
    acuInfo: string;
    apsuInfo: string;
    fanTrayInfo: string;
    kaAntennaInfo: string;
    kaModemInfo: string;
    kuAntennaInfo: string;
    kuModemInfo: string;
    varAccountName: string;
  }

 export const getEquipmentInfoQuery: Query<IEquipmentInformation> = {
    route: '/equipment/getEquipmentInfo',
    transform: null
  };
