/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Router Type List View
 */
import React, {useEffect, useMemo, useState} from 'react';
import {PageProps} from '../../common/layout/PageProps';
import useFetch from '../../../utils/useFetch';
import {getRouterTypesQuery} from '../../../store/queries/lookupQueries';
import {IRouterType} from '../../../store/queries/lookupTypes';
import AddRouterType from './forms/AddRouterType';
import SnackBar from '../../common/SnackBar';
import {useStore} from '../../../store/Store';
import {LookupsListAction} from '../../../store/reducers/LookupsReducer';
import {LookupListColumnId, buildRouterTypeListColumns, dynamicSort, getQueryFieldMapping} from './lookupTableUtils';
import ListViewComponent from '../ListViewComponent';
import RetireLookupRecord, {RetireLookupType} from './forms/RetireLookupRecord';
import {useResetSelectedLookup, useRefreshLookupList} from '../../../utils/customHooks';
import LookupHeaderAction from './LookupListHeaderActions';
import AccessControl from '../../common/accessControl/AccessControl';
import ListViewSortContainer from '../ListViewSortContainer';
import {ROUTER_TYPES_LIST_STORE_CONTEXT} from '../../../store/reducers/RouterTypesReducer';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {ListGridColumn} from '@viasat/insights-components';

const ID_PREFIX = 'routerTypeList';

interface RouterTypeListRow extends IRouterType {
  isSelected?: boolean;
  isDisabled?: boolean;
  isRowHighlighted?: boolean;
}

const RouterTypeList: React.FC<PageProps> = () => {
  const {store, dispatch} = useStore();
  const {snackbar} = store.app;
  const {isAdmin} = store.init;
  const {sort} = store.routerTypesList;
  const {newRow, deactivatedRow, refreshList, selectedLookups} = store.lookups;
  const [openAddRouterType, setOpenAddRouterType] = useState<boolean>(false);
  const [routerTypeListData, setRouterTypeListData] = useState<RouterTypeListRow[]>(null);
  const [retireRouterType, setRetireRouterType] = useState<boolean>(false);

  useResetSelectedLookup();
  useRefreshLookupList(refreshList);

  function handleRowSelection(selectedLookup: any) {
    dispatch({
      type: LookupsListAction.SELECTED_LOOKUP_ROWS,
      payload: selectedLookup
    });
  }

  const handleRowDeselection = (removedLookup: any) => {
    dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: removedLookup});
  };

  const routerTypesListColumns = buildRouterTypeListColumns(isAdmin).map(col => {
    if (col.key === 'id') {
      col.handleRowSelection = handleRowSelection;
      col.handleRowDeselection = handleRowDeselection;
    }
    return col;
  });

  const queryParams = useMemo(() => ({newRow, deactivatedRow, refreshList, doCache: false}), [newRow, deactivatedRow, refreshList]);
  const {isLoading: isRouterTypesLoading, data: routerTypesInfo} = useFetch<IRouterType[]>(
    getRouterTypesQuery,
    queryParams
  );

  const routerTypeTotal = useMemo(
    () => (isRouterTypesLoading ? 0 : routerTypesInfo?.length),
    [routerTypesInfo, isRouterTypesLoading]
  );

  useEffect(() => {
    if (isRouterTypesLoading) return;
    const routerTypeDataWithSelections: RouterTypeListRow[] = routerTypesInfo
      ? [...routerTypesInfo].map(row => {
          return {
            ...row,
            isSelected: selectedLookups.some((selectedRow: IRouterType) => selectedRow.id === row.id),
            isRowHighlighted: row.status === 'Retired'
          };
        })
      : null;
    setRouterTypeListData(routerTypeDataWithSelections.sort(dynamicSort(sort.column, sort.order)));
  }, [routerTypesInfo, isRouterTypesLoading, selectedLookups, sort.column, sort.order]);

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(LookupListColumnId[columnId]);

  const handleSortChange = ({column, order}: {column: string; order: string}) => {
    setRouterTypeListData(routerTypeListData.sort(dynamicSort(column, order)));
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, ROUTER_TYPES_LIST_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(routerTypesListColumns[column]),
        validColumns: routerTypesListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: routerTypesListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(routerTypesListColumns[col.key])
        )
      }
    });
  };
  return (
    <>
      <ListViewComponent
        idPrefix={ID_PREFIX}
        isLoading={isRouterTypesLoading}
        headerActionButton={[
          <AccessControl permitted={isAdmin}>
            <LookupHeaderAction
              isLoading={isRouterTypesLoading}
              selectedLookups={selectedLookups}
              setOpenAddAircraftModel={setOpenAddRouterType}
              setRetireAircraftModel={setRetireRouterType}
              idPrefix={ID_PREFIX}
              isRetire={true}
              allowRetireDeactive={true}
            />
          </AccessControl>,
          <ListViewSortContainer
            key="sorts"
            idPrefix={ID_PREFIX}
            disabled={isRouterTypesLoading}
            storeContext={ROUTER_TYPES_LIST_STORE_CONTEXT}
            columns={routerTypesListColumns}
            showColumnGroups={true}
            getQueryFieldForColumn={getQueryFieldForColumn}
          />
        ]}
        listColumns={routerTypesListColumns}
        listData={!isRouterTypesLoading && routerTypeListData ? routerTypeListData : []}
        listTotal={routerTypeTotal}
        listContainerHeight={'55px'}
        arePrereqsLoading={isRouterTypesLoading}
        handleRowSelection={handleRowSelection}
        handleRowDeselection={handleRowDeselection}
        handleSortChange={handleSortChange}
        selectedSort={sort}
        rowSelectionKey={'id'}
        rowDisableKey={'id'}
        selectedRows={selectedLookups}
      />
      {openAddRouterType ? (
        <AddRouterType
          openPanel={openAddRouterType}
          setOpenPanel={setOpenAddRouterType}
          panelData={selectedLookups[0]}
          isUpdate={selectedLookups.length === 1}
        ></AddRouterType>
      ) : (
        <></>
      )}
      {retireRouterType && (
        <RetireLookupRecord
          retireLookupType={RetireLookupType.RETIRE}
          openRetireLookupModal={retireRouterType}
          retireLookupQueryRoute={'lookups/deactivate/routerType/' + selectedLookups[0]?.id}
          recordDisplayName={'Router Type'}
          setOpenRetireLookupModal={setRetireRouterType}
        />
      )}
      {snackbar ? (
        <SnackBar id={`${ID_PREFIX}--snackbar`} openSnack={snackbar.openSnack} snackMessage={snackbar.snackMessage} />
      ) : (
        <></>
      )}
    </>
  );
};

export default RouterTypeList;
