/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Main Menu utils
 *
 */

import {isNil} from 'lodash';
import {DEFAULT_GROUP_CODE, IMainMenu} from '../common/constants';
import {StatusState} from '../../store/reducers/StatusReducer';
import {COMPLETED_OPTION_TEXT_COLOR, IN_PROGRESS_TEXT_COLOR} from '../common/theme/Colors';

/**
 * Determines the logged in internal user as external user based on the current group code
 * @param groupCode Current Group Code Selected
 * @param isInternal Whether logged in user is an internal or external
 * @returns True or False
 */
export const detectInternalUserAsExternal = (groupCode: string, isInternal: boolean): boolean =>
  groupCode !== DEFAULT_GROUP_CODE && isInternal;
/**
 * Generates the list of main menu options based on the use case
 * @param isNewInstall Use case
 * @param isInternal Whether internal user or not
 * @param statusInfo Menu Status
 * @param currentGroupCode Currently selected group code
 * @returns List of Menu and Navigations
 */
export const getMenuOptions = (
  isNewInstall: boolean,
  isInternal: boolean,
  statusInfo: StatusState,
  currentGroupCode: string,
  isAircraftInfoAdded: boolean
): IMainMenu[] => {
  const optionsList: IMainMenu[] = [];

  // This will enable the internal user also to act as an External user
  // and allow them to capture the Customer information similar to a VAR user
  const customerDetailsMenuNavigation = isInternal && !detectInternalUserAsExternal(currentGroupCode, isInternal);

  optionsList.push(
    {
      id: 'edit-aircraft-information',
      name: 'Aircraft Details',
      navigationPath: '/edit-aircraft-information',
      status:
        isAircraftInfoAdded && isNewInstall
          ? 'Added'
          : isAircraftInfoAdded && !isNewInstall
          ? 'Request Submitted'
          : null
    },
    {
      id: 'add-new-terminal',
      name: 'Equipment Details',
      navigationPath: '/new-terminal-installation',
      isDisabled: !isNewInstall,
      status: statusInfo.equipmentInformation
    },
    {
      id: 'replace-hardware',
      name: 'Equipment Replacement',
      navigationPath: '/replace-terminal-equipment',
      isDisabled: isNewInstall
    },
    {
      id: 'add-edit-customer-info',
      name: 'Customer Details',
      navigationPath: customerDetailsMenuNavigation ? '/add-internal-customer' : '/add-external-customer',
      status: statusInfo.customerInformation,
      isDisabled: isNewInstall
    },
    {
      id: 'add-edit-service-plan',
      name: 'Service Plan Options',
      navigationPath:
        isNewInstall && isNil(statusInfo.servicePlanInformation) ? '/choose-service-plan' : '/service-plan-options',
      status: statusInfo.servicePlanInformation,
      isDisabled: isNewInstall
    }
  );
  if (isInternal) {
    optionsList.push({
      id: 'warranty-extension',
      name: 'Warranty Details',
      navigationPath: '/warranty-extension',
      isDisabled: isNewInstall,
      status: null
    });
  }
  return optionsList;
};

/**
 * Gets the color based on status
 * @param context Context of Menu options
 * @param status Request completion status
 * @returns Status based color
 */
export const getStatusColor = (context: string, status: string) => {
  const allStatusColor = {
    'In Progress': context === 'mainMenu' ? IN_PROGRESS_TEXT_COLOR : COMPLETED_OPTION_TEXT_COLOR,
    Completed: COMPLETED_OPTION_TEXT_COLOR,
    'Request Submitted': COMPLETED_OPTION_TEXT_COLOR,
    Added: COMPLETED_OPTION_TEXT_COLOR
  };
  return allStatusColor[status];
};
