/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: get Var Account List query
 */

import {Query} from './types';

export interface IVarAccount {
  varAccountId: string;
  varName: string;
  groupCode: string;
}

export const getVarAccountListQuery: Query<IVarAccount[]> = {
  route: '/common/varAccountList',
  transform: null
};
