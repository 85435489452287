/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Request Details
 */
import React from 'react';
import {DateTime} from 'luxon';
import {capitalize} from 'lodash';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewSection,
  ReviewSubSection
} from './ReviewInformationStyles';
import {renderReviewInformation} from './util';
import {IApprovalDetail, ITaskData} from '../../store/queries/getRequestInformationQuery';

interface IRequestDetail {
  idPrefix: string;
  taskDetail: ITaskData;
  approvalDetail: IApprovalDetail;
}

const RequestDetail: React.FC<IRequestDetail> = ({idPrefix, taskDetail, approvalDetail}) => {
  const requestDate = DateTime.fromISO(approvalDetail.submittedTstamp, {zone: 'utc'}).toLocaleString();

  const requestorFirstName = approvalDetail.submittedBy.split('.')[0];

  var requestorLastName = approvalDetail.submittedBy.split('@')[0].split('.').pop();

  return (
    <ReviewSection>
      <ReviewItemTitle>Request Details</ReviewItemTitle>
      <ReviewItemSubTitle>For communication and coordination during the approval process.</ReviewItemSubTitle>
      <ReviewSubSection>
        <ReviewItems>
          <ReviewItem>
            <ReviewItemLabel>Subject</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__requestDetails--taskTitle`}>{renderReviewInformation(taskDetail.taskTitle)}</ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Date Requested</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__requestDetails--requestDate`}>{requestDate}</ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Requestor</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__requestDetails--requestorName`}>
              {capitalize(renderReviewInformation(requestorFirstName)).concat(' ')}
              {capitalize(renderReviewInformation(requestorLastName))}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Email</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__requestDetails--requestorEmail`}>{renderReviewInformation(approvalDetail.submittedBy)}</ReviewItemValue>
          </ReviewItem>
          {/* Phone number field will be available once we get the AD updated. Until then we will just show -- */}
          <ReviewItem>
            <ReviewItemLabel>Phone</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__requestDetails--requestorPhone`}>--</ReviewItemValue>
          </ReviewItem>
        </ReviewItems>
      </ReviewSubSection>
    </ReviewSection>
  );
};

export default RequestDetail;
