/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 */
import request from 'superagent';
import moment from 'moment';

import {apiBaseUrl} from '../../../utils/config';
import {csvDownload, getRawDataArrayFromBlob} from '../browserUtils';

/**
 * Primary function for processing click of CSV export button
 * @param props Export query props
 * @param columns The set of all columns that could be displayed
 * @param hiddenColumns The columns to be hidden
 * @param onSuccess On success of performing the export
 * @param onError Callback on error
 */
export const handleExportClick = async (
  props: any,
  columns: string[],
  onSuccess: any,
  setDownloadProgressPercentage: (percentage: number) => void,
  onError: (err: string) => void
) => {
  const validColumns = columns;
  try {
    setDownloadProgressPercentage(1);
    const res: request.Response = await request
      .post(`${apiBaseUrl}/openTasks/export`)
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${localStorage.token}`)
      .responseType('arraybuffer')
      .timeout({
        response: 0, // Wait 0 seconds for the server to start sending,
        deadline: 600000 // but allow 10 minutes for the file to finish loading.
      })
      .send({
        ...props,
        columns: validColumns
      });
    downloadCsv(res, moment(props.startTstamp), moment(props.endTstamp));
    setDownloadProgressPercentage(100);
    onSuccess();
  } catch (err) {
    setDownloadProgressPercentage(0);
    onError(err);
  }
};

/**
 * Causes the CSV to download
 * @param response Request response
 * @param startDate start date for the filename
 * @param endDate end date for the filename
 * @return Success handler closure that receives api download response and calls download hooks
 */
export const downloadCsv = (response: request.Response, startDate: moment.Moment, endDate: moment.Moment) => {
  const dateFormat = 'YYYYMMDDHHmm';
  const rawDataArray = getRawDataArrayFromBlob(response.body);
  const filename = `OpenTasks_${startDate.format(dateFormat)}_${endDate.format(dateFormat)}.csv`;
  csvDownload(rawDataArray, filename);
};
