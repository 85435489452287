/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation Wrapper
 */
import {ArrowBack} from '@mui/icons-material';
import React, {useMemo} from 'react';
import {Outlet, useNavigate, useParams, useOutletContext} from 'react-router-dom';
import {MenuBar} from '@viasat/insights-components';
import PageStyled from '../../theme/PageStyled';
import {IReviewInformation, getRequestInformationQuery} from '../../store/queries/getRequestInformationQuery';
import ReviewInfoLoadingBar from './ReviewInfoLoadingBars';

import {useStore} from '../../store/Store';
import useFetchV2 from '../../utils/useFetchV2';
import {ReviewInformationTitle, ReviewInformationWrapperContainer} from './ReviewInformationStyles';

interface ReviewInformationPageProps {
  title: string;
}

export function useApproveRequestInformation() {
  return useOutletContext<IReviewInformation>();
}

const ReviewInformationPage: React.FC<ReviewInformationPageProps> = ({title}) => {
  const idPrefix = 'reviewApprovalInfo';

  const {store} = useStore();
  const {refreshReviewInfo} = store.approvalInfo;

  const navigate = useNavigate();
  const {isAdmin, isInternal} = store.init;

  const {taskId} = useParams();
  const queryParams = useMemo(() => {
    if (!taskId && refreshReviewInfo === -1) return;
    return {taskId, refreshReviewInfo};
  }, [taskId, refreshReviewInfo]);
  const {data: approvalRequestInfo, isLoading: requestInfoLoading} = useFetchV2(
    {route: getRequestInformationQuery.route, params: queryParams},
    getRequestInformationQuery.transform
  );

  const goBackToApprovals = () => {
    if (isAdmin || isInternal) {
      navigate('/lists/approvals-list');
    } else {
      navigate('/main-menu');
    }
  };
  return (
    <PageStyled>
      <MenuBar
        getFullElementId={(name, type) => `${idPrefix}--pageContainer__${name}-${type}`}
        leftStack={[
          <ReviewInformationTitle>
            <ArrowBack onClick={() => goBackToApprovals()} /> <span>Requests</span>
          </ReviewInformationTitle>
        ]}
        rightStack={[]}
        tabs={null}
      />
      {requestInfoLoading ? (
        <ReviewInfoLoadingBar idPrefix={idPrefix} loadingBarRows={3} />
      ) : (
        <ReviewInformationWrapperContainer>
          <Outlet context={approvalRequestInfo} />
        </ReviewInformationWrapperContainer>
      )}
    </PageStyled>
  );
};

export default ReviewInformationPage;
