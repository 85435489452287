/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common Styles for the components
 */

import styled from '@emotion/styled';
import {
  LABEL_COLOR,
  FORM_ERROR_COLOR,
  NEXT_BUTTON_TEXT_DISABLED_COLOR,
  NEXT_BUTTON_BORDER,
  NEXT_BUTTON_DISABLED,
  NEXT_BUTTON_ENABLED,
  NEXT_BUTTON_TEXT_ENABLED_COLOR,
  HORIZONTAL_LINE_COLOR,
  BORDER_COLOR,
  FORM_COLOR,
  GREEN_COLOR,
  WHITE_COLOR,
  GREEN_TICK_COLOR,
  BLACK_BORDER_COLOR,
  SCROLL_BAR_TRACK,
  SCROLL_BAR_THUMB,
  DISABLED_SAVE_PDF_BTN_COLOR
} from '../theme/Colors';
import {HELP_TEXT_COLOR, RETIRE_LOOKUP_TITLE_COLOR, VIASAT_LOGO_ICON_COLOR} from './common/theme/Colors';
import {FormLayout} from './common/elements/Form';

export const BackButton = styled.button`
  display: flex;
  cursor: pointer;
  width: fit-content;
  height: 24px;
  left: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${LABEL_COLOR};
  flex: none;
  order: 1;
  flex-grow: 0;
  background: white;
  border: none;
`;

export const NextButton = styled.button<{disabled?: boolean; width?: number; bgColor?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  gap: 4px;
  border-radius: 1000px;
  position: relative;
  height: 40px;
  width: ${props => (props.width ? `${props.width}px` : '108px')};
  right: 0px;
  top: 10px;
  margin-right: 20px;
  background: ${props =>
    props.bgColor ? `${props.bgColor}` : props.disabled ? `${NEXT_BUTTON_DISABLED}` : `${NEXT_BUTTON_ENABLED}`};
  border: 1px solid ${NEXT_BUTTON_BORDER};
  .MuiCircularProgress-colorPrimary {
    color: ${WHITE_COLOR};
  }
  .next-button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    display: contents;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${props => (props.disabled ? `${NEXT_BUTTON_TEXT_DISABLED_COLOR}` : `${NEXT_BUTTON_TEXT_ENABLED_COLOR}`)};
  }
  .next-arrow {
    color: ${props => (props.disabled ? `${NEXT_BUTTON_TEXT_DISABLED_COLOR}` : `${NEXT_BUTTON_TEXT_ENABLED_COLOR}`)};
    width: 17.33px;
    height: 17.33px;
  }
  #servicePlan-barring--nextButton {
    width: auto;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

export const NormalButton = styled.button<{disabled?: boolean, hide?: boolean}>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  gap: 4px;
  border-radius: 1000px;
  background: none;
  position: relative;
  height: 40px;
  width: 108px;
  right: 0px;
  top: 10px;
  margin-right: 20px;
  border: 1px solid ${BLACK_BORDER_COLOR};
  .button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${LABEL_COLOR};
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

export const ContactViasatButton = styled.button<{disabled?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 4px;
  font-family: 'Source Sans Pro';
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  gap: 4px;
  background: none;
  height: 40px;
  width: auto;
  right: 0px;
  top: 10px;
  border: none;
  color: ${FORM_COLOR} .button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${FORM_COLOR};
  }
  svg {
    color: ${FORM_COLOR};
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

export const HeaderHorizontalRule = styled.hr`
  width: 95.64px;
  height: 3.98px;
  background: ${HORIZONTAL_LINE_COLOR};
  margin: 0px 0px 10px 0px;
`;

export const BottomHorizontalRule = styled.hr`
  border: 1px solid ${NEXT_BUTTON_DISABLED};
  margin-top: 40px;
  width: 100%;
`;

export const Form = styled.form<{count: number}>`
  display: flex;
  flex-direction: ${props => (props.count && props.count > 1 ? 'row' : 'column')};
  align-items: flex-start;
  padding: 0px 0px;
  gap: 14px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
  flex-wrap: ${props => (props.count && props.count > 1 ? 'wrap' : 'nowrap')};
  padding-bottom: 10px;
  .dropdown-menu {
    font-size: 16px;
    color: ${LABEL_COLOR};
    font-family: 'Source Sans Pro';
    background: ${NEXT_BUTTON_BORDER};
    border-radius: 4px;
    width: 100%;
    height: 40px;
    padding-left: 4px;
    margin: 0;
  }
  .formFieldHelpIcon {
    position: relative;
    top: 3px;
  }
`;

export const FormContainerLeftPanel = styled.div`
  width: 102%;
  max-height: 75%;
  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: ${SCROLL_BAR_TRACK};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${SCROLL_BAR_THUMB};
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${SCROLL_BAR_THUMB};
    border-radius: 4px;
  }
`;

export const FormError = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${FORM_ERROR_COLOR};
  top: 280px;
  margin: 0;
  padding: 0;
  display: flex;
  .formErrorIcon {
    font-size: 16px;
    padding-right: 5.33px;
  }
  .formWarningIcon {
    font-size: 16px;
    padding-right: 5.33px;
    color: #f27400;
  }
  .formWarningText {
    color: #cd3209;
  }
`;

export const MandatoryIndicator = styled.div`
  color: ${FORM_ERROR_COLOR};
  margin-right: 5px;
`;

export const StyledInput = styled.input`
  border: 1px solid ${BORDER_COLOR};
  font-size: 16px;
  font-family: 'Source Sans Pro';
  background: ${NEXT_BUTTON_BORDER};
  border-radius: 4px;
  width: -webkit-fill-available;
  height: 40px;
  color: ${LABEL_COLOR};
  z-index: 1000;
  padding: 0 10px;
  &:disabled {
    color: ${DISABLED_SAVE_PDF_BTN_COLOR}
  }
`;

export const FormFieldContainer = styled.div<{layout: string, count: number}>`
  display: flex;
  flex-direction: ${props => (props.layout === FormLayout.HORIZONTAL ? 'row' : 'column')};
  width: ${props => (props.count ? 90/props.count+'%' : '90%')};
`;

export const FormFieldInputContainer = styled.div<{layout: string}>`
  display: flex;
  flex-direction: column;
  width: ${props => (props.layout === FormLayout.HORIZONTAL ? '70%' : '100%')};
`;

export const TextFieldLabels = styled.div<{layout: string, isDisabled?: boolean}>`
  width: ${props => (props.layout === FormLayout.HORIZONTAL ? '30%' : '-webkit-fill-available')};
  height: 20px;
  top: 200px;
  display: flex;
  flex-direction: row;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.isDisabled ? DISABLED_SAVE_PDF_BTN_COLOR : FORM_COLOR};
  padding-top: ${props => (props.layout === FormLayout.HORIZONTAL ? '0px' : '20px')};

  .sub-text-label {
    color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
    font-style: italic;
  }
  .input-label {
    font-family: 'Source Sans Pro';
    line-height: 24px;
    color: ${LABEL_COLOR};
  }
`;
export const FormFieldHelpText = styled.div`
  color: ${FORM_COLOR};
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const StyledLink = styled.a`
  color: '#006EAD';
  text-decoration-color: '#9FAFBC';
`;

export const PageDescription = styled.div`
  min-width: 300px;
  width: -webkit-fill-available;
  margin-bottom: 2px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 17.9319px;
  letter-spacing: 0.199243px;
  line-height: 24px;
  color: ${LABEL_COLOR};
  flex: none;
  order: 1;
  flex-grow: 0;
  @media only screen and (max-width: 1720px) {
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 1608px) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 950px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 725px) {
    margin-bottom: 60px;
  }
`;

export const HeaderLabel = styled.div`
  display: flex;
  width: 646px;
  height: 36px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 27.894px;
  line-height: 24px;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
  color: ${LABEL_COLOR};
  flex: none;
  order: 0;
  flex-grow: 0;
  .no-serial-number {
    color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
  }
`;

export const LatestRevision = styled.div`
  min-width: 300px;
  width: -webkit-fill-available;
  margin-bottom: 2px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  color: ${LABEL_COLOR};
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-bottom: 30px;
  @media only screen and (max-width: 1720px) {
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 1608px) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 950px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 725px) {
    margin-bottom: 60px;
  }
`;

export const StyledRadioCheckbox = styled.input`
  width: 20px;
  height: 20px;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const GroupTitle = styled.div<{layout: string}>`
  width: 646px;
  height: 24px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${FORM_COLOR};
  padding-top: ${props => (props.layout === FormLayout.HORIZONTAL ? '0px' : '20px')};
  margin-bottom: ${props => (props.layout === FormLayout.HORIZONTAL ? '0px' : '-26px')};
`;

export const BottomDescription = styled.div`
  margin: 0px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${VIASAT_LOGO_ICON_COLOR};
  display: flex;
  .info-icon {
    font-size: 16px;
    color: ${VIASAT_LOGO_ICON_COLOR};
    padding: 0px 5px 0px 0px;
  }
`;
export const FieldDescription = styled.div`
  margin: 0px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  .title {
    color: ${HELP_TEXT_COLOR};
  }
  .value {
    padding-left: 5px;
    color: ${RETIRE_LOOKUP_TITLE_COLOR};
  }
`;
export const AlertWrapper = styled.div`
  box-sizing: border-box;
  width: 46vw;
  height: auto;
  min-height: 100px;
  background: ${WHITE_COLOR};
  border: 1px solid ${GREEN_COLOR};
  border-radius: 16px;
  display: flex;
  margin-bottom: 20px;
  .greenTick {
    float: left;
    width: 2vw;
    padding: 20px 10px 20px 20px;
    color: ${GREEN_TICK_COLOR};
    display: flex;
    flex-direction: column;
  }
  .closeIcon {
    width: 2vw;
    float: right;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
`;

export const AlertContent = styled.div`
  float: left;
  width: 36vw;
  padding: 20px 0px 20px 0px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  display: flex;
  flex-direction: column;
  .primaryMessage {
    width: 518px;
    height: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${LABEL_COLOR};
  }
  .messageInfo {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${FORM_COLOR};
    padding-top: 10px;
  }
`;

export const LeftGreenBorder = styled.div`
  width: 0.9vw;
  height: auto;
  background: ${GREEN_COLOR};
  border-radius: 14px 0px 0px 14px;
  float: left;
  display: flex;
  flex-direction: column;
`;

export const EquipmentDetailContainer = styled.div`
  max-height: 70%;
`;

export const RadioButtonLabel = styled.label`
  padding-left: 5px;
  padding-right: 10px;
  vertical-align: super;
  font-size: 14px;
`;

export const Visibility = styled.div`
  .labelText {
    padding-left: 5px;
    padding-right: 10px;
    vertical-align: super;
    font-size: 14px;
  }
`;

export const VisibleCondition = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${LABEL_COLOR};
  padding: 16px;
  background: ${NEXT_BUTTON_BORDER};
  border-radius: 8px;
  font-style: italic;
  .read-carefully {
    width: 614px;
    height: 90px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: ${LABEL_COLOR};
  }
`;
