/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: get Existing Equipment Info query
 */

import {forEach} from 'lodash';
import {Query} from './types';
import {DateTime} from 'luxon';

export interface IExistingEquipmentInformation {
  partNumber: string;
  serialNumber: string;
  macAddress: string;
  ipAddress: string;
  softwareId: string;
  simCardId: string;
  warrantyEndDate: string;
  warrantyStartDate: string;
}

/**
 * Transforms the API data to support different UI columns and edit use case
 * @param equipmentInfoData List of Equipment data
 * @returns Transformed Equipment for UI
 */
export const transformEquipmentInfo = (equipmentInfoData: IExistingEquipmentInformation[]) => {
  const equipmentInfo = [];
  forEach(equipmentInfoData, (equipment: IExistingEquipmentInformation) => {
    equipmentInfo.push({
      partNumber: equipment.partNumber,
      serialNumber: equipment.serialNumber,
      macAddress: equipment.macAddress,
      ipAddress: equipment.ipAddress,
      softwareId: equipment.softwareId,
      simCardId: equipment.simCardId,
      warrantyEndDate: equipment.warrantyEndDate ? DateTime.fromISO(equipment.warrantyEndDate).toISODate() : '--'
    });
  });
  return equipmentInfo;
};

export const getExistingEquipmentInfoQuery: Query<IExistingEquipmentInformation[]> = {
  route: '/salesforce/get/configurationItem',
  transform: transformEquipmentInfo
};
