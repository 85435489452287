/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Aircraft Information Form
 */

import {useAircraftInformationForm} from '../../../utils/aircraftDetailsFormHook';
import FormComponent, {FormField, FormLayout} from '../../common/elements/Form';
import {
  EditReviewInformationFormContainer,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewSection
} from '../ReviewInformationStyles';
import {IChangeAircraftDetail} from '../reviewInformationTypes';

interface IAircraftInfoForm extends IChangeAircraftDetail {
  formId: string;
  setAircraftFormData: (aircraftFormData: any) => void;
  formControlMethods: any;
}

const AircraftInformationForm: React.FC<IAircraftInfoForm> = ({
  idPrefix,
  taskDetail,
  existingAircraft,
  formId,
  setAircraftFormData,
  formControlMethods
}) => {
  const {watch} = formControlMethods;

  const aircraftInfoFields: FormField[] = useAircraftInformationForm(
    {
      serialNumber: taskDetail.serialNumber,
      make: taskDetail.make,
      model: taskDetail.model,
      tailId: taskDetail.tailId,
      network: taskDetail.network,
      installationType: taskDetail.installationType
    },
    false,
    watch,
    'aircraftInfo'
  );

  return (
    <ReviewSection>
      <ReviewItemTitle id={`${idPrefix}__aircraftInformation_title`}>Aircraft Information</ReviewItemTitle>
      <ReviewItemSubTitle id={`${idPrefix}__aircraftInformation_subtitle`}>
        Please review the information below for approval.
      </ReviewItemSubTitle>
      <EditReviewInformationFormContainer>
        <FormComponent
          idPrefix={idPrefix}
          id={formId}
          formFields={aircraftInfoFields}
          setFormData={setAircraftFormData}
          formControlMethods={formControlMethods}
          layout={FormLayout.HORIZONTAL}
        ></FormComponent>
      </EditReviewInformationFormContainer>
    </ReviewSection>
  );
};

export default AircraftInformationForm;
