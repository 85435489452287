/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Utils
 */

/**
 * Renders the value of the review detail
 * @param value Value that needed to be rendered
 * @returns Returns the value if exists otherwise returns `--`
 */
export const renderReviewInformation = (value: string) => (value ? value : '--');
