/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add External Customer
 */
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {CustomerInfoAction} from '../../../store/reducers/CustomerInfoReducer';
import {useStore} from '../../../store/Store';
import Breadcrumb from '../../common/Breadcrumb';
import FormComponent, {FormField, FormFieldType} from '../../common/elements/Form';
import {BottomHorizontalRule, HeaderHorizontalRule, HeaderLabel, NextButton, PageDescription} from '../../CommonStyles';
import PageContainer from '../../mainMenu/PageContainer';

const ID_PREFIX = 'customerInformation';

interface AddExternalCustomerProps {
  title: string;
}

const AddExternalCustomer: React.FC<AddExternalCustomerProps> = ({title}) => {
  const location = useLocation();
  const {dispatch, store} = useStore();
  const navigate = useNavigate();
  const serialNumber = store.aircraftInfo ? store.aircraftInfo.serialNumber : null;
  const customerInfo = store.customerInfo;
  const {handleSubmit, formState, watch, control} = useForm();
  const [customerInfoFormData, setcustomerInfoFormData] = useState(null);
  const initialValues = {
    legalName: customerInfo.organizationInfo ? customerInfo.organizationInfo.legalBusinessName : '',
    aircraftHomeBaseAirport: customerInfo.aircraftHomeBaseAirport ? customerInfo.aircraftHomeBaseAirport : ''
  };
  useEffect(() => {
    if (customerInfoFormData === null) return;
    dispatch({
      type: CustomerInfoAction.SET_ORGANIZATION_INFO,
      payload: {legalBusinessName: customerInfoFormData.legalBusinessName}
    });
    dispatch({
      type: CustomerInfoAction.SET_AIRCRAFT_TAIL_ID_AND_HOME_BASE_AIRPORT,
      payload: {
        aircraftHomeBaseAirport: customerInfoFormData.aircraftHomeBaseAirport
      }
    });

    navigate('../add-data-sharing-permission');
    // eslint-disable-next-line
  }, [customerInfoFormData]);

  const LeftChild = () => {
    const customerInfoFormFields: FormField[] = [
      {
        id: `${ID_PREFIX}--legalInfoTitle`,
        label: '',
        type: FormFieldType.GROUP_TITLE,
        value: 'New Service Options',
        disabled: false,
        rules: {required: false, pattern: null}
      },
      {
        id: 'legalBusinessName',
        label: 'Legal Business Name',
        type: FormFieldType.AUTO_COMPLETE,
        value: initialValues.legalName,
        disabled: false,
        description: {title: null, value: 'Name used on the service agreement'},
        rules: {
          required: true,
          pattern: null
        },
        placeholder: 'Search for legal business name ...',
        dropDownValues: store.app.endUsers?.map(item => item.accountName)
      },
      {
        id: 'aircraftHomeBaseAirport',
        label: 'Aircraft Home Base Airport (ICAO Airport Code)',
        type: FormFieldType.INPUT,
        value: initialValues.aircraftHomeBaseAirport,
        disabled: false,
        rules: {
          required: false,
          pattern: null
        }
      }
    ];
    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel>Add Customer Information</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription>
          Please enter the customer’s legal business name, the name the customer is commonly known as for SN: {serialNumber}
        </PageDescription>
        <BottomHorizontalRule />
        <FormComponent
          idPrefix={ID_PREFIX}
          id="add-external-customer-form"
          formFields={customerInfoFormFields}
          setFormData={setcustomerInfoFormData}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton id="add-external-customer-save--button" form="add-external-customer-form" disabled={false}>
        <span className="next-button-text">Next</span>
        <ArrowForwardIcon className="next-arrow" />
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<LeftChild />} actionButtons={<ActionButtons />} />;
};

export default AddExternalCustomer;
