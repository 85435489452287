/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add New Software Version in Lookup Table
 *
 */
import React, {useMemo, useState, useEffect} from 'react';
import {isNil} from 'lodash';
import {useForm} from 'react-hook-form';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {AddForm, AddFormHeader, DrawerButton, ButtonContainer, FormContainer} from '../LookupTableStyles';
import {addMapBundleVersionQuery, updateMapBundleVersionQuery} from '../../../../store/queries/lookupQueries';
import {SideDrawerProps, togglePanel, handleBackdropClick, InputProps} from '../lookupTableUtils';
import {useStore} from '../../../../store/Store';
import {AppAction} from '../../../../store/reducers/AppReducer';
import {LookupsListAction} from '../../../../store/reducers/LookupsReducer';
import FormComponent, {FormField, FormFieldType} from '../../../common/elements/Form';
import useFetchV2 from '../../../../utils/useFetchV2';

const ID_PREFIX = 'mapBundleVersion';

interface IFormInput {
  mapBundleFileName: string;
  priorReleaseSed: string;
  priorReleaseSscf: string;
  productionReleaseSed: string;
  productionReleaseSscf: string;
  pilotReleaseSed: string;
  pilotReleaseSscf: string;
  notes: string;
}

const AddMapBundleVersion: React.FC<SideDrawerProps> = ({openPanel, setOpenPanel, panelData, isUpdate}) => {
  const {dispatch} = useStore();
  const [newMapBundleVersionInfo, setNewMapBundleVersionInfo] = useState<any>(null);
  const [updateMapBundleVersionInfo, setUpdateMapBundleVersionInfo] = useState<any>(null);
  let initialValues: IFormInput = {
    mapBundleFileName: '',
    priorReleaseSed: '',
    priorReleaseSscf: '',
    productionReleaseSed: '',
    productionReleaseSscf: '',
    pilotReleaseSed: '',
    pilotReleaseSscf: '',
    notes: ''
  };
  if (isUpdate) initialValues = panelData;

  const swVersionFormFields: FormField[] = [
    {
      id: 'mapBundleFileName',
      label: 'Map File Name',
      type: FormFieldType.INPUT,
      value: initialValues.mapBundleFileName,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'priorReleaseSed',
      label: 'SED Prior Release',
      type: FormFieldType.INPUT,
      value: initialValues.priorReleaseSed,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'priorReleaseSscf',
      label: 'SSCF Prior Release',
      type: FormFieldType.INPUT,
      value: initialValues.priorReleaseSscf,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'productionReleaseSed',
      label: 'SED Production Release',
      type: FormFieldType.INPUT,
      value: initialValues.productionReleaseSed,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'productionReleaseSscf',
      label: 'SSCF Production Release',
      type: FormFieldType.INPUT,
      value: initialValues.productionReleaseSscf,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'pilotReleaseSed',
      label: 'SED Pilot Release',
      type: FormFieldType.INPUT,
      value: initialValues.pilotReleaseSed,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'pilotReleaseSscf',
      label: 'SSCF Pilot Release',
      type: FormFieldType.INPUT,
      value: initialValues.pilotReleaseSscf,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'notes',
      label: 'Notes',
      type: FormFieldType.TEXTFIELD,
      value: initialValues.notes,
      disabled: false,
      inputProps: InputProps,
      isMultiline: true,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  const {handleSubmit, formState, unregister, reset, watch, control} = useForm();

  const addQueryParams = useMemo(() => {
    if (
      isNil(newMapBundleVersionInfo) ||
      (newMapBundleVersionInfo &&
        (!newMapBundleVersionInfo.mapBundleFileName ||
          !newMapBundleVersionInfo.productionReleaseSed ||
          !newMapBundleVersionInfo.productionReleaseSscf))
    )
      return;
    return {
      mapBundleFileName: newMapBundleVersionInfo.mapBundleFileName,
      productionRelease: {
        sed: newMapBundleVersionInfo.productionReleaseSed,
        sscf: newMapBundleVersionInfo.productionReleaseSscf
      },
      priorRelease: {
        sed: newMapBundleVersionInfo.priorReleaseSed,
        sscf: newMapBundleVersionInfo.priorReleaseSscf
      },
      pilotRelease: {
        sed: newMapBundleVersionInfo.pilotReleaseSed,
        sscf: newMapBundleVersionInfo.pilotReleaseSscf
      },
      notes: newMapBundleVersionInfo.notes
    };
  }, [newMapBundleVersionInfo]);

  const updateQueryParams = useMemo(() => {
    if (
      isNil(updateMapBundleVersionInfo) ||
      (isUpdate &&
        updateMapBundleVersionInfo &&
        (!updateMapBundleVersionInfo.mapBundleFileName ||
          !updateMapBundleVersionInfo.productionReleaseSed ||
          !updateMapBundleVersionInfo.productionReleaseSscf))
    )
      return;
    return {
      mapBundleFileName: updateMapBundleVersionInfo.mapBundleFileName,
      productionRelease: {
        sed: updateMapBundleVersionInfo.productionReleaseSed,
        sscf: updateMapBundleVersionInfo.productionReleaseSscf
      },
      priorRelease: {
        sed: updateMapBundleVersionInfo.priorReleaseSed,
        sscf: updateMapBundleVersionInfo.priorReleaseSscf
      },
      pilotRelease: {
        sed: updateMapBundleVersionInfo.pilotReleaseSed,
        sscf: updateMapBundleVersionInfo.pilotReleaseSscf
      },
      notes: updateMapBundleVersionInfo.notes
    };
  }, [updateMapBundleVersionInfo, isUpdate]);

  const {isLoading: addInProgress, data: addNewMapBundleVersionInfo} = useFetchV2(
    {route: addMapBundleVersionQuery.route, params: addQueryParams},
    addMapBundleVersionQuery.transform
  );
  const {isLoading: updateInProgress, data: updatedMapBundleVersionInfo} = useFetchV2(
    {route: updateMapBundleVersionQuery(panelData?.id).route, params: updateQueryParams},
    updateMapBundleVersionQuery(panelData?.id).transform
  );
  const onCancelClick = () => {
    unregister();
    reset(
      {
        mapBundleFileName: '',
        priorReleaseSed: '',
        productionReleaseSed: '',
        pilotReleaseSed: '',
        priorReleaseSscf: '',
        productionReleaseSscf: '',
        pilotReleaseSscf: '',
        notes: ''
      },
      {
        keepValues: false
      }
    );
    setOpenPanel(false);
  };

  const handleOpen = () => {
    togglePanel(true);
    setOpenPanel(true);
  };

  const handleClose = () => {
    if (localStorage.getItem('backdropClick') === 'true') {
      localStorage.setItem('backdropClick', 'false');
      return;
    }
    togglePanel(false);
    setOpenPanel(false);
  };

  useEffect(() => {
    if (addInProgress) return;
    if (addNewMapBundleVersionInfo) {
      setOpenPanel(false);
      dispatch({
        type: LookupsListAction.SET_NEW_ROW_COUNT,
        payload: 1
      });
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'Map Bundle Version entry added.'}
      });
    }
    //eslint-disable-next-line
  }, [addInProgress, addNewMapBundleVersionInfo]);

  useEffect(() => {
    if (updateInProgress) return;
    if (updatedMapBundleVersionInfo) {
      setOpenPanel(false);
      dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: panelData});
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'Map Bundle Version data updated.'}
      });
    }
    //eslint-disable-next-line
  }, [updateInProgress, updatedMapBundleVersionInfo]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={openPanel}
        onOpen={handleOpen}
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
      >
        <AddForm>
          <AddFormHeader id={`${ID_PREFIX}--mapBundleVersionTitle`}>ADD NEW MAP BUNDLE VERSION INFO</AddFormHeader>
          <FormContainer>
            <FormComponent
              id="add-map-bundle-version-form"
              formFields={swVersionFormFields}
              setFormData={!isUpdate ? setNewMapBundleVersionInfo : setUpdateMapBundleVersionInfo}
              formControlMethods={{handleSubmit, formState, watch, control}}
              idPrefix={ID_PREFIX}
            ></FormComponent>
          </FormContainer>
          <ButtonContainer>
            <DrawerButton id={`${ID_PREFIX}-cancel-button`} className="cancel-button" onClick={onCancelClick}>
              <span className="button-text">Cancel</span>
            </DrawerButton>
            <DrawerButton
              form="add-map-bundle-version-form"
              id={`${ID_PREFIX}-add-button`}
              className="add-button"
              onClick={() => null}
            >
              <span className="button-text">{!isUpdate ? 'Add' : 'Update'}</span>
            </DrawerButton>
          </ButtonContainer>
        </AddForm>
      </SwipeableDrawer>
    </>
  );
};

export default AddMapBundleVersion;
