/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Equipment Details - Modem - Ka/Ku
 */
import React from 'react';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemValue,
  ReviewSubSection,
  ReviewSubSectionItemTitle
} from '../../ReviewInformationStyles';
import {renderReviewInformation} from '../../util';

interface IModemInfo {
  serialNumber: string;
  partNumber: string;
  macAddress?: string;
  ipAddress?: string;
  kaBandIpAddress?: string;
}
interface IModemDetail {
  idPrefix: string;
  kaModem: {new: IModemInfo; existing: IModemInfo};
  kuModem: {new: IModemInfo; existing: IModemInfo};
}

const ModemDetail: React.FC<IModemDetail> = ({idPrefix, kaModem, kuModem}) => {
  return (
    <>
      {kaModem && kaModem.new ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle id={`${idPrefix}__kaModem--title`}>Ka Modem</ReviewSubSectionItemTitle>
          <ReviewItems id={`${idPrefix}__kaModem`}>
            <ReviewItem>
              <ReviewItemLabel>Part Number</ReviewItemLabel>
              {kaModem.existing && kaModem.existing.partNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kaModem--existingPartNumuber`}>{renderReviewInformation(kaModem.existing.partNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kaModem--newPartNumber`}>{renderReviewInformation(kaModem.new.partNumber)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Serial Number</ReviewItemLabel>
              {kaModem.existing && kaModem.existing.serialNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kaModem--existingSerialNumber`}>{renderReviewInformation(kaModem.existing.serialNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kaModem--newSerialNumber`}>{renderReviewInformation(kaModem.new.serialNumber)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>MAC Address</ReviewItemLabel>
              {kaModem.existing && kaModem.existing.macAddress ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kaModem--existingMacAddress`}>{renderReviewInformation(kaModem.existing.macAddress)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kaModem--newMacAddress`}>{renderReviewInformation(kaModem.new.macAddress)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Ka IP Address</ReviewItemLabel>
              {kaModem.existing && kaModem.existing.kaBandIpAddress ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kaModem--existingkaBandIpAddress`}>{renderReviewInformation(kaModem.existing.kaBandIpAddress)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kaModem--newKaBandIpAddress`}>{renderReviewInformation(kaModem.new.kaBandIpAddress)}</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
      {kuModem && kuModem.new ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle>Ku Modem</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Part Number</ReviewItemLabel>
              {kuModem.existing && kuModem.existing.partNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kuModem--existingPartNumber`}>{renderReviewInformation(kuModem.existing.partNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kuModem--newPartNumber`}>{renderReviewInformation(kuModem.new.partNumber)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Serial Number</ReviewItemLabel>
              {kuModem.existing && kuModem.existing.serialNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kuModem--existingSerialNumber`}>{renderReviewInformation(kuModem.existing.serialNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kuModem--newSerialNumber`}>{renderReviewInformation(kuModem.new.serialNumber)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>MAC Address</ReviewItemLabel>
              {kuModem.existing && kuModem.existing.ipAddress ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kuModem--existingIPAddress`}>{renderReviewInformation(kuModem.existing.ipAddress)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kuModem--newIPAddress`}>{renderReviewInformation(kuModem.new.ipAddress)}</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
    </>
  );
};

export default ModemDetail;
