/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Aircraft Info for Review
 */
import React from 'react';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemValue,
  ReviewSubSection,
  ReviewSubSectionItemTitle
} from '../ReviewInformationStyles';
import {renderReviewInformation} from '../util';
import {IAircraftBasicInfo, ITaskData} from '../../../store/queries/getRequestInformationQuery';

interface IAricraftDetail {
  idPrefix: string;
  existingAircraft: IAircraftBasicInfo;
  taskDetail: ITaskData | null;
}

const AircraftInfoReview: React.FC<IAricraftDetail> = ({idPrefix, existingAircraft, taskDetail}) => {
  return (
    <>
      {existingAircraft && taskDetail ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle id={`${idPrefix}__changeAircraftDetail--title`}>
            Aircraft Details Change for Review
          </ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Tail ID</ReviewItemLabel>

              <ReviewItemValue isExisting={true} id={`${idPrefix}__changeAircraftDetail--tailIdExisting`}>
                {renderReviewInformation(existingAircraft.tailId)}
              </ReviewItemValue>

              <ReviewItemValue id={`${idPrefix}__changeAircraftDetail--tailIdNew`}>
                {renderReviewInformation(taskDetail.tailId)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Model</ReviewItemLabel>

              <ReviewItemValue isExisting={true} id={`${idPrefix}__changeAircraftDetail--modelExisting`}>
                {renderReviewInformation(existingAircraft.model)}
              </ReviewItemValue>

              <ReviewItemValue id={`${idPrefix}__changeAircraftDetail--modelNew`}>
                {renderReviewInformation(taskDetail.model)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Installation Type</ReviewItemLabel>

              <ReviewItemValue isExisting={true} id={`${idPrefix}__changeAircraftDetail--installationTypeExisting`}>
                {renderReviewInformation(existingAircraft.installationType)}
              </ReviewItemValue>

              <ReviewItemValue id={`${idPrefix}__changeAircraftDetail--installationTypeNew`}>
                {renderReviewInformation(taskDetail.installationType)}
              </ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
    </>
  );
};

export default AircraftInfoReview;
