/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: FanTray Information
 */

import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {useStore} from '../../store/Store';
import {EquipmentInfoAction, IFanTrayInformation} from '../../store/reducers/EquipmentInfoReducer';
import {AppAction} from '../../store/reducers/AppReducer';

import {updateFanTrayInformation} from '../../store/queries/newInstall/equipmentInfoQuery';

import {useEquipmentUpdate} from '../../utils/customHooks';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import FormComponent from '../common/elements/Form';

import {
  BottomHorizontalRule,
  HeaderHorizontalRule,
  HeaderLabel,
  LatestRevision,
  NextButton,
  PageDescription,
  StyledLink
} from '../CommonStyles';
import {EquipmentType, SELECT_AN_OPTION} from '../common/constants';
import {showUserManuals} from '../../utils/config';
import {useEquipmentInformationForm} from '../../utils/equipmentInformationFormHook';

const ID_PREFIX = 'fanTrayInstallation';

interface FanTrayInformationPageProps {
  title: string;
}

interface IFormInput {
  partNumber: string;
  serialNumber: string;
}

const FanTrayInformationPage: React.FC<FanTrayInformationPageProps> = ({title}) => {
  const location = useLocation();

  const FanTrayInfoForm = () => {
    const {store, dispatch} = useStore();
    const {aircraftInfo} = store;
    const {aircraftNetwork} = aircraftInfo;
    const fanTrayInfo = store.equipmentInfo.fanTray;
    const taskId = store.openTasksInfo.newTask?.taskId;
    const breadcrumbInfo = store.app.breadcrumb;
    const initialValues = fanTrayInfo
      ? fanTrayInfo
      : {
          partNumber: SELECT_AN_OPTION,
          serialNumber: ''
        };

    const {handleSubmit, formState, watch, control} = useForm();

    const {productDescription, equipmentFormFields} = useEquipmentInformationForm(
      watch,
      aircraftNetwork,
      initialValues,
      EquipmentType.FAN_TRAY,
      []
    );

    const [fanTrayformData, setFanTrayFormData] = useState<IFormInput>(null);

    useEquipmentUpdate<IFanTrayInformation>(
      taskId,
      fanTrayformData,
      updateFanTrayInformation,
      '/new-terminal-installation',
      () => {
        dispatch({
          type: EquipmentInfoAction.SET_FAN_TRAY_INFO,
          payload: {productDescription, ...fanTrayformData}
        });
        dispatch({
          type: AppAction.SET_BREADCRUMB,
          payload: breadcrumbInfo.filter(breadCrumb => breadCrumb.linkPath === '/new-terminal-installation')
        });
      },
      aircraftInfo
    );

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Enter Fan Tray Information</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--description`}>
          Use your mobile device to scan the QR code on the Fan Tray, or manually enter the information below.
        </PageDescription>
        <BottomHorizontalRule />
        <FormComponent
          idPrefix={ID_PREFIX}
          id="new-fan-tray-information-form"
          formFields={equipmentFormFields}
          setFormData={setFanTrayFormData}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
        {showUserManuals ? (
          <>
            <BottomHorizontalRule />
            <LatestRevision id={`${ID_PREFIX}--latestRevisionTitle`}>
              View latest revision of the Viasat Modem FanTray and Test Manual here:
            </LatestRevision>
            <StyledLink href="#" id={`${ID_PREFIX}--installProcedureLink`}>
              Fan Tray Installation Procedures
            </StyledLink>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton id={`${ID_PREFIX}--nextButton`} form="new-fan-tray-information-form" disabled={false}>
        <span className="next-button-text">Next</span>
        <ArrowForwardIcon className="next-arrow" />
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<FanTrayInfoForm />} actionButtons={<ActionButtons />} />;
};

export default FanTrayInformationPage;
