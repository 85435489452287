/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Reducer for Snackbar store
 */

import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import {SnackBarProps} from '@viasat/insights-components';

const INITIAL_FADE_TIME_MS = 5000;

export interface SnackBarMetaData {
  id: string;
  date: moment.Moment;
  redirectUrl?: string;
}

export declare type SnackBar = SnackBarProps & SnackBarMetaData;
export declare type SnackBarState = SnackBar[];
export const InitialSnackBarState: SnackBarState = [];

const getId = () => uuidv4();

export const createSnackBar = (snackBar: SnackBarProps, redirectUrl?: string): SnackBar => ({
  id: getId(),
  date: moment.utc(),
  isOpen: true,
  durationMs: INITIAL_FADE_TIME_MS,
  redirectUrl: redirectUrl ? redirectUrl : undefined,
  ...snackBar
});

// declare type ParentState = {snackBar: SnackBarState};
declare type ParentState = {snackBar: SnackBarState};

export enum SnackBarAction {
  HIDE_SNACK_BAR = 'HIDE_SNACK_BAR'
}

// Reducer
export const SnackBarReducer = (state: ParentState, action: any): ParentState => {
  switch (action.type) {
    case SnackBarAction.HIDE_SNACK_BAR:
      state.snackBar = state.snackBar.map((snack: SnackBar) =>
        action.payload.id === snack.id
          ? {
              ...snack,
              isOpen: false
            }
          : snack
      );
      break;
    default:
      break;
  }

  return state;
};
