/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Aircraft Model List
 */

import {ColumnSortReducer, ColumnSortState} from './ColumnSortReducer';
import {SnackBarReducer} from './SnackBarReducer';
import {delegateToReducers} from '../Reducer';
import {FiltersReducer, FiltersState} from './FiltersReducer';
import {CommonAction} from './InitReducer';
import {setFilters, setOrderColumnQuery} from '../UrlMap';

export const AIRCRAFT_MODEL_LIST_STORE_CONTEXT = 'aircraftModelList';

export interface AircraftModelListState {
  filters: FiltersState;
  sort: ColumnSortState;
}

export const InitialAircraftModelListState: AircraftModelListState = {
  filters: {
    filters: [],
    domainOptions: [],
    rangeOptions: {}
  },
  sort: {
    options: undefined,
    column: 'status',
    order: 'desc',
    queryField: 'status'
  }
};

export const AircraftModelListSessionStoreKeys: string[] = [
  'sort.column',
  'sort.order',
  'sort.queryField',
  'filters.filters'
];

export const AIRCRAFT_MODEL_LIST_ROUTE = '/lookups/aircraft-model-list';

/**
 * Equipmet information action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const AircraftModelListReducer = (state: AircraftModelListState, action: any): AircraftModelListState => {
  switch (action.type) {
    case CommonAction.PROCESS_URL_PARAMS:
      if (action.payload.path === AIRCRAFT_MODEL_LIST_ROUTE) {
        state = setOrderColumnQuery(state, action.payload);
        state = setFilters(state, action.payload);
      }
      break;
    default:
      break;
  }

  state = delegateToReducers(
    AIRCRAFT_MODEL_LIST_STORE_CONTEXT,
    state,
    action,
    {sort: ColumnSortReducer, filters: FiltersReducer, snackBar: SnackBarReducer},
    InitialAircraftModelListState
  );

  return state;
};
