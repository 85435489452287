/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Status Container Component
 */
import React from 'react';
import styled from '@emotion/styled';
import {NewReleasesOutlined, WarningOutlined, ErrorOutline, CheckCircleOutline} from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  APPROVED_BG_COLOR,
  APPROVED_TXT_COLOR,
  DECLINED_BG_COLOR,
  DECLINED_TXT_COLOR,
  EDITED_BG_COLOR,
  EDITED_TXT_COLOR,
  INFO_REQUIRED_BG_COLOR,
  INFO_REQUIRED_TXT_COLOR,
  NEW_BG_COLOR,
  NEW_TXT_COLOR
} from '../../../theme/Colors';

const STATUS_BG_COLOR_MAPPING = {
  NEW: NEW_BG_COLOR,
  DECLINED: DECLINED_BG_COLOR,
  INFO_REQUIRED: INFO_REQUIRED_BG_COLOR,
  APPROVED: APPROVED_BG_COLOR,
  EDITED: EDITED_BG_COLOR
};

const STATUS_TXT_COLOR_MAPPING = {
  NEW: NEW_TXT_COLOR,
  DECLINED: DECLINED_TXT_COLOR,
  INFO_REQUIRED: INFO_REQUIRED_TXT_COLOR,
  APPROVED: APPROVED_TXT_COLOR,
  EDITED: EDITED_TXT_COLOR
};

const STATUS_ICON = {
  NEW: <NewReleasesOutlined />,
  DECLINED: <ErrorOutline />,
  INFO_REQUIRED: <WarningOutlined />,
  APPROVED: <CheckCircleOutline />,
  EDITED: <ModeEditIcon />
};

export const StatusContainer = styled.div<{status: string; isGrid?: boolean; message?: string}>`
  background: ${props => (props.status ? STATUS_BG_COLOR_MAPPING[props.status] : '#ff0000')};
  display: flex;
  flex-direction: row;
  min-width: 63px;
  width: auto;
  height: ${props => (props.message ? 'calc(100% - 7px)' : '24px')};
  min-height: ${props => (props.message ? '40px' : 'unset')};
  border-radius: 4px;
  font-size: 14px;
  color: ${props => (props.status ? STATUS_TXT_COLOR_MAPPING[props.status] : '#ff0000')};
  padding: 7px 7px 5px 7px;
  font-weight: 600;
  justify-content: space-around;
  span {
    display: flex;
    margin-left: 5px;
    margin-top: ${props => (props.message ? '10px' : '2px')};
    color: ${props => (props.message ? '#202E39' : props.status ? STATUS_TXT_COLOR_MAPPING[props.status] : '#ff0000')};
  }
  .icon-position {
    top: calc(100% - 38px);
    position: relative;
    font-size: 20px;
  }
`;
interface IStatusComponent {
  status: string;
  idPrefix: string;
  isGrid?: boolean;
  message?: string;
}

const StatusComponent: React.FC<IStatusComponent> = ({status, idPrefix, isGrid, message}) => {
  const transformedStatus = status?.replace(/ /g, '_').toLocaleUpperCase();
  return (
    <StatusContainer
      id={`${idPrefix}--status-container-${status?.replace(/ /g, '')}`}
      status={transformedStatus}
      isGrid={isGrid}
      message={message}
    >
      <div className={message ? 'icon-position' : ''}>{STATUS_ICON[transformedStatus]}</div>
      <span> {message ? message : status} </span>
    </StatusContainer>
  );
};

export default StatusComponent;
