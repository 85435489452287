/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Lookup List Views
 */

import {delegateToReducers} from '../Reducer';

export const LOOKUPS_STORE_CONTEXT = 'lookups';

export interface ILookupsState {
  sortBy: string;
  sortOrder: string;
  newRow: number;
  deactivatedRow: number;
  refreshList: boolean;
  selectedLookups: any[];
}

export const InitialLookupsState: ILookupsState = {
  sortBy: null,
  sortOrder: null,
  newRow: 0,
  deactivatedRow: 0,
  refreshList: false,
  selectedLookups: []
};

export const LookupsSessionStoreKeys: string[] = ['sortBy', 'sortOrder'];

export enum LookupsListAction {
  SET_LOOKUPS_LIST_SORT = 'SET_LOOKUPS_LIST_SORT',
  SET_NEW_ROW_COUNT = 'SET_NEW_ROW_COUNT',
  RESET_NEW_ROW_COUNT = 'RESET_NEW_ROW_COUNT',
  SET_DEACTIVATED_ROW_COUNT = 'SET_DEACTIVATED_ROW_COUNT',
  RESET_DEACTIVATED_ROW_COUNT = 'RESET_DEACTIVATED_ROW_COUNT',
  REFRESH_LOOKUP_LIST = 'REFRESH_LOOKUP_LIST',
  SELECTED_LOOKUP_ROWS = 'SELECTED_LOOKUP_ROWS',
  REMOVE_FROM_SELECTED_LOOKUPS = 'REMOVE_FROM_SELECTED_LOOKUPS',
  REMOVE_ALL_SELECTED_LOOKUPS = 'REMOVE_ALL_SELECTED_LOOKUPS'
}

/**
 * Equipment information action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const LookupsReducer = (state: ILookupsState, action: any): ILookupsState => {
  switch (action.type) {
    case LookupsListAction.SET_LOOKUPS_LIST_SORT:
      state = {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder
      };
      break;
    case LookupsListAction.SET_NEW_ROW_COUNT:
      state = {
        ...state,
        newRow: action.payload
      };
      break;
    case LookupsListAction.RESET_NEW_ROW_COUNT:
      state = {
        ...state,
        newRow: 0
      };
      break;
    case LookupsListAction.SET_DEACTIVATED_ROW_COUNT:
      state = {
        ...state,
        deactivatedRow: action.payload
      };
      break;
    case LookupsListAction.RESET_DEACTIVATED_ROW_COUNT:
      state = {
        ...state,
        deactivatedRow: 0
      };
      break;
    case LookupsListAction.REFRESH_LOOKUP_LIST:
      state = {
        ...state,
        refreshList: action.payload
      };
      break;
    case LookupsListAction.SELECTED_LOOKUP_ROWS:
      const selectedData = [...state.selectedLookups, action.payload];
      state = {...state, selectedLookups: selectedData};
      break;
    case LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS:
      const updatedSelectedLookups = state.selectedLookups.filter(function (value) {
        return value.id !== action.payload.id;
      });
      state = {
        ...state,
        selectedLookups: updatedSelectedLookups
      };
      break;
    case LookupsListAction.REMOVE_ALL_SELECTED_LOOKUPS:
      state = {...state, selectedLookups: action.payload};
      break;
  }

  state = delegateToReducers(LOOKUPS_STORE_CONTEXT, state, action, {}, InitialLookupsState);

  return state;
};
