/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Global styles for the application
 */

import {css} from '@emotion/react';

const globalStyle = css`
  body {
    -webkit-min-device-pixel-ratio: 0;
    font-family: 'Source Sans Pro';
    font-size: '16px';
    background-color: '#efefef';
  }
  .datePicker-input {
    width: 100%;
    input {
      padding: 8.5px 14px;
    }
  }
`;

export default globalStyle;
