/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Approve Progress
 */
import React from 'react';
import {Stepper, Step, StepLabel, Typography, Box, styled} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {StepIconProps} from '@mui/material/StepIcon';
import {
  APPROVAL_STEPPER_BACKGROUND_COLOR,
  APPROVAL_STEPPER_NUMBER_ICON_COLOR,
  NEXT_BUTTON_TEXT_ENABLED_COLOR
} from '../../../theme/Colors';

interface IApprovalProcessStepperProps {
  approvalSteps: any;
}

const StepperApprovalContainer = styled(Box)(() => ({
  padding: '10px',
  marginTop: -25,
  maxWidth: '400px',
  backgroundColor: NEXT_BUTTON_TEXT_ENABLED_COLOR,
  borderRadius: '8px'
}));

const StepperIcon = styled(Box)(() => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: APPROVAL_STEPPER_BACKGROUND_COLOR,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  color: APPROVAL_STEPPER_NUMBER_ICON_COLOR
}));

const StepperWaitingAnimation = styled(Box)(() => ({
  animation: 'blinking 1.5s infinite',
  '@keyframes blinking': {
    '0%': {opacity: 1},
    '50%': {opacity: 0},
    '100%': {opacity: 1}
  }
}));

const StepperTextLabel = styled(Typography)(() => ({
  fontFamily: 'Source Sans Pro',
  fontWeight: 600,
  fontSize: 16
}));

const CustomStepIcon = (props: StepIconProps & {status: string; stepIndex: number}) => {
  const {status, stepIndex} = props;

  if (status === 'success') {
    return <CheckCircleIcon color="success" sx={{fontSize: 24}} />;
  } else if (status === 'error') {
    return <ErrorIcon color="error" sx={{fontSize: '24px'}} />;
  } else {
    return <StepperIcon>{stepIndex + 1}</StepperIcon>;
  }
};

const ApprovalProcessStepper: React.FC<IApprovalProcessStepperProps> = ({approvalSteps}) => {
  return (
    <StepperApprovalContainer>
      <Typography
        sx={{fontFamily: 'Source Sans Pro', fontWeight: 800, fontSize: 20, paddingBottom: 3}}
        variant="h6"
        gutterBottom
      >
        Processing Your Approval
      </Typography>
      <Stepper orientation="vertical" activeStep={approvalSteps?.length} sx={{marginLeft: 4}}>
        {approvalSteps?.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={iconProps => <CustomStepIcon {...iconProps} status={step.status} stepIndex={index} />}
            >
              <StepperTextLabel>{step.label}</StepperTextLabel>
              <Typography
                sx={{fontFamily: 'Source Sans Pro', fontWeight: 400, fontSize: 12}}
                variant="body2"
                color={step.status === 'error' ? 'error' : 'textSecondary'}
              >
                {step.description}{' '}
                {step.status === 'waiting' && <StepperWaitingAnimation component="span">...</StepperWaitingAnimation>}
              </Typography>
              {/* // TODO: we can comment this out once Retry option is integrated
              {step.status === 'error' && (
                <Box sx={{margin: '8px 0 0 32px'}}>
                  <Button variant="contained" color="primary">
                    Retry
                  </Button>
                </Box>
              )} */}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{marginTop: 4, fontFamily: 'Source Sans Pro', fontWeight: 400, fontSize: 14}}
      >
        Once all steps are complete, this modal will automatically close and you will be notified by email.
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{marginTop: 2, fontFamily: 'Source Sans Pro', fontWeight: 400, fontSize: 14}}
      >
        If any steps fail, you may try again.
      </Typography>
    </StepperApprovalContainer>
  );
};

export default ApprovalProcessStepper;
