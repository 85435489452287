/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: aircraft details form hook
 */

import {uniq} from 'lodash';
import {FieldValues, UseFormWatch} from 'react-hook-form';

import {FormField, FormFieldType} from '../components/common/elements/Form';
import {validateDropdownField} from './validationUtils';

import {useStore} from '../store/Store';

import {DUAL_BAND_NETWORK, INSTALLATION_TYPES, KA_NETWORK, KU_NETWORK, MenuProps} from '../components/common/constants';
/**
 * Builds aircraft information form 
 * @param aircraftInfo aircraft information
 * @param isNewInstall newInstall or existing aircraft
 * @param watch react useForm - watch hook
 * @param objectIdPrefix id prefix for form object
 * @returns Array of aircraft Form Fields
 */
export const useAircraftInformationForm = (
  aircraftInfo: {
    serialNumber: string;
    make: string;
    model: string;
    tailId: string;
    network: string;
    installationType: string;
  },
  isNewInstall: boolean,
  watch: UseFormWatch<FieldValues>,
  objectIdPrefix?: string
): FormField[] => {

  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const {store} = useStore();

  if(!aircraftInfo) {
    return;
  }

  const {aircraftModels} = store.app;
  const makeList = uniq(aircraftModels.map(mode => mode.aircraftMake)).sort();

  const selectedMake = watch(`${idPrefix + 'make'}`);
  const modelsByMake = aircraftModels?.filter(aircraftModel => aircraftModel.aircraftMake === selectedMake);
  const modelList = uniq(modelsByMake.map(mode => mode.aircraftModel));
  const networkTypes = [KA_NETWORK, KU_NETWORK, DUAL_BAND_NETWORK];

  const aircraftInfoFormFields: FormField[] = [
    {
      id: `${idPrefix + 'serialNumber'}`,
      label: 'Aircraft Serial Number',
      type: FormFieldType.INPUT,
      value: aircraftInfo.serialNumber,
      disabled: !isNewInstall,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: `${idPrefix + 'make'}`,
      label: 'Make',
      type: FormFieldType.SELECT,
      value: aircraftInfo.make,
      disabled: !isNewInstall,
      menuProps: MenuProps,
      dropDownValues: makeList,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Make')
      }
    },
    {
      id: `${idPrefix + 'model'}`,
      label: 'Model',
      type: FormFieldType.SELECT,
      value: aircraftInfo.model,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: modelList,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Model')
      }
    },
    {
      id: `${idPrefix + 'tailId'}`,
      label: 'Tail ID',
      type: FormFieldType.INPUT,
      value: aircraftInfo.tailId,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: `${idPrefix + 'network'}`,
      label: 'Network',
      type: FormFieldType.SELECT,
      value: aircraftInfo.network,
      disabled: !isNewInstall,
      menuProps: MenuProps,
      dropDownValues: networkTypes,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Network')
      }
    },
    {
      id: `${idPrefix + 'installationType'}`,
      label: 'Installation Type',
      type: FormFieldType.SELECT,
      value: aircraftInfo.installationType,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: INSTALLATION_TYPES,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Installation Type')
      }
    }
  ];
  return aircraftInfoFormFields;
};
