/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft Model List View
 */
import React, {useEffect, useMemo, useState} from 'react';
import {PageProps} from '../../common/layout/PageProps';
import useFetch from '../../../utils/useFetch';
import {getAircraftModelsQuery} from '../../../store/queries/lookupQueries';
import {IAircraftModel} from '../../../store/queries/lookupTypes';
import AddAircraftModel from './forms/AddAircraftModel';
import SnackBar from '../../common/SnackBar';
import {useStore} from '../../../store/Store';
import {LookupsListAction} from '../../../store/reducers/LookupsReducer';
import {LookupListColumnId, buildAircraftModelListColumns, dynamicSort, getQueryFieldMapping} from './lookupTableUtils';
import ListViewComponent from '../ListViewComponent';
import RetireLookupRecord, {RetireLookupType} from './forms/RetireLookupRecord';
import {useResetSelectedLookup, useRefreshLookupList} from '../../../utils/customHooks';
import LookupHeaderAction from './LookupListHeaderActions';
import AccessControl from '../../common/accessControl/AccessControl';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import {ListGridColumn} from '@viasat/insights-components';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {AIRCRAFT_MODEL_LIST_STORE_CONTEXT} from '../../../store/reducers/AircraftModelListReducer';
import ListViewSortContainer from '../ListViewSortContainer';
import {getListViewFilters} from '../listsUtil';
import {AIRCRAFT_MODEL_LIST_FILTERS, dynamicFilter} from './FilterUtils';
import {getFilterOptions} from '../../../utils/filterUtils';
import {FilterAction} from '../../../store/reducers/FiltersReducer';
import FilterHookContainer from '../../common/elements/filterSelector/FilterHookContainer';

const ID_PREFIX = 'aircraftModelList';

interface AircraftModelListRow extends IAircraftModel {
  isSelected?: boolean;
  isDisabled?: boolean;
  isRowHighlighted?: boolean;
}

const AircraftModelList: React.FC<PageProps> = () => {
  const {store, dispatch} = useStore();
  const {isAdmin} = store.init;
  const {newRow, deactivatedRow, refreshList, selectedLookups} = store.lookups;
  const {sort, filters} = store.aircraftModelList;
  const [openAddAircraftModel, setOpenAddAircraftModel] = useState<boolean>(false);
  const [retireAircraftModel, setRetireAircraftModel] = useState<boolean>(false);
  const {snackbar} = store.app;
  const [aircraftModelListData, setAircraftModelListData] = useState<AircraftModelListRow[]>(null);
  const aircraftModelListFilters = useMemo(() => getListViewFilters(AIRCRAFT_MODEL_LIST_FILTERS), []);

  useResetSelectedLookup();
  useRefreshLookupList(refreshList);

  function handleRowSelection(selectedLookup: any) {
    dispatch({
      type: LookupsListAction.SELECTED_LOOKUP_ROWS,
      payload: selectedLookup
    });
  }

  const handleRowDeselection = (removedLookup: any) => {
    dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: removedLookup});
  };

  const aircraftModelListColumns = buildAircraftModelListColumns(isAdmin).map(col => {
    if (col.key === 'id') {
      col.handleRowSelection = handleRowSelection;
      col.handleRowDeselection = handleRowDeselection;
    }
    return col;
  });
  const queryParams = useMemo(() => ({newRow, deactivatedRow, refreshList, doCache: false}), [newRow, deactivatedRow, refreshList]);
  const {isLoading: isAircraftModelsLoading, data: aircraftModelInfo} = useFetch<IAircraftModel[]>(
    getAircraftModelsQuery,
    queryParams
  );

  // useEffect Purpose: Update the options available for filtering each of the filterable columns
  useEffect(() => {
    if (aircraftModelListFilters) {
      const {domainOptions, rangeOptions} = getFilterOptions({listData: aircraftModelInfo}, aircraftModelListFilters);

      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, AIRCRAFT_MODEL_LIST_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [aircraftModelInfo, dispatch, aircraftModelListFilters]);

  useEffect(() => {
    if (isAircraftModelsLoading) return;
    const filteredListData = dynamicFilter(aircraftModelInfo, filters);
    const aircraftTypeDataWithSelections: AircraftModelListRow[] = filteredListData
      ? [...filteredListData].map(row => {
          return {
            ...row,
            isSelected: selectedLookups.some((selectedRow: IAircraftModel) => selectedRow.id === row.id),
            isRowHighlighted: row.status === 'Retired'
          };
        })
      : null;
    if (aircraftTypeDataWithSelections) {
      setAircraftModelListData(aircraftTypeDataWithSelections.sort(dynamicSort(sort.column, sort.order)));
    }
    // eslint-disable-next-line
  }, [aircraftModelInfo, isAircraftModelsLoading, selectedLookups, sort.column, sort.order]);

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(LookupListColumnId[columnId]);

  const handleSortChange = ({column, order}: {column: string; order: string}) => {
    setAircraftModelListData(aircraftModelListData.sort(dynamicSort(column, order)));
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, AIRCRAFT_MODEL_LIST_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(aircraftModelListColumns[column]),
        validColumns: aircraftModelListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: aircraftModelListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(aircraftModelListColumns[col.key])
        )
      }
    });
  };
  /**
   * Handles the filterchange event
   */
  const handleFilterChange = () => {
    setAircraftModelListData(dynamicFilter(aircraftModelInfo, filters));
  };

  return (
    <>
      <ListViewComponent
        idPrefix={ID_PREFIX}
        isLoading={isAircraftModelsLoading}
        headerActionButton={[
          <AccessControl permitted={isAdmin}>
            <LookupHeaderAction
              isLoading={isAircraftModelsLoading}
              selectedLookups={selectedLookups}
              setOpenAddAircraftModel={setOpenAddAircraftModel}
              setRetireAircraftModel={setRetireAircraftModel}
              idPrefix={ID_PREFIX}
              isRetire={true}
              allowRetireDeactive={true}
            />
          </AccessControl>,
          <FilterHookContainer
            key="filters"
            idPrefix={ID_PREFIX}
            storeContext={AIRCRAFT_MODEL_LIST_STORE_CONTEXT}
            handleFilterChange={handleFilterChange}
          />,
          <ListViewSortContainer
            key="sorts"
            idPrefix={ID_PREFIX}
            disabled={isAircraftModelsLoading}
            storeContext={AIRCRAFT_MODEL_LIST_STORE_CONTEXT}
            columns={aircraftModelListColumns}
            showColumnGroups={true}
            getQueryFieldForColumn={getQueryFieldForColumn}
          />
        ]}
        listColumns={aircraftModelListColumns}
        listData={aircraftModelListData}
        listTotal={isAircraftModelsLoading && !aircraftModelListData ? 0 : aircraftModelListData?.length}
        listContainerHeight={'55px'}
        arePrereqsLoading={isAircraftModelsLoading}
        handleRowSelection={handleRowSelection}
        handleRowDeselection={handleRowDeselection}
        handleSortChange={handleSortChange}
        selectedSort={sort}
        rowSelectionKey={'id'}
        rowDisableKey={'id'}
        selectedRows={selectedLookups}
      />
      {openAddAircraftModel ? (
        <AddAircraftModel
          openPanel={openAddAircraftModel}
          setOpenPanel={setOpenAddAircraftModel}
          panelData={selectedLookups[0]}
          isUpdate={selectedLookups.length === 1}
        ></AddAircraftModel>
      ) : (
        <></>
      )}
      {retireAircraftModel && (
        <RetireLookupRecord
          retireLookupType={RetireLookupType.RETIRE}
          openRetireLookupModal={retireAircraftModel}
          retireLookupQueryRoute={'lookups/deactivate/aircraftType/' + selectedLookups[0]?.id}
          recordDisplayName={'Aircraft Type'}
          setOpenRetireLookupModal={setRetireAircraftModel}
        />
      )}
      {snackbar ? (
        <SnackBar id={`${ID_PREFIX}--snackbar`} openSnack={snackbar.openSnack} snackMessage={snackbar.snackMessage} />
      ) : (
        <></>
      )}
    </>
  );
};

export default AircraftModelList;
