/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Lookup List Header
 */
import React from 'react';
import {Tooltip} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import {HeaderActionButton} from './LookupTableStyles';
interface IHeaderAction {
  isLoading: boolean;
  selectedLookups: any;
  setOpenAddAircraftModel: (openModal: boolean) => void;
  idPrefix: string;
  isRetire: boolean;
  setRetireAircraftModel?: (openModal: boolean) => void;
  allowRetireDeactive?: boolean;
}

const LookupHeaderAction: React.FC<IHeaderAction> = ({
  isLoading,
  selectedLookups,
  setOpenAddAircraftModel,
  setRetireAircraftModel,
  idPrefix,
  isRetire,
  allowRetireDeactive
}) => {
  return selectedLookups.length >= 1 ? (
    <>
      <Tooltip
        title={selectedLookups.length > 1 ? 'Select only one row to edit' : 'Click to edit'}
        placement="right-start"
      >
        <HeaderActionButton
          className="edit-retire-button"
          id={`${idPrefix}-edit-button`}
          disabled={selectedLookups.length > 1}
          onClick={() => setOpenAddAircraftModel(true)}
        >
          <EditIcon className="header-action-icon" />
          <span className="header-action-button-text">Edit</span>
        </HeaderActionButton>
      </Tooltip>
      {allowRetireDeactive ? (
        <HeaderActionButton
          className="edit-retire-button"
          id={`${idPrefix}-retire-button`}
          disabled={selectedLookups.length > 1 || selectedLookups[0]?.status === 'Retired'}
          onClick={() => setRetireAircraftModel(true)}
        >
          <BlockIcon className="header-action-icon" />
          <span className="header-action-button-text">{isRetire ? 'Retire' : 'Deactivate'}</span>
        </HeaderActionButton>
      ) : (
        <></>
      )}
    </>
  ) : (
    <HeaderActionButton
      id={`${idPrefix}-add-button`}
      disabled={isLoading}
      onClick={() => setOpenAddAircraftModel(true)}
    >
      <AddIcon className="header-action-icon" />
      <span className="header-action-button-text">Add</span>
    </HeaderActionButton>
  );
};

export default LookupHeaderAction;
