/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft and Equipment Information Right Panel
 */
import React from 'react';
import styled from '@emotion/styled';
import {Accordion, AccordionSummary} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ACCORDION_TITLE_LABEL, INTERNAL_CONTENT_BG_COLOR, WHITE_COLOR} from '../../../../theme/Colors';
import AccordionDetailsContainer, {AccordionItemSubTitle, AccordionItemTitle} from './AccordionDetails';

const AccordionTitleLabel = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: ${ACCORDION_TITLE_LABEL};
  margin-left: -16px;
`;

const AccordionDetailWrapper = styled.div<{isInternal: boolean}>`
  .accordion-title {
    margin-bottom: 5px;
  }
  .accordion-details {
    background: ${props => (props.isInternal ? INTERNAL_CONTENT_BG_COLOR : WHITE_COLOR)};
    padding: ${props => (props.isInternal ? '10px 10px' : 'inherit')};
  }
`;

export interface IAccordionItem {
  label: string;
  value: string;
  highlight?: boolean;
}

export interface IAccordionItems {
  title: string;
  items: IAccordionItem[];
  sectionCategory: string;
  sectionTitle?: string;
  subTitle?: string;
}
interface IInternalTitle {
  title: string;
  subTitle: string;
}
interface IAccordionContainerProps {
  id: string;
  title: string;
  subTitle: string;
  items: IAccordionItems[];
  expanded?: boolean;
  isInternal?: boolean;
  internalTitle?: IInternalTitle;
  accordionChangeEvent?: (event: React.SyntheticEvent, expanded: boolean) => void;
}

const AccordionContainer: React.FC<IAccordionContainerProps> = ({
  id,
  title,
  subTitle,
  items,
  expanded,
  isInternal,
  internalTitle,
  accordionChangeEvent
}) => {
  return (
    <Accordion className="accordion-section" onChange={accordionChangeEvent} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon id={`expand-more-icon`} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionTitleLabel id={`${id}--accordion-title`}>{title}</AccordionTitleLabel>
      </AccordionSummary>
      <AccordionDetailWrapper isInternal={isInternal}>
        {isInternal ? (
          <AccordionItemTitle fontSize={14} className='accordion-title'>
            {internalTitle.title}
            <AccordionItemSubTitle>{internalTitle.subTitle}</AccordionItemSubTitle>
          </AccordionItemTitle>
        ) : null}
        <AccordionDetailsContainer isInternal={isInternal} id={`${id}`} data={items} />
      </AccordionDetailWrapper>
    </Accordion>
  );
};

export default AccordionContainer;
