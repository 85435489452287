/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Request Aircraft Access Details
 */

import {useStore} from '../../../store/Store';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewItems,
  ReviewSection,
  ReviewSubSection
} from '../ReviewInformationStyles';
import {IRequestAircraftAccessDetail} from '../reviewInformationTypes';

const RequestAircraftAccessDetail: React.FC<IRequestAircraftAccessDetail> = ({
  idPrefix,
  existingAircraft,
  taskDetail
}) => {
  const {store} = useStore();
  const {varAccountList} = store.app;
  const currentVar = varAccountList.filter(varAccount => varAccount.varAccountId === existingAircraft.accountId)[0]
    ?.varName;
  const requestedVar = varAccountList.filter(varAccount => varAccount.groupCode === taskDetail.customerCode)[0]
    ?.varName;
  return (
    <ReviewSection>
      <ReviewItemTitle id={`${idPrefix}__requestAircraftAccessDetail_title`}>
        Request Aircraft Access Information
      </ReviewItemTitle>
      <ReviewItemSubTitle id={`${idPrefix}__requestAircraftAccessDetail_subtitle`}>
        Please review the information below for approval.
      </ReviewItemSubTitle>
      <ReviewSubSection>
        <ReviewItems>
          <ReviewItem>
            <ReviewItemLabel>Current VAR</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__requestAircraftAccessDetail_currentVar`}>{currentVar}</ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Requested VAR</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__requestAircraftAccessDetail_requestedVar`}>
              {requestedVar}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Reason For Request</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__requestAircraftAccessDetail_reason`}>
              {taskDetail.reason}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Request Notes</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__requestAircraftAccessDetail_notes`}>
              {taskDetail.taskDescription}
            </ReviewItemValue>
          </ReviewItem>
        </ReviewItems>
      </ReviewSubSection>
    </ReviewSection>
  );
};

export default RequestAircraftAccessDetail;
