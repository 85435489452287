/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Customer Information
 */

import {delegateToReducers} from '../Reducer';

export const CUSTOMER_INFO_STORE_CONTEXT = 'customerInfo';

export interface IOrganizationInfo {
  legalBusinessName: string;
}
export interface IContactInfo {
  contactName: string;
  contactEmail: string;
  phoneNumber: string;
}
export interface IBusinessAddress {
  addressLine1: string;
  addressLine2: string;
  country: string;
  region: string;
  city: string;
  zipcode: string;
}

export interface IBillingContactInfo {
  sameBillingInfo: boolean;
  contactName: string;
  contactEmail: string;
  phoneNumber: string;
}
export interface IBillingAddressInfo {
  sameAddress: boolean;
  country: string;
  region: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
  zipcode: string;
}
export interface ICustomerInfoState {
  taskId: string;
  isInternal: boolean;
  aircraftHomeBaseAirport: string;
  visibleToManufacturer: string;
  flightAwareDataAccess: string;
  varAccountId: string;
  organizationInfo: IOrganizationInfo;
  contactInfo: IContactInfo;
  businessAddress: IBusinessAddress;
  billingContactInfo: IBillingContactInfo;
  billingAddressInfo: IBillingAddressInfo;
}

export const InitialCustomerInfoState: ICustomerInfoState = {
  taskId: null,
  isInternal: false,
  aircraftHomeBaseAirport: null,
  visibleToManufacturer: null,
  flightAwareDataAccess: null,
  varAccountId: null,
  organizationInfo: {
    legalBusinessName: null
  },
  contactInfo: {
    contactName: null,
    contactEmail: null,
    phoneNumber: null
  },
  businessAddress: {
    addressLine1: null,
    addressLine2: null,
    country: null,
    region: null,
    city: null,
    zipcode: null
  },
  billingContactInfo: {
    sameBillingInfo: false,
    contactName: null,
    contactEmail: null,
    phoneNumber: null
  },
  billingAddressInfo: {
    sameAddress: false,
    country: null,
    region: null,
    city: null,
    addressLine1: null,
    addressLine2: null,
    zipcode: null
  }
};

export const CustomerInfoSessionStoreKeys: string[] = [
  'taskId',
  'isInternal',
  'aircraftHomeBaseAirport',
  'visibleToManufacturer',
  'flightAwareDataAccess',
  'varAccountId',
  'organizationInfo',
  'contactInfo',
  'businessAddress',
  'billingContactInfo',
  'billingAddressInfo'
];

export enum CustomerInfoAction {
  SET_EXTERNAL_CUSTOMER_INFO = 'SET_EXTERNAL_CUSTOMER_INFO',
  SET_INTERNAL_CUSTOMER_INFO = 'SET_INTERNAL_CUSTOMER_INFO',
  SET_BILLING_INFO = 'SET_BILLING_INFO',
  SET_AIRCRAFT_TAIL_ID_AND_HOME_BASE_AIRPORT = 'SET_AIRCRAFT_TAIL_ID_AND_HOME_BASE_AIRPORT',
  SET_ORGANIZATION_INFO = 'SET_ORGANIZATION_INFO',
  SET_CONTACT_INFO = 'SET_CONTACT_INFO',
  SET_BUSINESS_ADDRESS = 'SET_BUSINESS_ADDRESS',
  SET_BILLING_CONTACT_INFO = 'SET_BILLING_CONTACT_INFO',
  SET_BILLING_ADDRESS_INFO = 'SET_BILLING_ADDRESS_INFO',
  RESET_CUSTOMER_INFO = 'RESET_CUSTOMER_INFO',
  SET_DATA_USAGE_PERMISSION = 'SET_DATA_USAGE_PERMISSION',
  SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO',
  SET_CUSTOMER_TASK_ID = 'SET_CUSTOMER_TASK_ID'
}

/**
 * Customer Information action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const CustomerInfoReducer = (state: ICustomerInfoState, action: any): ICustomerInfoState => {
  switch (action.type) {
    case CustomerInfoAction.SET_ORGANIZATION_INFO:
      state = {
        ...state,
        organizationInfo: {...state.organizationInfo, legalBusinessName: action.payload.legalBusinessName}
      };
      break;
    case CustomerInfoAction.SET_CONTACT_INFO:
      state = {
        ...state,
        contactInfo: action.payload
      };
      break;
    case CustomerInfoAction.SET_BUSINESS_ADDRESS:
      state = {
        ...state,
        businessAddress: action.payload
      };
      break;
    case CustomerInfoAction.SET_BILLING_CONTACT_INFO:
      state = {
        ...state,
        billingContactInfo: action.payload
      };
      break;
    case CustomerInfoAction.SET_BILLING_ADDRESS_INFO:
      state = {
        ...state,
        billingAddressInfo: action.payload
      };
      break;
    case CustomerInfoAction.SET_AIRCRAFT_TAIL_ID_AND_HOME_BASE_AIRPORT:
      state = {
        ...state,
        aircraftHomeBaseAirport: action.payload.aircraftHomeBaseAirport,
        organizationInfo: {...state.organizationInfo}
      };
      break;
    case CustomerInfoAction.SET_DATA_USAGE_PERMISSION:
      state = {
        ...state,
        varAccountId: action.payload.varAccountId,
        visibleToManufacturer: action.payload.visibleToManufacturer,
        flightAwareDataAccess: action.payload.flightAwareDataAccess
      };
      break;
    case CustomerInfoAction.SET_CUSTOMER_INFO:
      if (action.payload && action.payload.length) {
        const customerInfo = action.payload[0];
        state = {
          ...state,
          taskId: customerInfo.taskId,
          aircraftHomeBaseAirport: customerInfo.aircraftHomeBaseAirport,
          visibleToManufacturer: customerInfo.visibileToManufacturer,
          flightAwareDataAccess: customerInfo.flightAwareDataAccess,
          varAccountId: customerInfo.varAccountId,
          organizationInfo: customerInfo.organizationInfo,
          contactInfo: customerInfo.contactInfo,
          businessAddress: customerInfo.businessAddress,
          billingContactInfo: customerInfo.billingContactInfo,
          billingAddressInfo: customerInfo.billingAddressInfo
        };
      }
      break;
    case CustomerInfoAction.SET_CUSTOMER_TASK_ID:
      state = {
        ...state,
        taskId: action.payload
      };
      break;
    case CustomerInfoAction.RESET_CUSTOMER_INFO:
      state = {
        ...InitialCustomerInfoState
      };
      break;
    default:
      break;
  }

  state = delegateToReducers(CUSTOMER_INFO_STORE_CONTEXT, state, action, {}, InitialCustomerInfoState);

  return state;
};
