/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Equipment Details - ACU - Ka/Ku
 */
import React from 'react';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemValue,
  ReviewSubSection,
  ReviewSubSectionItemTitle
} from '../../ReviewInformationStyles';
import {renderReviewInformation} from '../../util';

interface IAcuInfo {
  serialNumber: string;
  partNumber: string;
}
interface IAcuDetail {
  idPrefix: string;
  acuDetail: {new: IAcuInfo; existing: IAcuInfo};
}
const AcuDetail: React.FC<IAcuDetail> = ({idPrefix, acuDetail}) => {
  return (
    <>
      {acuDetail && acuDetail.new ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle>ACU</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Part Number</ReviewItemLabel>
              {acuDetail.existing && acuDetail.existing.partNumber ? (
                <ReviewItemValue>{renderReviewInformation(acuDetail.existing.partNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue>{renderReviewInformation(acuDetail.new.partNumber)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Serial Number</ReviewItemLabel>
              {acuDetail.existing && acuDetail.existing.serialNumber ? (
                <ReviewItemValue>{renderReviewInformation(acuDetail.existing.serialNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue>{renderReviewInformation(acuDetail.new.serialNumber)}</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
    </>
  );
};

export default AcuDetail;
