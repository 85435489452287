/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Validate Aircraft Status Popup Modal
 */
import React, {useState} from 'react';

import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';

import PopUpModal from '../common/Modal';
import {
  ACTIVE_COLOR,
  HELP_TEXT_COLOR,
  HEADER_ICON_BORDER_COLOR,
  POPUP_BLOCK_BACKGROUND_COLOR,
  POPUP_TEXT_COLOR,
  PROGRESS_BAR_BG_COLOR,
  SUPPORT_BORDER_COLOR,
  WHITE
} from '../common/theme/Colors';

import {useStore} from '../../store/Store';
import {useGetTaskDetails, useSetAircraftInfo} from '../../utils/validateAircraftHooks';
import {AircraftInfoAction} from '../../store/reducers/AircraftInfoReducer';

const ID_PREFIX = 'aircraftValidationModal';

const ValidateAircraftModalHeaderContainer = styled.div`
  width: 460px;
  display: flex;
  direction: row;
  margin-left: -10px;
  margin-right: -16px;
  margin-bottom: 20px;
  .closeIcon {
    width: 10%;
    position: absolute;
    right: 10px;
    margin-top: 5px;
    color: ${HEADER_ICON_BORDER_COLOR};
    cursor: pointer;
  }
`;

const AircraftValidationStatusModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 58vw;
  min-height: 13vh;
  align-items: left;
  gap: 8px;
  font-style: normal;
  text-align: left;
`;
const NoMatchText = styled.div`
  font-weight: 800;
  font-size: 24px;
  font-family: 'Source Sans Pro';
  display: flex;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${POPUP_TEXT_COLOR};
`;

const HelpText = styled.div`
  font-weight: 400;
  font-size: 18px;
  font-family: 'Source Sans Pro';
  line-height: 24px;
  color: ${HELP_TEXT_COLOR};
  padding: 5px;
`;

const ValidateAircraftStatusModalActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  gap: 16px;
  background: ${POPUP_BLOCK_BACKGROUND_COLOR};
  border-top: 1px solid ${PROGRESS_BAR_BG_COLOR};
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 57vw;
  height: 5vh;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;

const TryAgainButton = styled.button<{background?: string}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px 24px;
  gap: 4px;
  border: ${props => (props.background ? 'none' : `1px solid ${SUPPORT_BORDER_COLOR}`)};
  background: ${props => (props.background ? props.background : POPUP_BLOCK_BACKGROUND_COLOR)};
  border-radius: 1000px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const ButtonText = styled.span<{color?: string}>`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props => (props.color ? props.color : POPUP_TEXT_COLOR)};
`;

interface AircraftValidationModalProps {
  aircraft: any;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  onResumeTask?: () => void;
  triggerUpdateAircraftInfo?: boolean;
}
const AircraftValidationModal: React.FC<AircraftValidationModalProps> = ({
  aircraft,
  openModal,
  setOpenModal,
  onResumeTask,
  triggerUpdateAircraftInfo = false
}) => {
  const navigate = useNavigate();
  const {store, dispatch} = useStore();
  const [continueExistingTask, setContinueExistingTask] = useState<boolean>(false);
  const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';
  const updateAircraftInfo = triggerUpdateAircraftInfo || continueExistingTask;

  useSetAircraftInfo(updateAircraftInfo, aircraft?.aircraftDetails);
  useGetTaskDetails(
    updateAircraftInfo,
    false,
    aircraft?.taskDetails,
    groupCode,
    '/main-menu',
    aircraft?.aircraftDetails
  );

  // function to close the modal.
  const closeModal = () => {
    setOpenModal(false);
  };

  const startNewInstallation = () => {
    setOpenModal(false);
    dispatch({
      type: AircraftInfoAction.SET_AIRCRAFT,
      payload: {serialNumber: aircraft?.serialNumber}
    });
    navigate('/identify-aircraft-new-installation');
  };

  const resumeTask = () => {
    if (onResumeTask) {
      onResumeTask();
    } else {
      setOpenModal(false);
      setContinueExistingTask(true);
    }
  };

  const requestAccess = () => {
    setOpenModal(false);
    navigate('/request-aircraft-access', {state: {serialNumber: aircraft.serialNumber}});
  };

  const newInstallationConfig = {
    content: `Add a new aircraft with serial number: ${aircraft?.serialNumber}`,
    helpText: `We do not have this aircraft in our records. 
               If this is for a new aircraft select "Add New Aircraft" to continue. 
               If not, select "Re-enter Serial Number" to try again.`,
    actions: [
      {id: 'tryAgain', buttonText: 'Reenter Serial Number', buttonAction: closeModal},
      {
        id: 'startNew',
        buttonText: 'Add New Aircraft',
        buttonAction: startNewInstallation,
        buttonTextColor: WHITE,
        buttonBackgroundColor: ACTIVE_COLOR
      }
    ]
  };

  const onGoingInstallationConfig = {
    content: `There is an on-going task for this Serial Number: ${aircraft?.aircraftDetails?.serialNumber}`,
    helpText: `Click on Next below to continue with ${aircraft?.aircraftDetails?.taskType}`,
    actions: [
      {id: 'tryAgain', buttonText: 'Reenter Serial Number', buttonAction: closeModal},
      {
        id: 'startNew',
        buttonText: 'Next',
        buttonAction: resumeTask,
        buttonTextColor: WHITE,
        buttonBackgroundColor: ACTIVE_COLOR
      }
    ]
  };

  const aircraftUnavailableConfig = {
    content: `Access Unavailable for SN: ${aircraft?.serialNumber}`,
    helpText: `If you need access to this aircraft, please request access below.`,
    actions: [
      {id: 'tryAgain', buttonText: 'Reenter Serial Number', buttonAction: closeModal},
      {
        id: 'requestAccess',
        buttonText: 'Request Access',
        buttonAction: requestAccess,
        buttonTextColor: WHITE,
        buttonBackgroundColor: ACTIVE_COLOR
      }
    ]
  };

  const aircraftValidationModalConfig =
    aircraft?.userHaveAccess === false
      ? aircraftUnavailableConfig
      : !aircraft?.isExists
      ? newInstallationConfig
      : onGoingInstallationConfig;
  /**
   * method that return the header of the modal
   * @returns modal header
   */
  const AircraftValidationModalHeader = () => {
    return (
      <ValidateAircraftModalHeaderContainer>
        <CloseIcon className="closeIcon" onClick={closeModal} />
      </ValidateAircraftModalHeaderContainer>
    );
  };

  return (
    <div>
      <PopUpModal
        id="aircraftValidateModal"
        open={openModal}
        title={<AircraftValidationModalHeader />}
        titlecolor={HEADER_ICON_BORDER_COLOR}
        borderRadius="16px"
        padding={'0px'}
      >
        <>
          <AircraftValidationStatusModal>
            <NoMatchText id={`${ID_PREFIX}--noMatchText`}>{aircraftValidationModalConfig.content}</NoMatchText>
            <HelpText id={`${ID_PREFIX}--helpText`}>{aircraftValidationModalConfig.helpText}</HelpText>
          </AircraftValidationStatusModal>
          <ValidateAircraftStatusModalActionsContainer>
            {aircraftValidationModalConfig.actions.map(actionConfig => {
              return (
                <TryAgainButton
                  onClick={() => actionConfig.buttonAction()}
                  background={actionConfig.buttonBackgroundColor}
                  id={`${ID_PREFIX}--${actionConfig.id}Button`}
                >
                  <ButtonText color={actionConfig.buttonTextColor} id={`${ID_PREFIX}--${actionConfig.id}ButtonText`}>
                    {actionConfig.buttonText}
                  </ButtonText>
                </TryAgainButton>
              );
            })}
          </ValidateAircraftStatusModalActionsContainer>
        </>
      </PopUpModal>
    </div>
  );
};

export default AircraftValidationModal;
