/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Termianl Equipment
 */

import {delegateToReducers} from '../Reducer';

export const EQUIPMENT_INFO_STORE_CONTEXT = 'equipmentInfo';

export interface IKuModemInformation {
  ipAddress: string;
  productDescription?: string;
  serialNumber: string;
  partNumber: string;
  warrantyEndDate: string;
  warrantyStartDate?: string;
  configItemId?: string;
  newWarrantyEndDate?: string;
}

export interface IKaModemInformation {
  macAddress: string;
  productDescription?: string;
  serialNumber: string;
  partNumber: string;
  warrantyEndDate: string;
  warrantyStartDate?: string;
  configItemId?: string;
  newWarrantyEndDate?: string;
}

export interface IAntennaInformation {
  serialNumber: string;
  partNumber: string;
  productDescription?: string;
  warrantyEndDate?: string;
  warrantyStartDate?: string;
  configItemId?: string;
  newWarrantyEndDate?: string;
}

export interface IFanTrayInformation {
  serialNumber: string;
  partNumber: string;
  productDescription?: string;
  warrantyEndDate?: string;
  warrantyStartDate?: string;
  configItemId?: string;
  newWarrantyEndDate?: string;
}

export interface IApsuInformation {
  serialNumber: string;
  partNumber: string;
  productDescription?: string;
  warrantyEndDate?: string;
  warrantyStartDate?: string;
  configItemId?: string;
  newWarrantyEndDate?: string;
}

export interface IRouterInformation {
  routerType: string;
  serialNumber?: string;
  routerId?: string;
  simNumber?: string;
  otherRouterType?: string;
  warrantyEndDate?: string;
  warrantyStartDate?: string;
  configItemId?: string;
  newWarrantyEndDate?: string;
}

export interface IAcuInformation {
  serialNumber: string;
  partNumber: string;
  productDescription?: string;
  warrantyEndDate?: string;
  warrantyStartDate?: string;
  configItemId?: string;
  newWarrantyEndDate?: string;
}

interface IConfigItem {
  configItemId: string;
  warrantyStart: string;
  actualWarrantyEnd: string;
  extendedWarrantyEnd: string;
}

interface IKaWarrantyInfo{
  antennaWarrantyEndDate?: string;
  modemWarrantyEndDate?: string;
  apsuWarrantyEndDate?: string;
  fantrayWarrantyEndDate?: string;
  routerWarrantyEndDate?: string;
}

interface IKuWarrantyInfo{
  antennaWarrantyEndDate?: string;
  modemWarrantyEndDate?: string;
  acuWarrantyEndDate?: string;
  routerWarrantyEndDate?: string;
}

export interface IWarrantyExtension {
  serialNumber: string;
  tailId: string;
  siteId: string;
  configItems: IConfigItem[];
  reason?: string;
  ka: IKaWarrantyInfo;
  ku: IKuWarrantyInfo;
}

export interface EquipmentInfoState {
  taskId: string;
  kuModem: IKuModemInformation;
  kaModem: IKaModemInformation;
  kuAntenna: IAntennaInformation;
  kaAntenna: IAntennaInformation;
  apsu: IApsuInformation;
  fanTray: IFanTrayInformation;
  router: IRouterInformation;
  acu: IAcuInformation;
  existingApsu: IApsuInformation;
  existingFanTray: IFanTrayInformation;
  existingRouter: IRouterInformation;
  existingKuAntenna: IAntennaInformation;
  existingKaAntenna: IAntennaInformation;
  existingKaModem: IKaModemInformation;
  existingKuModem: IKuModemInformation;
  existingAcu: IAcuInformation;
  warrantyDetails: IWarrantyExtension;
}

export const InitialEquipmentInfoState: EquipmentInfoState = {
  taskId: null,
  kuModem: null,
  kaModem: {macAddress: null, productDescription: null, serialNumber: null, partNumber: null, warrantyEndDate: null},
  kuAntenna: null,
  kaAntenna: {serialNumber: null, partNumber: null, productDescription: null},
  apsu: {serialNumber: null, partNumber: null, productDescription: null},
  fanTray: null,
  router: null,
  acu: null,
  existingApsu: null,
  existingFanTray: null,
  existingRouter: null,
  existingKaAntenna: null,
  existingKuAntenna: null,
  existingKaModem: null,
  existingKuModem: null,
  existingAcu: null,
  warrantyDetails: null
};

export const EquipmentInfoSessionStoreKeys: string[] = [
  'taskId',
  'kuModem',
  'kaModem',
  'kuAntenna',
  'kaAntenna',
  'apsu',
  'fanTray',
  'router',
  'acu',
  'existingApsu',
  'existingFanTray',
  'existingRouter',
  'existingKaAntenna',
  'existingKuAntenna',
  'existingKaModem',
  'existingKuModem',
  'existingAcu',
  'warrantyDetails'
];

export enum EquipmentInfoAction {
  SET_TASK_ID = 'SET_TASK_ID',
  SET_KU_MODEM_INFO = 'SET_KU_MODEM_INFO',
  SET_KA_MODEM_INFO = 'SET_KA_MODEM_INFO',
  SET_KU_ANTENNA_INFO = 'SET_KU_ANTENNA_INFO',
  SET_KA_ANTENNA_INFO = 'SET_KA_ANTENNA_INFO',
  SET_EXISTING_KU_ANTENNA_INFO = 'SET_EXISTING_KU_ANTENNA_INFO',
  SET_EXISTING_KA_ANTENNA_INFO = 'SET_EXISTING_KA_ANTENNA_INFO',
  SET_APSU_INFO = 'SET_APSU_INFO',
  SET_EXISTING_APSU_INFO = 'SET_EXISTING_APSU_INFO',
  SET_FAN_TRAY_INFO = 'SET_FAN_TRAY_INFO',
  SET_EXISTING_FAN_TRAY_INFO = 'SET_EXISTING_FAN_TRAY_INFO',
  SET_ROUTER_INFO = 'SET_ROUTER_INFO',
  SET_EXISTING_ROUTER_INFO = 'SET_EXISTING_ROUTER_INFO',
  SET_ACU_INFO = 'SET_ACU_INFO',
  RESET_EQUIPMENT_INFO = 'RESET_EQUIPMENT_INFO',
  SET_EXISTING_KA_MODEM_INFO = 'SET_EXISTING_KA_MODEM_INFO',
  SET_EXISTING_KU_MODEM_INFO = 'SET_EXISTING_KU_MODEM_INFO',
  SET_EXISTING_ACU_INFO = 'SET_EXISTING_ACU_INFO',
  SET_EQUIPMENT_INFO = 'SET_EQUIPMENT_INFO',
  SET_WARRANTY_INFO = 'SET_WARRANTY_INFO',
  SET_WARRANTY_REASON = 'SET_WARRANTY_REASON',
  SET_EQUIPMENT_WARRANTY_EXTENSION = 'SET_EQUIPMENT_WARRANTY_EXTENSION'
}

/**
 * Equipmet information action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const EquipmentInfoReducer = (state: EquipmentInfoState, action: any): EquipmentInfoState => {
  switch (action.type) {
    case EquipmentInfoAction.SET_TASK_ID:
      state = {
        ...state,
        taskId: action.payload
      };
      break;
    case EquipmentInfoAction.SET_ROUTER_INFO:
      state = {
        ...state,
        router: action.payload
      };
      break;
    case EquipmentInfoAction.SET_EXISTING_ROUTER_INFO:
      state = {
        ...state,
        existingRouter: action.payload
      };
      break;
    case EquipmentInfoAction.SET_KA_MODEM_INFO:
      state = {
        ...state,
        kaModem: action.payload
      };
      break;
    case EquipmentInfoAction.SET_KU_MODEM_INFO:
      state = {
        ...state,
        kuModem: action.payload
      };
      break;
    case EquipmentInfoAction.SET_KU_ANTENNA_INFO:
      state = {
        ...state,
        kuAntenna: action.payload
      };
      break;
    case EquipmentInfoAction.SET_KA_ANTENNA_INFO:
      state = {
        ...state,
        kaAntenna: action.payload
      };
      break;
    case EquipmentInfoAction.SET_EXISTING_KU_ANTENNA_INFO:
      state = {
        ...state,
        existingKuAntenna: action.payload
      };
      break;
    case EquipmentInfoAction.SET_EXISTING_KA_ANTENNA_INFO:
      state = {
        ...state,
        existingKaAntenna: action.payload
      };
      break;
    case EquipmentInfoAction.SET_APSU_INFO:
      state = {
        ...state,
        apsu: action.payload
      };
      break;
    case EquipmentInfoAction.SET_EXISTING_APSU_INFO:
      state = {
        ...state,
        existingApsu: action.payload
      };
      break;
    case EquipmentInfoAction.SET_FAN_TRAY_INFO:
      state = {
        ...state,
        fanTray: action.payload
      };
      break;
    case EquipmentInfoAction.SET_EXISTING_FAN_TRAY_INFO:
      state = {
        ...state,
        existingFanTray: action.payload
      };
      break;
    case EquipmentInfoAction.SET_ACU_INFO:
      state = {
        ...state,
        acu: action.payload
      };
      break;
    case EquipmentInfoAction.SET_EXISTING_KA_MODEM_INFO:
      state = {
        ...state,
        existingKaModem: action.payload
      };
      break;
    case EquipmentInfoAction.SET_EXISTING_KU_MODEM_INFO:
      state = {
        ...state,
        existingKuModem: action.payload
      };
      break;
    case EquipmentInfoAction.SET_EXISTING_ACU_INFO:
      state = {
        ...state,
        existingAcu: action.payload
      };
      break;
    case EquipmentInfoAction.SET_WARRANTY_INFO:
      state = {
        ...state,
        warrantyDetails: action.payload
      };
      break;
    case EquipmentInfoAction.SET_WARRANTY_REASON:
      state = {
        ...state,
        warrantyDetails: {...state.warrantyDetails, reason: action.payload}
      };
      break;
    case EquipmentInfoAction.SET_EQUIPMENT_WARRANTY_EXTENSION:
      state = {
        ...state,
        ...(action.payload.kaRouterExtendedWarrantyEndDate && {
          router: {...state.router, newWarrantyEndDate: action.payload.kaRouterExtendedWarrantyEndDate}
        }),
        ...(action.payload.kuModemExtendedWarrantyEndDate && {
          kuModem: {...state.kuModem, newWarrantyEndDate: action.payload.kuModemExtendedWarrantyEndDate}
        }),
        ...(action.payload.kaModemExtendedWarrantyEndDate && {
          kaModem: {...state.kaModem, newWarrantyEndDate: action.payload.kaModemExtendedWarrantyEndDate}
        }),
        ...(action.payload.kuAcuExtendedWarrantyEndDate && {
          acu: {...state.acu, newWarrantyEndDate: action.payload.kuAcuExtendedWarrantyEndDate}
        }),
        ...(action.payload.kuAntennaExtendedWarrantyEndDate && {
          kuAntenna: {...state.kuAntenna, newWarrantyEndDate: action.payload.kuAntennaExtendedWarrantyEndDate}
        }),
        ...(action.payload.kaAntennaExtendedWarrantyEndDate && {
          kaAntenna: {...state.kaAntenna, newWarrantyEndDate: action.payload.kaAntennaExtendedWarrantyEndDate}
        }),
        ...(action.payload.kaFantrayExtendedWarrantyEndDate && {
          fanTray: {...state.fanTray, newWarrantyEndDate: action.payload.kaFantrayExtendedWarrantyEndDate}
        }),
        ...(action.payload.kaApsuExtendedWarrantyEndDate && {
          apsu: {...state.apsu, newWarrantyEndDate: action.payload.kaApsuExtendedWarrantyEndDate}
        })
      };
      break;
    case EquipmentInfoAction.RESET_EQUIPMENT_INFO:
      state = {
        ...state,
        router: null,
        kuModem: null,
        kaModem: {
          macAddress: null,
          productDescription: null,
          serialNumber: null,
          partNumber: null,
          warrantyEndDate: null
        },
        kuAntenna: null,
        kaAntenna: {serialNumber: null, partNumber: null, productDescription: null},
        apsu: {serialNumber: null, partNumber: null, productDescription: null},
        acu: null,
        fanTray: null,
        existingApsu: null,
        existingFanTray: null,
        existingRouter: null,
        existingKaAntenna: null,
        existingKuAntenna: null,
        existingKaModem: null,
        existingKuModem: null,
        existingAcu: null,
        taskId: null,
        warrantyDetails: null
      };
      break;
    case EquipmentInfoAction.SET_EQUIPMENT_INFO:
      if (action.payload && action.payload.length) {
        const equipmentInfo = action.payload[0];
        state = {
          ...state,
          router: equipmentInfo.routerInfo?.new,
          kuModem: equipmentInfo.kuModemInfo?.new,
          kaModem: equipmentInfo.kaModemInfo?.new,
          apsu: equipmentInfo.apsuInfo?.new,
          acu: equipmentInfo.acuInfo?.new,
          kuAntenna: equipmentInfo.kuAntennaInfo?.new,
          kaAntenna: equipmentInfo.kaAntennaInfo?.new,
          fanTray: equipmentInfo.fanTrayInfo?.new,
          existingApsu: equipmentInfo.apsuInfo?.existing,
          existingFanTray: equipmentInfo.fanTrayInfo?.existing,
          existingRouter: equipmentInfo.routerInfo?.existing,
          existingKaAntenna: equipmentInfo.kaAntennaInfo?.existing,
          existingKuAntenna: equipmentInfo.kuAntennaInfo?.existing,
          existingKaModem: equipmentInfo.kaModemInfo?.existing,
          existingKuModem: equipmentInfo.kuModemInfo?.existing,
          existingAcu: equipmentInfo.acuInfo?.existing
        };
      }
      break;
    default:
      break;
  }

  state = delegateToReducers(EQUIPMENT_INFO_STORE_CONTEXT, state, action, {}, InitialEquipmentInfoState);

  return state;
};
