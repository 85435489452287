/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Equipment Details - Router - Ka/Ku
 */
import React from 'react';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemValue,
  ReviewSubSection,
  ReviewSubSectionItemTitle
} from '../../ReviewInformationStyles';
import {renderReviewInformation} from '../../util';

interface IRouterInfo {
  routerType: string;
  serialNumber?: string;
  routerId?: string;
  simNumber?: string;
}
interface IRouterDetail {
  idPrefix: string;
  routerDetail: {new: IRouterInfo; existing: IRouterInfo};
}

const RouterDetail: React.FC<IRouterDetail> = ({idPrefix, routerDetail}) => {
  return (
    <>
      {routerDetail && routerDetail.new ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle>Router</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Router Type</ReviewItemLabel>
              {routerDetail.existing && routerDetail.existing.routerType ? (
                <ReviewItemValue isExisting={true}>{renderReviewInformation(routerDetail.existing.routerType)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue>{renderReviewInformation(routerDetail.new.routerType)}</ReviewItemValue>
            </ReviewItem>
            {routerDetail.new.serialNumber ? (
              <ReviewItem>
                <ReviewItemLabel>Serial Number</ReviewItemLabel>
                {routerDetail.existing && routerDetail.existing.serialNumber ? (
                  <ReviewItemValue isExisting={true}>{renderReviewInformation(routerDetail.existing.serialNumber)}</ReviewItemValue>
                ) : null}
                <ReviewItemValue>{renderReviewInformation(routerDetail.new.serialNumber)}</ReviewItemValue>
              </ReviewItem>
            ) : (
              <></>
            )}
            {routerDetail.new.routerId ? (
              <ReviewItem>
                <ReviewItemLabel>Router ID</ReviewItemLabel>
                {routerDetail.existing && routerDetail.existing.routerId ? (
                  <ReviewItemValue isExisting={true}>{renderReviewInformation(routerDetail.existing.routerId)}</ReviewItemValue>
                ) : null}
                <ReviewItemValue>{renderReviewInformation(routerDetail.new.routerId)}</ReviewItemValue>
              </ReviewItem>
            ) : (
              <></>
            )}
            {routerDetail.new.simNumber ? (
              <ReviewItem>
                <ReviewItemLabel>SIM Number</ReviewItemLabel>
                {routerDetail.existing && routerDetail.existing.simNumber ? (
                  <ReviewItemValue isExisting={true}>{renderReviewInformation(routerDetail.existing.simNumber)}</ReviewItemValue>
                ) : null}
                <ReviewItemValue>{renderReviewInformation(routerDetail.new.simNumber)}</ReviewItemValue>
              </ReviewItem>
            ) : (
              <></>
            )}
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
    </>
  );
};

export default RouterDetail;
