/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Change Aircraft Details
 */
import React from 'react';
import {IChangeAircraftDetail} from '../reviewInformationTypes';
import {
  ReviewSubSectionDivider,
  ReviewItem,
  ReviewItemLabel,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewItems,
  ReviewSection,
  ReviewSubSection
} from '../ReviewInformationStyles';
import {TASK_TYPES} from '../../common/constants';
import AircraftInfoReview from './AircraftInfoReview';

const ChangeAircraftDetail: React.FC<IChangeAircraftDetail> = ({idPrefix, existingAircraft, taskDetail}) => {
  return (
    <ReviewSection>
      <ReviewItemTitle id={`${idPrefix}__changeAircraftDetail_title`}>Aircraft Information</ReviewItemTitle>
      <ReviewItemSubTitle id={`${idPrefix}__changeAircraftDetail_subtitle`}>Please review the information below for approval.</ReviewItemSubTitle>
      {taskDetail && taskDetail?.taskType === TASK_TYPES.CHANGE_AIRCRAFT_DETAILS ? (
        <ReviewSubSection>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel></ReviewItemLabel>
              <ReviewItemValue>Previous</ReviewItemValue>
              <ReviewItemValue>New</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
          <ReviewSubSectionDivider />
        </ReviewSubSection>
      ) : null}
      <AircraftInfoReview
        idPrefix={idPrefix}
        existingAircraft={existingAircraft}
        taskDetail={taskDetail}
      />
       
    </ReviewSection>
  );
};

export default ChangeAircraftDetail;
