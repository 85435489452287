/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: New Install Completion Popup Modal
 */
import React from 'react';

import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';

import PopUpModal from '../common/Modal';
import {HEADER_ICON_BORDER_COLOR, POPUP_TEXT_COLOR} from '../common/theme/Colors';
import NewTerminalCompletionIcon from '../common/icons/NewTerminalCompletionIcon';

const ID_PREFIX = 'newTerminalCompletionModal';

const NewInstallCompletionContainer = styled.div`
  width: 400px;
  display: flex;
  direction: row;
  margin-left: -10px;
  margin-right: -16px;
  margin-bottom: 20px;
  .closeIcon {
    width: 10%;
    position: absolute;
    right: 10px;
    margin-top: 5px;
    color: ${HEADER_ICON_BORDER_COLOR};
    cursor: pointer;
  }
`;

const NewInstallCompletionModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 38vw;
  min-height: 25vh;
  text-align: center;
  align-items: center;
  gap: 8px;
  font-style: normal;
  text-align: center;
`;

const ModalTitle = styled.div`
  font-weight: 800;
  font-size: 24px;
  font-family: 'Source Sans Pro';
  display: flex;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${POPUP_TEXT_COLOR};
`;

interface NewInstallCompletionProps {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
}
const NewTerminalCompletionModal: React.FC<NewInstallCompletionProps> = ({openModal, setOpenModal}) => {
  // function to close the modal.
  const closeModal = () => {
    setOpenModal(false);
  };

  /**
   * method that return the header of the modal
   * @returns modal header
   */
  const NewInstallCompletionHeader = () => {
    return (
      <NewInstallCompletionContainer>
        <CloseIcon className="closeIcon" onClick={closeModal} />
      </NewInstallCompletionContainer>
    );
  };

  return (
    <div>
      <PopUpModal
        id="aircraftValidateModal"
        open={openModal}
        title={<NewInstallCompletionHeader />}
        titlecolor={HEADER_ICON_BORDER_COLOR}
        borderRadius="16px"
        padding={'0px'}
      >
        <>
          <NewInstallCompletionModal>
            <NewTerminalCompletionIcon />
            <ModalTitle id={`${ID_PREFIX}--submitMessage`}>Installation details have been submitted.</ModalTitle>
          </NewInstallCompletionModal>
        </>
      </PopUpModal>
    </div>
  );
};

export default NewTerminalCompletionModal;
