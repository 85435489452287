/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add Internal Customer Information Component
 */

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {CustomerInfoAction} from '../../../store/reducers/CustomerInfoReducer';
import {useStore} from '../../../store/Store';
import Breadcrumb from '../../common/Breadcrumb';
import FormComponent, {FormField, FormFieldType} from '../../common/elements/Form';
import {
  BottomHorizontalRule,
  FormContainerLeftPanel,
  HeaderHorizontalRule,
  HeaderLabel,
  NextButton,
  PageDescription
} from '../../CommonStyles';
import PageContainer from '../../mainMenu/PageContainer';

const ID_PREFIX = 'internalCustomerInformation';

interface AddInternalCustomerProps {
  title: string;
}

const CustomerInfoForm = () => {
  const {dispatch, store} = useStore();
  const customerInfo = store.customerInfo;
  const navigate = useNavigate();
  const location = useLocation();
  const {handleSubmit, formState, watch, control} = useForm();
  const [customerInfoFormData, setcustomerInfoFormData] = useState(null);
  useEffect(() => {
    if (customerInfoFormData === null) return;
    dispatch({
      type: CustomerInfoAction.SET_ORGANIZATION_INFO,
      payload: {legalBusinessName: customerInfoFormData.legalBusinessName}
    });

    dispatch({
      type: CustomerInfoAction.SET_CONTACT_INFO,
      payload: {
        contactName: customerInfoFormData.contactName,
        contactEmail: customerInfoFormData.contactEmail,
        phoneNumber: customerInfoFormData.contactPhoneNumber
      }
    });

    dispatch({
      type: CustomerInfoAction.SET_BUSINESS_ADDRESS,
      payload: {
        country: customerInfoFormData.country,
        region: customerInfoFormData.state,
        city: customerInfoFormData.city,
        addressLine1: customerInfoFormData.address1,
        addressLine2: customerInfoFormData.address2,
        zipcode: customerInfoFormData.zipCode
      }
    });

    navigate('../add-aircraft-tail-id');
    // eslint-disable-next-line
  }, [customerInfoFormData]);

  const initialValues = {
    legalName: customerInfo.organizationInfo ? customerInfo.organizationInfo.legalBusinessName : '',
    country: customerInfo.businessAddress ? customerInfo.businessAddress.country : '',
    region: customerInfo.businessAddress ? customerInfo.businessAddress.region : '',
    city: customerInfo.businessAddress ? customerInfo.businessAddress.city : '',
    addressLine1: customerInfo.businessAddress ? customerInfo.businessAddress.addressLine1 : '',
    addressLine2: customerInfo.businessAddress ? customerInfo.businessAddress.addressLine2 : '',
    zipCode: customerInfo.businessAddress ? customerInfo.businessAddress.zipcode : '',
    contactName: customerInfo.contactInfo ? customerInfo.contactInfo.contactName : '',
    contactEmail: customerInfo.contactInfo ? customerInfo.contactInfo.contactEmail : '',
    contactPhone: customerInfo.contactInfo ? customerInfo.contactInfo.phoneNumber : ''
  };

  const customerInfoFormFields: FormField[] = [
    {
      id: `${ID_PREFIX}--legalInfoTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Organization Name',
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: 'legalBusinessName',
      label: 'Legal Business Name',
      type: FormFieldType.AUTO_COMPLETE,
      value: initialValues.legalName,
      disabled: false,
      description: {title: null, value: 'Name used on the service agreement'},
      rules: {
        required: true,
        pattern: null
      },
      placeholder: 'Search for legal business name ...',
      dropDownValues: store.app.endUsers?.map(item => item.accountName)
    },
    {
      id: '',
      label: '',
      type: FormFieldType.BOTTOM_HORIZONTAL_RULE,
      value: '',
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: ID_PREFIX + '--businessInfoTitle',
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Business Address',
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: 'country',
      label: 'Country',
      type: FormFieldType.INPUT,
      value: initialValues.country,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'state',
      label: 'State / Province / Region',
      type: FormFieldType.INPUT,
      value: initialValues.region,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'city',
      label: 'City',
      type: FormFieldType.INPUT,
      value: initialValues.city,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'address1',
      label: 'Address Line 1',
      type: FormFieldType.INPUT,
      value: initialValues.addressLine1,
      disabled: false,
      description: {title: '', value: 'Street address P.O box'},
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'address2',
      label: 'Address Line 2',
      type: FormFieldType.INPUT,
      value: initialValues.addressLine2,
      disabled: false,
      description: {title: '', value: 'Apartment, suite, unit, building, floor, etc.'},
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'zipCode',
      label: 'Zip Code / Postal Code',
      type: FormFieldType.INPUT,
      value: initialValues.zipCode,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: '',
      label: '',
      type: FormFieldType.BOTTOM_HORIZONTAL_RULE,
      value: '',
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: `${ID_PREFIX}--contactInfoTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Contact Information',
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: 'contactName',
      label: 'Contact Name',
      type: FormFieldType.INPUT,
      value: initialValues.contactName,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: 'contactEmail',
      label: 'Contact Email',
      type: FormFieldType.INPUT,
      value: initialValues.contactEmail,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'contactPhoneNumber',
      label: 'Contact Phone Number',
      type: FormFieldType.INPUT,
      value: initialValues.contactPhone,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: '',
      label: '',
      type: FormFieldType.BOTTOM_HORIZONTAL_RULE,
      value: '',
      disabled: false,
      rules: {required: false, pattern: null}
    }
  ];

  return (
    <>
      <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Add Customer Information</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--description`}>
        Please add the customer’s business and contact information.
      </PageDescription>
      <BottomHorizontalRule />
      <FormContainerLeftPanel>
        <FormComponent
          idPrefix={ID_PREFIX}
          id="add-internal-customer-form"
          formFields={customerInfoFormFields}
          setFormData={setcustomerInfoFormData}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
      </FormContainerLeftPanel>
    </>
  );
};

const ActionButtons = () => {
  return (
    <NextButton id="add-internal-customer-save--button" form="add-internal-customer-form" disabled={false}>
      <span className="next-button-text">Next</span>
      <ArrowForwardIcon className="next-arrow" />
    </NextButton>
  );
};

const AddInternalCustomer: React.FC<AddInternalCustomerProps> = ({title}) => {
  return <PageContainer title={title} leftChild={<CustomerInfoForm />} actionButtons={<ActionButtons />} />;
};

export default AddInternalCustomer;
