/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common utils for Lists
 */
import React, {useMemo, useState} from 'react';
import {CellRenderer} from '@viasat/insights-components';
import ListGridIconTextOrLink from '../common/elements/listGridCells/ListGridIconTextOrLink';
import {isEqual} from 'lodash';
import {FilterOptionsType, FilterType, convertObjectToFilterRangeOptions, distillFilters} from '../../utils/filterUtils';
import {FilterRow} from '../../store/reducers/FiltersReducer';

export const downloadProgressPercentCalculation = (downloadProgressPercentage: number, progressAmount: number) => {
  const progressValue = downloadProgressPercentage;
  if (progressValue >= 0 && progressValue < 20) {
    progressAmount = 10; // 2  steps
  } else if (progressValue >= 20 && progressValue < 50) {
    progressAmount = 4; // 9 steps
  } else if (progressValue >= 50 && progressValue < 80) {
    progressAmount = 2; // 15 steps
  } else if (progressValue >= 80 && progressValue < 99) {
    progressAmount = 0.5; // 40 steps
  }
  return {progressValue, progressAmount};
};

export const LinkCellRenderer: CellRenderer = ({rowData, cellIdBase, column, cellData}) => {
  const {handleClick} = column;
  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={null}
      text={cellData}
      tooltipText={null}
      descriptionText={null}
      rowData={rowData}
      handleClick={handleClick}
    />
  );
};

/**
 * Adds sorting params into the params object
 * @param params Initial params object
 * @param sortBy queryField to sort by
 * @param sortOrder Sort order
 * @returns Returns Inputs for the query with sorting
 */
export const useSortingParams = (params: Object, sortBy: string, sortOrder: string) =>
  useMemo(() => {
    if (!params) {
      // Uncomment this line once implemented the sorting, filtering functionality and remove the below return
      // return undefined;
      return {...params, sortBy, sortOrder};
    }
    return {...params, sortBy, sortOrder};
  }, [params, sortBy, sortOrder]);

/**
 * Adds filtering params into the params object
 * @param params Initial params object
 * @param filterTypes Currently active filter types
 * @param filters Current filter values to be applied
 * @returns Inputs for the query with filtering
 */
export const useFilteringParams = (
  params: Object | undefined,
  filterTypes: FilterType[] | undefined,
  filters: FilterRow[] | undefined
) => {
  const [inputs, setInputs] = useState({params: undefined, filterTypes: undefined, filters: undefined});

  if (
    !isEqual(params, inputs.params) ||
    !isEqual(filterTypes, inputs.filterTypes) ||
    !isEqual(filters, inputs.filters)
  ) {
    setInputs({params, filterTypes, filters});
  }

  return useMemo(() => {
    if (!inputs.params || !inputs.filterTypes) {
      return undefined;
    }

    const distilledFilters = inputs.filters ? distillFilters(inputs.filterTypes, inputs.filters) : {};

    return {...inputs.params, filters: distilledFilters};
  }, [inputs.params, inputs.filterTypes, inputs.filters]);
};

/**
 * Performs a deep copy of the filter list and hides field(s) based on the
 * passed in parameters.
 * @returns A list of filters for approval list
 */
export const getListViewFilters = (filterList: Array<FilterType>): Array<FilterType> => {
  return filterList.sort((a: FilterType, b: FilterType): number => {
    return a.title > b.title ? 1 : -1;
  });
};

/**
 * Returns the range options for the Serial Number filter
 * @param props Filter container properties
 * @returns Array of Serial Number Filter key/value pairs
 */
export const getSerialNumberFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'serialNumber') : [];

/**
 * Returns the range options for the Tail ID filter
 * @param props Filter container properties
 * @returns Array of Tail ID Filter key/value pairs
 */
export const getTailIdFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'tailId') : [];

/**
 * Returns the range options for the Tail ID filter
 * @param props Filter container properties
 * @returns Array of Tail ID Filter key/value pairs
 */
export const getOrganizationFilterRangeOptions = (props: any): Array<FilterOptionsType> =>
  props.listData ? convertObjectToFilterRangeOptions(props.listData, 'make') : [];

/**
 * Converts the given list of values in filter range options
 * @param values list of values to convert
 * @return array of filter range key/value pairs for filter
 */
export const convertFilterValues = (values: string[]): Array<FilterOptionsType> => {
  return values.map((value: string) => ({
    optionKey: value,
    optionValue: value
  }));
};
