/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Decline Popup Modal
 */
import React, {useEffect, useMemo, useState} from 'react';

import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';

import PopUpModal from '../../common/Modal';
import {HEADER_ICON_BORDER_COLOR, POPUP_TEXT_COLOR} from '../../common/theme/Colors';
import {APPROVED_TXT_COLOR} from '../../../theme/Colors';
import {declineQuery} from '../../../store/queries/declineQuery';
import {validateDropdownField} from '../../../utils/validationUtils';
import FormComponent, {FormField, FormFieldType} from '../../common/elements/Form';
import {SELECT_AN_OPTION} from '../../common/constants';
import {useForm} from 'react-hook-form';
import {NextButton, NormalButton} from '../../CommonStyles';
import {LoadingSpinner} from '@viasat/insights-components';
import {useStore} from '../../../store/Store';
import {ApprovalInfoAction} from '../../../store/reducers/ApprovalReducer';
import useFetchV2 from '../../../utils/useFetchV2';

const ID_PREFIX = 'DeclineRequestModal';

const DeclineRequestModalTitleContainer = styled.div`
  width: 660px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .closeIcon {
    width: 10%;
    right: 1px;
    position: absolute;
    margin-top: 5px;
    color: ${HEADER_ICON_BORDER_COLOR};
    cursor: pointer;
  }
`;

const DeclineRequestModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 15px 45px 25px;
  width: 48vw;
  min-height: 35vh;
  text-align: center;
  align-items: center;
  gap: 8px;
  font-style: normal;
  text-align: center;
  .approval-checkmark {
    font-size: 62px;
    color: ${APPROVED_TXT_COLOR};
  }
`;

const ModalTitle = styled.div`
  font-weight: 800;
  font-size: 24px;
  font-family: 'Source Sans Pro';
  display: flex;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${POPUP_TEXT_COLOR};
  width: 80%;
  margin-left: -30px;
`;

const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  form {
    margin-left: 10px;
  }
`;

const FormContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 0px 0 -15px;
  width: 99%;
`;
interface DeclineRequestProps {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  taskId: string;
}

interface IFormInput {
  declinedSubject: string;
  declinedReason: string;
}

const DeclineRequestPopupModal: React.FC<DeclineRequestProps> = ({openModal, setOpenModal, taskId}) => {
  // function to close the modal.
  const closeModal = () => {
    setOpenModal(false);
  };
  const initialValues = {
    declinedSubject: SELECT_AN_OPTION,
    declinedReason: ''
  };
  const {dispatch} = useStore();

  const {handleSubmit, formState, watch, control} = useForm();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
        color: '#465967'
      }
    }
  };
  const declineInfoFormFields: FormField[] = [
    {
      id: 'declinedSubject',
      label: 'Subject',
      type: FormFieldType.SELECT,
      value: initialValues.declinedSubject,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: ['Additional Information Request', 'Request Declined'].map(declinedSubject => declinedSubject),
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Subject')
      }
    },
    {
      id: 'declinedReason',
      label: 'Describe reason for rejection',
      type: FormFieldType.INPUT,
      value: initialValues.declinedReason,
      description: {
        title: null,
        value: 'Write a reason to help your customer understand what is needed to resolve the issue.'
      },
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    }
  ];

  const [declineFormData, setDeclineFormData] = useState<IFormInput>(null);

  const declineQueryParams = useMemo(() => {
    if (!taskId || !declineFormData) return;
    return {
      taskId: taskId,
      declinedSubject: declineFormData.declinedSubject,
      declinedReason: declineFormData.declinedReason
    };
  }, [taskId, declineFormData]);

  const {data: declineData, isLoading: declineRequestLoading} = useFetchV2(
    {route: declineQuery.route, params: declineQueryParams},
    declineQuery.transform
  );

  useEffect(() => {
    if (declineRequestLoading || !declineData) return;
    closeModal();
    if (!declineRequestLoading && declineData) {
      dispatch({
        type: ApprovalInfoAction.REFRESH_REVIEW_INFO,
        payload: 1
      });
    }
    // eslint-disable-next-line
  }, [declineData, declineRequestLoading, dispatch]);

  /**
   * method that return the header of the modal
   * @returns modal header
   */
  const DeclineRequestHeader = () => {
    return (
      <DeclineRequestModalTitleContainer>
        <ModalTitle id={`${ID_PREFIX}--submitMessage`}>Email Response</ModalTitle>
        <CloseIcon className="closeIcon" onClick={closeModal} />
      </DeclineRequestModalTitleContainer>
    );
  };

  return (
    <div>
      <PopUpModal
        id="aircraftValidateModal"
        open={openModal}
        title={<DeclineRequestHeader />}
        titlecolor={HEADER_ICON_BORDER_COLOR}
        borderRadius="16px"
        padding={'0px'}
      >
        <DeclineRequestModal>
          <FormContainer>
            <FormComponent
              idPrefix={ID_PREFIX}
              id="decline-request-form"
              formFields={declineInfoFormFields}
              setFormData={setDeclineFormData}
              formControlMethods={{handleSubmit, formState, watch, control}}
            />
            <FormContainerActions>
              <NormalButton onClick={() => closeModal()} disabled={false}>
                <span id={`${ID_PREFIX}--cancelButton`} className="next-button-text">
                  Cancel
                </span>
              </NormalButton>
              <NextButton form="decline-request-form"  id={`${ID_PREFIX}--declineButton`} disabled={false}>
                <span  className="next-button-text">
                  Send
                  {declineRequestLoading && declineFormData ? (
                    <LoadingSpinner size={18} id="declineRequestLoading" />
                  ) : null}
                </span>
              </NextButton>
            </FormContainerActions>
          </FormContainer>
        </DeclineRequestModal>
      </PopUpModal>
    </div>
  );
};

export default DeclineRequestPopupModal;
