/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: gets All Configuration items query
 */

import {Query} from './types';

export interface IConfigItems {
  partNumber: string;
  serialNumber: string;
  macAddress: string;
  ipAddress: string;
  softwareId: string;
  simCardId: string;
  configSubType: string;
  configType: string;
  siteId: string;
  warrantyStartDate: string;
  warrantyEndDate: string;
}

export interface IConfigItemsObject {
  [key: string]: IConfigItems[];
}

export const getAllConfigItemsQuery: Query<IConfigItemsObject> = {
  route: '/salesforce/get/configurationItem',
  transform: null
};
