/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Customer Details
 */
import React from 'react';
import {find} from 'lodash';
import {useStore} from '../../../store/Store';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewSection,
  ReviewSubSection,
  ReviewSubSectionItemTitle
} from '../ReviewInformationStyles';
import {renderReviewInformation} from '../util';
import {ICustomerDetail} from '../reviewInformationTypes';

const CustomerDetail: React.FC<ICustomerDetail> = ({idPrefix, customerDetail}) => {
  const {store} = useStore();
  const {varAccountList} = store.app;

  const varDetail = find(varAccountList, {varAccountId: customerDetail.varAccountId});
  let varName = '--';
  if (varDetail) {
    varName = varDetail.varName;
  }
  return (
    <ReviewSection>
      <ReviewItemTitle>Customer Information</ReviewItemTitle>
      <ReviewItemSubTitle>Please review the information below for approval.</ReviewItemSubTitle>
      <ReviewSubSection>
        <ReviewSubSectionItemTitle>Organization</ReviewSubSectionItemTitle>
        <ReviewItems>
          <ReviewItem>
            <ReviewItemLabel>Legal Business Name</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__customerDetails--legalBusinessName`}>
              {renderReviewInformation(customerDetail?.organizationInfo?.legalBusinessName)}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Aircraft Home Base Airport</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__customerDetails--homeAirport`}>
              {renderReviewInformation(customerDetail.aircraftHomeBaseAirport)}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Visible To Manufacturer</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__customerDetails--visibleToManufacturer`}>
              {renderReviewInformation(customerDetail.visibleToManufacturer)}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Flight Aware Data Access</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__customerDetails--flightAwareDataAccess`}>
              {renderReviewInformation(customerDetail.flightAwareDataAccess)}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Value Added Reseller (VAR)</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__customerDetails--visibleToManufacturer`}>
              {renderReviewInformation(varName)}
            </ReviewItemValue>
          </ReviewItem>
        </ReviewItems>
      </ReviewSubSection>
      {customerDetail?.contactInfo ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle>Contact Information</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Contact Name</ReviewItemLabel>
              <ReviewItemValue id={`${idPrefix}__customerDetails--contactName`}>
                {renderReviewInformation(customerDetail?.contactInfo?.contactName)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Contact Email</ReviewItemLabel>
              <ReviewItemValue id={`${idPrefix}__customerDetails--contactEmail`}>
                {renderReviewInformation(customerDetail?.contactInfo?.contactEmail)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Contact Phone</ReviewItemLabel>
              <ReviewItemValue id={`${idPrefix}__customerDetails--phoneNumber`}>
                {renderReviewInformation(customerDetail?.contactInfo?.phoneNumber)}
              </ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
      {customerDetail?.businessAddress ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle>Business Address</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Address Line 1</ReviewItemLabel>
              <ReviewItemValue id={`${idPrefix}__customerDetails--addressLine1`}>
                {renderReviewInformation(customerDetail?.businessAddress?.addressLine1)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Address Line 2</ReviewItemLabel>
              <ReviewItemValue id={`${idPrefix}__customerDetails--addressLine2`}>
                {renderReviewInformation(customerDetail.businessAddress?.addressLine2)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>City</ReviewItemLabel>
              <ReviewItemValue id={`${idPrefix}__customerDetails--city`}>
                {renderReviewInformation(customerDetail.businessAddress?.city)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Region</ReviewItemLabel>
              <ReviewItemValue id={`${idPrefix}__customerDetails--region`}>
                {renderReviewInformation(customerDetail.businessAddress?.region)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Country</ReviewItemLabel>
              <ReviewItemValue id={`${idPrefix}__customerDetails--country`}>
                {renderReviewInformation(customerDetail.businessAddress?.country)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Zipcode</ReviewItemLabel>
              <ReviewItemValue id={`${idPrefix}__customerDetails--zipCode`}>
                {renderReviewInformation(customerDetail.businessAddress?.zipcode)}
              </ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
      {customerDetail?.billingAddressInfo ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle>Billing Address</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Address Line 1</ReviewItemLabel>
              <ReviewItemValue>
                {renderReviewInformation(customerDetail?.billingAddressInfo?.addressLine1)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Address Line 2</ReviewItemLabel>
              <ReviewItemValue>
                {renderReviewInformation(customerDetail.billingAddressInfo?.addressLine2)}
              </ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>City</ReviewItemLabel>
              <ReviewItemValue>{renderReviewInformation(customerDetail.billingAddressInfo?.city)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Region</ReviewItemLabel>
              <ReviewItemValue>{renderReviewInformation(customerDetail.billingAddressInfo?.region)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Country</ReviewItemLabel>
              <ReviewItemValue>{renderReviewInformation(customerDetail.billingAddressInfo?.country)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Zipcode</ReviewItemLabel>
              <ReviewItemValue>{renderReviewInformation(customerDetail.billingAddressInfo?.zipcode)}</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
    </ReviewSection>
  );
};

export default CustomerDetail;
