/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common component colors
 */

import {Colors as Shared} from '@viasat/insights-components';

export {Colors as Shared} from '@viasat/insights-components';

// @ts-ignore
export const SAMPLE_FOR_USING_SHARED_COLOR = Shared.MAIN_HEADER_COLOR;

export const SELECTED_ICON_COLOR = '#226394';
export const BODY_MESSAGE_TEXT = '#333';
export const BORDER_COLOR = '#C3CDD5';
export const LABEL_COLOR = '#202E39';
export const BLACK_BORDER_COLOR = '#111920';
export const ACCORDION_TITLE_LABEL = '#465967';
export const MAIN_MENU_DESC_COLOR = '#000000';
export const FORM_COLOR = '#465967';
export const FORM_ERROR_COLOR = '#B72025';
export const NEXT_BUTTON_DISABLED = '#DEE4E8';
export const NEXT_BUTTON_ENABLED = '#00819D';
export const NEXT_BUTTON_BORDER = '#F2F5F8';
export const HORIZONTAL_LINE_COLOR = '#00A2C0';
export const NEXT_BUTTON_TEXT_DISABLED_COLOR = '#9FAFBC';
export const NEXT_BUTTON_TEXT_ENABLED_COLOR = '#FFFFFF';
export const BUTTON_HOVER_COLOR = '#EFEFEF';
export const WHITE_COLOR = '#FFFFFF';
export const BUTTON_BORDER_COLOR = '#D9D1FA';
export const GREEN_COLOR = '#5EA215';
export const GREEN_TICK_COLOR = '#266705';
export const EDIT_BUTTON_DISABLED = '#C5C5C5';
export const CANCEL_ICON_COLOR = '#CD3209';
export const DECLINED_BG_COLOR = '#FA727240';
export const DECLINED_TXT_COLOR = '#B72025';
export const NEW_BG_COLOR = '#64CEFB30';
export const NEW_TXT_COLOR = '#006EAD';
export const INFO_REQUIRED_BG_COLOR = '#FDC36140';
export const INFO_REQUIRED_TXT_COLOR = '#CD3209';
export const APPROVED_BG_COLOR = '#CCEC6A40';
export const APPROVED_TXT_COLOR = '#266705';
export const EDITED_TXT_COLOR = '#465967';
export const EDITED_BG_COLOR = '#9FAFBC30';
export const LIGHT_GREY = '#AAAAAA';
export const TABLE_ROW_COLOR = '#465967';
export const REVIEW_ITEM_TITLE_COLOR = '#562BDA';
export const DISABLED_SAVE_PDF_BTN_COLOR = '#9FAFBC;';
export const SCROLL_BAR_TRACK = '#f1f5f8';
export const SCROLL_BAR_THUMB = 'rgb(216, 216, 216)';
export const ERROR_COLOR = '#D43C33';
export const DARK_RED_COLOR = '#A82200';
export const BAR_SERVICE_MESSAGE_CONTAINER = '#FDC3617D';
export const INTERNAL_CONTENT_BG_COLOR = '#E3F0F8';
export const CLEAR_ICON_COLOR = '#677A89';

export const APPROVAL_STEPPER_NUMBER_ICON_COLOR = '#757575';
export const APPROVAL_STEPPER_BACKGROUND_COLOR = '#E0E0E0';
