/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Service Plan Menu
 */
import React, {useEffect} from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styled from '@emotion/styled';
import {useLocation, useNavigate} from 'react-router-dom';
import {camelCase, startCase} from 'lodash';

import {
  BUTTON_HOVER_COLOR,
  FORM_COLOR,
  LABEL_COLOR,
  MAIN_MENU_DESC_COLOR,
  NEXT_BUTTON_DISABLED,
  NEXT_BUTTON_TEXT_DISABLED_COLOR
} from '../../theme/Colors';
import {useStore} from '../../store/Store';
import {AppAction} from '../../store/reducers/AppReducer';
import AlertContainer from '../common/AlertContainer';
import {IMainMenu, SF_SERVICE_SITE_STATUS} from '../common/constants';
import {useClearBreadCrumb} from '../../utils/customHooks';
import {getStatusColor} from '../mainMenu/util';
import PageContainer from '../mainMenu/PageContainer';
import {StatusState} from '../../store/reducers/StatusReducer';
import Breadcrumb from '../common/Breadcrumb';
import {useGetOpenTaskDetails} from '../../utils/validateAircraftHooks';
import {getStatusIcon} from '../mainMenu/MainMenu';

interface ServicePlanMenuProps {
  title: string;
}

const TitleLabel = styled.div`
  display: flex;
  width: 646px;
  height: 24px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 24px;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
  color: ${FORM_COLOR};
  flex: none;
  order: 0;
  flex-grow: 0;
  .no-serial-number {
    color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
  }
`;

const ServicePlanMenuDescription = styled.div`
  min-width: 300px;
  width: -webkit-fill-available;
  height: 72px;
  margin-bottom: 2px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${MAIN_MENU_DESC_COLOR};
  @media only screen and (max-width: 1720px) {
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 1608px) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 950px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 725px) {
    margin-bottom: 60px;
  }
`;
const ServicePlanMenuButtons = styled.button<{textColor?: string; status?: string}>`
  box-sizing: border-box;
  margin-bottom: 8px;
  background: white;
  display: flex;
  flex-direction: initial;
  align-items: center;
  padding: 8px 28px;
  gap: 8px;
  min-width: 300px;
  width: -webkit-fill-available;
  height: 48px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${props => (props.textColor ? props.textColor : LABEL_COLOR)};
  border: 1px solid ${NEXT_BUTTON_DISABLED};
  border-radius: 1000px;
  flex: none;
  order: 0;
  align-self: stretch;
  justify-content: space-between;
  flex-grow: 0;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
  }
  &:hover {
    background: ${BUTTON_HOVER_COLOR};
  }
  .option {
    display: flex;
    .arrow {
      width: 20px;
      height: 20px
      position: absolute;
      left: 16.67%;
      right: 16.67%;
      top: 16.67%;
      bottom: 16.67%;
      margin-left: 8px;
      color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
    }
   }
  .status {
    font-size: 16px;
    display: flex;
    margin-left: 8px;
    float: right;
   }
   .statusIcon {
    padding: ${props => (props.status !== 'Completed' ? '4px' : '0px')};
    
   }

`;

const ServicePlanMenuContainer = styled.div`
  width: 100%;
`;

const ID_PREFIX = 'servicePlanMenu';

const DECOMMISSION_ALLOWED_STATES = [
  SF_SERVICE_SITE_STATUS.FACTORY_TEST,
  SF_SERVICE_SITE_STATUS.DEMO,
  SF_SERVICE_SITE_STATUS.REVENUE_SERVICE,
  SF_SERVICE_SITE_STATUS.SUSPENDED
];

const ServicePlanMenu: React.FC<ServicePlanMenuProps> = ({title}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {store, dispatch} = useStore();
  const {alertInfo} = store.app;
  const {isNewInstall, status, serialNumber} = store.aircraftInfo;
  const statusInfo = store.statusInfo;
  const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';

  const {isLoading: openTasksListLoading} = useGetOpenTaskDetails(serialNumber, groupCode);

  const getMenuOptions = (isNewInstall: boolean, statusInfo: StatusState, aircraftStatus: string): IMainMenu[] => {
    const isEnableDecommission = DECOMMISSION_ALLOWED_STATES.includes(aircraftStatus as SF_SERVICE_SITE_STATUS);

    if (
      aircraftStatus === SF_SERVICE_SITE_STATUS.BARRED ||
      aircraftStatus === SF_SERVICE_SITE_STATUS.SUSPENDED ||
      aircraftStatus === SF_SERVICE_SITE_STATUS.DECOMMISSIONED
    ) {
      return [
        {
          id: 'resume-service-plan',
          name: 'Resume Service',
          navigationPath: '/resume-service-plan',
          isDisabled: isNewInstall,
          status: statusInfo.servicePlanResumeInformation
        }
      ];
    } else {
      return [
        {
          id: isNewInstall ? 'choose-service-plan' : 'change-service-plan',
          name: isNewInstall ? 'Choose Service Plan' : 'Change Service Plan',
          navigationPath: isNewInstall ? '/choose-service-plan' : '/change-service-plan',
          status: statusInfo.servicePlanInformation
        },
        {
          id: 'suspend-service-plan',
          name: 'Suspend Service',
          navigationPath: '/suspend-service-plan',
          isDisabled: isNewInstall,
          status: statusInfo.servicePlanSuspendInformation
        },
        {
          id: 'bar-service',
          name: 'Barring Service',
          navigationPath: '/bar-service',
          isDisabled: isNewInstall,
          status: statusInfo.servicePlanBarringInformation
        },
        {
          id: 'decommission-service-plan',
          name: 'Decommission Service',
          navigationPath: '/decommission-service-plan',
          isDisabled: isNewInstall || !isEnableDecommission,
          status: statusInfo.servicePlanDecommissionInformation
        }
      ];
    }
  };
  const mainMenuOptions = getMenuOptions(isNewInstall, statusInfo, status);

  const handleServicePlanMenuOptionClick = (navigationPath: string) => {
    if (openTasksListLoading) return null;
    navigate(navigationPath);
  };

  useClearBreadCrumb();

  useEffect(() => {
    return () =>
      dispatch({
        type: AppAction.SET_ALERT_INFO,
        payload: {showAlert: false}
      });
    //eslint-disable-next-line
  }, []);

  const LeftChild = () => {
    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <ServicePlanMenuContainer>
          {alertInfo ? (
            <AlertContainer
              showAlert={alertInfo.showAlert}
              alertOperation={alertInfo.operation}
              primaryMessage={alertInfo.message}
              detailInformation={alertInfo.details}
            />
          ) : (
            <></>
          )}
          <TitleLabel>Service Plan Options</TitleLabel>
          <ServicePlanMenuDescription>
            Choose from the options below to add or make changes to the aircraft’s service plan.
          </ServicePlanMenuDescription>
          {mainMenuOptions.map((menuOption: IMainMenu, idx: number) => {
            return (
              <div key={`${idx}`}>
                <ServicePlanMenuButtons
                  textColor={getStatusColor(ID_PREFIX, startCase(menuOption.status))}
                  status={menuOption.status}
                  id={`${ID_PREFIX}--${camelCase(menuOption.name)}-button`.split(' ').join('')}
                  disabled={menuOption.isDisabled}
                  key={`${menuOption.id}-option`}
                  onClick={() => handleServicePlanMenuOptionClick(menuOption.navigationPath)}
                >
                  <span className="option">
                    {menuOption.name}
                    <ArrowForwardIcon className="arrow" />
                  </span>
                  {menuOption.status ? (
                    <span
                      className="status"
                      id={`${ID_PREFIX}--${camelCase(menuOption.name)}--${camelCase(menuOption.status)}--status`}
                    >
                      <span className="statusIcon">{getStatusIcon(ID_PREFIX, startCase(menuOption.status))}</span>
                    </span>
                  ) : (
                    <></>
                  )}
                </ServicePlanMenuButtons>
              </div>
            );
          })}
        </ServicePlanMenuContainer>
      </>
    );
  };

  return <PageContainer title={title} leftChild={<LeftChild />} actionButtons={<></>} />;
};

export default ServicePlanMenu;
