/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Approvals List query route
 */
import {forEach} from 'lodash';
import {Query} from './types';
import moment from 'moment';
import {DATE_TIME_LOCAL_FORMAT_MINUTES} from '../../utils/dateTimeUtils';
import {formatCamelCaseString} from '../../utils/app-utils';

export interface IApprovalsList {
  taskId: string;
  tailId: string;
  taskTitle: string;
  taskType: string;
  make: string;
  model: string;
  customerCode: string;
  serialNumber: string;
  submittedTstamp: string;
  approvedTstamp: string;
  submittedBy: string;
  approvedBy: string;
  rejectedTstamp: string;
  rejectedBy: string;
  approvers: string;
  status: string;
  message: string;
  createdTstamp: string;
  createdBy: string;
  updatedTstamp: string;
  updatedBy: string;
}

/**
 * Formats the date objects along with other list data
 * @param approvalsRawList Approvals List from Database
 * @returns Approvals with date formatted data
 */
export const transformApprovalList = (approvalsRawList: IApprovalsList[] | null): IApprovalsList[] => {
  if (!approvalsRawList) return null;
  const approvals: IApprovalsList[] = [];
  forEach(approvalsRawList, approval => {
    approvals.push({
      ...approval,
      taskTitle: formatCamelCaseString(approval.taskTitle),
      submittedTstamp: approval.submittedTstamp
        ? moment.utc(approval.submittedTstamp).format(DATE_TIME_LOCAL_FORMAT_MINUTES)
        : null,
      approvedTstamp: approval.approvedTstamp
        ? moment.utc(approval.approvedTstamp).format(DATE_TIME_LOCAL_FORMAT_MINUTES)
        : null,
      rejectedTstamp: approval.rejectedTstamp
        ? moment.utc(approval.rejectedTstamp).format(DATE_TIME_LOCAL_FORMAT_MINUTES)
        : null,
      createdTstamp: moment.utc(approval.createdTstamp).format(DATE_TIME_LOCAL_FORMAT_MINUTES),
      updatedTstamp: approval.updatedTstamp
        ? moment.utc(approval.updatedTstamp).format(DATE_TIME_LOCAL_FORMAT_MINUTES)
        : null
    });
  });
  return approvals;
};

export const approvalsListQuery: Query<IApprovalsList[]> = {
  route: 'approval/list',
  transform: transformApprovalList
};
