/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Resume Service Plan Details
 */
import React from 'react';
import {DateTime} from 'luxon';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewSection,
  ReviewSubSection
} from '../ReviewInformationStyles';
import {renderReviewInformation} from '../util';
import {IResumeServicePlanDetail} from '../reviewInformationTypes';

const ResumeServicePlanDetail: React.FC<IResumeServicePlanDetail> = ({idPrefix, servicePlanDetail, approvalInfo}) => {
  const resumeStartDate = servicePlanDetail?.resumeTstamp
    ? DateTime.fromISO(servicePlanDetail?.resumeTstamp).toLocaleString()
    : null;
  const requestDate = approvalInfo?.submittedTstamp
    ? DateTime.fromISO(approvalInfo?.submittedTstamp).toLocaleString()
    : null;
  return (
    <ReviewSection>
      <ReviewItemTitle>Resume SERVICE</ReviewItemTitle>
      <ReviewItemSubTitle>Please review the information below for approval.</ReviewItemSubTitle>
      <ReviewSubSection>
        <ReviewItems>
          <ReviewItem>
            <ReviewItemLabel>Date Requested</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__planDetails--requestedDate`}>
              {renderReviewInformation(requestDate)}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemLabel>Requested Start Date</ReviewItemLabel>
            <ReviewItemValue id={`${idPrefix}__planDetails--resumeDate`}>
              {renderReviewInformation(resumeStartDate)}
            </ReviewItemValue>
          </ReviewItem>
        </ReviewItems>
      </ReviewSubSection>
    </ReviewSection>
  );
};

export default ResumeServicePlanDetail;
