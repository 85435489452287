/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Approve Completion Popup Modal
 */
import React from 'react';

import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';

import PopUpModal from '../../common/Modal';
import {HEADER_ICON_BORDER_COLOR, POPUP_TEXT_COLOR} from '../../common/theme/Colors';
import ApprovalProgress from './ApprovalProgress';
import {IApprovalProcessSteps} from '../../../utils/customHooks';

const ApprovalRequestModalContainer = styled.div`
  width: 400px;
  display: flex;
  direction: row;
  margin-left: -10px;
  margin-right: -16px;
  margin-bottom: 20px;
  .closeIcon {
    width: 10%;
    position: absolute;
    right: 10px;
    margin-top: 10px;
    color: ${POPUP_TEXT_COLOR};
    cursor: pointer;
  }
`;

interface ApprovalRequestProps {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  approvalSteps: IApprovalProcessSteps[];
}
const ApprovalRequestCompletionPopupModal: React.FC<ApprovalRequestProps> = ({
  openModal,
  setOpenModal,
  approvalSteps
}) => {
  // function to close the modal.
  const closeModal = () => {
    setOpenModal(false);
  };

  /**
   * method that return the header of the modal
   * @returns modal header
   */
  const ApproveRequestCompletionHeader = () => {
    return (
      <ApprovalRequestModalContainer>
        <CloseIcon className="closeIcon" onClick={closeModal} />
      </ApprovalRequestModalContainer>
    );
  };

  return (
    <div>
      <PopUpModal
        id="aircraftValidateModal"
        open={openModal}
        title={<ApproveRequestCompletionHeader />}
        titlecolor={HEADER_ICON_BORDER_COLOR}
        borderRadius="16px"
        padding={'10px'}
      >
        <ApprovalProgress approvalSteps={approvalSteps} />
      </PopUpModal>
    </div>
  );
};

export default ApprovalRequestCompletionPopupModal;
