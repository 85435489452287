/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Page ReviewInformation
 */
import Check from '@mui/icons-material/Check';
import {find, isNil} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useOutletContext} from 'react-router-dom';
import {DataToolTip} from '@viasat/insights-components';
import {NextButton, NormalButton} from '../../CommonStyles';
import {IReviewInformation} from '../../../store/queries/getRequestInformationQuery';
import AircraftDetail from '../AircraftDetail';
import RequestDetail from '../RequestDetail';

import AccessControl from '../../common/accessControl/AccessControl';
import {useStore} from '../../../store/Store';
import ApprovalRequestCompletionPopupModal from './ApprovalRequestCompletionPopupModal';
import DeclineRequestPopupModal from './DeclineRequestPopupModal';
import RequestHistory from '../RequestHistory';
import {
  AIRCRAFT_NOT_ONBOARDED_TOOLTIP_MSG,
  APPROVAL_PROGRESS_STEP_LABELS,
  FACTORY_TEST_RATE_PANS,
  getApprovalProcessConfig,
  JIRA_CREATION_TASKS,
  KU_NETWORK,
  SERVICE_PLAN_REQUEST_TYPES,
  SF_SERVICE_SITE_STATUS,
  TASK_STATUS,
  TASK_TYPES
} from '../../common/constants';
import {ApprovalInfoAction} from '../../../store/reducers/ApprovalReducer';
import {
  useApprovalProcessStepsUpdate,
  useApproveRequest,
  useCreateAccount,
  useCreateConfigurationItems,
  useCreateJiraTicket,
  useCreateServiceSite,
  useCreateUpdateConfigurationItems,
  useUpdateServiceSite
} from '../../../utils/customHooks';
import {ReviewContainer, ReviewPageActions} from '../ReviewInformationStyles';
import EquipmentDetail from './EquipmentDetail';
import ChangeAircraftDetail from './ChangeAircraftDetail';
import NewServicePlanDetail from './NewServicePlanDetail';
import ChangeServicePlanDetail from './ChangeServicePlanDetail';
import SuspendServicePlanDetail from './SuspendServicePlanDetail';
import BarringServicePlanDetail from './BarringServicePlanDetail';
import ResumeServicePlanDetail from './ResumeServicePlanDetail';
import DecommissionServicePlanDetail from './DecommissionServicePlanDetail';
import CustomerDetail from './CustomerDetail';
import RequestAircraftAccessDetail from './RequestAircraftAccessDetail';

interface ReviewInformationPageProps {
  title: string;
}

const ReviewInformationPage: React.FC<ReviewInformationPageProps> = ({title}) => {
  const idPrefix = 'reviewApprovalInfo';

  const {store, dispatch} = useStore();
  const {refreshReviewInfo} = store.approvalInfo;
  const {varAccountList} = store.app;

  const navigate = useNavigate();
  const {isAdmin} = store.init;

  const [taskIdForApproval, setTaskIdForApproval] = useState<string>(null);
  const [taskIdForDecline, setTaskIdForDecline] = useState<string>(null);
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);
  const [createJira, setCreateJira] = useState<boolean>(false);
  const [reviewTaskType, setReviewTaskType] = useState<string | null>(null);
  const [varName, setVarName] = useState<string | null>(null);
  const [approvalProcessSteps, setApprovalProcessSteps] = useState([]);

  const [siteId, setSiteId] = useState<string | null>(null);

  const {taskId} = useParams();

  const approvalRequestInfo = useOutletContext<IReviewInformation>();

  useEffect(() => {
    if (reviewTaskType) {
      const approvalSteps = getApprovalProcessConfig(reviewTaskType);
      setApprovalProcessSteps(approvalSteps);
    }
  }, [reviewTaskType]);

  // Updates the Task Type based on the review request data
  useEffect(() => {
    if (isNil(approvalRequestInfo)) return;
    setReviewTaskType(approvalRequestInfo.taskDetail.taskType);
    setSiteId(
      approvalRequestInfo.taskDetail.taskType === TASK_TYPES.CHANGE_AIRCRAFT_DETAILS
        ? approvalRequestInfo.existingAircraft.siteId
        : approvalRequestInfo.taskDetail.siteId
    );
    let valueAddedResellerName = approvalRequestInfo?.equipmentInformation?.varAccountName;
    if (!valueAddedResellerName) {
      const findVarNameById = find(varAccountList, {varAccountId: approvalRequestInfo?.existingAircraft?.accountId});
      valueAddedResellerName = findVarNameById?.varName;
    }
    setVarName(valueAddedResellerName);
    if (approvalRequestInfo.taskDetail.network !== KU_NETWORK) {
      const taskType = approvalRequestInfo.taskDetail.taskType;
      if (JIRA_CREATION_TASKS.includes(taskType)) {
        setCreateJira(true);
      } else if (taskType === TASK_TYPES.NEW_SERVICE_PLAN && !isNil(approvalRequestInfo.existingAircraft)) {
        if (
          approvalRequestInfo.existingAircraft.status === SF_SERVICE_SITE_STATUS.FACTORY_TEST &&
          approvalRequestInfo.servicePlanInformation.servicePlanType === FACTORY_TEST_RATE_PANS.KA
        ) {
          setCreateJira(false);
        } else {
          setCreateJira(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalRequestInfo]);

  const approveRequest = () => {
    setShowLoadingModal(true);
    setTaskIdForApproval(taskId);
  };
  const declineRequest = () => {
    setTaskIdForDecline(taskId);
    setShowDeclineModal(true);
  };

  const editRequest = () => {
    navigate(`/review-request/${taskId}/edit`);
  };

  // New Terminal Installation
  // 1. Service Site Creations
  const {serviceSiteCreateData, isServiceSiteCreationLoading} = useCreateServiceSite(taskIdForApproval, reviewTaskType);

  useApprovalProcessStepsUpdate(
    isServiceSiteCreationLoading && serviceSiteCreateData && serviceSiteCreateData.status > 300,
    !isServiceSiteCreationLoading && Boolean(serviceSiteCreateData?.id),
    APPROVAL_PROGRESS_STEP_LABELS.SERVICE_SITE_CREATE,
    approvalProcessSteps,
    setApprovalProcessSteps
  );

  // Jira Creation
  const {jiraData, isJiraCreationLoading} = useCreateJiraTicket(taskIdForApproval, serviceSiteCreateData, createJira);
  useApprovalProcessStepsUpdate(
    isJiraCreationLoading && jiraData && !jiraData.isSuccess,
    !isJiraCreationLoading && Boolean(jiraData),
    APPROVAL_PROGRESS_STEP_LABELS.JIRA_CREATE,
    approvalProcessSteps,
    setApprovalProcessSteps
  );

  const {configItemCreateData, isConfigItemCreationInProgress} = useCreateConfigurationItems(
    taskIdForApproval,
    serviceSiteCreateData,
    reviewTaskType
  );

  useApprovalProcessStepsUpdate(
    isConfigItemCreationInProgress &&
      configItemCreateData &&
      configItemCreateData.status > 300 &&
      !Boolean(configItemCreateData?.data),
    !isConfigItemCreationInProgress && Boolean(configItemCreateData?.data),
    APPROVAL_PROGRESS_STEP_LABELS.CONFIG_ITEM_CREATE,
    approvalProcessSteps,
    setApprovalProcessSteps
  );

  const {accountCreateData, isAccountCreationInProgress} = useCreateAccount(taskIdForApproval, reviewTaskType, siteId);
  useApprovalProcessStepsUpdate(
    isAccountCreationInProgress && accountCreateData && accountCreateData.status > 300,
    !isAccountCreationInProgress && Boolean(accountCreateData),
    APPROVAL_PROGRESS_STEP_LABELS.ACCOUNT_CREATE,
    approvalProcessSteps,
    setApprovalProcessSteps
  );

  // 4. New Service Plan update/Replace Hardware
  const {serviceSiteData, isServiceUpdateInProgress} = useUpdateServiceSite(
    taskIdForApproval,
    reviewTaskType,
    siteId,
    accountCreateData
  );
  useApprovalProcessStepsUpdate(
    isServiceUpdateInProgress && !serviceSiteData?.isSuccess,
    !isServiceUpdateInProgress && serviceSiteData.isSuccess,
    APPROVAL_PROGRESS_STEP_LABELS.SERVICE_SITE_UPDATE,
    approvalProcessSteps,
    setApprovalProcessSteps
  );

  const updatedJiraDetails = useCreateJiraTicket(taskIdForApproval, serviceSiteData, createJira);

  useApprovalProcessStepsUpdate(
    updatedJiraDetails.isJiraCreationLoading && Boolean(updatedJiraDetails?.jiraData),
    !updatedJiraDetails.isJiraCreationLoading && updatedJiraDetails?.jiraData?.isSuccess,
    APPROVAL_PROGRESS_STEP_LABELS.JIRA_CREATE,
    approvalProcessSteps,
    setApprovalProcessSteps
  );

  // 5. Update Configuration Items
  const {configItemUpdateData, isConfigItemUpdateInProgress} = useCreateUpdateConfigurationItems(
    taskIdForApproval,
    siteId,
    reviewTaskType
  );
  useApprovalProcessStepsUpdate(
    isConfigItemUpdateInProgress && configItemUpdateData && !configItemUpdateData.isSuccess,
    !isConfigItemUpdateInProgress && configItemUpdateData?.isSuccess,
    APPROVAL_PROGRESS_STEP_LABELS.CONFIG_ITEM_UPDATE,
    approvalProcessSteps,
    setApprovalProcessSteps
  );

  // This is to ensure we do not hit the Snowflake Approvals until we finish the New Install of the aircraft and LRUs
  const isNewTerminalInstallInProgress =
    !isNil(reviewTaskType) &&
    reviewTaskType === TASK_TYPES.NEW_INSTALL &&
    (isServiceSiteCreationLoading ||
      !serviceSiteCreateData ||
      !configItemCreateData ||
      isConfigItemCreationInProgress ||
      isJiraCreationLoading);

  // This is to ensure we do not hit the Snowflake approvals until we finish the new account create and update process
  const isNewAccountCreationInProgress =
    !isNil(reviewTaskType) &&
    reviewTaskType === TASK_TYPES.NEW_CUSTOMER &&
    (isServiceUpdateInProgress || !accountCreateData || !serviceSiteData);

  // This is to ensure we do not hit the Snowflake approvals until we finish the service site updates
  const isServicePlanUpdateInProgress =
    !isNil(reviewTaskType) &&
    (reviewTaskType === TASK_TYPES.NEW_SERVICE_PLAN ||
      reviewTaskType === TASK_TYPES.REPLACE_HARDWARE ||
      reviewTaskType === TASK_TYPES.CHANGE_SERVICE_PLAN ||
      reviewTaskType === TASK_TYPES.CHANGE_AIRCRAFT_DETAILS) &&
    (isServiceUpdateInProgress || !serviceSiteData || (createJira && updatedJiraDetails.isJiraCreationLoading));

  // This is to ensure we do not hit the Snowflake approvals until we finish the configuration items updates
  const isConfigItemsUpdateInProgress =
    !isNil(reviewTaskType) &&
    reviewTaskType === TASK_TYPES.REPLACE_HARDWARE &&
    (isConfigItemUpdateInProgress || !configItemUpdateData);

  const {approveData, approveRequestLoading} = useApproveRequest(
    taskIdForApproval,
    reviewTaskType,
    isNewTerminalInstallInProgress,
    isNewAccountCreationInProgress,
    isServicePlanUpdateInProgress,
    isConfigItemsUpdateInProgress
  );

  useApprovalProcessStepsUpdate(
    approveRequestLoading && (Boolean(approveData) || approveData?.updated === 0),
    !approveRequestLoading && Boolean(approveData),
    APPROVAL_PROGRESS_STEP_LABELS.FINAL_APPROVAL,
    approvalProcessSteps,
    setApprovalProcessSteps
  );

  const closeCompletionModal = () => {
    setShowLoadingModal(false);
  };

  useEffect(() => {
    if (refreshReviewInfo !== -1) {
      setTimeout(() => {
        dispatch({
          type: ApprovalInfoAction.REFRESH_REVIEW_INFO,
          payload: -1
        });
      }, 3000);
    }
  }, [refreshReviewInfo, showDeclineModal, dispatch]);

  useEffect(() => {
    if (approveRequestLoading || !approveData || isNewTerminalInstallInProgress) return;
    setTaskIdForApproval(null);
    dispatch({
      type: ApprovalInfoAction.REFRESH_REVIEW_INFO,
      payload: 1
    });
    // eslint-disable-next-line
  }, [
    approveData,
    approveRequestLoading,
    isNewTerminalInstallInProgress,
    isConfigItemCreationInProgress,
    serviceSiteCreateData,
    dispatch
  ]);

  return (
    <>
      {showLoadingModal ? (
        <ApprovalRequestCompletionPopupModal
          openModal={showLoadingModal}
          setOpenModal={closeCompletionModal}
          approvalSteps={approvalProcessSteps}
        />
      ) : (
        <></>
      )}
      {showDeclineModal ? (
        <DeclineRequestPopupModal
          taskId={taskIdForDecline}
          openModal={showDeclineModal}
          setOpenModal={setShowDeclineModal}
        />
      ) : (
        <></>
      )}

      <>
        <ReviewContainer isApproved={approvalRequestInfo.approvalDetail.status === TASK_STATUS.DECLINED}>
          <AircraftDetail
            idPrefix={idPrefix}
            taskDetail={approvalRequestInfo.taskDetail}
            approvalDetail={approvalRequestInfo.approvalDetail}
            varAccountName={varName}
          />
          {approvalRequestInfo.existingAircraft &&
          approvalRequestInfo.taskDetail.taskType === TASK_TYPES.CHANGE_AIRCRAFT_DETAILS ? (
            <ChangeAircraftDetail
              idPrefix={idPrefix}
              taskDetail={approvalRequestInfo.taskDetail}
              existingAircraft={approvalRequestInfo.existingAircraft}
            />
          ) : (
            <></>
          )}
          {approvalRequestInfo.existingAircraft &&
          approvalRequestInfo.taskDetail.taskType === TASK_TYPES.REQUEST_AIRCRAFT_ACCESS ? (
            <RequestAircraftAccessDetail
              idPrefix={idPrefix}
              taskDetail={approvalRequestInfo.taskDetail}
              existingAircraft={approvalRequestInfo.existingAircraft}
            />
          ) : (
            <></>
          )}
          {approvalRequestInfo.equipmentInformation ? (
            <EquipmentDetail
              idPrefix={idPrefix}
              taskDetail={approvalRequestInfo.taskDetail}
              equipmentDetail={approvalRequestInfo.equipmentInformation}
            />
          ) : (
            <></>
          )}
          {approvalRequestInfo.customerInformation ? (
            <CustomerDetail idPrefix={idPrefix} customerDetail={approvalRequestInfo.customerInformation} />
          ) : (
            <></>
          )}

          {approvalRequestInfo?.servicePlanInformation?.requestType === TASK_TYPES.NEW_SERVICE_PLAN ? (
            <NewServicePlanDetail
              idPrefix={idPrefix}
              taskDetails={approvalRequestInfo.taskDetail}
              servicePlanDetail={approvalRequestInfo.servicePlanInformation}
            />
          ) : (
            <></>
          )}
          {approvalRequestInfo?.servicePlanInformation?.requestType === TASK_TYPES.CHANGE_SERVICE_PLAN ? (
            <ChangeServicePlanDetail
              idPrefix={idPrefix}
              existingAircraft={approvalRequestInfo.existingAircraft}
              servicePlanDetail={approvalRequestInfo.servicePlanInformation}
            />
          ) : (
            <></>
          )}
          {approvalRequestInfo?.servicePlanInformation?.requestType?.includes('Suspend') ? (
            <SuspendServicePlanDetail
              idPrefix={idPrefix}
              servicePlanDetail={approvalRequestInfo.servicePlanInformation}
            />
          ) : (
            <></>
          )}
          {approvalRequestInfo?.servicePlanInformation?.requestType === SERVICE_PLAN_REQUEST_TYPES.BARRING_SERVICE ? (
            <BarringServicePlanDetail idPrefix={idPrefix} approvalDetails={approvalRequestInfo.approvalDetail} />
          ) : (
            <></>
          )}
          {approvalRequestInfo?.servicePlanInformation?.requestType === SERVICE_PLAN_REQUEST_TYPES.RESUME_SERVICE ? (
            <ResumeServicePlanDetail
              idPrefix={idPrefix}
              servicePlanDetail={approvalRequestInfo.servicePlanInformation}
              approvalInfo={approvalRequestInfo.approvalDetail}
            />
          ) : (
            <></>
          )}
          {approvalRequestInfo?.servicePlanInformation?.requestType ===
          SERVICE_PLAN_REQUEST_TYPES.DECOMMISSION_SERVICE ? (
            <DecommissionServicePlanDetail
              idPrefix={idPrefix}
              servicePlanDetail={approvalRequestInfo.servicePlanInformation}
            />
          ) : (
            <></>
          )}
          <RequestDetail
            idPrefix={idPrefix}
            taskDetail={approvalRequestInfo.taskDetail}
            approvalDetail={approvalRequestInfo.approvalDetail}
          />
          <RequestHistory
            idPrefix={idPrefix}
            requestHistory={approvalRequestInfo.approvalActivities}
            approvalDetail={approvalRequestInfo.approvalDetail}
          />
        </ReviewContainer>
        {approvalRequestInfo.approvalDetail.status !== TASK_STATUS.APPROVED ? (
          <ReviewPageActions>
            <AccessControl permitted={isAdmin}>
              <>
                {approvalRequestInfo?.taskDetail.taskType === TASK_TYPES.NEW_SERVICE_PLAN &&
                isNil(approvalRequestInfo?.existingAircraft) ? (
                  <DataToolTip arrow title={AIRCRAFT_NOT_ONBOARDED_TOOLTIP_MSG} placement="right-start">
                    <NextButton
                      disabled={true}
                      onClick={() => approveRequest()}
                      id={`${idPrefix}--approve-button`}
                      width={124}
                    >
                      <span id={`${idPrefix}--approveButton`} className="next-button-text">
                        <Check className="next-arrow" /> Approve
                      </span>
                    </NextButton>
                  </DataToolTip>
                ) : (
                  <NextButton onClick={() => approveRequest()} id={`${idPrefix}--approve-button`} width={124}>
                    <span id={`${idPrefix}--approveButton`} className="next-button-text">
                      <Check className="next-arrow" /> Approve
                    </span>
                  </NextButton>
                )}
                <NormalButton onClick={() => declineRequest()} id={`${idPrefix}--decline-button`}>
                  <span id={`${idPrefix}--declineButton`} className="button-text">
                    Decline
                  </span>
                </NormalButton>

                <NormalButton
                  onClick={() => editRequest()}
                  id={`${idPrefix}--edit-button`}
                  hide={approvalRequestInfo.taskDetail.taskType === TASK_TYPES.REQUEST_AIRCRAFT_ACCESS}
                >
                  <span id={`${idPrefix}--editButton`} className="button-text">
                    Edit
                  </span>
                </NormalButton>
              </>
            </AccessControl>
          </ReviewPageActions>
        ) : null}
      </>
    </>
  );
};

export default ReviewInformationPage;
