/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Approvals List query route
 */
import {Query} from './types';

export interface ICustomerInfo {
  tailId: string;
  serialNumber: string;
  organizationInfo: any;
  contactInfo: any;
  businessAddress: any;
  billingAddressInfo: any;
  billingContactInfo: any;
  aircraftHomeBaseAirport: string;
  visibleToManufacturer: string;
  flightAwareDataAccess: string;
  varAccountId?: string;
}

export interface IServicePlanInfo {
  tailId: string;
  serialNumber: string;
  servicePlanType: string;
  region: string;
  planStartDate: string;
  ipScope: string;
  mplsRouting: string;
  dataAllowance: string;
  suspensionTstamp?: string;
  resumeTstamp?: string;
  requestType?: string;
  requestReason?: string;
  servicePlanTerm?: string;
  kaBandIpExists?: boolean;
}
export interface IEquipmentInfo {
  tailId: string;
  serialNumber: string;
  make: string;
  model: string;
  networkType: string;
  kuModemInfo: any;
  kaModemInfo: any;
  kaAntennaInfo: any;
  kuAntennaInfo: any;
  routerInfo: any;
  acuInfo: any;
  apsuInfo: any;
  fanTrayInfo: any;
  varAccountName: string;
}

export interface IApprovalDetail {
  id: number;
  tailId: string;
  serialNumber: string;
  submittedTstamp: string;
  submittedBy: string;
  approvedBy: string;
  approvedTstamp: string;
  notes: string;
  rejectedBy: string;
  rejectedTstamp: string;
  status: string;
}
export interface ITaskData {
  taskId: string;
  tailId: string;
  serialNumber: string;
  status: string;
  taskType: string;
  taskTitle: string;
  taskDescription: string;
  make: string;
  model: string;
  network: string;
  customerCode: string;
  assignedTo: string;
  siteId?: string;
  installationType?: string;
  reason?: string;
}

export interface IAircraftBasicInfo {
  serialNumber: string;
  tailId: string;
  make: string;
  model: string;
  network: string;
  requestType?: string;
  installationType?: string;
  siteId?: string;
  servicePlan?: string;
  accountId?: string;
  status?: string;
}

export interface IReviewInformation {
  customerInformation: ICustomerInfo | null;
  servicePlanInformation: IServicePlanInfo | null;
  equipmentInformation: IEquipmentInfo | null;
  taskDetail: ITaskData | null;
  approvalDetail: IApprovalDetail | null;
  approvalActivities: any;
  existingAircraft: IAircraftBasicInfo | null;
}

export const getRequestInformationQuery: Query<IReviewInformation> = {
  route: 'approval/reviewRequest',
  transform: null
};
