/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: OpenTaskList query route
 */
import moment from 'moment';
import {forEach} from 'lodash';
import {Query} from './types';
import {DATE_TIME_LOCAL_FORMAT_MINUTES} from '../../utils/dateTimeUtils';
import {formatCamelCaseString} from '../../utils/app-utils';

export interface IOpenTaskList {
  taskId: string;
  tailId: string;
  serialNumber: string;
  network: string;
  status: string;
  taskType: string;
  taskTitle: string;
  message: string;
  taskDescription: string;
  make: string;
  model: string;
  customerCode: string;
  assignedTo: string;
  createdBy: string;
  createdTstamp: string;
  updatedBy: string;
  updatedTstamp: string;
}

/**
 * Formats the date objects along with other list data
 * @param openTasksRawList Open Tasks List from Database
 * @returns Open Tasks with date formatted data
 */
export const transformOpenTasks = (openTasksRawList: IOpenTaskList[] | null): IOpenTaskList[] => {
  if (!openTasksRawList) return null;
  const openTasks: IOpenTaskList[] = [];
  forEach(openTasksRawList, openTask => {
    openTasks.push({
      ...openTask,
      taskTitle: formatCamelCaseString(openTask.taskTitle),
      taskType: openTask.taskType,
      message: formatCamelCaseString(openTask.message),
      createdTstamp: moment.utc(openTask.createdTstamp).format(DATE_TIME_LOCAL_FORMAT_MINUTES),
      updatedTstamp: openTask.updatedTstamp
        ? moment.utc(openTask.updatedTstamp).format(DATE_TIME_LOCAL_FORMAT_MINUTES)
        : null
    });
  });
  return openTasks;
};

export const openTaskQuery: Query<IOpenTaskList[]> = {
  route: 'openTasks/list',
  transform: transformOpenTasks
};
