/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: App Utilities
 */

import {isNil} from 'lodash';
import {spaComs} from '@viasat/insights-spa-package';

const SESSION_STORAGE_EXCEPTIONS = ['iframeLocation', 'groupCode'];

/**
 * Clears local storage except for the items on the local storage exceptions list.
 */
export const safeClearLocalStorage = () => {};

/**
 * Performs a safe cleaning of session storage, by keeping stuff we still want to keep
 */
export const safeClearSessionStorage = () => {
  const exceptionMap: Record<string, string> = SESSION_STORAGE_EXCEPTIONS.reduce((memo, exception) => {
    const sessionStorageValue = sessionStorage.getItem(exception);
    return sessionStorageValue ? {...memo, [exception]: sessionStorageValue} : memo;
  }, {});

  sessionStorage.clear();

  Object.entries(exceptionMap).forEach(([key, value]) => {
    sessionStorage.setItem(key, value);
  });
};

/**
 * Performs the local part of the logout for either hotel or non-hotel logouts
 */
export const localLogout = () => {
  safeClearLocalStorage();
  safeClearSessionStorage();
};

/**
 * Handles a user logging out
 */
export const logout = () => {
  spaComs.logout();
};

/**
 * Formats a String with space added on each Capital letter match in the string
 * @param value String value to be formatted
 * @returns Formatted String with space added on each Capital letter match in the string
 */
export const formatCamelCaseString = (value: string): string => {
  if (isNil(value)) return;
  return value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};
