/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Main Menu
 */
import React, {useEffect} from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styled from '@emotion/styled';
import {useNavigate} from 'react-router-dom';
import {camelCase, startCase} from 'lodash';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InProgressIcon from '../common/icons/InProgressStatusIcon';
import PageContainer from './PageContainer';
import {
  BUTTON_HOVER_COLOR,
  FORM_COLOR,
  LABEL_COLOR,
  MAIN_MENU_DESC_COLOR,
  NEXT_BUTTON_DISABLED,
  NEXT_BUTTON_ENABLED,
  NEXT_BUTTON_TEXT_DISABLED_COLOR
} from '../../theme/Colors';
import {useStore} from '../../store/Store';
import {AppAction} from '../../store/reducers/AppReducer';
import AlertContainer from '../common/AlertContainer';
import {IMainMenu} from '../common/constants';
import {useClearBreadCrumb} from '../../utils/customHooks';
import {getMenuOptions, getStatusColor} from './util';
import {WHITE} from '../common/theme/Colors';

interface MainMenuProps {
  title: string;
  aircraftSerialNumber?: string;
}

const TitleLabel = styled.div`
  display: flex;
  width: 646px;
  height: 24px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 24px;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
  color: ${FORM_COLOR};
  flex: none;
  order: 0;
  flex-grow: 0;
  .no-serial-number {
    color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
  }
`;

const MainMenuDescription = styled.div`
  min-width: 300px;
  width: -webkit-fill-available;
  height: 72px;
  margin-bottom: 2px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${MAIN_MENU_DESC_COLOR};
  @media only screen and (max-width: 1720px) {
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 1608px) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 950px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 725px) {
    margin-bottom: 60px;
  }
`;
const MainMenuButtons = styled.button<{textColor?: string; status?: string}>`
  box-sizing: border-box;
  margin-bottom: 8px;
  background: white;
  display: flex;
  flex-direction: initial;
  align-items: center;
  padding: 8px 28px;
  gap: 8px;
  min-width: 300px;
  width: -webkit-fill-available;
  height: 48px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${props => (props.textColor ? props.textColor : LABEL_COLOR)};
  border: 1px solid ${NEXT_BUTTON_DISABLED};
  border-radius: 1000px;
  flex: none;
  order: 0;
  align-self: stretch;
  justify-content: space-between;
  flex-grow: 0;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
  }
  &:hover {
    background: ${BUTTON_HOVER_COLOR};
  }
  .option {
    display: flex;
    .arrow {
      width: 20px;
      height: 20px
      position: absolute;
      left: 16.67%;
      right: 16.67%;
      top: 16.67%;
      bottom: 16.67%;
      margin-left: 8px;
      color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
    }
   }
  .status {
    font-size: 16px;
    display: flex;
    margin-left: 8px;
    float: right;
   }
   .statusIcon {
    padding: ${props => (props.status !== 'Completed' ? '4px' : '0px')};
    
   }

`;

const MainMenuButtonPrimary = styled.button`
  box-sizing: border-box;
  margin-bottom: 8px;
  background: ${NEXT_BUTTON_ENABLED};
  display: flex;
  flex-direction: initial;
  align-items: center;
  padding: 8px 28px;
  gap: 8px;
  width: fit-content;
  height: 48px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${WHITE};
  border-radius: 1000px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  border: none;
  cursor: pointer;
`;
const SerialNumberValue = styled.div`
  margin-left: 5px;
  color: ${NEXT_BUTTON_ENABLED};
`;

const MainMenuContainer = styled.div`
  width: 100%;
`;

const ButtonLabelContainer = styled.div<{marginTop?: number}>`
  flex-direction: column;
  margin-bottom: 50px;
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : 0)};
`;
const MenuLabel = styled.div`
  margin-bottom: 26px;
`;

const ID_PREFIX = 'mainMenu';

/**
 * Gets the Icon based on status
 * @param context Context of Menu options
 * @param currentStatus Request completion status
 * @returns Status based color
 */
export const getStatusIcon = (context: string, currentStatus: string) => {
  const allStatus = {
    'In Progress':
      context === ID_PREFIX ? <InProgressIcon id="inProgressIcon" /> : <CheckCircleOutlineIcon id="inProgressIcon" />,
    Completed: <CheckCircleOutlineIcon />,
    'Request Submitted': <CheckCircleOutlineIcon id="requestSubmittedIcon" />,
    Added: <CheckCircleOutlineIcon id="requestAddedIcon" style={{fontSize: 24}} />
  };
  return allStatus[currentStatus];
};
const MainMenu: React.FC<MainMenuProps> = ({title, aircraftSerialNumber}) => {
  const navigate = useNavigate();
  const {store, dispatch} = useStore();
  const {isInternal} = store.init;
  const {alertInfo} = store.app;
  const currentGroupCode = store.customer.current.code;
  aircraftSerialNumber = store.aircraftInfo.serialNumber;
  const {isNewInstall, make, model, serialNumber, tailId, installationType} = store.aircraftInfo;
  const statusInfo = store.statusInfo;
  const isAircraftInfoAdded = make && model && serialNumber && tailId && installationType ? true : false;

  const mainMenuOptions = getMenuOptions(isNewInstall, isInternal, statusInfo, currentGroupCode, isAircraftInfoAdded);

  const handleAircraftSerialNumberClick = () => {
    navigate('/identify-aircraft');
  };

  const handleOpenTaskClick = () => {
    navigate('/lists/open-tasks');
  };

  const handleMainMenuOptionClick = (navigationPath: string) => {
    navigate(navigationPath);
  };

  useClearBreadCrumb();

  useEffect(() => {
    return () =>
      dispatch({
        type: AppAction.SET_ALERT_INFO,
        payload: {showAlert: false}
      });
    //eslint-disable-next-line
  }, []);

  const LeftChild = () => {
    return (
      <MainMenuContainer>
        {alertInfo ? (
          <AlertContainer
            showAlert={alertInfo.showAlert}
            alertOperation={alertInfo.operation}
            primaryMessage={alertInfo.message}
            detailInformation={alertInfo.details}
          />
        ) : (
          <></>
        )}
        <TitleLabel>
          Aircraft Serial Number:
          <SerialNumberValue
            id={`${ID_PREFIX}--aircraftSerialNumber`}
            className={aircraftSerialNumber ? '' : 'no-serial-number'}
          >
            {aircraftSerialNumber ? aircraftSerialNumber : '--'}
          </SerialNumberValue>
        </TitleLabel>
        {!aircraftSerialNumber ? (
          <>
            <MainMenuDescription>
              Enter the serial number of the aircraft you want to work with. If you are coming back to finish an open
              task select it from the 'Open Tasks' option.
            </MainMenuDescription>
            <ButtonLabelContainer>
              <MenuLabel>Identify an aircraft via serial number:</MenuLabel>
              <MainMenuButtons
                id={`${ID_PREFIX}--aircraftSerialNumberButton`}
                onClick={() => handleAircraftSerialNumberClick()}
              >
                Aircraft Serial Number <ArrowForwardIcon className="arrow" />
              </MainMenuButtons>
            </ButtonLabelContainer>
            <ButtonLabelContainer>
              <MenuLabel> Choose this option to see a list of all open tasks for all your aircraft:</MenuLabel>
              <MainMenuButtons onClick={() => handleOpenTaskClick()}>
                Open Tasks <ArrowForwardIcon className="arrow" />
              </MainMenuButtons>
            </ButtonLabelContainer>
          </>
        ) : (
          <>
            <MainMenuDescription>
              You have identified an aircraft via serial number. To continue working on this aircraft, select from the
              options below.
            </MainMenuDescription>
            {mainMenuOptions.map((menuOption: IMainMenu, idx: number) => {
              return (
                <div key={`${idx}`}>
                  <MainMenuButtons
                    textColor={getStatusColor(ID_PREFIX, startCase(menuOption.status))}
                    status={menuOption.status}
                    id={`${ID_PREFIX}--${camelCase(menuOption.name)}-button`.split(' ').join('')}
                    disabled={menuOption.isDisabled}
                    key={`${menuOption.id}-option`}
                    onClick={() => handleMainMenuOptionClick(menuOption.navigationPath)}
                  >
                    <span className="option">
                      {menuOption.name}
                      <ArrowForwardIcon className="arrow" />
                    </span>
                    {menuOption.status ? (
                      <span
                        className="status"
                        id={`${ID_PREFIX}--${camelCase(menuOption.name)}--${camelCase(menuOption.status)}--status`}
                      >
                        <span className="statusIcon">{getStatusIcon(ID_PREFIX, startCase(menuOption.status))}</span>
                      </span>
                    ) : (
                      <></>
                    )}
                  </MainMenuButtons>
                </div>
              );
            })}
            <ButtonLabelContainer marginTop={50}>
              <MenuLabel> Choose this option if you want to start working on a different aircraft:</MenuLabel>
              <MainMenuButtonPrimary
                id={`${ID_PREFIX}--aircraftSerialNumberButton`}
                onClick={() => handleAircraftSerialNumberClick()}
              >
                Select a different Aircraft
              </MainMenuButtonPrimary>
            </ButtonLabelContainer>
          </>
        )}
      </MainMenuContainer>
    );
  };
  return <PageContainer title={title} leftChild={<LeftChild />} />;
};

export default MainMenu;
