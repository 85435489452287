/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add Data Sharing Permission
 */
import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {isNil} from 'lodash';

import {addCustomerQuery} from '../../store/queries/addCustomerQuery';
import {updateCustomerQuery} from '../../store/queries/updateCustomerQuery';
import {AppAction} from '../../store/reducers/AppReducer';
import {CustomerInfoAction} from '../../store/reducers/CustomerInfoReducer';
import {useStore} from '../../store/Store';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import Breadcrumb from '../common/Breadcrumb';
import {SELECT_AN_OPTION} from '../common/constants';
import {
  BottomHorizontalRule,
  HeaderHorizontalRule,
  HeaderLabel,
  PageDescription,
  FormContainerLeftPanel
} from '../CommonStyles';
import PageContainer from '../mainMenu/PageContainer';
import {useCreateApprovalRequestOnCustomerInformationSubmit} from '../../utils/customHooks';
import useFetchV2 from '../../utils/useFetchV2';
import {detectInternalUserAsExternal} from '../mainMenu/util';
import FormSubmitButton from '../common/elements/FormSubmitButton';

const ID_PREFIX = 'dataSharingInformation';

interface AddDataSharingPermissionProps {
  title: string;
}

const LeftChild: React.FC<{setCustomerInfoReadyToSave: any}> = ({setCustomerInfoReadyToSave}) => {
  const location = useLocation();
  const intl = useIntl();
  const {handleSubmit, formState, watch, control} = useForm();
  const {store, dispatch} = useStore();
  const {varAccountList} = store.app;
  const [dataSharingPermissionFormData, setDataSharingPermissionFormData] = useState(null);
  const customerInfo = store.customerInfo;
  const navigate = useNavigate();
  const {isInternal, isValueAddedReseller} = store.init;
  const groupCode = store.customer.current.code;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
        color: '#465967'
      }
    }
  };

  if (customerInfo) {
    const varAccountByName = varAccountList?.find(varAccount => varAccount.varAccountId === customerInfo.varAccountId);
    const varAccountByGroupCode = varAccountList?.find(varAccount => varAccount.groupCode === groupCode);
    customerInfo.varAccountId = isValueAddedReseller
      ? varAccountByGroupCode?.varAccountId
      : varAccountByName?.varAccountId;
  }

  const initialValues = {
    varAccountId: customerInfo.varAccountId
      ? varAccountList?.find(varAccount => varAccount.varAccountId === customerInfo.varAccountId)?.varName
      : SELECT_AN_OPTION,
    visibleToManufacturer: customerInfo.visibleToManufacturer ? customerInfo.visibleToManufacturer : '',
    flightAwareDataAccess: customerInfo.flightAwareDataAccess ? customerInfo.flightAwareDataAccess : ''
  };

  let customerBillingInfoFormFields: FormField[] = [];
  if (!isValueAddedReseller) {
    customerBillingInfoFormFields.push({
      id: 'varAccountId',
      label: 'Make visible to Value Added Resellers (VARs)',
      type: FormFieldType.SELECT,
      value: initialValues.varAccountId,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: varAccountList?.map(varAccount => varAccount.varName),
      rules: {
        required: false,
        pattern: null
      }
    });
  }

  customerBillingInfoFormFields.push(
    {
      id: 'flightAwareDataAccess',
      label: 'Allow Viasat to access third-party flight tracking data for this aircraft?',
      type: FormFieldType.RADIO,
      value: initialValues.flightAwareDataAccess,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      },
      visibilityMessage: intl.formatMessage({id: `add-customer.access-to-third-party-data.read-carefully`}),
      radioOptions: [
        {value: 'Yes', isChecked: customerInfo.flightAwareDataAccess === 'yes' ? true : null},
        {value: 'No', isChecked: customerInfo.flightAwareDataAccess === 'no' ? true : null}
      ]
    },
    {
      id: 'visibleToManufacturer',
      label: 'Make this aircraft visible to manufacturer (OEM)?',
      type: FormFieldType.RADIO,
      value: initialValues.visibleToManufacturer,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      },
      visibilityMessage: intl.formatMessage({id: `add-customer.visible-to-manufacturer.read-carefully`}),
      radioOptions: [
        {value: 'Yes', isChecked: customerInfo.visibleToManufacturer === 'yes' ? true : null},
        {value: 'No', isChecked: customerInfo.visibleToManufacturer === 'no' ? true : null}
      ]
    }
  );

  useEffect(() => {
    if (dataSharingPermissionFormData === null) return;
    const varAccountByName = varAccountList?.find(
      varAccount => varAccount.varName === dataSharingPermissionFormData.varAccountId
    );
    const varAccountByGroupCode = varAccountList?.find(varAccount => varAccount.groupCode === groupCode);
    dispatch({
      type: CustomerInfoAction.SET_DATA_USAGE_PERMISSION,
      payload: {
        varAccountId: isValueAddedReseller ? varAccountByGroupCode?.varAccountId : varAccountByName?.varAccountId,
        visibleToManufacturer: dataSharingPermissionFormData.visibleToManufacturer,
        flightAwareDataAccess: dataSharingPermissionFormData.flightAwareDataAccess
      }
    });
    if (isInternal && !detectInternalUserAsExternal(groupCode, isInternal)) {
      setCustomerInfoReadyToSave(false);
      navigate('../add-customer-billing-info');
    } else {
      setCustomerInfoReadyToSave(true);
    }
    setCustomerInfoReadyToSave(true);
    // eslint-disable-next-line
  }, [dataSharingPermissionFormData]);

  return (
    <>
      <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
      <HeaderLabel>Data Sharing Permission</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription>Please select the sharing permission you need for the aircraft.</PageDescription>
      <BottomHorizontalRule />
      <FormContainerLeftPanel>
        <FormComponent
          idPrefix={ID_PREFIX}
          id="add-data-sharing-permission-form"
          formFields={customerBillingInfoFormFields}
          setFormData={setDataSharingPermissionFormData}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
      </FormContainerLeftPanel>
    </>
  );
};

const AddDataSharingPermission: React.FC<AddDataSharingPermissionProps> = ({title}) => {
  const [isCustomerInfoReadyToSave, setCustomerInfoReadyToSave] = useState<boolean>(false);
  const {store, dispatch} = useStore();
  const navigate = useNavigate();

  const {customerInfo, aircraftInfo} = store;
  const {breadcrumb} = store.app;
  const {isInternal} = store.init;
  const groupCode = store.customer.current.code;
  const taskId = customerInfo.taskId;
  // Whether the customer information is update or new
  const isUpdate = !isNil(taskId);
  const requestParams = useMemo(() => {
    if (!isCustomerInfoReadyToSave) return;
    const customerInfoTobeSubmitted = {...customerInfo};
    if (!isInternal || detectInternalUserAsExternal(groupCode, isInternal)) {
      delete customerInfoTobeSubmitted.contactInfo;
      delete customerInfoTobeSubmitted.billingContactInfo;
      delete customerInfoTobeSubmitted.businessAddress;
      delete customerInfoTobeSubmitted.billingAddressInfo;
    }
    return {
      isInternalUser: isInternal.toString(),
      groupCode: groupCode,
      ...aircraftInfo,
      ...customerInfoTobeSubmitted
    };
    // eslint-disable-next-line
  }, [isCustomerInfoReadyToSave]);
  const {isLoading: isCustomerInfoSaving, data: customerInfoResponse} = useFetchV2(
    {route: taskId ? updateCustomerQuery(taskId).route : addCustomerQuery.route, params: requestParams},
    taskId ? updateCustomerQuery(taskId).transform : addCustomerQuery.transform
  );
  const {isSubmitRequestLoading, submitResponse} = useCreateApprovalRequestOnCustomerInformationSubmit(
    customerInfoResponse?.taskId,
    isUpdate
  );
  useEffect(() => {
    if (isCustomerInfoSaving || !customerInfoResponse || isSubmitRequestLoading || !submitResponse) return;
    dispatch({
      type: CustomerInfoAction.SET_CUSTOMER_TASK_ID,
      payload: customerInfoResponse.taskId
    });
    dispatch({
      type: AppAction.SET_BREADCRUMB,
      payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
    });
    let operation,
      message,
      details = '';

    operation = 'added';
    message = 'New Customer information has been';
    details = 'New Customer information has been sent to Viasat MCC. ';

    dispatch({
      type: AppAction.SET_ALERT_INFO,
      payload: {
        showAlert: true,
        operation: operation,
        message: message,
        details: details + 'Support staff will reply via email as quickly as possible.'
      }
    });
    navigate('/main-menu');
    // eslint-disable-next-line
  }, [dispatch, isCustomerInfoSaving, customerInfoResponse, isSubmitRequestLoading, submitResponse]);
  return (
    <PageContainer
      title={title}
      leftChild={<LeftChild setCustomerInfoReadyToSave={setCustomerInfoReadyToSave} />}
      actionButtons={
        <FormSubmitButton
          buttonId="add-data-sharing-permission-save--button"
          formId="add-data-sharing-permission-form"
          disabled={false}
          hasArrowIcon={true}
          isLoading={(isCustomerInfoSaving || isSubmitRequestLoading) && !isNil(requestParams)}
          isSubmit={!isInternal || (isInternal && detectInternalUserAsExternal(groupCode, isInternal))}
        />
      }
    />
  );
};

export default AddDataSharingPermission;
