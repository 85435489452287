/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common component colors
 */

// eslint-disable-next-line
import {Colors as Shared} from '@viasat/insights-components';

export {Colors as Shared} from '@viasat/insights-components';

const hexToRgb = (hex: any) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : '';
};

// Common color definitions
export const ORANGE = '#EC7424';
export const WHITE = '#FFF';
export const GREY = '#626262';
export const BLACK = '#000';
export const GREEN = '#5ea215';
export const BLACK_RGB = '0, 0, 0';
export const BLUE = '#125986';
export const LIGHT_GREY = '#AAAAAA';
export const TRANSPARENT = '#00000000';
export const OFFLINE = '#7C8B8B';
export const OFFLINE_RGB = hexToRgb(OFFLINE);
export const IMPAIRED = '#FFA625';
export const IMPAIRED_RGB = hexToRgb(IMPAIRED);
export const ALERT_DIALOG_LEFT_BORDER = GREEN;
export const POPUP_TEXT_COLOR = '#202e39';
export const POPUP_TEXT_VALUE = '#040404';

export const BOLD_BLUE = '#185C87';

export const FIVE_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.05)';
export const TWELVE_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.12)';
export const FOURTEEN_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.14)';
export const TWENTY_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.2)';
export const THIRTY_PCT_TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.3)';
export const DRAG_BUTTON_BACKGROUND = '#F2F5F8';

export const MODAL_BG = WHITE;
export const MODAL_FONT_COLOR = '#444444';
export const PRINT_BUTTON_COLOR = '#444';

export const NO_DATA_COLOR = '#7C8B8B';

export const ACTIVE_COLOR = '#00819D';
export const VIASAT_LOGO_ICON_COLOR = '#465967';

// Button Section
export const ACTIVE_BUTTON_BACKGROUND = '#444444';
export const COMPLETED_OPTION_TEXT_COLOR = '#266705';
export const IN_PROGRESS_TEXT_COLOR = '#465967';
export const REQUEST_SUBMITTED_TEXT_COLOR = '#006EAD';

export const ISOLATE_ROUTE_BUTTON_ACTIVE_BACKGROUND = '#E2F0F8';

// Modal colors
export const MODAL_BACKGROUND = WHITE;
export const HEADER_ICON_BORDER_COLOR = '#DADADA';
export const POPUP_BLOCK_BACKGROUND_COLOR = '#F2F5F8';
export const PROGRESS_BAR_BG_COLOR = '#DEE4E8';
export const HELP_TEXT_COLOR = '#465967';
export const SUPPORT_BORDER_COLOR = '#111920';

export const BREADCRUMB_TEXT_COLOR = '#29126D';
export const BREADCRUMB_CURRENT_ROUTE_COLOR = '#006EAD';

export const CHECKBOX_ENABLED_COLOR = '#00819D';
export const CHECKBOX_COLOR = '#FFFFFF';

export const GRID_CELL_TEXT_ACTIVE_COLOR = '#000000';
export const GRID_CELL_LINK_COLOR = '#006EAD';
export const GRID_CELL_TEXT_DURATION_COLOR = '#9FAFBC';
export const GRID_CELL_DEFAULTED_TEXT_COLOR = '#9FAFBC';

export const RETIRE_LOOKUP_TITLE_COLOR = '#202E39';
export const RETIRE_LOOKUP_CONTENT_COLOR = '#465967';
export const RETIRE_LOOKUP_CANCEL_BUTTON_COLOR = RETIRE_LOOKUP_TITLE_COLOR;
export const RETIRE_LOOKUP_CANCEL_BACKGROUND = WHITE;
export const RETIRE_LOOKUP_BUTTON_COLOR = WHITE;
export const RETIRE_LOOKUP_BUTTON_BACKGROUND = '#CD3209';
export const RETIRE_LOOKUP_BUTTON_BORDER_COLOR = '#111920';

export const EDIT_REVIEW_INFORMATION_BANNER_BACKGROUND = '#F2F5F8';
