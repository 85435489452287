/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: New Terminal Installation utils
 */

import {isNil} from 'lodash';
import {EquipmentInfoState} from '../../store/reducers/EquipmentInfoReducer';

export interface IMenuOptions {
  id: string;
  name: string;
  equipment: string;
  isMandatory: boolean;
}

export enum APPROVAL_MODULES {
  EQUIPMENT = 'EQUIPMENT',
  CUSTOMER = 'CUSTOMER',
  SERVICE_PLAN = 'SERVICE_PLAN'
}

/**
 * This returns true/false depending on installation completion state
 * @param equipmentInfo equipmentInfo from store
 * @param newInstallOptions Installation menu
 * @returns true/false
 */
export const determineCompletionState = (
  equipmentInfo: EquipmentInfoState,
  newInstallOptions: IMenuOptions[]
): boolean => {
  let readyForSubmit = true;
  newInstallOptions.forEach(option => {
    if (
      option.isMandatory &&
      (isNil(equipmentInfo[option.equipment]) || isNil(equipmentInfo[option.equipment]['serialNumber']))
    ) {
      readyForSubmit = false;
      return;
    }
    if (!readyForSubmit) return false;
  });

  return readyForSubmit;
};
