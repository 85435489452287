/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Service Plan Information Form
 */
import {upperCase} from 'lodash';
import {useState} from 'react';

import FormComponent, {FormField, FormLayout} from '../../common/elements/Form';

import {ITaskData} from '../../../store/queries/getRequestInformationQuery';
import {
  useChangeServicePlanInfoForm,
  useChooseServicePlanForm,
  useDecommissionServicePlanForm,
  useKaIPAddressForm,
  useResumeServicePlanForm,
  useSuspendServicePlanForm
} from '../../../utils/servicePlanInfomrationFormHook';

import {IServicePlanDetail} from '../reviewInformationTypes';
import {SERVICE_PLAN_REQUEST_TYPES, TASK_TYPES} from '../../common/constants';
import {
  EditReviewInformationFormContainer,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewSection,
  ReviewSubSection
} from '../ReviewInformationStyles';

interface IServicePlanForm extends IServicePlanDetail {
  formId: string;
  setEquipmentFormData: (equipmentFormData: any) => void;
  formControlMethods: any;
  taskDetail: ITaskData | null;
}

const ServicePlanInformationForm: React.FC<IServicePlanForm> = ({
  idPrefix,
  formId,
  setEquipmentFormData,
  servicePlanDetail,
  formControlMethods
}) => {
  const {watch} = formControlMethods;
  const [planStartDate, setPlanStartDate] = useState(null);
  const [suspendDate, setSuspendDate] = useState(null);
  const [resumeDate, setResumeDate] = useState(null);
  const [decommissionDate, setDecommissionDate] = useState(null);

  const newServicePlanFormFields: FormField[] = [];

  const chooseServicePlanFormFields = useChooseServicePlanForm(
    servicePlanDetail.requestType === TASK_TYPES.NEW_SERVICE_PLAN
      ? {
          servicePlanTerm: servicePlanDetail.servicePlanTerm,
          servicePlanType: servicePlanDetail.servicePlanType,
          planStartDate: servicePlanDetail.planStartDate
        }
      : null,
    watch,
    planStartDate,
    setPlanStartDate,
    'servicePlanInfo'
  );

  chooseServicePlanFormFields && newServicePlanFormFields.push(...chooseServicePlanFormFields);

  const changeServicePlanFormFields = useChangeServicePlanInfoForm(
    servicePlanDetail.requestType === TASK_TYPES.CHANGE_SERVICE_PLAN
      ? {
          contractTerm: servicePlanDetail.servicePlanTerm,
          servicePlanType: servicePlanDetail.servicePlanType,
          planStartDate: servicePlanDetail.planStartDate,
          existingContractEndDate: ''
        }
      : null,
    watch,
    planStartDate,
    setPlanStartDate,
    'servicePlanInfo'
  );

  changeServicePlanFormFields && newServicePlanFormFields.push(...changeServicePlanFormFields);

  const addKaIpAddressFormFields = useKaIPAddressForm(
    servicePlanDetail.requestType === TASK_TYPES.NEW_SERVICE_PLAN ||
      servicePlanDetail.requestType === TASK_TYPES.CHANGE_SERVICE_PLAN
      ? {ipAddress: servicePlanDetail.ipScope}
      : null,
    'servicePlanInfo'
  );

  addKaIpAddressFormFields && newServicePlanFormFields.push(...addKaIpAddressFormFields);

  const suspendServicePlanFormFields = useSuspendServicePlanForm(
    servicePlanDetail.requestType === SERVICE_PLAN_REQUEST_TYPES.SUSPEND_FOR_MAINTENANCE ||
      servicePlanDetail.requestType === SERVICE_PLAN_REQUEST_TYPES.SUSPEND_FOR_MONTHLY_SUBSCRIPTION
      ? {
          suspensionType: servicePlanDetail.requestType,
          suspensionTstamp: servicePlanDetail.suspensionTstamp,
          resumeTstamp: servicePlanDetail.resumeTstamp
        }
      : null,

    {suspendDate, setSuspendDate},
    {resumeDate, setResumeDate},
    'servicePlanInfo'
  );

  suspendServicePlanFormFields && newServicePlanFormFields.push(...suspendServicePlanFormFields);

  const resumeServicePlanFormFields = useResumeServicePlanForm(
    servicePlanDetail.requestType === SERVICE_PLAN_REQUEST_TYPES.RESUME_SERVICE
      ? {
          planResumeDate: servicePlanDetail.resumeTstamp
        }
      : null,

    {resumeDate, setResumeDate},
    'servicePlanInfo'
  );

  resumeServicePlanFormFields && newServicePlanFormFields.push(...resumeServicePlanFormFields);

  const decomissionServicePlanFormFields = useDecommissionServicePlanForm(
    servicePlanDetail.requestType === SERVICE_PLAN_REQUEST_TYPES.DECOMMISSION_SERVICE
      ? {
          decommissionDate: servicePlanDetail.suspensionTstamp,
          decommissionReason: servicePlanDetail.requestReason,
          decommissionOtherReason: servicePlanDetail.requestReason
        }
      : null,

    {decommissionDate, setDecommissionDate},
    watch,
    'servicePlanInfo'
  );

  decomissionServicePlanFormFields && newServicePlanFormFields.push(...decomissionServicePlanFormFields);

  return (
    <ReviewSection>
      <ReviewItemTitle id={`${idPrefix}__servicePlanInformation_title`}>
        {upperCase(servicePlanDetail.requestType)}
      </ReviewItemTitle>
      <ReviewItemSubTitle id={`${idPrefix}__servicePlanInformation_subtitle`}>
        Please review the information below for approval.
      </ReviewItemSubTitle>
      <ReviewSubSection>
        <EditReviewInformationFormContainer>
          <FormComponent
            idPrefix={idPrefix}
            id={formId}
            formFields={newServicePlanFormFields}
            setFormData={setEquipmentFormData}
            formControlMethods={formControlMethods}
            layout={FormLayout.HORIZONTAL}
          ></FormComponent>
        </EditReviewInformationFormContainer>
      </ReviewSubSection>
    </ReviewSection>
  );
};

export default ServicePlanInformationForm;
