/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Lookup table Styles for the components
 */

import styled from '@emotion/styled';
import {
  NEXT_BUTTON_BORDER,
  NEXT_BUTTON_ENABLED,
  EDIT_BUTTON_DISABLED,
  WHITE_COLOR,
  LABEL_COLOR,
  FORM_COLOR,
  BORDER_COLOR,
  BUTTON_BORDER_COLOR,
  FORM_ERROR_COLOR,
  NEXT_BUTTON_TEXT_DISABLED_COLOR,
  NEXT_BUTTON_TEXT_ENABLED_COLOR
} from '../../../theme/Colors';

export const HeaderActionButton = styled.button<{disabled?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  gap: 4px;
  border-radius: 1000px;
  position: relative;
  height: 40px;
  width: 108px;
  right: 0px;
  margin-right: 20px;
  background: ${props => (props.disabled ? `${EDIT_BUTTON_DISABLED}` : `${NEXT_BUTTON_ENABLED}`)};
  border: 1px solid ${NEXT_BUTTON_BORDER};
  &.edit-retire-button {
    background: none;
    margin: 0;
    width: 67px;
    margin-right: 8px;
    color: ${FORM_COLOR};
    .header-action-icon {
      color: inherit;
    }
    .header-action-button-text {
      color: inherit;
    }
  }
  .header-action-icon {
    color: ${WHITE_COLOR};
  }
  .header-action-button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: ${props => (props.disabled ? `${NEXT_BUTTON_TEXT_DISABLED_COLOR}` : `${NEXT_BUTTON_TEXT_ENABLED_COLOR}`)};
    color: ${WHITE_COLOR};
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;
export const FormContainer = styled.div<{height?: number}>`
  width: 95%;
  height: ${props => props.height ? `${props.height}%`: '83%'};
  overflow: hidden;
  overflow-y: scroll;
  margin: 10px;
  padding: 10px;
  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #F1F5F8;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(216, 216, 216);
    border-radius: 4px;
  }
`;

export const AddForm = styled.div`
  box-sizing: border-box;
  padding: 0px;
  width: 350px;
  height: 100%;
  right: 0px;
  top: 60px;
  .dropdown-menu {
    font-size: 16px;
    font-family: 'Source Sans Pro';
    background: ${NEXT_BUTTON_BORDER};
    border-radius: 4px;
    width: 98%;
    height: 45px;
    color: ${LABEL_COLOR};
    z-index: 1000;
    padding: 0 10px;
  }
`;

export const AddFormHeader = styled.div`
  color: ${LABEL_COLOR};
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  padding: 10px;
  letter-spacing: 0.2px;
`;

export const AddFormLabels = styled.div`
  width: -webkit-fill-available;
  height: 20px;
  top: 200px;
  display: flex;
  flex-direction: row;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${FORM_COLOR};
  padding-top: 20px;
  margin-left: 10px;
`;

export const AddFormInputs = styled.input`
  border: 1px solid ${BORDER_COLOR};
  font-size: 16px;
  font-family: 'Source Sans Pro';
  background: ${NEXT_BUTTON_BORDER};
  border-radius: 4px;
  width: 88%;
  height: 40px;
  color: ${LABEL_COLOR};
  z-index: 1000;
  padding: 0 10px;
  margin-left: 10px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;

  .add-button {
    background: ${NEXT_BUTTON_ENABLED};
    color: ${WHITE_COLOR};
  }
  .cancel-button {
    background: ${WHITE_COLOR};
    border: 1px solid ${BUTTON_BORDER_COLOR};
  }
`;

export const DrawerButton = styled.button<{disabled?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  float: left;
  align-items: center;
  padding: 8px 24px;
  cursor: pointer;
  gap: 4px;
  border-radius: 1000px;
  position: relative;
  height: 40px;
  width: 45%;
  right: 0px;
  margin-left: 3%;
  margin-bottom: 10%;

  border: 1px solid ${NEXT_BUTTON_BORDER};

  .button-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
`;

export const FormError = styled.p`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${FORM_ERROR_COLOR};
  top: 280px;
  margin: 0;
  margin-left: 10px;
  padding: 0;
`;
