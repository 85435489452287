/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Common browser utilities
 */

/**
 * Returns data as string from a blob object
 * @param blobData - blob data to convert
 * @returns utf decoded csv text
 */
export function getRawDataArrayFromBlob(blobData: any) {
  const enc = new TextDecoder('utf-8');
  return enc.decode(new Uint8Array(blobData));
}

/**
 * Converts text into a Binary Large Object
 * @param data - desired data to download
 * @returns csv Blob
 */
export function getCsvBlobDataToDownload(data: any) {
  return new Blob([data], {
    type: 'text/csv'
  });
}

/**
 * Downloads data client side of the browser
 * @param data - desired data to download
 * @param filename - name of saved file
 */
export const csvDownload = async (data: any, filename: any) => {
  const csvData = getCsvBlobDataToDownload(data);
  const url = window.URL.createObjectURL(csvData);
  const elem = document.createElement('a');
  elem.setAttribute('hidden', '');
  elem.setAttribute('href', url);
  elem.setAttribute('download', filename);
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};
