/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Service Plan Infomration Form hooks
 */

import {filter, isEmpty, isNil} from 'lodash';
import {FieldValues, UseFormWatch} from 'react-hook-form';

import {useStore} from '../store/Store';

import {FormField, FormFieldType} from '../components/common/elements/Form';
import {MenuProps, ipv4Regex} from '../components/common/constants';

import {validateDropdownField} from './validationUtils';
import {makeContractTermOptional} from '../components/servicePlan/util';
import {HELP_TEXT_COLOR} from '../components/common/theme/Colors';
import {kaBandIPAddressRequired} from './config';

export const useChooseServicePlanForm = (
  servicePlanInfo: {
    servicePlanType: string;
    servicePlanTerm: string;
    planStartDate: string;
  },
  watch: UseFormWatch<FieldValues>,
  planStartDate: any,
  setPlanStartDate: (planStartDate: any) => void,
  objectIdPrefix?: string
): FormField[] => {
  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const {store} = useStore();

  if (!servicePlanInfo) {
    return;
  }

  const {isInternal} = store.init;

  const {servicePlans, servicePlanTerms} = store.app;
  const networkCapability =
    store.aircraftInfo.network === 'Dual Band (Ka & Ku)' ? 'Dual Band' : store.aircraftInfo.network;
  const networkSpecificServicePlans = filter(servicePlans, servicePlan =>
    servicePlan.network.includes(networkCapability)
  );

  const servicePlanCheck = watch(`${idPrefix + 'servicePlanType'}`);
  const isContractTermOptional = makeContractTermOptional(servicePlanCheck);

  const planStartDateFormatted =
    planStartDate && planStartDate.c
      ? planStartDate.c.month + '/' + planStartDate.c.day + '/' + planStartDate.c.year
      : servicePlanInfo.planStartDate;

  const servicePlanInfoFormFields: FormField[] = [
    {
      id: `${idPrefix + 'servicePlanType'}`,
      label: 'Service Plan Type',
      type: FormFieldType.SELECT,
      value: servicePlanInfo.servicePlanType,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues:
        networkSpecificServicePlans && networkSpecificServicePlans.length
          ? networkSpecificServicePlans.map(servicePlan =>
              isInternal ? servicePlan.planName : servicePlan.generalPlanName
            )
          : [],
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Service Plan Type')
      }
    },
    {
      id: `${idPrefix + 'servicePlanTerm'}`,
      label: 'Service Plan Term',
      type: FormFieldType.SELECT,
      value: servicePlanInfo.servicePlanTerm,
      disabled: isContractTermOptional,
      menuProps: MenuProps,
      dropDownValues:
        servicePlanTerms && servicePlanTerms.length
          ? servicePlanTerms.map(servicePlanTerm => servicePlanTerm.term)
          : [],
      description: null,
      rules: {
        required: isContractTermOptional ? false : true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Service Plan Term')
      }
    },
    {
      id: `${idPrefix + 'planStartDate'}`,
      label: 'Plan Start Date',
      type: FormFieldType.DATE,
      value: planStartDateFormatted,
      setDate: setPlanStartDate,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: [],
      description: null,
      rules: {
        required: true,
        pattern: null
      }
    }
  ];

  return servicePlanInfoFormFields;
};

export const useKaIPAddressForm = (servicePlanInfo: {ipAddress: string}, objectIdPrefix?: string): FormField[] => {
  if (!servicePlanInfo || isNil(servicePlanInfo.ipAddress) || isEmpty(servicePlanInfo.ipAddress)) {
    return;
  }
  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const servicePlanInfoFormFields: FormField[] = [
    {
      id: `${idPrefix + 'ipAddress'}`,
      label: 'Ka IP Address',
      type: FormFieldType.INPUT,
      value: servicePlanInfo.ipAddress,
      disabled: false,
      dropDownValues: [],
      description: null,
      rules: {
        required: kaBandIPAddressRequired,
        pattern: {value: new RegExp(ipv4Regex), message: 'Invalid IP Address'}
      }
    }
  ];
  return servicePlanInfoFormFields;
};

export const useChangeServicePlanInfoForm = (
  servicePlanInfo: {
    servicePlanType: string;
    contractTerm: string;
    planStartDate: string;
    existingContractEndDate: string;
  },
  watch: UseFormWatch<FieldValues>,
  planStartDate: any,
  setPlanStartDate: (planStartDate: any) => void,
  objectIdPrefix?: string
): FormField[] => {
  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const {store} = useStore();

  if (!servicePlanInfo) {
    return;
  }

  const {isInternal} = store.init;

  const {servicePlans, servicePlanTerms} = store.app;
  const networkCapability =
    store.aircraftInfo.network === 'Dual Band (Ka & Ku)' ? 'Dual Band' : store.aircraftInfo.network;
  const networkSpecificServicePlans = filter(servicePlans, servicePlan =>
    servicePlan.network.includes(networkCapability)
  );

  const servicePlanCheck = watch(`${idPrefix + 'servicePlanType'}`);
  const isContractTermOptional = makeContractTermOptional(servicePlanCheck);
  const existingContractEndDateCheck = watch(`${idPrefix + 'existingContractEndDate'}`);

  const planStartDateFormatted =
    planStartDate && planStartDate.c
      ? planStartDate.c.month + '/' + planStartDate.c.day + '/' + planStartDate.c.year
      : servicePlanInfo.planStartDate;

  const servicePlanInfoFormFields: FormField[] = [
    {
      id: `${idPrefix + 'servicePlanType'}`,
      label: 'Service Plan Type',
      type: FormFieldType.SELECT,
      value: servicePlanInfo.servicePlanType,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues:
        networkSpecificServicePlans && networkSpecificServicePlans.length
          ? networkSpecificServicePlans.map(servicePlan =>
              isInternal ? servicePlan.planName : servicePlan.generalPlanName
            )
          : [],
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Service Plan Type')
      }
    },
    {
      id: `${idPrefix + 'planStartDate'}`,
      label: 'Subscription Start Date',
      type: FormFieldType.DATE,
      value: planStartDateFormatted,
      setDate: setPlanStartDate,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: [],
      placeholder: '__/__/____',
      description: null,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: `${idPrefix + 'existingContractEndDate'}`,
      label: 'Use the existing contract end date',
      type: FormFieldType.RADIO,
      value: servicePlanInfo.existingContractEndDate,
      disabled: isContractTermOptional,
      menuProps: MenuProps,
      dropDownValues: [],
      description: null,
      rules: {
        required: isContractTermOptional ? false : true,
        pattern: null
      },
      radioOptions: [{value: 'Yes'}, {value: 'No'}]
    },
    {
      id: `${idPrefix + 'servicePlanTerm'}`,
      label: 'Contract Term',
      type: FormFieldType.SELECT,
      value: servicePlanInfo.contractTerm,
      disabled: isContractTermOptional || existingContractEndDateCheck === 'yes' ? true : false,
      menuProps: MenuProps,
      dropDownValues:
        servicePlanTerms && servicePlanTerms.length
          ? servicePlanTerms.map(servicePlanTerm => servicePlanTerm.term)
          : [],
      description: null,
      rules: {
        required: isContractTermOptional || existingContractEndDateCheck === 'yes' ? false : true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Service Plan Term')
      }
    }
  ];
  return servicePlanInfoFormFields;
};

export const useSuspendServicePlanForm = (
  servicePlanInfo: {suspensionType: string; suspensionTstamp: string; resumeTstamp: string},
  {suspendDate, setSuspendDate},
  {resumeDate, setResumeDate},
  objectIdPrefix?: string
): FormField[] => {
  if (!servicePlanInfo) {
    return;
  }
  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const suspensionStartDateFormatted =
    suspendDate && suspendDate.c
      ? suspendDate.c.month + '/' + suspendDate.c.day + '/' + suspendDate.c.year
      : servicePlanInfo.suspensionTstamp;
  const suspensionEndDateFormatted =
    resumeDate && resumeDate.c
      ? resumeDate.c.month + '/' + resumeDate.c.day + '/' + resumeDate.c.year
      : servicePlanInfo.resumeTstamp;

  const suspensionTypes = ['Suspend Monthly Service', 'Suspend Service for Maintenance'];

  const suspendServicePlanInfoFormFields: FormField[] = [
    {
      id: `${idPrefix + 'requestType'}`,
      label: 'Suspension Type',
      type: FormFieldType.SELECT,
      value: servicePlanInfo.suspensionType,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: suspensionTypes,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Suspension Type')
      }
    },
    {
      id: `${idPrefix + 'suspensionTstamp'}`,
      label: 'Suspension Start Date',
      type: FormFieldType.DATE,
      value: suspensionStartDateFormatted,
      setDate: setSuspendDate,
      disabled: false,
      menuProps: MenuProps,
      placeholder: 'Start Date',
      description: null,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: `${idPrefix + 'resumeTstamp'}`,
      label: 'Suspension End Date',
      type: FormFieldType.DATE,
      value: suspensionEndDateFormatted,
      setDate: setResumeDate,
      disabled: false,
      menuProps: MenuProps,
      placeholder: 'Estimated End Date (Optional)',
      description: null,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  return suspendServicePlanInfoFormFields;
};

export const useResumeServicePlanForm = (
  servicePlanInfo: {planResumeDate: string},
  {resumeDate, setResumeDate},
  objectIdPrefix?: string
): FormField[] => {
  if (!servicePlanInfo) {
    return;
  }
  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const planResumeDateFormatted =
    resumeDate && resumeDate.c
      ? resumeDate.c.month + '/' + resumeDate.c.day + '/' + resumeDate.c.year
      : servicePlanInfo.planResumeDate;
  const resumeServicePlanInfoFormFields: FormField[] = [
    {
      id: `${idPrefix + 'resumeTstamp'}`,
      label: 'Start Date',
      type: FormFieldType.DATE,
      value: planResumeDateFormatted,
      placeholder: '__/__/____',
      setDate: setResumeDate,
      disabled: false,
      menuProps: MenuProps,
      description: null,
      rules: {
        required: true,
        pattern: null
      }
    }
  ];
  return resumeServicePlanInfoFormFields;
};

export const useDecommissionServicePlanForm = (
  servicePlanInfo: {decommissionDate: string; decommissionReason: string; decommissionOtherReason: string},
  {decommissionDate, setDecommissionDate},
  watch: UseFormWatch<FieldValues>,
  objectIdPrefix?: string
): FormField[] => {
  if (!servicePlanInfo) {
    return;
  }
  const idPrefix = objectIdPrefix ? objectIdPrefix + '.' : '';

  const OTHER_OPTION = 'Other';
  const DECOMMISSION_REASONS = [
    'Contract ended and not renewing',
    'Aircraft sold',
    'Aircraft Airworthiness',
    'Inoperable terminal hardware',
    'Transfer of Service Provider',
    'Management Company transfer',
    'Customer is subject to Early Termination fees',
    OTHER_OPTION
  ];

  const TextFieldInputProps = {
    style: {
      maxHeight: 200,
      height: 70,
      color: HELP_TEXT_COLOR,
      fontFamily: 'Source Sans Pro'
    }
  };

  const decommissionDateFormatted =
    decommissionDate && decommissionDate.c
      ? decommissionDate.c.month + '/' + decommissionDate.c.day + '/' + decommissionDate.c.year
      : servicePlanInfo.decommissionDate;

  const selectedReasonType: string = watch(`${idPrefix + 'decommissionReason'}`);

  const decommissionOtherReason: string = watch(`${idPrefix + 'decommissionOtherReason'}`);

  const isOtherReason =
    selectedReasonType === OTHER_OPTION ||
    !DECOMMISSION_REASONS.filter(reasons => reasons !== OTHER_OPTION).includes(servicePlanInfo.decommissionReason);

  const servicePlanInfoFormFields: FormField[] = [
    {
      id: `${idPrefix + 'decommissionDate'}`,
      label: 'Decommission Date',
      type: FormFieldType.DATE,
      value: decommissionDateFormatted,
      setDate: setDecommissionDate,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: [],
      placeholder: '__/__/____',
      description: null,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: `${idPrefix + 'decommissionReason'}`,
      label: 'Reason',
      type: FormFieldType.SELECT,
      value: isOtherReason ? OTHER_OPTION : servicePlanInfo.decommissionReason,
      placeholder: 'tada',
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: DECOMMISSION_REASONS,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, _) => validateDropdownField(value, 'Reason')
      }
    }
  ];

  if (isOtherReason) {
    servicePlanInfoFormFields.push({
      id: `${idPrefix + 'decommissionOtherReason'}`,
      label: ' ',
      type: FormFieldType.TEXTFIELD,
      value: servicePlanInfo.decommissionOtherReason,
      placeholder: 'Reason',
      inputProps: TextFieldInputProps,
      isMultiline: true,
      fullWidth: true,
      disabled: false,
      rules: {
        required: 'Reason for Other is required',
        maxLength: {
          value: 250,
          message: 'Reason for Other cannot exceed more than 250 characters'
        },
        pattern: null
      },
      helperText: `${decommissionOtherReason?.length | 0} / 250`
    });
  }

  return servicePlanInfoFormFields;
};
