/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation Styles
 */

import styled from '@emotion/styled';
import {INFO_REQUIRED_TXT_COLOR, LABEL_COLOR, NEXT_BUTTON_DISABLED, REVIEW_ITEM_TITLE_COLOR} from '../../theme/Colors';
import {EDIT_REVIEW_INFORMATION_BANNER_BACKGROUND, WHITE} from '../common/theme/Colors';

export const ReviewContainer = styled.div<{isApproved: boolean; hasBanner?: boolean}>`
  display: flex;
  flex-direction: column;
  height: ${props =>
    props.isApproved ? 'calc(100% - 10px)' : props.hasBanner ? 'calc(100% - 200px)' : 'calc(100% - 140px)'};
  overflow-y: scroll;
`;

export const ReviewInformationTitle = styled.div`
  display: flex;
  span {
    margin-left: 15px;
  }
  svg {
    cursor: pointer;
  }
`;

export const ThickBorderBottom = styled.div`
  border-top: 4px solid ${NEXT_BUTTON_DISABLED};
  display: flex;
  width: 96px;
`;

export const ReviewSection = styled.div<{hasNoTop?: boolean; hasNoBorder?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (!props.hasNoTop ? '24px' : '0')};
  border-top: ${props => (!props.hasNoBorder ? `1px solid ${NEXT_BUTTON_DISABLED}` : 'none')};
`;

export const ReviewPageActions = styled.div`
  display: flex;
  flex-direction: row;
  background: ${WHITE};
  border-top: 1px solid ${NEXT_BUTTON_DISABLED};
  width: 95vw;
  height: 9vh;
  margin-left: 0px;
  padding-left: 32px;
  padding-top: 15px;
`;

export const ReviewSubSection = styled.div`
  margin: 0 32px;
`;

export const ReviewSubSectionItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${LABEL_COLOR};
`;

export const ReviewItemTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${REVIEW_ITEM_TITLE_COLOR};
  text-transform: uppercase;
  margin-top: 32px;
  margin-left: 32px;
`;

export const ReviewItemSubTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  margin-left: 32px;
  margin-bottom: 32px;
`;

export const ReviewItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 12px 0px;
`;

export const ReviewItem = styled.div`
  display: flex;
  margin: 8px 0px;
  width: 100%;
`;

export const ReviewItemLabel = styled.div`
  display: flex;
  width: 25%;
`;

export const ReviewItemValue = styled.div<{isExisting?: boolean}>`
  display: flex;
  width: 30%;
  color: ${props => (props.isExisting ? INFO_REQUIRED_TXT_COLOR : LABEL_COLOR)};
`;

export const ReviewHeader = styled.div`
  display: flex;
  flext-direction: row;
  justify-content: space-between;
`;

export const ReviewSubSectionDivider = styled.div`
  border: none;
  border-top: 1px solid ${NEXT_BUTTON_DISABLED};
  margin-bottom: 10px;
`;

export const ReviewInformationWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EditInformationBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  background: ${EDIT_REVIEW_INFORMATION_BANNER_BACKGROUND};
`;

export const EditInformationBannerContent = styled.div`
  display: flex;
  height: 24px;
  margin: 16px;
  width: 100%;
  color: ${LABEL_COLOR};
  .editIcon {
    margin-right: 10px;
    height: 24px;
  }
  .closeIcon {
    height: 24px;
    margin-top: 2px;
    margin-left: auto;
    cursor: pointer;
  }
`;

export const EditInformationTitle = styled.div`
  margin-top: 4px;
`;

export const EditReviewInformationFormContainer = styled.div`
  width: 512px;
  margin: 0px 0px 0px 32px;
`;
