/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Page styled component
 */

import styled from '@emotion/styled';

const ListGridContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  margin-left: 12px;
`;

export default ListGridContainer;
