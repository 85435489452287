/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Contact Viasat
 */
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {LoadingSpinner} from '@viasat/insights-components';
import {isNil} from 'lodash';
import {useStore} from '../../store/Store';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import {
  FormContainerLeftPanel,
  NextButton,
  HeaderHorizontalRule,
  BottomHorizontalRule,
  PageDescription,
  HeaderLabel
} from '../CommonStyles';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import {validateDropdownField} from '../../utils/validationUtils';
import {useContactViasat} from '../../utils/validateAircraftHooks';

const ID_PREFIX = 'contactViasat';

interface ContactViasatProps {
  title: string;
}

const LeftChild: React.FC<any> = ({setContactDetail}) => {
  const location = useLocation();
  const {store} = useStore();
  const aircraftInfo = store.aircraftInfo;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
        color: '#465967'
      }
    }
  };

  const initialValues = {
    serialNumber: aircraftInfo.serialNumber ? aircraftInfo.serialNumber : '',
    subject: 'Select an option',
    description: '',
    tailId: aircraftInfo.tailId ? aircraftInfo.tailId : ''
  };

  const subjectList = [
    'Request Update / Information on Prior Request',
    'New Terminal Installation',
    'Replacing Equipment',
    'Adding or Editing Customer Information',
    'Adding or Managing Service Plan',
    'Requesting access to an Aircraft'
  ];

  const newAircraftFormFields: FormField[] = [
    {
      id: 'subject',
      label: 'Subject',
      type: FormFieldType.SELECT,
      value: initialValues.subject,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: subjectList,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Subject')
      }
    },
    {
      id: 'description',
      label: 'Description',
      type: FormFieldType.INPUT,
      value: initialValues.description,
      placeholder: 'Please add a detailed description of the issue to assist in troubleshooting.',
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'serialNumber',
      label: 'Serial Number',
      type: FormFieldType.INPUT,
      value: initialValues.serialNumber,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'tailId',
      label: 'Tail ID',
      type: FormFieldType.INPUT,
      value: initialValues.tailId,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    }
  ];

  const {handleSubmit, formState, watch, control} = useForm();

  let currentRoute = location.pathname;

  return (
    <>
      <Breadcrumb currentRoute={currentRoute}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--title`}>Contact Viasat</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--label`}>
        To request support for service submit the information below.
      </PageDescription>
      <BottomHorizontalRule />
      <FormContainerLeftPanel>
        <FormComponent
          idPrefix={ID_PREFIX}
          id="contact-viasat-form"
          formFields={newAircraftFormFields}
          setFormData={setContactDetail}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
      </FormContainerLeftPanel>
    </>
  );
};

const ActionButtons: React.FC<any> = ({isLoading}) => {
  return (
    <NextButton form="contact-viasat-form" id={`${ID_PREFIX}--submitNextButton`}>
      <span className="next-button-text">Send</span>
      {isLoading ? <LoadingSpinner size={18} id="AircraftValidationLoading" /> : null}
    </NextButton>
  );
};

const ContactViasat: React.FC<ContactViasatProps> = ({title}) => {
  const [contactDetail, setContactDetail] = useState<any>(null);

  const {contactViasatIsLoading} = useContactViasat(contactDetail);

  return (
    <PageContainer
      title={title}
      leftChild={<LeftChild setContactDetail={setContactDetail} />}
      actionButtons={<ActionButtons isLoading={contactViasatIsLoading && !isNil(contactDetail)} />}
    />
  );
};

export default ContactViasat;
