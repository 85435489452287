/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Utility functions to maps Url State into Store State
 */

import {isNil} from 'lodash';

import {UrlParamsPayload} from './types';
import {getFiltersToApply} from '../utils/linkUtils';

// TODO (CMDO-77872): move this to a generic utility file, preferably one focused on FP / lodash-like utils
//  this is NOT specific to UrlMap
/**
 * Returns whether the new value is non-null, not undefined, and different from the current value
 * @param currentValue the current value
 * @param newValue the new value
 * @returns true if the value has changed, false otherwise
 */
export const isValueChanged = (currentValue: any, newValue: any): boolean => {
  return !isNil(newValue) && newValue !== currentValue;
};

/**
 * Take parsed url filters and return a new state
 * Reset filters state if no url filters
 * @param state current state
 * @param urlParams parsed url data
 * @return new state
 */
export const setFilters = (state: any, urlParams: UrlParamsPayload): any => {
  const filters = getFiltersToApply(urlParams.params);
  if (filters.length > 0) {
    state = {
      ...state,
      filters: {
        ...state.filters,
        filters
      }
    };
  } else {
    state = {
      ...state,
      filters: {
        ...state.filters,
        filters: []
      }
    };
  }
  return state;
};

/**
 * Take parsed url sort/order and return new state
 * @param state current state
 * @param urlParams parsed url data
 * @return new state
 */
export const setOrderColumn = (state: any, urlParams: UrlParamsPayload): any => {
  if (urlParams.params.order && urlParams.params.column) {
    const {order, column} = urlParams.params;
    state = {
      ...state,
      sort: {
        ...state.sort,
        order,
        column
      }
    };
  }
  return state;
};

/**
 * Take parsed url sort/order/queryField and return new state
 * @param state current state
 * @param urlParams parsed url data
 * @return new state
 */
export const setOrderColumnQuery = (state: any, urlParams: UrlParamsPayload): any => {
  if (urlParams.params.order && urlParams.params.column && urlParams.params.queryField) {
    const {order, column, queryField} = urlParams.params;
    state = {
      ...state,
      sort: {
        ...state.sort,
        order,
        column,
        queryField
      }
    };
  }
  return state;
};

/**
 * Take parsed url hidden columns and return new state
 * NOTE: only saves in session storage
 * @param state current state
 * @param urlParams parsed url data
 * @param listContext list context
 * @return new state
 */
export const setSharelinkHiddenColumns = (state: any, urlParams: UrlParamsPayload, listContext: string): any => {
  if (urlParams.params.hiddenColumns) {
    // New State, Sharelink hiddenColumns saved to sessionStorage
    state = {
      ...state,
      hideColumns: {
        hiddenColumns: state.hideColumns.hiddenColumns // preserve hiddenColumns
      }
    };
  } else {
    // Show All, use hiddenColumns Default
    state = {
      ...state,
      hideColumns: {
        hiddenColumns: state.hideColumns.hiddenColumns, // preserve hiddenColumns
        sharelinkHiddenColumns: [] // set sharelink hiddenColumns
      }
    };
  }
  return state;
};

export const setHistoricalConnectedStart = (state: any, urlParams: UrlParamsPayload) => {
  if (urlParams.params.connectedStart) {
    const {connectedStart} = urlParams.params;
    state = {
      ...state,
      historicalConnectedStart: connectedStart
    };
  }
  return state;
};
