/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Replace Modem Information
 */
import React, {useEffect, useMemo} from 'react';
import {useWatch, useForm} from 'react-hook-form';
import {isNil} from 'lodash';

import {useStore} from '../../store/Store';
import {EquipmentInfoAction} from '../../store/reducers/EquipmentInfoReducer';
import ReplaceEquipmentBase from './ReplaceEquipmentBase';
import {FormField, FormFieldType} from '../common/elements/Form';
import {FormConfig, FormTypes} from './EquipmentUpdateForm';

import {updateKaModemInformation, updateKuModemInformation} from '../../store/queries/newInstall/equipmentInfoQuery';
import {
  EquipmentType,
  ipv4Regex,
  KU_NETWORK,
  macAddressRegex,
  SELECT_AN_OPTION,
  serialNumberValidation
} from '../common/constants';
import {usePartNumbersFetch} from '../../utils/customHooks';
import {getPartNumbersQuery} from '../../store/queries/lookupQueries';
import {validateConfigItemsExists, validateDropdownField} from '../../utils/validationUtils';
import {getExistingEquipmentInfoQuery} from '../../store/queries/getExistingEquipmentInfoQuery';
import useFetchV2 from '../../utils/useFetchV2';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
      color: '#465967'
    }
  }
};

interface IFormInput {
  partNumber: string;
  serialNumber: string;
  macAddress?: string;
  ipAddress?: string;
  warrantyEndDate?: string;
}

interface ModemReplaceProps {
  title: string;
}

const ModemReplace: React.FC<ModemReplaceProps> = ({title}) => {
  const {handleSubmit, formState, control, watch, reset, setValue} = useForm();
  const {store, dispatch} = useStore();
  const modemConfigItems = store.app.configItems?.['Modem'] ? store.app.configItems['Modem'] : [];
  const {aircraftNetwork, siteId} = store.aircraftInfo;
  const isKuAircraft = aircraftNetwork === KU_NETWORK;
  const serialNumberInput = watch('serialNumber');
  const ipAddressInput = watch('ipAddress');
  const macAddressInput = watch('macAddress');
  const queryParams = useMemo(() => {
    if (isNil(siteId) || isNil(aircraftNetwork)) return;
    return {
      siteId: siteId,
      configType: 'Modem',
      configSubType: aircraftNetwork
    };
  }, [siteId, aircraftNetwork]);

  const {isLoading: existingEquipmentInfoLoading, data: existingEquipmentData} = useFetchV2(
    {route: getExistingEquipmentInfoQuery.route, params: queryParams},
    getExistingEquipmentInfoQuery.transform
  );
  useEffect(() => {
    if (existingEquipmentInfoLoading && !existingEquipmentData) return;
    if (existingEquipmentData && existingEquipmentData.length) {
      dispatch({
        type: isKuAircraft
          ? EquipmentInfoAction.SET_EXISTING_KU_MODEM_INFO
          : EquipmentInfoAction.SET_EXISTING_KA_MODEM_INFO,
        payload: existingEquipmentData[0]
      });
      setValue('partNumber', existingEquipmentData[0]?.partNumber);
      setValue('serialNumber', existingEquipmentData[0]?.serialNumber);
      setValue('ipAddress', existingEquipmentData[0]?.ipAddress);
      setValue('macAddress', existingEquipmentData[0]?.macAddress);
    }
    // eslint-disable-next-line
  }, [existingEquipmentInfoLoading, existingEquipmentData]);
  const formConfigList: FormConfig[] = [
    {
      id: FormTypes.EXISTING,
      title: 'Verify Existing Modem Information',
      description:
        'To start please verify the information for the faulty modem that is being removed from the terminal. If this information does not appear below, please Add it manually.',
      footerText: '',
      footerHyperLinkText: '',
      footerHyperLinkUrl: '',
      actionType: isKuAircraft
        ? EquipmentInfoAction.SET_EXISTING_KU_MODEM_INFO
        : EquipmentInfoAction.SET_EXISTING_KA_MODEM_INFO
    },
    {
      id: FormTypes.NEW,
      title: 'Add Replacement Modem Information',
      description: 'Please Add replacement Modem information manually or via QR Code.',
      footerText: 'View latest revision of the Viasat Modem Operations and Test Manual here:',
      footerHyperLinkText: 'Modem Installation Procedures',
      footerHyperLinkUrl: '#',
      actionType: isKuAircraft ? EquipmentInfoAction.SET_KU_MODEM_INFO : EquipmentInfoAction.SET_KA_MODEM_INFO
    }
  ];

  const partNumbersList = usePartNumbersFetch(EquipmentType.MODEM, aircraftNetwork, getPartNumbersQuery);

  const selectedPartNumber: string = useWatch({
    control,
    name: 'partNumber'
  });
  const partNumberParams = partNumbersList?.filter(partNumber => partNumber.viasatPartNumber === selectedPartNumber)[0];

  const productDescription = partNumberParams?.viasatPartNumberDescription;
  const serialNumberFormat = partNumberParams?.serialNumberFormat;
  const serialNumberFormatDescription = partNumberParams?.serialNumberFormatDescription;

  const serialNumberRegex = serialNumberValidation[selectedPartNumber];

  const getFormFields = (initialValues: IFormInput, isExistingForm: boolean): FormField[] => {
    const existingKaModem = store.equipmentInfo.existingKaModem;
    const existingKuModem = store.equipmentInfo.existingKuModem;
    const equipmentFormFields: FormField[] = [
      {
        id: 'partNumber',
        label: 'Part Number',
        type: FormFieldType.SELECT,
        value: initialValues.partNumber,
        disabled: false,
        menuProps: MenuProps,
        dropDownValues: partNumbersList?.map(partNumber => partNumber.viasatPartNumber),
        description: {title: 'Product Description', value: productDescription ? productDescription : '--'},
        rules: {
          required: true,
          pattern: null,
          validate: (value, formValues) => validateDropdownField(value, 'Part Number')
        }
      },
      {
        id: 'serialNumber',
        label: 'Serial Number',
        type: FormFieldType.INPUT,
        value: initialValues.serialNumber,
        disabled: false,
        placeholder: serialNumberFormat ? `  eg. ${serialNumberFormat}` : '',
        description: serialNumberFormat ? {title: 'Serial Number Format', value: serialNumberFormat} : null,
        descriptionHelper: serialNumberFormatDescription ? serialNumberFormatDescription : '',
        rules: {
          required: true,
          pattern: {value: new RegExp(serialNumberRegex), message: 'Invalid Serial Number.'},
          warning: validateConfigItemsExists(
            modemConfigItems,
            'serialNumber',
            serialNumberInput,
            aircraftNetwork,
            siteId
          ),
          validate: (value, formValues) => {
            if (!isExistingForm)
              return (
                (isKuAircraft ? value !== existingKuModem?.serialNumber : value !== existingKaModem?.serialNumber) ||
                'Invalid Serial Number. Serial Number cannot be same as installed hardware.'
              );
            else return true;
          }
        }
      },
      isKuAircraft
        ? {
            id: 'ipAddress',
            label: 'IP Address',
            type: FormFieldType.INPUT,
            value: existingKuModem?.ipAddress ? existingKuModem?.ipAddress : initialValues.ipAddress,
            disabled: existingKuModem?.ipAddress ? true : false,
            rules: {
              required: true,
              pattern: {value: new RegExp(ipv4Regex), message: 'Invalid IP Address'},
              warning: validateConfigItemsExists(modemConfigItems, 'ipAddress', ipAddressInput, aircraftNetwork, siteId)
            }
          }
        : {
            id: 'macAddress',
            label: 'Mac Address',
            type: FormFieldType.INPUT,
            value: initialValues.macAddress,
            disabled: false,
            rules: {
              required: true,
              pattern: {value: new RegExp(macAddressRegex), message: 'Invalid MAC Address'},
              warning: validateConfigItemsExists(
                modemConfigItems,
                'macAddress',
                macAddressInput,
                aircraftNetwork,
                siteId
              ),
              validate: (value, formValues) => {
                if (!isExistingForm)
                  return (
                    (isKuAircraft ? value !== existingKuModem?.ipAddress : value !== existingKaModem?.macAddress) ||
                    'Invalid Mac Address. Mac Address cannot be same as installed hardware.'
                  );
                else return true;
              },
              upperCaseInput: true
            }
          }
    ];
    return equipmentFormFields;
  };

  return (
    <ReplaceEquipmentBase<IFormInput>
      title={title}
      aircraftSerialNumber={''}
      formIdPrefix={isKuAircraft ? 'replaceKuModemForm' : 'replaceKaModemForm'}
      equipmentName={isKuAircraft ? 'kuModem' : 'kaModem'}
      existingEquipmentStoreKey={isKuAircraft ? 'existingKuModem' : 'existingKaModem'}
      newEquipmentStoreKey={isKuAircraft ? 'kuModem' : 'kaModem'}
      formConfigList={formConfigList}
      defaultFormValues={{
        partNumber: existingEquipmentData?.length ? existingEquipmentData[0]?.partNumber : SELECT_AN_OPTION,
        serialNumber: '',
        macAddress: ''
      }}
      getFormFields={(initialValues, isExistingForm) => getFormFields(initialValues, isExistingForm)}
      formControlMethods={{handleSubmit, formState, control, watch, reset}}
      fetchQuery={isKuAircraft ? updateKuModemInformation : updateKaModemInformation}
      isLoading={siteId ? existingEquipmentInfoLoading : false}
    />
  );
};

export default ModemReplace;
