/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: get Customer Info query
 */

import {Query} from './types';

export interface ICustomerInformation {
  taskId: string;
  tailId: string;
  serialNumber: string;
  organizationInfo: any;
  contactInfo: any;
  businessAddress: any;
  billingContactInfo: any;
  billingAddressInfo: any;
  aircraftHomeBaseAirport: string;
  visibileToManufacturer: string;
  flightAwareDataAccess: string;
  varAccountId: string;
}

export const getCustomerInfoQuery: Query<ICustomerInformation> = {
  route: '/customerInformation/getCustomer',
  transform: null
};
