/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: App Component
 */
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import getPages from './Pages';
import {SliderMenu} from '@viasat/insights-components';
import ListViewPage from './components/lists/ListViewPage';
import IdentifyAircraft from './components/mainMenu/IdentifyAircraft';
import NewTerminalInstallation from './components/addNewTerminal/NewTerminalInstallation';
import AddExternalCustomer from './components/customerInformation/externalCustomer/AddExternalCustomer';
import AddInternalCustomer from './components/customerInformation/internalCustomer/AddInternalCustomer';
import ModemInformation from './components/addNewTerminal/ModemInformation';
import AntennaInformation from './components/addNewTerminal/AntennaInformation';
import APSUInstallation from './components/addNewTerminal/APSUInstallation';
import FanTrayInstallation from './components/addNewTerminal/FanTrayInstallation';
import RouterInformation from './components/addNewTerminal/RouterInformation';
import ReviewInformation from './components/reviewInformation/view/ReviewInformation';
import AddAircraftHomeBase from './components/customerInformation/internalCustomer/AddAircraftHomeBase';
import AddCustomerBillingInfo from './components/customerInformation/internalCustomer/AddCustomerBillingInfo';
import ChooseServicePlan from './components/servicePlan/ChooseServicePlan';
import AddKaIpAddress from './components/servicePlan/AddKaIpAddress';
import AcuInformation from './components/addNewTerminal/AcuInformation';
import MainMenu from './components/mainMenu/MainMenu';
import OpenTasksListView from './components/lists/openTasks/OpenTasks';
import ReplaceEquipmentMenu from './components/replaceEquipment/ReplaceEquipmentMenu';
import APSUReplace from './components/replaceEquipment/APSUReplace';
import FanTrayReplace from './components/replaceEquipment/FanTrayReplace';
import RouterReplace from './components/replaceEquipment/RouterReplace';
import AntennaReplace from './components/replaceEquipment/AntennaReplace';
import ModemReplace from './components/replaceEquipment/ModemReplace';
import AcuReplace from './components/replaceEquipment/AcuReplace';
import ValidateSerialNumber from './components/mainMenu/ValidateSerialNumber';
import {useStore} from './store/Store';
import ApprovalsList from './components/lists/approvals/ApprovalsList';
import AddDataSharingPermission from './components/customerInformation/AddDataSharingPermission';
import ContactViasat from './components/contactViasat/ContactViasat';
import ServicePlanMenu from './components/servicePlan/ServicePlanMenu';
import SuspendServicePlan from './components/servicePlan/SuspendServicePlan';
import ResumeServicePlan from './components/servicePlan/ResumeServicePlan';
import ChangeServicePlan from './components/servicePlan/ChangeServicePlan';
import BarringServicePlan from './components/servicePlan/BarringServicePlan';
import DecommissionServicePlan from './components/servicePlan/DecommissionServicePlan';
import EditAircraftDetails from './components/mainMenu/EditAircraftDetails';
import ReviewInformationWrapper from './components/reviewInformation/ReviewInformationWrapper';
import EditReviewInformation from './components/reviewInformation/edit/EditReviewInformation';
import WarrantyDetails from './components/warrantyDetails/WarrantyDetails';
import WarrantyExtensionReason from './components/warrantyDetails/WarrantyExtensionReason';
import RequestAircraftAcccess from './components/requestAircraftAccess/RequestAircraftAccess';

interface AppProps {
  windowLocation: string;
}

const App: React.FC<AppProps> = props => {
  const {store} = useStore();
  const {isAdmin, isInternal} = store.init;
  return (
    <SliderMenu
      items={getPages(isAdmin, isInternal)}
      getFullElementId={(name: string, type: string) => `sliderMenu__${name}-${type}`}
    >
      <Routes>
        <Route path="/" element={<Navigate to="/main-menu" />} />
        <Route path="/main-menu" element={<MainMenu title="Main Menu" />} />
        <Route path="/contact-viasat" element={<ContactViasat title="Contact Viasat" />} />
        <Route path="/lists/open-tasks" element={<OpenTasksListView title="Open Tasks" />} />
        <Route path="/lists/approvals-list" element={<ApprovalsList title="Requests" />} />
        <Route path="/lookups/*" element={<ListViewPage title="List View Page" />} />
        <Route path="/identify-aircraft" element={<ValidateSerialNumber title="Identify Aircraft" />} />
        <Route path="/identify-aircraft-new-installation" element={<IdentifyAircraft title="Identify Aircraft" />} />
        <Route path="/edit-aircraft-information" element={<EditAircraftDetails title="Edit Aircraft Details" />} />
        <Route
          path="/new-terminal-installation"
          element={<NewTerminalInstallation title="New Terminal Installation" />}
        />
        <Route
          path="/replace-terminal-equipment"
          element={<ReplaceEquipmentMenu title="Replace Terminal Equipment" />}
        />
        <Route
          path="/add-external-customer"
          element={<AddExternalCustomer title="Add or Edit Customer Information" />}
        />
        <Route
          path="/add-internal-customer"
          element={<AddInternalCustomer title="Add or Edit Customer Information" />}
        />
        <Route path="/add-aircraft-tail-id" element={<AddAircraftHomeBase title="Add Aircraft Tail Id" />} />
        <Route
          path="/add-customer-billing-info"
          element={<AddCustomerBillingInfo title="Add Customer Billing Information" />}
        />
        <Route
          path="/add-data-sharing-permission"
          element={<AddDataSharingPermission title="Data Sharing Permission" />}
        />
        <Route path="/new-modem-information" element={<ModemInformation title="Modem Information" />} />
        <Route path="/new-antenna-information" element={<AntennaInformation title="Antenna Information" />} />
        <Route path="/new-apsu-installation" element={<APSUInstallation title="APSU Installation" />} />
        <Route path="/new-acu-information" element={<AcuInformation title="ACU Information" />} />
        <Route path="/new-fantray-installation" element={<FanTrayInstallation title="FanTray Installation" />} />
        <Route path="/new-router-information" element={<RouterInformation title="Router Information" />} />
        <Route path="/replace-apsu-information" element={<APSUReplace title="Replace APSU" />} />
        <Route path="/replace-fantray-information" element={<FanTrayReplace title="Replace FanTray" />} />
        <Route path="/replace-router-information" element={<RouterReplace title="Replace Router" />} />
        <Route path="/replace-antenna-information" element={<AntennaReplace title="Replace Antenna" />} />
        <Route path="/replace-modem-information" element={<ModemReplace title="Replace Modem" />} />
        <Route path="/replace-acu-information" element={<AcuReplace title="Replace ACU" />} />
        <Route path="/choose-service-plan" element={<ChooseServicePlan title="Choose Service Plan" />} />
        <Route path="/change-service-plan" element={<ChangeServicePlan title="Change Service Plan" />} />
        <Route path="/resume-service-plan" element={<ResumeServicePlan title="Resume Service" />} />
        <Route path="/suspend-service-plan" element={<SuspendServicePlan title="Suspend Service" />} />
        <Route path="/bar-service" element={<BarringServicePlan title="Barring Service" />} />
        <Route path="/decommission-service-plan" element={<DecommissionServicePlan title="Decommission Service" />} />
        <Route path="/service-plan-options" element={<ServicePlanMenu title="Service Plan Options" />} />
        <Route path="/add-ip-ka-address" element={<AddKaIpAddress title="Specify Ka-Band IP Address" />} />

        <Route path="/review-request/:taskId" element={<ReviewInformationWrapper title="Review Information" />}>
          <Route path="view" element={<ReviewInformation title="Review Information" />} />
          <Route path="edit" element={<EditReviewInformation />} />
        </Route>
        <Route path="/warranty-extension" element={<WarrantyDetails title="Warranty Details" />} />
        <Route
          path="/warranty-extension-reason"
          element={<WarrantyExtensionReason title="Reason for Warranty Change" />}
        />
        <Route path="/request-aircraft-access" element={<RequestAircraftAcccess title="Request Access" />} />
      </Routes>
    </SliderMenu>
  );
};
export default App;
