/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Reducer for store management
 */

import {IAction, IStore} from './types';
import {actionTypeHasGivenContext, removeContextFromActionType} from './reducerUtils';
import {InitReducer, INIT_STORE_CONTEXT} from './reducers/InitReducer';
import {CUSTOMER_STORE_CONTEXT, CustomerReducer} from './reducers/CustomerReducer';

import {APP_STORE_CONTEXT, AppReducer} from './reducers/AppReducer';
import {EquipmentInfoReducer, EQUIPMENT_INFO_STORE_CONTEXT} from './reducers/EquipmentInfoReducer';
import {AircraftInfoReducer, AIRCRAFT_INFO_STORE_CONTEXT} from './reducers/AircraftInfoReducer';
import {OpenTasksInfoReducer, OPEN_TASKS_INFO_STORE_CONTEXT} from './reducers/OpenTasksReducer';
import {LookupsReducer, LOOKUPS_STORE_CONTEXT} from './reducers/LookupsReducer';
import {SERVICE_PLAN_STORE_CONTEXT, ServicePlanReducer} from './reducers/ServicePlanReducer';
import {CUSTOMER_INFO_STORE_CONTEXT, CustomerInfoReducer} from './reducers/CustomerInfoReducer';
import {APPROVAL_INFO_STORE_CONTEXT, ApprovalInfoReducer} from './reducers/ApprovalReducer';
import {AIRCRAFT_MODEL_LIST_STORE_CONTEXT, AircraftModelListReducer} from './reducers/AircraftModelListReducer';
import {KA_BAND_SW_VERSIONS_LIST_STORE_CONTEXT, KaBandSwVersionsListReducer} from './reducers/KaBandSwVersionsReducer';
import {KU_BAND_SW_VERSIONS_LIST_STORE_CONTEXT, KuBandSwVersionsListReducer} from './reducers/KuBandSwVersionsReducer';
import {
  MAP_BUNDLE_VERSIONS_LIST_STORE_CONTEXT,
  MapBundleVersionsListReducer
} from './reducers/MapBundleVersionsReducer';
import {
  SERVICE_PLAN_TERMS_LIST_STORE_CONTEXT,
  ServicePlanTermsListReducer
} from './reducers/ServicePlanTermsListReducer';
import {SERVICE_PLANS_LIST_STORE_CONTEXT, ServicePlansListReducer} from './reducers/ServicePlansListReducer';
import {ROUTER_TYPES_LIST_STORE_CONTEXT, RouterTypesListReducer} from './reducers/RouterTypesReducer';
import {STATUS_STORE_CONTEXT, StatusReducer} from './reducers/StatusReducer';
import {WARRANTY_TERMS_LIST_STORE_CONTEXT, WarrantyTermsListReducer} from './reducers/WarrantyTermsListReducer';

export const Reducer = (
  {
    init,
    app,
    customer,
    equipmentInfo,
    aircraftInfo,
    openTasksInfo,
    approvalInfo,
    lookups,
    servicePlan,
    customerInfo,
    aircraftModelList,
    kaBandSwVersionsList,
    kuBandSwVersionsList,
    mapBundleVersionsList,
    routerTypesList,
    servicePlansList,
    servicePlanTermsList,
    warrantyTermsList,
    statusInfo
  }: IStore,
  action: IAction
) => ({
  [INIT_STORE_CONTEXT]: InitReducer(init, action),
  [APP_STORE_CONTEXT]: AppReducer(app, action),
  [CUSTOMER_STORE_CONTEXT]: CustomerReducer(customer, action),
  [EQUIPMENT_INFO_STORE_CONTEXT]: EquipmentInfoReducer(equipmentInfo, action),
  [AIRCRAFT_INFO_STORE_CONTEXT]: AircraftInfoReducer(aircraftInfo, action),
  [OPEN_TASKS_INFO_STORE_CONTEXT]: OpenTasksInfoReducer(openTasksInfo, action),
  [APPROVAL_INFO_STORE_CONTEXT]: ApprovalInfoReducer(approvalInfo, action),
  [LOOKUPS_STORE_CONTEXT]: LookupsReducer(lookups, action),
  [SERVICE_PLAN_STORE_CONTEXT]: ServicePlanReducer(servicePlan, action),
  [CUSTOMER_INFO_STORE_CONTEXT]: CustomerInfoReducer(customerInfo, action),
  [AIRCRAFT_MODEL_LIST_STORE_CONTEXT]: AircraftModelListReducer(aircraftModelList, action),
  [KA_BAND_SW_VERSIONS_LIST_STORE_CONTEXT]: KaBandSwVersionsListReducer(kaBandSwVersionsList, action),
  [KU_BAND_SW_VERSIONS_LIST_STORE_CONTEXT]: KuBandSwVersionsListReducer(kuBandSwVersionsList, action),
  [MAP_BUNDLE_VERSIONS_LIST_STORE_CONTEXT]: MapBundleVersionsListReducer(mapBundleVersionsList, action),
  [ROUTER_TYPES_LIST_STORE_CONTEXT]: RouterTypesListReducer(routerTypesList, action),
  [SERVICE_PLANS_LIST_STORE_CONTEXT]: ServicePlansListReducer(servicePlansList, action),
  [SERVICE_PLAN_TERMS_LIST_STORE_CONTEXT]: ServicePlanTermsListReducer(servicePlanTermsList, action),
  [WARRANTY_TERMS_LIST_STORE_CONTEXT]: WarrantyTermsListReducer(warrantyTermsList, action),
  [STATUS_STORE_CONTEXT]: StatusReducer(statusInfo, action)
});

/**
 * Passes the given action down to the provided reducers and gives them each an opportunity to update the state
 * @param storeContext The store context to limit which actions should be processed
 * @param state The initial state
 * @param action The action to be processed
 * @param reducers The reducers to delegate processing to
 * @param defaultState The default state
 * @returns The updated state
 */
export const delegateToReducers = (
  storeContext: string,
  state: any,
  action: any,
  reducers: {[key: string]: (state: any, action: IAction, defaultState: any) => any},
  defaultState: any
) => {
  // Don't delegate to reducers if the action has a different context, but do allow actions without any context
  if (actionTypeHasGivenContext(action.type, storeContext, true)) {
    return Object.keys(reducers).reduce(
      (memo: any, reducerKey: any) => ({
        ...memo,
        ...reducers[reducerKey](
          memo,
          {
            ...action,
            type: removeContextFromActionType(action.type, storeContext)
          },
          defaultState
        )
      }),
      state
    );
  }
  return state;
};
