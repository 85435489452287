/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Equipment Details - Antenna - Ka/Ku
 */
import React from 'react';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemValue,
  ReviewSubSection,
  ReviewSubSectionItemTitle
} from '../../ReviewInformationStyles';
import {renderReviewInformation} from '../../util';

interface IAntennaInfo {
  serialNumber: string;
  partNumber: string;
}
interface IAntennaDetail {
  idPrefix: string;
  kaAntenna: {new: IAntennaInfo; existing: IAntennaInfo};
  kuAntenna: {new: IAntennaInfo; existing: IAntennaInfo};
}

const AntennaDetail: React.FC<IAntennaDetail> = ({idPrefix, kaAntenna, kuAntenna}) => {
  return (
    <>
      {kaAntenna && kaAntenna.new ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle id={`${idPrefix}__kaAntenna--title`}>Ka Antenna</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Part Number</ReviewItemLabel>
              {kaAntenna.existing && kaAntenna.existing.partNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kaAntenna--existingPartNumber`}>
                  {renderReviewInformation(kaAntenna.existing.partNumber)}
                </ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kaAntenna--newPartNumber`}>{renderReviewInformation(kaAntenna.new.partNumber)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Serial Number</ReviewItemLabel>
              {kaAntenna.existing && kaAntenna.existing.serialNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kaAntenna--existingSerialNumber`}>
                  {renderReviewInformation(kaAntenna.existing.serialNumber)}
                </ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kaAntenna--newSerialNumber`}>{renderReviewInformation(kaAntenna.new.serialNumber)}</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
      {kuAntenna && kuAntenna?.new ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle id={`${idPrefix}__kuAntenna_title`}>Ku Antenna</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Part Number</ReviewItemLabel>
              {kuAntenna.existing && kuAntenna.existing.serialNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kuAntenna--existingPartNumber`}>
                  {renderReviewInformation(kuAntenna.existing.partNumber)}
                </ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kuAntenna_newPartNumber`}>{renderReviewInformation(kuAntenna.new.partNumber)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Serial Number</ReviewItemLabel>
              {kuAntenna.existing && kuAntenna.existing.serialNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__kuAntenna--existingSerialNumber`}>
                  {renderReviewInformation(kuAntenna.existing.serialNumber)}
                </ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__kuAntenna_newSerialNumber`}>{renderReviewInformation(kuAntenna.new.serialNumber)}</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
    </>
  );
};

export default AntennaDetail;
