/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Customer Information Form
 */
import React from 'react';

import FormComponent, {FormField, FormLayout} from '../../common/elements/Form';

import {useStore} from '../../../store/Store';
import {ITaskData} from '../../../store/queries/getRequestInformationQuery';

import {ICustomerDetail} from '../reviewInformationTypes';
import {
  EditReviewInformationFormContainer,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewSection
} from '../ReviewInformationStyles';
import {
  useAddressInfoForm,
  useContactInfoForm,
  useDataSharingPermissionForm,
  useOrganizationForm,
  useTailForm
} from '../../../utils/customerInformationFormHook';

interface ICustomerForm extends ICustomerDetail {
  formId: string;
  taskDetail: ITaskData;
  setCustomerFormData: (equipmentFormData: any) => void;
  formControlMethods: any;
}

const CustomerInformationForm: React.FC<ICustomerForm> = ({
  idPrefix,
  customerDetail,
  taskDetail,
  formId,
  setCustomerFormData,
  formControlMethods
}) => {
  const {store} = useStore();
  const {varAccountList} = store.app;
  const {isValueAddedReseller} = store.init;

  const {customerCode} = taskDetail;
  const isExternalUserRequest = customerCode !== null;

  const customerFormFields: FormField[] = [];
  const organisationFields = useOrganizationForm({
    legalBusinessName: customerDetail.organizationInfo?.legalBusinessName
  });
  organisationFields && customerFormFields.push(...organisationFields);
  const tailFields = useTailForm({
    aircraftHomeBaseAirport: customerDetail.aircraftHomeBaseAirport
  });
  tailFields && customerFormFields.push(...tailFields);
  const dataSharingFormFields = useDataSharingPermissionForm(
    {
      visibleToManufacturer: customerDetail?.visibleToManufacturer,
      flightAwareDataAccess: customerDetail?.flightAwareDataAccess,
      varAccountId: customerDetail?.varAccountId
    },
    isValueAddedReseller,
    varAccountList
  );
  dataSharingFormFields && customerFormFields.push(...dataSharingFormFields);
  const contactFormFields = useContactInfoForm(
    !isExternalUserRequest? 
    {
      contactName: customerDetail.contactInfo?.contactName,
      contactEmail: customerDetail.contactInfo?.contactEmail,
      contactPhone: customerDetail.contactInfo?.phoneNumber
    }: null,
    'Contact Information',
    'contactInfo'
  );
  contactFormFields && customerFormFields.push(...contactFormFields);
  const billingContactInfo = useContactInfoForm(
    !isExternalUserRequest ?
    {
      contactName: customerDetail.billingContactInfo?.contactName,
      contactEmail: customerDetail.billingContactInfo?.contactEmail,
      contactPhone: customerDetail.billingContactInfo?.phoneNumber
    }: null,
    'Billing Contact Information',
    'billingContactInfo'
  );
  billingContactInfo && customerFormFields.push(...billingContactInfo);
  const addressFormFields = useAddressInfoForm(
    !isExternalUserRequest ?
    {
      region: customerDetail.businessAddress?.region,
      city: customerDetail.businessAddress?.city,
      addressLine1: customerDetail.businessAddress?.addressLine1,
      addressLine2: customerDetail.businessAddress?.addressLine2,
      zipcode: customerDetail.businessAddress?.zipcode,
      country: customerDetail.businessAddress?.country
    }: null,
    'Business Address',
    'businessAddress'
  );
  addressFormFields && customerFormFields.push(...addressFormFields);
  const billingAddressFields = useAddressInfoForm(
    !isExternalUserRequest ?
    {
      region: customerDetail.billingAddressInfo?.region,
      city: customerDetail.billingAddressInfo?.city,
      addressLine1: customerDetail.billingAddressInfo?.addressLine1,
      addressLine2: customerDetail.billingAddressInfo?.addressLine2,
      zipcode: customerDetail.billingAddressInfo?.zipcode,
      country: customerDetail.billingAddressInfo?.country
    }: null,
    'Billing Address',
    'billingAddressInfo'
  );
  billingAddressFields && customerFormFields.push(...billingAddressFields);

  return (
    <ReviewSection>
      <ReviewItemTitle id={`${idPrefix}__customerInformation_title`}>Customer Information</ReviewItemTitle>
      <ReviewItemSubTitle id={`${idPrefix}__customerInformation_subtitle`}>
        Please review the information below for approval.
      </ReviewItemSubTitle>
      <EditReviewInformationFormContainer>
        <FormComponent
          idPrefix={idPrefix}
          id={formId}
          formFields={customerFormFields}
          setFormData={setCustomerFormData}
          formControlMethods={formControlMethods}
          layout={FormLayout.HORIZONTAL}
        ></FormComponent>
      </EditReviewInformationFormContainer>
    </ReviewSection>
  );
};

export default CustomerInformationForm;
