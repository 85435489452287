/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Lookup Reference Table Query Utils
 */

import {forEach} from 'lodash';
import {IAircraftModel, IKuMapBundleFiles, IKuMapBundleFilesList} from '../lookupTypes';

/**
 * 
 * @param networkSupportList List of Network Support available for the model
 * @param network Network Type to check
 * @returns True or False based on the network supported
 */
export const checkNetworkSupport = (networkSupportList: string, network: string): boolean => {
  return networkSupportList ? JSON.parse(networkSupportList).includes(network) : false;
};
/**
 * Transforms the API data to support different UI columns and edit use case
 * @param aircraftModelList List of Aircraft Model data
 * @returns Transformed aircraft model list for UI
 */
export const getAircraftModel = (aircraftModelList: IAircraftModel[]) => {
  const aircraftModelData = [];
  forEach(aircraftModelList, (aircraftModel: IAircraftModel) => {
    aircraftModelData.push({
      id: aircraftModel.id,
      aircraftMake: aircraftModel.aircraftMake,
      aircraftModel: aircraftModel.aircraftModel,
      stcAvailable: aircraftModel.stcAvailable,
      deactivatedTstamp: aircraftModel.deactivatedTstamp,
      networkSupport: JSON.parse(aircraftModel.network),
      ka: checkNetworkSupport(aircraftModel.network, 'Ka'),
      ku: checkNetworkSupport(aircraftModel.network, 'Ku'),
      dualBand: checkNetworkSupport(aircraftModel.network, 'Dual-band'),
      vvipKa: checkNetworkSupport(aircraftModel.network, 'VVIP Ka'),
      vvipDualBand: checkNetworkSupport(aircraftModel.network, 'VVIP Dual Band'),
      status: aircraftModel.status,
      notes: aircraftModel.notes
    });
  });
  return aircraftModelData;
};

/**
 * Generates the data for UI from the raw Map Bundle Files
 * @param mapBundleFiles Map Bundle Files List
 * @returns Processed Map Bundle Files List for UI
 */
export const getMapBundleVersions = (mapBundleFiles: IKuMapBundleFiles[]): IKuMapBundleFilesList[] => {
  const mapBundleFileList = [];
  forEach(mapBundleFiles, mapBundle => {
    const priorRelease = mapBundle.priorRelease;
    const productionRelease = mapBundle.productionRelease;
    const pilotRelease = mapBundle.pilotRelease;
    mapBundleFileList.push({
      id: mapBundle.id,
      mapBundleFileName: mapBundle.mapBundleFileName,
      priorReleaseSed: priorRelease ? priorRelease.sed : '--',
      priorReleaseSscf: priorRelease ? priorRelease.sscf : '--',
      productionReleaseSed: productionRelease ? productionRelease.sed : '--',
      productionReleaseSscf: productionRelease ? productionRelease.sscf : '--',
      pilotReleaseSed: pilotRelease ? pilotRelease.sed : '--',
      pilotReleaseSscf: pilotRelease ? pilotRelease.sscf : '--',
      notes: mapBundle.notes,
      status: mapBundle.status
    });
  });
  return mapBundleFileList;
};
