/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Identify Aircraft
 */
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {isNil, uniq} from 'lodash';
import {useForm} from 'react-hook-form';

import {useStore} from '../../store/Store';
import {useStartNewInstall} from '../../utils/validateAircraftHooks';

import {LoadingSpinner} from '@viasat/insights-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PageContainer from './PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import {
  FormContainerLeftPanel,
  NextButton,
  HeaderHorizontalRule,
  BottomHorizontalRule,
  PageDescription,
  HeaderLabel
} from '../CommonStyles';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';

import {validateDropdownField} from '../../utils/validationUtils';
import {INSTALLATION_TYPES} from '../common/constants';

const ID_PREFIX = 'identifyAircraft';

interface IdentifyAircraftProps {
  title: string;
}

const LeftChild: React.FC<any> = ({setAircraft}) => {
  const location = useLocation();
  const {store} = useStore();
  const {isValueAddedReseller} = store.init;
  const {aircraftModels, varAccountList} = store.app;
  const aircraftInfo = store.aircraftInfo;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
        color: '#465967'
      }
    }
  };
  const {handleSubmit, formState, watch, control} = useForm();

  const initialValues = {
    serialNumber: aircraftInfo.serialNumber ? aircraftInfo.serialNumber : '',
    make: 'Select an option',
    model: 'Select an option',
    tailId: '',
    network: 'Select an option',
    varName: '',
    installationType: 'Select an option'
  };
  const makeList = uniq(aircraftModels.map(mode => mode.aircraftMake)).sort();

  const networkTypes = ['Ka', 'Ku', 'Dual Band (Ka & Ku)'];

  const selectedMake = watch('make');

  const modelsByMake = aircraftModels?.filter(aircraftModel => aircraftModel.aircraftMake === selectedMake);
  const modelList = uniq(modelsByMake.map(mode => mode.aircraftModel));

  const newAircraftFormFields: FormField[] = [
    {
      id: 'serialNumber',
      label: 'Aircraft Serial Number',
      type: FormFieldType.INPUT,
      value: initialValues.serialNumber,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'make',
      label: 'Make',
      type: FormFieldType.SELECT,
      value: initialValues.make,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: makeList,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Make')
      }
    },
    {
      id: 'model',
      label: 'Model',
      type: FormFieldType.SELECT,
      value: initialValues.model,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: modelList,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Model')
      }
    },
    {
      id: 'tailId',
      label: 'Tail ID',
      type: FormFieldType.INPUT,
      value: initialValues.tailId,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'network',
      label: 'Network',
      type: FormFieldType.SELECT,
      value: initialValues.network,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: networkTypes,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Network')
      }
    },
    {
      id: 'installationType',
      label: 'Installation Type',
      type: FormFieldType.SELECT,
      value: initialValues.network,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: INSTALLATION_TYPES,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Installation Type')
      }
    }
  ];

  if (!isValueAddedReseller) {
    newAircraftFormFields.push({
      id: 'varAccountId',
      label: 'Make visible to Value Added Resellers (VARs)',
      type: FormFieldType.SELECT,
      value: initialValues.varName,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: varAccountList?.map(varAccount => varAccount.varName),
      rules: {
        required: false,
        pattern: null
      }
    });
  }

  let currentRoute = location.pathname;

  return (
    <>
      <Breadcrumb currentRoute={currentRoute}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--title`}>Identify Aircraft</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--label`}>
        Please identify aircraft make and model. If the model is not listed in the menu, please add the exact model.
      </PageDescription>
      <BottomHorizontalRule />
      <FormContainerLeftPanel>
        <FormComponent
          idPrefix={ID_PREFIX}
          id="identify-aircraft-form"
          formFields={newAircraftFormFields}
          setFormData={setAircraft}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
      </FormContainerLeftPanel>
    </>
  );
};

const ActionButtons: React.FC<any> = ({isLoading, isNewInstallation}) => {
  return (
    <NextButton form="identify-aircraft-form" id={`${ID_PREFIX}--submitNextButton`}>
      <span className="next-button-text">{isNewInstallation ? 'Submit' : 'Next'}</span>
      {!isNewInstallation ? <ArrowForwardIcon className="next-arrow" /> : <></>}
      {isLoading ? <LoadingSpinner size={18} id="AircraftValidationLoading" /> : null}
    </NextButton>
  );
};

const IdentifyAircraft: React.FC<IdentifyAircraftProps> = ({title}) => {
  const [aircraft, setAircraft] = useState<any>(null);

  useStartNewInstall(aircraft);

  return (
    <PageContainer
      title={title}
      leftChild={<LeftChild setAircraft={setAircraft} />}
      actionButtons={<ActionButtons isLoading={!isNil(aircraft?.serialNumber)} />}
    />
  );
};

export default IdentifyAircraft;
