/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Customer Information Form Hooks
 */

import {useIntl} from 'react-intl';
import {FormFieldType} from '../components/common/elements/Form';
import {IVarAccount} from '../store/queries/getVarAccountListQuery';

/**
 * Builds customer information - organization info form
 * @param organizationInfo object containing organzition name
 * @returns Array of organization info form Fields
 */

export function useOrganizationForm(organizationInfo: {legalBusinessName: string}) {
  if (!organizationInfo) {
    return;
  }

  const organizationFormFields = [
    {
      id: `customerForm.organizationTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Organization',
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: 'customerForm.organizationInfo.legalBusinessName',
      label: 'Legal Business Name',
      type: FormFieldType.INPUT,
      value: organizationInfo.legalBusinessName,
      disabled: false,
      description: {title: null, value: 'Name used on the service agreement'},
      rules: {
        required: true,
        pattern: null
      }
    }
  ];

  return organizationFormFields;
}

/**
 * Builds customer information - tail info form
 * @param tailInfo object containing tailId and home base airport
 * @returns Array of tail info form Fields
 */

export function useTailForm(tailInfo: {aircraftHomeBaseAirport: string}) {
  if (!tailInfo) {
    return;
  }

  const tailInfoFormFields = [
    {
      id: 'customerForm.aircraftHomeBaseAirport',
      label: 'Aircraft Home Base Airport (ICAO Airport Code)',
      type: FormFieldType.INPUT,
      value: tailInfo.aircraftHomeBaseAirport,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    }
  ];
  return tailInfoFormFields;
}

/**
 * Builds customer information - data sharing info form
 * @param dataSharingPermissionInfo object containing properities of data sharing access
 * @returns Array of data sharing info form Fields
 */

export function useDataSharingPermissionForm(
  dataSharingPermissionInfo: {visibleToManufacturer: string; flightAwareDataAccess: string; varAccountId: string},
  isValueAddedReseller: boolean,
  varAccountList: IVarAccount[]
) {
  const intl = useIntl();
  if (!dataSharingPermissionInfo) {
    return;
  }
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
        color: '#465967'
      }
    }
  };
  const dataSharingPermissionFormFields = [];
  if (!isValueAddedReseller) {
    dataSharingPermissionFormFields.push({
      id: 'customerForm.varAccountId',
      label: 'Make visible to Value Added Resellers (VARs)',
      type: FormFieldType.SELECT,
      value: varAccountList.filter(varAccount => varAccount.varAccountId === dataSharingPermissionInfo.varAccountId)[0]
        ?.varName,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: varAccountList?.map(varAccount => varAccount.varName),
      rules: {
        required: false,
        pattern: null
      }
    });
  }
  dataSharingPermissionFormFields.push(
    {
      id: 'customerForm.flightAwareDataAccess',
      label: 'Allow Viasat to access third-party flight tracking data for this aircraft?',
      type: FormFieldType.RADIO,
      value: dataSharingPermissionInfo.flightAwareDataAccess,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      },
      visibilityMessage: intl.formatMessage({id: `add-customer.access-to-third-party-data.read-carefully`}),
      radioOptions: [
        {value: 'Yes', isChecked: dataSharingPermissionInfo.flightAwareDataAccess === 'yes' ? true : null},
        {value: 'No', isChecked: dataSharingPermissionInfo.flightAwareDataAccess === 'no' ? true : null}
      ]
    },
    {
      id: 'customerForm.visibleToManufacturer',
      label: 'Make this aircraft visible to manufacturer (OEM)?',
      type: FormFieldType.RADIO,
      value: dataSharingPermissionInfo.visibleToManufacturer,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      },
      visibilityMessage: intl.formatMessage({id: `add-customer.visible-to-manufacturer.read-carefully`}),
      radioOptions: [
        {value: 'Yes', isChecked: dataSharingPermissionInfo.visibleToManufacturer === 'yes' ? true : null},
        {value: 'No', isChecked: dataSharingPermissionInfo.visibleToManufacturer === 'no' ? true : null}
      ]
    }
  );
  return dataSharingPermissionFormFields;
}

/**
 * Builds customer information - contact info form
 * @param contactInfo contact info object
 * @param groupTitle contact info group title
 * @param objectKey key on which the form fields to be registered
 * @returns Array of contact info form Fields
 */
export function useContactInfoForm(
  contactInfo: {contactName: string; contactEmail: string; contactPhone: string},
  groupTitle: string,
  objectKey: string
) {
  if (!contactInfo) {
    return;
  }

  const contactInfoFields = [
    {
      id: `customerForm.contactInfoTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: groupTitle,
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: `${'customerForm.' + objectKey + '.contactName'}`,
      label: 'Contact Name',
      type: FormFieldType.INPUT,
      value: contactInfo.contactName,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: `${'customerForm.' + objectKey + '.contactEmail'}`,
      label: 'Contact Email',
      type: FormFieldType.INPUT,
      value: contactInfo.contactEmail,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: `${'customerForm.' + objectKey + '.phoneNumber'}`,
      label: 'Contact Phone Number',
      type: FormFieldType.INPUT,
      value: contactInfo.contactPhone,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  return contactInfoFields;
}

/**
 * Builds customer information - address form
 * @param addressInfo address info object
 * @param groupTitle address info group title
 * @param objectKey key on which the form fields to be registered
 * @returns Array of address info form Fields
 */

export function useAddressInfoForm(
  addressInfo: {
    region: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    zipcode: string;
    country: string;
  },
  groupTitle: string,
  objectKey: string
) {
  if (!addressInfo) {
    return;
  }
  const addressFormFields = [
    {
      id: `'customerForm.'+addressTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: groupTitle,
      disabled: false,
      rules: {required: false, pattern: null}
    },
    {
      id: `${'customerForm.' + objectKey + '.country'}`,
      label: 'Country',
      type: FormFieldType.INPUT,
      value: addressInfo.country,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: `${'customerForm.' + objectKey + '.region'}`,
      label: 'State / Province / Region',
      type: FormFieldType.INPUT,
      value: addressInfo.region,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: `${'customerForm.' + objectKey + '.city'}`,
      label: 'City',
      type: FormFieldType.INPUT,
      value: addressInfo.city,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: `${'customerForm.' + objectKey + '.addressLine1'}`,
      label: 'Address Line 1',
      type: FormFieldType.INPUT,
      value: addressInfo.addressLine1,
      disabled: false,
      description: {title: '', value: 'Street address P.O box'},
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: `${'customerForm.' + objectKey + '.addressLine2'}`,
      label: 'Address Line 2',
      type: FormFieldType.INPUT,
      value: addressInfo.addressLine2,
      disabled: false,
      description: {title: '', value: 'Apartment, suite, unit, building, floor, etc.'},
      rules: {
        required: false,
        pattern: null
      }
    },
    {
      id: `${'customerForm.' + objectKey + '.zipcode'}`,
      label: 'Zip Code / Postal Code',
      type: FormFieldType.INPUT,
      value: addressInfo.zipcode,
      disabled: false,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  return addressFormFields;
}
