/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Validate Serial Number
 */

import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {useValidateSerialNumber} from '../../utils/validateAircraftHooks';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingSpinner} from '@viasat/insights-components';
import PageContainer from './PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import {NextButton, HeaderHorizontalRule, BottomHorizontalRule, PageDescription, HeaderLabel} from '../CommonStyles';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import AircraftValidationModal from './AircraftValidationModal';

const ID_PREFIX = 'validateSerialNumber';

interface ValidateSerialNumberProps {
  title: string;
}

const ValidateSerialNumberForm: React.FC<{setAircraft: (aircraft: any) => void}> = ({setAircraft}) => {
  const location = useLocation();

  interface IValidateSerialNumber {
    serialNumber: string;
  }
  const initialValues: IValidateSerialNumber = {
    serialNumber: null
  };

  const validateSerialNumberFormFields: FormField[] = [
    {
      id: 'serialNumber',
      label: 'Aircraft Serial Number',
      type: FormFieldType.INPUT,
      value: initialValues.serialNumber,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    }
  ];

  const {handleSubmit, formState, watch, control} = useForm();
  return (
    <>
      <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--title`}>Enter Aircraft Serial Number</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--label`}>Please enter the aircraft serial number to continue.</PageDescription>
      <BottomHorizontalRule />
      <FormComponent
        id="identify-aircraft-form"
        idPrefix={ID_PREFIX}
        formFields={validateSerialNumberFormFields}
        setFormData={setAircraft}
        formControlMethods={{handleSubmit, formState, watch, control}}
      ></FormComponent>
    </>
  );
};

const ValidateSerialNumber: React.FC<ValidateSerialNumberProps> = ({title}) => {
  const [aircraft, setAircraft] = useState<any>(null);
  const [showValidationStatusModal, setShowValidationStatusModal] = useState<boolean>(false);

  const {validateInProgress, validatedAircraft} = useValidateSerialNumber(aircraft, setShowValidationStatusModal, true);

  const ValidateSerialNumberActionButtons: React.FC<{}> = () => {
    return (
      <NextButton form="identify-aircraft-form" id={`${ID_PREFIX}--submitNextButton`}>
        <span className="next-button-text">Next</span>
        <ArrowForwardIcon className="next-arrow" />
        {validateInProgress && aircraft?.serialNumber ? (
          <LoadingSpinner size={18} id="AircraftValidationLoading" />
        ) : null}
      </NextButton>
    );
  };

  return (
    <>
      <AircraftValidationModal
        openModal={showValidationStatusModal}
        aircraft={
          validatedAircraft?.aircraftDetails || validatedAircraft?.userHaveAccess === false
            ? {...validatedAircraft, serialNumber: aircraft?.serialNumber}
            : aircraft
        }
        setOpenModal={setShowValidationStatusModal}
      />
      <PageContainer
        title={title}
        leftChild={<ValidateSerialNumberForm setAircraft={setAircraft} />}
        actionButtons={<ValidateSerialNumberActionButtons />}
      />
    </>
  );
};

export default ValidateSerialNumber;
