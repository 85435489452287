/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Lookup Table Utils
 */
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {DefaultCellRendererV2, CellRenderer} from '@viasat/insights-components';
import {CheckboxCellRenderer} from '../../common/elements/listGridCells/ListGridCheckbox';
import {ListGridLinkedDataColumn} from '../../../utils/listUtils';
import ListGridIconTextOrLink from '../../common/elements/listGridCells/ListGridIconTextOrLink';
import {CANCEL_ICON_COLOR, GREEN_TICK_COLOR} from '../../../theme/Colors';
import LongTextCellRenderer from '../../common/elements/listGridCells/LongTextCell';

export interface SideDrawerProps {
  openPanel: boolean;
  setOpenPanel: (openPanel: boolean) => void;
  isUpdate?: boolean;
  panelData?: any;
}

export const togglePanel = (open: boolean) => {
  if (open) {
    return;
  }
};

export const handleBackdropClick = event => {
  localStorage.setItem('backdropClick', 'true');
  event.stopPropagation();
  return false;
};

/**
 * Renders icon based on the cell data value
 * @param params Cell and Row props to process
 * @returns Icon or Text Link
 */
export const IconCellRenderer: CellRenderer = ({rowData, cellIdBase, column, cellData}) => {
  const {handleClick} = column;
  return (
    <ListGridIconTextOrLink
      cellIdBase={cellIdBase}
      icon={
        cellData === true || cellData === 'Yes' ? (
          <CheckIcon fontSize="small" sx={{color: GREEN_TICK_COLOR}} />
        ) : (
          <ClearIcon fontSize="small" sx={{color: CANCEL_ICON_COLOR}} />
        )
      }
      text={null}
      tooltipText={null}
      descriptionText={null}
      rowData={rowData}
      handleClick={handleClick}
    />
  );
};

/**
 * Returns a list of columns that should be included based on the given
 * @param isAdmin Check for Admin access
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildPartNumberListColumns = (isAdmin: boolean): ListGridLinkedDataColumn[] => {
  // Column definitions for part numbers list grid
  const partNumbersListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'status',
      dataKey: 'status',
      showHideGroup: '__groupless',
      title: 'Status',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 100,
      sortable: false,
      frozen: false
    },
    {
      key: 'viasatPartNumber',
      dataKey: 'viasatPartNumber',
      showHideGroup: '__groupless',
      title: 'Viasat Part Number',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 200,
      sortable: false,
      frozen: false
    },
    {
      key: 'equipmentType',
      dataKey: 'equipmentType',
      showHideGroup: '__groupless',
      title: 'Equipment Type',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: false,
      frozen: false
    },
    {
      key: 'commonProductDescription',
      dataKey: 'commonProductDescription',
      showHideGroup: '__groupless',
      title: 'Common Product Description',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 350,
      sortable: false,
      frozen: false
    },
    {
      key: 'viasatPartNumberDescription',
      dataKey: 'viasatPartNumberDescription',
      showHideGroup: '__groupless',
      title: 'Viasat Part Number Description',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 350,
      sortable: false,
      frozen: false
    },
    {
      key: 'network',
      dataKey: 'network',
      showHideGroup: '__groupless',
      title: 'Network',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 100,
      sortable: false,
      frozen: false
    },
    {
      key: 'serialNumberFormat',
      dataKey: 'serialNumberFormat',
      showHideGroup: '__groupless',
      title: 'Serial Number Format',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 250,
      sortable: false,
      frozen: false
    },
    {
      key: 'serialNumberFormatDescription',
      dataKey: 'serialNumberFormatDescription',
      showHideGroup: '__groupless',
      title: 'Serial Number Format Description',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 250,
      sortable: false,
      frozen: false
    },
    {
      key: 'notes',
      dataKey: 'notes',
      showHideGroup: '__groupless',
      title: 'Notes',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 350,
      sortable: false,
      frozen: false
    }
  ];
  if (isAdmin) {
    partNumbersListColumns.unshift({
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    });
  }

  return partNumbersListColumns;
};

/**
 * Returns a list of columns that should be included based on the given
 * @param isAdmin Check for Admin access
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildAircraftModelListColumns = (isAdmin: boolean): ListGridLinkedDataColumn[] => {
  // Column definitions for aircraft type list grid
  const aircraftTypeListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'stcAvailable',
      dataKey: 'stcAvailable',
      showHideGroup: '__groupless',
      title: 'STC Available',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 186,
      sortable: true,
      frozen: false
    },
    {
      key: 'aircraftMake',
      dataKey: 'aircraftMake',
      showHideGroup: '__groupless',
      title: 'Make',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 186,
      sortable: true,
      frozen: false
    },
    {
      key: 'aircraftModel',
      dataKey: 'aircraftModel',
      showHideGroup: '__groupless',
      title: 'Model',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 190,
      sortable: true,
      frozen: false
    },
    {
      key: 'ka',
      dataKey: 'ka',
      showHideGroup: '__groupless',
      title: 'KA',
      getColumnJsx: IconCellRenderer,
      width: 100,
      sortable: true,
      frozen: false
    },
    {
      key: 'ku',
      dataKey: 'ku',
      showHideGroup: '__groupless',
      title: 'KU',
      getColumnJsx: IconCellRenderer,
      width: 100,
      sortable: true,
      frozen: false
    },
    {
      key: 'dualBand',
      dataKey: 'dualBand',
      showHideGroup: '__groupless',
      title: 'Dual Band',
      getColumnJsx: IconCellRenderer,
      width: 120,
      sortable: true,
      frozen: false
    },
    {
      key: 'vvipKa',
      dataKey: 'vvipKa',
      showHideGroup: '__groupless',
      title: 'VVIP KA',
      getColumnJsx: IconCellRenderer,
      width: 120,
      sortable: true,
      frozen: false
    },
    {
      key: 'vvipDualBand',
      dataKey: 'vvipDualBand',
      showHideGroup: '__groupless',
      title: 'VVIP Dual Band',
      getColumnJsx: IconCellRenderer,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'status',
      dataKey: 'status',
      showHideGroup: '__groupless',
      title: 'Status',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 156,
      sortable: true,
      frozen: false
    },
    {
      key: 'notes',
      dataKey: 'notes',
      showHideGroup: '__groupless',
      title: 'Notes',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 390,
      sortable: false,
      frozen: false
    }
  ];

  if (isAdmin) {
    aircraftTypeListColumns.unshift({
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    });
  }

  return aircraftTypeListColumns;
};

/**
 * Returns a list of columns that should be included based on the given
 * @param isAdmin Check for Admin access
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildRouterTypeListColumns = (isAdmin: boolean): ListGridLinkedDataColumn[] => {
  // Column definitions for router type list grid
  const routerTypeListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'status',
      dataKey: 'status',
      showHideGroup: '__groupless',
      title: 'Status',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 186,
      sortable: true,
      frozen: false
    },
    {
      key: 'routerType',
      dataKey: 'routerType',
      showHideGroup: '__groupless',
      title: 'Router Type',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 250,
      sortable: true,
      frozen: false
    },
    {
      key: 'serialNumber',
      dataKey: 'serialNumber',
      showHideGroup: '__groupless',
      title: 'Serial Number',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 190,
      sortable: true,
      frozen: false
    },
    {
      key: 'aerosphereId',
      dataKey: 'aerosphereId',
      showHideGroup: '__groupless',
      title: 'Aerospace ID',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 190,
      sortable: true,
      frozen: false
    },
    {
      key: 'simCardNumber',
      dataKey: 'simCardNumber',
      showHideGroup: '__groupless',
      title: 'SIM Card Number',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 190,
      sortable: true,
      frozen: false
    },
    {
      key: 'notes',
      dataKey: 'notes',
      showHideGroup: '__groupless',
      title: 'Notes',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 350,
      sortable: false,
      frozen: false
    }
  ];

  if (isAdmin) {
    routerTypeListColumns.unshift({
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    });
  }

  return routerTypeListColumns;
};

/**
 * Returns a list of columns that should be included based on the given
 * @param network Type of Network to render the specific column
 * @param isAdmin Check for Admin access
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildSoftwareVersionsListColumns = (network: string, isAdmin: boolean): ListGridLinkedDataColumn[] => {
  // Column definitions for Sw Versions list grid
  const swVersionsListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'softwareType',
      dataKey: 'softwareType',
      showHideGroup: '__groupless',
      title: `${network}-Band Software Type`,
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 240,
      sortable: true,
      frozen: false
    },
    {
      key: 'priorRelease',
      dataKey: 'priorRelease',
      showHideGroup: '__groupless',
      title: 'Prior Release',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 250,
      sortable: true,
      frozen: false
    },
    {
      key: 'productionRelease',
      dataKey: 'productionRelease',
      showHideGroup: '__groupless',
      title: 'Production Release',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 190,
      sortable: true,
      frozen: false
    },
    {
      key: 'pilotRelease',
      dataKey: 'pilotRelease',
      showHideGroup: '__groupless',
      title: 'Pilot Release',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 190,
      sortable: true,
      frozen: false
    },
    {
      key: 'notes',
      dataKey: 'notes',
      showHideGroup: '__groupless',
      title: 'Notes',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 700,
      sortable: false,
      frozen: false
    }
  ];
  if (isAdmin) {
    swVersionsListColumns.unshift({
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    });
  }

  return swVersionsListColumns;
};

/**
 * Returns a list of columns that should be included based on the given
 * @param isAdmin Check for Admin access
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildMapBundleVersionsListColumns = (isAdmin: boolean): ListGridLinkedDataColumn[] => {
  // Column definitions for ip addresses list grid
  const mapBundleFilesListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'mapBundleFileName',
      dataKey: 'mapBundleFileName',
      showHideGroup: '__groupless',
      title: 'Map Files',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 240,
      sortable: true,
      frozen: false
    },
    {
      key: 'priorReleaseSed',
      dataKey: 'priorReleaseSed',
      showHideGroup: 'Prior',
      title: 'SED',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'priorReleaseSscf',
      dataKey: 'priorReleaseSscf',
      showHideGroup: 'Prior',
      title: 'SSCF',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'productionReleaseSed',
      dataKey: 'productionReleaseSed',
      showHideGroup: 'Production',
      title: 'SED',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'productionReleaseSscf',
      dataKey: 'productionReleaseSscf',
      showHideGroup: 'Production',
      title: 'SSCF',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'pilotReleaseSed',
      dataKey: 'pilotReleaseSed',
      showHideGroup: 'Pilot',
      title: 'SED',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'pilotReleaseSscf',
      dataKey: 'pilotReleaseSscf',
      showHideGroup: 'Pilot',
      title: 'SSCF',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'notes',
      dataKey: 'notes',
      showHideGroup: '__groupless',
      title: 'Notes',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 500,
      sortable: false,
      frozen: false
    }
  ];

  if (isAdmin) {
    mapBundleFilesListColumns.unshift({
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    });
  }

  return mapBundleFilesListColumns;
};

/**
 * Returns a list of columns that should be included based on the given
 * @param isAdmin Check for Admin access
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildServicePlanTermsListColumns = (isAdmin: boolean): ListGridLinkedDataColumn[] => {
  // Column definitions for ip addresses list grid
  const servicePlanTermsListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'status',
      dataKey: 'status',
      showHideGroup: '__groupless',
      title: 'Status',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'term',
      dataKey: 'term',
      showHideGroup: '__groupless',
      title: 'term',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 200,
      sortable: true,
      frozen: false
    },
    {
      key: 'availableForViasat',
      dataKey: 'availableForViasat',
      showHideGroup: '__groupless',
      title: 'Viasat',
      getColumnJsx: IconCellRenderer,
      width: 100,
      sortable: true
    },
    {
      key: 'availableForSatcomDirect',
      dataKey: 'availableForSatcomDirect',
      showHideGroup: '__groupless',
      title: 'SD Satcom Direct',
      getColumnJsx: IconCellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'availableForHoneywell',
      dataKey: 'availableForHoneywell',
      showHideGroup: '__groupless',
      title: 'Honeywell',
      getColumnJsx: IconCellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'availableForCollins',
      dataKey: 'availableForCollins',
      showHideGroup: '__groupless',
      title: 'Collins',
      getColumnJsx: IconCellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'availableForOemMro',
      dataKey: 'availableForOemMro',
      showHideGroup: '__groupless',
      title: 'OEM/MRO',
      getColumnJsx: IconCellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'notes',
      dataKey: 'notes',
      showHideGroup: '__groupless',
      title: 'Notes',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 500,
      sortable: false,
      frozen: false
    }
  ];

  if (isAdmin) {
    servicePlanTermsListColumns.unshift({
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    });
  }

  return servicePlanTermsListColumns;
};

/**
 * Returns a list of columns that should be included based on the given
 * @param isAdmin Check for Admin access
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildServicePlanListColumns = (isAdmin: boolean): ListGridLinkedDataColumn[] => {
  // Column definitions for service plans list grid
  const servicePlansListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'network',
      dataKey: 'network',
      showHideGroup: '__groupless',
      title: 'Network',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 156,
      sortable: true,
      frozen: false
    },
    {
      key: 'generalPlanName',
      dataKey: 'generalPlanName',
      showHideGroup: '__groupless',
      title: 'General Plan Name',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 186,
      sortable: true,
      frozen: false
    },
    {
      key: 'planName',
      dataKey: 'planName',
      showHideGroup: '__groupless',
      title: 'Plan Name',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 200,
      sortable: true,
      frozen: false
    },
    {
      key: 'planStatus',
      dataKey: 'planStatus',
      showHideGroup: '__groupless',
      title: 'Plan Status',
      getColumnJsx: LongTextCellRenderer,
      width: 190,
      sortable: true
    },
    {
      key: 'planType',
      dataKey: 'planType',
      showHideGroup: '__groupless',
      title: 'Plan Type',
      getColumnJsx: LongTextCellRenderer,
      width: 190,
      sortable: true
    },
    {
      key: 'region',
      dataKey: 'region',
      showHideGroup: '__groupless',
      title: 'REGION',
      getColumnJsx: LongTextCellRenderer,
      width: 190,
      sortable: true
    },
    {
      key: 'dataAllowanceGb',
      dataKey: 'dataAllowanceGb',
      showHideGroup: '__groupless',
      title: 'Data Alloc (GB/mo)',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 200,
      sortable: true
    },
    {
      key: 'hoursAllowance',
      dataKey: 'hoursAllowance',
      showHideGroup: '__groupless',
      title: 'Hour Alloc (HR/mo)',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 200,
      sortable: true
    },
    {
      key: 'availableForViasat',
      dataKey: 'availableForViasat',
      showHideGroup: '__groupless',
      title: 'Viasat',
      getColumnJsx: IconCellRenderer,
      width: 100,
      sortable: true
    },
    {
      key: 'availableForSatcomDirect',
      dataKey: 'availableForSatcomDirect',
      showHideGroup: '__groupless',
      title: 'SD Satcom Direct',
      getColumnJsx: IconCellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'availableForHoneywell',
      dataKey: 'availableForHoneywell',
      showHideGroup: '__groupless',
      title: 'Honeywell',
      getColumnJsx: IconCellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'availableForCollins',
      dataKey: 'availableForCollins',
      showHideGroup: '__groupless',
      title: 'Collins',
      getColumnJsx: IconCellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'availableForOemMro',
      dataKey: 'availableForOemMro',
      showHideGroup: '__groupless',
      title: 'OEM/MRO',
      getColumnJsx: IconCellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'notes',
      dataKey: 'notes',
      showHideGroup: '__groupless',
      title: 'Notes',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 250,
      sortable: false
    }
  ];

  if (isAdmin) {
    servicePlansListColumns.unshift({
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    });
  }

  return servicePlansListColumns;
};

/**
 * Returns a list of columns that should be included based on the given
 * @param isAdmin Check for Admin access
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildIPAddressListColumns = (isAdmin: boolean): ListGridLinkedDataColumn[] => {
  // Column definitions for ip addresses list grid
  const ipAddressesListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'status',
      dataKey: 'status',
      showHideGroup: '__groupless',
      title: 'Status',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 140,
      sortable: false,
      frozen: false
    },
    {
      key: 'serviceProvider',
      dataKey: 'serviceProvider',
      showHideGroup: '__groupless',
      title: 'Service Provider',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 250,
      sortable: false,
      frozen: false
    },
    {
      key: 'realm',
      dataKey: 'realm',
      showHideGroup: '__groupless',
      title: 'Realm',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 190,
      sortable: false,
      frozen: false
    },
    {
      key: 'ipScope',
      dataKey: 'ipScope',
      showHideGroup: '__groupless',
      title: 'IP Scope',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 190,
      sortable: false,
      frozen: false
    },
    {
      key: 'comments',
      dataKey: 'comments',
      showHideGroup: '__groupless',
      title: 'Notes',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 900,
      sortable: false,
      frozen: false
    }
  ];
  if (isAdmin) {
    ipAddressesListColumns.unshift({
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    });
  }

  return ipAddressesListColumns;
};

/**
 * Returns a list of columns that should be included for Warranty Terms lookup
 * @param isAdmin Check for Admin access
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildWarrantyTermsListColumns = (isAdmin: boolean): ListGridLinkedDataColumn[] => {
  // Column definitions for ip addresses list grid
  const warrantyTermsListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'make',
      dataKey: 'make',
      showHideGroup: '__groupless',
      title: 'Installer',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 320,
      sortable: true,
      frozen: false
    },
    {
      key: 'installationType',
      dataKey: 'installationType',
      showHideGroup: '__groupless',
      title: 'Installation Type',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 260,
      sortable: true
    },
    {
      key: 'networkType',
      dataKey: 'networkType',
      showHideGroup: '__groupless',
      title: 'Network Type',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'warrantyTerms',
      dataKey: 'warrantyTerms',
      showHideGroup: '__groupless',
      title: 'Warranty Term',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'warrantyStartInstallation',
      dataKey: 'warrantyStartInstallation',
      showHideGroup: '__groupless',
      title: 'Warranty Start: Installation',
      getColumnJsx: IconCellRenderer,
      width: 250,
      sortable: true
    },
    {
      key: 'warrantyStartCustomerService',
      dataKey: 'warrantyStartCustomerService',
      showHideGroup: '__groupless',
      title: 'Warranty Start: Customer Service',
      getColumnJsx: IconCellRenderer,
      width: 300,
      sortable: true
    },
    {
      key: 'graceDuration',
      dataKey: 'graceDuration',
      showHideGroup: '__groupless',
      title: 'Grace Duration',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true
    },
    {
      key: 'notes',
      dataKey: 'notes',
      showHideGroup: '__groupless',
      title: 'Notes',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 400,
      sortable: false,
      frozen: false
    }
  ];

  if (isAdmin) {
    warrantyTermsListColumns.unshift({
      key: 'id',
      dataKey: 'id',
      showHideGroup: '__static',
      title: '',
      getColumnJsx: CheckboxCellRenderer,
      align: 'center',
      width: 40,
      frozen: true
    });
  }

  return warrantyTermsListColumns;
};

// Lookup Add/Edit Form Props
export const InputProps = {
  style: {
    maxHeight: 200,
    width: 290,
    height: 70,
    color: '#465967',
    fontFamily: 'Source Sans Pro'
  }
};

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
      color: '#465967',
      fontFamily: 'Source Sans Pro'
    }
  }
};

export const dynamicSort = (column: string, order: string) => {
  let result;
  const sortOrder = order === 'desc' ? -1 : 1;
  return function (obj1, obj2) {
    result = obj1[column] < obj2[column] ? -1 : obj1[column] > obj2[column] ? 1 : 0;

    return result * sortOrder;
  };
};

// Approval List Hidden Columns mappings for encoding/decoding sharelink
// Required: Enum name should match columnId name, case-sensitive
export enum LookupListColumnId {
  serialNumber,
  tailId
}

/**
 * Get the Name of a Column Id Enum Key
 * @param columnIdEnum key to convert to name
 * @returns name of enum as string, otherwise UNKNOWN_COLUMN_ID_NAME
 */
export const getColumnIdEnumName = (enumType: any, columnIdEnum: any): string =>
  columnIdEnum.hasOwnProperty(enumType) ? columnIdEnum[enumType] : 'UNKNOWN_COLUMN_ID_NAME';

/**
 * Get Approval List ColumnId Name
 * @param columnId columnId enum to convert to name
 * @returns name of columnId as string
 */
export const getColumnIdName = (columnId: LookupListColumnId) => getColumnIdEnumName(columnId, LookupListColumnId);

// Query Field Constants
export const QUERY_FIELD_MAPPING = {
  make: 'make'
};
/**
 * Get Approval List Query Field Mapping
 * @param columnId columnId enum to convert to query field
 * @param append append string to columnId name (default='')
 * @returns query field as string, otherwise undefined
 */
export const getQueryFieldMapping = (columnId: LookupListColumnId, append: string = ''): string | undefined => {
  const columnIdName = getColumnIdName(columnId) + append;
  return columnIdName ? QUERY_FIELD_MAPPING[columnIdName] : undefined;
};
