/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Validate Aircraft Query
 */
import {FetchQuery} from '../types';

export interface IAircraft {
  groupCode?: any;
  tailId: string;
  make: string;
  model: string;
  serialNumber: string;
  var?: string;
  endUser?: string;
  network?: string;
  taskId?: string;
  taskType?: string;
  status?: string;
  siteId: string;
  installationType?: string;
}

export interface ITask {
  taskId: string;
  taskStatus: string;
  taskType: string;
}

interface IServicePlan {
  servicePlanName: string;
  servicePlanStartDate: string;
  contractTerm: string;
}

export interface IEquipmentDetail {
  serialNumber: string;
  partNumber: string;
  configType: string;
  configSubType: string;
  status: string;
  warrantyStart: string;
  warrantyEnd: string;
}

export interface IValidateAircraft {
  isExists: boolean;
  aircraftDetails: IAircraft | null;
  isNewInstall: boolean;
  isRequestSubmittedForApproval: boolean;
  isValidationWithUpdatedDetails: boolean;
  taskDetails: ITask[];
  equipmentDetail: IEquipmentDetail[];
  servicePlanDetail: IServicePlan;
  userHaveAccess?: boolean;
}

export const identifyAircraftQuery: FetchQuery<IValidateAircraft> = {
  route: 'validateAircraft',
  transform: null
};
