/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Aircraft Information
 */

import {delegateToReducers} from '../Reducer';

export const AIRCRAFT_INFO_STORE_CONTEXT = 'aircraftInfo';

export interface AircraftInfoState {
  isNewInstall: boolean;
  isRequestSubmittedForApproval: boolean;
  serialNumber: string;
  tailId: string;
  make: string;
  model: string;
  network: string;
  aircraftNetwork: string;
  endUser: string;
  valueAddedReseller: string;
  status: string;
  siteId: string;
  taskId: string;
  installationType: string;
}

export const InitialAircraftInfoState: AircraftInfoState = {
  isNewInstall: false,
  isRequestSubmittedForApproval: false,
  serialNumber: null,
  tailId: null,
  make: null,
  model: null,
  network: null,
  aircraftNetwork: null,
  endUser: null,
  valueAddedReseller: null,
  status: null,
  siteId: null,
  taskId: null,
  installationType: null
};

export const AircraftInfoSessionStoreKeys: string[] = [
  'isNewInstall',
  'isRequestSubmittedForApproval',
  'serialNumber',
  'tailId',
  'make',
  'model',
  'network',
  'endUser',
  'valueAddedReseller',
  'aircraftNetwork',
  'status',
  'siteId',
  'taskId',
  'installationType'
];

export enum AircraftInfoAction {
  SET_USE_CASE = 'SET_USE_CASE',
  SET_IS_REQUEST_SUBMITTED_FLAG = 'SET_IS_REQUEST_SUBMITTED_FLAG',
  SET_AIRCRAFT = 'SET_AIRCRAFT',
  SET_TASK_ID = 'SET_TASK_ID',
  SET_AIRCRAFT_NETWORK = 'SET_AIRCRAFT_NETWORK',
  RESET_AIRCRAFT = 'RESET_AIRCRAFT'
}

/**
 * Aircraft Information action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const AircraftInfoReducer = (state: AircraftInfoState, action: any): AircraftInfoState => {
  switch (action.type) {
    case AircraftInfoAction.SET_USE_CASE:
      state = {
        ...state,
        isNewInstall: action.payload
      };
      break;
      case AircraftInfoAction.SET_IS_REQUEST_SUBMITTED_FLAG:
        state = {
          ...state,
          isRequestSubmittedForApproval: action.payload
        };
        break;
    case AircraftInfoAction.SET_AIRCRAFT:
      const aircraft = action.payload;
      state = {
        ...state,
        status: aircraft.isNewInstall ? 'New Install' : aircraft.status,
        ...aircraft,
        valueAddedReseller: aircraft.varAccountId ? aircraft.varAccountId : aircraft.valueAddedReseller
      };
      break;
    case AircraftInfoAction.SET_TASK_ID:
      state = {
        ...state,
        taskId: action.payload
      };
      break;
    case AircraftInfoAction.SET_AIRCRAFT_NETWORK:
      state = {
        ...state,
        aircraftNetwork: action.payload
      };
      break;
    case AircraftInfoAction.RESET_AIRCRAFT:
      state = {
        ...state,
        serialNumber: null,
        tailId: null,
        make: null,
        model: null,
        network: null,
        endUser: null,
        valueAddedReseller: null,
        installationType: null,
        status: null,
        siteId: null,
        taskId: null
      };
      break;
    default:
      break;
  }

  state = delegateToReducers(AIRCRAFT_INFO_STORE_CONTEXT, state, action, {}, InitialAircraftInfoState);

  return state;
};
