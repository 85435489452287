/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Request Aircraft Access
 */

import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {isNil} from 'lodash';

import {useStore} from '../../store/Store';
import useFetchV2 from '../../utils/useFetchV2';
import {requestAircraftAccessQuery} from '../../store/queries/requestAircraftAccessQuery';
import {AppAction} from '../../store/reducers/AppReducer';

import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import {BottomHorizontalRule, HeaderHorizontalRule, HeaderLabel, PageDescription} from '../CommonStyles';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import FormSubmitButton from '../common/elements/FormSubmitButton';

import {validateDropdownField} from '../../utils/validationUtils';

import {MenuProps, SELECT_AN_OPTION} from '../common/constants';

import {HELP_TEXT_COLOR} from '../common/theme/Colors';

const ID_PREFIX = 'requestAircraftAccess';

const RequestAccessForm: React.FC<{
  serialNumber: string;
  setRequestAircraftAccessFormData: (formData: {requestReason: string; reasonNote: string}) => void;
}> = ({serialNumber, setRequestAircraftAccessFormData}) => {
  const TextFieldInputProps = {
    style: {
      maxHeight: 200,
      height: 70,
      color: HELP_TEXT_COLOR,
      fontFamily: 'Source Sans Pro'
    }
  };

  const {handleSubmit, formState, watch, control} = useForm();

  const noteValue: string = watch('reasonNote');

  const requestAircraftAccessFormFields: FormField[] = [
    {
      id: `requestReason`,
      label: 'Reason for Request',
      type: FormFieldType.SELECT,
      value: SELECT_AN_OPTION,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: ['Replace Equipment', 'Other'],
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Reason for Request')
      }
    },
    {
      id: `reasonNote`,
      label: 'Note',
      type: FormFieldType.TEXTFIELD,
      placeholder: 'Reason for Request...',
      isMultiline: true,
      inputProps: TextFieldInputProps,
      value: '',
      disabled: false,
      rules: {
        required: false,
        maxLength: {
          value: 250,
          message: 'Note cannot exceed more than 250 characters'
        },
        pattern: null
      },
      helperText: `${noteValue?.length | 0} / 250`
    }
  ];

  return (
    <>
      <Breadcrumb currentRoute={window.location.pathname}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Request Access</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--description`}>
        Please select the reason for your request to access the aircraft bearing the serial number:{' '}
        <>{`${serialNumber}`}</>. If you have any additional information you'd like to share regarding this request, add
        your notes below.
      </PageDescription>
      <BottomHorizontalRule />
      <FormComponent
        idPrefix={ID_PREFIX}
        id="request-aircraft-access-form"
        formFields={requestAircraftAccessFormFields}
        setFormData={setRequestAircraftAccessFormData}
        formControlMethods={{handleSubmit, formState, watch, control}}
      ></FormComponent>
    </>
  );
};

const RequestAircraftAcccess: React.FC<{title: string}> = ({title}) => {
  const {store, dispatch} = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const {serialNumber} = location.state;
  const groupCode = store.customer.current.code;

  const [requestAircraftAccessFormData, setRequestAircraftAccessFormData] = useState(null);

  const queryParams = useMemo(() => {
    if (!requestAircraftAccessFormData || isNil(requestAircraftAccessFormData)) return;
    return {groupCode, serialNumber, ...requestAircraftAccessFormData};
  }, [requestAircraftAccessFormData, groupCode, serialNumber]);

  const {isLoading: requestAccessLoading, data: accessRequested} = useFetchV2(
    {route: requestAircraftAccessQuery.route, params: queryParams},
    requestAircraftAccessQuery.transform
  );

  useEffect(() => {
    if (requestAccessLoading || isNil(accessRequested)) return;

    navigate('/main-menu');
    dispatch({
      type: AppAction.SET_ALERT_INFO,
      payload: {
        showAlert: true,
        operation: null,
        message: 'We have received your request.',
        details: 'You will receive an email update upon Viasat review.'
      }
    });
    //eslint-disable-next-line
  }, [requestAccessLoading, accessRequested]);

  return (
    <PageContainer
      title={title}
      leftChild={
        <RequestAccessForm
          serialNumber={serialNumber}
          setRequestAircraftAccessFormData={setRequestAircraftAccessFormData}
        />
      }
      actionButtons={
        <FormSubmitButton
          buttonId={`${ID_PREFIX}--submitButton`}
          formId="request-aircraft-access-form"
          disabled={false}
          hasArrowIcon={true}
          isLoading={queryParams && requestAccessLoading}
          isSubmit={true}
        />
      }
    />
  );
};

export default RequestAircraftAcccess;
