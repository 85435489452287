/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Create ConfigurationItem data object as part of approval
 */
import {Query} from '../types';

export interface IConfigurationItem {
  id: string;
  isSuccess: boolean;
  status?: number;
  message?: string;
  data?: any[];
}

export const createConfigurationItemQuery: Query<IConfigurationItem> = {
  route: 'salesforce/create/configurationItem',
  transform: null
};
