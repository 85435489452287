/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Replace Router Information
 */
import React, {useEffect, useMemo} from 'react';
import {useWatch, useForm} from 'react-hook-form';
import {isNil} from 'lodash';

import {useStore} from '../../store/Store';
import {EquipmentInfoAction, IRouterInformation} from '../../store/reducers/EquipmentInfoReducer';
import {getExistingEquipmentInfoQuery} from '../../store/queries/getExistingEquipmentInfoQuery';
import {updateRouterInformation} from '../../store/queries/newInstall/equipmentInfoQuery';

import ReplaceEquipmentBase from './ReplaceEquipmentBase';
import {FormField, FormFieldType} from '../common/elements/Form';
import {FormConfig, FormTypes} from './EquipmentUpdateForm';

import {
  OTHER_ROUTER,
  serialNumberPatternRegex,
  simNumberPatternRegex,
  VSR_ROUTER,
  routerIdPatternRegex,
  routerTypeList,
  SELECT_AN_OPTION
} from '../common/constants';
import {validateDropdownField} from '../../utils/validationUtils';
import useFetchV2 from '../../utils/useFetchV2';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
      color: '#465967'
    }
  }
};

interface IFormInput {
  routerType: string;
  serialNumber?: string;
  routerId?: string;
  simNumber?: string;
  otherRouterType?: string;
}

interface APSUReplaceProps {
  title: string;
}

const RouterReplace: React.FC<APSUReplaceProps> = ({title}) => {
  const {handleSubmit, formState, control, watch, reset, setValue} = useForm();
  const {store, dispatch} = useStore();
  const {siteId} = store.aircraftInfo;
  const routerType: string = useWatch({
    control,
    name: 'routerType'
  });
  const queryParams = useMemo(() => {
    if (isNil(siteId) || routerType !== 'Viasat BizAv Router (VSR)') return;
    return {
      siteId: siteId,
      configType: 'Router',
      configSubType: 'Viasat'
    };
  }, [siteId, routerType]);

  const {isLoading: existingEquipmentInfoLoading, data: existingEquipmentData} = useFetchV2(
    {route: getExistingEquipmentInfoQuery.route, params: queryParams},
    getExistingEquipmentInfoQuery.transform
  );
  useEffect(() => {
    if (existingEquipmentInfoLoading && !existingEquipmentData) return;
    if (existingEquipmentData && existingEquipmentData.length) {
      dispatch({
        type: EquipmentInfoAction.SET_EXISTING_ROUTER_INFO,
        payload: existingEquipmentData[0]
      });
      setValue('serialNumber', existingEquipmentData[0]?.serialNumber);
      setValue('routerId', existingEquipmentData[0]?.softwareId);
      setValue('simNumber', existingEquipmentData[0]?.simCardId);
    }
    // eslint-disable-next-line
  }, [existingEquipmentInfoLoading]);

  const formConfigList: FormConfig[] = [
    {
      id: FormTypes.EXISTING,
      title: 'Enter Existing Router Details',
      description:
        'Use your mobile device to scan the QR code on the Router, or manually enter the router information for the new terminal.',
      footerText: 'View latest revision of the Viasat Router Operations and Test Manual here:',
      footerHyperLinkText: 'Router Installation Procedures',
      footerHyperLinkUrl: '#',
      actionType: EquipmentInfoAction.SET_EXISTING_ROUTER_INFO
    },
    {
      id: FormTypes.NEW,
      title: 'Enter New Router Details',
      description:
        'Use your mobile device to scan the QR code on the router, or manually enter the Router information for the new terminal.',
      footerText: '',
      footerHyperLinkText: '',
      footerHyperLinkUrl: '',
      actionType: EquipmentInfoAction.SET_ROUTER_INFO
    }
  ];

  const selectedRouterType: string = useWatch({
    control,
    name: 'routerType'
  });

  const getFormFields = (
    initialValues: IFormInput,
    isExistingForm: boolean,
    selectedRouterType: string
  ): FormField[] => {
    const isVSRRouter = (!selectedRouterType ? initialValues.routerType : selectedRouterType) === VSR_ROUTER;
    const isOtherRouter = selectedRouterType === OTHER_ROUTER;
    const existingRouter: IRouterInformation = store.equipmentInfo.existingRouter;
    const routerTypeFormField: FormField[] = [
      {
        id: 'routerType',
        label: 'Router Type',
        type: FormFieldType.SELECT,
        value: initialValues.routerType,
        disabled: false,
        menuProps: MenuProps,
        dropDownValues: routerTypeList,
        rules: {
          required: true,
          pattern: null,
          validate: (value, formValues) => validateDropdownField(value, 'Router Type')
        }
      }
    ];

    const otherRouterTypeFormField: FormField[] = [
      {
        id: 'otherRouterType',
        label: '',
        type: FormFieldType.INPUT,
        value: initialValues.otherRouterType,
        disabled: false,
        rules: {
          required: true,
          pattern: null,
          validate: (value, formValues) => {
            if (!isExistingForm)
              return (
                value !== existingRouter.otherRouterType ||
                'Invalid Router type. Router type cannot be same as installed hardware.'
              );
            else return true;
          }
        }
      }
    ];

    const vsrRouterInfoFormFields: FormField[] = [
      {
        id: 'serialNumber',
        label: 'Serial Number',
        type: FormFieldType.INPUT,
        value: initialValues.serialNumber,
        disabled: false,
        rules: {
          required: isVSRRouter,
          pattern: {value: new RegExp(serialNumberPatternRegex), message: 'Invalid Serial Number'},
          validate: (value, formValues) => {
            if (!isExistingForm)
              return (
                value !== existingRouter.serialNumber ||
                'Invalid Serial Number. Serial Number cannot be same as installed hardware.'
              );
            else return true;
          }
        }
      },
      {
        id: 'routerId',
        label: 'Router ID (Aerosphere)',
        type: FormFieldType.INPUT,
        value: initialValues.routerId,
        disabled: false,
        rules: {
          required: isVSRRouter,
          pattern: {value: new RegExp(routerIdPatternRegex), message: 'Invalid Router ID'},
          validate: (value, formValues) => {
            if (!isExistingForm)
              return (
                value !== existingRouter.routerId ||
                'Invalid Router Id. Router Id cannot be same as installed hardware.'
              );
            else return true;
          }
        }
      },
      {
        id: 'simNumber',
        label: 'SIM Number',
        type: FormFieldType.INPUT,
        value: initialValues.simNumber,
        disabled: false,
        rules: {
          required: isVSRRouter,
          pattern: {value: new RegExp(simNumberPatternRegex), message: 'Invalid SIM Number'},
          validate: (value, formValues) => {
            if (!isExistingForm)
              return (
                value !== existingRouter.simNumber ||
                'Invalid SIM Number. SIM Number cannot be same as installed hardware.'
              );
            else return true;
          }
        }
      }
    ];

    const routerInfoFormFields = [...routerTypeFormField];
    isVSRRouter && routerInfoFormFields.push(...vsrRouterInfoFormFields);
    isOtherRouter &&
      routerInfoFormFields.push(...otherRouterTypeFormField) &&
      routerInfoFormFields.push(...vsrRouterInfoFormFields);
    return routerInfoFormFields;
  };

  return (
    <ReplaceEquipmentBase<IFormInput>
      title={title}
      aircraftSerialNumber={''}
      formIdPrefix={'replaceRouterForm'}
      equipmentName="router"
      existingEquipmentStoreKey="existingRouter"
      newEquipmentStoreKey="router"
      formConfigList={formConfigList}
      defaultFormValues={{
        routerType: SELECT_AN_OPTION,
        serialNumber: null,
        routerId: null,
        simNumber: null,
        otherRouterType: null
      }}
      getFormFields={(initialValues, isExistingForm) =>
        getFormFields(initialValues, isExistingForm, selectedRouterType)
      }
      formControlMethods={{handleSubmit, formState, control, watch, reset}}
      fetchQuery={updateRouterInformation}
    />
  );
};

export default RouterReplace;
