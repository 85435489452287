/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Open Tasks list view
 */
import React, {useEffect, useMemo, useState} from 'react';
import {MenuBar, ShareLink, ListGridV2, LoadingButton, ListGridColumn} from '@viasat/insights-components';

import PageStyled from '../../../theme/PageStyled';
import {PageProps} from '../../common/layout/PageProps';

import ListGridContainer from '../ListGridContainer';
import {getElementId} from '../../../utils/elementIdUtils';
import useFetch from '../../../utils/useFetch';
import {IOpenTaskList, openTaskQuery} from '../../../store/queries/openTaskListQuery';
import {getCurrentUrlPath} from '../../../utils/linkUtils';
import {handleExportClick} from './exportOpenTaskList';
import {useStore} from '../../../store/Store';
import {OPEN_TASKS_INFO_STORE_CONTEXT, OpenTasksInfoAction} from '../../../store/reducers/OpenTasksReducer';
import {
  useSortingParams,
  useFilteringParams,
  downloadProgressPercentCalculation,
  getListViewFilters
} from '../listsUtil';
import {
  OPEN_TASKS_LIST_FILTERS,
  OpenTasksListColumnId,
  buildOpenTasksListColumns,
  fetchDBColumnKey,
  getQueryFieldMapping
} from './openTasksUtils';
import {useGetTaskDetails, useSetAircraftInfo, useValidateSerialNumber} from '../../../utils/validateAircraftHooks';
import FilterHookContainer from '../../common/elements/filterSelector/FilterHookContainer';
import ListViewSortContainer from '../ListViewSortContainer';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import {getFilterOptions} from '../../../utils/filterUtils';
import {FilterAction} from '../../../store/reducers/FiltersReducer';
import {AircraftInfoState} from '../../../store/reducers/AircraftInfoReducer';

const ID_PREFIX = 'openTasksList';

const OpenTasksListView: React.FC<PageProps> = () => {
  // Builds open tasks list columns with the set current time
  const [downloadProgressPercentage, setDownloadProgressPercentage] = useState(0);
  const {store, dispatch} = useStore();
  const [timerIndex, setTimerIndex] = useState(-1);
  const [selectedAircraft, setSelectedAircraft] = useState<{serialNumber: string}>(null);

  /**
   * Navigates to the main menu the selected task and calls the customer hook to get the on-going task details
   * @param rowData Open Task List row data
   */
  const handleOpenTaskLinkClick = (rowData: any) => {
    setSelectedAircraft({serialNumber: rowData.serialNumber});
  };
  const openTasksListColumns = buildOpenTasksListColumns(handleOpenTaskLinkClick);

  const clamp = (n: number, min: number, max: number) => Math.max(Math.min(n, max), min);

  const clearIndex = () => {
    if (timerIndex > -1) {
      clearTimeout(timerIndex);
      setTimerIndex(-1);
    }
  };

  useEffect(() => {
    clearIndex();
    if (downloadProgressPercentage > 0 && downloadProgressPercentage < 100) {
      // using line equation : ms = 2.278 * row + 2975
      const update = (7.778 * openTasksListColumns.length + 7975) / 50;
      const idx = window.setTimeout(() => {
        let amount = 0;
        const {progressAmount, progressValue} = downloadProgressPercentCalculation(downloadProgressPercentage, amount);
        amount = progressAmount;
        setDownloadProgressPercentage(clamp(progressValue + progressAmount, 0, 99.4));
      }, update);
      setTimerIndex(idx);
    }
    // eslint-disable-next-line
  }, [downloadProgressPercentage]);

  const urlPath = getCurrentUrlPath();
  const {
    sortBy,
    sortOrder,
    sort,
    filters: {filters = []}
  } = store.openTasksInfo;
  useEffect(() => {
    dispatch({
      type: OpenTasksInfoAction.SET_OPEN_TASKS_SORT_INFO,
      payload: {sortBy: 'createdTstamp', sortOrder: 'desc'}
    });
  }, [dispatch]);

  // Determine what columns to show and hide for the list grid
  const currentColumns = openTasksListColumns;
  const exportColumns = fetchDBColumnKey(handleOpenTaskLinkClick);
  const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';
  const defaultParams = {
    groupCode,
    doCache: false
  };
  const openTasksListFilters = useMemo(() => getListViewFilters(OPEN_TASKS_LIST_FILTERS), []);

  const queryParamsWithFiltering = useFilteringParams(defaultParams, openTasksListFilters, filters);

  const queryParamsWithSorting = useSortingParams(queryParamsWithFiltering, sortBy, sortOrder);

  const {data: openTasksListData, isLoading: openTasksLoading} = useFetch<IOpenTaskList[]>(
    openTaskQuery,
    queryParamsWithSorting
  );

  const handleExport = async (downloadSuccess, downloadFailure) => {
    handleExportClick(
      queryParamsWithSorting,
      exportColumns,
      downloadSuccess,
      setDownloadProgressPercentage,
      downloadFailure
    );
  };

  const {validateInProgress, validatedAircraft} = useValidateSerialNumber(selectedAircraft, () => {}, true);
  const updateAircraftInfo = !validateInProgress && !!validatedAircraft?.aircraftDetails;

  useSetAircraftInfo(updateAircraftInfo, validatedAircraft?.aircraftDetails);
  useGetTaskDetails(
    updateAircraftInfo,
    false,
    validatedAircraft?.taskDetails,
    groupCode,
    '/main-menu',
    validatedAircraft?.aircraftDetails as AircraftInfoState
  );

  const setShowListNotification = (show: boolean) => {
    return;
  };

  // useEffect Purpose: Update the options available for filtering each of the filterable columns
  useEffect(() => {
    if (openTasksListFilters) {
      const {domainOptions, rangeOptions} = getFilterOptions({listData: openTasksListData}, openTasksListFilters);

      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, OPEN_TASKS_INFO_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [openTasksListData, dispatch, openTasksListFilters]);

  const handleFilterChange = () => {
    setShowListNotification(true); // allows notification to be shown again on filter change
  };

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(OpenTasksListColumnId[columnId]);

  const handleSortChange = ({column, order}: {column: string; order: string}) => {
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, OPEN_TASKS_INFO_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(openTasksListColumns[column]),
        validColumns: openTasksListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: openTasksListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(openTasksListColumns[col.key])
        )
      }
    });
  };

  return (
    <PageStyled>
      <MenuBar
        getFullElementId={(name: string, type: string) => getElementId(ID_PREFIX, 'menuBar', name, type)}
        title={`Open Tasks ${!openTasksLoading && openTasksListData ? `(${openTasksListData?.length})` : ''}`}
        subtitle={''}
        isSubtitleLoading={openTasksLoading}
        leftStack={[
          <FilterHookContainer
            key="filters"
            idPrefix={ID_PREFIX}
            storeContext={OPEN_TASKS_INFO_STORE_CONTEXT}
            handleFilterChange={handleFilterChange}
          />,
          <ListViewSortContainer
            key="sorts"
            idPrefix={ID_PREFIX}
            disabled={openTasksLoading}
            storeContext={OPEN_TASKS_INFO_STORE_CONTEXT}
            columns={openTasksListColumns}
            showColumnGroups={true}
            getQueryFieldForColumn={getQueryFieldForColumn}
          />
        ]}
        rightStack={[
          <LoadingButton
            getFullElementId={(name, type) => `${ID_PREFIX}--exportButton__${name}-${type}`}
            key={`export${ID_PREFIX}`}
            title="CSV"
            successMessage="Done"
            errorMessage="Error"
            messageDurationMs={5000}
            isDisabled={false}
            onClick={handleExport}
            percentageComplete={downloadProgressPercentage}
          />,
          <ShareLink
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'shareLink', name, type)}
            disabled={openTasksLoading}
            urlPath={urlPath}
            urlQuery={''}
          />
        ]}
        tabs={null}
      />
      <ListGridContainer>
        <ListGridV2
          listGridData={openTasksListData && !openTasksLoading ? openTasksListData : []}
          listGridDataTotal={!openTasksLoading && openTasksListData ? openTasksListData?.length : 0}
          isHeaderLoading={openTasksLoading}
          isLoading={openTasksLoading}
          columns={currentColumns}
          fullWidthLoadingBars={true}
          handleSortChange={handleSortChange}
          selectedSort={sort}
        />
      </ListGridContainer>
    </PageStyled>
  );
};

export default OpenTasksListView;
