/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add New IP Address in Lookup Table
 *
 */
import React, {useMemo, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {AddForm, AddFormHeader, DrawerButton, ButtonContainer, FormContainer} from '../LookupTableStyles';
import {addIPAddressQuery} from '../../../../store/queries/lookupQueries';
import {SideDrawerProps, togglePanel, handleBackdropClick, InputProps} from '../lookupTableUtils';
import {useStore} from '../../../../store/Store';
import {AppAction} from '../../../../store/reducers/AppReducer';
import {LookupsListAction} from '../../../../store/reducers/LookupsReducer';
import FormComponent, {FormField, FormFieldType} from '../../../common/elements/Form';
import useFetchV2 from '../../../../utils/useFetchV2';

const ID_PREFIX = 'ipAddress';

interface IFormInput {
  serviceProvider: string;
  realm: string;
  ipScope: string;
  comments: string;
}

const AddIPAddress: React.FC<SideDrawerProps> = ({openPanel, setOpenPanel}) => {
  const {dispatch} = useStore();
  const [newIPAddressInfo, setNewIPAddressInfo] = useState<any>(null);
  const initialValues: IFormInput = {
    serviceProvider: '',
    realm: '',
    ipScope: '',
    comments: ''
  };

  const ipAddressFormFields: FormField[] = [
    {
      id: 'serviceProvider',
      label: 'Service Provider',
      type: FormFieldType.INPUT,
      value: initialValues.serviceProvider,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'realm',
      label: 'Realm',
      type: FormFieldType.INPUT,
      value: initialValues.realm,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'ipScope',
      label: 'IP Scope',
      type: FormFieldType.INPUT,
      value: initialValues.ipScope,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'comments',
      label: 'Notes',
      type: FormFieldType.TEXTFIELD,
      value: initialValues.comments,
      disabled: false,
      inputProps: InputProps,
      isMultiline: true,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  const {handleSubmit, formState, unregister, reset, watch, control} = useForm();

  const queryParams = useMemo(() => {
    if (newIPAddressInfo && (!newIPAddressInfo.serviceProvider || !newIPAddressInfo.realm || !newIPAddressInfo.ipScope))
      return;
    return newIPAddressInfo;
  }, [newIPAddressInfo]);

  const {isLoading: addInProgress, data: addNewIPAddressInfo} = useFetchV2(
    {route: addIPAddressQuery.route, params: queryParams},
    addIPAddressQuery.transform
  );

  const onCancelClick = () => {
    unregister();
    reset(
      {serviceProvider: '', realm: '', ipScope: '', comments: ''},
      {
        keepValues: false
      }
    );
    setOpenPanel(false);
  };

  const handleOpen = () => {
    togglePanel(true);
    setOpenPanel(true);
  };

  const handleClose = () => {
    if (localStorage.getItem('backdropClick') === 'true') {
      localStorage.setItem('backdropClick', 'false');
      return;
    }
    togglePanel(false);
    setOpenPanel(false);
  };

  useEffect(() => {
    if (addInProgress) return;
    if (addNewIPAddressInfo) {
      setOpenPanel(false);
      dispatch({
        type: LookupsListAction.SET_NEW_ROW_COUNT,
        payload: 1
      });
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'New ip address entry added.'}
      });
    }
    //eslint-disable-next-line
  }, [addInProgress, addNewIPAddressInfo]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={openPanel}
        onOpen={handleOpen}
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
      >
        <AddForm>
          <AddFormHeader id={`${ID_PREFIX}--ipAddressFormTitle`}>ADD NEW IP ADDRESS INFO</AddFormHeader>
          <FormContainer>
            <FormComponent
              id="add-ip-address-model-form"
              formFields={ipAddressFormFields}
              setFormData={setNewIPAddressInfo}
              formControlMethods={{handleSubmit, formState, watch, control}}
              idPrefix={ID_PREFIX}
            ></FormComponent>
          </FormContainer>
          <ButtonContainer>
            <DrawerButton id="ip-address-cancel-button" className="cancel-button" onClick={onCancelClick}>
              <span className="button-text">Cancel</span>
            </DrawerButton>
            <DrawerButton
              form="add-ip-address-model-form"
              id="ip-address-add-button"
              className="add-button"
              onClick={() => null}
            >
              <span className="button-text">Add</span>
            </DrawerButton>
          </ButtonContainer>
        </AddForm>
      </SwipeableDrawer>
    </>
  );
};

export default AddIPAddress;
