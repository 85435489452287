/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: App Reducer
 */

import {IConfigItemsObject} from '../queries/getAllConfigItemsQuery';
import {IAircraftModel, IServicePlan, IServicePlanTerms} from '../queries/lookupTypes';
import {IVarAccount} from '../queries/getVarAccountListQuery';
import {IOemAccount} from '../queries/getOemAccountListQuery';
import {IVarKaBandIp} from '../queries/varKaBandIpListQuery';
import {IEndUsers} from '../queries/endUsersQuery';

export const APP_STORE_CONTEXT = 'app';

export interface SnackBarProps {
  openSnack: boolean;
  snackMessage?: string;
}

export interface AlertInfoProps {
  showAlert: boolean;
  operation: string;
  message: string;
  details: string;
}

export interface BreadCrumb {
  linkPath: string;
  linkText: string;
}
export interface IRightPanelAccordionState {
  aircraftInfo: boolean;
  equipmentInfo: boolean;
  servicePlanInfo: boolean;
  customerInfo: boolean;
  warrantyInfo: boolean;
}
export interface AppState {
  menuOpened: boolean;
  isInternalLink: boolean;
  breadcrumb: Array<BreadCrumb>;
  snackbar: SnackBarProps;
  alertInfo: AlertInfoProps;
  servicePlans: IServicePlan[];
  servicePlanTerms: IServicePlanTerms[];
  aircraftModels: IAircraftModel[];
  configItems: IConfigItemsObject;
  varAccountList: IVarAccount[];
  oemAccountList: IOemAccount[];
  varKaIpList: IVarKaBandIp[];
  endUsers: IEndUsers[];
  rightPanelAccordionState: IRightPanelAccordionState;
}

export const InitialAppState: AppState = {
  menuOpened: false,
  isInternalLink: false,
  breadcrumb: [],
  snackbar: null,
  alertInfo: null,
  servicePlans: [],
  servicePlanTerms: [],
  aircraftModels: [],
  configItems: {},
  varAccountList: [],
  oemAccountList: [],
  varKaIpList: [],
  endUsers: [],
  rightPanelAccordionState: {
    aircraftInfo: true,
    equipmentInfo: false,
    servicePlanInfo: false,
    customerInfo: false,
    warrantyInfo: false
  }
};

export const AppSessionStoreKeys: string[] = [
  'servicePlans',
  'servicePlanTerms',
  'aircraftModels',
  'configItems',
  'varAccountList',
  'varKaIpList',
  'rightPanelAccordionState'
];

export enum AppAction {
  SET_MENU_OPENED = 'SET_MENU_OPENED',
  SET_RIGHT_PANEL_ACCORDIONS_EXPANDED = 'SET_RIGHT_PANEL_ACCORDIONS_EXPANDED',
  PROCESS_URL_PARAMS = 'PROCESS_URL_PARAMS',
  SET_BREADCRUMB = 'SET_BREADCRUMB',
  SET_SNACK_BAR = 'SET_SNACK_BAR',
  SET_ALERT_INFO = 'SET_ALERT_INFO',
  SET_SERVICE_PLANS = 'SET_SERVICE_PLANS',
  SET_SERVICE_PLAN_TERMS = 'SET_SERVICE_PLAN_TERMS',
  SET_AIRCRAFT_MODELS = 'SET_AIRCRAFT_MODELS',
  SET_CONFIG_ITEMS = 'SET_CONFIG_ITEMS',
  SET_VAR_ACCOUNT_LIST = 'SET_VAR_ACCOUNT_LIST',
  SET_OEM_ACCOUNT_LIST = 'SET_OEM_ACCOUNT_LIST',
  SET_VAR_KA_IP_LIST = 'SET_VAR_KA_IP_LIST',
  SET_RIGHT_PANEL_ACCORDION_STATE = 'SET_RIGHT_PANEL_ACCORDION_STATE',
  SET_END_USERS = 'SET_END_USERS'
}

export const filterHistory = (currentState: any) => ({
  ...currentState,
  app: {
    ...currentState.app
  }
});

/**
 * Menu action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const AppReducer = (state: AppState, action: any): AppState => {
  console.debug(action.type);
  switch (action.type) {
    case AppAction.SET_MENU_OPENED:
      state = {
        ...state,
        menuOpened: action.payload
      };
      break;

    case AppAction.PROCESS_URL_PARAMS:
      const {isInternalLink} = action.payload.params;
      let newState = {};
      // make sure the url parm exists (params are always strings) and don't set unless it's different
      if (typeof isInternalLink === 'string' && isInternalLink !== String(state.isInternalLink)) {
        newState = {
          ...newState,
          isInternalLink: isInternalLink === 'true'
        };
      }
      state = {
        ...state,
        ...newState
      };
      break;
    case AppAction.SET_SERVICE_PLANS:
      state = {
        ...state,
        servicePlans: action.payload
      };
      break;
    case AppAction.SET_SERVICE_PLAN_TERMS:
      state = {
        ...state,
        servicePlanTerms: action.payload
      };
      break;

    case AppAction.SET_RIGHT_PANEL_ACCORDION_STATE:
      state = {
        ...state,
        rightPanelAccordionState: {...state.rightPanelAccordionState, ...action.payload}
      };
      break;

    case AppAction.SET_AIRCRAFT_MODELS:
      state = {
        ...state,
        aircraftModels: action.payload
      };
      break;
    case AppAction.SET_CONFIG_ITEMS:
      state = {
        ...state,
        configItems: action.payload
      };
      break;

    case AppAction.SET_VAR_ACCOUNT_LIST:
      state = {
        ...state,
        varAccountList: action.payload
      };
      break;
    case AppAction.SET_OEM_ACCOUNT_LIST:
      state = {
        ...state,
        oemAccountList: action.payload
      };
      break;
    case AppAction.SET_VAR_KA_IP_LIST:
      state = {
        ...state,
        varKaIpList: action.payload
      };
      break;
    case AppAction.SET_END_USERS:
      state = {
        ...state,
        endUsers: action.payload
      };
      break;
    case AppAction.SET_BREADCRUMB:
      state = {
        ...state,
        breadcrumb: action.payload
      };
      break;

    case AppAction.SET_SNACK_BAR:
      state = {
        ...state,
        snackbar: action.payload
      };
      break;

    case AppAction.SET_ALERT_INFO:
      state = {
        ...state,
        alertInfo: action.payload
      };
      break;

    default:
      break;
  }
  return state;
};
