/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Form Submit/Next Button
 */
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingSpinner} from '@viasat/insights-components';

import {NextButton} from '../../CommonStyles';

interface ISubmitButton {
  isLoading: boolean;
  buttonId: string;
  formId: string;
  isSubmit?: boolean;
  hasArrowIcon?: boolean;
  disabled?: boolean;
  handleClick?: () => void;
  width?: number;
  bgColor?: string;
  buttonText?: string;
}
const FormSubmitButton: React.FC<ISubmitButton> = ({
  isLoading,
  buttonId,
  formId,
  isSubmit,
  hasArrowIcon,
  disabled,
  handleClick,
  width,
  bgColor,
  buttonText
}) => {
  return (
    <NextButton id={buttonId} form={formId} disabled={disabled} bgColor={bgColor} onClick={handleClick} width={width}>
      <span className="next-button-text">{buttonText ? buttonText : isSubmit ? 'Submit' : 'Next'}</span>
      {isLoading ? (
        <LoadingSpinner size={14} id={`${buttonId}--Loading`} />
      ) : hasArrowIcon ? (
        <ArrowForwardIcon className="next-arrow" />
      ) : null}
    </NextButton>
  );
};

export default FormSubmitButton;
