/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Replace FanTray Information
 */
import React, {useEffect, useMemo} from 'react';
import {useWatch, useForm} from 'react-hook-form';
import {isNil} from 'lodash';

import {useStore} from '../../store/Store';
import {EquipmentInfoAction, IAcuInformation} from '../../store/reducers/EquipmentInfoReducer';

import ReplaceEquipmentBase from './ReplaceEquipmentBase';
import {FormField, FormFieldType} from '../common/elements/Form';
import {FormConfig, FormTypes} from './EquipmentUpdateForm';

import {updateFanTrayInformation} from '../../store/queries/newInstall/equipmentInfoQuery';
import {EquipmentType, SELECT_AN_OPTION, serialNumberValidation} from '../common/constants';
import {validateDropdownField} from '../../utils/validationUtils';
import {usePartNumbersFetch} from '../../utils/customHooks';
import {getPartNumbersQuery} from '../../store/queries/lookupQueries';
import {getExistingEquipmentInfoQuery} from '../../store/queries/getExistingEquipmentInfoQuery';
import useFetchV2 from '../../utils/useFetchV2';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
      width: 250,
      color: '#465967'
    }
  }
};

interface IFormInput {
  partNumber: string;
  serialNumber: string;
}

interface APSUReplaceProps {
  title: string;
}

const FanTrayReplace: React.FC<APSUReplaceProps> = ({title}) => {
  const {handleSubmit, formState, control, watch, reset, setValue} = useForm();
  const {store, dispatch} = useStore();
  const {aircraftInfo} = store;
  const {aircraftNetwork, siteId} = aircraftInfo;
  const queryParams = useMemo(() => {
    if (isNil(siteId) || isNil(aircraftNetwork)) return;
    return {
      siteId: siteId,
      configType: 'Fan Tray',
      configSubType: aircraftNetwork
    };
  }, [siteId, aircraftNetwork]);

  const {isLoading: existingEquipmentInfoLoading, data: existingEquipmentData} = useFetchV2(
    {route: getExistingEquipmentInfoQuery.route, params: queryParams},
    getExistingEquipmentInfoQuery.transform
  );
  useEffect(() => {
    if (existingEquipmentInfoLoading && !existingEquipmentData) return;
    if (existingEquipmentData && existingEquipmentData.length) {
      dispatch({
        type: EquipmentInfoAction.SET_EXISTING_FAN_TRAY_INFO,
        payload: existingEquipmentData[0]
      });
      setValue('partNumber', existingEquipmentData[0]?.partNumber);
      setValue('serialNumber', existingEquipmentData[0]?.serialNumber);
    }
    // eslint-disable-next-line
  }, [existingEquipmentInfoLoading]);

  const formConfigList: FormConfig[] = [
    {
      id: FormTypes.EXISTING,
      title: 'Enter Existing FanTray Details',
      description:
        'Use your mobile device to scan the QR code on the FanTray, or manually enter the FanTray information for the new terminal.',
      footerText: 'View latest revision of the Viasat FanTray Operations and Test Manual here:',
      footerHyperLinkText: 'FanTray Installation Procedures',
      footerHyperLinkUrl: '#',
      actionType: EquipmentInfoAction.SET_EXISTING_FAN_TRAY_INFO
    },
    {
      id: FormTypes.NEW,
      title: 'Enter New FanTray Details',
      description:
        'Use your mobile device to scan the QR code on the FanTray, or manually enter the FanTray information for the new terminal.',
      footerText: '',
      footerHyperLinkText: '',
      footerHyperLinkUrl: '',
      actionType: EquipmentInfoAction.SET_FAN_TRAY_INFO
    }
  ];

  const selectedPartNumber: string = useWatch({
    control,
    name: 'partNumber'
  });
  const serialNumberRegex = serialNumberValidation[selectedPartNumber];

  const partNumbersList = usePartNumbersFetch(EquipmentType.FAN_TRAY, aircraftNetwork, getPartNumbersQuery);

  const partNumberParams = partNumbersList?.filter(partNumber => partNumber.viasatPartNumber === selectedPartNumber)[0];

  const serialNumberFormat = partNumberParams?.serialNumberFormat;
  const serialNumberFormatDescription = partNumberParams?.serialNumberFormatDescription;

  const getFormFields = (initialValues: IFormInput, isExistingForm: boolean): FormField[] => {
    const existingFanTray: IAcuInformation = store.equipmentInfo.existingFanTray;
    const equipmentFormFields: FormField[] = [
      {
        id: 'partNumber',
        label: 'Part Number',
        type: FormFieldType.SELECT,
        value: initialValues.partNumber,
        disabled: false,
        menuProps: MenuProps,
        dropDownValues: partNumbersList.map(partNumber => partNumber.viasatPartNumber),
        rules: {
          required: true,
          pattern: null,
          validate: (value, formValues) => validateDropdownField(value, 'Part Number')
        }
      },
      {
        id: 'serialNumber',
        label: 'Serial Number',
        type: FormFieldType.INPUT,
        value: initialValues.serialNumber,
        disabled: false,
        placeholder: serialNumberFormat ? `  eg. ${serialNumberFormat}` : '',
        description: serialNumberFormat ? {title: 'Serial Number Format', value: serialNumberFormat} : null,
        descriptionHelper: serialNumberFormatDescription ? serialNumberFormatDescription : '',
        rules: {
          required: true,
          pattern: {value: new RegExp(serialNumberRegex), message: 'Invalid Serial Number.'},
          validate: (value, formValues) => {
            if (!isExistingForm)
              return (
                value !== existingFanTray.serialNumber ||
                'Invalid Serial Number. Serial Number cannot be same as installed hardware.'
              );
            else return true;
          }
        }
      }
    ];
    return equipmentFormFields;
  };

  return (
    <ReplaceEquipmentBase<IFormInput>
      title={title}
      aircraftSerialNumber={''}
      formIdPrefix="replaceFanTrayForm"
      equipmentName="fanTray"
      existingEquipmentStoreKey="existingFanTray"
      newEquipmentStoreKey="fanTray"
      formConfigList={formConfigList}
      defaultFormValues={{
        partNumber: existingEquipmentData?.length ? existingEquipmentData[0]?.partNumber : SELECT_AN_OPTION,
        serialNumber: ''
      }}
      getFormFields={(initialValues, isExistingForm) => getFormFields(initialValues, isExistingForm)}
      formControlMethods={{handleSubmit, formState, control, watch, reset}}
      fetchQuery={updateFanTrayInformation}
    />
  );
};

export default FanTrayReplace;
