/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Edit Aircraft Details
 */
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {uniq, isEqual} from 'lodash';

import {useStore} from '../../store/Store';
import {useValidateSerialNumber} from '../../utils/validateAircraftHooks';

import {LoadingSpinner} from '@viasat/insights-components';
import PageContainer from './PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import {
  FormContainerLeftPanel,
  NextButton,
  HeaderHorizontalRule,
  BottomHorizontalRule,
  PageDescription,
  HeaderLabel
} from '../CommonStyles';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';

import {validateDropdownField} from '../../utils/validationUtils';
import useFetchV2 from '../../utils/useFetchV2';
import {editAircraftDetailsQuery} from '../../store/queries/editAircraftDetailsQuery';
import {AppAction} from '../../store/reducers/AppReducer';
import {AircraftInfoAction} from '../../store/reducers/AircraftInfoReducer';
import AircraftValidationModal from './AircraftValidationModal';
import {INSTALLATION_TYPES} from '../common/constants';
import {useGetEquipmentInfoV2} from '../../utils/customHooks';

const ID_PREFIX = 'editAircraftDetails';

const LeftChild: React.FC<any> = ({setAircraft}) => {
  const location = useLocation();
  const {store} = useStore();
  const {aircraftModels} = store.app;
  const aircraftInfo = store.aircraftInfo;
  const {isNewInstall} = store.aircraftInfo;
  const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';
  sessionStorage.groupCode = groupCode;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
        color: '#465967'
      }
    }
  };
  const {handleSubmit, formState, watch, control} = useForm();

  const initialValues = {
    serialNumber: aircraftInfo.serialNumber ? aircraftInfo.serialNumber : '',
    make: aircraftInfo.make ? aircraftInfo.make : 'Select an option',
    model: aircraftInfo.model ? aircraftInfo.model : 'Select an option',
    tailId: aircraftInfo.tailId ? aircraftInfo.tailId : '',
    network: aircraftInfo.network ? aircraftInfo.network : 'Select an option',
    installationType: aircraftInfo.installationType ? aircraftInfo.installationType : 'Select an option'
  };
  const makeList = uniq(aircraftModels.map(mode => mode.aircraftMake)).sort();

  const networkTypes = ['Ka', 'Ku', 'Dual Band (Ka & Ku)'];
  const selectedMake = watch('make');

  const modelsByMake = aircraftModels?.filter(aircraftModel => aircraftModel.aircraftMake === selectedMake);
  const modelList = uniq(modelsByMake.map(mode => mode.aircraftModel));
  const newAircraftFormFields: FormField[] = [
    {
      id: 'serialNumber',
      label: 'Aircraft Serial Number',
      type: FormFieldType.INPUT,
      value: initialValues.serialNumber,
      disabled: !isNewInstall,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'make',
      label: 'Make',
      type: FormFieldType.SELECT,
      value: initialValues.make,
      disabled: !isNewInstall,
      menuProps: MenuProps,
      dropDownValues: makeList,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Make')
      }
    },
    {
      id: 'model',
      label: 'Model',
      type: FormFieldType.SELECT,
      value: initialValues.model,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: modelList,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Model')
      }
    },
    {
      id: 'tailId',
      label: 'Tail ID',
      type: FormFieldType.INPUT,
      value: initialValues.tailId,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'network',
      label: 'Network',
      type: FormFieldType.SELECT,
      value: initialValues.network,
      disabled: !isNewInstall,
      menuProps: MenuProps,
      dropDownValues: networkTypes,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Network')
      }
    },
    {
      id: 'installationType',
      label: 'Installation Type',
      type: FormFieldType.SELECT,
      value: initialValues.installationType,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: INSTALLATION_TYPES,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Installation Type')
      }
    }
  ];

  const currentRoute = location.pathname;
  return (
    <>
      <Breadcrumb currentRoute={currentRoute}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--title`}>Aircraft Details</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--label`}>
        Please add or edit the aircraft details. If the model is not listed in the menu, please list the exact model.
      </PageDescription>
      <BottomHorizontalRule />
      <FormContainerLeftPanel>
        <FormComponent
          idPrefix={ID_PREFIX}
          id="edit-aircraft-details-form"
          formFields={newAircraftFormFields}
          setFormData={setAircraft}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
      </FormContainerLeftPanel>
    </>
  );
};

const ActionButtons: React.FC<any> = ({isLoading, isNewInstallation, validateAircraft, setValidationStatusModal}) => {
  return (
    <NextButton form="edit-aircraft-details-form" id={`${ID_PREFIX}--submitButton`}>
      <span className="next-button-text">{isNewInstallation ? 'Add' : 'Submit'}</span>
      {isLoading ? <LoadingSpinner size={18} id="AircraftValidationLoading" /> : null}
    </NextButton>
  );
};

const EditAircraftDetails: React.FC<any> = ({title}) => {
  const [aircraft, setAircraft] = useState<any>(null);
  const navigate = useNavigate();
  const {store, dispatch} = useStore();
  const [initialAircraftState] = useState({
    serialNumber: store.aircraftInfo?.serialNumber,
    tailId: store.aircraftInfo?.tailId,
    make: store.aircraftInfo?.make,
    model: store.aircraftInfo?.model,
    network: store.aircraftInfo?.network,
    installationType: store.aircraftInfo?.installationType
  });
  const {isNewInstall} = store.aircraftInfo;
  const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';
  const equipmentInfoTaskId = store.equipmentInfo?.taskId;
  const [showValidationStatusModal, setShowValidationStatusModal] = useState<boolean>(false);
  const [disableValidationStatusModal, setDisableValidationStatusModal] = useState<boolean>(false);
  const [allowDataReset, setAllowDataReset] = useState(false);
  const [triggerUpdateAircraftInfo, setTriggerUpdateAircraftInfo] = useState(false);
  const validateSerialNumberNavUrl = undefined;

  const aircraftInfoUpdated = useMemo(
    () => !!aircraft && !isEqual(initialAircraftState, aircraft),
    [initialAircraftState, aircraft]
  );

  // serial number updated
  const shouldReFetchAircraft = useMemo(
    () => !!aircraft && aircraft?.serialNumber !== initialAircraftState.serialNumber,
    [aircraft, initialAircraftState.serialNumber]
  );

  // reset state when serial number change
  useEffect(() => {
    setShowValidationStatusModal(false);
    setAllowDataReset(false);
    setTriggerUpdateAircraftInfo(false);
    setDisableValidationStatusModal(false);
  }, [aircraft?.serialNumber]);

  const {validatedAircraft, validatedAircraftReFetch, validatedAircraftIsFetching} = useValidateSerialNumber(
    // validate serial number only if the entered serial number is updated
    shouldReFetchAircraft ? aircraft : undefined,
    () => {}, // skip setShowValidationStatusModal action
    isNewInstall,
    validateSerialNumberNavUrl,
    allowDataReset,
    () => {
      // Trigger for updating aircraft info once data reset complete
      setTriggerUpdateAircraftInfo(true);
    }
  );
  const validatedAircraftNoAccess = validatedAircraft?.userHaveAccess === false;

  const allowSerialNumberUpdate = useMemo(() => !validatedAircraft?.isExists, [validatedAircraft]);
  useEffect(() => {
    if (validatedAircraft?.hasOwnProperty('isExists')) {
      setShowValidationStatusModal(!disableValidationStatusModal && validatedAircraft?.isExists);
    } else if (validatedAircraftNoAccess) {
      setShowValidationStatusModal(true);
    }
  }, [validatedAircraft, disableValidationStatusModal, validatedAircraftNoAccess]);

  const navToMainMenu = () => {
    navigate('/main-menu', {replace: true});
  };

  const setAlertInfo = () => {
    dispatch({
      type: AppAction.SET_ALERT_INFO,
      payload: {
        showAlert: true,
        operation: 'updated',
        message: 'Aircraft information ',
        details: 'You will receive an email upon Viasat review.'
      }
    });
  };

  const setNewAircraftInfo = () => {
    dispatch({
      type: AircraftInfoAction.SET_AIRCRAFT,
      payload: {...aircraft, taskId: editAircraftDetailsResponse?.taskId}
    });
  };

  const editAircraftQueryParams = useMemo(() => {
    // Disable edit when no aircraft info or aircraft info not updated or user don't have access for validated aircraft
    if (!aircraft || !aircraftInfoUpdated || validatedAircraftNoAccess) return;
    if (
      isNewInstall &&
      shouldReFetchAircraft &&
      (validatedAircraftIsFetching || !validatedAircraft || showValidationStatusModal)
    )
      return null;
    if (shouldReFetchAircraft && !allowSerialNumberUpdate) return null;
    return {
      ...aircraft,
      groupCode: store.customer.current.code,
      taskId: store.aircraftInfo?.taskId,
      isNewInstall: isNewInstall
    };
    // eslint-disable-next-line
  }, [
    validatedAircraftIsFetching,
    validatedAircraft,
    showValidationStatusModal,
    aircraft,
    isNewInstall,
    shouldReFetchAircraft,
    aircraftInfoUpdated
  ]);

  const {
    refetch,
    isFetching: isFetchingEditAircraft,
    data: editAircraftDetailsResponse
  } = useFetchV2(
    {
      route: editAircraftDetailsQuery.route,
      params: editAircraftQueryParams
    },
    editAircraftDetailsQuery.transform,
    undefined,
    {manual: true}
  );

  useEffect(() => {
    if (editAircraftQueryParams) refetch();
  }, [editAircraftQueryParams, refetch]);

  const editAircraftInfoUpdated = useMemo(() => {
    return editAircraftDetailsResponse && editAircraftDetailsResponse?.taskId;
  }, [editAircraftDetailsResponse]);

  // network updated but not serial number
  const shouldReFetchEquipmentInfo = useMemo(() => {
    return !!aircraft && !shouldReFetchAircraft && initialAircraftState?.network !== aircraft?.network;
  }, [aircraft, shouldReFetchAircraft, initialAircraftState?.network]);

  // should execute when edit aircraft info is updated and there is change in aircraft network type
  const {data: updatedEquipmentInfo, isFetching: equipmentInfoFetching} = useGetEquipmentInfoV2(
    editAircraftInfoUpdated && shouldReFetchEquipmentInfo,
    equipmentInfoTaskId,
    groupCode
  );

  useEffect(() => {
    if (editAircraftInfoUpdated && shouldReFetchEquipmentInfo && updatedEquipmentInfo) {
      setNewAircraftInfo();
      setAlertInfo();
      navToMainMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAircraftInfoUpdated, shouldReFetchEquipmentInfo, updatedEquipmentInfo]);

  // serial number update case
  useEffect(() => {
    if (!validatedAircraftIsFetching && shouldReFetchAircraft && editAircraftDetailsResponse?.taskId) {
      setAlertInfo();
      setDisableValidationStatusModal(true);
      setTriggerUpdateAircraftInfo(false);
      setAllowDataReset(true);
      validatedAircraftReFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editAircraftDetailsResponse?.taskId,
    shouldReFetchAircraft,
    validatedAircraftReFetch,
    validatedAircraftIsFetching
  ]);

  useEffect(() => {
    if (isFetchingEditAircraft || !editAircraftDetailsResponse) return;
    // if network change then re pull data
    if (!shouldReFetchAircraft && !shouldReFetchEquipmentInfo && editAircraftDetailsResponse?.taskId) {
      setNewAircraftInfo();
      setAlertInfo();
      navToMainMenu();
    } else {
      console.error(editAircraftDetailsResponse);
    }
    // eslint-disable-next-line
  }, [isFetchingEditAircraft, editAircraftDetailsResponse, dispatch, shouldReFetchAircraft]);

  const onSubmit = aircraft => {
    if (!!aircraft && isEqual(initialAircraftState, aircraft)) {
      navToMainMenu();
    } else setAircraft(aircraft);
  };

  const isSelectExistingAircraft = () =>
    isNewInstall && shouldReFetchAircraft && validatedAircraft?.isExists && validatedAircraft?.aircraftDetails;
  const isRequestAircraftAccess = () => validatedAircraftNoAccess;

  const isLoading = validatedAircraftIsFetching || isFetchingEditAircraft || equipmentInfoFetching;
  return (
    <>
      <AircraftValidationModal
        openModal={showValidationStatusModal}
        aircraft={
          // only show modal when there is an existing aircraft with updated serial number
          // select existing aircraft serialnumber flow only available for newInstall
          isSelectExistingAircraft() || isRequestAircraftAccess()
            ? {...validatedAircraft, serialNumber: aircraft?.serialNumber}
            : {}
        }
        setOpenModal={setShowValidationStatusModal}
        // callback function to execute when user select to continue with existing aircraft
        onResumeTask={() => {
          setShowValidationStatusModal(false);
          setAllowDataReset(true);
        }}
        triggerUpdateAircraftInfo={Boolean(triggerUpdateAircraftInfo && validatedAircraft?.aircraftDetails)}
      />
      <PageContainer
        title={title}
        leftChild={<LeftChild setAircraft={onSubmit} />}
        actionButtons={<ActionButtons isNewInstallation={isNewInstall} isLoading={isLoading} />}
      />
    </>
  );
};

export default EditAircraftDetails;
