/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Edit Review Information Modal
 */
import React from 'react';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';

import CloseIcon from '@mui/icons-material/Close';

import FormComponent, {FormField, FormFieldType} from '../../common/elements/Form';
import {NextButton, NormalButton} from '../../CommonStyles';

import PopUpModal from '../../common/Modal';
import {HEADER_ICON_BORDER_COLOR, POPUP_TEXT_COLOR} from '../../common/theme/Colors';
import {APPROVED_TXT_COLOR} from '../../../theme/Colors';
import {LoadingSpinner} from '@viasat/insights-components';

const ID_PREFIX = 'EditReviewInformationModal';

const EditReviewModalTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .closeIcon {
    width: 10%;
    right: 1px;
    position: absolute;
    margin-top: 5px;
    color: ${HEADER_ICON_BORDER_COLOR};
    cursor: pointer;
  }
`;

const EditReviewInformationModal = styled.div`
  flex-direction: column;
  padding: 5px 15px 45px 15px;
  width: 48vw;
  text-align: center;
  align-items: center;
  gap: 8px;
  font-style: normal;
  text-align: center;
  .approval-checkmark {
    font-size: 62px;
    color: ${APPROVED_TXT_COLOR};
  }
`;

const ModalTitle = styled.div`
  font-weight: 800;
  font-size: 24px;
  font-family: 'Source Sans Pro';
  display: flex;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${POPUP_TEXT_COLOR};
  margin-left: 24px;
`;

const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  form {
    margin-left: 10px;
    text-align: left;
  }
`;

const FormContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 0px 0 -15px;
  width: 99%;
`;
interface EditReviewInformationModalProps {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  setEditReviewDescription: (editReviewDescription: {description: string}) => void;
  updateReviewInfoInProgress: boolean;
}

const EditReviewInfoPopupModal: React.FC<EditReviewInformationModalProps> = ({
  openModal,
  setOpenModal,
  setEditReviewDescription,
  updateReviewInfoInProgress
}) => {

  // function to close the modal.
  const closeModal = () => {  
    setOpenModal(false);
  };
  const initialValues = {
    editNote: ''
  };

  const {handleSubmit, formState, watch, control} = useForm();

  const editReviewInfoModalFields: FormField[] = [
    {
      id: 'description',
      label: '',
      type: FormFieldType.INPUT,
      value: initialValues.editNote,
      description: {
        title: null,
        value: 'Describe the reason for making the changes'
      },
      disabled: false,
      rules: {
        required: 'Edit Note is required',
        pattern: null
      }
    }
  ];

  /**
   * method that return the header of the modal
   * @returns modal header
   */
  const EditReviewInfoHeader = () => {
    return (
      <EditReviewModalTitleContainer>
        <ModalTitle id={`${ID_PREFIX}--submitMessage`}>Add a note to your edit</ModalTitle>
        <CloseIcon className="closeIcon" onClick={closeModal} />
      </EditReviewModalTitleContainer>
    );
  };

  return (
    <div>
      <PopUpModal
        id="editReviewInfoModal"
        open={openModal}
        title={<EditReviewInfoHeader />}
        titlecolor={HEADER_ICON_BORDER_COLOR}
        borderRadius="16px"
        padding={'0px'}
      >
        <EditReviewInformationModal>
          <FormContainer>
            <FormComponent
              idPrefix={ID_PREFIX}
              id="edit-review-modal-form"
              formFields={editReviewInfoModalFields}
              setFormData={setEditReviewDescription}
              formControlMethods={{handleSubmit, formState, watch, control}}
            />
            <FormContainerActions>
              <NormalButton onClick={() => closeModal()} disabled={false}>
                <span id={`${ID_PREFIX}--cancelButton`} className="next-button-text">
                  Cancel
                </span>
              </NormalButton>
              <NextButton form="edit-review-modal-form" id={`${ID_PREFIX}--declineButton`} disabled={false}>
                <span className="next-button-text">Save</span>
                {updateReviewInfoInProgress ? <LoadingSpinner size={18} id="UpdateReviewInformationLoading" /> : null}
              </NextButton>
            </FormContainerActions>
          </FormContainer>
        </EditReviewInformationModal>
      </PopUpModal>
    </div>
  );
};

export default EditReviewInfoPopupModal;
