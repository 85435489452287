/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Equipment Information Form
 */
import React from 'react';
import {isNil} from 'lodash';

import {EquipmentType, KA_NETWORK, KU_NETWORK, TASK_TYPES} from '../../common/constants';
import FormComponent, {FormField, FormFieldType, FormLayout} from '../../common/elements/Form';

import {
  useEquipmentInformationForm,
  useModemInformationForm,
  useRouterInfomationForm
} from '../../../utils/equipmentInformationFormHook';

import {useStore} from '../../../store/Store';
import {IEquipmentInfo, ITaskData} from '../../../store/queries/getRequestInformationQuery';

import {IEquipmentDetail} from '../reviewInformationTypes';
import {
  EditReviewInformationFormContainer,
  ReviewItemSubTitle,
  ReviewItemTitle,
  ReviewSection
} from '../ReviewInformationStyles';

interface IEquipmentForm extends IEquipmentDetail {
  formId: string;
  setEquipmentFormData: (equipmentFormData: any) => void;
  formControlMethods: any;
}

function useFormFields(
  equipmentDetail: IEquipmentInfo,
  taskDetail: ITaskData,
  configItems,
  watch,
  unregister,
  varKaIpList,
  aircraftInfo
): FormField[] {
  const formFields: FormField[] = [];

  const kaAntennaForm = useEquipmentInformationForm(
    watch,
    KA_NETWORK,
    equipmentDetail.kaAntennaInfo?.new,
    EquipmentType.ANTENNA,
    configItems['ANTENNA'],
    'equipmentInfo.kaAntennaInfo.new',
    taskDetail.taskType === TASK_TYPES.REPLACE_HARDWARE ? equipmentDetail.kaAntennaInfo?.existing : null
  );

  if (kaAntennaForm && !isNil(kaAntennaForm.equipmentFormFields)) {
    formFields.push({
      id: `kaAntennaTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Ka Antenna',
      disabled: false,
      rules: {required: false, pattern: null}
    }) && formFields.push(...kaAntennaForm.equipmentFormFields);
  }

  const kuAntennaForm = useEquipmentInformationForm(
    watch,
    KU_NETWORK,
    equipmentDetail.kuAntennaInfo?.new,
    EquipmentType.ANTENNA,
    configItems['ANTENNA'],
    'equipmentInfo.kuAntennaInfo.new',
    taskDetail.taskType === TASK_TYPES.REPLACE_HARDWARE ? equipmentDetail.kuAntennaInfo?.existing : null
  );

  if (kuAntennaForm && !isNil(kuAntennaForm.equipmentFormFields)) {
    formFields.push({
      id: `kuAntennaTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Ku Antenna',
      disabled: false,
      rules: {required: false, pattern: null}
    }) && formFields.push(...kuAntennaForm.equipmentFormFields);
  }

  const kaModemForm = useModemInformationForm(
    watch,
    KA_NETWORK,
    equipmentDetail.kaModemInfo?.new,
    EquipmentType.MODEM,
    configItems['Modem'],
    varKaIpList,
    aircraftInfo,
    'equipmentInfo.kaModemInfo.new',
    taskDetail.taskType === TASK_TYPES.REPLACE_HARDWARE ? equipmentDetail.kaModemInfo?.existing : null
  );

  if (kaModemForm && !isNil(kaModemForm.modemInfoFormFields)) {
    formFields.push({
      id: `kaModemTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Ka Modem',
      disabled: false,
      rules: {required: false, pattern: null}
    }) && formFields.push(...kaModemForm.modemInfoFormFields);
  }

  const kuModemForm = useModemInformationForm(
    watch,
    KU_NETWORK,
    equipmentDetail.kuModemInfo?.new,
    EquipmentType.MODEM,
    configItems['Modem'],
    undefined,
    undefined,
    'equipmentInfo.kuModemInfo.new',
    taskDetail.taskType === TASK_TYPES.REPLACE_HARDWARE ? equipmentDetail.kuModemInfo?.existing : null
  );

  if (kuModemForm && !isNil(kuModemForm.modemInfoFormFields)) {
    formFields.push({
      id: `kuModemTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Ku Modem',
      disabled: false,
      rules: {required: false, pattern: null}
    }) && formFields.push(...kuModemForm.modemInfoFormFields);
  }

  const apsuForm = useEquipmentInformationForm(
    watch,
    KA_NETWORK,
    equipmentDetail.apsuInfo?.new,
    EquipmentType.APSU,
    configItems['POWER SUPPLY'],
    'equipmentInfo.apsuInfo.new',
    taskDetail.taskType === TASK_TYPES.REPLACE_HARDWARE ? equipmentDetail.apsuInfo?.existing : null
  );

  if (apsuForm && !isNil(apsuForm)) {
    formFields.push({
      id: `apsuTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'APSU',
      disabled: false,
      rules: {required: false, pattern: null}
    }) && formFields.push(...apsuForm.equipmentFormFields);
  }

  const acuForm = useEquipmentInformationForm(
    watch,
    KU_NETWORK,
    equipmentDetail.acuInfo?.new,
    EquipmentType.ACU,
    configItems['ACU'],
    'equipmentInfo.acuInfo.new',
    taskDetail.taskType === TASK_TYPES.REPLACE_HARDWARE ? equipmentDetail.acuInfo?.existing : null
  );

  if (acuForm && !isNil(acuForm)) {
    formFields.push({
      id: `acuTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'ACU',
      disabled: false,
      rules: {required: false, pattern: null}
    }) && formFields.push(...acuForm.equipmentFormFields);
  }

  const fanTrayForm = useEquipmentInformationForm(
    watch,
    KA_NETWORK,
    equipmentDetail.fanTrayInfo?.new,
    EquipmentType.FAN_TRAY,
    configItems['ANTENNA'],
    'equipmentInfo.fanTrayInfo.new',
    taskDetail.taskType === TASK_TYPES.REPLACE_HARDWARE ? equipmentDetail.fanTrayInfo?.existing : null
  );

  if (fanTrayForm && !isNil(fanTrayForm?.equipmentFormFields)) {
    formFields.push({
      id: `fanTrayTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Fan Tray',
      disabled: false,
      rules: {required: false, pattern: null}
    });
    formFields.push(...fanTrayForm.equipmentFormFields);
  }

  const routerForm = useRouterInfomationForm(
    watch,
    unregister,
    equipmentDetail.routerInfo?.new,
    'equipmentInfo.routerInfo.new',
    taskDetail.taskType === TASK_TYPES.REPLACE_HARDWARE ? equipmentDetail.routerInfo?.existing : null
  );
  if (routerForm && !isNil(routerForm)) {
    formFields.push({
      id: `routerTitle`,
      label: '',
      type: FormFieldType.GROUP_TITLE,
      value: 'Router',
      disabled: false,
      rules: {required: false, pattern: null}
    });
    formFields.push(...routerForm);
  }

  return formFields;
}

const EquipmentInformationForm: React.FC<IEquipmentForm> = ({
  idPrefix,
  taskDetail,
  equipmentDetail,
  formId,
  setEquipmentFormData,
  formControlMethods
}) => {
  const {watch, unregister} = formControlMethods;

  const {store} = useStore();
  const configItems = store.app.configItems;
  const {varKaIpList} = store.app;
  const {aircraftInfo} = store;

  const equipmentFormFields: FormField[] = useFormFields(equipmentDetail, taskDetail, configItems, watch, unregister, varKaIpList, aircraftInfo);

  return (
    <ReviewSection>
      <ReviewItemTitle id={`${idPrefix}__equipmentInfomration_title`}>Equipment Information</ReviewItemTitle>
      <ReviewItemSubTitle id={`${idPrefix}__equipmentInfomration_subtitle`}>
        Please review the information below for approval.
      </ReviewItemSubTitle>
      <EditReviewInformationFormContainer>
        <FormComponent
          idPrefix={idPrefix}
          id={formId}
          formFields={equipmentFormFields}
          setFormData={setEquipmentFormData}
          formControlMethods={formControlMethods}
          layout={FormLayout.HORIZONTAL}
        ></FormComponent>
      </EditReviewInformationFormContainer>
    </ReviewSection>
  );
};

export default EquipmentInformationForm;
