/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Breadcrumb Component
 */
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';
import {BREADCRUMB_ROUTE_MAP} from './constants';
import {BREADCRUMB_TEXT_COLOR, BREADCRUMB_CURRENT_ROUTE_COLOR} from './theme/Colors';
import {useStore} from '../../store/Store';
import {AppAction} from '../../store/reducers/AppReducer';

interface BreadcrumbProps {
  currentRoute: string;
}

const Breadcrumb = ({currentRoute}: BreadcrumbProps) => {
  const navigate = useNavigate();
  const {store, dispatch} = useStore();
  const {breadcrumb} = store.app;
  const [breadcrumbData, setBreadcrumbData] = useState(breadcrumb);
  const breadcrumbTextStyle = {
    fontFamily: 'Source Sans Pro',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'capitalize',
    color: BREADCRUMB_TEXT_COLOR,
    cursor: 'pointer'
  };

  const currentRouteStyle = {
    fontFamily: 'Source Sans Pro',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'capitalize',
    color: BREADCRUMB_CURRENT_ROUTE_COLOR
  };

  const handleHomeClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    navigate('/main-menu');
  };

  const handleLinkClick = (event, routePath) => {
    event.preventDefault();
    const breadcrumbData = [...breadcrumb];
    breadcrumbData.length = breadcrumbData.findIndex(breadcrumb => breadcrumb.linkPath === routePath);
    setBreadcrumbData(breadcrumbData);
    dispatch({type: AppAction.SET_BREADCRUMB, payload: breadcrumbData});
    navigate(routePath);
  };

  useEffect(() => {
    const newLinkData = {linkPath: currentRoute, linkText: BREADCRUMB_ROUTE_MAP[currentRoute.slice(1)]};
    if (!breadcrumb.some(breadCrumbItem => breadCrumbItem.linkText === newLinkData.linkText)) {
      setBreadcrumbData([...breadcrumb, newLinkData]);
      dispatch({type: AppAction.SET_BREADCRUMB, payload: [...breadcrumb, newLinkData]});
    }
    //eslint-disable-next-line
  }, [currentRoute, breadcrumb]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" title="crumb-home" onClick={handleHomeClick}>
      <HomeIcon sx={{mr: 0.5}} fontSize="inherit" />
    </Link>
  ];
  breadcrumbData.forEach((linkRouteData, index) => {
    let last = false;
    if (breadcrumbData.length === index + 1) {
      last = true;
    }
    if (last) {
      breadcrumbs.push(
        <Typography key={index + 2} color="text.primary" sx={currentRouteStyle} title="current-crumb">
          {linkRouteData.linkText}
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          sx={breadcrumbTextStyle}
          underline="hover"
          key={index + 2}
          color="inherit"
          title="previous-crumb"
          onClick={e => handleLinkClick(e, linkRouteData.linkPath)}
        >
          {linkRouteData.linkText}
        </Link>
      );
    }
  });

  return (
    <Stack spacing={2} sx={{paddingBottom: '24px'}}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default Breadcrumb;
