/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Approval Requests utils
 */
import {DefaultCellRendererV2, CellRenderer} from '@viasat/insights-components';
import {ListGridLinkedDataColumn} from '../../../utils/listUtils';
import {
  LinkCellRenderer,
  convertFilterValues,
  getOrganizationFilterRangeOptions,
  getSerialNumberFilterRangeOptions,
  getTailIdFilterRangeOptions
} from '../listsUtil';
import StatusComponent from '../../common/elements/StatusContainer';
import {FilterOptionsType, FilterType} from '../../../utils/filterUtils';

const DB_COLUMN_MAPPING = {
  make: 'make',
  serialNumber: 'serialNumber',
  tailId: 'tailId',
  taskTitle: 'taskTitle',
  status: 'status',
  submittedBy: 'submittedBy',
  updatedTstamp: 'updatedTstamp',
  updatedBy: 'updatedBy',
  submittedTstamp: 'submittedTstamp'
};

export enum FilterId {
  make = 'make',
  serialNumber = 'serialNumber',
  tailId = 'tailId',
  taskTitle = 'taskTitle',
  status = 'status',
  submittedBy = 'submittedBy',
  updatedTstamp = 'updatedTstamp',
  updatedBy = 'updatedBy',
  submittedTstamp = 'submittedTstamp'
}

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getServiceStatusFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertFilterValues(['New', 'Info Required', 'Approved', 'Declined']);

export const APPROVAL_LIST_FILTERS: Array<FilterType> = [
  {
    title: 'Organization',
    id: FilterId.make,
    getValues: getOrganizationFilterRangeOptions
  },
  {
    title: 'Status',
    id: FilterId.status,
    getValues: getServiceStatusFilterRangeOptions
  },
  {
    title: 'Serial Number',
    id: FilterId.serialNumber,
    getValues: getSerialNumberFilterRangeOptions
  },
  {
    title: 'Tail ID',
    id: FilterId.tailId,
    getValues: getTailIdFilterRangeOptions
  }
];

/**
 * Renderer function for the status column
 * @param cellIdBase The ID of the table cell of the status column
 * @param cellData The status value
 */
const StatusCellRenderer: CellRenderer = ({cellIdBase, cellData}) => {
  return <StatusComponent idPrefix={cellIdBase} status={cellData} />;
};
// Approval List Hidden Columns mappings for encoding/decoding sharelink
// Required: Enum name should match columnId name, case-sensitive
export enum ApprovalListColumnId {
  serialNumber,
  tailId
}

/**
 * Get the Name of a Column Id Enum Key
 * @param columnIdEnum key to convert to name
 * @returns name of enum as string, otherwise UNKNOWN_COLUMN_ID_NAME
 */
export const getColumnIdEnumName = (enumType: any, columnIdEnum: any): string =>
  columnIdEnum.hasOwnProperty(enumType) ? columnIdEnum[enumType] : 'UNKNOWN_COLUMN_ID_NAME';

/**
 * Get Approval List ColumnId Name
 * @param columnId columnId enum to convert to name
 * @returns name of columnId as string
 */
export const getColumnIdName = (columnId: ApprovalListColumnId) => getColumnIdEnumName(columnId, ApprovalListColumnId);

// Query Field Constants
export const QUERY_FIELD_MAPPING = {
  make: 'make',
  serialNumber: 'serialNumber',
  tailId: 'tailId',
  taskTitle: 'taskTitle',
  status: 'status',
  submittedBy: 'submittedBy',
  updatedTstamp: 'updatedTstamp',
  updatedBy: 'updatedBy',
  submittedTstamp: 'submittedTstamp'
};

/**
 * Get Approval List Query Field Mapping
 * @param columnId columnId enum to convert to query field
 * @param append append string to columnId name (default='')
 * @returns query field as string, otherwise undefined
 */
export const getQueryFieldMapping = (columnId: ApprovalListColumnId, append: string = ''): string | undefined => {
  const columnIdName = getColumnIdName(columnId) + append;
  return columnIdName ? QUERY_FIELD_MAPPING[columnIdName] : undefined;
};

/**
 * Returns a list of columns that should be included based on the given
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildApprovalsListColumns = (handleApprovalItemClick): ListGridLinkedDataColumn[] => {
  // Column definitions for Approval list grid
  const approvalsListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'make',
      dataKey: 'make',
      showHideGroup: '__groupless',
      title: 'Organization',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 240,
      sortable: true,
      frozen: false
    },
    {
      key: 'serialNumber',
      dataKey: 'serialNumber',
      showHideGroup: '__groupless',
      title: 'Aircraft SN',
      getColumnJsx: LinkCellRenderer,
      handleClick: handleApprovalItemClick,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'tailId',
      dataKey: 'tailId',
      showHideGroup: '__groupless',
      title: 'Tail ID',
      getColumnJsx: LinkCellRenderer,
      handleClick: handleApprovalItemClick,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'taskTitle',
      dataKey: 'taskTitle',
      showHideGroup: '__groupless',
      title: 'Task',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 290,
      sortable: true,
      frozen: false
    },
    {
      key: 'status',
      dataKey: 'status',
      showHideGroup: '__groupless',
      title: 'Status',
      getColumnJsx: StatusCellRenderer,
      width: 290,
      sortable: true,
      frozen: false
    },
    {
      key: 'submittedTstamp',
      dataKey: 'submittedTstamp',
      showHideGroup: '__groupless',
      title: 'Submitted Date',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 200,
      sortable: true,
      frozen: false
    },
    {
      key: 'submittedBy',
      dataKey: 'submittedBy',
      showHideGroup: '__groupless',
      title: 'Submitted By',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 300,
      sortable: true,
      frozen: false
    },
    {
      key: 'updatedTstamp',
      dataKey: 'updatedTstamp',
      showHideGroup: '__groupless',
      title: 'Last Update',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 200,
      sortable: true,
      frozen: false
    },
    {
      key: 'updatedBy',
      dataKey: 'updatedBy',
      showHideGroup: '__groupless',
      title: 'Updated By',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 230,
      sortable: true,
      frozen: false
    }
  ];

  return approvalsListColumns;
};

/**
 * Mapping and return the list view column with db column
 */
export const fetchDBColumnKey = () => {
  const columnKeys = [];
  const approvalListColumns = buildApprovalsListColumns(null);
  for (const index in approvalListColumns) {
    columnKeys.push(DB_COLUMN_MAPPING[approvalListColumns[index]['key']]);
  }
  return columnKeys;
};
