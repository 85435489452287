/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add New Aircraft Type in Lookup Table
 *
 */
import React, {useMemo, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {AddForm, AddFormHeader, DrawerButton, ButtonContainer, FormContainer} from '../LookupTableStyles';
import {addAircraftTypeQuery, updateAircraftTypeQuery} from '../../../../store/queries/lookupQueries';
import {SideDrawerProps, togglePanel, handleBackdropClick, MenuProps, InputProps} from '../lookupTableUtils';
import {useStore} from '../../../../store/Store';
import {AppAction} from '../../../../store/reducers/AppReducer';
import {LookupsListAction} from '../../../../store/reducers/LookupsReducer';
import FormComponent, {FormField, FormFieldType} from '../../../common/elements/Form';
import {validateDropdownField} from '../../../../utils/validationUtils';
import {MANUFACTURERS, NETWORK_OPTIONS, YES_NO} from '../../../common/constants';
import useFetchV2 from '../../../../utils/useFetchV2';

const ID_PREFIX = 'aircraftModel';

interface IFormInput {
  aircraftMake: string;
  aircraftModel: string;
  stcAvailable: string;
  networkSupport: string[];
  notes: string;
}

const AddAircraftType: React.FC<SideDrawerProps> = ({openPanel, setOpenPanel, panelData, isUpdate}) => {
  const {dispatch} = useStore();
  const [newAircraftTypeInfo, setNewAircraftTypeInfo] = useState<any>(null);
  const [updateAircraftTypeInfo, setUpdateAircraftTypeInfo] = useState<any>(null);
  let initialValues: IFormInput = {
    aircraftMake: '',
    aircraftModel: '',
    stcAvailable: '',
    networkSupport: [],
    notes: ''
  };
  if (isUpdate) initialValues = panelData;

  const aircraftModelFormFields: FormField[] = [
    {
      id: 'aircraftMake',
      label: 'Make',
      type: FormFieldType.SELECT,
      value: initialValues.aircraftMake,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: MANUFACTURERS,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Aircraft Make')
      }
    },
    {
      id: 'aircraftModel',
      label: 'Model',
      type: FormFieldType.INPUT,
      value: initialValues.aircraftModel,
      disabled: false,
      rules: {
        required: true,
        pattern: null
      }
    },
    {
      id: 'networkSupport',
      label: 'Network',
      type: FormFieldType.MULTISELECT,
      value: initialValues.networkSupport,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: NETWORK_OPTIONS,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'Network')
      }
    },
    {
      id: 'stcAvailable',
      label: 'STC Available',
      type: FormFieldType.SELECT,
      value: initialValues.stcAvailable,
      disabled: false,
      menuProps: MenuProps,
      dropDownValues: YES_NO,
      description: null,
      rules: {
        required: true,
        pattern: null,
        validate: (value, formValues) => validateDropdownField(value, 'STC Available')
      }
    },
    {
      id: 'notes',
      label: 'Notes',
      type: FormFieldType.TEXTFIELD,
      value: initialValues.notes,
      disabled: false,
      inputProps: InputProps,
      isMultiline: true,
      rules: {
        required: false,
        pattern: null
      }
    }
  ];
  const {handleSubmit, formState, unregister, reset, watch, control} = useForm();

  const addQueryParams = useMemo(() => {
    if (newAircraftTypeInfo && (!newAircraftTypeInfo.aircraftMake || !newAircraftTypeInfo.aircraftModel)) return;
    return newAircraftTypeInfo;
  }, [newAircraftTypeInfo]);

  const updateQueryParams = useMemo(() => {
    if (
      isUpdate &&
      updateAircraftTypeInfo &&
      (!updateAircraftTypeInfo.aircraftMake || !updateAircraftTypeInfo.aircraftModel)
    )
      return;
    return updateAircraftTypeInfo;
  }, [updateAircraftTypeInfo, isUpdate]);

  const {isLoading: addInProgress, data: addNewAircraftTypeInfo} = useFetchV2(
    {route: addAircraftTypeQuery.route, params: addQueryParams},
    addAircraftTypeQuery.transform
  );
  const {isLoading: updateInProgress, data: updatedAircraftTypeInfo} = useFetchV2(
    {route: updateAircraftTypeQuery(panelData?.id).route, params: updateQueryParams},
    updateAircraftTypeQuery(panelData?.id).transform
  );

  const onCancelClick = () => {
    unregister();
    reset(
      {aircraftMake: '', aircraftModel: '', stcAvailable: '', networkSupport: [], notes: ''},
      {
        keepValues: false
      }
    );
    setOpenPanel(false);
  };

  const handleOpen = () => {
    togglePanel(true);
    setOpenPanel(true);
  };

  const handleClose = () => {
    if (localStorage.getItem('backdropClick') === 'true') {
      localStorage.setItem('backdropClick', 'false');
      return;
    }
    togglePanel(false);
    setOpenPanel(false);
  };

  useEffect(() => {
    if (addInProgress) return;
    if (addNewAircraftTypeInfo) {
      setOpenPanel(false);
      dispatch({
        type: LookupsListAction.SET_NEW_ROW_COUNT,
        payload: 1
      });
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'New aircraft type entry added.'}
      });
    }
    //eslint-disable-next-line
  }, [addInProgress, addNewAircraftTypeInfo]);

  useEffect(() => {
    if (updateInProgress) return;
    if (updatedAircraftTypeInfo) {
      setOpenPanel(false);
      dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: panelData});
      dispatch({
        type: LookupsListAction.REFRESH_LOOKUP_LIST,
        payload: true
      });
      dispatch({
        type: AppAction.SET_SNACK_BAR,
        payload: {openSnack: true, snackMessage: 'Aircraft Type data updated.'}
      });
    }
    //eslint-disable-next-line
  }, [updateInProgress, updatedAircraftTypeInfo]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={openPanel}
        onOpen={handleOpen}
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
      >
        <AddForm>
          <AddFormHeader id={`${ID_PREFIX}--aircraftModelFormTitle`}>ADD NEW AIRCRAFT MODEL</AddFormHeader>
          <FormContainer>
            <FormComponent
              id="add-aircraft-model-form"
              idPrefix="add-aircraft-model-form"
              formFields={aircraftModelFormFields}
              setFormData={!isUpdate ? setNewAircraftTypeInfo : setUpdateAircraftTypeInfo}
              formControlMethods={{handleSubmit, formState, watch, control}}
            ></FormComponent>
          </FormContainer>
          <ButtonContainer>
            <DrawerButton id={`${ID_PREFIX}-cancel-button`} className="cancel-button" onClick={onCancelClick}>
              <span className="button-text">Cancel</span>
            </DrawerButton>
            <DrawerButton
              form="add-aircraft-model-form"
              id={`${ID_PREFIX}-add-button`}
              className="add-button"
              onClick={() => null}
            >
              <span className="button-text">{!isUpdate ? 'Add' : 'Update'}</span>
            </DrawerButton>
          </ButtonContainer>
        </AddForm>
      </SwipeableDrawer>
    </>
  );
};

export default AddAircraftType;
