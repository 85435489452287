/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Hooke to manage sequences
 */
import {useState} from 'react';

interface SequenceInterface<T> {
  activeSequenceIdx: number;
  nextSequence: () => void;
  previousSequence: () => void;
  getCurrentSequence: () => T;
}

/**
 * hook to manage sequences
 *
 * @param {T[]} sequenceList - list data
 * @param {number} defaultIndex - default sequence index
 * @returns {SequenceInterface<T>} object to manage sequence.
 * @returns {number} object.activeSequenceIdx
 * @returns {function} object.nextSequence
 * @returns {function} object.previousSequence
 * @returns {function} object.getCurrentSequence
 */
function useSequence<T>(
  sequenceList: T[],
  defaultIndex: number
): SequenceInterface<T> {
  const [activeSequenceIdx, setActiveSequenceIdx] = useState(defaultIndex);

  const nextSequence = (): void => {
    if (activeSequenceIdx < sequenceList.length - 1) setActiveSequenceIdx(activeSequenceIdx + 1);
  };
  const previousSequence = (): void => {
    if (activeSequenceIdx) setActiveSequenceIdx(activeSequenceIdx - 1);
  };
  const getCurrentSequence = (): T => {
    return sequenceList[activeSequenceIdx];
  };

  return {activeSequenceIdx, nextSequence, previousSequence, getCurrentSequence};
}

export default useSequence; 
