/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Suspend Service Plan
 */
import React, {useEffect, useState, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {isNil} from 'lodash';
import InfoIcon from '@mui/icons-material/Info';

import {
  BottomDescription,
  BottomHorizontalRule,
  FormContainerLeftPanel,
  HeaderHorizontalRule,
  HeaderLabel,
  NextButton,
  PageDescription
} from '../CommonStyles';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import {validateDropdownField} from '../../utils/validationUtils';
import {useStore} from '../../store/Store';
import {AppAction} from '../../store/reducers/AppReducer';
import {useCreateApprovalRequestOnServicePlanSubmit} from '../../utils/customHooks';
import {suspendServicePlanQuery} from '../../store/queries/servicePlanInfoQueries';
import useFetchV2 from '../../utils/useFetchV2';

const ID_PREFIX = 'servicePlan-suspend';

interface SuspendServicePlanProps {
  title: string;
}

interface IFormInput {
  suspensionType: string;
  suspensionStartData?: string;
  suspensionEndData?: string;
}

const SuspendServicePlan: React.FC<SuspendServicePlanProps> = ({title}) => {
  const LeftChild = () => {
    const navigate = useNavigate();
    const {store, dispatch} = useStore();
    const taskId = store.servicePlan?.suspendServicePlan?.taskId;
    const {serialNumber, tailId, make, model, network} = store.aircraftInfo;
    const {breadcrumb} = store.app;
    const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';
    const {handleSubmit, formState, watch, control} = useForm();
    const location = useLocation();
    const [suspendServiceFormData, setSuspendServiceFormData] = useState(null);

    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: 224,
          width: 250,
          color: '#465967'
        }
      }
    };
    let initialValues: IFormInput = {
      suspensionType: 'Select an option',
      suspensionEndData: null,
      suspensionStartData: null
    };

    const queryParams = useMemo(() => {
      const requestType = suspendServiceFormData?.suspensionType;
      if (isNil(requestType) || isNil(tailId) || isNil(serialNumber)) return;
      return {
        groupCode,
        requestType,
        taskId,
        serialNumber,
        tailId,
        make,
        model,
        network,
        suspensionType: suspendServiceFormData?.suspensionType,
        suspendDate: suspendServiceFormData?.suspendDate,
        ...(suspendServiceFormData?.resumeDate !== '' && {resumeDate: suspendServiceFormData?.resumeDate})
      };
      //eslint-disable-next-line
    }, [suspendServiceFormData, groupCode]);

    const {isLoading: inProgress, data: servicePlanInfo} = useFetchV2(
      {route: suspendServicePlanQuery.route, params: queryParams},
      suspendServicePlanQuery.transform
    );

    const {isSubmitRequestLoading, submitResponse} = useCreateApprovalRequestOnServicePlanSubmit(
      servicePlanInfo?.taskId,
      false
    );

    useEffect(() => {
      if (inProgress || isNil(servicePlanInfo) || isSubmitRequestLoading) return;
      const operation = null;
      const message = 'We have received your request.';
      const details = 'You will receive an email upon Viasat review.';
      dispatch({
        type: AppAction.SET_BREADCRUMB,
        payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
      });
      dispatch({
        type: AppAction.SET_ALERT_INFO,
        payload: {
          showAlert: true,
          operation: operation,
          message: message,
          details: details
        }
      });
      navigate('/main-menu');
      //eslint-disable-next-line
    }, [dispatch, inProgress, servicePlanInfo, isSubmitRequestLoading, submitResponse]);

    const [suspensionStartDate, setSuspensionStartDate] = useState(null);
    const [suspensionEndDate, setSuspensionEndDate] = useState(null);

    const suspensionStartDateFormatted =
      suspensionStartDate && suspensionStartDate.c
        ? suspensionStartDate.c.month + '/' + suspensionStartDate.c.day + '/' + suspensionStartDate.c.year
        : initialValues.suspensionStartData;
    const suspensionEndDateFormatted =
      suspensionEndDate && suspensionEndDate.c
        ? suspensionEndDate.c.month + '/' + suspensionEndDate.c.day + '/' + suspensionEndDate.c.year
        : initialValues.suspensionEndData;

    const suspensionTypes = ['Suspend Monthly Service', 'Suspend Service for Maintenance'];

    const suspendServicePlanInfoFormFields: FormField[] = [
      {
        id: 'suspensionType',
        label: 'Suspension Type',
        type: FormFieldType.SELECT,
        value: initialValues.suspensionType,
        disabled: false,
        menuProps: MenuProps,
        dropDownValues: suspensionTypes,
        description: null,
        rules: {
          required: true,
          pattern: null,
          validate: (value, formValues) => validateDropdownField(value, 'Suspension Type')
        }
      },
      {
        id: 'suspendDate',
        label: 'Suspension',
        type: FormFieldType.DATE,
        value: suspensionStartDateFormatted,
        setDate: setSuspensionStartDate,
        disabled: false,
        menuProps: MenuProps,
        placeholder: 'Start Date',
        description: null,
        rules: {
          required: true,
          pattern: null
        }
      },
      {
        id: 'resumeDate',
        label: '',
        type: FormFieldType.DATE,
        value: suspensionEndDateFormatted,
        setDate: setSuspensionEndDate,
        disabled: false,
        menuProps: MenuProps,
        placeholder: 'Estimated End Date (Optional)',
        description: null,
        rules: {
          required: false,
          pattern: null
        }
      }
    ];

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Suspend Service</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--pageDescription`}>
          To suspend service select the type of suspension and enter the preferred start and end dates. Suspending
          service may take up to 2 working days.
        </PageDescription>
        <BottomHorizontalRule />
        <FormContainerLeftPanel>
          <FormComponent
            idPrefix={ID_PREFIX}
            id="suspend-service-form"
            formFields={suspendServicePlanInfoFormFields}
            setFormData={setSuspendServiceFormData}
            formControlMethods={{handleSubmit, formState, watch, control}}
          ></FormComponent>
        </FormContainerLeftPanel>
        <BottomDescription>
          <InfoIcon className="info-icon" />
          Suspending service may take up to 2 working days.
        </BottomDescription>
      </>
    );
  };

  const ActionButtons = () => {
    return (
      <NextButton form="suspend-service-form" id={`${ID_PREFIX}--nextButton`} disabled={false}>
        <span className="next-button-text">Submit</span>
      </NextButton>
    );
  };
  return <PageContainer title={title} leftChild={<LeftChild />} actionButtons={<ActionButtons />} />;
};

export default SuspendServicePlan;
