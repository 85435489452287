/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Form Component
 */

import {get, isArray} from 'lodash';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {
  Control,
  Controller,
  FieldValues,
  FormState,
  UseFormHandleSubmit,
  UseFormWatch,
  UseFormReset,
  ValidationRule
} from 'react-hook-form';
import {Box, Checkbox, FormControlLabel, MenuItem, OutlinedInput, Select, TextField, useTheme} from '@mui/material';
import {DataToolTip} from '@viasat/insights-components';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {WarningOutlined} from '@mui/icons-material';
import {
  BottomHorizontalRule,
  FieldDescription,
  Form,
  FormError,
  GroupTitle,
  MandatoryIndicator,
  StyledInput,
  TextFieldLabels,
  StyledRadioCheckbox,
  RadioButtonLabel,
  Visibility,
  VisibleCondition,
  FormFieldContainer,
  FormFieldInputContainer
} from '../../CommonStyles';
import {
  BORDER_COLOR,
  FORM_COLOR,
  FORM_ERROR_COLOR,
  LABEL_COLOR,
  NEXT_BUTTON_BORDER,
  LIGHT_GREY,
  DISABLED_SAVE_PDF_BTN_COLOR
} from '../../../theme/Colors';
import {SELECT_AN_OPTION} from '../constants';
import {buildId} from '../../../utils/buildId';
import AutoCompleteInput from './AutoCompleteInput';

export enum FormFieldType {
  SELECT = 'select',
  INPUT = 'input',
  MULTISELECT = 'multi-select',
  TEXTFIELD = 'text',
  GROUP_TITLE = 'group-title',
  BOTTOM_HORIZONTAL_RULE = 'bottom-line',
  CHECK_BOX = 'check-box',
  DATE = 'date',
  RADIO = 'radio',
  AUTO_COMPLETE = 'auto-complete'
}

export enum FormLayout {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL'
}

const calendarStyle = {
  fontSize: '16px',
  fontFamily: 'Source Sans Pro',
  background: `${NEXT_BUTTON_BORDER}`,
  borderRadius: '4px',
  color: `${LABEL_COLOR}`,
  zIndex: '1000',
  width: '92%'
};

export type FormControlMethods = {
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  formState: FormState<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  control: Control<FieldValues, any>;
  reset?: UseFormReset<FieldValues>;
};

interface FormFieldRule {
  value: number;
  message: string;
}

export interface FormField {
  id: string;
  label: string;
  type: FormFieldType;
  value: string | string[];
  disabled: boolean;
  placeholder?: string;
  description?: {title: string; value: string};
  rules: {
    required: boolean | string;
    pattern: ValidationRule<RegExp>;
    validate?: any;
    warning?: boolean;
    maxLength?: number | FormFieldRule;
    minLength?: number | FormFieldRule;
    upperCaseInput?: boolean;
  };
  //select field props
  menuProps?: any;
  dropDownValues?: string[];
  isMultiline?: boolean;
  isMultiSelect?: boolean;
  inputProps?: any;
  descriptionHelper?: string;
  radioOptions?: any;
  visibilityMessage?: string;
  setDate?: any;
  fullWidth?: boolean;
  helperText?: string;
}

export interface FormDetails {
  id: string;
  idPrefix?: string;
  setFormData: (data: any) => void;
  formFields: FormField[];
  defaultValues?: any;
  formControlMethods: FormControlMethods;
  layout?: FormLayout;
  count?: number;
}

const FormFieldComponent: React.FC<{
  formField: FormField;
  onChange: (...event: any[]) => void;
  errors;
  idPrefix: string;
  setDate?: any;
  layout: FormLayout;
}> = ({formField, onChange, errors, idPrefix, setDate, layout}) => {
  const theme = useTheme();
  switch (formField.type) {
    case FormFieldType.GROUP_TITLE:
      return (
        <GroupTitle id={formField.id} layout={layout}>
          {formField.value}
        </GroupTitle>
      );
    case FormFieldType.BOTTOM_HORIZONTAL_RULE:
      return <BottomHorizontalRule />;
    case FormFieldType.CHECK_BOX:
      return (
        <FormControlLabel
          style={{paddingTop: 20}}
          control={<Checkbox onChange={e => onChange(e.target.checked)} />}
          id={formField.id}
          label={formField.value}
        />
      );
    case FormFieldType.DATE:
      return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            className="datePicker-input"
            value={formField.value}
            onChange={updatedStartDate => {
              setDate(updatedStartDate);
              onChange(updatedStartDate);
            }}
            disabled={formField.disabled}
            renderInput={params => (
              <TextField
                className="datePicker-input"
                disabled={formField.disabled}
                sx={calendarStyle}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: formField?.placeholder
                }}
                fullWidth
                id={formField.id}
              />
            )}
          />
        </LocalizationProvider>
      );
    case FormFieldType.INPUT:
      return (
        <StyledInput
          id={`${idPrefix}--${formField.id}Field`}
          key={`${formField.id}-inputField`}
          defaultValue={formField.value}
          name={formField.id}
          onChange={e => onChange(e.target.value)}
          disabled={formField.disabled}
          placeholder={formField?.placeholder}
          style={{
            textTransform: formField.rules.upperCaseInput ? 'uppercase' : 'unset',
            borderColor: get(errors, formField.id) || formField.rules.warning ? FORM_ERROR_COLOR : BORDER_COLOR
          }}
        />
      );
    case FormFieldType.AUTO_COMPLETE:
      return (
        <AutoCompleteInput
          id={`${idPrefix}--${formField.id}Field`}
          formField={formField}
          onChange={onChange}
          errors={errors}
          placeholder={formField.placeholder}
        />
      );

    case FormFieldType.TEXTFIELD:
      return (
        <TextField
          id={`${idPrefix}--${formField.id}Field`}
          inputProps={formField.inputProps}
          multiline={formField.isMultiline}
          rows={formField.isMultiline ? 4 : 1}
          key={`${formField.id}-inputField`}
          defaultValue={formField.value}
          name={formField.id}
          onChange={e => onChange(e.target.value)}
          disabled={formField.disabled}
          style={{borderColor: get(errors, formField.id) ? FORM_ERROR_COLOR : BORDER_COLOR}}
          error={!!get(errors, formField.id)}
          fullWidth={formField.fullWidth}
          placeholder={formField.placeholder}
          helperText={
            formField?.helperText && (
              <Box component="span" sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                <span>{formField?.helperText}</span>
              </Box>
            )
          }
        />
      );
    case FormFieldType.SELECT:
      return (
        <Select
          id={`${idPrefix}--${formField.id}FieldDropdown`}
          key={`${formField.id}-selectField`}
          className="dropdown-menu"
          name={formField.id}
          disabled={formField.disabled}
          defaultValue={formField.value}
          onChange={e => onChange(e.target.value)}
          input={<OutlinedInput key={`${formField.id}-selectField-outlinedInput`} />}
          renderValue={selected => {
            return selected;
          }}
          MenuProps={formField.menuProps}
          inputProps={{'aria-label': 'Without label'}}
          style={{border: get(errors, formField.id) ? `1px solid ${FORM_ERROR_COLOR}` : `1px solid ${BORDER_COLOR}`}}
        >
          <MenuItem value={SELECT_AN_OPTION}>{SELECT_AN_OPTION}</MenuItem>
          {formField.dropDownValues.map((dropDownValue, idx) => (
            <MenuItem
              id={buildId(idPrefix, formField.id, dropDownValue)}
              key={`${formField.id}-selectField-${dropDownValue}-${idx}`}
              value={dropDownValue}
              style={{
                fontWeight:
                  formField.value === dropDownValue
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium
              }}
            >
              {dropDownValue}
            </MenuItem>
          ))}
        </Select>
      );
    case FormFieldType.MULTISELECT:
      return (
        <Select
          multiple
          id={`${idPrefix}--${formField.id}FieldDropdown`}
          key={`${formField.id}-selectField`}
          className="dropdown-menu"
          name={formField.id}
          disabled={formField.disabled}
          defaultValue={formField.value}
          onChange={e => onChange(e.target.value)}
          input={<OutlinedInput key={`${formField.id}-selectField-outlinedInput`} />}
          renderValue={selected => {
            if (isArray(selected)) {
              return selected.join(', ');
            }
            return selected;
          }}
          MenuProps={formField.menuProps}
          inputProps={{'aria-label': 'Without label'}}
          style={{border: get(errors, formField.id) ? `1px solid ${FORM_ERROR_COLOR}` : `1px solid ${BORDER_COLOR}`}}
        >
          <MenuItem value={SELECT_AN_OPTION}>{SELECT_AN_OPTION}</MenuItem>
          {formField.dropDownValues.map(dropDownValue => (
            <MenuItem
              id={buildId(idPrefix, formField.id, dropDownValue)}
              key={`${formField.id}-selectField-${dropDownValue}`}
              value={dropDownValue}
              style={{
                fontWeight:
                  formField.value === dropDownValue
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium
              }}
            >
              {dropDownValue}
            </MenuItem>
          ))}
        </Select>
      );
    case FormFieldType.RADIO:
      return (
        <Visibility>
          {formField.visibilityMessage ? (
            <VisibleCondition>
              <span className="read-carefully">Please read carefully:</span>
              {formField.visibilityMessage}
            </VisibleCondition>
          ) : (
            <></>
          )}
          {formField.radioOptions.map(option => (
            <>
              <StyledRadioCheckbox
                type="radio"
                checked={option.isChecked}
                value={option.value.toLowerCase()}
                id={`${idPrefix}--${formField.id}${option.value}CheckBox`}
                key={`${idPrefix}--${formField.id}${option.value}CheckBox`}
                defaultValue={formField.value}
                name={formField.id}
                disabled={formField.disabled}
                onChange={e => onChange(e.target.value)}
              />
              <RadioButtonLabel>{option.value}</RadioButtonLabel>
            </>
          ))}
        </Visibility>
      );
    default:
      break;
  }
};
const FormComponent: React.FC<FormDetails> = ({
  id,
  setFormData,
  formFields,
  formControlMethods,
  idPrefix,
  layout = FormLayout.VERTICAL,
  count = 1
}) => {
  const {
    handleSubmit,
    formState: {errors},
    control
  } = formControlMethods;

  const useSubmit = data => {
    setFormData(data);
  };
  return (
    <Form count={count} id={id} onSubmit={handleSubmit(useSubmit)} key={id}>
      {formFields.map(formField => {
        return (
          <FormFieldContainer layout={layout} count={count}>
            {formField.label && (
              <TextFieldLabels
                id={`${idPrefix}--${formField.id}FieldTitle`}
                key={`${formField.id}-label`}
                isDisabled={formField.disabled}
                style={{
                  color: formField.disabled
                    ? DISABLED_SAVE_PDF_BTN_COLOR
                    : get(errors, formField.id) || formField.rules.warning
                    ? FORM_ERROR_COLOR
                    : FORM_COLOR
                }}
                layout={layout}
              >
                {formField.label}
                {formField.rules.required && (
                  <MandatoryIndicator key={`${formField.id}-mandatoryIndicator`}>*</MandatoryIndicator>
                )}
              </TextFieldLabels>
            )}
            <FormFieldInputContainer layout={layout}>
              <Controller
                key={`${formField.id}-controller`}
                name={formField.id}
                control={control}
                rules={formField.rules}
                defaultValue={formField.value}
                render={({field}) => (
                  <FormFieldComponent
                    key={`${formField.id}-formField`}
                    formField={formField}
                    onChange={field.onChange}
                    errors={errors}
                    idPrefix={idPrefix}
                    setDate={formField.setDate}
                    layout={layout}
                  />
                )}
              />
              {formField.description && (
                <FieldDescription>
                  <span className="title" id={`${formField.id}--descriptionTitle`}>
                    {formField.description.title}
                    {formField.description.title ? ':' : ''}
                  </span>
                  <span className="value" id={`${formField.id}--description`}>
                    {formField.description.value}
                  </span>
                  {formField.descriptionHelper && (
                    <DataToolTip arrow title={formField.descriptionHelper} placement="right-start">
                      <InfoOutlinedIcon
                        sx={{margin: '5px 0 0 5px', width: 16, height: 16, color: LIGHT_GREY}}
                        className="formFieldHelpIcon"
                      />
                    </DataToolTip>
                  )}
                </FieldDescription>
              )}
              {get(errors, formField.id) && (
                <FormError key={`${formField.label}-error`} id={`${idPrefix}--${formField.id}FieldError`}>
                  <InfoIcon className="formErrorIcon" />
                  <div>
                    {get(errors, formField.id).message
                      ? get(errors, formField.id).message
                      : `${formField.label ? formField.label : formField.id} is required`}
                  </div>
                </FormError>
              )}

              {formField.rules.warning && (
                <FormError key={`${formField.label}-warning`} id={`${idPrefix}--${formField.id}FieldWarning`}>
                  <WarningOutlined className="formWarningIcon" />
                  <div className="formWarningText">
                    {`${
                      formField.label ? formField.label : formField.id
                    } seems to be used in another aircraft. Please confirm that the ${
                      formField.label ? formField.label : formField.id
                    } is correct before you continue.`}
                  </div>
                </FormError>
              )}
            </FormFieldInputContainer>
          </FormFieldContainer>
        );
      })}
    </Form>
  );
};

export default FormComponent;
