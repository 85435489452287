/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Open Tasks
 */

import {ColumnSortReducer, ColumnSortState} from './ColumnSortReducer';
import {SnackBarReducer} from './SnackBarReducer';
import {delegateToReducers} from '../Reducer';
import {FiltersReducer, FiltersState} from './FiltersReducer';
import {CommonAction} from './InitReducer';
import {setFilters, setOrderColumnQuery} from '../UrlMap';

export const OPEN_TASKS_INFO_STORE_CONTEXT = 'openTasksInfo';

export interface task {
  taskId: string;
  serialNumber: string;
  tailId: string;
}

export interface OpenTasksState {
  filters: FiltersState;
  sort: ColumnSortState;
  sortBy: string;
  sortOrder: string;
  newTask: task;
}

export const InitialOpenTasksInfoState: OpenTasksState = {
  filters: {
    filters: [],
    domainOptions: [],
    rangeOptions: {}
  },
  sort: {
    options: undefined,
    column: 'status',
    order: 'desc',
    queryField: 'status'
  },
  sortBy: null,
  sortOrder: null,
  newTask: null
};

export const OpenTasksInfoSessionStoreKeys: string[] = ['sortBy', 'sortOrder', 'newTask'];

export enum OpenTasksInfoAction {
  SET_OPEN_TASKS_SORT_INFO = 'SET_OPEN_TASKS_SORT_INFO',
  SET_NEW_TASK = 'SET_NEW_TASK',
  RESET_TASK = 'RESET_TASK'
}

export const OPEN_TASKS_LIST_ROUTE = '/lists/open-tasks';

/**
 * Open Tasks information action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const OpenTasksInfoReducer = (state: OpenTasksState, action: any): OpenTasksState => {
  switch (action.type) {
    case CommonAction.PROCESS_URL_PARAMS:
      if (action.payload.path === OPEN_TASKS_LIST_ROUTE) {
        state = setOrderColumnQuery(state, action.payload);
        state = setFilters(state, action.payload);
      }
      break;
    case OpenTasksInfoAction.SET_OPEN_TASKS_SORT_INFO:
      state = {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder
      };
      break;
    case OpenTasksInfoAction.SET_NEW_TASK:
      state = {
        ...state,
        newTask: action.payload
      };
      break;
    case OpenTasksInfoAction.RESET_TASK:
      state = {
        ...state,
        newTask: null
      };
      break;  
    default:
      break;
  }

  state = delegateToReducers(
    OPEN_TASKS_INFO_STORE_CONTEXT,
    state,
    action,
    {sort: ColumnSortReducer, filters: FiltersReducer, snackBar: SnackBarReducer},
    InitialOpenTasksInfoState
  );

  return state;
};
