/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Retire lookup record modal
 */

import {useState} from 'react';
import {useRetireLookupRecord} from '../../../../utils/customHooks';
import styled from '@emotion/styled';
import PopUpModal from '../../../common/Modal';
import {
  RETIRE_LOOKUP_BUTTON_BACKGROUND,
  RETIRE_LOOKUP_BUTTON_BORDER_COLOR,
  RETIRE_LOOKUP_BUTTON_COLOR,
  RETIRE_LOOKUP_CANCEL_BACKGROUND,
  RETIRE_LOOKUP_CANCEL_BUTTON_COLOR,
  RETIRE_LOOKUP_CONTENT_COLOR,
  RETIRE_LOOKUP_TITLE_COLOR
} from '../../../common/theme/Colors';
import {NEXT_BUTTON_DISABLED} from '../../../../theme/Colors';

const RetireLookupContainer = styled.div`
  width: 600px;
  min-height: 282px;
  display: flex;
  flex-direction: column;
  foint-family: Source Sans Pro;
`;
const RetireLookupBody = styled.div`
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 54px 48px 24px;
`;

const RetireLookupTilte = styled.div`
  font-style: normal;
  font-size: 24px;
  font-weight: 800;
  color: ${RETIRE_LOOKUP_TITLE_COLOR};
  display: flex;
  width: 100%;
  justify-content: center;
  line-height: 32px;
  height: 32px;
  margin-bottom: 16px;
`;

const RetireLookupContent = styled.div`
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  color: ${RETIRE_LOOKUP_CONTENT_COLOR};
  display: flex;
  width: 100%;
  justify-content: center;
  line-height: 24px;
  height: 48px;
`;

const RetireLookupActionContainer = styled.div`
  border-top: 1px solid ${NEXT_BUTTON_DISABLED};
  height: 64px;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  padding: 24px;
  align-items: center;
`;

const RetrieLookupActionButton = styled.button<{backgroundColor: string; fontColor: string; hasBorder: boolean}>`
  box-sizing: border-box;
  display: flex
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px 24px;
  border-radius: 1000px;
  border: ${props => (props.hasBorder ? `1px solid ${RETIRE_LOOKUP_BUTTON_BORDER_COLOR}` : 'none')};
  margin-right: 12px;
  min-width: 98px;
  width: auto;
  height: 42px;

  font-size: 16px;
  font-weight: 600;
  color: ${props => props.fontColor};
  background: ${props => props.backgroundColor};
`;

export enum RetireLookupType {
  RETIRE = 'Retire',
  DEACTIVATE = 'Deactivate'
}

interface RetireLookupRecordProps {
  retireLookupQueryRoute: string;
  recordDisplayName: string;
  retireLookupType: RetireLookupType;
  openRetireLookupModal: boolean;
  setOpenRetireLookupModal: (openModal: boolean) => void;
}

const RetireLookupRecord: React.FC<RetireLookupRecordProps> = ({
  retireLookupQueryRoute,
  recordDisplayName,
  retireLookupType,
  openRetireLookupModal,
  setOpenRetireLookupModal
}) => {
  const [retireLookup, setRetrieLookup] = useState(false);
  useRetireLookupRecord(
    retireLookupQueryRoute,
    retireLookup,
    `${recordDisplayName} has been  ${retireLookupType}d`,
    setOpenRetireLookupModal
  );
  return (
    <PopUpModal
      id="retire-lookup-modal"
      open={openRetireLookupModal}
      title={''}
      titlecolor={''}
      borderRadius="16px"
      padding={'0px'}
    >
      <RetireLookupContainer>
        <RetireLookupBody>
          <RetireLookupTilte id="retire-lookup--title">
            {retireLookupType} {recordDisplayName}?{' '}
          </RetireLookupTilte>
          <RetireLookupContent id="retire-lookup--content">
            {retireLookupType.slice(0, -1) + 'ing'} the {recordDisplayName} will make it unavailable as a choice to
            Service Manager and Insights for Business Aviation. Are you sure ?
          </RetireLookupContent>
        </RetireLookupBody>
        <RetireLookupActionContainer>
          <RetrieLookupActionButton
            hasBorder={true}
            id="cancel-retire-lookup-record-button"
            backgroundColor={RETIRE_LOOKUP_CANCEL_BACKGROUND}
            fontColor={RETIRE_LOOKUP_CANCEL_BUTTON_COLOR}
            onClick={() => setOpenRetireLookupModal(false)}
          >
            Cancel
          </RetrieLookupActionButton>
          <RetrieLookupActionButton
            hasBorder={false}
            id="retire-lookup-record-button"
            backgroundColor={RETIRE_LOOKUP_BUTTON_BACKGROUND}
            fontColor={RETIRE_LOOKUP_BUTTON_COLOR}
            onClick={() => {
              setRetrieLookup(true);
            }}
          >
            {retireLookupType}
          </RetrieLookupActionButton>
        </RetireLookupActionContainer>
      </RetireLookupContainer>
    </PopUpModal>
  );
};

export default RetireLookupRecord;
