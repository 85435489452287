/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Link-related utility functions.
 */

import {isEmpty, reduce} from 'lodash';
import {stringify} from 'qs';
/**
 * Gets the current URL path for the app
 * @returns the path
 */
export const getCurrentUrlPath = () => {
  // Gen location (allow for port on localhost/tunnel)
  const href = sessionStorage.iframeLocation;
  const cleanedHref = href && href[href.length - 1] === '/' ? href.slice(0, -1) : href;

  // Path always needs to be for the current window, not the parent
  return `${cleanedHref}${window.location.pathname}`;
};

/**
 * Returns Url String from applied filters
 * @return Url String
 * @param filters
 */
const generateFiltersUrl = (filters: any): string => {
  // Convert the filters to an object, removing any empty ones
  const usedFilters = reduce(
    {
      ...filters
    },
    (acc, value, key) => {
      if (isEmpty(value)) {
        return acc;
      }
      return {...acc, [key]: value};
    },
    {}
  );
  return isEmpty(usedFilters) ? '' : stringify(usedFilters);
};
/**
 * Gets filters as a search string
 * @param urlFilters filters to be appended
 * @param store the store (used for retrieving current settings like dates and airline)
 * @param addAutoRefresh include auto refresh state to url
 * @returns the url filters
 */
export const getSessionFiltersToUrl = (urlFilters: any, store: any): string => {
  const {
    init: {viewAsCustomer},
    group: {
      current: {codes: groupCodes}
    }
  } = store;

  const filters = {
    ...(urlFilters ? urlFilters : {}),
    ...(groupCodes ? {group: (groupCodes as string[]).join(',')} : {}),
    viewAsCustomer: encodeURIComponent(viewAsCustomer)
  };

  return generateFiltersUrl(filters);
};

/**
 * Gets filters as a search string
 * @param urlFilters filters to be appended
 * @param store the store (used for retrieving current settings like dates and airline)
 * @param addAutoRefresh include auto refresh state to url
 * @returns the url filters
 */
export const getFiltersToUrl = (urlFilters: any, store: any, addAutoRefresh: boolean = false): string => {
  const {
    init: {isInternal}
  } = store;

  const filters = {
    ...(urlFilters ? urlFilters : {}),
    ...isInternal
  };

  return generateFiltersUrl(filters);
};

/**
 * Returns Filters to apply from url filter object
 * @param filtersObject filtersObject from the url
 * @return Filters to apply
 */
export const getFiltersToApply = (filtersObject: any) => {
  return Object.keys(filtersObject).reduce((acc: any[], key: string) => {
    const dashIndex = key.indexOf('-');
    if (dashIndex < 0) {
      return acc;
    }
    // getDash Index:
    const value = filtersObject[key];
    const filterId = key.slice(0, dashIndex);
    const domainOptionsKey = key.slice(dashIndex + 1);
    return [...acc, {filterId, domainOptionsKey, rangeOptionsKeys: value}];
  }, []);
};

/**
 * Take parsed url filters and return a new state
 * Reset filters state if no url filters
 * @param state current state
 * @param urlParams parsed url data
 * @return new state
 */
export const setShareLinkFilters = (state: any, urlParams: any): any => {
  const filters = getFiltersToApply(urlParams);
  if (filters.length > 0) {
    state = {
      ...state,
      filters: {
        ...state.filters,
        filters
      }
    };
  } else {
    state = {
      ...state,
      filters: {
        ...state.filters,
        filters: []
      }
    };
  }
  return state;
};

/**
 * Take parsed url sort/order and return new state
 * @param state current state
 * @param urlParams parsed url data
 * @return new state
 */
export const setShareLinkSortOrder = (state: any, urlParams: any): any => {
  if (urlParams.order && urlParams.column) {
    const {order, column} = urlParams;
    state = {
      ...state,
      sort: {
        ...state.sort,
        order,
        column
      }
    };
  }
  return state;
};
