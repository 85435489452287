/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Lookup Reference Table Queries
 */

import {Query} from './types';
import {
  IAircraftModel,
  IIPAddress,
  IPartNumber,
  IRouterType,
  IServicePlan,
  IAddRecord,
  IUpdateRecord,
  ISwVersions,
  IServicePlanTerms,
  IKuMapBundleFilesList,
  IWarrantyTerm
} from './lookupTypes';
import {getAircraftModel, getMapBundleVersions} from './util/lookupQueryUtil';

export const addAircraftTypeQuery: Query<IAddRecord> = {
  route: 'lookups/create/aircraftType',
  transform: null
};

export const getAircraftModelsQuery: Query<IAircraftModel[]> = {
  route: 'lookups/get/aircraftTypes',
  transform: getAircraftModel
};

export const updateAircraftTypeQuery = (id: string): Query<IUpdateRecord> => ({
  route: `lookups/update/aircraftType/${id}`,
  transform: null
});

export const addIPAddressQuery: Query<IAddRecord> = {
  route: 'lookups/create/ipAddress',
  transform: null
};

export const getIPAddressesQuery: Query<IIPAddress[]> = {
  route: 'lookups/get/ipAddresses',
  transform: null
};

export const addPartNumberQuery: Query<IAddRecord> = {
  route: 'lookups/create/partNumber',
  transform: null
};

export const getPartNumbersQuery: Query<IPartNumber[]> = {
  route: 'lookups/get/partNumbers',
  transform: null
};

export const updatePartNumberQuery = (id: string): Query<IUpdateRecord> => ({
  route: `lookups/update/partNumber/${id}`,
  transform: null
});

export const addRouterTypeQuery: Query<IAddRecord> = {
  route: 'lookups/create/routerType',
  transform: null
};

export const getRouterTypesQuery: Query<IRouterType[]> = {
  route: 'lookups/get/routerTypes',
  transform: null
};

export const updateRouterTypeQuery = (id: string): Query<IUpdateRecord> => ({
  route: `lookups/update/routerType/${id}`,
  transform: null
});

export const addServicePlanQuery: Query<IAddRecord> = {
  route: 'lookups/create/servicePlan',
  transform: null
};

export const getServicePlansQuery: Query<IServicePlan[]> = {
  route: 'lookups/get/servicePlans',
  transform: null
};

export const getKaBandSwListQuery: Query<ISwVersions[]> = {
  route: 'lookups/get/softwareVersions/Ka',
  transform: null
};

export const getKuBandSwListQuery: Query<ISwVersions[]> = {
  route: 'lookups/get/softwareVersions/Ku',
  transform: null
};

export const getKuBandMapBundleFilesListQuery: Query<IKuMapBundleFilesList[]> = {
  route: 'lookups/get/mapBundleVersions',
  transform: getMapBundleVersions
};

export const getServicePlanTermsListQuery: Query<IServicePlanTerms[]> = {
  route: 'lookups/get/servicePlanTerms',
  transform: null
};

export const addSwVersionQuery: Query<IAddRecord> = {
  route: 'lookups/create/softwareVersion',
  transform: null
};

export const updateSwVersionQuery = (id: string): Query<IUpdateRecord> => ({
  route: `lookups/update/softwareVersion/${id}`,
  transform: null
});

export const addServicePlanTermsQuery: Query<IAddRecord> = {
  route: 'lookups/create/servicePlanTerms',
  transform: null
};

export const updateServicePlanTermsQuery = (id: string): Query<IUpdateRecord> => ({
  route: `lookups/update/servicePlanTerm/${id}`,
  transform: null
});

export const addMapBundleVersionQuery: Query<IAddRecord> = {
  route: 'lookups/create/mapBundleVersions',
  transform: null
};

export const updateMapBundleVersionQuery = (id: string): Query<IUpdateRecord> => ({
  route: `lookups/update/mapBundleVersion/${id}`,
  transform: null
});

export const addWarrantyTermsQuery: Query<IAddRecord> = {
  route: 'lookups/create/warrantyTerm',
  transform: null
};

export const updateWarrantyTermsQuery = (id: string): Query<IUpdateRecord> => ({
  route: `lookups/update/warrantyTerm/${id}`,
  transform: null
});

export const getWarrantyTermsListQuery: Query<IWarrantyTerm[]> = {
  route: 'lookups/get/warrantyTerms',
  transform: null
};
