/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Index
 */
import './utils/MuiClassNameSetup';
import React from 'react';
import ReactDOM from 'react-dom';
import {Global} from '@emotion/react';
import * as FullStory from '@fullstory/browser';
import {QueryClientProvider} from '@tanstack/react-query';

import './index.css';
import OuterAppWrapper from './OuterAppWrapper';
import AppProviders from './AppProviders';
import globalStyle from './theme/GlobalStyle';
import {ThemeProvider} from '@mui/material/styles';
import theme from './AppTheme';
import {StoreProvider} from './store/Store';
import {Reducer} from './store/Reducer';
import {DEV_ENVIRONMENT, environment, fullStoryOrgId} from './utils/config';
import {queryClient} from './utils/useFetchV2';

/**
 * Declaring <Global/> as static constant to prevent rerender as reccomended by link
 * @link https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override
 */
const inputGlobalStyles = <Global styles={globalStyle} />;

const devMode = environment === DEV_ENVIRONMENT;
FullStory.init({orgId: fullStoryOrgId, recordCrossDomainIFrames: true, devMode});
console.info(`Environment: ${environment}, devMode: ${devMode}`);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AppProviders>
      {inputGlobalStyles}
      <QueryClientProvider client={queryClient}>
        <StoreProvider reducer={Reducer}>
          <OuterAppWrapper />
        </StoreProvider>
      </QueryClientProvider>
    </AppProviders>
  </ThemeProvider>,
  document.getElementById('root')
);
