/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Warranty Details
 */
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {useStore} from '../../store/Store';

import FormComponent, {FormField, FormFieldType} from '../common/elements/Form';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';

import {BottomHorizontalRule, HeaderHorizontalRule, HeaderLabel, PageDescription} from '../CommonStyles';

import {DUAL_BAND_NETWORK, KA_NETWORK, KU_NETWORK} from '../common/constants';

import styled from '@emotion/styled';
import {LABEL_COLOR} from '../../theme/Colors';
import FormSubmitButton from '../common/elements/FormSubmitButton';
import {useWarrantyExtensionForm} from '../../utils/equipmentInformationFormHook';
import {useEquipmentWarrantyExtension} from '../../utils/customHooks';

const ID_PREFIX = 'warrantyExtension';

interface WarrantyProps {
  title: string;
}

interface IFormInput {
  commonWarrantyDate: string;
}

const WarrantyDescription = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: ${LABEL_COLOR};
  line-height: 24px;
  margin-bottom: 14px;
  margin-top: 14px;
`;

const WarrantyDetails: React.FC<WarrantyProps> = ({title}) => {
  const location = useLocation();

  const WarrantyExtCommonForm = () => {
    const {store} = useStore();

    const [warrantyInfo, setWarrantyInfo] = useState(null);
    const aircraftInfo = store.aircraftInfo;
    const aircraftNetwork = aircraftInfo.network;
    const equipmentInfo = store.equipmentInfo;

    const [commonWarrantyEndDate, setCommonWarrantyEndDate] = useState(null);

    const {handleSubmit, formState, watch, control} = useForm();

    const initialValuesForm: IFormInput = {
      commonWarrantyDate: null
    };
    const warrantyExtFormFields: FormField[] = [
      {
        id: 'commonWarrantyDate',
        label: 'New Warranty End Date',
        type: FormFieldType.DATE,
        value: commonWarrantyEndDate ? commonWarrantyEndDate : initialValuesForm.commonWarrantyDate,
        setDate: setCommonWarrantyEndDate,
        disabled: false,
        placeholder: '__/__/____',
        rules: {
          required: false,
          pattern: null
        }
      }
    ];
    const {kaWarrantyFormFields, kuWarrantyFormFields} = useWarrantyExtensionForm(watch, equipmentInfo);

    useEquipmentWarrantyExtension(warrantyInfo, setWarrantyInfo);

    return (
      <>
        <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
        <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Warranty Details</HeaderLabel>
        <HeaderHorizontalRule />
        <PageDescription id={`${ID_PREFIX}--description`}>
          Make changes to the LRU warranty details for aircraft: {aircraftInfo.serialNumber}
        </PageDescription>
        <BottomHorizontalRule />
        <WarrantyDescription>Update the warranty end date for all LRUs:</WarrantyDescription>
        <FormComponent
          idPrefix={ID_PREFIX}
          id="warranty-extension-form"
          formFields={warrantyExtFormFields}
          setFormData={setWarrantyInfo}
          formControlMethods={{handleSubmit, formState, watch, control}}
        ></FormComponent>
        <WarrantyDescription>Or edit them individually:</WarrantyDescription>
        {aircraftNetwork === DUAL_BAND_NETWORK ? (
          <WarrantyDescription>Ka Equipment Warranty Dates</WarrantyDescription>
        ) : (
          <></>
        )}
        {aircraftNetwork === DUAL_BAND_NETWORK || aircraftNetwork === KA_NETWORK ? (
          <FormComponent
            idPrefix={ID_PREFIX}
            id="warranty-extension-form"
            formFields={kaWarrantyFormFields}
            setFormData={setWarrantyInfo}
            formControlMethods={{handleSubmit, formState, watch, control}}
            count={2}
          ></FormComponent>
        ) : (
          <></>
        )}
        {aircraftNetwork === DUAL_BAND_NETWORK ? (
          <WarrantyDescription>Ku Equipment Warranty Dates</WarrantyDescription>
        ) : (
          <></>
        )}
        {aircraftNetwork === DUAL_BAND_NETWORK || aircraftNetwork === KU_NETWORK ? (
          <FormComponent
            idPrefix={ID_PREFIX}
            id="warranty-extension-form"
            formFields={kuWarrantyFormFields}
            setFormData={setWarrantyInfo}
            formControlMethods={{handleSubmit, formState, watch, control}}
            count={2}
          ></FormComponent>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <PageContainer
      title={title}
      leftChild={<WarrantyExtCommonForm />}
      actionButtons={
        <FormSubmitButton
          buttonId={`${ID_PREFIX}--nextButton`}
          formId="warranty-extension-form"
          disabled={false}
          hasArrowIcon={true}
          isLoading={false}
          isSubmit={false}
        />
      }
    />
  );
};

export default WarrantyDetails;
