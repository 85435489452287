/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Replace Equipment Update Form
 */
import {useLocation} from 'react-router-dom';
import FormComponent, {FormControlMethods} from '../common/elements/Form';
import {EquipmentInfoAction} from '../../store/reducers/EquipmentInfoReducer';
import Breadcrumb from '../common/Breadcrumb';

import {
  BottomHorizontalRule,
  FormContainerLeftPanel,
  HeaderHorizontalRule,
  HeaderLabel,
  LatestRevision,
  PageDescription,
  StyledLink
} from '../CommonStyles';
import {showUserManuals} from '../../utils/config';

export enum FormTypes {
  EXISTING = 'existing',
  NEW = 'new'
}

export interface FormConfig {
  id: FormTypes;
  title: string;
  description: string;
  footerText: string;
  footerHyperLinkText: string;
  footerHyperLinkUrl: string;
  actionType: EquipmentInfoAction;
}

export interface EquipmentUpdateFormProps {
  formId: string;
  formConfig: FormConfig;
  formFields: any[];
  onFormSubmit: (data: any) => void;
  formControlMethods: FormControlMethods;
  idPrefix?: string;
}

export const EquipmentUpdateFormHeader = ({formId, title, description}) => {
  const location = useLocation();

  return (
    <>
      <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
      <HeaderLabel id={`${formId}--title`}>{title}</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription>{description}</PageDescription>
      <BottomHorizontalRule />
    </>
  );
};

export const EquipmentUpdateFormFooter = ({footerText, hyperLinkText, hyperLinkUrl}) => {
  if (!footerText || !hyperLinkText || !hyperLinkUrl) {
    return null;
  }

  return showUserManuals ? (
    <>
      <BottomHorizontalRule />
      {footerText && <LatestRevision>{footerText}</LatestRevision>}
      {hyperLinkUrl && <StyledLink href={hyperLinkUrl}>{hyperLinkText}</StyledLink>}
    </>
  ) : (
    <></>
  );
};

const EquipmentUpdateForm: React.FC<EquipmentUpdateFormProps> = ({
  formId,
  formConfig,
  formFields,
  onFormSubmit,
  formControlMethods,
  idPrefix
}) => {
  return (
    <>
      <EquipmentUpdateFormHeader formId={formId} title={formConfig.title} description={formConfig.description} />
      <FormContainerLeftPanel>
        <FormComponent
          id={formId}
          key={formId}
          idPrefix={idPrefix}
          formFields={formFields}
          setFormData={onFormSubmit}
          formControlMethods={formControlMethods}
        />
      </FormContainerLeftPanel>
      <EquipmentUpdateFormFooter
        footerText={formConfig.footerText}
        hyperLinkText={formConfig.footerHyperLinkText}
        hyperLinkUrl={formConfig.footerHyperLinkUrl}
      />
    </>
  );
};

export default EquipmentUpdateForm;
