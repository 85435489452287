/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Primary View Pages
 */
import OpenTasks from './components/lists/openTasks/OpenTasks';
import MainMenu from './components/mainMenu/MainMenu';
import ListViewPage from './components/lists/ListViewPage';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TableChart from '@mui/icons-material/TableChart';
import ApprovalsList from './components/lists/approvals/ApprovalsList';
import {approvalListEnabled} from './utils/config';

/**
 * Generates the list of app pages based on user access check
 * @param isAdmin Check for Admin access
 * @returns List of App pages based on the available access
 */
const getPages = (isAdmin: boolean, isInternal: boolean) => {
  const PAGES = [
    {
      name: 'Main Menu',
      key: 'mainMenu',
      icon: <BusinessCenterIcon />,
      route: '/main-menu',
      ariaLabel: 'Main Menu',
      component: MainMenu,
      props: {
        title: 'Main Menu'
      }
    },
    {
      name: 'Open Tasks',
      key: 'openTasks',
      icon: <AssignmentTurnedInIcon />,
      route: '/lists/open-tasks',
      ariaLabel: 'Open Tasks',
      component: OpenTasks,
      props: {
        title: 'Open Tasks'
      }
    }
  ];
  if (approvalListEnabled && (isAdmin || isInternal)) {
    PAGES.push({
      name: 'Requests',
      key: 'approvalsList',
      icon: <PendingActionsIcon />,
      route: '/lists/approvals-list',
      ariaLabel: 'Requests',
      component: ApprovalsList,
      props: {
        title: 'Requests'
      }
    });
  }
  if (isAdmin || isInternal) {
    PAGES.push({
      name: 'Lookups',
      key: 'lookupsView',
      icon: <TableChart />,
      route: '/lookups/aircraft-model-list',
      ariaLabel: 'Lookus View',
      component: ListViewPage,
      props: {
        title: 'Lookups View'
      }
    });
  }
  return PAGES;
};

export default getPages;
