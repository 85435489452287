/**
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Initial state and reducer for Approval
 */

import {ColumnSortReducer, ColumnSortState} from './ColumnSortReducer';
import {SnackBarReducer} from './SnackBarReducer';
import {delegateToReducers} from '../Reducer';
import {FiltersReducer, FiltersState} from './FiltersReducer';
import {CommonAction} from './InitReducer';
import {setFilters, setOrderColumnQuery} from '../UrlMap';

export const APPROVAL_INFO_STORE_CONTEXT = 'approvalInfo';

export interface approvalRequest {
  taskId: string;
  serialNumber: string;
  tailId: string;
}

export interface ApprovalState {
  filters: FiltersState;
  sort: ColumnSortState;
  sortBy: string;
  sortOrder: string;
  newApproval: approvalRequest;
  refreshReviewInfo: number;
}

export const InitialApprovalInfoState: ApprovalState = {
  filters: {
    filters: [],
    domainOptions: [],
    rangeOptions: {}
  },
  sort: {
    options: undefined,
    column: 'createdTstamp',
    order: 'desc',
    queryField: 'createdTstamp'
  },
  sortBy: null,
  sortOrder: null,
  newApproval: null,
  refreshReviewInfo: -1
};

export const ApprovalInfoSessionStoreKeys: string[] = [
  'sort.column',
  'sort.order',
  'sort.queryField',
  'filters.filters',
  'sortBy',
  'sortOrder',
  'newApproval'
];

export enum ApprovalInfoAction {
  SET_APPROVAL_SORT_INFO = 'SET_APPROVAL_SORT_INFO',
  REFRESH_REVIEW_INFO = 'REFRESH_REVIEW_INFO'
}

export const APPROVAL_LIST_ROUTE = '/lists/approvals-list';

/**
 * Equipmet information action reducer
 * @param state Current State
 * @param action Action to perform
 * @returns Updated state
 */
export const ApprovalInfoReducer = (state: ApprovalState, action: any): ApprovalState => {
  switch (action.type) {
    case CommonAction.PROCESS_URL_PARAMS:
      if (action.payload.path === APPROVAL_LIST_ROUTE) {
        state = setOrderColumnQuery(state, action.payload);
        state = setFilters(state, action.payload);
      }
      break;
    case ApprovalInfoAction.SET_APPROVAL_SORT_INFO:
      state = {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder
      };
      break;
    case ApprovalInfoAction.REFRESH_REVIEW_INFO:
      state = {
        ...state,
        refreshReviewInfo: action.payload
      };
      break;
    default:
      break;
  }

  state = delegateToReducers(
    APPROVAL_INFO_STORE_CONTEXT,
    state,
    action,
    {sort: ColumnSortReducer, filters: FiltersReducer, snackBar: SnackBarReducer},
    InitialApprovalInfoState
  );

  return state;
};
