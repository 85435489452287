/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Form Action Button
 */
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {NextButton} from '../../CommonStyles';

export const FormActionButtons = ({buttonText, disabled, id, formId, showArrow = true}) => {
  return (
    <NextButton id={`${id}--nextButton`} form={formId} disabled={disabled}>
      <span className="next-button-text">{buttonText}</span>
      {showArrow && <ArrowForwardIcon className="next-arrow" />}
    </NextButton>
  );
};
