/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Form Validation utils
 */

import {isNil, isObject} from 'lodash';
import {DateTime} from 'luxon';
import {SELECT_AN_OPTION} from '../components/common/constants';
import {IConfigItems} from '../store/queries/getAllConfigItemsQuery';
import {DATE_MONTH_YEAR_NUMBER_FORMAT, formatDateTimeInput} from './dateTimeUtils';
import {IVarKaBandIp} from '../store/queries/varKaBandIpListQuery';

/**
 * validate dropdown when no value selected
 * @param fieldValue dropdown value
 * @param fieldLabel dropdown label
 * @returns returns validation message if no valid value selected  else true
 */
export const validateDropdownField = (fieldValue: string, fieldLabel: string): string | boolean => {
  if (fieldValue === SELECT_AN_OPTION) {
    return fieldLabel + ' is required';
  } else return true;
};

/**
 * validate KA band IP when existing aircraft has same fieldValue
 * @param fieldValue dropdown value
 * @param tailId Tail ID
 * @param ipList KA band IP(s) list
 * @returns returns validation message if ka band ip exists in another aircraft
 */
export const isExistingKaBandIp = (fieldValue: string, tailId: string, ipList: IVarKaBandIp[]): string | boolean => {
  const res = ipList?.some(
    item =>
      (item?.serviceSiteKaBandIp === fieldValue && tailId !== item?.tailId) ||
      (item?.ntdChangesKaBandIp === fieldValue && tailId !== item?.tailId)
  );
  if (res) return 'The specified Ka IP address is already in use.';
  else return true;
};

/**
 * Checks if given item exists in configuration items list
 * @param configItems list of configuration items
 * @param key comparison key used in configItems
 * @param itemToMatch single configuration item
 * @param network aircraft network
 * @param siteId site identifier
 * @returns returns true is item is available in list
 */
export const validateConfigItemsExists = (
  configItems: IConfigItems[],
  key: string,
  itemToMatch: string,
  network: string,
  siteId?: string
) => {
  if (isNil(itemToMatch)) return false;
  const sameItem = configItems?.some(
    item => item[key] === itemToMatch && network === item.configSubType && (siteId ? item.siteId !== siteId : true)
  );
  return sameItem;
};

/**
 * Check Extended Warranty date is greater than existing warranty end date
 * @param extendedWarrantyDate - New Warranty End date
 * @param existingWarrantyDate - Existing Warranty End date
 * @returns - returns error msg if extended date is prior to existing date
 */
export const validateWarrantyEndDate = (
  extendedWarrantyDate: string,
  existingWarrantyDate: string
): string | boolean => {
  const extendedWarrantyFormatted = isObject(extendedWarrantyDate)
    ? formatDateTimeInput(DateTime.fromISO(extendedWarrantyDate), DATE_MONTH_YEAR_NUMBER_FORMAT)
    : extendedWarrantyDate;
  if (extendedWarrantyFormatted >= existingWarrantyDate) {
    return true;
  } else {
    return 'Cannot start before the original end date.';
  }
};
