/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Open Tasks utils
 */
import {DefaultCellRendererV2, CellRenderer, ListGridColumn} from '@viasat/insights-components';
import {ListGridLinkedDataColumn} from '../../../utils/listUtils';
import LongTextCellRenderer from '../../common/elements/listGridCells/LongTextCell';
import {
  LinkCellRenderer,
  convertFilterValues,
  getSerialNumberFilterRangeOptions,
  getTailIdFilterRangeOptions
} from '../listsUtil';
import {FilterOptionsType, FilterType} from '../../../utils/filterUtils';
import {TASK_TYPES} from '../../common/constants';

const DB_COLUMN_MAPPING = {
  serialNumber: 'serialNumber',
  tailId: 'tailId',
  taskTitle: 'taskTitle',
  message: 'taskDescription',
  status: 'status',
  createdTstamp: 'createdTstamp',
  updatedTstamp: 'updatedTstamp',
  updatedBy: 'updatedBy',
  createdBy: 'createdBy'
};

// Approval List Hidden Columns mappings for encoding/decoding sharelink
// Required: Enum name should match columnId name, case-sensitive
export enum OpenTasksListColumnId {
  status = 0
}

/**
 * Get the Name of a Column Id Enum Key
 * @param columnIdEnum key to convert to name
 * @returns name of enum as string, otherwise UNKNOWN_COLUMN_ID_NAME
 */
export const getColumnIdEnumName = (enumType: any, columnIdEnum: any): string =>
  columnIdEnum.hasOwnProperty(enumType) ? columnIdEnum[enumType] : 'UNKNOWN_COLUMN_ID_NAME';

/**
 * Get Open Tasks List ColumnId Name
 * @param columnId columnId enum to convert to name
 * @returns name of columnId as string
 */
export const getColumnIdName = (columnId: OpenTasksListColumnId) =>
  getColumnIdEnumName(columnId, OpenTasksListColumnId);

// Query Field Constants
export const QUERY_FIELD_MAPPING = {
  serialNumber: 'serialNumber',
  tailId: 'tailId',
  status: 'status',
  createdTstamp: 'createdTstamp'
};

/**
 * Get Open Tasks List Query Field Mapping
 * @param columnId columnId enum to convert to query field
 * @param append append string to columnId name (default='')
 * @returns query field as string, otherwise undefined
 */
export const getQueryFieldMapping = (columnId: OpenTasksListColumnId, append: string = ''): string | undefined => {
  const columnIdName = getColumnIdName(columnId) + append;
  return columnIdName ? QUERY_FIELD_MAPPING[columnIdName] : undefined;
};

export enum FilterId {
  serialNumber = 'serialNumber',
  tailId = 'tailId',
  status = 'status'
}

/**
 * Returns the range options for the status filter
 * @param props Filter container properties
 * @returns Array of status filter key/value pairs
 */
export const getServiceStatusFilterRangeOptions = (): Array<FilterOptionsType> =>
  convertFilterValues(['New', 'In Progress', 'Submitted', 'Approved', 'Declined']);

export const OPEN_TASKS_LIST_FILTERS: Array<FilterType> = [
  {
    title: 'Status',
    id: FilterId.status,
    getValues: getServiceStatusFilterRangeOptions
  },
  {
    title: 'Serial Number',
    id: FilterId.serialNumber,
    getValues: getSerialNumberFilterRangeOptions
  },
  {
    title: 'Tail ID',
    id: FilterId.tailId,
    getValues: getTailIdFilterRangeOptions
  }
];

/**
 * Returns the cell renderer based on task type
 * @param props cell renderer properties
 * @returns cell renderer
 */
export const CellRendererBasedOnTaskType: CellRenderer = (props: {
  cellData: any;
  columns: ListGridColumn[];
  column: ListGridColumn;
  columnIndex: number;
  rowData: any;
  rowIndex: number;
  cellIdBase?: string;
}) => {
  if (props.rowData.taskType && props.rowData.taskType === TASK_TYPES.REQUEST_AIRCRAFT_ACCESS) {
    return <div style={{marginLeft: '10px'}}><DefaultCellRendererV2 {...props}/></div>;
  } else {
    return <LinkCellRenderer {...props} />;
  }
};

/**
 * Returns a list of columns that should be included based on the given
 * @return list of ListGridLinkedDataColumn objects
 */
export const buildOpenTasksListColumns = (handleOpenTaskLinkClick): ListGridLinkedDataColumn[] => {
  // Column definitions for open tasks list grid
  const openTasksListColumns: ListGridLinkedDataColumn[] = [
    {
      key: 'serialNumber',
      dataKey: 'serialNumber',
      showHideGroup: '__groupless',
      title: 'Aircraft SN',
      getColumnJsx: CellRendererBasedOnTaskType,
      handleClick: handleOpenTaskLinkClick,
      width: 156,
      sortable: true,
      frozen: false
    },
    {
      key: 'tailId',
      dataKey: 'tailId',
      showHideGroup: '__groupless',
      title: 'Tail ID',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 150,
      sortable: true,
      frozen: false
    },
    {
      key: 'taskTitle',
      dataKey: 'taskTitle',
      showHideGroup: '__groupless',
      title: 'Task',
      getColumnJsx: CellRendererBasedOnTaskType,
      handleClick: handleOpenTaskLinkClick,
      width: 365,
      sortable: true
    },
    {
      key: 'message',
      dataKey: 'message',
      showHideGroup: '__groupless',
      title: 'Message',
      getColumnJsx: LongTextCellRenderer,
      width: 365,
      sortable: false
    },
    {
      key: 'status',
      dataKey: 'status',
      showHideGroup: '__groupless',
      title: 'Status',
      getColumnJsx: LongTextCellRenderer,
      width: 365,
      sortable: true
    },
    {
      key: 'createdTstamp',
      dataKey: 'createdTstamp',
      showHideGroup: '__groupless',
      title: 'Request Date',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 224,
      sortable: true
    },
    {
      key: 'createdBy',
      dataKey: 'createdBy',
      showHideGroup: '__groupless',
      title: 'Submitted By',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 300,
      sortable: false
    },
    {
      key: 'updatedTstamp',
      dataKey: 'updatedTstamp',
      showHideGroup: '__groupless',
      title: 'Last Update',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 224,
      sortable: false
    },
    {
      key: 'updatedBy',
      dataKey: 'updatedBy',
      showHideGroup: '__groupless',
      title: 'Updated By',
      getColumnJsx: DefaultCellRendererV2 as CellRenderer,
      width: 250,
      sortable: false
    }
  ];

  return openTasksListColumns;
};

/**
 * Mapping and return the list view column with db column
 */
export const fetchDBColumnKey = handleOpenTaskLinkClick => {
  const columnKeys = [];
  const openTasksListColumns = buildOpenTasksListColumns(handleOpenTaskLinkClick);
  for (const index in openTasksListColumns) {
    if (openTasksListColumns[index]['key'] !== 'submittedDate') {
      // Remove this condition after added the submittedDate column in the db
      columnKeys.push(DB_COLUMN_MAPPING[openTasksListColumns[index]['key']]);
    }
  }
  return columnKeys;
};
