/***
 * Copyright (C) 2023 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Service Plan List View
 */
import React, {useMemo, useEffect, useState} from 'react';
import {HorizontalTabs, MenuBar, ShareLink, ListGridV2, ListGridColumn} from '@viasat/insights-components';

import {lookupTableTabDefinitions} from '../tabDefinitions';
import PageStyled from '../../../theme/PageStyled';
import {PageProps} from '../../common/layout/PageProps';
import ListGridContainer from '../../common/ListGridContainer';
import {getElementId} from '../../../utils/elementIdUtils';
import {getServicePlansQuery} from '../../../store/queries/lookupQueries';
import AddServicePlan from './forms/LookupAddServicePlan';
import SnackBar from '../../common/SnackBar';
import {useStore} from '../../../store/Store';
import {getCurrentUrlPath} from '../../../utils/linkUtils';
import {LookupsListAction} from '../../../store/reducers/LookupsReducer';
import {LookupListColumnId, buildServicePlanListColumns, dynamicSort, getQueryFieldMapping} from './lookupTableUtils';
import {useRefreshLookupList, useResetSelectedLookup} from '../../../utils/customHooks';
import {IServicePlan} from '../../../store/queries/lookupTypes';
import LookupHeaderAction from './LookupListHeaderActions';
import RetireLookupRecord, {RetireLookupType} from './forms/RetireLookupRecord';
import AccessControl from '../../common/accessControl/AccessControl';
import {getActionTypeWithContext} from '../../../store/reducerUtils';
import {ColumnSortAction} from '../../../store/reducers/ColumnSortReducer';
import {SERVICE_PLANS_LIST_STORE_CONTEXT} from '../../../store/reducers/ServicePlansListReducer';
import ListViewSortContainer from '../ListViewSortContainer';
import {getListViewFilters} from '../listsUtil';
import {SERVICE_PLAN_LIST_FILTERS, dynamicFilter} from './FilterUtils';
import {getFilterOptions} from '../../../utils/filterUtils';
import {FilterAction} from '../../../store/reducers/FiltersReducer';
import FilterHookContainer from '../../common/elements/filterSelector/FilterHookContainer';
import useFetchV2 from '../../../utils/useFetchV2';

const ID_PREFIX = 'servicePlansList';

const ServicePlanList: React.FC<PageProps> = () => {
  const {store, dispatch} = useStore();
  const {isAdmin} = store.init;
  const {sort, filters} = store.servicePlansList;
  const {newRow, deactivatedRow, refreshList, selectedLookups} = store.lookups;
  const [servicePlansListData, setServicePlansListData] = useState<IServicePlan[]>(null);
  const servicePlanListFilters = useMemo(() => getListViewFilters(SERVICE_PLAN_LIST_FILTERS), []);

  const {snackbar} = store.app;
  const urlPath = getCurrentUrlPath();

  useResetSelectedLookup();
  useRefreshLookupList(refreshList);

  const handleRowSelection = (selectedLookup: any) => {
    dispatch({
      type: LookupsListAction.SELECTED_LOOKUP_ROWS,
      payload: selectedLookup
    });
  };

  const handleRowDeselection = (removedLookup: any) => {
    dispatch({type: LookupsListAction.REMOVE_FROM_SELECTED_LOOKUPS, payload: removedLookup});
  };

  const servicePlansListColumns = buildServicePlanListColumns(isAdmin).map(col => {
    // Add row selection/deselection handlers for checkbox
    if (col.key === 'id') {
      col.handleRowSelection = handleRowSelection;
      col.handleRowDeselection = handleRowDeselection;
    }

    return col;
  });
  const queryParams = useMemo(
    () => ({newRow, deactivatedRow, refreshList, doCache: false}),
    [newRow, deactivatedRow, refreshList]
  );

  const {isLoading: servicePlanLoading, data: servicePlanInfo} = useFetchV2(
    {route: getServicePlansQuery.route, params: queryParams},
    getServicePlansQuery.transform
  );
  const [openAddServicePlan, setOpenAddServicePlan] = useState<boolean>(false);
  const [deactivateServicePlan, setDeactivateServicePlan] = useState<boolean>(false);

  useEffect(() => {
    if (refreshList) {
      setTimeout(() => {
        dispatch({
          type: LookupsListAction.REFRESH_LOOKUP_LIST,
          payload: false
        });
        dispatch({
          type: LookupsListAction.RESET_NEW_ROW_COUNT,
          payload: 0
        });
      }, 3000); // The API call takes 1s or 1.5s or 2s sometimes and inconsistent with 500ms.
    }
  }, [refreshList, dispatch]);

  // Determine what columns to show and hide for the list grid
  const currentColumns = servicePlansListColumns;
  // useEffect Purpose: Update the options available for filtering each of the filterable columns
  useEffect(() => {
    if (servicePlanListFilters) {
      const {domainOptions, rangeOptions} = getFilterOptions({listData: servicePlanInfo}, servicePlanListFilters);

      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, SERVICE_PLANS_LIST_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [servicePlanInfo, dispatch, servicePlanListFilters]);

  useEffect(() => {
    if (servicePlanLoading) return;
    const filteredListData = dynamicFilter(servicePlanInfo, filters);
    const servicePlanDataWithSelections: IServicePlan[] = filteredListData
      ? [...filteredListData].map(row => {
          return {
            ...row,
            isSelected: selectedLookups.some((selectedRow: IServicePlan) => selectedRow.id === row.id),
            isRowHighlighted: row.status === 'Deactivated'
          };
        })
      : [];
    setServicePlansListData(servicePlanDataWithSelections.sort(dynamicSort(sort.column, sort.order)));
    // eslint-disable-next-line
  }, [servicePlanInfo, servicePlanLoading, selectedLookups, sort.column, sort.order]);

  const getQueryFieldForColumn = (columnId: string) => getQueryFieldMapping(LookupListColumnId[columnId]);

  const handleSortChange = ({column, order}: {column: string; order: string}) => {
    setServicePlansListData(servicePlansListData.sort(dynamicSort(column, order)));
    dispatch({
      type: getActionTypeWithContext(ColumnSortAction.SET_COLUMN_SORT, SERVICE_PLANS_LIST_STORE_CONTEXT),
      payload: {
        order: order,
        column: column,
        queryField: getQueryFieldMapping(servicePlansListColumns[column]),
        validColumns: servicePlansListColumns.map((col: ListGridColumn) => col.key),
        validQueryFields: servicePlansListColumns.map((col: ListGridColumn) =>
          getQueryFieldMapping(servicePlansListColumns[col.key])
        )
      }
    });
  };
  /**
   * Handles the filterchange event
   */
  const handleFilterChange = () => {
    setServicePlansListData(dynamicFilter(servicePlanInfo, filters));
  };

  return (
    <PageStyled>
      <MenuBar
        getFullElementId={(name: string, type: string) => getElementId(ID_PREFIX, 'menuBar', name, type)}
        title={''}
        subtitle={''}
        isSubtitleLoading={false}
        leftStack={[
          <AccessControl permitted={isAdmin}>
            <LookupHeaderAction
              isLoading={servicePlanLoading}
              selectedLookups={selectedLookups}
              setOpenAddAircraftModel={setOpenAddServicePlan}
              setRetireAircraftModel={setDeactivateServicePlan}
              idPrefix={ID_PREFIX}
              isRetire={false}
              allowRetireDeactive={true}
            />
          </AccessControl>,
          <FilterHookContainer
            key="filters"
            idPrefix={ID_PREFIX}
            storeContext={SERVICE_PLANS_LIST_STORE_CONTEXT}
            handleFilterChange={handleFilterChange}
          />,
          <ListViewSortContainer
            key="sorts"
            idPrefix={ID_PREFIX}
            disabled={servicePlanLoading}
            storeContext={SERVICE_PLANS_LIST_STORE_CONTEXT}
            columns={servicePlansListColumns}
            showColumnGroups={true}
            getQueryFieldForColumn={getQueryFieldForColumn}
          />
        ]}
        rightStack={[
          <ShareLink
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'shareLink', name, type)}
            disabled={servicePlanLoading}
            urlPath={urlPath}
            urlQuery={''}
          />
        ]}
        tabs={
          <HorizontalTabs
            getFullElementId={(name, type) => getElementId(ID_PREFIX, 'listTabs', name, type)}
            tabs={lookupTableTabDefinitions}
            titleCount={!servicePlanLoading && servicePlansListData ? servicePlansListData.length : 0}
          ></HorizontalTabs>
        }
      />
      {openAddServicePlan ? (
        <AddServicePlan
          openPanel={openAddServicePlan}
          setOpenPanel={setOpenAddServicePlan}
          panelData={selectedLookups[0]}
          isUpdate={selectedLookups.length === 1}
        ></AddServicePlan>
      ) : (
        <></>
      )}
      {deactivateServicePlan && (
        <RetireLookupRecord
          retireLookupType={RetireLookupType.DEACTIVATE}
          openRetireLookupModal={deactivateServicePlan}
          retireLookupQueryRoute={'lookups/deactivate/servicePlan/' + selectedLookups[0]?.id}
          recordDisplayName={'Service Plan'}
          setOpenRetireLookupModal={setDeactivateServicePlan}
        />
      )}
      {snackbar ? (
        <SnackBar id={`${ID_PREFIX}--snackbar`} openSnack={snackbar.openSnack} snackMessage={snackbar.snackMessage} />
      ) : (
        <></>
      )}
      <ListGridContainer>
        <ListGridV2
          listGridData={!servicePlanLoading && servicePlansListData ? servicePlansListData : []}
          listGridDataTotal={!servicePlanLoading && servicePlansListData ? servicePlansListData.length : 0}
          isHeaderLoading={servicePlanLoading}
          isLoading={servicePlanLoading}
          columns={currentColumns}
          fullWidthLoadingBars={true}
          handleSortChange={handleSortChange}
          selectedSort={sort}
        />
      </ListGridContainer>
    </PageStyled>
  );
};

export default ServicePlanList;
