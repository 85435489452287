/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft and Equipment Information Right Panel
 */
import React from 'react';
import styled from '@emotion/styled';
import {AccordionDetails} from '@mui/material';
import {camelCase} from 'lodash';
import {
  ACCORDION_TITLE_LABEL,
  FORM_COLOR,
  LABEL_COLOR,
  FORM_ERROR_COLOR,
  NEXT_BUTTON_TEXT_DISABLED_COLOR
} from '../../../../theme/Colors';
import {IAccordionItem, IAccordionItems} from './Accordion';

export const AccordionItemTitle = styled.div<{fontSize?: number}>`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 800;
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '12px')};
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding-bottom: 8px;
  color: ${LABEL_COLOR};
  padding-top: 2px;
  display: flex;
`;

const AccordionHeader = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin: 16px 0px 16px 0px;
  color: ${ACCORDION_TITLE_LABEL};
`;

const AccordionItem = styled.div<{isInternalCard?: boolean}>`
  margin-bottom: ${props => (props.isInternalCard ? '0px' : '24px')};
`;
export const AccordionItemSubTitle = styled.div`
  color: ${NEXT_BUTTON_TEXT_DISABLED_COLOR};
  margin-left: 2px;
`;

const AccordionItemLabel = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${FORM_COLOR};
  margin-bottom: 10px;
  display: flex;
  span {
    width: 50%;
    display: flex;
  }
`;

const AccordionItemLabelSpanHighlight = styled.span`
  color: ${FORM_ERROR_COLOR};
`;

interface IAccordionDetailsProps {
  id: string;
  data: IAccordionItems[];
  isInternal: boolean;
}

const AccordionDetailsContainer: React.FC<IAccordionDetailsProps> = ({id, data, isInternal}) => {
  return (
    <AccordionDetails style={{margin: '-17px -16px', padding: '8px 8px 0px'}}>
      {data.map((accordionData: IAccordionItems, idx: number) => {
        return accordionData.items && accordionData.items.length ? (
          <AccordionItem isInternalCard={isInternal} className="accordion-details" key={`accordionDetails-${idx}`}>
            <AccordionItemTitle>{accordionData.title}</AccordionItemTitle>
            {accordionData.items.map((accordionItem: IAccordionItem, index: number) => {
              const ActiveSpan = accordionItem.highlight ? AccordionItemLabelSpanHighlight : styled.span``;
              return (
                <AccordionItemLabel key={`${accordionItem.label}-${id}-${index}`}>
                  <span
                    id={`${id}--${accordionData.sectionCategory}-${camelCase(accordionItem.label)}-title`
                      .split(' ')
                      .join('')}
                  >
                    {accordionItem.label}
                  </span>{' '}
                  <ActiveSpan
                    id={`${id}--${accordionData.sectionCategory}-${camelCase(accordionItem.label)}-value`
                      .split(' ')
                      .join('')}
                  >
                    {accordionItem.value ? accordionItem.value : '--'}
                  </ActiveSpan>
                </AccordionItemLabel>
              );
            })}
          </AccordionItem>
        ) : accordionData.sectionTitle ? (
          <AccordionHeader key={`${accordionData.sectionTitle}-${id}-${idx}`}>
            {accordionData.sectionTitle}
          </AccordionHeader>
        ) : (
          <React.Fragment key={idx}></React.Fragment>
        );
      })}
    </AccordionDetails>
  );
};

export default AccordionDetailsContainer;
