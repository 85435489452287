/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is partNumber to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Barring Service Plan
 */
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from '@emotion/styled';
import {Warning} from '@mui/icons-material';

import {
  BottomHorizontalRule,
  FormContainerLeftPanel,
  HeaderHorizontalRule,
  HeaderLabel,
  NextButton,
  PageDescription
} from '../CommonStyles';
import PageContainer from '../mainMenu/PageContainer';
import Breadcrumb from '../common/Breadcrumb';
import {BAR_SERVICE_MESSAGE_CONTAINER, CANCEL_ICON_COLOR, DARK_RED_COLOR, LABEL_COLOR} from '../../theme/Colors';
import {useStore} from '../../store/Store';
import {isNil} from 'lodash';
import {useCreateApprovalRequestOnServicePlanSubmit} from '../../utils/customHooks';
import {AppAction} from '../../store/reducers/AppReducer';
import {barringServicePlanQuery} from '../../store/queries/servicePlanInfoQueries';
import useFetchV2 from '../../utils/useFetchV2';

const ID_PREFIX = 'servicePlan-barring';

interface BarringServicePlanProps {
  title: string;
}

const BarServiceMessage = styled.div`
  height: 24px;
  width: auto;
  padding: 16px;
  background: ${BAR_SERVICE_MESSAGE_CONTAINER};
  color: ${LABEL_COLOR};
  border-radius: 8px;
  margin-top: 40px;
  display: flex;
  span {
    margin-left: 8px;
    margin-top: 2px;
  }
`;
const LeftChild = () => {
  const location = useLocation();

  return (
    <>
      <Breadcrumb currentRoute={location.pathname}></Breadcrumb>
      <HeaderLabel id={`${ID_PREFIX}--headerLabel`}>Barring Service</HeaderLabel>
      <HeaderHorizontalRule />
      <PageDescription id={`${ID_PREFIX}--pageDescription`}>
        To bar service select 'Bar Service' option below to continue.
      </PageDescription>
      <BottomHorizontalRule />
      <FormContainerLeftPanel>
        <BarServiceMessage id={`${ID_PREFIX}--warningMsg`}>
          <Warning sx={{color: CANCEL_ICON_COLOR}} />
          <span>Barring service will shut down all Viasat connectivity services.</span>
        </BarServiceMessage>
      </FormContainerLeftPanel>
    </>
  );
};

const ActionButtons = props => {
  return (
    <NextButton
      bgColor={DARK_RED_COLOR}
      onClick={props.handleClick}
      width={130}
      form="resume-service-form"
      id={`${ID_PREFIX}--nextButton`}
      disabled={false}
    >
      <span className="next-button-text">Bar Service</span>
    </NextButton>
  );
};

const BarringServicePlan: React.FC<BarringServicePlanProps> = ({title}) => {
  const [requestType, setRequestType] = useState(null);
  const submitForm = () => {
    setRequestType('Barring Service');
  };
  const {store, dispatch} = useStore();
  const navigate = useNavigate();
  const taskId = store.servicePlan?.barringServicePlan?.taskId;
  const {serialNumber, tailId, make, model, network} = store.aircraftInfo;
  const {breadcrumb} = store.app;
  const groupCode = store.customer.current.code ? store.customer.current.code : 'ALL';

  const queryParams = useMemo(() => {
    if (isNil(requestType) || isNil(tailId) || isNil(serialNumber)) return;
    return {
      groupCode,
      requestType,
      taskId,
      serialNumber,
      tailId,
      make,
      model,
      network
    };
    //eslint-disable-next-line
  }, [requestType, groupCode]);

  const {isLoading: inProgress, data: servicePlanInfo} = useFetchV2(
    {route: barringServicePlanQuery.route, params: queryParams},
    barringServicePlanQuery.transform
  );

  const {isSubmitRequestLoading, submitResponse} = useCreateApprovalRequestOnServicePlanSubmit(
    servicePlanInfo?.taskId,
    false
  );

  useEffect(() => {
    if (inProgress || isNil(servicePlanInfo) || isSubmitRequestLoading) return;

    const operation = 'submitted';
    const message = 'Barring service plan request information has been';
    const details = 'Service plan barring information has been sent to Viasat MCC. ';

    dispatch({
      type: AppAction.SET_BREADCRUMB,
      payload: breadcrumb.filter(breadCrumb => breadCrumb.linkPath === '/main-menu')
    });
    dispatch({
      type: AppAction.SET_ALERT_INFO,
      payload: {
        showAlert: true,
        operation: operation,
        message: message,
        details: details
      }
    });
    navigate('/main-menu');
    //eslint-disable-next-line
  }, [dispatch, inProgress, servicePlanInfo, isSubmitRequestLoading, submitResponse]);

  return (
    <PageContainer title={title} leftChild={<LeftChild />} actionButtons={<ActionButtons handleClick={submitForm} />} />
  );
};

export default BarringServicePlan;
