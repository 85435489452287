/***
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ReviewInformation - Equipment Details - APSU - Ka/Ku
 */
import React from 'react';
import {
  ReviewItem,
  ReviewItemLabel,
  ReviewItems,
  ReviewItemValue,
  ReviewSubSection,
  ReviewSubSectionItemTitle
} from '../../ReviewInformationStyles';
import {renderReviewInformation} from '../../util';

interface IApsuInfo {
  serialNumber: string;
  partNumber: string;
}
interface IApsuDetail {
  idPrefix: string;
  apsuDetail: {new: IApsuInfo; existing: IApsuInfo};
}

const ApsuDetail: React.FC<IApsuDetail> = ({idPrefix, apsuDetail}) => {
  return (
    <>
      {apsuDetail && apsuDetail.new ? (
        <ReviewSubSection>
          <ReviewSubSectionItemTitle>APSU</ReviewSubSectionItemTitle>
          <ReviewItems>
            <ReviewItem>
              <ReviewItemLabel>Part Number</ReviewItemLabel>
              {apsuDetail.existing && apsuDetail.existing.partNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__apsu--existingPartNumber`}>{renderReviewInformation(apsuDetail.existing.partNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__apsu--newPartNumber`}>{renderReviewInformation(apsuDetail.new.partNumber)}</ReviewItemValue>
            </ReviewItem>
            <ReviewItem>
              <ReviewItemLabel>Serial Number</ReviewItemLabel>
              {apsuDetail.existing && apsuDetail.existing.serialNumber ? (
                <ReviewItemValue isExisting={true} id={`${idPrefix}__apsu--existingSerialNumber`}>{renderReviewInformation(apsuDetail.existing.serialNumber)}</ReviewItemValue>
              ) : null}
              <ReviewItemValue id={`${idPrefix}__apsu--newSerialNumber`}>{renderReviewInformation(apsuDetail.new.serialNumber)}</ReviewItemValue>
            </ReviewItem>
          </ReviewItems>
        </ReviewSubSection>
      ) : (
        <></>
      )}
    </>
  );
};

export default ApsuDetail;
